import { Box, styled } from '@mui/material';

export const Main = styled(Box)(({ theme }) => ({
    maxWidth: theme.typography.pxToRem(590),
    width: '100%',
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
        borderWidth: theme.typography.pxToRem(1),
        borderColor: theme.palette.clouds,
        borderStyle: 'solid',
        borderTopLeftRadius: theme.typography.pxToRem(3),
        borderTopRightRadius: theme.typography.pxToRem(3),
        boxShadow: '0px 3px 6px #00000029',
    },
}));

export const Header = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.dark,
    padding: theme.typography.pxToRem(30),
    [theme.breakpoints.up('sm')]: {
        borderTopLeftRadius: theme.typography.pxToRem(3),
        borderTopRightRadius: theme.typography.pxToRem(3),
        flexDirection: 'row',
        padding: theme.typography.pxToRem(40),
    },
}));

export const Icon = styled(Box)(({ theme }) => ({
    height: 52,
    width: 52,
    borderRadius: 100,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
        height: 70,
        width: 70,
    },
}));

export const Content = styled(Box)(({ theme }) => ({
    padding: '1.875rem .9375rem',
}));
