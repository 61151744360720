import {
    ChangePasswordProps,
    CreateAccountProps,
    LoginProps,
    RecoveryPasswordProps,
} from '@interfaces/ClientServicesApi';
import { localStorage } from '../../utils/localStorage';
import { client } from '../../api/client';

const useAuth = () => {
    const { services } = client();

    const login = async ({ email, password }: LoginProps) => {
        const response = await services.auth.login({ email, password });
        localStorage({
            action: 'add',
            key: '@auth',
            value: 'pc2l0b3ItYXBwbGljYXRpb24tc2',
        });
        // window.localStorage.setItem('@auth', 'pc2l0b3ItYXBwbGljYXRpb24tc2');
        return response;
    };

    const logout = async () => {
        const response = await services.auth.logout();
        localStorage({
            action: 'remove',
            key: '@auth',
            value: 'pc2l0b3ItYXBwbGljYXRpb24tc2',
        });
        setTimeout(() => {
            window.location.replace('/');
        }, 200);
        return response;
    };

    const createAccount = async ({ ...user }: CreateAccountProps) => {
        const response = await services.auth.account.create(user);
        return response;
    };

    const validationAccount = async (code: Number) => {
        const response = await services.auth.validationAccount(code);
        return response;
    };

    const resendValidation = async (sendEmail: boolean, sendSms: boolean) => {
        const response = await services.auth.resendCode(sendEmail, sendSms);
        return response;
    };

    const recoveryPassword = async ({ email }: RecoveryPasswordProps) => {
        const response = services.auth.account.password.recovery({ email });
        return response;
    };

    const changePassword = async ({ token, password }: ChangePasswordProps) => {
        const response = services.auth.account.password.change({ token, password });
        return response;
    };

    return {
        login,
        logout,
        createAccount,
        validationAccount,
        resendValidation,
        recoveryPassword,
        changePassword,
    };
};


export { useAuth };
