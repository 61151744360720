import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();

    const css = {
        sxLoadMoreBox: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        },
        sxBoxCircular: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '12.5rem',
            width: '100%',
        },
        sxCircularProgress: {
            color: palette.dark,
        },
    };

    return { css };
};
