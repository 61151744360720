export const sx = () => {
    const css = {
        sxIcon: {
            tiny: {
                fontSize: '1rem',
                padding: '0.5625rem 0.5rem',
                marginRight: '0.625rem',
            },
            small: {
                fontSize: '1.125rem',
                padding: '0.625rem 0.5625rem',
                marginRight: '0.625rem',
            },
            medium: {
                fontSize: '1.25rem',
                marginRight: '0.625rem',
            },
        },
        sxBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            // paddingLeft: '0.9375rem',
            // cursor: 'pointer',
            // minWidth: '17.5rem',
        },
        sxBoxContent: {
            display: 'flex',
            alignItems: 'center',
        },
        sxBoxTexts: {
            display: 'flex',
            flexDirection: 'column',
        },
        sxBoxChildren: {
            // padding: ' 0 0.9375rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        sxBoxIconCustom: {
            // width: '3.5rem',
            // height: '3.75rem',
            // padding: '1rem',
            // margin: '0.3125rem 0',
            // marginRight: '0.625rem',
        },
    };

    return { css };
};
