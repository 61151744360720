import React from 'react';
import {
    Box,
    Button,
    InputAdornment,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormCouponProps, SnackBarResponse, ToggleStaticProps } from '@interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from './yup';
import { useCheckout } from '@hooks';
import { Snackbar } from '@components';
import { useCart } from '../../../../../hooks/shopping';

interface TemplateFormCouponProps {
    onSubmitAction?: () => void;
}

const FormCoupon: React.FC<TemplateFormCouponProps> = ({
    onSubmitAction,
}: TemplateFormCouponProps) => {
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const { checkout } = useCheckout();
    const { cart } = useCart();
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const [feedback, setFeedback] = React.useState<SnackBarResponse>(
        {} as SnackBarResponse);


    const { register, handleSubmit, formState: { errors } } =
        useForm<FormCouponProps>({
            resolver: yupResolver(formSchema),
            mode: 'onBlur',
        });

    const onSubmit: SubmitHandler<FormCouponProps> = async (data) => {
        const response = await checkout.coupon.apply({
            name: data.couponCode,
        });

        if (response) {
            setFeedback((prev) => prev = {
                type: response.response.type,
                message: response?.response.message,
            });
            snackRef.current?.show();

            if (!response.error) {
                await cart.load();
                setTimeout(() => {
                    onSubmitAction && onSubmitAction();
                }, 1000);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box maxWidth={matches ? '60%' : '100%'} mt={typography.pxToRem(20)}>
                <TextField
                    focused
                    fullWidth
                    label={'Inserir código do cupom *'}
                    variant="outlined"
                    id="outlined-basic"
                    type={'text'}
                    helperText={errors.couponCode ?
                        errors.couponCode.message :
                        ''}
                    error={!!errors.couponCode}
                    {...register('couponCode',
                        { required: true },
                    )}
                    placeholder='...'
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="medium"
                                    type='submit'
                                    className='MuiButton secondary'
                                    sx={{
                                        ...{
                                            borderRadius: typography.pxToRem(4),
                                            backgroundColor: palette.dark,
                                        },
                                        '&:hover': {
                                            backgroundColor: palette.dark,
                                        },
                                    }}
                                >
                                    Aplicar
                                </Button>
                            </InputAdornment>
                        ),
                        startAdornment: (
                            <InputAdornment position="start">
                                <I icon={['far', 'ticket']} color={palette.dark} />
                            </InputAdornment>
                        ),
                    }} />
            </Box>
            <Snackbar
                ref={snackRef}
                message={feedback.message}
                severity={feedback?.type}
            />
        </form>
    );
};

export { FormCoupon };
