/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
/* eslint-disable indent */
/* eslint-disable max-len */
import {
    AddressBox,
    AvatarGroup,
    BoxContent,
    HeaderPage,
    Headline,
    MenuItem,
    Price,
    Product,
    SmallerDashedList,
    FormControlSelect,
    MuiDialog,
    Template,
    Snackbar,
    SmallTableColumn,
} from '@components';
import {
    Box,
    Button,
    Container,
    FormControl,
    Grid,
    Radio,
    Select,
    SelectChangeEvent,
    Stack,
    Typography,
    useTheme,
    MenuItem as MuiMenuItem,
    useMediaQuery,
    Checkbox,
    AlertColor,
    CircularProgress,
    FormHelperText,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import React, { useState } from 'react';
import { ToggleStaticProps, CreditCardFlagType, CreditCardProps } from '@interfaces';
import { addressFormat, formatNumberToPriceBRL, images } from '@utils';
import { paymentMethods } from './static/payment-methots';
import { useCheckout, usePaymentMethod, useStepper, useUser } from '@hooks';
import { CouponList } from './components/coupon-list';
import { Flag } from './styles';
import { flagsIcon } from '@static/payment-method';
import { Address } from '@interfaces/Contexts';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCart } from '../../../../hooks/shopping/cart';
import { ShippingTime } from './components/shipping-time';
import { ResponseAsyncProps } from '@interfaces/ClientServicesApi';
import { CheckoutHandlePaymentTypeProps } from '@interfaces/Checkout';
import { CheckoutPaymentMethodType } from '@types';

// const creditCardList = [
//     {
//         id: 'f00e92c22b1b',
//         creditCardNumber: '0000 0000 0000 4521',
//         brand: 'visa',
//         icon: ['fab', 'cc-visa'],
//     },
//     {
//         id: '6c55bc4d22c6',
//         creditCardNumber: '0000 0000 0000 1840',
//         brand: 'mastercard',
//         icon: ['fab', 'cc-mastercard'],
//     },
// ];

const DEFAULTS = {
    ERRORS: {
        creditCard: {
            error: false,
            message: '',
        },
    },
};

const steps = [
    'Forma de pagamento',
    'Pagar com cartão de crédito',
    'Pagar pix',
    'Pagar boleto',
];

const Checkout = () => {
    const { cart } = useCart();
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const dialogConfirm = React.useRef<ToggleStaticProps>(null);
    const dialogbalance = React.useRef<ToggleStaticProps>(null);
    const dialogCoupon = React.useRef<ToggleStaticProps>(null);
    const dialogCreditCard = React.useRef<ToggleStaticProps>(null);
    const { palette, typography } = useTheme();
    const { css } = sx();
    const matches = useMediaQuery('(min-width:600px)');
    const { activeStep, goToStep } = useStepper({ steps: steps });
    const urlSearch = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const location = useLocation();
    const { user, loadUser } = useUser();
    const { checkout } = useCheckout();
    const { creditCard } = usePaymentMethod();

    const [cardSelected, setCardSelected] = React.useState<CreditCardProps>(
        {} as CreditCardProps,
    );
    const [limitCardList, setLimitCardList] = React.useState(3);
    const [installmentsQuantity, setInstallmentsQuantity] = React.useState('1');
    const [paymentMethodType,
        setPaymentMethodType] = React.useState<CheckoutPaymentMethodType | undefined>();
    const [address, setAddress] = React.useState<Address>();
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
        isLoading: false,
    });
    const [errors, setErrors] = useState(DEFAULTS.ERRORS);

    const handleChange = (event: SelectChangeEvent) => {
        setInstallmentsQuantity(event.target.value);
    };

    const installmentsValue = () => {
        const list = [];
        const price = cart.resume.data.total;
        const installmentPrice = (per?: number): number => {
            const num = price ? Number(price.toFixed(2)) : 0;
            return per ? num / per : num;
        };
        const [first] = String(price).split('.');

        if ((String(price).indexOf('.') !== 1) && Number(first) <= 300) {
            const result = [
                { value: 1, title: `1x de R$${formatNumberToPriceBRL(+installmentPrice())} sem juros` },
            ];
            list.push(...result);
        } else {
            const result = [
                { value: 1, title: `1x de R$${formatNumberToPriceBRL(+installmentPrice())} sem juros` },
                { value: 2, title: `2x de R$${formatNumberToPriceBRL(+installmentPrice(2).toFixed(2))} sem juros` },
                { value: 3, title: `3x de R$${formatNumberToPriceBRL(+installmentPrice(3).toFixed(2))} sem juros` },
            ];
            list.push(...result);
        }
        return list;
    };

    const handleSelectedCreditCard = (creditCard: CreditCardProps) => {
        setCardSelected((prev) => prev = creditCard);
        setErrors((prev) => prev = DEFAULTS.ERRORS);
    };

    const paymentType = async ({ type, step }: CheckoutHandlePaymentTypeProps) => {
        setCardSelected({} as CreditCardProps);
        goToStep(step);
        setPaymentMethodType((prev) => prev = type);
    };

    const purchase = async () => {
        if (!(Object.keys(cardSelected).length > 0) && paymentMethodType === 'CARTAO') {
            setErrors((prev) => prev = {
                ...prev,
                creditCard: {
                    error: true,
                    message: 'Selecione um cartão de crédito',
                },
            });

            return;
        };

        setErrors((prev) => prev = DEFAULTS.ERRORS);

        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });
        if (paymentMethodType !== 'CARTAO') {
            setCardSelected({} as CreditCardProps);
        }
        const response = await checkout.purchase({
            paymentMethod: paymentMethodType as CheckoutPaymentMethodType,
            deliverymethod: 'Correio',
            paymentType: 'POR COMPRA',
            creditCardId: Object.keys(cardSelected).length > 0 ? cardSelected._id : '',
            installmentNumber: Object.keys(cardSelected).length > 0 ? Number(installmentsQuantity) : undefined, // serar string vazia em setInstallmentsQuantity() caso seja diferente de cartão
        });

        if (!(response?.error)) {
            cart.reset();
            setResponseData((prev) => prev = {
                ...prev,
                isLoading: false,
            });

            return navigate('/checkout/success/' +
                response?.response.payload.pedidos[0] +
                '/' + paymentMethodType?.toUpperCase());
        } else {
            setResponseData((prev) => prev = {
                ...prev,
                type: response?.response.type,
                message: response?.response.message,
                isLoading: false,
            });
            snackRef.current?.show();
        }
    };

    const handleChangePoints = async (checked: boolean) => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });
        let response: ResponseAsyncProps | undefined;
        if (checked) {
            response = await checkout.points.apply();
        } else {
            response = await checkout.points.remove();
        }
        cart.load();
        setResponseData((prev) => prev = {
            ...prev,
            type: response?.response.type,
            message: response?.response.message,
            isLoading: false,
        });
        snackRef.current?.show();
    };

    const handleChangeWallet = async (checked: boolean) => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });
        let response: ResponseAsyncProps | undefined;
        if (checked) {
            response = await checkout.wallet.apply();
        } else {
            response = await checkout.wallet.remove();
        }
        cart.load();
        setResponseData((prev) => prev = {
            ...prev,
            type: response?.response.type,
            message: response?.response.message,
            isLoading: false,
        });
        snackRef.current?.show();
    };

    const onSubmit = async () => {
        await purchase();
    };

    const sxDisplay = (step: number) => {
        return {
            display: activeStep === step ? 'block' : 'none',
        };
    };

    React.useEffect(() => {
        // dialogbalance.current?.show();
        loadUser();
        creditCard.loadList();
    }, []);

    React.useEffect(() => {
        if (creditCard.list.data) {
            setCardSelected(creditCard.list.data[0]);
        }
    }, [creditCard.list.data]);

    React.useEffect(() => {
        const addressId = urlSearch.get('shippingAddress');
        if (addressId) {
            const address = user.data?.endereco.find((ad) => ad._id === addressId);
            setAddress(address);
        }
    }, [user.data]);

    React.useEffect(() => {
        const addressId = urlSearch.get('shippingAddress');
        if (addressId) {
            const address = user.data?.endereco.find((ad) => ad._id === addressId);
            setAddress(address);
        }
    }, [location]);

    React.useEffect(() => {
        if (!cart.stores.isLoaded) {
            cart.load();
        }
    }, []);

    return (
        <React.Fragment>
            <Container maxWidth='lg'
                className='no-padding'
                sx={{
                    backgroundColor: 'white',
                }}>
                <HeaderPage
                    titleAlign={'default'}
                    title={'Resumo do pedido'}
                    icon={['far', 'bag-shopping']}
                />

                <Grid container spacing={0} sx={matches ? css.grid.container : {}}>
                    <Grid item sm={4} xs={12} sx={matches ? css.grid.divider : {}}>
                        <Box bgcolor='white'>
                            <Box sx={css.box}>
                                <Headline
                                    size='small'
                                    title='Produtos' />
                                {cart.stores.isLoaded && cart.stores.data.length > 0 && (
                                    cart.stores.data.map((item) => (
                                        <Box sx={{ mt: 2, mb: 1 }}>
                                            <Box sx={css.cart.wrapper}>
                                                <Box sx={css.cart.header}>
                                                    <AvatarGroup
                                                        borderColor={palette.snow}
                                                        align={'right'}
                                                        size={'tiny'}
                                                        title={item.loja?.minha_loja.titulo}
                                                        src={item.loja.imagens ?
                                                            images.load(item.loja.imagens.avatar, 'server') : ''} />
                                                </Box>
                                                {item.itens.map((product) => (
                                                    <BoxContent
                                                        paddingLeft
                                                        borderBottom>
                                                        <Product
                                                            size={'small'}
                                                            align={'right'}
                                                            // eslint-disable-next-line max-len
                                                            src={images.load(product.id.fotos[0], 'server')}
                                                            title={product.id.id_anuncio ?
                                                                product.id.id_anuncio.titulo :
                                                                product.id.titulo}
                                                            legend={`${product.id.nome_cor ?
                                                                'Cor: ' + product.id.nome_cor + ',' :
                                                                ''} ${product.id.tamanho ?
                                                                    'Tamanho: ' + product.id.tamanho.nome + ',' :
                                                                    product.id_item_variacao ?
                                                                        product.id_item_variacao.tamanho ?
                                                                            'Tamanho: ' + product.id_item_variacao.tamanho.nome + ',' : '' : ''}`} />
                                                        {product.id.quantidade_dias_disponivel && (
                                                            <Typography
                                                                variant="caption"
                                                                fontWeight="bold"
                                                                color={palette.dark}>
                                                                Prazo para produção: {product.id.quantidade_dias_disponivel + ' dias úteis'}
                                                            </Typography>
                                                        )}
                                                    </BoxContent>
                                                ))}
                                                <Box sx={css.cart.footer}>
                                                    <Stack
                                                        flexDirection='row'
                                                        alignItems="center"
                                                        sx={css.cart.footer.shippingMob}>
                                                        <Box mr={typography.pxToRem(10)}>
                                                            <Typography
                                                                variant="body2"
                                                                fontWeight="bold"
                                                                color={palette.dark}>
                                                                Frete
                                                            </Typography>
                                                        </Box>
                                                        <Box mt={typography.pxToRem(5)}>
                                                            <Price
                                                                size='medium'
                                                                value={formatNumberToPriceBRL(item.valor_frete)} />
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                                {Object.keys(item.transportadora_frete).length > 1 && (
                                                    <ShippingTime item={item} days={item.transportadora_frete.delivery_time} />
                                                )}
                                            </Box>
                                        </Box>
                                    )))}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={12} sx={matches ? css.grid.divider : {}}>

                        <Box sx={css.box}>
                            <Headline
                                size='small'
                                title='Endereço de entrega' />
                            <Box sx={{ mt: 2, mb: 1 }}>
                                {address &&
                                    <AddressBox
                                        mode={'edit'}
                                        nickname={address.apelido}
                                        title={`CEP: ${address.cep}`}
                                        legend={addressFormat({
                                            logradouro: address.logradouro,
                                            bairro: address.bairro,
                                            cidade: address.cidade,
                                            estado: address.estado,
                                            numero: Number(address.numero),
                                            complemento: address.complemento,
                                        })}
                                        onClick={() => dialogConfirm.current?.show()} />
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item sm={4} xs={12} sx={matches ? css.grid.divider : {}}>
                        <Box sx={css.box}>
                            <Headline
                                size='small'
                                title='Meu pedido'
                                paddingBottom />
                            <Box mb={1} sx={css.resume.wrapper}>
                                {/* <SmallerDashedList
                                    divider
                                    secondaryAction={<Points size='small' value={'10'} />}
                                    title={'Bônus'}
                                    listItemId={'bonus-list'}
                                    size={'medium'}
                                    popper='Texto explicando o bônus'
                                /> */}
                                <SmallerDashedList
                                    divider
                                    secondaryAction={<Price
                                        size='small'
                                        value={formatNumberToPriceBRL(cart.resume.data.total_parcial)}
                                    />}
                                    title='Valor total dos produtos'
                                    listItemId={'bonus-list'}
                                    size={'medium'}
                                />
                                <SmallerDashedList
                                    divider
                                    secondaryAction={<Price
                                        size='small'
                                        value={formatNumberToPriceBRL(cart.resume.data.total_frete)}
                                    />}
                                    title={'Frete'}
                                    listItemId={'bonus-list'}
                                    size={'medium'}
                                />
                                {/* <SmallerDashedList
                                    divider
                                    secondaryAction={
                                        <Checkbox
                                            onChange={(e) => {
                                                handleChangeWallet(e.currentTarget.checked);
                                            }}
                                            size='small'
                                        />
                                    }
                                    title={'Saldo Mariaz'}
                                    subtitle='R$ 70,00 disponível para uso'
                                    listItemId={'bonus-list'}
                                    size={'medium'}
                                    popper='Texto explicando o saldo mariaz'
                                /> */}
                                {/* <SmallerDashedList
                                    divider
                                    secondaryAction={
                                        <Checkbox
                                            onChange={(e) => {
                                                handleChangePoints(e.currentTarget.checked);
                                            }}
                                            size='small'
                                        />
                                    }
                                    title={'Saldo de Pontos'}
                                    subtitle='R$ 14,60 disponível para uso'
                                    listItemId={'bonus-list'}
                                    size={'medium'}
                                    popper='Texto explicando o saldo de pontos'
                                /> */}
                                <SmallerDashedList
                                    divider
                                    secondaryAction={
                                        cart.resume.data.desconto && cart.resume.data.desconto.cupom ?
                                            <Price size='small'
                                                value={formatNumberToPriceBRL(cart.resume.data.desconto.cupom.desconto)}
                                                negative /> : <></>}
                                    title={[<I icon={['far', 'ticket']} style={{ marginRight: 4 }} />,
                                    cart.resume.data.desconto && cart.resume.data.desconto.cupom ?
                                        `1 Cupom Aplicado: ${cart.resume.data.desconto.cupom.codigo}` :
                                        'Aplicar Cupom']}
                                    titleBold
                                    listItemId={'bonus-list'}
                                    size={'medium'}
                                    onClick={() => dialogCoupon.current?.show()}
                                />
                                <Stack
                                    flexDirection='row'
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={css.resume.total}>
                                    <Box>
                                        <Typography
                                            variant={matches ? 'body1' : 'body2'}
                                            fontWeight="bold"
                                            color={palette.dark}>
                                            Total
                                        </Typography>
                                    </Box>
                                    <Box mt={typography.pxToRem(5)}>
                                        <Price
                                            size='medium'
                                            value={formatNumberToPriceBRL(cart.resume.data.total)}
                                        />
                                    </Box>
                                </Stack>
                            </Box>
                        </Box>
                        <Box pb={2} sx={css.box}>
                            <Headline
                                size='small'
                                title={steps[activeStep]}
                                paddingBottom />
                            <Box sx={sxDisplay(0)}>
                                {paymentMethods.map(({ _id, icon, title, type }, i) => (
                                    <Box mb={1} key={_id}>
                                        <MenuItem
                                            borderTop
                                            borderType={'square'}
                                            boxShadow
                                            title={{
                                                text: title,
                                                fontWeight: 'medium',
                                                size: 'medium',
                                            }}
                                            icon={{
                                                path: icon,
                                                size: 'small',
                                            }}
                                            onClick={() => paymentType({
                                                type: type as CheckoutPaymentMethodType,
                                                step: i + 1,
                                            })}
                                            htmlFor={_id}
                                            children={
                                                <Box p={2}>
                                                    <I icon={['far', 'chevron-right']} />
                                                </Box>
                                            } />
                                    </Box>
                                ))}
                            </Box>

                            <Box sx={sxDisplay(1)}>
                                {creditCard.list.isLoaded && creditCard.list.data &&
                                    <>
                                        {creditCard.list.data.slice(0, limitCardList).map((card) => (
                                            <MenuItem
                                                key={card._id}
                                                borderType={'square'}
                                                borderTop
                                                title={{ text: card.bandeira }}
                                                legend={{
                                                    text: card.numero_cartao,
                                                    fontWeight: 'bold',
                                                }}
                                                iconCustom={{
                                                    path:
                                                        <Flag
                                                            src={flagsIcon[card.bandeira as CreditCardFlagType]}
                                                            alt='cc-visa' />,
                                                }}
                                                onClick={() => null}
                                                children={(
                                                    <Box pr={2}>
                                                        <Radio
                                                            checked={cardSelected._id === card._id}
                                                            onChange={() => handleSelectedCreditCard(card)}
                                                            size='small'
                                                            id={card._id}
                                                            name='cc' />
                                                    </Box>
                                                )} />
                                        ))}
                                        {limitCardList < creditCard.list.data?.length ?
                                            <Typography
                                                onClick={() => setLimitCardList(limitCardList + 3)}
                                                sx={{ ...css.buttons.typography }}
                                                variant='h4'>
                                                Mostrar mais
                                            </Typography> :
                                            !(creditCard.list.data?.length <= 3) &&
                                            <Typography
                                                onClick={() => setLimitCardList(3)}
                                                sx={{ ...css.buttons.typography }}
                                                variant='h4'>
                                                Mostrar menos
                                            </Typography>
                                        }
                                    </>
                                }

                                {errors.creditCard.error && (
                                    <FormHelperText error>
                                        {errors.creditCard.message}
                                    </FormHelperText>
                                )}

                                <Box pt={2}>
                                    <MenuItem
                                        borderTop
                                        htmlFor='add-cartao'
                                        borderType={'square'}
                                        boxShadow
                                        title={{
                                            text: 'Adicionar Cartão',
                                            fontWeight: 'bold',
                                            size: 'medium',
                                        }}
                                        icon={{
                                            path: ['fal', 'plus-square'],
                                            size: 'medium',
                                        }}
                                        onClick={() => dialogCreditCard.current?.show()}
                                        children={
                                            <Box p={2}>
                                                <I icon={['far', 'chevron-right']} />
                                            </Box>
                                        } />
                                </Box>

                                {creditCard.list.isLoaded &&
                                    creditCard.list.data &&
                                    (creditCard.list.data?.length > 0) && (
                                        installmentsValue().length > 1 && (
                                            <Box mt={2} pb={1}>
                                                <Headline
                                                    size='small'
                                                    title='Opções de parcelamento'
                                                    popper={<span>
                                                        Parcelamento está disponível apenas<br /> para compras a partir de R$300,00
                                                    </span>}
                                                />
                                                <Box mt={1} />
                                                <FormControlSelect label={'Condição'} id={'condition-label'}>
                                                    <FormControl fullWidth>
                                                        <Select
                                                            labelId="condition-label"
                                                            id="demo-simple-select"
                                                            value={installmentsQuantity}
                                                            label="Condição"
                                                            size='medium'
                                                            onChange={handleChange}
                                                        >
                                                            {installmentsValue().map((installment) => (
                                                                <MuiMenuItem
                                                                    key={`installment-${installment.value}`}
                                                                    value={installment.value}>
                                                                    {installment.title}
                                                                </MuiMenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </FormControlSelect>
                                            </Box>
                                        )
                                    )}
                            </Box>

                            <Box sx={sxDisplay(2)}>
                                <Typography
                                    variant="body2"
                                    color={palette.dark}>
                                    seu código pix tem validade de <strong>10 minutos</strong>
                                </Typography>
                            </Box>
                            <Box sx={sxDisplay(3)} />
                        </Box>
                        {activeStep >= 1 && (
                            <Box
                                width='100%'
                                justifyContent='center'
                                pb={2}
                                sx={css.box}>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    sx={css.buttons.confirm}
                                    type='submit'
                                    className='MuiButton primary'
                                    onClick={!responseData.isLoading ? () => onSubmit() : () => { }}
                                >
                                    Confirmar {responseData.isLoading &&
                                        <CircularProgress
                                            size={14}
                                            color='inherit'
                                            sx={{ ml: 0.5 }} />}
                                </Button>

                                <Box mt={1} />

                                <Button
                                    fullWidth
                                    variant="text"
                                    size="large"
                                    type='submit'
                                    sx={css.buttons.cancel}
                                    onClick={() => {
                                        goToStep(0);
                                        setLimitCardList(3);
                                    }}
                                >
                                    Alterar forma de pagamento
                                </Button>
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </Container>
            <MuiDialog
                maxWidth='sm'
                ref={dialogCreditCard}
                contentPadding
                fullScreen={!matches}
                title='Adicionar cartão de crédito'
                showDialogTitle>
                <Template.FormCreditCard
                    onSubmitAction={() => {
                        dialogCreditCard.current?.hide();
                    }} />
            </MuiDialog>
            <MuiDialog
                maxWidth='sm'
                ref={dialogCoupon}
                contentPadding
                fullScreen={!matches}
                title='Cupom'
                showDialogTitle>

                {cart.resume.data.desconto && cart.resume.data.desconto.cupom ?
                    <CouponList
                        title={cart.resume.data.desconto.cupom.codigo}
                        discount={cart.resume.data.desconto.cupom.desconto} /> :
                    <>
                        <Typography
                            variant="body1"
                            color={palette.darkGrey}
                            gutterBottom>
                            Cada produto pode ter apenas um cupom aplicado.
                        </Typography>
                        <Template.FormCoupon
                            onSubmitAction={() => dialogCoupon.current?.hide()} />
                    </>
                }
            </MuiDialog>
            <MuiDialog
                maxWidth='sm'
                ref={dialogConfirm}
                contentPadding
                fullScreen={!matches}
                title='Alterar endereço de entrega'
                showDialogTitle>
                <Template.FormAddress
                    address={address}
                    location='checkout'
                    onSubmitAction={() => {
                        dialogConfirm.current?.hide();
                    }} />
            </MuiDialog>
            <MuiDialog
                maxWidth='xs'
                ref={dialogbalance}
                contentPadding
                onConfirm={{
                    action: () => { },
                    text: 'Usar saldo',
                }}
                onCancel={{
                    action: () => dialogbalance.current?.hide(),
                }}>

                <Box>
                    <Typography
                        variant="h6"
                        fontWeight={typography.fontWeightBold}
                        color={palette.dark}
                        gutterBottom>
                        Usar saldo Mariaz?
                    </Typography>
                    <Typography
                        variant="body1"
                        color={palette.dark}>
                        Voce tem <strong>R$70,00</strong> disponíveis para usar e
                        abater do valor final a pagar.
                    </Typography>
                </Box>
            </MuiDialog>
            <Snackbar
                ref={snackRef}
                message={responseData.message}
                severity={responseData.type}
            />
        </React.Fragment>
    );
};

export { Checkout };
