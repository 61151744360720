import { Skeleton, useMediaQuery, useTheme } from '@mui/material';


export const skeleton = () => {
    const { typography } = useTheme();

    const rm = (px: number) => typography.pxToRem(px);
    const m = useMediaQuery('(min-width:600px)');


    const mediaQuery = {
        avatar: {
            height: rm(m ? 70 : 56),
            width: rm(m ? 70 : 56),
        },
        banner: {
            height: rm(m ? 270 : 120),
            width: '100%',
        },
    };

    const elems = {
        avatar: <Skeleton variant="circular" sx={mediaQuery.avatar} />,
        banner: <Skeleton variant="rectangular" sx={mediaQuery.banner} />,
    };
    return elems;
};
