/* eslint-disable max-len */
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    Container,
    TextField,
    Checkbox,
    Stack,
    Box,
    Typography,
    InputAdornment,
    IconButton,
    CircularProgress,
} from '@mui/material';
import Button from '@mui/material/Button';
// import Popper from '@mui/material/Popper';
// import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
// import Fade from '@mui/material/Fade';
// import Paper from '@mui/material/Paper';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import { maskCellphone } from '@static/region-telephone';

import {
    ButtonLarge,
    // OrDivisor,
    // ButtonFacebook,
    Snackbar,
} from '@components';

import { FormCreateAccount, ToggleStaticProps } from '@interfaces';

import $ from 'jquery';
import 'jquery-mask-plugin';

import { formSchema } from './yup';
import { sx } from './sx';
import { Form, Label, ErrorTerms, RegionIcon } from './styles';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { useUser } from '@hooks';

type RegionNameType = 'BR' | 'USA' | 'IT';

const CreateAccount: React.FC = () => {
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const auth = useAuth();
    const { css } = sx();
    const navigate = useNavigate();
    const { addResponseCreate } = useUser();
    const [showPassword, setShowPassword] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();
    const [region] = React.useState({
        name: 'BR',
        flag: 'https://images.emojiterra.com/google/noto-emoji/unicode-15/color/svg/1f1e7-1f1f7.svg',
        code: '+55',
    });
    // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const open = Boolean(anchorEl);
    // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const { register, handleSubmit, formState: { errors } } =
        useForm<FormCreateAccount>({
            resolver: yupResolver(formSchema()),
            mode: 'onBlur',
        });

    const onSubmit: SubmitHandler<FormCreateAccount> = async (data) => {
        setIsLoading(true);
        const user = {
            nome: data.name,
            celular: data.phone ? `${region.code} ${data.phone}` : undefined,
            senha: data.password,
            cpf: data.cpf,
            email: data.email,
        };

        const response = await auth.createAccount(user);
        if (!response?.error) {
            setFeedback({
                message: response?.response
                    .message, type: response?.response.type,
            });
            addResponseCreate(response?.response.payload);
            snackRef.current?.show();
            setTimeout(() => {
                navigate(`/criar-conta/enviar-codigo/${user.celular ? 'sms-email' : 'email'}`);
            }, 1500);
        }
        setFeedback({
            message: response?.response
                .message ? response?.response
                    .message : response?.response
                    .errors.errors[0].msg,
            type: response?.response.type,
        });
        snackRef.current?.show();
        setIsLoading(false);
        return;
    };


    const inputMasks = () => {
        $('.cellphone').mask(maskCellphone[region.name as RegionNameType]);
        $('.cpf').mask('000.000.000-00');
    };

    React.useEffect(() => {
        inputMasks();
    }, [region]);

    return (
        <>
            <Container
                maxWidth={'xs'}
                sx={{ ...css.sxContainer }}>
                <Box sx={{ ...css.sxBoxAccess }}>
                    <Typography
                        variant='h1'
                        sx={{ ...css.sxAccessText }}
                    >
                        Cadastre-se agora no Mariaz
                    </Typography>
                    {/* <ButtonFacebook
                        text='Cadastre-se com o Facebook'
                        onClick={() => null} /> */}
                </Box>
                {/* <OrDivisor /> */}
                <Form
                    onSubmit={handleSubmit(onSubmit)}>
                    <TextField
                        focused={true}
                        label={'Nome completo *'}
                        placeholder='Nome completo'
                        id="outlined-basic"
                        variant="outlined"
                        helperText={errors.name ? errors.name.message : ''}
                        {...register('name', { required: true })} />
                    <TextField
                        focused={true}
                        label={'E-mail *'}
                        placeholder='examplodeemail@exemplo.com'
                        id="outlined-basic"
                        variant="outlined"
                        helperText={errors.email ? errors.email.message : ''}
                        {...register('email', { required: true })} />
                    <TextField
                        focused={true}
                        label={'Telefone'}
                        placeholder={maskCellphone[region.name as RegionNameType]}
                        id="outlined-basic"
                        variant="outlined"
                        helperText={errors.phone ? errors.phone.message : ''}
                        inputProps={{
                            className: 'cellphone',
                        }}
                        sx={{
                            '& > .MuiInputBase-root': {
                                paddingLeft: 0,
                            },
                        }}
                        InputProps={{
                            startAdornment: (
                                <Button
                                    sx={{ ...css.sxButtonRegion, ...css.sxButtonRegionSelected }}>
                                    <RegionIcon src={region.flag} alt={region.name} /> {region.code}
                                </Button>
                            ),
                        }}
                        {...register('phone', { required: false })} />
                    <TextField
                        focused={true}
                        label={'CPF *'}
                        placeholder='000.000.000-00'
                        id="outlined-basic"
                        variant="outlined"
                        helperText={errors.cpf ? errors.cpf.message : ''}
                        inputProps={{
                            className: 'cpf',
                        }}
                        {...register('cpf', { required: true })} />
                    <TextField
                        focused={true}
                        type={showPassword ? 'text' : 'password'}
                        label={'Senha *'}
                        placeholder='Senha'
                        className='with-icon-end'
                        id="input-with-icon-textfield"
                        variant="outlined"
                        helperText={errors.password ?
                            errors.password.message : ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowPassword(!showPassword)}
                                        sx={{ ...css.sxIconButtonEye }}>
                                        {showPassword ?
                                            <I icon={['fas', 'eye-slash']} /> :
                                            <I icon={['fas', 'eye']} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        {...register('password', { required: true })} />
                    <TextField
                        focused={true}
                        type={showPassword ? 'text' : 'password'}
                        label={'Repita sua senha *'}
                        placeholder='Repita sua senha'
                        className='with-icon-end'
                        id="input-with-icon-textfield"
                        variant="outlined"
                        helperText={errors.retype_password ?
                            errors.retype_password.message : ''}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() =>
                                            setShowPassword(!showPassword)}
                                        sx={{ ...css.sxIconButtonEye }}>
                                        {showPassword ?
                                            <I icon={['fas', 'eye-slash']} /> :
                                            <I icon={['fas', 'eye']} />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        {...register('retype_password', { required: true })} />


                    <Stack
                        alignItems={'start'}
                        direction={'column'}
                        sx={{ marginBottom: '1.875rem' }}>
                        <Stack
                            alignItems={'start'}
                            direction={'row'}>
                            <Checkbox
                                id='keep-logged'
                                sx={{ ...css.sxCheckbox }}
                                {...register('terms', { required: true })} />

                            <Label htmlFor='keep-logged'>
                                Li e estou de acordo com os <Link to={'/termos-de-uso'}>
                                    <Typography
                                        variant='caption'
                                        sx={{ ...css.sxCreateAccountLink }}>
                                        Termos de uso</Typography>. *
                                </Link>
                            </Label>
                        </Stack>
                        {errors.terms &&
                            <ErrorTerms>{errors.terms.message}</ErrorTerms>}
                    </Stack>
                    <ButtonLarge
                        {...css.sxButtonLarge}
                        type='submit'
                        text={'Criar conta'}
                        onClick={() => null}>
                        {isLoading &&
                            <CircularProgress
                                size={14}
                                color='inherit'
                                sx={{ ml: 0.5 }} />
                        }
                    </ButtonLarge>
                    <Box
                        sx={{ ...css.sxBoxEnd }}>
                        <Typography
                            variant='caption'
                            sx={{ ...css.sxAccountQuestion }}>
                            Já possui uma conta?
                        </Typography>
                        <Typography
                            variant='caption'
                            onClick={() => navigate('/login')}
                            sx={{ ...css.sxCreateAccountLink }}>
                            Faça o login
                        </Typography>
                    </Box>
                </Form>
            </Container>
            <Snackbar
                ref={snackRef}
                autoHideDuration={5000}
                message={feedback ? feedback.message : ''}
                severity={feedback ? feedback.type as any : 'success'}
            />
        </>
    );
};

export { CreateAccount };
