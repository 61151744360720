import * as Yup from 'yup';

export const formSchema = Yup.object().shape({
    password: Yup.string()
        .required('Senha é um campo obrigatório')
        .min(8, 'A Senha precisa conter pelo menos 8 dígitos'),
    retype_password: Yup.string()
        .required('Repita a senha digitada')
        .oneOf([Yup.ref('password')], 'As senhas não são iguais'),
});
