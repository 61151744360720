/* eslint-disable max-len */
/* eslint-disable camelcase */
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import { useFilters } from '@hooks';
import {
    FilterComponentProps, FormPriceMinMax, FormStaticProps,
} from '@interfaces';
import { FilterDataProps } from '@interfaces/Contexts';
import { Box, FormHelperText, Grid, TextField } from '@mui/material';
import React, { RefAttributes } from 'react';
import { ForwardRefExoticComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
    BoxActiveFiltersList,
    ButtonSelectedFilter,
    FilterContent,
    FilterFooter,
} from '../../../components/filters';
import { formSchema } from './yup';
const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

export const PriceFilter: ForwardRefExoticComponent<FilterComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            minHeightContent,
            onClearAction,
            onSubmitAction,
        } = props;

        // const { ad } = useAds();
        const filter = useFilters();

        const navigate = useNavigate();

        const [tempFilter, setTempFilter] = React.useState<FilterDataProps[]>(
            filter.filters.price.length > 0 ?
                filter.filters.price : [],
        );

        const {
            register,
            handleSubmit,
            // setValue,
            reset,
            setError,
            formState: { errors },
        } = useForm<FormPriceMinMax>({
            resolver: yupResolver(formSchema),
            mode: 'all',
        });

        const handleRemoveSelectedFilter = ({ _id, nome }: FilterDataProps) => {
            const filtering = tempFilter.filter((item) => item._id !== _id);
            setTempFilter((prev) => prev = [...filtering]);

            if (nome === 'price_min') {
                return reset({ price_min: '' });
            }

            if (nome === 'price_max') {
                return reset({ price_max: '' });
            }
        };

        const deleteURLParam = (key: string) => {
            const queryParams = new URLSearchParams(location.search);

            if (queryParams.has(key)) {
                queryParams.delete(key);
                navigate({
                    search: queryParams.toString(),
                });
            }
        };

        const handleSetURLParams = (key: string, value: string | undefined) => {
            const urlParams = new URLSearchParams(location.search);
            if (!value) {
                urlParams.delete(key);
            } else if (urlParams.get(key)) {
                urlParams.set(key, value);
            } else {
                urlParams.append(key, value);
            }
            return urlParams.toString();
        };

        const setURLParams = (key: string, value: string | undefined) => {
            const search = handleSetURLParams(key, value);
            navigate({ search });
        };

        const resetFilterData = () => {
            setTempFilter((prev) => prev = []);
            filter.add({
                type: 'price',
                data: [],
            });
            filter.add({
                type: 'pricemin',
                data: [],
            });
            filter.add({
                type: 'pricemax',
                data: [],
            });
            reset({
                price_min: '',
                price_max: '',
            });

            deleteURLParam('pricemin');
            deleteURLParam('pricemax');
            setURLParams('pricemin', undefined);
            setURLParams('pricemax', undefined);
        };

        const onSubmit: SubmitHandler<FormPriceMinMax> = async (data) => {
            const { price_min, price_max } = data;

            if (Number(price_min) >= Number(price_max)) {
                setError('price_min', {
                    message: 'Valor maior ou igual que o valor máximo',
                });
                return;
            }

            const objectPriceFilter: FilterDataProps[] = [
                {
                    _id: price_min as string,
                    nome: 'price_min',
                    param: 'pmin=',
                    filter: price_min as string,
                },
                {
                    _id: price_max as string,
                    nome: 'price_max',
                    param: 'pmax=',
                    filter: price_max as string,
                },
            ];

            filter.add({
                type: 'price',
                data: objectPriceFilter,
            });
            filter.add({
                type: 'pricemin',
                data: [objectPriceFilter[0]],
            });
            filter.add({
                type: 'pricemax',
                data: [objectPriceFilter[1]],
            });
            onSubmitAction && onSubmitAction();
            setURLParams('pricemin', objectPriceFilter[0].filter+','+'price_min');
            setURLParams('pricemax', objectPriceFilter[1].filter+','+'price_max');
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        React.useEffect(() => {
            // parseQueryStringURL(location.search, 'price', ad, filter.tempFilters.price);
        }, []);

        return (
            <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)}>

                <FilterContent minHeightContent={minHeightContent}>
                    <BoxActiveFiltersList>
                        {tempFilter.length > 0 ?
                            tempFilter.map(({ _id, nome, filter }) => (
                                <ButtonSelectedFilter
                                    key={_id}
                                    value={filter as string}
                                    onClick={() => handleRemoveSelectedFilter({
                                        _id,
                                        nome,
                                    })}
                                />
                            )) : null}
                    </BoxActiveFiltersList>

                    <FilterContent minHeightContent={114}>

                        <Box pl={2} pr={2} pt={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={5}>
                                    <TextField
                                        focused
                                        defaultValue={tempFilter.find((filter) => filter.nome === 'price_min')?.filter}
                                        fullWidth
                                        id="outlined-min"
                                        label="mínimo"
                                        variant="outlined"
                                        size='small'
                                        error={!!errors.price_min}
                                        {...register('price_min', {
                                            required: {
                                                message: 'Campo obrigatório para filtragem',
                                                value: true,
                                            },
                                        })}
                                    />
                                    {!!errors.price_min && <FormHelperText error>
                                        {errors.price_min.message}
                                    </FormHelperText>}
                                </Grid>
                                <Grid item xs={2}>
                                    <Box
                                        display='flex'
                                        alignItems='center'
                                        justifyContent='center'
                                        height='100%'>
                                        <I
                                            icon={['far', 'long-arrow-right']}
                                        />
                                    </Box>
                                </Grid>
                                <Grid item xs={5}>
                                    <TextField
                                        focused
                                        defaultValue={tempFilter.find((filter) => filter.nome === 'price_max')?.filter}
                                        fullWidth
                                        id="outlined-min"
                                        label="máximo"
                                        variant="outlined"
                                        size='small'
                                        error={!!errors.price_max}
                                        {...register('price_max', {
                                            required: {
                                                message: 'Campo obrigatório para filtragem',
                                                value: true,
                                            },
                                        })}
                                    />
                                    {!!errors.price_max && <FormHelperText error>
                                        {errors.price_max.message}
                                    </FormHelperText>}
                                </Grid>
                            </Grid>
                        </Box>
                    </FilterContent>

                </FilterContent>
                <FilterFooter
                    onClearAction={onClearAction ?
                        onClearAction as () => void :
                        resetFilterData
                    }
                    onSubmitAction={() => { }}
                // disabledOnSubmit={!(!!tempFilter.length)}
                />
            </Box>
        );
    });

// const PriceFilter = () => {
//     return (
//         <Box height='100%' component='form'>
//             <FilterContent minHeightContent={114}>

//                 <Box p={2}>
//                     <Grid container spacing={2}>
//                         <Grid item xs={5}>
//                             <TextField
//                                 focused
//                                 fullWidth
//                                 id="outlined-min"
//                                 label="mínimo"
//                                 variant="outlined"
//                                 size='small'
//                             />
//                         </Grid>
//                         <Grid item xs={2}>
//                             <Box
//                                 display='flex'
//                                 alignItems='center'
//                                 justifyContent='center'
//                                 height='100%'>
//                                 <I
//                                     icon={['far', 'long-arrow-right']}
//                                 />
//                             </Box>
//                         </Grid>
//                         <Grid item xs={5}>
//                             <TextField
//                                 focused
//                                 fullWidth
//                                 id="outlined-min"
//                                 label="máximo"
//                                 variant="outlined"
//                                 size='small'
//                             />
//                         </Grid>
//                     </Grid>
//                 </Box>
//             </FilterContent>
//             <FilterFooter
//                 onClearAction={() => { }}
//                 onSubmitAction={() => { }}
//             />
//         </Box>
//     );
// };

// export { PriceFilter };
