import React from 'react';
import Slider from 'react-slick';

import { Box, Container } from '@mui/material';
import { settings } from './slider-settings';
import { ImageSliderBlog } from '@components';
import { banners } from '../../../../api/services/banners';

import { sx } from './sx';
import { images } from '@utils';
import { CarrousselProps } from '@interfaces';

const BlogSlider: React.FC = () => {
    const { sxBox, sxBoxSlider } = sx();
    const [carroussel, setCarroussel] = React.useState<CarrousselProps[]>(
        [] as CarrousselProps[],
    );

    const loadCarroussel = async () => {
        const response = await banners.carroussel();

        if (!response?.error) {
            setCarroussel(response?.response.payload);
        }
    };

    React.useEffect(() => {
        loadCarroussel();
    }, []);

    return (
        <Container maxWidth='lg'>
            {carroussel.length > 0 &&
                <Box sx={{ ...sxBox }}>
                    {/* <Typography
                        variant='h3'
                        fontWeight={typography.fontWeightBold}
                        fontSize={ matches? '1.75rem' : '1.125rem'}
                        padding={ matches? '1.125rem 0' : '0 0 0.9375rem 0'}>
                        Últimas do blog
                    </Typography> */}
                    <Box sx={{ ...sxBoxSlider }}>
                        <Slider {...settings} className='blog-slider'>
                            {carroussel.map(({ _id, anexo, url }) => (
                                <ImageSliderBlog
                                    key={_id}
                                    src={images.load(anexo, 'server')}
                                    link={url} />
                            ))}
                        </Slider>
                    </Box>
                </Box>
            }
        </Container>
    );
};

export { BlogSlider };
