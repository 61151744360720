/* eslint-disable max-len */
import React from 'react';

import {
    Box,
    Container,
    Typography,
    IconButton,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import Avatar from '@img/avatar.png';
import {
    AvatarGroup,
    ButtonLarge,
    Navigation,
    Page,
    Image,
} from '@components';
import Banner from '@img/user-cover.png';

import { Hero, ICButton } from './styles';
import { useUser } from '../../hooks/user';
import { images } from '@utils';
// import { useAuth } from '../../hooks/auth';

const UserMenu: React.FC = () => {
    const navigate = useNavigate();
    // const auth = useAuth();
    const { user, userMenu } = useUser();
    const { palette, typography } = useTheme();
    const { pxToRem } = typography;

    // const logout = async () => {
    //     const response = await auth.logout();
    //     if (response?.error) {
    //         return alert('error ao deslogar');
    //     }
    // };

    return (
        <Page.Wrapper border position='fixed'>
            <Box>
                <Hero>
                    <ICButton>
                        <IconButton
                            onClick={() => navigate(-1)}>
                            <I
                                icon={['far', 'arrow-right-long']}
                                fontSize={20}
                                color={palette.dark}
                            />
                        </IconButton>
                    </ICButton>
                    <Image src={user.data?.imagens && user.data?.imagens.banner ?
                        images.load(user.data?.imagens.banner, 'server') :
                        images.load(Banner, 'local')} alt="" />
                </Hero>
                <Box mb={pxToRem(30)}>
                    <Container
                        maxWidth='sm'>
                        <Box mt={pxToRem(-20)}>
                            {user.isLoaded && user.data &&
                                <AvatarGroup
                                    align={'bottom'}
                                    title={user.data.nome}
                                    size={'small'}
                                    src={user.data?.imagens && user.data?.imagens.avatar ?
                                        images.load(user.data?.imagens.avatar, 'server') :
                                        images.load(Avatar, 'local')} />
                            }
                            {user.isLoaded && user.data?.cnpj &&
                                <Box mt={pxToRem(20)}>
                                    <ButtonLarge
                                        backgroundColor={palette.dark}
                                        color={palette.common.white}
                                        text={'Criar produto'}
                                        onClick={() => navigate('/produtos/adicionar')} />
                                </Box>
                            }
                        </Box>
                    </Container>
                </Box>
                <Typography
                    variant='caption'
                    fontWeight={typography.fontWeightBold}
                    fontSize={pxToRem(10)}
                    color={palette.dark}
                    ml={2}>
                    MENU
                </Typography>
            </Box>
            <Page.Content heightCalc={285}>
                <Container
                    maxWidth='sm'
                    sx={{ pb: 2 }}>
                    {userMenu.isLoaded && userMenu.data &&
                        <Navigation.User
                            menu={userMenu.data} />
                    }
                </Container>
            </Page.Content>
        </Page.Wrapper>
    );
};

export { UserMenu };
