import { Box, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { sx } from './sx';

interface WalletButtonActionProps {
    icon: IconProp;
    title: string;
    legend: string;
    onClick?: () => void;
    disabled?: boolean;
};

const WalletButtonAction = (
    { icon, title, legend, onClick, disabled }: WalletButtonActionProps) => {
    const { typography } = useTheme();
    const { pxToRem } = typography;

    const { css } = sx();

    return (
        <Box
            sx={css.wrapper(disabled as boolean)}
            className='button-action'
            onClick={!disabled ? onClick : () => { }}>
            <Box
                sx={css.icon}>
                <I icon={icon} />
            </Box>
            <Box ml={pxToRem(10)}>
                <Typography
                    variant='h2'
                    component='h2'
                    fontSize={14}
                    fontWeight={typography.fontWeightBold}
                    mb={pxToRem(2)}
                >
                    {title}
                </Typography>
                <Typography
                    variant='h2'
                    component='h2'
                    fontSize={12}
                    fontWeight={typography.fontWeightMedium}
                >
                    {legend}
                </Typography>
            </Box>
        </Box>
    );
};

export { WalletButtonAction };
