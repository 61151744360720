import { Button } from '@mui/material';
import styled from 'styled-components';

export const ButtonUploadCover = styled(Button)(({ theme }) => `
    background-color: #FFFFFF;
    color: #010101 !important;
    font-weight: bold;
    padding: 3px 8px;
    text-transform: initial;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    &:hover {
    background-color: #FFFFFF !important;
  };
  & > label {
    cursor: pointer;
  }
`);
