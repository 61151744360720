import configs from '../configs/config.json';
import PlaceholderIMG from '@img/no-image.png';
import { FileListProps, ResponseHandleImages } from '@interfaces/Utils';

const load = (path: string, source: 'local' | 'server') => {
    if (path) {
        if (source === 'server') {
            return configs.apis.url + '/images/' + path;
        }

        if (source === 'local') {
            return path;
        }
    }

    return PlaceholderIMG;
};

const fileOBJ = (idx: number, file: FileList) => {
    const dataImageURL = URL.createObjectURL(file[idx]);
    const imageToArray = dataImageURL.split('/');
    const imageHash = imageToArray[imageToArray.length - 1];
    return ({
        id: imageHash,
        src: dataImageURL,
        content: dataImageURL,
        file: file[idx],
    });
};

const handleImages = async (event: React.ChangeEvent<HTMLInputElement>) => {
    let response = {} as ResponseHandleImages;
    const files: FileListProps[] = [];
    try {
        const selectedFiles = event.target.files as FileList;

        if (selectedFiles.length > 1) {
            for (let i = 0; selectedFiles.length > i; i++) {
                if (i === 4) break;
                files.push(fileOBJ(i, selectedFiles));
            }
        } else {
            files.push(fileOBJ(0, selectedFiles));
        }

        const count = files.length;
        if (!!(count)) {
            return response = {
                message: count > 1 ?
                    count + ' imagens adicionadas' :
                    '1 imagem adicinada',
                error: false,
                files: files,
            };
        } else {
            throw new Error('Erro ao adicionar imagens');
        }
    } catch (error: any) {
        return response = {
            message: error.message,
            error: true,
            files: [],
        };
    } finally {
        return response;
    }
};

// * DND
const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const images = {
    load: load,
    add: handleImages,
    fileOBJ,
    dnd: {
        reorder: reorder,
    },
};

export { images };
