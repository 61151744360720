import * as Yup from 'yup';

export const formSchema = Yup.object().shape({
    bank: Yup.string().required('Selecione o Banco'),
    accountType: Yup.string().required('Tipo de conta é obrigatório'),
    agency: Yup.string().required('Digite o número da agência'),
    agencyDig: Yup.string().required('Informe o dígito da agência'),
    accountNumber: Yup.string().required('Digite o número da conta'),
    accountDig: Yup.string().required('Informe o dígito da conta'),
});
