import { useTheme, alpha } from '@mui/material';

const sx = () => {
    const { palette, typography, zIndex } = useTheme();
    const css = {
        inputLabel: {
            ...{
                fontWeight: typography.fontWeightBold,
                backgroundColor: palette.common.white,
                pl: 1,
                pr: 1,
            },
            '&.Mui-focused': {
                color: palette.dark,
            },
        },
        wrapper: {
            mt: 2,
            pb: 4,
            backgroundColor: 'white',
            zIndex: 999,
            pt: 1,
            overflowY: 'auto',
        },
        chip: {
            backgroundColor: palette.dark,
            color: palette.common.white,
            marginLeft: typography.pxToRem(4),
        },
        backdrop: {
            color: palette.darkGrey,
            zIndex: zIndex.drawer + 1,
            backgroundColor: alpha(palette.common.white, 0.5),
        },
        inputEndAdornment: {
            fontSize: '16px',
            left: '-16px',
            color: '#757575',
        },
    };
    return { css };
};

export { sx };
