import React from 'react';
import { WalletContext } from '../../contexts/wallet/context';
import { client } from '../../api/client';
import {
    WalletMovementHistoryProps,
    WalletCurrentDataProps,
    BankAccountProps,
    IuguBalancesProps,
} from '@interfaces/Contexts';

const DEFAULTS = {
    WALLET_INFO: {
        balances: {
            data: {} as IuguBalancesProps,
            message: '',
            isLoaded: false,
        },
    },
};

const walletProvider = () => {
    const [movementHistory, setMovementHistory] =
        React.useState<WalletMovementHistoryProps>(
            {} as WalletMovementHistoryProps,
        );

    const [currentData, setCurrentData] =
        React.useState<WalletCurrentDataProps>(
            {} as WalletCurrentDataProps,
        );

    const [walletIInfo, setWalletInfo] = React.useState(DEFAULTS.WALLET_INFO);

    const loadCurrentData = async () => {
        setCurrentData((prev) => prev = {
            ...prev,
            isLoaded: false,
        });
        const { services } = client();
        const response = await services.wallet.find.wallet();
        if (response?.response.payload) {
            setCurrentData({
                data: response?.response.payload,
                isLoaded: true,
            });
        } else {
            setCurrentData((prev) => prev = {
                ...prev,
                isLoaded: true,
            });
        }
    };

    const loadMovementHistory = async () => {
        setMovementHistory((prev) => prev = {
            ...prev,
            isLoaded: false,
        });
        const { services } = client();
        const response = await services.wallet.find.movementHistory();
        if (response?.response.payload) {
            setMovementHistory({
                data: response?.response.payload,
                isLoaded: true,
            });
        } else {
            setMovementHistory((prev) => prev = {
                ...prev,
                isLoaded: true,
            });
        }
    };

    const addBankAccount = async (data: BankAccountProps) => {
        const { services } = client();
        const response = await services.wallet.bankAccount.add(data);

        return response;
    };

    const updateBankAccount = async (data: BankAccountProps) => {
        const { services } = client();
        const response = await services.wallet.bankAccount.update(data);

        return response;
    };

    const loadBankAccount = async () => {
        const { services } = client();
        const response = await services.wallet.bankAccount.load();

        return response;
    };

    // * IUGU
    const loadBalances = async () => {
        const { services } = client();
        const response = await services.wallet.iugu.balances.load();
        setWalletInfo((prev) => prev = {
            ...prev,
            balances: {
                data: response?.response.payload ?
                    response?.response.payload : {},
                message: response?.response.message,
                isLoaded: !!(!response?.error),
            },
        });
        return response;
    };

    const loadMovements = async () => {
        const { services } = client();
        const response = await services.wallet.iugu.history.load.movements();
        return response;
    };

    const loadReceivables = async () => {
        const { services } = client();
        const response = await services.wallet.iugu.history.load.receivables();
        return response;
    };

    const loadBankTransfers = async () => {
        const { services } = client();
        const response = await services.wallet.iugu.history.load.bankTransfers();
        return response;
    };

    const wallet = {
        current: {
            load: loadCurrentData,
            data: currentData,
        },
        movementHistory: {
            data: movementHistory,
            load: loadMovementHistory,
        },
        bankAccount: {
            add: addBankAccount,
            update: updateBankAccount,
            load: loadBankAccount,
        },
        iugu: {
            balances: {
                load: loadBalances,
                list: walletIInfo.balances,
            },
            history: {
                load: {
                    movements: loadMovements,
                    receivables: loadReceivables,
                    bankTransfers: loadBankTransfers,
                },
            },
        },
    };

    return {
        wallet,
    };
};

const useWallet = () => {
    const context = React.useContext(WalletContext);
    return context;
};

export { useWallet, walletProvider };
