import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxBox: {
            padding: '0 0.9375rem',
            cursor: 'pointer',
        },
        sxLink: {
            padding: '1rem 0',
            textDecoration: 'none',
            textTransform: 'uppercase',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: '0.875rem',
            fontWeight: typography.fontWeightMedium,
            color: palette.dark,
        },
        sxBoxExpanded: {
            display: 'flex',
            flexDirection: 'column',
        },
        sxLinkExpanded: {
            padding: '0.75rem',
            textDecoration: 'none',
            fontWeight: typography.fontWeightMedium || 500,
            fontFamily: typography.fontNunito,
            color: palette.dark,
            borderBottom: `0.0625rem solid ${palette.clouds}`,
        },
    };
    return {
        ...css,
    };
};
