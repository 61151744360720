import React from 'react';
import { MenuItem } from '@components';
import { Box, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { useNavigate } from 'react-router-dom';
import { userIconsMenu } from '@static/user-menu';
import { useAuth, useUser } from '@hooks';

interface UserMenuListProps {
    _id: string;
    nome: string;
    descricao: string;
    url: string;
    action?: () => void;
}

interface UserMenuProps {
    menu: UserMenuListProps[];
}

const User = ({ menu }: UserMenuProps) => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const auth = useAuth();
    const { user } = useUser();

    const logout = async () => {
        await auth.logout();
        (document as any).rewardHub.logoutSession();
        (document as any).rewardHub.closeHub();
    };

    return (
        <React.Fragment>
            {menu.map(({ _id, descricao, nome, url }, i) => {
                return (
                    <MenuItem
                        key={`${nome}-${i}-${_id}`}
                        icon={{
                            path:
                                userIconsMenu.find(
                                    (icon) => icon.nome === nome)?.icon,
                            backgroundColor: palette.clouds,
                            size: 'tiny',
                        }}
                        onClick={() => navigate(`${url}${nome === 'Loja' ?
                            '/' + user.data?.minha_loja.url : ''}`)}
                        borderType={'bottom'}
                        title={{
                            text: descricao,
                            color: palette.dark,
                            size: 'medium',
                        }}
                        htmlFor={_id}>
                        <Box pl={2} pr={2}>
                            <I
                                style={{ fontSize: '1rem' }}
                                icon={
                                    ['far', 'chevron-right']}
                            />
                        </Box>
                    </MenuItem>
                );
            })}
            <MenuItem
                icon={{
                    path: ['far', 'right-from-bracket'],
                    backgroundColor: palette.clouds,
                    size: 'tiny',
                    color: palette.pomegarnate,
                }}
                onClick={logout}
                borderType={'none'}
                title={{
                    text: 'Sair',
                    color: palette.pomegarnate,
                    size: 'medium',
                }}
                htmlFor={'Sair'} >
                <Box pl={2} pr={2}>
                    <I
                        style={{ fontSize: '1rem' }}
                        icon={
                            ['far', 'chevron-right']}
                    />
                </Box>
            </MenuItem>
        </React.Fragment>
    );
};

export { User };
