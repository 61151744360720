import React from 'react';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormRecoveryPasswordEmail, ToggleStaticProps } from '@interfaces';
import { Snackbar } from '@components';

import { formSchema } from './yup';
import { sx } from './sx';
import { Form } from './styles';
import { useAuth } from '@hooks';

const RecoveryPasswordEmail: React.FC = () => {
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const { css } = sx();
    const { recoveryPassword } = useAuth();
    const [responseData, setResponseData] = React.useState({
        message: '',
        isLoading: false,
    });

    const { register, handleSubmit, formState: { errors } } =
        useForm<FormRecoveryPasswordEmail>({
            resolver: yupResolver(formSchema),
            mode: 'onBlur',
        });

    const onSubmit: SubmitHandler<FormRecoveryPasswordEmail> = async (data) => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });

        const response = await recoveryPassword({ email: data.email });
        if (!response?.error) {
            snackRef.current?.show();
            setResponseData((prev) => prev = {
                ...prev,
                message: response?.response.message,
                isLoading: false,
            });
        }
    };

    return (
        <Container
            maxWidth={'xs'}
            sx={{ ...css.sxContainer }}>
            <Box sx={{ ...css.sxBoxAccess }}>
                <Typography
                    variant='h2'
                    textAlign={'center'}
                    sx={{ ...css.sxAccessText }}
                >
                    Recuperar senha
                </Typography>
                <Typography
                    variant='caption'
                    textAlign={'center'}
                    sx={{ ...css.sxTypeText }}
                >
                    Forneça seu e-mail e enviaremos um link para definição
                    de nova senha. Não se esqueça de verificar a caixa de spam.
                </Typography>
            </Box>
            <Form
                onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    focused={true}
                    label={'E-mail'}
                    id="outlined-basic"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                    variant="outlined"
                    {...register('email', { required: true })} />

                <Box
                    width='100%'
                    justifyContent='center'>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type='submit'
                        className='MuiButton secondary'
                        disabled={responseData.isLoading}
                    >
                        Enviar {responseData.isLoading && (
                            <CircularProgress
                                size={14}
                                color='inherit'
                                sx={{ ml: 0.5 }} />)}
                    </Button>
                </Box>
            </Form>
            <Snackbar
                ref={snackRef}
                message={responseData.message}
                severity='success'
            />
        </Container>
    );
};

export { RecoveryPasswordEmail };
