import { styled, InputLabel, Box, TextareaAutosize } from '@mui/material';

export const FormControlSelect = styled(Box)({
    position: 'relative',
});

export const FormControlLabel = styled(InputLabel)(({ theme }) => ({
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
    fontSize: '12px !important',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.dark,
    left: 10,
    top: -8,
    zIndex: 1,
    padding: '0 4px',
}));

export const Textarea = styled(TextareaAutosize)(({ theme }) => ({
    ...{
        width: '100%',
        borderColor: theme.palette.inputBorderColor,
        borderRadius: '.25rem',
        fontSize: '1rem',
        fontFamily: theme.typography.fontFamily,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.dark,
        outline: 0,
        padding: '.875rem',
    },
    '&.error': {
        borderColor: theme.palette.pomegarnate,
    },
}));
