/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { ButtonInterest, ContentItem, NewsLetter } from '@components';

import { ImageSection1, ImageSection2 } from './styles';
import { sx } from './sx';
import Slider from 'react-slick';
import { settings } from './slider-settings';
import { contents } from '../../../api/services/contents';
import { ContentProps, ListContentsProps } from '@interfaces';
import { images } from '@utils';

import ContentSection1 from '@img/content-section1.png';
import NewsLetterImg from '@img/news-letter.png';
import NovoMundo from '@img/novo-mundo.png';

const LandingPage: React.FC = () => {
    const slider = React.useRef<any>(null);
    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx();

    const { discover } = contents;
    const [listContents, setListContents] = React.useState<ListContentsProps>({
        isLoaded: true,
        contents: [] as ContentProps[],
    });

    const loadContents = async () => {
        setListContents({
            isLoaded: false,
            contents: listContents.contents,
        });
        const response = await discover({
            type: '',
            idCategory: '',
            limit: 4,
        });

        if (!response?.error && response?.response.payload) {
            setListContents({
                isLoaded: true,
                contents: response.response.payload,
            });
        } else {
            setListContents({
                isLoaded: true,
                contents: [] as ContentProps[],
            });
        }
    };

    React.useEffect(() => {
        loadContents();
    }, []);

    return (
        <>
            <Container maxWidth='lg'>
                <Box sx={{ ...css.sxBoxSection1 }}>
                    <Box sx={{ ...css.sxBoxTextsSection1 }}>
                        <Typography
                            sx={{ ...css.sxTitleSection }}
                            variant='h1'>
                            conteúdos exclusivos <br /> para inspirar o seu vestir, seu decorar e o seu viver
                        </Typography>
                        {matches &&
                            <Box>
                                <ButtonInterest
                                    text={'VER TUDO'}
                                    margin={'0'}
                                    color={'white'}
                                    backgroundColor={'black'}
                                    padding={'0.625rem 5.3125rem'}
                                    onClick={() => navigate('/membros/descobrir')} />
                            </Box>
                        }
                    </Box>
                    <Box sx={{ ...css.sxBoxImageSection1 }}>
                        <ImageSection1
                            src={ContentSection1}
                            alt="" />
                    </Box>
                    {!matches &&
                        <Box sx={{ ...css.sxBoxButtonSection }}>
                            <ButtonInterest
                                text={'NOSSOS CONTEÚDOS'}
                                margin={'0'}
                                color={'white'}
                                backgroundColor={'black'}
                                padding={'0.625rem 3.9375rem'}
                                onClick={() => navigate('/membros/descobrir')} />
                        </Box>
                    }
                </Box>
            </Container>
            <Box sx={{ ...css.sxBoxSectionGray }}>
                <Container
                    sx={{ ...css.sxContainerSection2 }}
                    maxWidth='lg'>
                    {!matches &&
                        <Box sx={{ ...css.sxBoxButtonSection }}>
                            <ButtonInterest
                                text={'ACESSE AGORA'}
                                margin={'0'}
                                color={'white'}
                                backgroundColor={'black'}
                                padding={'0.625rem 5.3125rem'}
                                onClick={() => navigate('/membros/descobrir')} />
                        </Box>
                    }
                    <Box sx={{ ...css.sxBoxImageSection }}>
                        <ImageSection2
                            src={NovoMundo} alt="" />
                    </Box>
                    <Box sx={{ ...css.sxBoxTextsSection2 }}>
                        <Typography
                            sx={{ ...css.sxTitleSection }}
                            variant='h1'>
                            Novo mundo. <br /> Nosso mundo.
                        </Typography>
                        <Typography
                            sx={{ ...css.sxLegendSection }}>
                            Navegue pelo festival de conteúdo feito para celebrar o pré-lançamento do Mariaz.
                            Uma série de conversas com mulheres inspiradoras para despertar, ser cada vez mais autêntica
                            e fazer essa revolução acontecer
                        </Typography>
                        {matches &&
                            <Box>
                                <ButtonInterest
                                    text={'ACESSE AGORA'}
                                    margin={'0'}
                                    color={'white'}
                                    backgroundColor={'black'}
                                    padding={'0.625rem 5.3125rem'}
                                    onClick={() => navigate('/membros/descobrir')} />
                            </Box>
                        }
                    </Box>
                </Container>
            </Box>
            <Box sx={{ ...css.sxBoxSectionGray, backgroundColor: 'transparent' }}>
                <Container maxWidth='lg'>
                    <Typography
                        sx={{ ...css.sxTitleSection3, textAlign: 'center' }}
                        variant='h1'>
                        Nossos conteúdos
                    </Typography>
                    {listContents.contents.length >= 4 || !matches ?
                        <Slider
                            ref={slider} {...settings}
                            className='collections-slider'>
                            {listContents.contents.map((content, i) => (
                                <ContentItem
                                    slider
                                    key={content._id}
                                    link={'/membros/conteudo/' + content._id}
                                    srcImage={images.load(content.capa, 'server')}
                                    title={content.titulo}
                                    legend={content.legenda}
                                    tag={content.categoria ?
                                        content.categoria.nome :
                                        ''} />
                            ))}
                        </Slider> :
                        <Stack
                            direction={'row'}>
                            {listContents.contents.map((content, i) => (
                                <ContentItem
                                    slider
                                    key={content._id}
                                    link={'/membros/conteudo/' + content._id}
                                    srcImage={images.load(content.capa, 'server')}
                                    title={content.titulo}
                                    legend={content.legenda}
                                    tag={content.categoria ?
                                        content.categoria.nome :
                                        ''} />
                            ))}
                        </Stack>
                    }
                </Container>
            </Box>
            <Box sx={{ ...css.sxBoxSectionGray }}>
                <Container sx={{ ...css.sxContainerSection }}>
                    {matches &&
                        <Box sx={{ ...css.sxBoxImageSection }}>
                            <ImageSection2
                                src={NewsLetterImg} alt="" />
                        </Box>
                    }
                    <Box sx={{ ...css.sxBoxNewsLetter }}>
                        <Typography
                            sx={{ ...css.sxTitleSection }}
                            variant='h1'>
                            Fora das redes sociais, a conversa é muito mais interessante
                        </Typography>
                        <Typography
                            sx={{ ...css.sxLegendSection }}>
                            Receba nosso conteúdo em primeira mão e faça parte dessa revolução
                        </Typography>
                        <NewsLetter location='landing-page' />
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export { LandingPage };
