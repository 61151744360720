import { useTheme } from '@mui/material';

const sx = () => {
    const { typography, palette } = useTheme();
    const css = {
        row: {
            // borderTopWidth: typography.pxToRem(1),
            borderBottomWidth: typography.pxToRem(1),
            borderStyle: 'solid',
            borderColor: palette.clouds,
            pt: typography.pxToRem(15),
            pb: typography.pxToRem(15),
            pl: 3,
            pr: 3,
        },
    };

    return { css };
};

export { sx };
