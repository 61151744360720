import { LocalStorageProps } from '@interfaces';

export const localStorage = ({
    action,
    key,
    value,
}: LocalStorageProps) => {
    const get = window.localStorage.getItem(key);

    if (action === 'add') {
        if (!get && value) {
            return window.
                localStorage.
                setItem(key, value);
        }
    }

    if (action === 'remove') {
        return window.localStorage.removeItem(key);
    }

    if (action === 'get') {
        return !!window.localStorage.getItem(key);
    }
};
