import { FormControlSelectProps } from '@interfaces';
import Box from '@mui/material/Box';
import { FormControlLabel } from './styles';

const FormControlSelect = ({ label, id, children }: FormControlSelectProps) => {
    return (
        <Box position='relative'>
            <FormControlLabel htmlFor={id} >
                {label}
            </FormControlLabel>
            {children}
        </Box>

    );
};

export { FormControlSelect };
