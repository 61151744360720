import React from 'react';
import Box from '@mui/material/Box';
import { ImageProps } from '@interfaces';

import { sx } from './sx';
import { ImageStyled } from './styles';

const ProductImage: React.FC<ImageProps> = ({
    size, src, children, onClick,
}: ImageProps) => {
    const { sxBox } = sx();
    return (
        <Box onClick={onClick} sx={{
            ...sxBox.default,
            ...sxBox[size],
        }}>
            <ImageStyled src={src} alt="" />
            {children}
        </Box>
    );
};

export { ProductImage };
