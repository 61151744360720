import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ButtonInterest, FixedMenu, HeaderPage } from '@components';

import { sx } from './sx';

const LoginSuggestion: React.FC = () => {
    const { css } = sx();
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    return (
        <Container>
            <HeaderPage
                onClick={() => navigate('/')}
                icon={['far', 'arrow-left-long']}
                titleAlign={'default'}
                title={'Voltar para a home'} />
            <Container
                sx={{ ...css.sxContainer }}
                maxWidth='lg'>
                <Stack
                    spacing={1}
                    alignItems={'center'}>
                    <I
                        style={{ ...css.sxLockIcon }}
                        icon={['fas', 'lock']} />
                    <Typography sx={{ ...css.sxTitle }} variant='h2'>
                        Faça o seu login para poder acessar todos os<br />
                        <span> nossos conteúdos </span>
                    </Typography>
                    <ButtonInterest
                        text={'Cadastre-se agora'}
                        color={'#fff'}
                        margin={'1.875rem 0 0 0 !important'}
                        backgroundColor={'#010101'}
                        padding={'0.625rem 2.5rem'}
                        onClick={() => navigate('/login')} />
                </Stack>
                {!matches &&
                    <FixedMenu />}
            </Container>
        </Container>
    );
};

export { LoginSuggestion };
