/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import {
    AvatarGroup,
    BoxContent,
    HeaderPage,
    MuiDialog,
    Page,
    Price,
    Product,
    ProductStatusTimeline,
    ProductStatusTimelineItem,
    SmallTableColumn,
    Snackbar,
} from '@components';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { usePurchases } from '../../../../hooks/shopping/purchases';
import { PurchaseHistoryProps, PurchasesProps } from '@interfaces/Purchases';
import { addressFormat, formatNumberToPriceBRL, images, purchaseStatus } from '@utils';
import { PurchaseHistoryStatusType } from '@types';
import { SnackBarResponse, ToggleStaticProps } from '@interfaces';
import { InvoiceLink } from './styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useCheckout } from '../../../../hooks/shopping/checkout';

const DEFAULTS = {
    PURCHASE: {
        isLoaded: false,
        data: {} as PurchasesProps,
    },
    SELECTED_ORDER: {
        isLoaded: false,
        data: {} as PurchasesProps,
    },
};

const Details = () => {
    const { purchases } = usePurchases();
    const { checkout } = useCheckout();

    const { palette } = useTheme();
    const { css } = sx();
    const params = useParams();

    const navigate = useNavigate();

    const dialogCancelOrder = React.useRef<ToggleStaticProps>(null);
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const [purchaseItem, setPurchaseItem] = React.useState(DEFAULTS.PURCHASE);
    const [feedback, setFeedback] = React.useState<SnackBarResponse>({} as SnackBarResponse);
    const [isLoading, setIsloading] = React.useState(false);

    const findPurchase = async () => {
        const response = await checkout.order.find({ orderID: params.id as string });

        if (!(response?.error)) {
            setPurchaseItem((prev) => prev = {
                data: response?.response.payload,
                isLoaded: true,
            });
        } else {
            setPurchaseItem((prev) => prev = {
                data: {} as PurchasesProps,
                isLoaded: false,
            });
        }
    };

    const handleCancelOrderDialog = () => {
        dialogCancelOrder.current?.show();
    };

    const cancelOrder = async () => {
        setIsloading((prev) => prev = true);

        const response = await purchases.order.actions.cancel({ orderID: purchaseItem.data._id });
        if (!(response?.error)) {
            setFeedback({
                type: 'success',
                message: response?.response.message,
            });
            dialogCancelOrder.current?.hide();
            snackRef.current?.show();
            setTimeout(() => {
                navigate('/minhas-compras');
            }, 1500);
            setIsloading((prev) => prev = false);
        } else {
            setFeedback({
                type: 'info',
                message: response?.response.message,
            });
            dialogCancelOrder.current?.hide();
            snackRef.current?.show();
            setIsloading((prev) => prev = false);
        }
    };

    React.useEffect(() => {
        findPurchase();
    }, []);

    const findPurchaseStatus = (type: PurchaseHistoryStatusType) => {
        const result = {} as PurchaseHistoryProps;
        const response = purchaseItem.isLoaded ? purchaseItem.
            data.
            historico_status
            .find((item) => purchaseStatus(item.status as PurchaseHistoryStatusType)[type].status) :
            false;

        if (!!(response)) {
            const [date, hour] = response?.data.split('T') as string[];
            const dateFormated = date.split('-').reverse().join('-');
            const hourFormated = hour.replace('Z', '').split('.')[0];

            Object.assign(result, {
                status: !!(response),
                date: dateFormated,
                hour: hourFormated,
            });
        } else {
            Object.assign(result, {
                status: false,
                date: '',
                hour: '',
            });
        };

        return result;
    };

    const invoicePayment = {
        PIX: 'Ver Fatura PIX',
        BOLETO: 'Ver Boleto',
        CARTAO: '',
    };

    const paymentType = {
        PIX: {
            title: 'PIX',
            icon: ['fab', 'pix'] as IconProp,
        },
        BOLETO: {
            title: 'Boleto',
            icon: ['far', 'barcode'] as IconProp,
        },
        CARTAO: {
            title: 'Cartão de crédito',
            icon: ['far', 'credit-card'] as IconProp,
        },
    };

    return (
        <React.Fragment>
            <Page.Wrapper border position='fixed'>
                <HeaderPage
                    titleAlign={'default'}
                    icon={['far', 'chevron-left']}
                    title={'Minhas Compras'}
                    onClick={() => navigate('/minhas-compras')} />
                <Box sx={{
                    height: 'calc(100% - 50px)',
                    overflowY: 'auto',
                }}>
                    <Box sx={css.row}>
                        {purchaseItem.isLoaded ? (
                            <SmallTableColumn
                                title='Vendedor'
                                value={
                                    <AvatarGroup
                                        align='right'
                                        borderColor={palette.common.white}
                                        size='medium'
                                        src={purchaseItem.data.vendedor.imagens ?
                                            images.load(purchaseItem.data.vendedor.imagens.avatar, 'server') :
                                            ''}
                                        title={purchaseItem.data.vendedor.nome} />
                                } />
                        ) : ''}

                    </Box>
                    <Box sx={css.row}>
                        {purchaseItem.isLoaded ? (
                            <SmallTableColumn
                                title='Número do pedido'
                                value={purchaseItem.data._id} />
                        ) : ''}
                    </Box>
                    <Box sx={css.row}>
                        <SmallTableColumn
                            title='Status do pedido'
                            value={
                                <ProductStatusTimeline>
                                    {/* {history.map(({ _id, data, status }) => {
                                            const current = handleStatus(status as PurchaseHistoryStatusType)[status as PurchaseHistoryStatusType];
                                            return (
                                                <ProductStatusTimelineItem
                                                    key={_id}
                                                    title={current.title}
                                                    status='etiqueta_gerada'
                                                    active={current.status}
                                                />
                                            );
                                        })} */}
                                    <ProductStatusTimelineItem
                                        title='Entregue'
                                        status='etiqueta_gerada'
                                        date={findPurchaseStatus('FINALIZADO').date}
                                        hour={findPurchaseStatus('FINALIZADO').hour}
                                        active={findPurchaseStatus('FINALIZADO').status}
                                    />
                                    <ProductStatusTimelineItem
                                        title='Enviado'
                                        status='etiqueta_gerada'
                                        date={findPurchaseStatus('POSTADO').date}
                                        hour={findPurchaseStatus('POSTADO').hour}
                                        active={findPurchaseStatus('POSTADO').status}
                                    />
                                    <ProductStatusTimelineItem
                                        title='Aguardando Envio'
                                        status='etiqueta_gerada'
                                        date={findPurchaseStatus('ETIQUETA_GERADA').date}
                                        hour={findPurchaseStatus('ETIQUETA_GERADA').hour}
                                        active={findPurchaseStatus('ETIQUETA_GERADA').status}
                                    />
                                    <ProductStatusTimelineItem
                                        title='Pago'
                                        status='etiqueta_gerada'
                                        date={findPurchaseStatus('PAGO').date}
                                        hour={findPurchaseStatus('PAGO').hour}
                                        active={findPurchaseStatus('PAGO').status}
                                    />
                                    <ProductStatusTimelineItem
                                        title='Aguardando Pagamento'
                                        status='etiqueta_gerada'
                                        date={findPurchaseStatus('AGUARDANDO_PAGAMENTO').date}
                                        hour={findPurchaseStatus('AGUARDANDO_PAGAMENTO').hour}
                                        active={findPurchaseStatus('AGUARDANDO_PAGAMENTO').status}
                                    />
                                </ProductStatusTimeline>
                            } />
                    </Box>
                    {purchaseItem.isLoaded ? (
                        <Box sx={css.row}>
                            <SmallTableColumn
                                title='Total dos produtos'
                                value={<Price size='small'
                                    value={formatNumberToPriceBRL(purchaseItem.data.total_parcial)}
                                />} />
                        </Box>
                    ) : ''}
                    {purchaseItem.isLoaded ? (
                        <Box sx={css.row}>
                            <SmallTableColumn
                                title='Forma de pagamento'
                                value={[paymentType[purchaseItem.data.pagamento.tipo].title,
                                    [<I icon={paymentType[purchaseItem.data.pagamento.tipo].icon} />]]} />
                        </Box>) : ''}

                    {purchaseItem.isLoaded ? (
                        purchaseItem.data.status_atual === 'AGUARDANDO_PAGAMENTO' && (
                            (purchaseItem.data.pagamento.tipo === 'PIX') ||
                                (purchaseItem.data.pagamento.tipo === 'BOLETO') ? (
                                    <Box sx={css.row}>
                                        <SmallTableColumn
                                            title='Fatura'
                                            value={<InvoiceLink href={purchaseItem.data.pagamento.informacoes.url_fatura} target='_blank'>
                                                {invoicePayment[purchaseItem.data.pagamento.tipo]}
                                                <I icon={['far', 'share']} style={{ marginLeft: '.3125rem' }} />
                                            </InvoiceLink>} />
                                    </Box>) : '')) : ''}
                    <Box sx={css.row}>
                        {purchaseItem.isLoaded ? (
                            <SmallTableColumn
                                title='Frete'
                                value={
                                    <Stack direction='row'>
                                        <Price size='small'
                                            value={formatNumberToPriceBRL(purchaseItem.data.valor_frete)}
                                        />
                                        <Typography
                                            component='p'
                                            sx={css.shipping.title}>
                                            <Box sx={css.shipping.icon}>
                                                <I icon={['fal', 'truck']} />
                                            </Box>
                                            Correios
                                        </Typography>
                                    </Stack>
                                } />
                        ) : ''}
                    </Box>
                    <Box sx={css.row}>
                        {purchaseItem.isLoaded ? (
                            <SmallTableColumn
                                title='Total em reais'
                                value={<Price size='small'
                                    value={formatNumberToPriceBRL(purchaseItem.data.total)}
                                />} />
                        ) : ''}
                    </Box>
                    <Box sx={css.row}>
                        {purchaseItem.isLoaded ? (
                            <SmallTableColumn
                                title='Endereço da entrega'
                                value={addressFormat({
                                    cep: purchaseItem.data.endereco_entrega.cep,
                                    logradouro: purchaseItem.data.endereco_entrega.logradouro,
                                    bairro: purchaseItem.data.endereco_entrega.bairro,
                                    cidade: purchaseItem.data.endereco_entrega.cidade,
                                    estado: purchaseItem.data.endereco_entrega.estado,
                                    numero: Number(purchaseItem.data.endereco_entrega.numero),
                                    complemento: purchaseItem.data.endereco_entrega.complemento,
                                })} />
                        ) : ''}
                    </Box>
                    {purchaseItem.isLoaded ? (
                        purchaseItem.data.status_atual === 'AGUARDANDO_PAGAMENTO' && (

                            <Box sx={css.row}>
                                <Typography
                                    variant='h2'
                                    component='p'
                                    textTransform='uppercase'
                                    sx={css.texts.actions}>
                                    Ações
                                </Typography>
                                <SmallTableColumn
                                    title='Pedido'
                                    value={
                                        <Button
                                            variant="contained"
                                            size='small'
                                            className='MuiButton secondary'
                                            onClick={() => handleCancelOrderDialog()}>
                                            Cancelar pedido
                                        </Button>
                                    } />
                            </Box>)
                    ) : ''}
                    {purchaseItem.isLoaded ? (
                        purchaseItem.data.status_atual === 'PAGO' && (

                            <Box sx={css.row}>
                                <Typography
                                    variant='h2'
                                    component='p'
                                    textTransform='uppercase'
                                    sx={css.texts.actions}>
                                    Ações
                                </Typography>
                                <SmallTableColumn
                                    title='Pedido'
                                    value={
                                        <Button
                                            variant="contained"
                                            size='small'
                                            className='MuiButton secondary'
                                            onClick={() => handleCancelOrderDialog()}>
                                            Cancelar pedido
                                        </Button>
                                    } />
                            </Box>)
                    ) : ''}
                    <Box sx={{
                        ...css.row,
                        pb: 0,
                        borderBottom: 0,
                    }}>
                        <SmallTableColumn
                            title='Produtos'
                            value={
                                <Box>
                                    {purchaseItem.isLoaded ?
                                        (
                                            purchaseItem.data.itens.map((product, i, list) => (
                                                <BoxContent
                                                    key={product._id}
                                                    borderBottom={i + 1 === list.length ? false : true}
                                                    actions={[
                                                        {
                                                            actionType: 'goTo',
                                                            actionFunction:
                                                                () => navigate('/produto/' + product.id.id_anuncio._id + '?variantion=' + product.id._id),
                                                        }]} >
                                                    <Product
                                                        size={'small'}
                                                        _id={product.id._id}
                                                        align={'right'}
                                                        to={() => navigate('/produto/' + product.id.id_anuncio._id + '?variantion=' + product.id._id)}
                                                        contentAlign='center'
                                                        src={images.load(product.id.fotos[0], 'server')}
                                                        title={product.id.id_anuncio ? product.id.id_anuncio.titulo && product.id.id_anuncio.titulo : product.id.titulo}
                                                    />
                                                </BoxContent>
                                            ))
                                        ) : 'Carregando itens'}
                                </Box>
                            } />
                    </Box>
                </Box>
            </Page.Wrapper>
            <MuiDialog
                maxWidth='xs'
                ref={dialogCancelOrder}
                contentPadding={false}
                title='Cancelar Pedido?'
                showDialogTitle
                onConfirm={{
                    action: async () => await cancelOrder(),
                    text: ['Confirmar', isLoading ?
                        <Box ml={0.5} mt={0.4}>
                            <CircularProgress
                                size={14}
                                color='inherit' /></Box> : ''],
                }}>
                <Box pt={2} pb={2} pl={1} pr={1}>
                    <Typography
                        component='p'
                        sx={css.shipping.title}>
                        <ul style={css.shipping.ulList}>
                            <li>Para compras realizadas no cartão de crédito: o cancelamento e reembolso do valor acontece de forma imediata</li>
                            <li>Para compras realizadas no PIX ou Boleto que ainda não foram pagas: o cancelamento acontece de forma imediata</li>
                            <li>Para compras realizadas no PIX ou Boleto que já foram pagas: o cancelamento acontece de forma imediata e você receberá um email com instruções para solicitação do reembolso</li>
                        </ul>
                    </Typography>
                </Box>
            </MuiDialog>
            <Snackbar
                ref={snackRef}
                severity={feedback?.type as any}
                message={feedback?.message}
            />
        </React.Fragment>
    );
};
export { Details };
