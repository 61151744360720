import React from 'react';
import $ from 'jquery';
import 'jquery-mask-plugin';

import { HeaderPage, Page } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUser } from '@hooks';
import { ErrorsProps, ProfileForm, ToggleStaticProps } from '@interfaces';
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { Container } from '@mui/system';
import { Snackbar } from '@components';
import { formatPhoneNumber } from '@utils';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form, RegionIcon } from './styles';
import { formSchema } from './yup';
import { sx } from './sx';
import { maskCellphone } from '@static/region-telephone';

type RegionNameType = 'BR' | 'USA' | 'IT';

const Personal = () => {
    const { css } = sx();
    const { typography } = useTheme();
    const navigate = useNavigate();
    const { user, updateUser, loadUser } = useUser();
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
        isLoading: false,
    });
    const [personalErrors, setPersonalErrors] = React.useState<ErrorsProps>({
        listErrors: {
            error: false,
            location: '',
            msg: '',
            param: '',
            value: '',
        },
    });
    const [region] = React.useState({
        name: 'BR',
        // eslint-disable-next-line max-len
        flag: 'https://images.emojiterra.com/google/noto-emoji/unicode-15/color/svg/1f1e7-1f1f7.svg',
        code: '+55',
    });

    const keys = Object.keys(personalErrors);

    const { register, handleSubmit, formState: { errors } } =
        useForm<ProfileForm>({
            resolver: yupResolver(formSchema),
            mode: 'onBlur',
        });


    const onSubmit: SubmitHandler<ProfileForm> = async (data) => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });

        const response = await updateUser({
            ...data,
            celular: `${region.code} ${data.celular}`,
        });
        if (!response.error) {
            loadUser();
            snackRef.current?.show();
            setResponseData((prev) => prev = {
                ...prev,
                type: response?.response.type,
                message: response?.response.message,
                isLoading: false,
            });
            setPersonalErrors({
                listErrors: {
                    error: false,
                    location: '',
                    msg: '',
                    param: '',
                    value: '',
                },
            });

            // eslint-disable-next-line max-len
            setTimeout(() => navigate('/meu-perfil'), 2000);
        } else {
            if (response.error && response.response.errors) {
                if (response.response.errors.errors) {
                    setResponseData((prev) => prev = {
                        ...prev,
                        isLoading: false,
                    });
                    const filterErrors = response.response.errors.errors.
                        map((err: any, i: number) => {
                            const object = {
                                [err.param]: {
                                    location: err.location,
                                    msg: err.msg,
                                    param: err.param,
                                    error: true,
                                },
                            };

                            if (err.param !== keys[i]) {
                                return (object);
                            }

                            return (object);
                        });

                    const reduceErrors = filterErrors.reduce(
                        (acc: any, current: any) => ({
                            ...acc,
                            ...current,
                        }),
                        {},
                    );
                    setPersonalErrors((prev) => prev = reduceErrors);
                }
            } else {
                snackRef.current?.show();
                setResponseData((prev) => prev = {
                    ...prev,
                    type: response?.response.type,
                    message: response?.response.message,
                    isLoading: false,
                });
            }
        }
    };

    const inputMasks = () => {
        $('.cpf').mask('000.000.000-00');
        $('.cellphone').mask(maskCellphone['BR']);
    };

    React.useEffect(() => {
        inputMasks();
    }, [region]);

    return (
        <Page.Wrapper border position='fixed'>
            <HeaderPage
                // eslint-disable-next-line max-len
                onClick={() => navigate('/meu-perfil')}
                titleAlign={'default'}
                title={'Dados Pessoais'}
                icon={['far', 'chevron-left']}
            />
            <Page.Content paddingBottom>
                <Box sx={{
                    padding: '1.5rem 0 0 0',
                }}>
                    <Container maxWidth='lg'>
                        {user.data &&
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Box sx={{
                                    '&:first-child .MuiTextField-root': { mb: 2 },
                                }}>

                                    <TextField
                                        focused
                                        fullWidth
                                        label={'Nome'}
                                        defaultValue={user.data.nome}
                                        id="outlined-basic"
                                        variant="outlined"
                                        error={
                                            (personalErrors.hasOwnProperty('nome') &&
                                                !!personalErrors.nome.error) ||
                                            !!errors.nome}
                                        helperText={
                                            (personalErrors.hasOwnProperty('nome') &&
                                                personalErrors.nome.msg) ||
                                            (!!errors.nome?.message &&
                                                errors.nome.message)}
                                        {...register('nome', { required: true })} />

                                    <TextField
                                        focused
                                        fullWidth
                                        disabled
                                        defaultValue={user.data.email}
                                        label={'Email'}
                                        id="outlined-basic"
                                        variant="outlined" />

                                    {user.data.cpf &&
                                        <TextField
                                            focused
                                            fullWidth
                                            disabled
                                            defaultValue={user.data.cpf}
                                            label={'CPF'}
                                            id="outlined-basic"
                                            variant="outlined"
                                            inputProps={{
                                                className: 'cpf',
                                            }} />
                                    }
                                    <TextField
                                        focused={true}
                                        fullWidth
                                        label={'Telefone'}
                                        defaultValue={
                                            user.data.telefone ?
                                                formatPhoneNumber({
                                                    ddd: user.data.telefone.ddd,
                                                    numero: user.data.telefone.numero,
                                                }) : ''}
                                        placeholder={
                                            maskCellphone[region.name as RegionNameType]}
                                        id="outlined-basic"
                                        variant="outlined"
                                        error={
                                            (personalErrors.hasOwnProperty('celular') &&
                                                !!personalErrors.celular.error) ||
                                            !!errors.celular}
                                        helperText={
                                            (personalErrors.hasOwnProperty('celular') &&
                                                personalErrors.celular.msg) ||
                                            (!!errors.celular?.message &&
                                                errors.celular.message)}
                                        inputProps={{
                                            className: 'cellphone',
                                        }}
                                        sx={{
                                            '& > .MuiInputBase-root': {
                                                paddingLeft: 0,
                                            },
                                        }}
                                        InputProps={{
                                            startAdornment: (
                                                <Button
                                                    sx={{
                                                        ...css.sxBtRegion,
                                                        ...css.sxButtonRegionSelected }}>
                                                    <RegionIcon
                                                        src={region.flag}
                                                        alt={region.name} /> {region.code}
                                                </Button>
                                            ),
                                        }}
                                        {...register('celular', { required: false })} />

                                    <Table aria-label="simple table">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align="left">
                                                    <Typography
                                                        variant="subtitle1"
                                                        component='span'
                                                        sx={{
                                                            fontSize: '.875rem',
                                                            fontWeight: typography.
                                                                fontWeightBold,
                                                        }}>
                                                        Senha
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="left">
                                                    *************
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Button
                                                        variant="text"
                                                        sx={{ ...css.sxUpdatePassButton }}
                                                        onClick={
                                                            () => navigate(
                                                                '/meu-perfil/alterar/' +
                                                                'dados-pessoais/' +
                                                                'senha')
                                                        }>
                                                        Alterar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>

                                </Box>
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth
                                    type='submit'
                                    className='MuiButton primary'
                                >
                                    Salvar alterações {responseData.isLoading && (
                                        <CircularProgress
                                            size={14}
                                            color='inherit'
                                            sx={{ ml: 0.5 }} />)}
                                </Button>
                            </Form>
                        }
                    </Container>
                </Box>
                <Snackbar
                    ref={snackRef}
                    message={responseData.message}
                    severity={responseData.type}
                />
            </Page.Content>
        </Page.Wrapper>
    );
};

export { Personal };
