import React from 'react';
import {
    BoxContent,
    ButtonInterest,
    FixedMenu,
    HeaderPage,
    MessageBox,
    MuiDialog,
    Page,
    Product,
    Snackbar,
} from '@components';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { FavoriteProps, ToggleStaticProps } from '@interfaces';
import { useUser } from '@hooks';
import { images } from '@utils';
import ProductPlaceholder from '@img/product-placeholder.png';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../../hooks/shopping/cart';

const Favorites = () => {
    const { cart } = useCart();

    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const { palette } = useTheme();
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const dialogConfirm = React.useRef<ToggleStaticProps>(null);
    const { favorites } = useUser();
    const [limit, setLimit] = React.useState<number>(8);
    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();
    const [favorite, setFavorite] = React.useState<FavoriteProps>({} as FavoriteProps);

    const deleteFavorite = async (id: string) => {
        const response = await favorites.delete(id);
        if (response?.error) {
            setFeedback({
                message: response.response
                    .message, type: response.response.type,
            });
            snackRef.current?.show();
            return;
        };

        dialogConfirm.current?.hide();

        setFeedback({
            message: response?.response
                .message, type: response?.response.type,
        });
        snackRef.current?.show();

        favorites.load({
            limit: limit,
        });
    };

    const handleSelectFavorite = async (favorite: FavoriteProps) => {
        setFavorite((prev) => prev = favorite);
        dialogConfirm.current?.show();
    };

    const addToCart = async (idVariation: string, variationItemId: string) => {
        const object = {
            quantity: 1,
            idVariation: idVariation,
            variationItemId: variationItemId,
        };
        const response = await cart.item.add({ ...object });
        if (!response?.error) {
            setFeedback((prev) => prev = {
                type: 'success',
                message: response?.response.message,
            });
            snackRef.current?.show();
            await cart.load();
        } else {
            setFeedback((prev) => prev = {
                type: 'info',
                message: response.response.message,
            });
            snackRef.current?.show();
        }
    };

    React.useEffect(() => {
        favorites.load({
            limit: limit,
        });
    }, [limit]);

    return (
        <Page.Wrapper border position='relative'>
            <Box>
                <HeaderPage
                    titleAlign={'default'}
                    title={'Meus Favoritos'}
                    icon={['far', 'heart']}
                />

                <Box>
                    {favorites.list.data ?
                        favorites.list.data.length > 0 &&
                        favorites.list.data.map((favorite) => {
                            return (
                                <BoxContent
                                    borderBottom
                                    paddingLeft
                                    actions={[
                                        {
                                            actionType: 'addToCart',
                                            actionFunction:
                                                // eslint-disable-next-line max-len
                                                () => addToCart(favorite.variacao._id, favorite.variacao.itens._id),
                                        },
                                        {
                                            actionType: 'delete',
                                            actionFunction:
                                                () => handleSelectFavorite(favorite),
                                        },
                                    ]}>
                                    <Product
                                        // eslint-disable-next-line max-len
                                        to={() => navigate('/produto/' + favorite._id + '?variantion=' + favorite.variacao._id)}
                                        _id={favorite._id}
                                        size={'big'}
                                        align={'right'}
                                        src={favorite?.fotos ?
                                            // eslint-disable-next-line max-len
                                            images.load(favorite?.variacao.fotos[0], 'server') :
                                            images.load(ProductPlaceholder, 'local')}
                                        // eslint-disable-next-line max-len
                                        title={`${favorite.titulo} - ${favorite.variacao.nome_cor}`}
                                        legend={favorite.usuario.minha_loja ?
                                            favorite.usuario.minha_loja.titulo :
                                            ''}
                                        // eslint-disable-next-line max-len
                                        price={String(favorite.variacao.itens.valor_por)} />
                                </BoxContent>
                            );
                        }) : (
                            <MessageBox
                                type='info'
                                // eslint-disable-next-line max-len
                                message='Você ainda não salvou nenhum produto como favorito.'
                            />
                        )}
                    {favorites.list.data && favorites.list.data.length > 0 &&
                        limit <= favorites.list.total &&
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <ButtonInterest
                                text={'CARREGAR MAIS'}
                                margin={'0.9375rem 0'}
                                color={palette.common.white}
                                backgroundColor={palette.dark}
                                padding={'0.5rem 1.5625rem'}
                                onClick={() => setLimit(limit + 8)} />
                        </Box>
                    }
                </Box>

            </Box>
            <MuiDialog
                maxWidth='xs'
                ref={dialogConfirm}
                contentPadding={false}
                title='Deletar Favorito?'
                showDialogTitle
                onConfirm={{
                    action: () => deleteFavorite(favorite.idFavorito),
                    text: 'Confirmar',
                }}>
                <Box
                    sx={{
                        'pl': '15px',
                        '&:last-child .box-content': {
                            borderBottom: 0,
                        },
                    }}>
                    <BoxContent>
                        <Product
                            size={'big'}
                            align={'right'}
                            src={favorite?.fotos ?
                                images.load(favorite?.variacao.fotos[0], 'server') :
                                images.load(ProductPlaceholder, 'local')}
                            // eslint-disable-next-line max-len
                            title={favorite.variacao ? `${favorite.titulo} - ${favorite.variacao.nome_cor}` : `${favorite.titulo}`}
                            legend={Object.keys(favorite).length > 0 ?
                                favorite.usuario.minha_loja ?
                                    favorite.usuario.minha_loja.titulo :
                                    '' : ''}
                            price={String(favorite.valor_por)} />
                    </BoxContent>
                </Box>
            </MuiDialog>
            <Snackbar
                ref={snackRef}
                message={feedback ? feedback.message : ''}
                severity={feedback ? feedback.type as any : 'success'}
            />
            {!matches &&
                <FixedMenu />
            }
        </Page.Wrapper>
    );
};

export { Favorites };
