import { useTheme, useMediaQuery } from '@mui/material';

export const sx = () => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            maxHeight: '445px',
            minHeight: '180px',
            height: '100%',
        },
        sxTitle: {
            paddingTop: '10px',
            fontWeight: typography.fontWeightMedium || 500,
            fontSize: matches ? '14px' : '12px',
            textTransform: 'uppercase',
            color: palette.dark,
        },
    };

    return { css };
};
