import * as api from './calls';

const wallet = {
    find: {
        wallet: api.findWallet,
        movementHistory: api.findMovementHistory,
    },
    bankAccount: {
        add: api.addBankAccount,
        update: api.updateBankAccount,
        load: api.loadBankAccount,
    },
    iugu: {
        balances: {
            load: api.loadBalances,
        },
        history: {
            load: {
                movements: api.loadMovements,
                receivables: api.loadReceivables,
                bankTransfers: api.loadBankTransfers,
            },
        },
    },
};

export {
    wallet,
};
