import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faEye,
    faCheck,
    faChevronRight,
    faChevronLeft,
    faPlus,
    faBagShopping,
    faCreditCard,
    faPenToSquare,
    faCircleQuestion,
    faUser,
    faMagnifyingGlass,
    faBullhorn,
    faCartShopping,
    faCreditCardBlank,
    faStore,
    faIdCard,
    faWallet,
    faRightFromBracket,
    faGear,
    faHeart as heartRegular,
    faBarsStaggered,
    faArrowRightLong,
    faShareNodes,
    faXmark,
    faArrowLeftLong,
    faMegaphone,
    faTrashCan,
    faMapLocationDot,
    faChevronDown,
    faChevronUp,
    faSliders,
    faArrowDownLeftAndArrowUpRightToCenter,
    faArrowLeftRotate,
    faArrowsLeftRightToLine,
    faArrowsLeftRight,
    faTentArrowLeftRight,
    faBuildingColumns,
    faCalendarStar as faCalendarStarReg,
    faCalendarDays as faCalendarDaysReg,
    faTriangleExclamation,
    faCircleInfo as faCircleInfoReg,
    faCircleCheck,
    faCoins,
    faBarcode,
    faClock as faClockRegular,
    faCirclePlus,
    faGlasses,
    faClothesHanger,
    faMinus as faMinusRegular,
    faRulerCombined,
    faLink,
    faTicket,
    faTruck as faTruckReg,
    faShare,
} from '@fortawesome/pro-regular-svg-icons';

import {
    faSquare,
    faSquareCheck,
} from '@fortawesome/pro-thin-svg-icons';

import {
    faSquarePlus,
    faCircleExclamation,
    faTrashCan as faTrashCanLight,
    faHeart,
    faPowerOff,
    faPenToSquare as penToSquareLight,
    faBagShopping as bagShoppingLight,
    faPlus as plusLight,
    faMinus,
    faMessageDots,
    faCircleInfo,
    faTruck,
    faHandHoldingBox,
    faMagnifyingGlass as magnifyingGlassLight,
    faHouse as houseLight,
    faCamera as faCameraLight,
    faTicketSimple,
    faCheck as faCheckLigth,
    faCreditCardFront,
    faClock,
    faUser as faUserLight,
    faArrowLeftLong as arrowLLong,
    faShareNodes as shareNodesLight,
    faCompass,
    faSquareList,
    faCartShopping as cartShoppingLight,
    faUserMagnifyingGlass,
    faCirclePlus as circlePlusLight,
    faBullhorn as bullhornLight,
    faStore as storeLight,
    faCheckCircle,
    faBasketShopping,
    faCircleQuestion as faCircleQuestionLight,
} from '@fortawesome/pro-light-svg-icons';

import {
    faCcMastercard,
    faSquareInstagram,
    faWhatsapp,
    faFacebookF,
    faFacebook,
    faTwitterSquare,
    faCcVisa,
    faCcAmex,
    faPix,
} from '@fortawesome/free-brands-svg-icons';

import {
    faEye as faEyeSolid,
    faEyeSlash,
    faLocationDot,
    faHeart as faHeartSolid,
    faSortDown,
    faMessages,
    faBagShopping as bagShoppingSolid,
    faChevronRight as faChevronRightSolid,
    faChevronLeft as faChevronLeftSolid,
    faHouse,
    faCamera,
    faShareNodes as shareNodesRegular,
    faMagnifyingGlass as magnifyingGlassSolid,
    faUser as faUserSolid,
    faCalendarStar,
    faCalendarDays,
    faLock,
    faCheck as faCheckSolid,
    faEllipsisV,
    faBookmark,
    faCircle,
    faWallet as faWalletSolid,
    faAddressCard,
    faBullhorn as bullhornSolid,
    faStore as storeSolid,
    faSquare as faSquareSolid,
    faCloudArrowUp,
    faXmarkCircle,
    faXmark as faXmarkSolid,
    faCircleUser,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
    faCircleUser,
    faShare,
    faTicket,
    faBasketShopping,
    faLink,
    faTwitterSquare,
    faCheckCircle,
    faCloudArrowUp,
    faSquareSolid,
    faClothesHanger,
    faClockRegular,
    faClock,
    faBarcode,
    faPix,
    faCreditCardFront,
    faCheckLigth,
    faTicketSimple,
    faCircle,
    faCircleCheck,
    faTriangleExclamation,
    faBookmark,
    faEllipsisV,
    faBuildingColumns,
    faTentArrowLeftRight,
    faArrowDownLeftAndArrowUpRightToCenter,
    faArrowsLeftRightToLine,
    faArrowLeftRotate,
    faArrowsLeftRight,
    faSquare,
    faSquareCheck,
    faSliders,
    faChevronDown,
    faChevronUp,
    faMapLocationDot,
    faLock,
    faCalendarStarReg,
    faCalendarDaysReg,
    faCalendarStar,
    faCalendarDays,
    faMegaphone,
    faCamera,
    faCameraLight,
    faCheck,
    faCheckSolid,
    faUserLight,
    faEye,
    faHouse,
    faChevronRightSolid,
    faChevronRight,
    faChevronLeftSolid,
    faChevronLeft,
    faPlus,
    faSquarePlus,
    faLocationDot,
    faCircleExclamation,
    faHeart,
    faHeartSolid,
    faBagShopping,
    faCreditCard,
    faPenToSquare,
    faCcMastercard,
    faTrashCan,
    faTrashCanLight,
    penToSquareLight,
    faPowerOff,
    faMagnifyingGlass,
    bagShoppingLight,
    faCircleQuestion,
    faCircleQuestionLight,
    faUser,
    faUserSolid,
    faSortDown,
    faSquareInstagram,
    plusLight,
    faMinus,
    faBullhorn,
    faCartShopping,
    faCreditCardBlank,
    faStore,
    faIdCard,
    faWallet,
    faWalletSolid,
    faRightFromBracket,
    faGear,
    bagShoppingSolid,
    heartRegular,
    faBarsStaggered,
    faArrowRightLong,
    faEyeSolid,
    faFacebookF,
    faFacebook,
    faMessageDots,
    faShareNodes,
    shareNodesRegular,
    faCircleInfo,
    faCircleInfoReg,
    faTruck,
    faTruckReg,
    faHandHoldingBox,
    faEyeSlash,
    faXmark,
    faXmarkCircle,
    faXmarkSolid,
    faCcVisa,
    magnifyingGlassLight,
    magnifyingGlassSolid,
    houseLight,
    faArrowLeftLong,
    faCoins,
    faAddressCard,
    arrowLLong,
    shareNodesLight,
    faCompass,
    faSquareList,
    cartShoppingLight,
    faUserMagnifyingGlass,
    faCirclePlus,
    circlePlusLight,
    faGlasses,
    bullhornSolid,
    bullhornLight,
    storeLight,
    storeSolid,
    faMinusRegular,
    faRulerCombined,
    faWhatsapp,
    faMessages,
    faCcAmex,
);
