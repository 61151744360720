/* eslint-disable max-len */
import React from 'react';
import { TextsLayout } from '../texts-layout';
import { Typography } from '@mui/material';

const CodeOfEthics: React.FC = () => {
    return (
        <TextsLayout>
            <Typography
                textAlign={'center'}
                fontSize={'1.375rem !important'}
                variant='h1'>
                CÓDIGO DE CONDUTA E ÉTICA – MARIAZ
            </Typography>
            <Typography variant='body1'>
                O MARIAZ atua na prestação de serviço de intermediação através de sua plataforma na Rede Mundial de Computadores (“Internet”), pela qual fornece espaço virtual para marcas e compradores cadastrados realizarem compra e venda de produtos diretamente entre si.
            </Typography>
            <Typography variant='body1'>
                O Código de Conduta e Ética do MARIAZ tem como objetivo orientar a conduta pessoal e profissional dos sócios, colaboradores, parceiros, prestadores de serviços e terceiros, de forma criar um ambiente de negócios pautado pela harmonia, honestidade e lealdade, observando as seguintes diretrizes:
            </Typography>
            <Typography variant='h1'>
                1. PRINCÍPIOS GERAIS
            </Typography>
            <Typography variant='body1'>
                Os valores éticos que norteiam a atuação do MARIAZ, seus parceiros e colaboradores, são baseados nos princípios da verdade, transparência, justiça, visão humana e respeito à diversidade étnica, socioeconômica, cultural, religiosa e sexual, sendo o MARIAZ comprometido com a qualidade de vida de seus parceiros, colaboradores, de suas famílias, da comunidade e da sociedade como um todo. Quanto às normas de conduta que devem ser observadas, cumpridas e respeitadas por todos os parceiros e colaboradores, estão as leis do país; os contratos, acordos e parcerias firmadas pelo MARIAZ; a preservação das informações de propriedade do MARIAZ e sua confidencialidade, dentre outras. O MARIAZ acredita que cada indivíduo é importante para garantir a satisfação do cliente, a capacidade de inovação e o resultado final, constituindo um dos principais pilares de seu sucesso e crescimento.
            </Typography>
            <Typography variant='h1'>
                2. PERFIL DO MARIAZ
            </Typography>
            <Typography variant='body2'>
                <ul>
                    <li>Trabalhamos apenas com marcas independentes lideradas por mulheres. Ou seja, marcas que são empresas pertencentes, dirigidas ou gerenciadas pela designer ou sua família e onde a mulher ocupa o posto de liderança criativa, liderança de negócios, ou ambas;</li>
                    <li>As coleções são produzidas localmente em pequenas séries de produção, por artesãos ou pela própria designer, sempre que possível;</li>
                    <li>Incentivamos uma cadeia de suprimentos justa e transparente, garantindo que nossos fornecedores e parceiros não usem fábricas clandestinas, com utilização de trabalho infantil ou forçado;</li>
                    <li>Os produtos oferecidos na plataforma MARIAZ devem ser produzidos com matérias-primas legais, de origem sustentável e em nenhuma hipótese com materiais provenientes de tráfico ilegal, desmatamento ou exploração de animais. Incentivamos nossos parceiros e fornecedores a usarem, sempre que possível, sobras ou materiais reciclados.</li>
                    <li>Incentivamos todos os nossos parceiros e fornecedores de produtos de bem-estar e beleza a usarem ingredientes naturais, à base de plantas e não sintéticos, sempre que possível, bem como que não utilizem animais para teste de seus produtos. </li>
                    <li>Todos os produtos oferecidos pelos parceiros em nossa plataforma são únicos, originais e de propriedade de cada marca parceira. Todos os itens são fabricados pela designer ou sob sua orientação e produzidos especificamente por elas, com rigorosos padrões de qualidade. </li>
                </ul>
            </Typography>
            <Typography variant='h1'>3. Normas de conduta</Typography>
            <Typography variant='h2'>3.1. Relações com Órgãos Governamentais</Typography>
            <Typography variant='body2'>
                Deve haver rigoroso cumprimento dos requisitos legais, fiscais e trabalhistas estabelecidos pelos órgãos competentes e reguladores, por meio do cumprimento da legislação aplicável e pagamento das obrigações tributárias e trabalhistas competentes.
            </Typography>
            <Typography variant='h2'>3.2. Relações com a Imprensa</Typography>
            <Typography variant='body2'>
                Somente pessoas devidamente autorizadas pela diretoria do MARIAZ poderão dar, em seu nome, declarações, entrevistas ou informações para a imprensa, sejam elas por escrito ou verbalmente.
            </Typography>
            <Typography variant='h2'>3.3. Relações com Público Externo</Typography>
            <Typography variant='body2'>
                O MARIAZ espera que seus parceiros e colaboradores comprometam-se a tratar com justiça, ética e imparcialidade os seus clientes, fornecedores, concorrentes e demais colaboradores, em todas as ocasiões. Deve sempre ser utilizado o bom senso, respeito e valores éticos em tais relações.
            </Typography>
            <Typography variant='h2'>3.4. Padrão de Conduta com Clientes e Fornecedores</Typography>
            <Typography variant='body2'>
                É responsabilidade de todos os parceiros e colaboradores zelar pela cordialidade, agilidade, eficiência, respeito e padrões éticos, fornecendo informações claras e precisas às solicitações e/ou reclamações feitas pelos clientes.
            </Typography>
            <Typography variant='body2'>
                Referida conduta também deve ser observada em relação aos fornecedores, os quais são parceiros comerciais que viabilizam a realização das atividades do MARIAZ e das marcas parceiras. Todos os fornecedores deverão ser encorajados e orientados a cumprir e fazer cumprir o presente Código de Ética, além das disposições do respectivo contrato que o vincule à marca parceira.
            </Typography>
            <Typography variant='h2'>3.5. Padrão de Conduta com Concorrentes </Typography>
            <Typography variant='body2'>
                O relacionamento com os concorrentes deve ser pautado por respeito e observados os princípios éticos, evitando-se quaisquer ações e/ou práticas que possam caracterizar concorrência desleal, dentre outros.
            </Typography>
            <Typography variant='body2'>
                Nenhum colaborador está autorizado a fornecer quaisquer informações do MARIAZ a concorrentes.
            </Typography>
            <Typography variant='h2'>3.6. Conflitos de Interesse e Influências</Typography>
            <Typography variant='body2'>
                O conflito de interesse ocorre quando existe a possibilidade de confronto direto ou indireto entre os interesses dos parceiros e colaboradores e os do MARIAZ, que possam comprometer ou influenciar de maneira indevida o desempenho de suas atribuições e responsabilidades, e se caracteriza por toda e qualquer vantagem material em favor do próprio colaborador e/ou de terceiros (parentes, amigos etc.), com os quais houve relação comercial, pessoal ou política.
            </Typography>
            <Typography variant='body2'>
                Também se entende por conflito de interesse a utilização de cargo, função ou informações confidenciais sobre negócios e/ou assuntos do MARIAZ para influenciar decisões que venham a favorecer interesses particulares próprios ou de terceiros, o que é expressamente proibido.
            </Typography>
            <Typography variant='body2'>
                Ocorrendo esses conflitos ou havendo dúvidas sobre sua ocorrência, o parceiro ou colaborador envolvido deverá posicionar imediatamente o MARIAZ para que esta tome a decisão cabível.
            </Typography>
            <Typography variant='h2'>3.7. Práticas Anticorrupção e Antissuborno</Typography>
            <Typography variant='body2'>
                O MARIAZ não admite, por parte de seus parceiros ou colaboradores, o pagamento e nem o recebimento de propinas ou vantagens diretas ou indiretas, de fornecedores, clientes ou parceiros, assim como não permite que seus parceiros e colaboradores busquem ou ofereçam favorecimento direto ou indireto por intermédio da oferta ou recebimento de presentes, brindes, contratos, negócios ou patrocínios especiais, por si ou por terceiros (parentes, amigos, etc.).
            </Typography>
            <Typography variant='h2'>3.8. Política de Proteção ao Meio Ambiente </Typography>
            <Typography variant='body2'>
                De acordo com a diretriz indicada pelo MARIAZ, é sugerido que seus parceiros e colaboradores desenvolvam suas atividades sempre com o intuito de reduzir ao máximo os impactos ambientais.
            </Typography>
            <Typography variant='body2'>
                De acordo com as políticas internas do MARIAZ e em razão do estrito cumprimento da legislação vigente, regulamentações ambientais, além das políticas públicas, o MARIAZ, sempre que possível, dará preferência a parceiros, colaboradores e fornecedores que trabalhem para sustentar, proteger e recuperar o meio ambiente, por meio de economia de recursos, reciclagem, tratamento ou destinação de resíduos, assim como outras ações estruturantes ou de recuperação do meio ambiente.
            </Typography>
            <Typography variant='h1'>4. RESPONSABILIDADES INDIVIDUAIS</Typography>
            <Typography variant='h2'>4.1. Informações de Propriedade da Empresa e sua Confidencialidade</Typography>
            <Typography variant='body2'>
                Os parceiros e colaboradores têm a obrigação de proteger e não divulgar informações confidenciais que possam obter ou criar em relação às suas atividades realizadas para o MARIAZ, independentemente de sua forma.
            </Typography>
            <Typography variant='body2'>
                A sua obrigação de proteger as informações confidenciais não se limita a protegê-las contra o uso indevido, mas, outrossim, utilizá-las somente para o desempenho de seus deveres profissionais. Parceiro ou colaboradores não devem divulgar informações confidenciais sobre qualquer cliente, fornecedor, ou de negócio do MARIAZ a qualquer pessoa não autorizada expressamente (inclusive outros colaboradores). Exemplos destas informações incluem: sistemas, informações de carteiras de clientes, processos, procedimentos ou estratégias que propiciem ao MARIAZ vantagem concorrencial; informações não públicas sobre as operações do MARIAZ; resultados, estratégias e projeções; informações não públicas sobre os planos e processos de negócios, e sobre relacionamentos com clientes; informações não públicas de funcionários e/ou pessoal; informações não públicas recebidas no curso de seu trabalho ou missão sobre clientes, fornecedores e distribuidores; e informações não públicas sobre a tecnologia, sistemas e produtos de propriedade do MARIAZ.
            </Typography>
            <Typography variant='body2'>
                Parceiros ou colaboradores deverão adotar medidas de precaução para evitar a divulgação não autorizada de tais informações. Assim sendo, parceiros ou colaboradores deverão também tomar as providências necessárias para garantir que os documentos relacionados ao negócio sejam produzidos, copiados, enviados, transportados, arquivados, armazenados e descartados da forma projetada para evitar o acesso não autorizado a estas informações.
            </Typography>
            <Typography variant='h2'>4.2. Diversidades Étnicas, Socioeconômicas, Culturais, Religiosas e Sexuais</Typography>
            <Typography variant='body2'>
                É expressamente proibido qualquer tipo de manifestação discriminatória de qualquer natureza (raça, cor, idade, sexo, orientação sexual, religião, incapacidade física ou mental, preceitos étnicos, condição sociocultural, nacionalidade ou estado civil) dirigida a qualquer pessoa.
            </Typography>
            <Typography variant='h2'>4.3. Assédio Moral e Sexual</Typography>
            <Typography variant='body2'>
                Não será admitido nenhum tipo de assédio moral ou sexual no âmbito da relação com o MARIAZ. As condutas de assédio moral são aquelas capazes denegrir a dignidade de qualquer pessoa ou que gerem um ambiente de trabalho intimidador, hostil ou ofensivo. São consideradas condutas de assédio sexual atitudes como propostas ou insinuações sexuais verbais, gestuais ou físicas.
            </Typography>
            <Typography variant='h2'>4.4. Saúde e Segurança no Trabalho</Typography>
            <Typography variant='body2'>
                O MARIAZ sugere a todos os seus parceiros e colaboradores que disponham de condições adequadas de trabalho, baseadas nos mais elevados princípios de higiene e segurança, realizando e avaliando continuamente ações preventivas e corretivas.
            </Typography>
            <Typography variant='body2'>
                Contudo, é de responsabilidade dos parceiros e colaboradores estarem atentos às normas e procedimentos para a realização de suas atividades profissionais, preservando sua integridade física e de seus colegas, internamente e na prestação de serviço externo.
            </Typography>
            <Typography variant='h1'>5. GESTÃO DO CÓDIGO </Typography>
            <Typography variant='h2'>5.1. Violações ao Código de Conduta e Ética</Typography>
            <Typography variant='body2'>
                O conhecimento das políticas e práticas expressas neste Código de Conduta e Ética é de inteira responsabilidade de cada parceiro ou colaborador.
            </Typography>
            <Typography variant='body2'>
                Caso seja constatada conduta inadequada ou descumprimento de qualquer disposição estabelecida neste Código, o MARIAZ detém a prerrogativa de tomar as medidas cabíveis, como o desligamento do colaborador, o rompimento do contrato de parceria e medidas judiciais e extrajudiciais cabíveis, sempre garantindo o pleno e amplo direito de defesa e argumentação das partes envolvidas.
            </Typography>
            <Typography variant='body2'>
                Caso algum parceiro ou colaborador identifique ou tenha suspeita de que o presente Código de Conduta e Ética esteja sendo violado, ou haja possibilidade ou iminência de ser violado, deverá denunciar a conduta pelo email contato@mariaz.com.br.
            </Typography>
            <Typography variant='h2'>5.2. Contato </Typography>
            <Typography variant='body2'>
                Em caso de dúvidas sobre qual deve ser a conduta mais correta a adotar, o parceiro ou colaborador deve procurar entrar em contato com o MARIAZ através do email contato@mariaz.com.br.
            </Typography>
        </TextsLayout>
    );
};

export { CodeOfEthics };
