import { styled } from '@mui/material';

export const Form = styled('form')({
    maxWidth: '22.5rem',
});

export const RegionIcon = styled('img')`
    width: 1rem;
    margin-right: 0.375rem;
`;
