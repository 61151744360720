import React from 'react';
import { MeasurementTableProps } from '@interfaces';

const MeasureTable: React.FC<{data: MeasurementTableProps[]}> =
({ data }: {data: MeasurementTableProps[]}) => {
    return (
        <div className="size-mobile-table th-shadow">
            <table role="table">
                <thead>
                    <tr>
                        <th scope="col">Tamanho</th>
                        {data[0].medidas.map((medida) => (
                            <th key={medida._id} scope="col">
                                {medida.nome}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row) => (
                        <tr>
                            <th aria-label={`Tamanho ${row.tamanho.nome}`} scope="row">
                                {row.tamanho.nome}
                            </th>
                            {row.medidas.map((medida) => (
                                <td
                                    aria-label=
                                        {`${medida.valor} ${medida.unidade_medida}`}>
                                    {medida.valor} {medida.unidade_medida}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export { MeasureTable };
