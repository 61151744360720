/* eslint-disable max-len */
import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    Link,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { RelatedPostLink } from './components/related-post-link';
import { Article } from './components/article';
import { contents } from '../../../api/services/contents';
import { sx } from './sx';
// import { ImagePost } from './styles';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom';
import { MuiDialog, Snackbar } from '@components';
// import { images } from '@utils';
import {
    ContentDataProps,
    ContentItemProps,
    RelatedContents,
    RelatedContentsProps,
    ToggleStaticProps,
} from '@interfaces';
import { images } from '@utils';
import { useOthers, useUser } from '@hooks';
import { Helmet } from 'react-helmet';
import { WhatsappShareButton } from 'react-share';

const DEFULTS = {
    shortLink: {
        text: 'Copiar Link',
        isLoaded: false,
    },
};

const Post: React.FC = () => {
    const { css } = sx();
    const matches = useMediaQuery('(min-width:600px)');
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { user } = useUser();
    const { content } = useOthers();
    const shareref = React.useRef<ToggleStaticProps>(null);
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const [contentData, setContentData] = React.useState<ContentItemProps>({
        isLoaded: false,
        content: {} as ContentDataProps,
    });
    const [relatedContents, setRelatedContents] = React.useState<RelatedContentsProps>({
        isLoaded: false,
        data: [] as RelatedContents[],
    });
    const [saveButton, setSaveButton] = React.useState<boolean>(false);
    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();
    const [copyShortLink, setCopyShortLink] = React.useState(DEFULTS.shortLink);

    const loadRelatedContents = async () => {
        const response = await contents.discover({
            limit: 4,
            type: '',
            idCategory: '',
        });

        if (!response?.error && response?.response.payload) {
            setRelatedContents({
                isLoaded: true,
                data: response.response.payload,
            });
        } else {
            setRelatedContents({
                isLoaded: true,
                data: [] as RelatedContents[],
            });
        }
    };

    const loadContent = async ({ idContent }: { idContent: string }) => {
        if (idContent) {
            const response = await contents.details({
                idContent: idContent,
            });

            if (!response?.error) {
                setContentData({
                    isLoaded: true,
                    content: response?.response.payload,
                });
                setSaveButton(response?.response.payload.isFavorite);
            } else {
                setContentData({
                    isLoaded: false,
                    content: {} as ContentDataProps,
                });
                snackRef.current?.show();
                setFeedback({
                    type: response.response.type,
                    message: response.response.message,
                });
                setTimeout(() => {
                    navigate('/membros/descobrir');
                }, 3000);
            }
        }
    };

    const handleSaveButton = async () => {
        const response = await contents.save({
            idContent: contentData.content._id,
        });
        snackRef.current?.show();
        setFeedback({
            type: response?.response.type,
            message: response?.response.payload,
        });
        setSaveButton(!saveButton);
    };

    const copyShortLinkToClipboard = async () => {
        setCopyShortLink((prev) => prev = DEFULTS.shortLink);
        const response = await content.generateShortLink({
            type: 'content',
            idAd: params.idContent as string,
            urlInfos: {
                long_url: window.location.href,
            },
        });

        if (!response?.error) {
            navigator.clipboard.writeText(response?.response.payload);
            setCopyShortLink((prev) => prev = {
                text: 'Copiado!',
                isLoaded: true,
            });
        }
        setTimeout(() => {
            setCopyShortLink((prev) => prev = DEFULTS.shortLink);
        }, 2000);
    };

    React.useEffect(() => {
        if (params.idContent && user.data?.cpf) {
            loadRelatedContents();
            loadContent({
                idContent: params.idContent,
            });
        }
    }, [location, user]);

    return (
        <Container sx={{ ...css.sxContainer }}>
            {!user.isLoaded || !contentData.isLoaded &&
                <Box sx={{ ...css.sxBoxCircular }}>
                    <CircularProgress size={40} sx={{ ...css.sxCircularProgress }} />
                </Box>
            }
            {user.isLoaded &&
                (user.data?.cpf ?
                    contentData.isLoaded && contentData.content._id &&
                    <>
                        <Helmet
                            title={contentData.content.titulo}
                            meta={[
                                { name: 'twitter:site', content: window.location.href },
                                { name: 'twitter:creator', content: 'mariaz.com.br' },
                                { name: 'twitter:title', content: contentData.content.titulo },
                                { name: 'twitter:image', content: images.load(contentData.content.capa, 'server') },
                                { property: 'og:title', content: contentData.content.titulo },
                                { property: 'og:site_name', content: 'mariaz.com.br' },
                                { property: 'og:type', content: 'website' },
                                { property: 'og:url', content: window.location.href },
                                { property: 'og:description', content: contentData.content.legenda },
                                { property: 'og:image', content: images.load(contentData.content.capa, 'server') },
                                { property: 'og:site_name', content: 'Fishii.shop' },
                            ]} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <Typography
                                    sx={{ ...css.sxTitlePost }}
                                    variant='h1'>
                                    {contentData.content.titulo || contentData.content.nome}
                                </Typography>
                                <Typography
                                    variant='caption'
                                    sx={{ ...css.sxLegendPost }}>
                                    {contentData.content.legenda}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                {/* {content.content.foto && content.content.foto !== undefined &&
                                    <Box sx={{ ...css.sxBoxImage }}>
                                        <ImagePost
                                            src={images.load(
                                                content.content.foto, 'server')} alt="" />
                                    </Box>
                                } */}
                                {contentData.content.video && contentData.content.video !== undefined &&
                                    <Box sx={{ ...css.sxBoxImage }}>
                                        <div className='sc-iframe'>
                                            <iframe width="560" height="315" src={contentData.content.video} title={contentData.content.titulo || contentData.content.nome}
                                                frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        </div>
                                    </Box>
                                }
                                <Article
                                    content={contentData.content.texto} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <Stack>
                                    <Typography
                                        variant='h2'
                                        sx={{ ...css.sxSidebarTitle }}>
                                        Posts relacionados
                                    </Typography>
                                    {relatedContents.isLoaded &&
                                        relatedContents.data.map((content) => (
                                            <RelatedPostLink
                                                key={content._id}
                                                src={images.load(content.capa, 'server')}
                                                title={content.titulo}
                                                link={'/membros/conteudo/' + content._id} />
                                        ))
                                    }
                                </Stack>
                                <Box sx={{ ...css.sxBoxFollow }}>
                                    <Typography
                                        variant='h2'
                                        sx={{ ...css.sxSidebarTitle }}>
                                        Instagram
                                    </Typography>
                                    <Link
                                        sx={{ ...css.sxIgLink }}
                                        href='https://www.instagram.com/mariaz.com.br/' target='_blank'>
                                        <I icon={['fab', 'square-instagram']} />
                                    </Link>
                                </Box>
                            </Grid>
                        </Grid>
                        <Stack
                            sx={{ ...css.sxStackButtons }}
                            spacing={1}
                            direction={'row'}>
                            <IconButton
                                onClick={() => navigate(-1)}
                                sx={{ ...css.sxIconButton }}>
                                <I icon={['fal', 'arrow-left-long']} />
                                <Typography
                                    sx={{ ...css.sxIconButtonText }}
                                    variant='caption'>
                                    Voltar
                                </Typography>
                            </IconButton>
                            <IconButton
                                onClick={() => handleSaveButton()}
                                sx={{ ...css.sxIconButton }}>
                                <I icon={[saveButton ? 'fas' : 'fal', 'heart']} />
                                <Typography
                                    sx={{ ...css.sxIconButtonText }}
                                    variant='caption'>
                                    {saveButton ? 'Salvo' : 'Salvar'}
                                </Typography>
                            </IconButton>
                            <IconButton
                                onClick={() => shareref.current?.show()}
                                sx={{ ...css.sxIconButton }}>
                                <I icon={['fal', 'share-nodes']} />
                                <Typography
                                    sx={{ ...css.sxIconButtonText }}
                                    variant='caption'>
                                    Compartilhar
                                </Typography>
                            </IconButton>
                        </Stack>
                        <MuiDialog
                            maxWidth='xs'
                            fullScreen={!matches}
                            ref={shareref}
                            title='Compartilhar'
                            showDialogTitle>
                            <Box p={3}>
                                <Stack direction='column' spacing={3}>
                                    <WhatsappShareButton url={window.location.href}>
                                        <Stack direction='row' alignItems='center' spacing={2}>
                                            <I
                                                icon={['fab', 'whatsapp']}
                                                fontSize={30}
                                                color={css.share.copyToClipboard.color} />
                                            <Typography
                                                variant='h3'
                                                sx={css.sxColorSelected}>
                                                Whatsapp
                                            </Typography>
                                        </Stack>
                                    </WhatsappShareButton>
                                    <Box>
                                        <Button
                                            type='button'
                                            onClick={copyShortLinkToClipboard}
                                            disabled={copyShortLink.isLoaded}
                                            sx={{
                                                'pl': 0,
                                                'opacity': !copyShortLink.isLoaded ? 1 : 0.8,
                                                ':hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}>
                                            <Stack direction='row' alignItems='center' spacing={2}>
                                                <I
                                                    icon={['far', 'link']}
                                                    fontSize={18}
                                                    color={css.share.copyToClipboard.color} />
                                                <Typography
                                                    variant='h3'
                                                    sx={{
                                                        ...css.sxColorSelected,
                                                        ...css.share.copyToClipboard,
                                                    }}>
                                                    {copyShortLink.text}
                                                </Typography>
                                            </Stack>
                                        </Button>
                                    </Box>

                                </Stack>
                            </Box>
                        </MuiDialog>
                    </> :
                    <Navigate to={'/not-permission'} />)
            }
            <Snackbar
                ref={snackRef}
                message={feedback ? feedback.message : ''}
                severity={feedback ? feedback.type as any : 'info'}
            />
        </Container>
    );
};

export { Post };
