import { Box, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

interface WrapperProps {
    position: 'fixed' | 'relative';
    border?: boolean;
    children: React.ReactNode;
}

interface ContentProps {
    children: React.ReactNode;
    paddingBottom?: boolean;
    heightCalc?: number;
}

const Wrapper: React.FC<WrapperProps> = (
    { position, border, children }: WrapperProps) => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:37.5rem)');

    // React.useEffect(() => {
    //     if (!matches) {
    //         document.body.classList.add('page-content');
    //     } else {
    //         if (document.location.pathname !== '/perfil/alterar/loja') {
    //             document.body.classList.remove('page-content');
    //         }
    //         document.body.classList.remove('page-content');
    //     }
    // }, [matches]);

    return (
        <Box
            sx={position === 'fixed' ? {
                border: border ?
                    '.0625rem solid ' + palette.clouds :
                    'none',
                backgroundColor: palette.common.white,
                position: !matches ? 'fixed' : 'relative',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: matches ? 468 : 498,
                scrollBehavior: 'smooth',
            } : {
                position: 'relative',
                border: border ?
                    '.0625rem solid ' + palette.clouds :
                    'none',
            }}>
            {children}
        </Box>
    );
};

const Content: React.FC<ContentProps> = ({
    children,
    paddingBottom,
    heightCalc,
}: ContentProps) => {
    const matches = useMediaQuery('(min-width:37.5rem)');

    return (
        <Box
            sx={{
                height: !matches ?
                    // eslint-disable-next-line quotes
                    `calc(100% - ${heightCalc + 'px' ??
                    '3.1875rem'})` :
                    'auto',
                overflowY: 'auto',
                paddingBottom: paddingBottom ? !matches ? 6 : 4 : 0,
            }}>
            {children}
        </Box>
    );
};

export { Content, Wrapper };
