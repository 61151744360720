import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { typography } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const css = {
        sxContainer: {
            padding: matches ? '2.8125rem 0' : '1.875rem 0.9375rem',
        },
        sxBoxInfo: {
            display: 'flex',
            flexDirection: matches ? 'column' : 'row',
            paddingRight: matches ? '3.75rem' : '0',
            paddingBottom: matches ? '0' : '0.9375rem',
            justifyContent: matches ? 'center' : 'space-between',
        },
        sxBoxTexts: {
            width: '100%',
            dispay: 'flex',
            justifContent: 'center',
        },
        sxTitle: {
            fontWeight: typography.fontWeightBold,
            fontSize: matches ? '1.75rem' : '1.125rem',
            mb: 1,
        },
        sxLegend: {
            fontWeight: typography.fontWeightMedium,
            fontSize: matches ? '1rem' : '0.875rem',
        },
        sxIconButton: {
            paddingLeft: '3.125rem',
            display: 'flex',
            alignItems: 'start',
            fontSize: '1rem',
        },
    };
    return { css };
};
