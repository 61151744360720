import {
    Filter,
    HeaderPage,
    MessageBox,
    Page,
    TabPanel,
    Points as PointsValue,
    WalletExtractItem,
} from '@components';
import {
    Box,
    Container,
    Tab,
    Tabs,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import { sx } from './sx';
import { filters } from '@filters';

function a11yProps(index: number) {
    return {
        'id': 'simple-tab-' + index,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Points = () => {
    const { typography, palette } = useTheme();
    const { pxToRem } = typography;
    const { css } = sx();

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Page.Wrapper border position='relative'>
            <HeaderPage
                titleAlign={'default'}
                icon={['far', 'wallet']}
                title='Minha Carteira'
                legend='de Pontos' />

            <Container maxWidth='lg'>
                <Box pt={pxToRem(30)} pb={pxToRem(20)}>
                    <Typography
                        variant='h2'
                        component='h2'
                        fontSize={14}
                        fontWeight={typography.fontWeightBold}
                        mb={pxToRem(3)}
                    >
                        Saldo disponível
                    </Typography>
                    <PointsValue size='big' value='0' />
                </Box>

                <Box
                    p={'.75rem .9375rem'}
                    sx={{
                        backgroundColor: palette.clouds,
                    }}>
                    <Typography
                        variant='h2'
                        component='h2'
                        fontSize={12}
                        fontWeight={typography.fontWeightBold}
                    >
                        Atualizado às 14:30 de 15/04/2021
                    </Typography>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}>
                        <Tab label="Extrato" {...a11yProps(0)} />
                        <Tab label="Pendentes" {...a11yProps(1)} />
                        <Tab label="A expirar" {...a11yProps(2)} />
                    </Tabs>
                </Box>

                <Filter.Wallet filterList={filters.wallet.period} />

                <TabPanel value={value} index={0}>
                    <Box pt={pxToRem(15)} pb={pxToRem(15)}
                        sx={css.extract.separate}>
                        <WalletExtractItem
                            title='Venda de produto'
                            status='pending'
                            date='10/01/2023'
                            amount='50.00'
                            type='points' />
                        <WalletExtractItem
                            title='Venda de produto'
                            status='accepted'
                            date='10/08/2023'
                            amount='78.00'
                            type='points'
                        />
                        <WalletExtractItem
                            title='Venda de produto'
                            status='processing'
                            date='24/02/2023'
                            amount='36.00'
                            type='points'
                        />
                        <MessageBox type='info' message='Nada para exibir.' />
                    </Box>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MessageBox type='info' message='Nada para exibir.' />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <MessageBox type='info' message='Nada para exibir.' />
                </TabPanel>
            </Container>
        </Page.Wrapper>
    );
};

export { Points };
