/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { useAds } from '../../../../hooks/ads';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Box, Breadcrumbs, Button, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Image } from '@components';
import ProductPlaceholder from '@img/product-placeholder.png';
import { sx } from '../../sx';
import StickyBox from 'react-sticky-box';

interface ReviewAdBarProps {
    success: boolean;
    ok: () => void;
    edit: () => void;
}

const ReviewAdBar = ({ success = false, edit, ok }: ReviewAdBarProps) => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');

    const ad = useAds();
    const { css } = sx();

    return (
        <StickyBox style={{ zIndex: 999 }}>
            <Box sx={{
                backgroundColor: '#fff',
                display: 'flex',
                justifyContent: 'center',
                p: 2,
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
                boxShadow: '0px 6px 6px #00000029',
                overflow: 'hidden',
            }}>
                <Box
                    maxWidth={280} width='100%'>
                    {success && (
                        <Stack direction='column' alignItems='center'>
                            <Box>
                                <I icon={['fal', 'check-circle']} fontSize={32} color={palette.success.main} />
                            </Box>
                            <Typography
                                component='p'
                                fontWeight='bold'
                                fontSize={matches ? 22 : 16}
                                color={({ palette }) => palette.dark}>
                                Produto cadastrado!
                            </Typography>
                        </Stack>
                    )}
                    {!success && (
                        <>
                            <Box>
                                <Typography
                                    component='p'
                                    fontWeight='bold'
                                    align='center'
                                    fontSize={matches ? 22 : 16}
                                    color={({ palette }) => palette.dark}>
                                    Confirma o cadastro do produto?
                                </Typography>
                            </Box>
                            <Box
                                display='flex'
                                justifyContent='center'
                                mt={2}>
                                <Box
                                    width='100%'
                                    justifyContent='center'
                                    display='flex'
                                    sx={{
                                        '& .MuiButton-root:last-child': {
                                            ml: 1,
                                        },
                                    }}>
                                    <Button
                                        fullWidth
                                        onClick={edit}
                                        variant="contained"
                                        size="medium"
                                        type='button'
                                        className='MuiButton secondary'
                                        sx={{
                                            backgroundColor: palette.carminePink + ' !important',
                                        }}
                                    >
                                        Editar
                                    </Button>
                                    <Button
                                        onClick={ok} // createAd
                                        fullWidth
                                        variant="contained"
                                        size="medium"
                                        type='button'
                                        className='MuiButton secondary'
                                    >
                                        OK
                                    </Button>
                                </Box>
                            </Box>
                        </>)}
                </Box>
            </Box>
        </StickyBox>
    );
};

export { ReviewAdBar };
