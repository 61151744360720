/* eslint-disable no-unused-vars */
import { createTheme } from '@mui/material/styles';
import { muiCheckbox } from './muiCheckbox';

declare module '@mui/material/styles' {

    interface Palette {
        midnight: string;
        americanRiver: string;
        silverBlue: string;
        darkGrey: string;
        clouds: string;
        snow: string;
        dark: string;
        marine: string;
        pomegarnate: string;
        carminePink: string;
        nephritis: string;
        lightGrey: string;
        peach: string;
        wine: string;
        inputBorderColor: string;
        status: {
            pending: string;
            rejected: string;
            accepted: string;
            processing: string;
            info: string;
        }
    }

    interface PaletteOptions {
        midnight: string;
        americanRiver: string;
        silverBlue: string;
        darkGrey: string;
        clouds: string;
        snow: string;
        lightGrey: string;
        dark: string;
        marine: string;
        pomegarnate: string;
        carminePink: string;
        nephritis: string;
        peach: string;
        wine: string;
        inputBorderColor: string;
        status: {
            pending: string;
            rejected: string;
            accepted: string;
            processing: string;
            info: string;
        }
    }
}

declare module '@mui/material/styles/createTypography' {
    interface FontStyle {
        fontNunito: string;
        fontWeightSemiBold: number;
    }
}

const theme = createTheme({
    typography: {
        fontFamily: 'Nunito',
        fontWeightSemiBold: 600,
    },
    components: {
        MuiButtonGroup: {
            defaultProps: {
                size: 'large',
            },
        },
        MuiCheckbox: {
            defaultProps: {
                ...muiCheckbox,
            },
            styleOverrides: {
                colorPrimary: {
                    '&.Mui-checked': {
                        color: '#010101',
                    },
                    ...{ color: '#6A6F7A' },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                colorPrimary: {
                    '&.Mui-checked': {
                        color: '#010101',
                    },
                    ...{ color: '#6A6F7A' },
                },
            },
        },
        MuiButton: {
            defaultProps: {
                size: 'large',
                disableRipple: true,
                sx: {
                    '&.MuiButton.primary': {
                        backgroundColor: '#010101',
                    },
                    '&.MuiButton.secondary': {
                        backgroundColor: '#010101',
                    },
                    '&.MuiButton.text': {
                        color: '#3D3D40',
                        backgroundColor: 'transparent',
                        fontWeight: 'semibold',
                    },
                    '&.MuiButton.secondary.Mui-disabled': {
                        backgroundColor: '#010101',
                        color: '#FFFFFF',
                        opacity: 0.9,
                    },
                },
            },
            styleOverrides: {
                root: {
                    borderRadius: 0,
                    textTransform: 'initial',
                },
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                // size: 'medium',
                sx: {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: '.0625rem',
                        borderColor: ({ palette }) => palette.inputBorderColor,
                    },
                    '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                        borderColor: ({ palette }) => palette.carminePink,
                    },
                    ...{
                        color: ({ palette }) => palette.dark,
                        fontWeight: ({ typography }) => typography.fontWeightMedium,
                        backgroundColor: ({ palette }) => palette.common.white,
                    },
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                sx: {
                    '&.Mui-focused': {
                        fontWeight: 700,
                        color: ({ palette }) => palette.dark,
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: '#010101',
                },
            },
        },
        MuiTab: {
            defaultProps: {
                style: {
                    fontSize: 14,
                    textTransform: 'capitalize',
                },
            },
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#010101',
                        fontWeight: 'bold',
                    },
                },
            },
        },
    },
    palette: {
        midnight: '#353B48',
        americanRiver: '#6A6F7A',
        silverBlue: '#7F98AA',
        clouds: '#ECF0F1',
        snow: '#F9FAFE',
        darkGrey: '#3D3D40',
        lightGrey: '#F1F1F1',
        dark: '#010101',
        marine: '#2B3951',
        pomegarnate: '#BC1823',
        carminePink: '#D13B53',
        nephritis: '#27AE60',
        peach: '#F1C7AA',
        wine: '#71263D',
        inputBorderColor: 'rgba(0, 0, 0, 0.23)',
        status: {
            pending: '#f25e35',
            rejected: '#BC1823',
            accepted: '#27AE60',
            processing: '#7F98AA',
            info: '#0288d1',
        },
    },
});

export { theme };
