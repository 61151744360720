import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxContainer: {
            paddingTop: '1.5625rem',
        },
        sxBoxTitle: {
            padding: `1.875rem ${matches ? '0' : '0.9375rem'}`,
        },
        sxTitle: {
            fontWeight: typography.fontWeightRegular,
            fontSize: matches ? '2.375rem' : '1.5rem',
        },
        sxLegend: {
            fontWeight: typography.fontWeightRegular,
            fontSize: matches ? '1rem' : '0.875rem',
        },
        sxTabs: {
            'width': '100%',
            'height': '100%',
            'marginBottom': '1.25rem',
            'borderBottom': `0.0625rem solid ${palette.clouds}`,
            '& > .MuiTabs-scroller': {
                '& > .MuiTabs-indicator': {
                    display: 'none',
                },
                '& > .MuiTabs-flexContainer': {
                    '& > .MuiTab-root': {
                        'textTransform': 'none !important',
                        'width': matches ? 'auto' : '50%',
                        'padding': matches ? '0.8125rem 1.875rem' : '0.8125rem 0',
                        'fontSize': matches ? '18px' : '14px',
                        'fontWeight': typography.fontWeightRegular,
                        'color': palette.dark,
                        '&.Mui-selected': {
                            color: palette.common.white,
                            backgroundColor: palette.dark,
                        },
                    },
                },
            },
        },
        sxTabText: {
            'display': 'flex',
            'alignItems': 'center',
            'fontSize': matches ? '1rem' : '0.75rem',
            '& > svg': {
                paddingLeft: '0.375rem',
            },
        },
        sxBoxOutlet: {
            padding: matches ? '3.125rem 0' : ' 1.875rem 0.9375rem',
            paddingTop: '0',
            minHeight: 'calc(100vh - 260px)',
        },
    };

    return { css };
};
