import { useTheme } from '@mui/material';

interface SxProps {
    backgroundColor: string;
    color: string;
}

export const sx = ({
    backgroundColor, color,
}: SxProps) => {
    const { typography } = useTheme();
    const css = {
        sxButton: {
            'backgroundColor': backgroundColor,
            '&:hover': {
                backgroundColor: backgroundColor,
            },
            'color': color,
            'fontWeight': typography.fontWeightMedium || 500,
            'fontSize': '0.9375rem',
            'borderRadius': '0',
            'width': '100%',
            'textTransform': 'none',
        },
    };

    return { css };
};
