/* eslint-disable camelcase */
import { GetInTouchProps } from '@interfaces';
import {
    CategoriesProps,
    CategorizationProps,
    GenerateShortLinkProps,
    ListCategorizationProps,
    LocationCitiesProps,
    LocationProps,
    LocationStatesProps,
    PostEmailListProps,
    QuickLinksProps,
    ResetCategorizationDataProps,
    ShowCasesProps,
} from '@interfaces/Contexts';
import React from 'react';
import { client } from '../../api/client';
import { OthersContext } from '../../contexts/others/context';
import { DEFAULTS } from './defaults';

const othersHookProvider = () => {
    const [listCategories,
        setListCategories] = React.useState<CategoriesProps[]>([]);
    const [listHistoryCategory,
        setListHistoryCategory] = React.useState<ListCategorizationProps[]>([]);
    const [location, setLocation] = React.useState<LocationProps>(DEFAULTS.location);

    const listCategorization = async ({ _id, direction }: CategorizationProps) => {
        const { services } = client();
        const response = await services.others.categorization({ _id, direction });
        return response;
    };

    const [quicklinks,
        setQuickLinks] = React.useState<QuickLinksProps>(DEFAULTS.quicklinks);

    const [showcases,
        setShowCases] = React.useState<ShowCasesProps>(DEFAULTS.showcases);

    const handleCategorization = async ({
        _id,
        direction,
        categoria_pai,
        lastLevel,
        title,
    }: ListCategorizationProps) => {
        if (direction === 'FORWARD' && !(categoria_pai)) {
            setListHistoryCategory((prev) => prev = [{
                title: title,
                direction: direction,
                _id: _id,
                categoria_pai: categoria_pai,
            }]);
        } else if (direction === 'FORWARD' && categoria_pai && !lastLevel) {
            const filter = listHistoryCategory.filter((cat) => cat._id === _id).length;
            if (!(!!filter)) {
                await historyCategorization({
                    title: title,
                    _id: _id,
                    categoria_pai: categoria_pai,
                    direction: 'FORWARD',
                });
            }
        }

        if (!lastLevel) {
            const response = await listCategorization({ _id, direction });
            if (!response?.error) {
                setListCategories((prev) => prev = response?.response.payload);
            }
        }
    };

    const historyCategorization = async ({
        _id,
        categoria_pai,
        direction,
        title,
    }: ListCategorizationProps) => {
        if (direction === 'FORWARD') {
            setListHistoryCategory((prev) => prev = [...prev, {
                title: title,
                direction: direction,
                _id: _id,
                categoria_pai: categoria_pai,
            }]);
        }

        if (direction === 'BACK') {
            const idx = listHistoryCategory.
                findIndex((el) => el.categoria_pai === categoria_pai);

            if (idx !== -1) {
                const splice = listHistoryCategory.splice(0, idx + 1);
                setListHistoryCategory((prev) => prev = [...splice]);

                if (direction === 'BACK' &&
                    (splice.length === 1)) {
                    await handleCategorization({});
                    setListHistoryCategory([]);
                } else {
                    await handleCategorization({
                        _id: _id,
                        direction: 'BACK',
                    });
                    setListHistoryCategory((prev) => prev = [...splice]);
                }
            }
        }
    };

    const resetCategorizationData = ({ type }: ResetCategorizationDataProps) => {
        if (type === 'history') {
            setListHistoryCategory((prev) => prev = []);
        }

        if (type === 'categories') {
            setListCategories((prev) => prev = []);
        }
    };

    const loadQuickLinks = async () => {
        setQuickLinks((prev) => prev = DEFAULTS.quicklinks);
        const { services } = client();
        const response = await services.others.quicklinks.load();

        setQuickLinks((prev) => prev = {
            isLoaded: true,
            data: response?.response.payload ?
                response?.response.payload :
                [],
            message: response?.response.message,
        });
        return response;
    };

    const loadShowCases = async () => {
        setShowCases((prev) => prev = DEFAULTS.showcases);
        const { services } = client();
        const response = await services.others.showcases.load();

        setShowCases((prev) => prev = {
            isLoaded: true,
            data: response?.response.payload ?
                response?.response.payload :
                [],
            message: response?.response.message,
        });
        return response;
    };

    const listStates = async () => {
        const { services } = client();
        const response = await services.others.location.states();
        setLocation((prev) => prev = {
            ...prev,
            states: response?.response ? response?.response.sort(
                (a: LocationStatesProps, b: LocationStatesProps) => {
                    return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                }) : [],
        });
        return response;
    };

    const listCities = async (stateId: number) => {
        const { services } = client();
        const response = await services.others.location.cities(stateId);
        setLocation((prev) => prev = {
            ...prev,
            cities: response?.response ? response?.response.sort(
                (a: LocationCitiesProps, b: LocationCitiesProps) => {
                    return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0;
                }) : [],
        });
        return response;
    };

    const postEmailList = async ({ name, email }: PostEmailListProps) => {
        const { services } = client();

        const response = await services.others.newsletter.postEmail({ name, email });
        return response;
    };

    const generateShortLink = async ({
        type,
        idAd,
        urlInfos,
    }: GenerateShortLinkProps) => {
        const { services } = client();

        const response = await services.
            others.
            shortLink.
            generate({ type, idAd, urlInfos });
        return response;
    };

    const categories = {
        listCategorization: listCategorization,
        handleCategorization: handleCategorization,
        historyCategorization: historyCategorization,
        reset: resetCategorizationData,
        list: {
            categories: listCategories,
            history: listHistoryCategory,
        },
    };

    const getInTouch = async ({ name, email, subject, text }: GetInTouchProps) => {
        const { services } = client();
        const response = await services.others.forms.getInTouch.send({
            name, email, subject, text,
        });

        return response;
    };

    const locations = {
        load: {
            states: listStates,
            cities: listCities,
        },
        list: {
            states: location.states,
            cities: location.cities,
        },
    };

    const quicklink = {
        load: loadQuickLinks,
        list: quicklinks,
    };

    const showcase = {
        load: loadShowCases,
        list: showcases,
    };

    const newsletter = {
        postEmail: postEmailList,
    };

    const product = {
        generateShortLink,
    };

    const content = {
        generateShortLink,
    };

    const forms = {
        getInTouch: {
            send: getInTouch,
        },
    };

    return {
        categories,
        locations,
        quicklink,
        showcase,
        newsletter,
        product,
        content,
        forms,
    };
};

const useOthers = () => {
    const context = React.useContext(OthersContext);
    return context;
};

export {
    othersHookProvider,
    useOthers,
};
