import { FilterDataProps, FilterStateProps } from '@interfaces/Contexts';
import { FiltersType } from '@types';

const handleActiveFilterName = (type: FiltersType,
    filter: FilterStateProps,
    pluralName: string,
    singularName: string) => {
    return filter[type].length !== 1 ? pluralName : singularName;
};

const concatCommaToFilterValue = (list: FilterDataProps[],
    _id: string,
    value: string) => {
    return list[list.length - 1]._id === _id ? value : value + ', ';
};
const handleActiveFilterValues = (type: FiltersType, filter: FilterStateProps) => {
    return filter[type]
        .map(({ _id, nome, filter }, i, arr) => {
            const result = type === 'price' ?
                concatCommaToFilterValue(arr, _id, filter as string) :
                concatCommaToFilterValue(arr, _id, nome);
            return result;
        });
};

const filterLength = (type: FiltersType, filter: FilterStateProps) => {
    const length = filter[type].length > 0 ? true : false;
    return length;
};

export {
    concatCommaToFilterValue,
    handleActiveFilterName,
    handleActiveFilterValues,
    filterLength,
};
