/* eslint-disable max-len */
import React from 'react';
import {
    HeaderPage,
    ActionSmallButton,
    Page,
    Snackbar,
    AddressBox,
} from '@components';

import { useLocation, useNavigate } from 'react-router-dom';
import { ToggleStaticProps } from '@interfaces';
import { Box, Container } from '@mui/material';
import { useUser } from '@hooks';
import { addressFormat } from '@utils';

const AddressList: React.FC = () => {
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const location = useLocation();
    const [snackMessage] = React.useState(location.state ?
        location.state.feedback : 'Alterações feitas com sucesso.');
    const { user } = useUser();

    const locationSearch = !!location.search;

    const navigate = useNavigate();

    React.useEffect(() => {
        if (locationSearch) {
            return snackRef.current?.show();
        }
    }, [locationSearch]);

    return (
        <Page.Wrapper border position='relative'>
            <HeaderPage
                titleAlign={'default'}
                icon={['far', 'map-location-dot']}
                title={'Meus endereços'}
                action={
                    <ActionSmallButton
                        text={'Adicionar'}
                        icon={['far', 'plus']}
                        onClick={() => navigate('/meus-enderecos/adicionar')} />
                } />
            <Container maxWidth='lg'>
                {user.data && user.data.endereco && user.data.endereco.length > 0 &&
                    user.data?.endereco.map((address) => (
                        <Box key={address._id} sx={{ mt: 2, mb: 2 }}>
                            <AddressBox
                                mode={'list'}
                                nickname={address.apelido}
                                title={`CEP: ${address.cep}`}
                                legend={addressFormat({
                                    logradouro: address.logradouro,
                                    bairro: address.bairro,
                                    cidade: address.cidade,
                                    estado: address.estado,
                                    numero: Number(address.numero),
                                    complemento: address.complemento,
                                })}
                                onClick={() => navigate('/meus-enderecos/editar', {
                                    state: {
                                        address: address,
                                    },
                                })} />
                        </Box>
                    ))}
                {user.data && user.data.endereco && user.data.endereco.length === 0 &&
                    <Box sx={{ mt: 2, mb: 2 }}>
                        <AddressBox
                            mode={'add'}
                            title={'Adicionar Endereço'}
                            legend={'Clique para adicionar ' +
                                'um novo endereço'}
                            onClick={() => navigate('/meus-enderecos/adicionar')} />
                    </Box>
                }
            </Container>
            <Snackbar
                ref={snackRef}
                message={snackMessage}
                severity='success'
            />
        </Page.Wrapper>
    );
};

export { AddressList };
