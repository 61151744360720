import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useAuth } from '../hooks/auth';
// import { useUser } from '../hooks/user';
// import { useNavigate } from 'react-router-dom';

const auth = useAuth();
// const { loadUser } = useUser();
// const navigate = useNavigate();

export const logout = async () => {
    const response = await auth.logout();
    if (response?.error) {
        return;
    }

    window.location.replace('/');
};

export const userIconsMenu = [
    {
        id: '9bd11b3ba74b',
        nome: 'Criar anúncio',
        icon: ['far', 'circle-plus'] as IconProp,
    },
    {
        id: '5641e4c02d39',
        nome: 'Loja',
        icon: ['far', 'eye'] as IconProp,
    },
    {
        id: 'e270916f7781',
        nome: 'Anúncios',
        icon: ['far', 'bullhorn'] as IconProp,
    },
    {
        id: 'ef19a508fd4f',
        nome: 'Vendas',
        icon: ['far', 'store'] as IconProp,
    },
    {
        id: 'ef19a508fopf',
        nome: 'Compras',
        icon: ['far', 'cart-shopping'] as IconProp,
    },
    {
        id: '91c2c92c896b',
        nome: 'Ajuda',
        icon: ['far', 'circle-question'] as IconProp,
    },
    {
        id: '44803ae7b0ea',
        nome: 'Formas de pagamento',
        icon: ['far', 'credit-card-blank'] as IconProp,
    },
    {
        id: '9bd11b3ba0ab',
        title: 'Compras',
        icon: ['far', 'cart-shopping'] as IconProp,
    },
    {
        id: 'e49107e64827',
        nome: 'Favoritos',
        icon: ['far', 'heart'] as IconProp,
    },
    {
        id: 'e9eb611e0cf8',
        nome: 'Perfil',
        icon: ['far', 'gear'] as IconProp,
    },
    {
        id: '19707143ec3c',
        nome: 'Endereços',
        icon: ['far', 'id-card'] as IconProp,
    },
    {
        id: '315723d9856c',
        nome: 'Carteira',
        icon: ['far', 'wallet'] as IconProp,
    },
    {
        id: '319523d9856c',
        nome: 'Conteúdos',
        icon: ['far', 'glasses'] as IconProp,
    },
];
