import React from 'react';
import {
    AdColorsProps,
    AdFindProps,
    AdImagesProps,
    LoadAdVariantionsByAdIDProps,
    VariationsColorProps,
} from '@interfaces/Contexts';
import { images } from '@utils';

import { client } from '../../api/client';

import { DEFAULTS_VARIATIONS } from './defaults';
import { AdsContext } from '../../contexts/ads/context';
import { DropResult } from 'react-beautiful-dnd';
// import { useImages } from '../images';

interface StateVariationsProps {
    listByAdID: {
        list: {
            data: LoadAdVariantionsByAdIDProps[],
            message: string;
            isLoaded: boolean;
        }
    };
    listByVariationID: {
        list: {
            data: LoadAdVariantionsByAdIDProps,
            message: string;
            isLoaded: boolean;
        }
    };
}

const useVariationsProviderHook = () => {
    // const images = useImages();

    const [adVariations,
        setAdVariations] = React.useState<StateVariationsProps>(DEFAULTS_VARIATIONS);
    const [adVariationImages,
        setAdVariationImages] = React.
        useState<AdImagesProps[]>(DEFAULTS_VARIATIONS.images.variation);
    const [variationColors,
        setVariationColors] = React.
        useState<VariationsColorProps>(DEFAULTS_VARIATIONS.colors);
    const [variationSelectedColor,
        setVariationSelectedColor] = React.useState<AdColorsProps>({} as AdColorsProps);
    const [variationCustomColor,
        setVariationCustomColor] = React.useState<AdImagesProps>({} as AdImagesProps);

    const findVariationByAdID = async ({ id }: AdFindProps) => {
        const { services } = client();

        setAdVariations((prev) => prev = {
            ...prev,
            listByAdID: DEFAULTS_VARIATIONS.listByAdID,
        });

        const response = await services.ads.variations.find.byAdID({ id });
        setAdVariations((prev) => prev = {
            ...prev,
            listByAdID: {
                list: {
                    data: response?.response.payload ? response?.response.payload : [],
                    isLoaded: true,
                    message: response?.response.message,
                },
            },
        });

        return response;
    };

    const findVariationByID = async ({ id }: AdFindProps) => {
        const { services } = client();
        setAdVariations((prev) => prev = {
            ...prev,
            listByVariationID: DEFAULTS_VARIATIONS.listByVariationID,
        });

        const response = await services.ads.variations.findByID({ id });
        setAdVariations((prev) => prev = {
            ...prev,
            listByVariationID: {
                list: {
                    data: response?.response.payload ? response?.response.payload : {},
                    isLoaded: !!(!response?.error),
                    message: response?.response.message,
                },
            },
        });
        return response;
    };

    // * IMAGES

    const handleVariationImages = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files as FileList;
        const response = await images.add(event);

        if (!response.error) {
            if (files.length === 1 && !(adVariationImages.length >= 4)) {
                setAdVariationImages((prev) => prev = [
                    ...prev,
                    ...response.files,
                ]);
            } else {
                const res = response.files.slice(0, 4 - adVariationImages.length);
                setAdVariationImages((prev) => prev = [
                    ...prev,
                    ...res,
                ]);
            }
        }
    };

    const handleDeleteImage = (id: string) => {
        const filter = adVariationImages.filter((item) => item.id !== id);
        return setAdVariationImages((prev) => prev = [...filter]);
    };

    const handleAddVariationImagesToEDIT = (images: AdImagesProps[]) => {
        setAdVariationImages((prev) => prev = images);
    };

    const clearVariationImages = () => {
        setAdVariationImages((prev) => prev = DEFAULTS_VARIATIONS.images.variation);
    };

    // * IMAGES COLORS
    const handleVariationColor = async (event: React.ChangeEvent<HTMLInputElement>,
        colorName: string) => {
        // const files = event.target.files as FileList;

        const response = await images.add(event);
        if (!response.error) {
            setVariationCustomColor((prev) => prev = {
                id: response.files[0].id,
                content: colorName,
                file: response.files[0].file,
                src: response.files[0].src,
            });
        }
    };

    const removeVariationColor = () => {
        setVariationCustomColor((prev) => prev = {} as AdImagesProps);
    };

    const selectVariationColorImageToEdit = ({ ...props }: AdImagesProps) => {
        setVariationCustomColor((prev) => prev = props);
    };

    // * Colors
    const addSelectedColor = ({ ...props }: AdColorsProps) => {
        setVariationSelectedColor((prev) => prev = props);
    };

    const clearSelectedColor = () => {
        setVariationSelectedColor((prev) => prev = {} as AdColorsProps);
    };

    // * Loads
    const loadColors = async () => {
        const { services } = client();
        const response = await services.ads.load.colors();
        setVariationColors((prev) => prev = {
            data: response?.response.payload,
            message: response?.response.message,
            isLoaded: true,
        });
        return response;
    };

    // * DND
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const items = images.dnd.reorder(
            adVariationImages,
            result.source.index,
            result.destination.index,
        );

        setAdVariationImages((prev) => prev = items as any);
    };

    const variationsAD = {
        find: {
            byAdID: findVariationByAdID,
            byVariationID: findVariationByID,
        },
        list: {
            byAdID: adVariations.listByAdID.list,
            byVariationID: adVariations.listByVariationID.list,
        },
        images: {
            variation: {
                addToEdit: handleAddVariationImagesToEDIT,
                add: handleVariationImages,
                delete: handleDeleteImage,
                clear: clearVariationImages,
                list: adVariationImages,
            },
            dnd: {
                onDragEnd: onDragEnd,
            },
        },
        colors: {
            load: loadColors,
            list: variationColors,
            selected: {
                add: addSelectedColor,
                list: variationSelectedColor,
                clear: clearSelectedColor,
            },
            customColor: {
                list: variationCustomColor,
                add: handleVariationColor,
                remove: removeVariationColor,
                addToEdit: selectVariationColorImageToEdit,
            },
        },
        // listByAdID: {
        //     list: adVariations.listByAdID ?
        //         adVariations.listByAdID.list :
        //         DEFAULTS.variations.listByAdID.list,
        // },
        // listByVariationID: findVariationByID,
        // images: {
        //     list: adVariationsImages.variationList,
        //     colorsList: adVariationsImages.colors,
        //     customColorsList: adVariationsImages.customColors,
        //     add: selectImage,
        //     delete: handleDelete,
        //     clear: clearAdVariationsImages,
        // },
        // items: {
        //     currentSizeID: adVariations.items.currentSizeID,
        //     list: adVariations.items.list,
        //     add: addVariationsToCreate,
        //     reset: resetVariationsItemsData,
        //     addResponse: adVariations.items.response,
        //     remove: removeVariationListItem,
        // },
        // colors: {
        //     add: addVariantColor,
        //     addCustom: addVariantCustomColor,
        //     data: {
        //         list: adVariationsColor,
        //         customList: adVariationsCustomColor,
        //     },
        // },
    };

    return { variationsAD };
};

const useVariations = () => {
    const context = React.useContext(AdsContext);
    return context;
};

export { useVariations, useVariationsProviderHook };
