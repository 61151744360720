import { Box, styled } from '@mui/material';

export const WrapperTimeLine = styled(Box)(() => ({
    '& .product-status-item:last-child > .divider': {
        display: 'none',
    },
}));

export const Col = styled(Box)(() => ({
    ...{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
}));

export const Cols = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    width: '100%',
}));

export const IconBox = styled(Box)(({ theme }) => ({
    width: theme.typography.pxToRem(40),
    height: theme.typography.pxToRem(40),
    borderRadius: theme.typography.pxToRem(100),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const DividerV = styled(Box)(({ theme }) => ({
    height: theme.typography.pxToRem(30),
    width: theme.typography.pxToRem(2),
    marginLeft: theme.typography.pxToRem(19),
}));
