/* eslint-disable max-len */
import React from 'react';
import {
    AvatarGroup,
    BoxContent,
    HeaderPage,
    Headline,
    MuiDialog,
    Price,
    Product,
    SmallerDashedList,
    Snackbar,
    Image,
    VariationItem,
    MessageBox,
    Template,
} from '@components';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { ToggleStaticProps } from '@interfaces';
import { Stack } from '@mui/system';
import { ControllQuantity } from '../../../product-view/components';
import { sx } from './sx';
import { Item } from './styles';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../../../../hooks/shopping';
import { images } from '../../../../utils/images';
import { CartAddProps, CartListItemsProps } from '@interfaces/Cart';
import { useAds } from '../../../../hooks/ads';
import { LoadAdVariantionsByAdIDProps } from '@interfaces/Contexts';
import { formatNumberToPriceBRL } from '@utils';
import { useUser } from '@hooks';

interface SelectedADProps {
    type: 'idAd' | 'idVariation' | 'variationItemId' | 'variationItemSizeId';
    data: string;
};

interface HandleQuantityProps {
    type: 'increment' | 'decrement';
};

interface CartAdVariationsProps extends LoadAdVariantionsByAdIDProps {
    title: string;
    type: 'ad' | 'variation';
    valor_por?: number;
};

const DEFAULTS = {
    SELECTED_AD: {
        idAd: {
            _id: '',
        },
        idVariation: {
            _id: '',
        },
        variationItemId: {
            _id: '',
        },
        variationItemSizeId: {
            _id: '',
        },
    },
    ITEM_QUANTITY: {
        default: {
            quantidade: 0,
            quantidade_estoque: 0,
        },
        update: {
            quantity: 0,
        },
    },
    IS_LOADED: {
        shipping: {
            loaded: false,
        },
    },
    ERRORS: {
        size: {
            error: false,
            message: '',
        },
        noSize: false,
    },
};

const Cart = () => {
    const { cart } = useCart();
    const { user } = useUser();
    const ads = useAds();
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const dialogConfirm = React.useRef<ToggleStaticProps>(null);
    const dialogAddNewAddress = React.useRef<ToggleStaticProps>(null);
    const matches = useMediaQuery('(min-width:600px)');

    const { palette, typography } = useTheme();
    const { css } = sx();

    const navigate = useNavigate();

    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();
    const [cartItemIndex, setCartItemIndex] = React.useState<number>(0);
    const [selectedAD, setSelectedAD] = React.useState(DEFAULTS.SELECTED_AD);
    const [quantity, setQuantity] = React.useState(DEFAULTS.ITEM_QUANTITY);
    const [variations, setVariations] = React.useState<CartAdVariationsProps[]>([]);
    const [errors, setErrors] = React.useState(DEFAULTS.ERRORS);
    const [isLoaded, setIsLoaded] = React.useState(DEFAULTS.IS_LOADED);

    const loadCartAPIS = async () => {
        setIsLoaded((prev) => prev = {
            ...prev,
            shipping: {
                loaded: true,
            },
        });

        if ((Object.keys(user).length > 0) && user.isLoaded) {
            if (user.data && !(user.data?.endereco.length > 0)) {
                await cart.load();
                setIsLoaded((prev) => prev = DEFAULTS.IS_LOADED);
                return;
            } else {
                await cart.calculateShipping();
                await cart.load();
                setIsLoaded((prev) => prev = DEFAULTS.IS_LOADED);
                return;
            }
        }
    };


    const removeCartItem = async ({ idAd, idVariation, variationItemId, quantity }: CartAddProps) => {
        const response = await cart.item.remove({ idAd, idVariation, variationItemId, quantity });
        if (!response?.error) {
            setFeedback((prev) => prev = {
                type: 'success',
                message: response?.response.message,
            });
            snackRef.current?.show();

            await loadCartAPIS();
        } else {
            setFeedback((prev) => prev = {
                type: 'info',
                message: response.response.message,
            });
            snackRef.current?.show();
        }
    };

    const selectCartItem = async (product: CartListItemsProps, itemIndex: number) => {
        setCartItemIndex((prev) => prev = itemIndex);

        const productResponse = await ads.find({
            id: product.id.id_anuncio ?
                product.id.id_anuncio._id :
                product.id._id,
        });

        const variantionResponse = await ads.variationsAD.find.byAdID({
            id: product.id.id_anuncio ?
                product.id.id_anuncio._id :
                product.id._id,
        });

        const object = [] as any[];
        let productTitle = '';

        if (!(productResponse?.error)) {
            // const itens = productResponse?.response.payload.tamanho ?
            //     productResponse?.response.payload.tamanho.reduce((list: any, item: { _id: any; nome: any; }) => {
            //         const newList = [...list];
            //         return [...newList, {
            //             tamanho: {
            //                 _id: item._id,
            //                 nome: item.nome,
            //             },
            //             valor_por: productResponse?.response.payload.valor_por,
            //             quantidade_estoque: productResponse?.response.payload.quantidade_estoque,
            //         }];
            //     }, []) : [];

            // const format = {
            //     _id: productResponse?.response.payload._id,
            //     nome_cor: 'padrão',
            //     fotos: [productResponse?.response.payload.fotos[0]],
            //     id_anuncio: productResponse?.response.payload._id,
            //     imagem_cor: productResponse?.response.payload.fotos[0],
            //     itens: itens,
            //     title: productResponse?.response.payload.titulo,
            //     valor_por: productResponse?.response.payload.valor_por,
            //     type: 'ad',
            // };
            productTitle = productResponse?.response.payload.titulo;
            // object.push(format);
        }

        if (!(variantionResponse?.error)) {
            const format = variantionResponse?.response.payload.map((variation: any) => {
                return {
                    ...variation,
                    title: productTitle,
                    type: 'variation',
                };
            });

            object.push(...format);
        }

        setVariations((prev) => prev = object);

        setQuantity((prev) => prev = {
            ...prev,
            default: {
                quantidade: product.quantidade,
                quantidade_estoque: product.id.quantidade_estoque ?
                    product.id.quantidade_estoque :
                    product.id_item_variacao.quantidade_estoque,
            },
            update: {
                quantity: product.quantidade,
            },
        });

        // * Select AD State
        selectedADState({
            type: 'idAd',
            data: product.id_item_variacao ?
                '' :
                product.id.id_anuncio ?
                    product.id.id_anuncio._id :
                    product.id._id,
        });

        selectedADState({
            type: 'idVariation',
            data: product.id.id_anuncio ?
                product.id._id : '',
        });

        selectedADState({
            type: 'variationItemId',
            data: product.id_item_variacao ?
                product.id_item_variacao._id : '',
        });

        if (product.id_item_variacao || product.id.tamanho) {
            selectedADState({
                type: 'variationItemSizeId',
                data: product.id_item_variacao ?
                    product.id_item_variacao.tamanho ? product.id_item_variacao.tamanho._id : product.id_item_variacao._id :
                    product.id.tamanho._id,
            });
            setErrors((prev) => prev = {
                ...prev,
                size: DEFAULTS.ERRORS.size,
                noSize: false,
            });
        } else {
            setErrors((prev) => prev = {
                ...prev,
                size: DEFAULTS.ERRORS.size,
                noSize: true,
            });
        }

        dialogConfirm.current?.show();
    };

    const selectedADState = ({ type, data }: SelectedADProps) => {
        setSelectedAD((prev) => prev = {
            ...prev,
            [type]: {
                _id: data,
            },
        });
    };

    const clearSelectedAD = () => {
        setSelectedAD((prev) => prev = DEFAULTS.SELECTED_AD);
        setCartItemIndex((prev) => prev = 0);
        dialogConfirm.current?.hide();
    };

    const handleSelectAD = async ({ type, data }: SelectedADProps) => {
        switch (type) {
        case 'idAd':
            selectedADState({ type: 'idAd', data });
            selectedADState({ type: 'idVariation', data: '' });
            selectedADState({ type: 'variationItemId', data: '' });
            break;
        case 'idVariation':
            selectedADState({ type: 'idAd', data: '' });
            selectedADState({ type: 'idVariation', data });
            selectedADState({ type: 'variationItemSizeId', data: '' });
            break;
        case 'variationItemId':
            selectedADState({ type: 'variationItemId', data });
            break;
        case 'variationItemSizeId':
            selectedADState({ type: 'variationItemSizeId', data });
            setErrors((prev) => prev = {
                ...prev,
                size: DEFAULTS.ERRORS.size,
                noSize: false,
            });
            break;

        default:
            break;
        }
    };

    const handleQuantity = ({ type }: HandleQuantityProps) => {
        if (type === 'increment') {
            setQuantity((prev) => prev = {
                ...prev,
                update: {
                    quantity: prev.update.quantity + 1,
                },
            });
            return;
        }

        if (type === 'decrement') {
            setQuantity((prev) => prev = {
                ...prev,
                update: {
                    quantity: prev.update.quantity - 1,
                },
            });
            return;
        }
    };

    const updateCartItem = async () => {
        if (!(selectedAD.variationItemSizeId._id) && !errors.noSize) {
            setErrors((prev) => prev = {
                ...prev,
                size: {
                    error: true,
                    message: 'Selecione um tamanho',
                },
                noSize: false,
            });
            return;
        }

        const response = await cart.item.add({
            idAd: selectedAD.idAd._id,
            idVariation: selectedAD.idVariation._id,
            variationItemId: selectedAD.variationItemId._id,
            replaceItem: cartItemIndex,
            quantity: quantity.update.quantity,
        });
        if (!response?.error) {
            setFeedback((prev) => prev = {
                type: 'success',
                message: response?.response.message,
            });
            snackRef.current?.show();
            dialogConfirm.current?.hide();
            await loadCartAPIS();
        } else {
            setFeedback((prev) => prev = {
                type: 'info',
                message: response.response.message,
            });
            dialogConfirm.current?.hide();
            snackRef.current?.show();
        }
    };

    React.useEffect(() => {
        loadCartAPIS();
    }, []);

    React.useEffect(() => {
        if ((Object.keys(user).length > 0) && user.isLoaded) {
            if (user.data && !(user.data?.endereco.length > 0)) {
                dialogAddNewAddress.current?.show();
            } else {
                dialogAddNewAddress.current?.hide();
            }
        } else {
        }
    }, [user.data, user.data?.endereco.length]);

    return (
        <>
            {!((Object.keys(user).length > 0) && user.isLoaded) && (
                <Template.PageMessageTemplate title='Faça o login para visualizar sua sacola!'>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type='submit'
                        className='MuiButton secondary'
                        onClick={() => navigate('/criar-conta')}>
                        Cadastre-se
                    </Button>
                    <Box>
                        ou
                    </Box>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type='submit'
                        className='MuiButton secondary'
                        onClick={() => navigate('/login')}>
                        Faça o login
                    </Button>
                </Template.PageMessageTemplate>
            )}

            <Snackbar
                ref={snackRef}
                autoHideDuration={2000}
                message={feedback ? feedback.message : ''}
                severity={feedback ? feedback.type as any : 'info'}
            />

            {((Object.keys(user).length > 0) && user.isLoaded) && (
                <Container
                    maxWidth='lg'
                    className={'no-padding'}>
                    <HeaderPage
                        onClick={() => { }}
                        titleAlign={'default'}
                        title={'Minha Sacola'}
                        icon={['far', 'shopping-bag']}
                    />
                    <Box mb={2} />
                </Container>
            )}

            {((Object.keys(user).length > 0) && user.isLoaded) && (
                <Container maxWidth='lg'>
                    {!(cart.stores.isLoaded) && !(cart.stores.data.length > 0) && (
                        <MessageBox
                            message={'Você ainda não adicionou produtos na sacola'}
                            type={'info'}
                        />
                    )}
                    <Grid container spacing={2}>
                        <Grid item sm={8} xs={12}>

                            {cart.stores.isLoaded && cart.stores.data.length > 0 && (
                                cart.stores.data.map((item) => (

                                    <Box sx={css.wrapper}>
                                        <Box sx={css.header}>
                                            <AvatarGroup
                                                borderColor={palette.snow}
                                                align={'right'}
                                                size={'tiny'}
                                                title={item.loja?.minha_loja.titulo}
                                                src={item.loja.imagens ?
                                                    images.load(item.loja.imagens.avatar, 'server') : ''} />
                                        </Box>

                                        <Container
                                            maxWidth='lg'
                                            className={'no-padding'}>
                                            {item.itens.map((product, i) => {
                                                return (
                                                    <BoxContent
                                                        paddingLeft
                                                        borderBottom
                                                        actions={[
                                                            {
                                                                actionType: 'change',
                                                                actionFunction:
                                                                    () => selectCartItem(product, i + 1),
                                                            },
                                                            {
                                                                actionType: 'delete',
                                                                actionFunction:
                                                                    async () => await removeCartItem({
                                                                        idAd: product.id_item_variacao ? '' : product.id.id_anuncio ?
                                                                            product.id.id_anuncio._id : product.id._id,
                                                                        idVariation: product.id.id_anuncio ?
                                                                            product.id._id : '',
                                                                        variationItemId: product.id_item_variacao ?
                                                                            product.id_item_variacao._id : '',
                                                                        quantity: product.id.quantidade_estoque ?
                                                                            product.id.quantidade_estoque :
                                                                            product.id_item_variacao &&
                                                                                product.id_item_variacao.quantidade_estoque ?
                                                                                product.id_item_variacao.quantidade_estoque : 0,
                                                                    }),
                                                            }]} >
                                                        <Product
                                                            size={'big'}
                                                            align={'right'}
                                                            _id={product.id._id}
                                                            // eslint-disable-next-line max-len
                                                            src={images.load(product.id.fotos[0], 'server')}
                                                            title={product.id.id_anuncio ?
                                                                product.id.id_anuncio.titulo :
                                                                product.id.titulo}
                                                            legend={`${product.id.nome_cor ?
                                                                'Cor: ' + product.id.nome_cor + ',' :
                                                                ''} ${product.id_item_variacao.tamanho ?
                                                                'Tamanho: ' + product.id_item_variacao.tamanho.nome + ',' :
                                                                'Tamanho: ' + product.id_item_variacao.nome + ','} ${product.quantidade ?
                                                                'Quantidade: ' + product.quantidade : ''}`}
                                                            to={() => { }}
                                                            price={product.id.valor_por ?
                                                                String(product.id.valor_por) :
                                                                String(product.id_item_variacao.valor_por)}
                                                        />
                                                        {product.id.quantidade_dias_disponivel && (
                                                            <Typography
                                                                variant="caption"
                                                                fontWeight="bold"
                                                                color={palette.dark}>
                                                                Prazo para produção: {product.id.quantidade_dias_disponivel + ' Dias'}
                                                            </Typography>
                                                        )}
                                                    </BoxContent>
                                                );
                                            })}
                                            <Box sx={css.footer}>
                                                <Stack
                                                    flexDirection='row'
                                                    alignItems="center"
                                                    sx={matches ? css.footer.shippingDesk : css.footer.shippingMob}>
                                                    {isLoaded.shipping.loaded ?
                                                        <Box p={0.2}>
                                                            <CircularProgress size={16} color='inherit' />
                                                        </Box> :
                                                        (<>
                                                            <Box mr={typography.pxToRem(10)}>
                                                                <Typography
                                                                    variant="body2"
                                                                    fontWeight="bold"
                                                                    color={palette.dark}>
                                                                    Frete
                                                                </Typography>
                                                            </Box>
                                                            <Box mt={typography.pxToRem(5)}>
                                                                <Price
                                                                    size='medium'
                                                                    value={formatNumberToPriceBRL(item.valor_frete)}
                                                                />
                                                            </Box>
                                                        </>)}
                                                </Stack>
                                            </Box>
                                        </Container>

                                    </Box>
                                ))
                            )}
                        </Grid>
                        <Grid item sm={4} xs={12}>
                            {cart.resume.isLoaded && (
                                <Box sx={!matches ? css.totalWrapper : {}}>
                                    <Box sx={matches ? css.wrapper : {}}>
                                        {matches && (
                                            <Box sx={css.header}>
                                                <Headline
                                                    size='small'
                                                    title='Resumo da compra'
                                                    paddingBottom />
                                            </Box>)}

                                        <Box p={2} pt={1}>
                                            <Box mb={1}>
                                                <SmallerDashedList
                                                    divider
                                                    secondaryAction={<Price
                                                        size='small'
                                                        value={formatNumberToPriceBRL(cart.resume.data.total_parcial)}
                                                    />}
                                                    title='Valor total dos produtos'
                                                    listItemId={'bonus-list'}
                                                    size={matches ? 'medium' : 'small'}
                                                />
                                                <SmallerDashedList
                                                    divider
                                                    secondaryAction={isLoaded.shipping.loaded ?
                                                        <CircularProgress size={16} color='inherit' /> :
                                                        <Price
                                                            size='small'
                                                            value={formatNumberToPriceBRL(cart.resume.data.total_frete)}
                                                        />}
                                                    title={'Frete'}
                                                    listItemId={'bonus-list'}
                                                    size={matches ? 'medium' : 'small'}
                                                />
                                                <Stack
                                                    flexDirection='row'
                                                    justifyContent="space-between"
                                                    alignItems="center"
                                                    sx={css.total}>
                                                    <Box>
                                                        <Typography
                                                            variant={matches ? 'body1' : 'body2'}
                                                            fontWeight="bold"
                                                            color={palette.dark}>
                                                            Total
                                                        </Typography>
                                                    </Box>
                                                    <Box mt={typography.pxToRem(5)}>
                                                        <Price size='medium' value={formatNumberToPriceBRL(cart.resume.data.total)} />
                                                    </Box>
                                                </Stack>
                                            </Box>
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                size="large"
                                                type='submit'
                                                className='MuiButton secondary'
                                                onClick={() =>
                                                    user.isLoaded && user.data ?
                                                        navigate('/checkout/endereco') :
                                                        navigate('/login')}>
                                                Confirmar endereço de entrega
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Grid>
                    </Grid>

                    <MuiDialog
                        maxWidth='sm'
                        ref={dialogConfirm}
                        contentPadding={false}
                        fullScreen={!matches}
                        title='Escolha os detalhes deste produto'
                        showDialogTitle={!matches}>
                        <Box sx={{
                            minHeight: 465,
                            display: matches ? 'flex' : 'block',
                            height: '100%',
                        }}>
                            <Box sx={{
                                backgroundColor: matches ? palette.clouds : palette.common.white,
                                minWidth: matches ? '50%' : '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: matches ? 'center' : 'flex-start',
                                pl: typography.pxToRem(matches ? 30 : 15),
                                pr: typography.pxToRem(matches ? 30 : 15),
                                pt: typography.pxToRem(matches ? 0 : 15),
                                pb: typography.pxToRem(matches ? 0 : 15),
                                borderBottom: matches ? 'none' : '.0625rem solid ' + palette.clouds,
                            }}>
                                {variations.length > 0 ? (
                                    variations.map((variation) => {
                                        if ((selectedAD.idVariation._id === variation._id) ||
                                            (selectedAD.idAd._id === variation._id)) {
                                            return (
                                                <>
                                                    <Box sx={{
                                                        display: matches ? 'block' : 'flex',
                                                    }}>

                                                        <Image
                                                            src={images.load(variation.fotos[0], 'server')}
                                                            maxHeight={matches ? 244 : 80} />
                                                        <Box sx={{
                                                            ml: typography.pxToRem(matches ? 0 : 10),
                                                            mt: typography.pxToRem(10),
                                                        }}>
                                                            <Typography
                                                                variant="body1"
                                                                fontWeight="bold"
                                                                fontSize={typography.pxToRem(matches ? 18 : 16)}
                                                                color={palette.dark}
                                                                gutterBottom>
                                                                {variation.title}
                                                            </Typography>
                                                            {variation.itens && variation.itens.map((item) => {
                                                                if (variation.type === 'variation' &&
                                                                    ((selectedAD.idVariation._id === variation._id) &&
                                                                        (selectedAD.variationItemId._id === item._id))) {
                                                                    return (
                                                                        <Price
                                                                            size='medium'
                                                                            value={formatNumberToPriceBRL(item.valor_por)}
                                                                        />
                                                                    );
                                                                }
                                                                if (variation.type === 'ad') {
                                                                    if (selectedAD.variationItemSizeId._id === item.tamanho._id) {
                                                                        return (
                                                                            <Price
                                                                                size='medium'
                                                                                value={formatNumberToPriceBRL(item.valor_por)}
                                                                            />
                                                                        );
                                                                    }
                                                                }
                                                            })}
                                                        </Box>
                                                    </Box>
                                                </>
                                            );
                                        }
                                    })
                                ) : ''}
                            </Box>
                            <Box width='100%' display='flex' alignItems='center'>
                                <Box width='100%' position='relative' p={typography.pxToRem(30)} pr={typography.pxToRem(30)}>
                                    {matches && (
                                        <>
                                            <IconButton
                                                onClick={clearSelectedAD}
                                                aria-label="close" sx={{
                                                    position: 'absolute',
                                                    top: typography.pxToRem(5),
                                                    right: typography.pxToRem(10),
                                                }}>
                                                <I icon={['far', 'times']} color={palette.dark} />
                                            </IconButton>
                                            <Typography
                                                variant="h6"
                                                fontWeight={typography.fontWeightBold}
                                                color={palette.dark}
                                                lineHeight={1.5}
                                                mb={typography.pxToRem(20)}>
                                                Escolha os detalhes deste produto
                                            </Typography>
                                        </>
                                    )}
                                    <Box mb={typography.pxToRem(20)}>
                                        <Stack spacing={1} direction={'row'}>
                                            <Typography
                                                variant="body2"
                                                fontWeight={typography.fontWeightBold}
                                                color={palette.dark}
                                                gutterBottom>
                                                Cor:
                                            </Typography>
                                            {variations.length > 0 ? (
                                                variations.map((variation) => {
                                                    if ((selectedAD.idVariation._id === variation._id) ||
                                                        (selectedAD.idAd._id === variation._id)) {
                                                        return (
                                                            <Typography
                                                                variant="body2"
                                                                fontWeight={typography.fontWeightMedium}
                                                                color={palette.darkGrey}
                                                                gutterBottom>
                                                                {variation.nome_cor}
                                                            </Typography>
                                                        );
                                                    }
                                                })
                                            ) : ''}
                                        </Stack>
                                        <Stack spacing={1} direction={'row'}>
                                            {variations.length > 0 ? (
                                                variations.map((variation) => (
                                                    <VariationItem
                                                        onClick={variations.length === 1 ?
                                                            () => { } :
                                                            () => handleSelectAD({
                                                                type: variation.type === 'ad' ? 'idAd' : 'idVariation',
                                                                data: variation._id,
                                                            })}
                                                        size='small'
                                                        checkedRadio={(selectedAD.idVariation._id === variation._id) ||
                                                            (selectedAD.idAd._id === variation._id)}
                                                        image={images.load(variation.fotos[0], 'server')} />
                                                ))
                                            ) : ''}
                                        </Stack>
                                    </Box>
                                    <Box mb={typography.pxToRem(20)}>
                                        {variations.length > 0 &&
                                            variations.map((variation) => {
                                                if (variation.itens.length > 0) {
                                                    if ((selectedAD.idVariation._id === variation._id) ||
                                                        (selectedAD.idAd._id === variation._id)) {
                                                        return (
                                                            <Typography
                                                                variant="body2"
                                                                fontWeight={typography.fontWeightMedium}
                                                                color={palette.dark}
                                                                gutterBottom>
                                                                Tamanhos
                                                            </Typography>
                                                        );
                                                    }
                                                }
                                            })}

                                        <Grid container spacing={1}>
                                            {variations.length > 0 ?
                                                variations.map((variation) => (
                                                    variation.itens &&
                                                    variation.itens.map((item) => {
                                                        if ((selectedAD.idVariation._id === variation._id) ||
                                                            (selectedAD.idAd._id === variation._id)) {
                                                            return (
                                                                <Grid
                                                                    item
                                                                    xs={item.tamanho ? item.tamanho.nome.length >= 4 ? 4 : 2 : 4}
                                                                    key={item._id}>
                                                                    <Item
                                                                        onClick={() => {
                                                                            // if (variation.type === 'ad') {
                                                                            //     handleSelectAD({
                                                                            //         type: 'idAd',
                                                                            //         data: variation.id_anuncio,
                                                                            //     });
                                                                            // }

                                                                            // if (variation.type === 'variation') {
                                                                            handleSelectAD({
                                                                                type: 'variationItemId',
                                                                                data: item._id,
                                                                            });
                                                                            // }
                                                                            handleSelectAD({
                                                                                type: 'variationItemSizeId',
                                                                                data: item.tamanho ? item.tamanho._id : item._id,
                                                                            });
                                                                        }}
                                                                        className={selectedAD.variationItemSizeId._id === (item.tamanho ? item.tamanho._id : item._id) ? 'active' : ''}
                                                                    >
                                                                        <label htmlFor={item._id}>
                                                                            {item.tamanho ? item.tamanho.nome : item.nome}
                                                                            <Checkbox
                                                                                hidden
                                                                                id={item._id}
                                                                                size='small'
                                                                                value={item._id}
                                                                                checked={false}
                                                                            />
                                                                        </label>
                                                                    </Item>
                                                                </Grid>
                                                            );
                                                        };
                                                    })
                                                )) : ''}
                                        </Grid>
                                        {errors.size.error && (
                                            <FormHelperText error>
                                                {errors.size.message}
                                            </FormHelperText>
                                        )}
                                    </Box>
                                    <Box mb={typography.pxToRem(30)}>
                                        <Typography
                                            variant="body2"
                                            fontWeight={typography.fontWeightMedium}
                                            color={palette.dark}
                                            gutterBottom>
                                            Quantidade
                                        </Typography>
                                        <ControllQuantity
                                            disabled={quantity.default.quantidade_estoque === 1}
                                            value={quantity.update.quantity}
                                            limit={quantity.default.quantidade_estoque}
                                            remove={() => handleQuantity({ type: 'decrement' })}
                                            add={async () => handleQuantity({ type: 'increment' })} />
                                        {/* <Typography
                                            variant="caption"
                                            color={palette.darkGrey}>
                                            {quantity.default.quantidade_estoque === 1 ?
                                                '1 disponível' :
                                                quantity.default.quantidade_estoque + ' disponíveis'}
                                        </Typography> */}
                                    </Box>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        size="large"
                                        type='submit'
                                        className='MuiButton secondary'
                                        onClick={updateCartItem}
                                    >
                                        Atualizar
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </MuiDialog>
                </Container>
            )}

            <MuiDialog
                maxWidth='sm'
                ref={dialogAddNewAddress}
                contentPadding
                fullScreen={!matches}>
                <Box mb={typography.pxToRem(20)}>
                    <Typography
                        variant="h6"
                        align='center'
                        fontWeight="bold"
                        color={palette.dark}>
                        Adicione um endereço de entrega
                    </Typography>
                    <Typography
                        variant="body2"
                        align='center'
                        fontWeight="regular"
                        color={palette.dark}>
                        Calculamos o frete com base no seu endereço.
                    </Typography>
                </Box>

                <Template.FormAddress
                    cancelAction={() => navigate('/')}
                />
            </MuiDialog>
        </>
    );
};

export { Cart };
