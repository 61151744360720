import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxBox: (paddingBottom: boolean) => {
            return {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: paddingBottom ?
                    '10px 0' :
                    '10px 0 0 0',
            };
        },
        sxTitle: {
            fontWeight: typography.fontWeightBold,
            color: palette.dark,
        },
        sxLegend: {
            paddingTop: '0.3125rem',
            fontWeight: typography.fontWeightMedium,
            color: palette.dark,
        },
    };
    return {
        ...css,
    };
};
