import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxBox: (error: boolean) => {
            return {
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '14px 12px',
                width: '100%',
                height: '5.5rem',
                border: `0.0625rem solid ${error ?
                    palette.pomegarnate :
                    palette.clouds}`,
                borderRadius: '0.25rem',
            };
        },
        sxBoxIcon: {
            width: '2rem',
            height: '2rem',
            backgroundColor: palette.clouds,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            marginBottom: '0.25rem',
        },
        sxIcon: {
            color: palette.dark,
            fontSize: '0.875rem',
        },
        sxTypeText: {
            fontSize: '0.75rem',
            fontWeight: typography.fontWeightBold || 700,
            textAlign: 'center',
            textTransform: 'capitalize',
        },
    };

    return { css };
};
