/* eslint-disable max-len */
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const mainMenu = [
    {
        id: '35474e14c592',
        title: 'Categorias',
        icon: ['far', 'chevron-right'] as IconProp,
    },
    {
        id: '8a471ebf5120',
        title: 'Presentes',
        icon: ['far', 'chevron-right'] as IconProp,
        link: '/resultados?category=646f1bee72395b5024bcf4bb,M%C3%A9dias&pricemin=0,price_min&pricemax=9999,price_max',
    },
    {
        id: 'f5a25b03fbeb',
        title: 'Conteúdos',
        icon: ['far', 'chevron-right'] as IconProp,
        link: '/membros/descobrir',
    },
    {
        id: 'f36e98d72f5c',
        title: 'Sobre',
        icon: ['far', 'chevron-right'] as IconProp,
        link: '/quem-somos',
    },
];
