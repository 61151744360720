import {
    AdConditionsProps,
    AdImagesProps,
    AdProps,
    AdVariantionsProps,
    AdVariationsColorsProps,
    CreateStateVariationsProps,
    VariationsItemProps,
    AdSizesProps,
    LoadAdVariantionsByAdIDProps,
} from '@interfaces/Contexts';

export const responseLists = {
    data: [],
    message: '',
    isLoaded: false,
};

const deliveryTypes = {
    aplicativo_entrega: false,
    entrega_maos: false,
};

export const DEFAULTS_VARIATIONS = {
    listByAdID: {
        list: responseLists,
    },
    listByVariationID: {
        list: {
            data: {} as LoadAdVariantionsByAdIDProps,
            message: '',
            isLoaded: false,
        },
    },
    images: {
        variation: [] as AdImagesProps[],
        customColor: [] as AdImagesProps[],
    },
    colors: responseLists,
};

export const DEFAULTS = {
    highlighted: responseLists,
    variations: {
        listByAdID: {
            list: responseLists,
        },
        items: {
            sizesList: [] as AdConditionsProps[],
            list: [] as VariationsItemProps[],
            response: {
                error: false,
                message: '',
            },
        } as CreateStateVariationsProps,
        images: {
            variationList: [] as AdImagesProps[],
            colors: [] as AdImagesProps[],
            customColors: [] as AdImagesProps[],
        },
        colors: {
            color: {
                _id: '',
                imagem: '',
                nome: '',
                customColor: false,
            },
        } as AdVariationsColorsProps,
    },
    measures: {
        listSelected: {
            _id: '',
            data: [],
        },
        list: [],
    },
    ad: {
        list: responseLists,
        listById: {
            data: {} as AdProps,
            message: '',
            isLoaded: false,
        },
        listAdVariationsById: responseLists,
        variationsById: {
            data: {} as AdVariantionsProps,
            message: '',
            isLoaded: false,
        },
        currentAd: {
            _id: '',
            isLoaded: false,
        },
        conditions: responseLists,
        brand: responseLists,
        colors: responseLists,
        weights: responseLists,
        deliveryTypes: responseLists,
        measures: responseLists,
        sizes: {
            message: '',
            isLoaded: false,
            data: {} as AdSizesProps[],
        },
        sizesListAll: {
            message: '',
            isLoaded: false,
            data: [{
                _id: '',
                categorizacoes: [],
                nome: '',
                ordem: 0,
                tamanhos: [],
            }],
        },
        userCommission: {
            data: 10,
            message: '',
            isLoaded: false,
        },
    },
    newAd: {
        title: '',
        description: '',
        quantity_days_available: '',
        composition: '',
        others: '',
        brand: [],
        condition: [],
        size: [],
        category: [],
        categoryHistory: [],
        weight: [],
        price: '',
        deliveryTypes: deliveryTypes,
        measureTable: [],
    },
};
