import React from 'react';
import { MenuItem, MessageBox } from '@components';
import { Box, useTheme } from '@mui/material';
// import { ads } from '../../../../api/services/ads';
// import { BrandsProps } from '@interfaces';
import { useAds } from '../../../../hooks/ads';

const Brands = () => {
    const { palette } = useTheme();
    // const [brands, setBrands] = React.useState<BrandsProps>({} as BrandsProps);
    // const { load } = ads;
    const { loadBrands, ad } = useAds();

    // const loadBrands = async () => {
    //     setBrands((prev) => prev = {
    //         ...prev, isLoaded: false,
    //     });
    //     const response = await load.brands();
    //     if (!response?.error) {
    //         setBrands({
    //             isLoaded: true,
    //             data: response?.response.payload,
    //         });
    //     } else {
    //         setBrands((prev) => prev = {
    //             ...prev, isLoaded: true,
    //         });
    //     }
    // };

    React.useEffect(() => {
        loadBrands();
    }, []);

    return (
        <React.Fragment>
            <Box sx={{
                minHeight: '3.75rem',
            }}>
                {
                    ad.brand.isLoaded && (ad.brand.data.length > 0 ?
                        ad.brand.data.map(({
                            _id,
                            nome,
                        }) => {
                            return (
                                <MenuItem
                                    key={`${nome}-${_id}`}
                                    borderType={'bottom'}
                                    title={{
                                        text: nome,
                                        color: palette.dark,
                                        size: 'medium',
                                    }}
                                    htmlFor={_id} />
                            );
                        }) : <MessageBox
                            type='info'
                            message='Nenhuma marca cadastrada'
                        />)}

            </Box>
        </React.Fragment>
    );
};

export { Brands };
