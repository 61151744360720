import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Box, Grid, TextField } from '@mui/material';
import {
    FilterContent,
    FilterFooter,
} from '../../../components/filters';

const PriceFilter = () => {
    return (
        <Box height='100%' component='form'>
            <FilterContent minHeightContent={114}>

                <Box p={2}>
                    <Grid container spacing={2}>
                        <Grid item xs={5}>
                            <TextField
                                focused
                                fullWidth
                                id="outlined-min"
                                label="mínimo"
                                variant="outlined"
                                size='small'
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Box
                                display='flex'
                                alignItems='center'
                                justifyContent='center'
                                height='100%'>
                                <I
                                    icon={['far', 'long-arrow-right']}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={5}>
                            <TextField
                                focused
                                fullWidth
                                id="outlined-min"
                                label="máximo"
                                variant="outlined"
                                size='small'
                            />
                        </Grid>
                    </Grid>
                </Box>
            </FilterContent>
            <FilterFooter
                onClearAction={() => { }}
                onSubmitAction={() => { }}
            />
        </Box>
    );
};

export { PriceFilter };
