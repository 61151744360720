export const filterPriceList = [
    {
        _id: '27adf7d349b9',
        nome: 'price_min',
        param: 'pmin=',
        filter: '0',
    },
    {
        _id: 'b5222ff74249',
        nome: 'price_max',
        param: 'pmax=',
        filter: '9999999',
    },
];
