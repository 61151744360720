/* eslint-disable max-len */
import React from 'react';
import { Stack } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    IconBadge,
} from '@components';

import { sx } from './sx';
import { useUser } from '@hooks';
import { useCart } from '../../../hooks/shopping/cart';

const FixedMenu: React.FC = () => {
    const { cart } = useCart();

    const { user, favorites } = useUser();

    const { sxStack } = sx();
    const navigate = useNavigate();
    const location = useLocation();

    const handleIcon = (action: string) => {
        if (action === location.pathname) {
            return 'fas';
        }
        return 'fal';
    };

    const cartItemsCount = cart.stores.data.map((store) => store.itens).reduce((list, item) => {
        return list + item.length;
    }, 0);

    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            sx={{ ...sxStack }}>
            <IconBadge
                onClick={() => navigate('/')}
                icon={[handleIcon('/'), 'house']} />
            <IconBadge
                onClick={() => navigate('/buscar')}
                icon={[handleIcon('/buscar'), 'magnifying-glass']} />
            {user.isLoaded &&
                (user.data?.cpf ?
                    <IconBadge
                        onClick={() => navigate('/meus-favoritos')}
                        badgeContent={favorites.list.total}
                        icon={[handleIcon('/meus-favoritos'), 'heart']} /> :
                    <IconBadge
                        onClick={() => navigate('/produtos/adicionar')}
                        icon={[handleIcon('/produtos/adicionar'), 'bullhorn']} />)
            }
            {user.isLoaded && user.data?.cnpj ?
                <IconBadge
                    badgeContent={0}
                    onClick={() => navigate('/minhas-vendas')}
                    icon={[handleIcon('/minhas-vendas'), 'store']} /> :
                <IconBadge
                    badgeContent={cartItemsCount}
                    onClick={() => navigate('/minha-sacola')}
                    icon={[handleIcon('/minha-sacola'), 'bag-shopping']} />
            }
            {!user.isLoaded && (
                <IconBadge
                    onClick={() => navigate('/login')}
                    icon={[handleIcon('/login'), 'user']} />
            )}
        </ Stack>
    );
};

export { FixedMenu };
