import { BankAccountProps } from '@interfaces/Contexts';
import { client } from '../../client';

const findWallet = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/wallet');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const findMovementHistory = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/wallet/movement');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const addBankAccount = async (data: BankAccountProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/marketplace/create-account', data);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadBankAccount = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/user/bank-account');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const updateBankAccount = async (data: BankAccountProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/transfer/update-account', data);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

// * IUGU

const loadBalances = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/iugu/balances');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadMovements = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/iugu/list/movements');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadReceivables = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/iugu/list/receivables');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadBankTransfers = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/iugu/list/bank_transfers');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export {
    findWallet,
    findMovementHistory,
    addBankAccount,
    updateBankAccount,
    loadBankAccount,
    loadBalances,
    loadMovements,
    loadReceivables,
    loadBankTransfers,
};
