import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { AlertMessageProps } from '@interfaces';

import { sx } from './sx';

const MessageBox: React.FC<AlertMessageProps> = ({
    message,
    type,
}: AlertMessageProps) => {
    const { css } = sx();
    const { typography, palette } = useTheme();

    const typeObj = {
        error: {
            icon: ['fal', 'circle-exclamation'] as IconProp,
            color: palette.status.rejected,
        },
        warning: {
            icon: ['far', 'triangle-exclamation'] as IconProp,
            color: palette.status.pending,
        },
        info: {
            icon: ['fal', 'circle-info'] as IconProp,
            color: palette.americanRiver,
        },
        success: {
            icon: ['far', 'circle-check'] as IconProp,
            color: palette.status.accepted,
        },
    };

    return (
        <Box sx={{ ...css.sxBox }}>
            <Box mb={typography.pxToRem(4)}>
                <I
                    icon={typeObj[type].icon}
                    fontSize={32}
                    color={typeObj[type].color}
                />
            </Box>
            <Typography
                textAlign={'center'}
                sx={{ ...css.sxMessage }}
                variant='caption'>
                {message}
            </Typography>
        </Box>
    );
};

export { MessageBox };
