import { Grid, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';


const skeletonElems = () => {
    const { typography } = useTheme();

    const rm = (px: number) => typography.pxToRem(px);
    const m = useMediaQuery('(min-width:600px)');


    const mediaQuery = {
        quicklink: {
            height: rm(m ? 98 : 70),
            width: rm(m ? 98 : 70),
        },
        quicklinkText: {
            height: 20,
            width: '30%',
        },
        productTitle: {
            height: 22,
            width: '40%',
        },
        productPrice: {
            height: 24,
            width: '20%',
        },
    };

    const elems = {
        quicklink: [
            <Skeleton variant="circular" sx={mediaQuery.quicklink} />,
            <Skeleton variant="rectangular" sx={mediaQuery.quicklinkText} />,
        ],
    };
    return elems;
};

export const SkeletonQuicklink = () => {
    const { quicklink } = skeletonElems();
    return (
        <Stack
            direction={'row'}
            spacing={1}
            justifyContent={'center'}>
            {Array.from(Array(4).keys()).map(() => (
                <Grid item lg={3} md={3} sm={6} xs={6}>
                    <Stack spacing={1} alignItems='center'>
                        {quicklink.map((skel) => skel)}
                    </Stack>
                </Grid>
            ))}
        </Stack>
    );
};
