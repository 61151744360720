import React, { ChangeEvent } from 'react';
import {
    Container,
    Box,
    Typography,
    Grid,
    TextField,
    Link,
    CircularProgress,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    ButtonLarge,
    Snackbar,
} from '@components';
import { FormSecurityCode, SecCodeKey, ToggleStaticProps } from '@interfaces';

import $ from 'jquery';
import 'jquery-mask-plugin';

import { sx } from './sx';
import { Form } from './styles';
import { formSchemaCode } from './yup';
import { useAuth } from '../../../hooks/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../../hooks/user';
import { localStorage } from '../../../utils/localStorage';

const VerificationCode: React.FC = () => {
    const { css } = sx();
    const code1 = React.useRef<HTMLInputElement>(null);
    const code2 = React.useRef<HTMLInputElement>(null);
    const code3 = React.useRef<HTMLInputElement>(null);
    const code4 = React.useRef<HTMLInputElement>(null);
    const code5 = React.useRef<HTMLInputElement>(null);
    const code6 = React.useRef<HTMLInputElement>(null);
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const [feedback, setFeedback] = React.useState<{message: string, type: string}>();
    const [isLoading, setIsLoading] = React.useState(false);

    const { loadUser } = useUser();
    const auth = useAuth();
    const navigate = useNavigate();
    const params = useParams();

    const texts = {
        title: {
            email: `Um código de 6 dígitos foi enviado para o email,
            não se esqueça de verificar a caixa de spam`,
            sms: `Um código de 6 dígitos foi enviado para o 
            seu telefone`,
        },
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormSecurityCode>({
        resolver: yupResolver(formSchemaCode),
        mode: 'onBlur',
    });

    const onSubmit: SubmitHandler<FormSecurityCode> = async (data) => {
        setIsLoading(true);
        const { code1, code2, code3, code4, code5, code6 } = data;
        const securityCode =
        Number(`${code1}${code2}${code3}${code4}${code5}${code6}`);

        const response = await auth.validationAccount(securityCode);
        if (response?.error) {
            setFeedback({ message: response?.response
                .message, type: response?.response.type });
            snackRef.current?.show();
            setIsLoading(false);
            return;
        } else {
            setFeedback({ message: response?.response
                .message, type: response?.response.type });
            snackRef.current?.show();

            localStorage({
                action: 'add',
                key: '@auth',
                value: 'pc2l0b3ItYXBwbGljYXRpb24tc2',
            });

            await loadUser();

            setTimeout(() => {
                navigate('/');
            }, 1500);
        }
    };

    const resendEmailValidation = async () => {
        const email = !!(params.type === 'email');
        const sms = !!(params.type === 'sms');

        const response = await auth.resendValidation(email, sms);
        if (response?.error) {
            setFeedback({ message: response?.response
                .message, type: response?.response.type });
            snackRef.current?.show();
            return;
        }
        setFeedback({ message: response?.response
            .message, type: response?.response.type });
        snackRef.current?.show();
    };

    const handleCodeInput = ({
        codeKey,
        event,
    }: {
        codeKey: SecCodeKey;
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;
      }) => {
        const { value } = event.target;
        const max = value.slice(0, 1);
        const match = value.match(/^[0-9]*$/) ? max : '';

        if (value.length >= 1) {
            switch (codeKey) {
            case 'code1':
                if (match) code2.current && code2.current?.focus();
                break;
            case 'code2':
                if (match) code3.current && code3.current?.focus();
                break;
            case 'code3':
                if (match) code4.current && code4.current?.focus();
                break;
            case 'code4':
                if (match) code5.current && code5.current?.focus();
                break;
            case 'code5':
                if (match) code6.current && code6.current?.focus();
                break;
            case 'code6':
                if (match) code6.current && code6.current?.blur();
                break;
            default:
                break;
            }
        }
    };

    const inputMasks = () => {
        $('.code').mask('0');
    };

    React.useEffect(() => {
        inputMasks();
    }, []);

    return (
        <Container
            maxWidth={'xs'}
            sx={{ ...css.sxContainer }}>
            <Box sx={{ ...css.sxBoxAccess }}>
                <Typography
                    variant='h2'
                    textAlign={'center'}
                    sx={{ ...css.sxAccessText }}
                >
                    {texts.title[params.type as 'sms' | 'email']}
                </Typography>
                <Typography
                    variant='caption'
                    textAlign={'center'}
                    sx={{ ...css.sxTypeText }}
                >
                    Digite o código no campo abaixo para confirmar sua conta
                </Typography>
            </Box>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={2}>
                        <TextField
                            variant='outlined'
                            id={'outlined-code1'}
                            error={!!errors.code1}
                            aria-describedby={'outlined-type-code1'}
                            fullWidth={true}
                            className={`${
                                errors.code1 ?
                                    'type-code field-stepper error' :
                                    'type-code field-stepper'
                            }`}
                            inputProps={{
                                'aria-label': 'Type first code',
                                'className': 'code',
                            }}
                            {...register('code1', { required: true })}
                            onChange={(event) =>
                                handleCodeInput({
                                    codeKey: 'code1', event: event })
                            }
                            inputRef={code1}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            variant='outlined'
                            id={'outlined-code2'}
                            error={!!errors.code2}
                            aria-describedby={'outlined-type-code2'}
                            fullWidth={true}
                            className={`${
                                errors.code2 ?
                                    'type-code field-stepper error' :
                                    'type-code field-stepper'
                            }`}
                            inputProps={{
                                'aria-label': 'Type first code',
                                'className': 'code',
                            }}
                            {...register('code2', { required: true })}
                            onChange={(event) =>
                                handleCodeInput({
                                    codeKey: 'code2', event: event })
                            }
                            inputRef={code2}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            variant='outlined'
                            id={'outlined-code3'}
                            error={!!errors.code3}
                            aria-describedby={'outlined-type-code3'}
                            fullWidth={true}
                            className={`${
                                errors.code3 ?
                                    'type-code field-stepper error' :
                                    'type-code field-stepper'
                            }`}
                            inputProps={{
                                'aria-label': 'Type first code',
                                'className': 'code',
                            }}
                            {...register('code3', { required: true })}
                            onChange={(event) =>
                                handleCodeInput({
                                    codeKey: 'code3', event: event })
                            }
                            inputRef={code3}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            variant='outlined'
                            id={'outlined-code4'}
                            error={!!errors.code4}
                            aria-describedby={'outlined-type-code4'}
                            fullWidth={true}
                            className={`${
                                errors.code4 ?
                                    'type-code field-stepper error' :
                                    'type-code field-stepper'
                            }`}
                            inputProps={{
                                'aria-label': 'Type first code',
                                'className': 'code',
                            }}
                            {...register('code4', { required: true })}
                            onChange={(event) =>
                                handleCodeInput({
                                    codeKey: 'code4', event: event })
                            }
                            inputRef={code4}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            variant='outlined'
                            id={'outlined-code5'}
                            error={!!errors.code5}
                            aria-describedby={'outlined-type-code5'}
                            fullWidth={true}
                            className={`${
                                errors.code5 ?
                                    'type-code field-stepper error' :
                                    'type-code field-stepper'
                            }`}
                            inputProps={{
                                'aria-label': 'Type first code',
                                'className': 'code',
                            }}
                            {...register('code5', { required: true })}
                            onChange={(event) =>
                                handleCodeInput({
                                    codeKey: 'code5', event: event })
                            }
                            inputRef={code5}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            variant='outlined'
                            id={'outlined-code6'}
                            error={!!errors.code5}
                            aria-describedby={'outlined-type-code6'}
                            fullWidth={true}
                            className={`${
                                errors.code6 ?
                                    'type-code field-stepper error' :
                                    'type-code field-stepper'
                            }`}
                            inputProps={{
                                'aria-label': 'Type first code',
                                'className': 'code',
                            }}
                            {...register('code6', { required: true })}
                            onChange={(event) =>
                                handleCodeInput({
                                    codeKey: 'code6', event: event })
                            }
                            inputRef={code6}
                        />
                    </Grid>
                </Grid>
                <Link
                    variant='caption'
                    onClick={resendEmailValidation}
                    sx={{ ...css.sxResendLink }}>
                        Reenviar código
                </Link>
                <ButtonLarge
                    {...css.sxButtonLarge}
                    type={'submit'}
                    text={'Enviar'}
                    onClick={() => null}>
                    {isLoading &&
                        <CircularProgress
                            size={14}
                            color='inherit'
                            sx={{ ml: 0.5 }} />
                    }
                </ButtonLarge>
            </Form>
            <Snackbar
                ref={snackRef}
                message={feedback? feedback.message: ''}
                severity={feedback? feedback.type as any: 'success'}
            />
        </Container>
    );
};

export { VerificationCode };
