import * as api from './calls';

export const purchases = {
    load: api.load,
    order: {
        actions: {
            cancel: api.cancelOrder,
        },
    },
};
