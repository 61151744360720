import * as api from './calls';

const ads = {
    create: api.create,
    update: api.update,
    search: api.search,
    list: api.list,
    find: api.find,
    findMeasureTable: api.findMeasureTable,
    findMeasureTableByAdID: api.findMeasureTableByAdID,
    action: api.action,
    variations: {
        create: api.createVariation,
        update: api.updateVariation,
        delete: api.deleteVariation,
        findByID: api.findVariationByID,
        find: {
            byAdID: api.findVariationByAdID,
        },
    },
    load: {
        highlighted: api.highlighted,
        conditions: api.loadConditions,
        brands: api.loadBrands,
        weights: api.loadWeights,
        sizes: api.loadSizes,
        sizesByCategory: api.loadSizesByCategory,
        userCommission: api.userAdCommission,
        deliveryTypes: api.loadShippingOptions,
        materials: api.loadMaterials,
        styles: api.loadStyles,
        sustainability: api.loadSustainabilityOptions,
        measure: api.loadMeasureTypes,
        colors: api.loadColors,
        // variations: api.loadVariations,
    },
};

export { ads };
