import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Container, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { HeaderPage } from '@components';

import { sx } from './sx';

interface PageMessageTemplateProps {
    title: string;
    substile?: string;
    children: React.ReactNode;
}

const PageMessageTemplate: React.FC<PageMessageTemplateProps> = (
    { title, substile, children,
    }: PageMessageTemplateProps) => {
    const { css } = sx();
    const navigate = useNavigate();
    return (
        <Container>
            <HeaderPage
                onClick={() => navigate(-1)}
                icon={['far', 'arrow-left-long']}
                titleAlign={'default'}
                title={'Voltar'} />
            <Container
                sx={{ ...css.sxContainer }}
                maxWidth='lg'>
                <Stack
                    spacing={1}
                    alignItems={'center'}>
                    <I
                        style={{ ...css.sxLockIcon }}
                        icon={['fas', 'lock']} />
                    <Typography sx={{ ...css.sxTitle }} variant='h2'>
                        {title}<br />
                        {substile && (
                            <span> {substile} </span>
                        )}
                    </Typography>
                    {children}
                </Stack>
            </Container>
        </Container>
    );
};

export { PageMessageTemplate };
