import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette, typography } = useTheme();
    const css = {
        sxBoxContainer: {
            padding: matches ? '60px 100px' : '0',
            minHeight: '40.1875rem',
        },
        sxLink: {
            display: 'flex',
            padding: '0.9375rem',
            textDecoration: 'none',
            marginBottom: '0.625rem',
        },
        sxTextLink: {
            'fontSize': matches ? '0.9375rem' : '0.875rem',
            'color': palette.dark,
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        sxIconLinkBack: {
            fontSize: '1.125rem',
            color: palette.dark,
        },
        sxTitle: {
            fontWeight: typography.fontWeightBold,
            fontSize: matches ? '1.75rem' : '1.125rem',
            padding: matches ? '0.625rem 0.9375rem' : '0.625rem 0',
        },
        sxBoxAnswer: {
            'padding': matches ? '0.9375rem' : '0.9375rem 0',
        },
        sxAnswerText: {
            'fontSize': '1rem',
            'fontWeight': typography.fontWeightRegular,
            '& > ul': {
                marginLeft: '1.875rem',
            },
            '& > .listdecimal': {
                listStyle: 'decimal',
            },
            '& > .listdisc': {
                listStyle: 'disc',
            },
        },
        sxBoxGrid: {
            display: 'flex',
            marginTop: matches ? '0.625rem' : 0,
        },
    };
    return { css };
};
