import {
    Box,
    Grid,
    TextField,
    InputAdornment,
    IconButton,
    Button,
    useTheme,
    useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

const FormPeriod = () => {
    const { typography, palette } = useTheme();
    const { pxToRem } = typography;

    const matches = useMediaQuery('(min-width:600px)');

    return (
        <Box pt={3}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        focused
                        fullWidth
                        label={'Início'}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <I
                                            icon={['far',
                                                'calendar-days']}
                                            fontSize={18}
                                            color={palette.dark}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        focused
                        fullWidth
                        label={'Fim'}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <I
                                            icon={['far',
                                                'calendar-days']}
                                            fontSize={18}
                                            color={palette.dark}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        focused
                        fullWidth
                        label={'Buscar'}
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <I
                                            icon={['far',
                                                'magnifying-glass']}
                                            fontSize={18}
                                            color={palette.dark}
                                        />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }} />
                </Grid>
            </Grid>
            <Box
                display='flex'
                justifyContent='center'
                mt={pxToRem(15)}>
                <Box
                    maxWidth={matches ? 320 : '100%'}
                    width='100%'
                    justifyContent='center'>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type='submit'
                        className='MuiButton secondary'
                    >
                        Filtrar
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export { FormPeriod };
