/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable max-len */
import React, { RefAttributes } from 'react';
import { FormStaticProps, ToggleStaticProps } from '@interfaces';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    Stack,
    TextField,
    Typography,
    alpha,
    useMediaQuery,
    useTheme,
    Collapse,
    Alert,
} from '@mui/material';
import { ForwardRefExoticComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { useAds } from '@hooks';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { BoxContent, MenuItem, MuiDialog, Price, SmallTableColumn, UploadImagePreview, Snackbar, Image } from '@components';
import { sx } from '../../sx';
import { FilterContent, FilterFooter } from '../../../../../../../components/filters';
import { FormAddColor } from '../variant-color';
import { FormAddSizes } from '../variant-sizes';
import { formatNumberToPriceBRL, images } from '@utils';
import { useParams } from 'react-router-dom';

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

interface FormAddVariantProps {
    color: string;
}

interface ComponentProps {
    editVariationID?: string;
}

const DEFAULTS = {
    CUSTOM_ERRORS: {
        images: {
            error: false,
            message: '',
        },
        items: {
            error: false,
            message: '',
        },
        quantity: {
            error: false,
            message: '',
        },
        colors: {
            error: false,
            message: '',
        },
        price: {
            error: false,
            message: '',
        },
        name: {
            error: false,
            message: '',
        },
    },
    EDIT_ID: {
        itemListID: '',
        sizeID: '',
        action: 'add',
    },
};

export const FormAddVariant: ForwardRefExoticComponent<ComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            editVariationID,
        } = props;
        const dialogColors = React.useRef<ToggleStaticProps>(null);
        const dialogSize = React.useRef<ToggleStaticProps>(null);
        const refAddColors = React.useRef<FormStaticProps>(null);
        const refAddSize = React.useRef<FormStaticProps>(null);
        const snackRef = React.useRef<ToggleStaticProps>(null);
        const uploadRef = React.useRef<HTMLInputElement>(null);

        const params = useParams();

        const { css } = sx();
        const ads = useAds();

        const { palette, typography } = useTheme();
        const matches = useMediaQuery('(min-width:600px)');

        const [editID, setEditID] = React.useState(DEFAULTS.EDIT_ID);
        const [customErrors, setCustomErrors] = React.useState(DEFAULTS.CUSTOM_ERRORS);
        const [isLoaded, setIsLoaded] = React.useState(false);
        const [sizesList, setSizesList] = React.useState<any[]>([]);

        const {
            register,
            setValue,
            handleSubmit,
            reset,
            formState: { errors },
        } =
            useForm<FormAddVariantProps>({
                mode: 'all',
            });

        const clearColorsData = (type: 'add' | 'addCustom') => {
            ads.variations.colors[type]({
                _id: '',
                imagem: '',
                nome: '',
                customColor: false,
            });
        };

        const resetHookData = () => {
            clearColorsData('add');
            clearColorsData('addCustom');
            ads.variations.images.clear();
            ads.variations.items.reset();
        };

        const handleLoadSizes = async (_id: string) => {
            const response = await ads.loadSizes({
                type: 'byCategory',
                categoriesList: [_id],
            });
            if (!response?.error) {
                const sizes = !!(response?.response.payload);
                if (!sizes) {
                    ads.createNewAd.insertData({
                        type: 'size',
                        data: {
                            size: [],
                        },
                    });
                }
            }
        };

        const findSizesAD = async () => {
            const response = await ads.find({ id: params.id as string });
            const data = await response?.response.payload;
            const category = data.categorizacao.map((cat: any) => cat._id);
            const lastCategoryID = category[category.length - 1];
            if (data.tamanho) {
                setSizesList(data.tamanho);
                handleLoadSizes(lastCategoryID);
                ads.createNewAd.insertData({
                    type: 'size', data: {
                        size: data.tamanho,
                    },
                });
            }
        };

        const deleteSizeListItem = async (_id: string) => {
            ads.variations.items.remove({ _id: _id });
        };

        const onSubmit: SubmitHandler<FormAddVariantProps> = async (data) => {
            // const findQuantity = ads.variations.items.list.find((item) => item.stockQuantity === 0);
            const findPrice = ads.variations.items.list.find((item) => item.price === 0);
            const findName = ads.variations.items.list.find((item) => !!item.sizeName || !!item.nome);
            // if (findQuantity) {
            //     setCustomErrors((prev) => prev = {
            //         ...prev,
            //         quantity: {
            //             message: 'Adicione uma quantidade de stoque para ' + findQuantity.nome ?? findQuantity.sizeName,
            //             error: true,
            //         },
            //     });
            //     setIsLoaded(false);
            //     setTimeout(() => snackRef.current?.show(), 100);
            //     return;
            // }

            if (findPrice) {
                setCustomErrors((prev) => prev = {
                    ...prev,
                    price: {
                        message: 'Adicione um preço',
                        error: true,
                    },
                });
                setIsLoaded(false);
                return;
            }

            if (!findName) {
                setCustomErrors((prev) => prev = {
                    ...prev,
                    name: {
                        message: 'Adicione um tamanho na variação',
                        error: true,
                    },
                });
                setIsLoaded(false);
                return;
            }

            setCustomErrors((prev) => prev = DEFAULTS.CUSTOM_ERRORS);
            setIsLoaded(true);

            if (!(ads.variationsAD.images.variation.list.length > 0)) {
                setCustomErrors((prev) => prev = {
                    ...prev,
                    images: {
                        ...prev.images,
                        message: 'Adicione ao menos uma imagem',
                        error: true,
                    },
                });
                setIsLoaded(false);
                return;
            }
            if (!(ads.variations.items.list.length > 0)) {
                setCustomErrors((prev) => prev = {
                    ...prev,
                    items: {
                        ...prev.items,
                        message: 'Adicione ao menos uma variação de tamanho',
                        error: true,
                    },
                });
                setIsLoaded(false);
                return;
            }

            const adFormData = new FormData();

            const reduceItems = ads.variations.items.list.reduce((list: any, item: any) => {
                const arr = [];
                if (item.size) {
                    arr.push(...list, {
                        size: item.size,
                        sku: item.sku,
                        price: item.price,
                        stockQuantity: item.stockQuantity,
                    });
                } else {
                    arr.push(...list, {
                        name: item.nome,
                        sku: item.sku,
                        price: item.price,
                        stockQuantity: item.stockQuantity,
                    });
                }

                return arr;
            }, []);

            const variation: any = {
                items: reduceItems,
            } as any;

            ads.variationsAD.images.variation.list.map(({ file }) => {
                return adFormData.append('pictures', file);
            });

            if (!ads.variationsAD.colors.selected.list.customColor) {
                variation.color = ads.variationsAD.colors.selected.list._id;
                delete variation.colorName;
                adFormData.delete('colorImage');
            } else {
                variation.colorName = ads.variationsAD.colors.selected.list.nome;
                adFormData.append('colorImage', ads.variationsAD.colors.customColor.list.file);
                delete variation.color;
            }

            if (editVariationID) {
                variation.id = editVariationID as string;
                variation.idAd = params.id as string;
                adFormData.append('variation', JSON.stringify(variation));
                const response = await ads.createNewAd.variations.update({
                    ad: adFormData as any,
                });

                if (!response?.error) {
                    // await loadVariations(params.id as string);
                    await ads.variationsAD.find.byAdID({ id: params.id as string });
                    resetHookData();
                    setIsLoaded(false);
                    ads.externalActions.action({ action: 'variationDialog', state: true });
                } else {
                    setCustomErrors((prev) => prev = {
                        ...prev,
                        colors: {
                            message: response.response.message,
                            error: true,
                        },
                    });
                    setIsLoaded(false);
                    setTimeout(() => snackRef.current?.show(), 100);
                }
                return;
            } else {
                adFormData.append('variation', JSON.stringify(variation));
                const response = await ads.createNewAd.variations.create({
                    adID: params.id as string, // 646171b8a54f672358bd74f0
                    ad: adFormData as any,
                });

                if (!response?.error) {
                    // await loadVariations(params.id as string);
                    await ads.variationsAD.find.byAdID({ id: params.id as string });
                    resetHookData();
                    setIsLoaded(false);
                    ads.externalActions.action({ action: 'variationDialog', state: true });
                } else {
                    setCustomErrors((prev) => prev = {
                        ...prev,
                        colors: {
                            message: response.response.message,
                            error: true,
                        },
                    });
                    setIsLoaded(false);
                    setTimeout(() => snackRef.current?.show(), 100);
                }
            }
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        const rm = (px: number) => typography.pxToRem(px);

        const excerpt = (text: string, count: number) => {
            if (count > text.length) {
                return text;
            }
            const substring = text.substring(0, count) + '...';
            return substring;
        };

        const resetItemsErrors = () => {
            setCustomErrors((prev) => prev = {
                ...prev,
                quantity: DEFAULTS.CUSTOM_ERRORS.quantity,
                price: DEFAULTS.CUSTOM_ERRORS.price,
                name: DEFAULTS.CUSTOM_ERRORS.name,
            });
        };

        const forwardEditID = (sizeID: string, itemListID: string, action: 'add' | 'edit') => {
            setEditID((prev) => prev = {
                sizeID,
                itemListID,
                action,
            });
            resetItemsErrors();
            dialogSize.current?.show();
        };

        // const handleOnSubmitSize = () => {
        //     refAddSize.current?.onSubmit();
        //     // dialogSize.current?.hide();
        // };

        const handleOnSubmitColor = () => {
            // refAddColors.current?.onSubmit();
            dialogColors.current?.hide();
            setEditID((prev) => prev = DEFAULTS.EDIT_ID);
        };

        const clearRefValue = () => {
            if (uploadRef.current?.value) {
                return uploadRef.current.value = '';
            };
        };

        React.useEffect(() => {
            if (ads.externalActions.status.sizeDialog.state) {
                // handleOnSubmitSize();?
                dialogSize.current?.hide();

                ads.externalActions.action({ action: 'sizeDialog', state: false });
            }
        }, [ads.externalActions.status.sizeDialog.state]);

        React.useEffect(() => {
            if (ads.externalActions.status.colorsDialog.state) {
                dialogColors.current?.hide();
                setEditID((prev) => prev = DEFAULTS.EDIT_ID);

                ads.externalActions.action({ action: 'colorsDialog', state: false });
            }
        }, [ads.externalActions.status.colorsDialog.state]);

        // React.useEffect(() => {
        //     if (ads.variations.colors.data.list.color.nome) {
        //         setValue('color', ads.variations.colors.data.list.color.nome);
        //         dialogColors.current?.hide();
        //     }
        // }, [ads.variations.colors.data.list.color.nome]);

        React.useEffect(() => {
            if (ads.variationsAD.colors.selected.list.nome) {
                setValue('color', ads.variationsAD.colors.selected.list.nome);
            } else {
                reset({ color: '' });
            }
        }, [ads.variationsAD.colors.selected.list.nome]);

        React.useEffect(() => {
            if (ads.variationsAD.images.variation.list.length >= 1) {
                setCustomErrors((prev) => prev = {
                    ...prev,
                    images: DEFAULTS.CUSTOM_ERRORS.images,
                });
            }
        }, [ads.variationsAD.images.variation.list]);

        React.useEffect(() => {
            if (!(sizesList.length > 0)) {
                findSizesAD();
            }
        }, []);

        // React.useEffect(() => {
        //     if (variations.colors.data.customList.color.nome) {
        //         setValue('color', variations.colors.data.customList.color.nome);
        //         dialogColors.current?.hide();
        //     }
        // }, [variations.colors.data.customList.color.nome]);
        const imagePath = () => {
            const path = ads.variationsAD.colors.selected.list.imagem;
            if (!path) {
                return {
                    blob: false,
                    url: '',
                    error: true,
                };
            };

            if (path.indexOf('blob:http') !== -1) {
                const split = path.split('blob:http');
                return {
                    blob: true,
                    url: 'blob:http' + split.join(''),
                    error: false,
                };
            } else {
                return {
                    blob: false,
                    url: path,
                    error: false,
                };
            }
        };

        return (
            <>
                {/* <Snackbar
                    ref={snackRef}
                    message={customErrors.colors.message}
                    severity={'error'}
                /> */}
                <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)} pt={2}>
                    <FilterContent minHeightContent={61}>
                        <Container maxWidth='sm'>

                            <Box mb={3}>
                                <Box mb={2}>
                                    <Typography
                                        variant="h1"
                                        fontSize={16}
                                        fontWeight={typography.fontWeightBold}
                                        color={palette.dark}
                                        mb={1}
                                    >
                                        Fotos · {ads.variationsAD.images.variation.list.length}/4
                                    </Typography>
                                    <Typography
                                        variant="h1"
                                        fontSize={14}
                                        fontWeight={typography.fontWeightRegular}
                                        color={palette.dark}
                                    >
                                        Upload máximo de 4 fotos.<br />
                                        Arraste as fotos para ordenar como preferir!
                                    </Typography>
                                </Box>
                                <DragDropContext onDragEnd={ads.variationsAD.images.dnd.onDragEnd}>
                                    <Droppable
                                        droppableId="droppable"
                                        direction="horizontal">
                                        {(provided, snapshot) => (
                                            <Grid
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                container spacing={2}>
                                                {ads.variationsAD.images.variation.list.length < 4 && (
                                                    <Grid item md={2} xs={4}>
                                                        <UploadImagePreview
                                                            id={'upload-product-image-' + ads.variationsAD.
                                                                images.
                                                                variation.
                                                                list.
                                                                length + 1}
                                                            height={matches ? 80 : 85}
                                                            type='upload'
                                                            onChange={ads.variationsAD.images.variation.add}
                                                            refprop={uploadRef} />
                                                        <Typography
                                                            variant="h1"
                                                            fontSize={10}
                                                            fontWeight={typography.fontWeightRegular}
                                                            color={palette.dark}
                                                            mt={1}
                                                        >
                                                            JPG E PNG Somente *
                                                        </Typography>
                                                    </Grid>
                                                )}

                                                {ads.variationsAD.images.variation.list.map((item, index) => (
                                                    <Draggable key={item.id}
                                                        draggableId={item.id}
                                                        index={index}>
                                                        {(provided, snapshot) => (
                                                            <Grid
                                                                ref={provided.innerRef}
                                                                {...provided.
                                                                    draggableProps}
                                                                {...provided.
                                                                    dragHandleProps}
                                                                item
                                                                md={3}
                                                                xs={4}>

                                                                <UploadImagePreview
                                                                    id='upload-product-image'
                                                                    type='preview'
                                                                    height={matches ? 80 : 85}
                                                                    src={item.src}
                                                                    onDelete={() => {
                                                                        ads.variationsAD.images.variation.delete(item.id);
                                                                        clearRefValue();
                                                                    }}
                                                                    refprop={uploadRef} />
                                                            </Grid>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </Grid>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                                {customErrors.images.error && (
                                    <FormHelperText error>{customErrors.images.message}</FormHelperText>
                                )}
                            </Box>

                            <Grid container spacing={2}>
                                <Grid item xs={12} mb={1}>
                                    <TextField
                                        focused
                                        fullWidth
                                        label={'Cor'}
                                        variant="outlined"
                                        helperText={errors.color ?
                                            errors.color.message :
                                            ''}
                                        error={!!errors.color}
                                        {...register('color',
                                            { required: true },
                                        )}
                                        onClick={() => {
                                            dialogColors.current?.show();
                                            setCustomErrors((prev) => prev = {
                                                ...prev,
                                                colors: DEFAULTS.CUSTOM_ERRORS.colors,
                                            });
                                        }}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Stack
                                                        direction='row'
                                                        alignItems='center'
                                                        spacing={1}>
                                                        {!imagePath().error && (
                                                            <Image
                                                                src={images.load(imagePath().url,
                                                                    !imagePath().blob ?
                                                                        'server' :
                                                                        'local')}
                                                                alt={ads.variationsAD.colors.selected.list.nome}
                                                                maxHeight={26} />
                                                        )}

                                                    </Stack>
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <I
                                                            icon={['far', 'chevron-right']}
                                                            fontSize={16}
                                                            color={palette.dark}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                            </Grid>
                            {errors.color && (
                                <FormHelperText error>Adicione uma cor</FormHelperText>
                            )}
                            {customErrors.colors.error && (
                                <Collapse in={customErrors.colors.error}>
                                    <Alert severity="info">{customErrors.colors.message}</Alert>
                                </Collapse>
                            )}
                        </Container>
                        <Box mb={rm(10)} />
                        <Grid container spacing={2}>
                            <Grid item xs={12} mb={1}>
                                {ads.variations.items.list.map(({
                                    itemListID,
                                    size,
                                    sizeName,
                                    nome,
                                    price,
                                    sku,
                                    stockQuantity,
                                    _id,
                                }, i) => {
                                    return (
                                        <BoxContent
                                            paddingLeft
                                            borderTop
                                            borderBottom
                                            actions={[
                                                {
                                                    actionType: 'edit',
                                                    actionFunction:
                                                        () => forwardEditID(_id, String('item-' + i + 1), 'edit'),
                                                },
                                                {
                                                    actionType: 'delete',
                                                    actionFunction: () => deleteSizeListItem(itemListID ?
                                                        itemListID : _id),
                                                }]} >
                                            <>
                                                <Stack direction='row' alignItems='baseline' spacing={3} mb={rm(10)}>
                                                    {sizeName && (
                                                        <SmallTableColumn
                                                            title='Tamanho'
                                                            value={<Chip label={sizeName as string}
                                                                size='small' sx={css.content.chip} />} />
                                                    )}
                                                    {nome && (
                                                        <SmallTableColumn
                                                            title='Tamanho'
                                                            value={<Chip label={nome}
                                                                size='small' sx={css.content.chip} />} />
                                                    )}
                                                </Stack>
                                                <Stack direction='row' alignItems='baseline' spacing={3} mb={rm(10)}>
                                                    <SmallTableColumn
                                                        title='Preço'
                                                        value={<Price
                                                            size='small'
                                                            value={formatNumberToPriceBRL(price)} />}
                                                    />
                                                    <SmallTableColumn
                                                        title='Quantidade'
                                                        value={stockQuantity} />
                                                    <SmallTableColumn
                                                        title='SKU'
                                                        value={<Typography
                                                            variant="body1"
                                                            fontSize={rm(14)}
                                                            fontWeight={500}
                                                            color={palette.dark}
                                                            component='p'>
                                                            {excerpt(sku, 5)}
                                                        </Typography>} />
                                                </Stack>
                                            </>
                                        </BoxContent>
                                    );
                                })}
                            </Grid>
                        </Grid>
                        {customErrors.quantity.error && (
                            <Container maxWidth='sm'>
                                <Collapse in={customErrors.quantity.error}>
                                    <Alert severity="info">{customErrors.quantity.message}</Alert>
                                </Collapse>
                            </Container>
                        )}
                        {customErrors.price.error && (
                            <Container maxWidth='sm'>
                                <Collapse in={customErrors.price.error}>
                                    <Alert severity="info">{customErrors.price.message}</Alert>
                                </Collapse>
                            </Container>
                        )}
                        {customErrors.name.error && (
                            <Container maxWidth='sm'>
                                <Collapse in={customErrors.name.error}>
                                    <Alert severity="info">{customErrors.name.message}</Alert>
                                </Collapse>
                            </Container>
                        )}

                        <Box mb={rm(15)} />
                        {/* <Box sx={{ opacity: !(ads.variations.items.list.length === sizesList.length) ? 1 : 0.5 }}> */}
                        <Box>
                            <Container maxWidth='sm'>
                                <MenuItem
                                    borderTop
                                    borderType={'square'}
                                    boxShadow
                                    title={{
                                        text: 'Adicionar tamanho',
                                        fontWeight: 'bold',
                                        size: 'medium',
                                    }}
                                    icon={{
                                        path: ['fal', 'plus-square'],
                                        size: 'medium',
                                    }}
                                    onClick={() => {
                                        dialogSize.current?.show();
                                        setEditID((prev) => prev = DEFAULTS.EDIT_ID);
                                        setCustomErrors((prev) => prev = {
                                            ...prev,
                                            items: DEFAULTS.CUSTOM_ERRORS.items,
                                        });
                                    }}
                                    // onClick={!(ads.variations.items.list.length === sizesList.length) ?
                                    //     () => {
                                    //         dialogSize.current?.show();
                                    //         setEditID((prev) => prev = DEFAULTS.EDIT_ID);
                                    //     } : () => { }}
                                    children={
                                        <Box p={2}>
                                            <I icon={['far', 'chevron-right']} />
                                        </Box>
                                    } />
                                {customErrors.items.error && (
                                    <FormHelperText error>{customErrors.items.message}</FormHelperText>
                                )}
                            </Container>
                        </Box>
                    </FilterContent>
                    <Box
                        display='flex'
                        p={[1, 2]}
                        sx={{
                            '& .MuiButton-outlined': {
                                m: 1,
                            },
                            ...{
                                position: 'relative',
                                justifyContent: 'center',
                                display: 'flex',
                                backgroundColor: palette.common.white,
                                borderTopLeftRadius: 6,
                                borderTopRightRadius: 6,
                                boxShadow: !matches ? '0 -6px 12px 0 ' +
                                    alpha(palette.common.black, 0.2) : 'none',
                            },
                        }}>
                        <Button
                            variant="outlined"
                            fullWidth
                            disabled={isLoaded}
                            size='small'
                            type='submit'
                            sx={{
                                'maxWidth': '16.75rem',
                                '&:hover': {
                                    backgroundColor: palette.dark,
                                    border: 0,
                                },
                                '&.Mui-disabled': {
                                    color: palette.common.white,
                                    opacity: 0.7,
                                },
                                ...{
                                    borderRadius: 0.5,
                                    textTransform: 'capitalize',
                                    backgroundColor: palette.dark,
                                    fontWeight: typography.fontWeightBold,
                                    color: palette.common.white,
                                    border: 0,
                                },
                            }}>
                            {!isLoaded ? 'Aplicar' :
                                <CircularProgress size={20} color='inherit' />
                            }
                        </Button>
                    </Box>
                </Box >
                <MuiDialog
                    maxWidth='sm'
                    fullScreen={!matches}
                    ref={dialogColors}
                    contentPadding={false}
                    onCancel={{
                        action: () => dialogColors.current?.hide(),
                    }}
                    title='Cor da variação'
                    showDialogTitle>
                    <FormAddColor ref={refAddColors} onSubmitAction={handleOnSubmitColor}>
                        <FilterFooter
                            onClearAction={() => { }}
                            onSubmitAction={handleOnSubmitColor} />
                    </FormAddColor>
                </MuiDialog>
                <MuiDialog
                    maxWidth='sm'
                    fullScreen={!matches}
                    ref={dialogSize}
                    contentPadding={false}
                    onCancel={{
                        action: () => dialogSize.current?.hide(),
                    }}
                    title='Tamanho da variação'
                    showDialogTitle>
                    <FormAddSizes
                        ref={refAddSize}
                        editID={editID.sizeID}
                        itemListID={editID.itemListID}
                        action={editID.action as 'add' | 'edit'}>
                        {/* <FilterFooter
                            onClearAction={() => { }}
                            onSubmitAction={handleOnSubmitSize} /> */}
                    </FormAddSizes>
                </MuiDialog>
            </>
        );
    });
