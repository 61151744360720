import {
    Box,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React from 'react';
import { ButtonSelectPeriod } from '../buttons/select-period';
import { FormPeriod } from '../../../../../filters/wallet/period';
import { FilterWalletProps } from '@interfaces';

const Wallet = ({ filterList }: FilterWalletProps) => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const { pxToRem } = typography;

    const [filtersList, setFiltersList] = React.useState(filterList);

    const handleFilterActive = (id: string) => {
        const list = filtersList.map((item) => {
            if (item.id === id) {
                return {
                    ...item,
                    active: true,
                };
            } else {
                return {
                    ...item,
                    active: false,
                };
            }
        });
        setFiltersList(list);
    };
    return (
        <Box
            pt={2}
            pb={2}
            pl={3}
            pr={3}
            ml={matches ? -3 : -2}
            mr={matches ? -3 : -2}
            sx={{
                backgroundColor: palette.clouds,
            }}>
            <Typography
                variant='h2'
                component='h2'
                fontSize={14}
                fontWeight={typography.fontWeightBold}
                mb={pxToRem(5)}
            >
                Selecione o período:
            </Typography>
            <Box sx={{ overflowX: 'auto' }}>
                <Box sx={{
                    ...{
                        display: 'inline-flex',
                        pt: pxToRem(10),
                        pb: pxToRem(10),
                    },
                    '& .MuiButton-root': {
                        ml: pxToRem(10),
                        whiteSpace: 'nowrap',
                    },
                }}>
                    {filtersList.map(({ id, title, active }) => (
                        <ButtonSelectPeriod
                            key={id}
                            title={title}
                            active={active}
                            onClick={() => handleFilterActive(id)}
                        />
                    ))}
                </Box>
            </Box>

            {!!filtersList.find(({ type, active }) =>
                type === 'custom' && active) && (
                <FormPeriod />
            )}
        </Box>
    );
};

export { Wallet };
