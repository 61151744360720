/* eslint-disable no-unused-vars */
/* eslint-disable quotes */
/* eslint-disable max-len */
/* eslint-disable indent */
import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    Alert,
    AlertColor,
    Backdrop,
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    debounce,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Popover,
    Select,
    Snackbar,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import $ from 'jquery';
import 'jquery-mask-plugin';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from './yup';
import { FormControlLabel, FormControlSelect, Textarea } from './styles';
import { DeliveryType, MuiDialog, Price, UploadImagePreview } from '@components';
import { CreateNewAdProps, FormStaticProps, ToggleStaticProps } from '@interfaces';
import { WeightInfo } from '../info/weight';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useAds, useOthers } from '@hooks';
import { HandleExternalsComponentsProps, NewAdDataProps, NewAdRemoveData } from '@interfaces/Contexts';
import { SizesFilter } from '../../../../../filters/products/sizes';
import { CategoriesFilter } from '../../../../../filters/products/categories';
import { adPriceWithCommission, formatNumberToPriceBRL } from '@utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MeasurementsList } from '../measurement-chart/list';
import { sx } from './sx';
import { ResponseAsyncProps } from '@interfaces/ClientServicesApi';

const newAd = () => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const ads = useAds();
    const { categories } = useOthers();
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const { css } = sx();
    // const location = useLocation();

    const dialogweight = React.useRef<ToggleStaticProps>(null);
    const dialogprice = React.useRef<ToggleStaticProps>(null);
    const dialogcategories = React.useRef<ToggleStaticProps>(null);
    const dialogsizes = React.useRef<ToggleStaticProps>(null);
    const dialogmeasurements = React.useRef<ToggleStaticProps>(null);
    const [anchorPopoverEl, setAnchorPopoverEl] = React.useState<HTMLButtonElement | null>(null);

    const sizesref = React.useRef<FormStaticProps>(null);
    const categoriesref = React.useRef<FormStaticProps>(null);
    const qtdref = React.useRef<HTMLInputElement>(null);

    const uploadref = React.useRef<HTMLInputElement>(null);

    const [priceWithCommission, setPriceWithCommission] = React.useState('0.00');
    const [validatePhotos, setValidatePhotos] = React.useState({
        visibility: false,
        message: '',
    });
    const [backdrop, setBackdrop] = React.useState(true);
    const [responseAD, setResponseAD] = React.useState({
        message: '',
        type: '' as AlertColor,
    });
    const [isLoadingResponse, setIsLoadingResponse] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    // const [price, setPrice] = React.useState<string>('0');

    let sellerCommission = 0;
    const openPopover = Boolean(anchorPopoverEl);
    const idPopover = openPopover ? 'qtd-days-popover' : undefined;

    const { register, handleSubmit, setValue, control, setError, clearErrors, formState: { errors } } =
        useForm<CreateNewAdProps>({
            resolver: yupResolver(formSchema(ads.ad.sizes ? ads.ad.sizes.isLoaded : false)),
            mode: 'onChange',
            shouldUnregister: false,
        });

    const debounceSave = React.useRef(debounce((value: number) => commission(value), 1000)).current;

    const handleMeasuresDialog = () => {
        dialogmeasurements.current?.hide();
    };

    const handleClickPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorPopoverEl(event.currentTarget);
    };

    const onSubmit: SubmitHandler<CreateNewAdProps> = async (data) => {
        setBackdrop(true);
        if (ads.createNewAd.images.list.length > 0) {
            const formData = new FormData();

            const measureTable = ads.createNewAd.data.measureTable ?
                ads.createNewAd.data.measureTable.reduce((list: any, item) => {
                    const map = item.measures.map((m) => {
                        return {
                            measure: m._id,
                            value: Number.parseFloat(m.value as string),
                        };
                    });
                    return list = [...list, {
                        size: item.size,
                        measures: map,
                    }];
                }, []) : [];

            const selectedHistoryCategories = categories.list.history.map(({ _id }) => _id);
            const categoriesList = ads.createNewAd.data.category ?
                ads.createNewAd.data.category?.map((cat) => cat._id) : [];
            const selectedCategoriesList = (selectedHistoryCategories.length > 0) ?
                [...selectedHistoryCategories, categoriesList[0]] :
                categoriesList;

            const object = {
                title: data.title,
                description: data.description,
                quantity_days_available: data.quantity_days_available as any,
                composicao: data.composition,
                outros: data.others as any,
                condition: { _id: "5f2c06ae5eb35d408c6a8016" },
                listSelectedCategorization: selectedCategoriesList,
                weight: { _id: data.weight },
                // price: parseFloat(data.price.replace(/\./g, '').replace(',', '.')),
                price: 0,
                deliveryTypes: {
                    aplicativo_entrega: true,
                    entrega_maos: false,
                },
                size: ads.createNewAd.data.size?.map((size) => size._id),
                measureTable: measureTable,
                // stockQuantity: data.stockQuantity,
                // sku: data.sku as any,
            };

            if (!data.others) delete object['outros'];
            // if (!data.sku) delete object['sku'];
            if (!data.quantity_days_available) delete object['quantity_days_available'];
            if (!(!!ads.createNewAd.data.size?.length)) {
                delete object['size'];
                delete object['measureTable'];
            };
            ads.createNewAd.images.list.map(({ file }, i) => {
                return formData.append(`image${i + 1}`, file);
            });

            formData.append('ad', JSON.stringify(object));

            setValidatePhotos((prev) => prev = {
                visibility: false,
                message: '',
            });

            const response = (!(location.pathname.indexOf('/produtos/editar') !== -1) &&
                !params.id ?
                await ads.createNewAd.create(formData as any) :
                await ads.update(params.id as string, formData as any)) as ResponseAsyncProps;

            if (!response.error) {
                setResponseAD((prev) => prev = {
                    message: response.response.message,
                    type: 'success',
                });
                setIsLoadingResponse((prev) => prev = false);
                setBackdrop(false);
                // if (ads.createNewAd.data.size &&
                //     ads.createNewAd.data.size?.length > 0) {
                    navigate('/produtos/adicionar/variantes/' + response.response.payload.idAd);
                // } else {
                //     navigate('/produto/revisar/' + response.response.payload.idAd);
                // }
            } else {
                setBackdrop(false);
                setResponseAD((prev) => prev = {
                    message: response.response.message,
                    type: 'error',
                });
                setIsLoadingResponse((prev) => prev = false);
                setSnackbarOpen((prev) => prev = true);
            }
        } else {
            setBackdrop(false);
            setValidatePhotos((prev) => prev = {
                visibility: true,
                message: 'Você deve selecionar ao menos uma foto!',
            });
        };
    };

    const handleExternalsComponents = ({ type }: HandleExternalsComponentsProps) => {
        switch (type) {
            case 'size':
                sizesref.current?.onSubmit();
                setTimeout(() => {
                    dialogsizes.current?.hide();
                }, 200);
                break;
            case 'category':
                categoriesref.current?.onSubmit();
                setTimeout(() => {
                    dialogcategories.current?.hide();
                }, 200);
                break;
            default:
                break;
        }
    };

    const clearAdData = ({ type }: Omit<NewAdRemoveData, 'data'>) => {
        switch (type) {
            case 'size':
                ads.createNewAd.clearAdData({ type: 'size' });
                break;
            case 'category':
                ads.createNewAd.clearAdData({ type: 'category' });
                categories.handleCategorization({});
                categories.reset({ type: 'history' });
                break;

            default:
                break;
        }
    };

    const handlePrice = (priceProps: string) => {
        debounceSave(parseFloat(priceProps.replace(/\./g, '').replace(',', '.')));
    };

    const commission = (price: number) => {
        setPriceWithCommission((prev) =>
            prev = adPriceWithCommission(Number(price), String(sellerCommission)));
    };

    const closeCategoriesDialog = () => {
        dialogcategories.current?.hide();
    };

    const handleLoadSizes = async (_id: string) => {
        const response = await ads.loadSizes({
            type: 'byCategory',
            categoriesList: [_id],
        });
        if (!response?.error) {
            const sizes = !!(response?.response.payload);
            if (!sizes) {
                ads.createNewAd.insertData({
                    type: 'size',
                    data: {
                        size: [],
                    },
                });
                ads.createNewAd.insertData({
                    type: 'measureTable', data: {
                        measureTable: [],
                    },
                });
                ads.measures.clearMeasuresAdData();
            }
        }
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const clearRef = () => {
        if (uploadref.current?.value) {
            return uploadref.current.value = '';
        };
    };

    React.useEffect(() => {
        if (!ads.ad.weights.isLoaded &&
            !ads.ad.measures.isLoaded) {
            Promise.all([
                ads.loadUserCommission(),
                ads.loadWeights(),
                ads.loadMeasureTypes()])
                .then((response) => {
                    setBackdrop((prev) => prev = false);
                    if (!response[0]?.error) {
                        sellerCommission = response[0]?.response.payload;
                    }
                })
                .catch((err) => { });
        } else {
            setBackdrop((prev) => prev = false);
        }
    }, []);

    // React.useEffect(() => {
    //     if (ads.createNewAd.data.stockQuantity) {
    //         const value = ads.createNewAd.data.stockQuantity;
    //         setTimeout(() => {
    //             setValue('stockQuantity', value as string);
    //         }, 300);
    //     }
    // }, [ads.createNewAd.data.stockQuantity]);

    React.useEffect(() => {
        if (ads.createNewAd.data.sku) {
            const value = ads.createNewAd.data.sku;
            setTimeout(() => {
                setValue('sku', value as string);
            }, 300);
        }
    }, [ads.createNewAd.data.sku]);

    React.useEffect(() => {
        if (ads.createNewAd.data.title) {
            const value = ads.createNewAd.data.title;
            setTimeout(() => {
                setValue('title', value as string);
            }, 300);
        }
    }, [ads.createNewAd.data.title]);

    React.useEffect(() => {
        if (ads.createNewAd.data.composition) {
            const value = ads.createNewAd.data.composition;
            setTimeout(() => {
                setValue('composition', value as string);
            }, 300);
        }
    }, [ads.createNewAd.data.composition]);

    React.useEffect(() => {
        if (ads.createNewAd.data.quantity_days_available) {
            const value = ads.createNewAd.data.quantity_days_available;
            setTimeout(() => {
                setValue('quantity_days_available', value as string);
            }, 300);
        }
    }, [ads.createNewAd.data.quantity_days_available]);

    React.useEffect(() => {
        if (ads.createNewAd.data.others) {
            const value = ads.createNewAd.data.others;
            setTimeout(() => {
                setValue('others', value as string);
            }, 300);
        }
    }, [ads.createNewAd.data.others]);

    // React.useEffect(() => {
    //     if (ads.createNewAd.data.stockQuantity) {
    //         const value = ads.createNewAd.data.stockQuantity;
    //         setTimeout(() => {
    //             setValue('stockQuantity', value as string);
    //         }, 300);
    //     }
    // }, [ads.createNewAd.data.stockQuantity]);

    React.useEffect(() => {
        if (ads.createNewAd.data.sku) {
            const value = ads.createNewAd.data.sku;
            setTimeout(() => {
                setValue('sku', value as string);
            }, 300);
        }
    }, [ads.createNewAd.data.sku]);

    React.useEffect(() => {
        if (ads.createNewAd.data.description) {
            const value = ads.createNewAd.data.description;
            setTimeout(() => {
                setValue('description', value);
            }, 300);
        }
    }, [ads.createNewAd.data.description]);

    React.useEffect(() => {
        if (ads.createNewAd.data.price) {
            const value = ads.createNewAd.data.price;
            setTimeout(() => {
                debounceSave(Number(ads.createNewAd.data.price));
                setValue('price', formatNumberToPriceBRL(Number(value)));
            }, 300);
        }
    }, [ads.createNewAd.data.price]);

    // React.useEffect(() => {
    //     if (ads.createNewAd.data.deliveryTypes?.aplicativo_entrega) {
    //         setTimeout(() => {
    //             setValue('aplicativo_entrega', true);
    //         }, 300);
    //     }
    // }, [ads.createNewAd.data.deliveryTypes?.aplicativo_entrega]);

    React.useEffect(() => {
        if (ads.createNewAd.data.weight &&
            ads.createNewAd.data.weight.length) {
            setTimeout(() => {
                setValue('weight', ads.createNewAd.data.weight ?
                    ads.createNewAd.data.weight[0]._id : '', { shouldValidate: true });
            }, 300);
        }
    }, [setValue]);

    React.useEffect(() => {
        if (ads.createNewAd.data.size &&
            ads.createNewAd.data.size.length) {
            setTimeout(() => {
                setValue('size', `\n\n `, { shouldValidate: false });
            }, 300);
            ads.createNewAd.clearAdData({ type: 'categoryHistory' });
        }
    }, [ads.createNewAd.data.size, ads.createNewAd.data.category]);

    React.useEffect(() => {
        if (ads.createNewAd.data.category &&
            ads.createNewAd.data.category.length) {
            const category = ads.createNewAd.data.category;
            const lastCategoryID = category[category.length - 1];
            const value = category.
            map((c, i, arr) => arr.length !== i + 1 ?
                c.nome + ' > ' :
                c.nome).toString().replaceAll(',', '');
            setValue('category', value);
            handleLoadSizes(lastCategoryID._id);
        }
    }, [ads.createNewAd.data.category]);

    React.useEffect(() => {
        if (ads.createNewAd.data.size &&
            ads.createNewAd.data.size.length &&
            location.pathname.indexOf('/produtos/editar') !== -1) {
            if (ads.createNewAd.data.category &&
                ads.createNewAd.data.category.length) {
                const category = ads.createNewAd.data.category;
                const lastCategoryID = category[category.length - 1];
                handleLoadSizes(lastCategoryID._id);
            }
            setTimeout(() => {
                setValue('size', `\n\n `, { shouldValidate: false });
                ads.createNewAd.clearAdData({ type: 'categoryHistory' });
            }, 300);
        }
    }, [ads.createNewAd.data.category]);

    // React.useEffect(() => {
    //     if (ads.createNewAd.data.category &&
    //         ads.createNewAd.data.category.length) {
    //         const value = ads.createNewAd.data.category[0].nome;
    //         setTimeout(() => {
    //             setValue('category', value);
    //         }, 300);
    //     }
    // }, [ads.createNewAd.data.category]);

    React.useEffect(() => {
        if (ads.createNewAd.images.list.length > 0) {
            setValidatePhotos((prev) => prev = {
                visibility: false,
                message: '',
            });
        }
    }, [ads.createNewAd.images.list]);

    React.useEffect(() => {
        $('.money').mask('000.000.000.000.000,00', {
            reverse: true, onChange: (e) => handlePrice(e),
        });
    }, []);

    return (
        <Box sx={css.wrapper}>
            <Backdrop sx={css.backdrop} open={backdrop}>
                <CircularProgress color="inherit" size={26} />
            </Backdrop>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={responseAD.type}>
                    {responseAD.message}
                </Alert>
            </Snackbar>
            <Container maxWidth='sm'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box mb={4}>
                        <Box mb={2}>
                            <Typography
                                variant="h1"
                                fontSize={16}
                                fontWeight={typography.fontWeightBold}
                                color={palette.dark}
                                mb={1}
                            >
                                Fotos · {ads.createNewAd.images.list.length}/4
                            </Typography>
                            <Typography
                                variant="h1"
                                fontSize={14}
                                fontWeight={typography.fontWeightRegular}
                                color={palette.dark}
                            >
                                Upload máximo de 4 fotos.<br />
                                Arraste as fotos para ordenar como preferir!
                            </Typography>
                        </Box>
                        <DragDropContext onDragEnd={ads.createNewAd.images.dnd.onDragEnd}>
                            <Droppable
                                droppableId="droppable"
                                direction="horizontal">
                                {(provided, snapshot) => (
                                    <Grid
                                        ref={provided.innerRef}
                                        {...provided.droppableProps}
                                        container spacing={2}>

                                        <Grid item md={2} xs={4}>
                                            <UploadImagePreview
                                                id='upload-product-image-1'
                                                height={matches ? 80 : 85}
                                                type='upload'
                                                onChange={ads.createNewAd.images.addNew}
                                                refprop={uploadref} />
                                            <Typography
                                                variant="h1"
                                                fontSize={10}
                                                fontWeight={typography.fontWeightRegular}
                                                color={palette.dark}
                                                mt={1}
                                            >
                                                JPG E PNG Somente *
                                            </Typography>
                                        </Grid>

                                        {ads.createNewAd.images.list.map((item, index) => (
                                            <Draggable key={item.id}
                                                draggableId={item.id}
                                                index={index}>
                                                {(provided, snapshot) => (
                                                    <Grid
                                                        ref={provided.innerRef}
                                                        {...provided.
                                                            draggableProps}
                                                        {...provided.
                                                            dragHandleProps}
                                                        item
                                                        md={4}
                                                        xs={4}>

                                                        <UploadImagePreview
                                                            key={item.id}
                                                            id='upload-product-image'
                                                            type='preview'
                                                            height={matches ? 80 : 85}
                                                            src={item.src}
                                                            onDelete={() => {
                                                                ads.createNewAd.images.delete(item.id);
                                                                clearRef();
                                                            }}
                                                            refprop={uploadref} />
                                                    </Grid>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </Grid>
                                )}
                            </Droppable>
                        </DragDropContext>
                        {validatePhotos.visibility && (
                            <FormHelperText error>
                                {validatePhotos.message}
                            </FormHelperText>
                        )}
                    </Box>
                    <Grid container spacing={2}>

                        <Grid item xs={12} mb={1}>
                            <TextField
                                focused
                                fullWidth
                                label={'Título *'}
                                variant="outlined"
                                helperText={errors.title ?
                                    errors.title.message :
                                    ''}
                                error={!!errors.title}
                                {...register('title',
                                    { required: true },
                                )} />
                        </Grid>
                        <Grid item xs={12} mb={1}>
                            <FormControlSelect>
                                <FormControlLabel id="description-label">
                                    Descrição *
                                </FormControlLabel>
                                <FormControl fullWidth error={!!errors.description}>
                                    <Textarea
                                        aria-label="minimum height"
                                        minRows={3}
                                        className={!!errors.description ?
                                            'error' :
                                            ''}
                                        {...register('description',
                                            { required: true },
                                        )}
                                    />
                                    {errors.description && (
                                        <FormHelperText>
                                            {errors.description?.message}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </FormControlSelect>
                        </Grid>

                        <Grid item xs={12} mb={1}>
                            <TextField
                                focused
                                fullWidth
                                label={'Composição *'}
                                variant="outlined"
                                helperText={errors.composition ?
                                    errors.composition.message :
                                    ''}
                                error={!!errors.composition}
                                {...register('composition',
                                    { required: true },
                                )} />
                        </Grid>

                        <Grid item xs={12} mb={1}>
                            <FormControlSelect>
                                <FormControlLabel id="others-label">
                                    Outros
                                </FormControlLabel>
                                <FormControl fullWidth error={!!errors.others}>
                                    <Textarea
                                        aria-label="minimum height"
                                        minRows={3}
                                        className={!!errors.others ?
                                            'error' :
                                            ''}
                                        {...register('others',
                                            { required: false },
                                        )}
                                    />
                                </FormControl>
                                <FormHelperText>
                                    Cuidados especiais · Sustentabilidade · Elementos de estilo
                                </FormHelperText>
                            </FormControlSelect>
                        </Grid>

                        <Grid item xs={12} mb={1}>
                            <TextField
                                focused
                                fullWidth
                                onClick={() => dialogcategories.current?.show()}
                                error={!!errors.category}
                                label={'Categoria e Subcategoria *'}
                                className='with-icon-end'
                                id="category-input"
                                variant="outlined"
                                helperText={errors.category ?
                                    errors.category.message : ''}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton>
                                                <I
                                                    icon={['far', 'chevron-right']}
                                                    fontSize={16}
                                                    color={palette.dark}
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                {...register('category', { required: true })} />
                        </Grid>
                        {ads.ad.sizes.isLoaded && (
                            <Grid item xs={12} mb={1}>
                                <TextField
                                    focused
                                    fullWidth
                                    onClick={() => dialogsizes.current?.show()}
                                    error={!!errors.size}
                                    label={'Tamanhos do item *'}
                                    className='with-icon-end'
                                    id="sizes-field"
                                    variant="outlined"
                                    helperText={errors.size ?
                                        errors.size.message : ''}
                                    InputProps={{
                                        readOnly: true,
                                        startAdornment: (
                                            <InputAdornment position="end">
                                                {ads.createNewAd.data.size ?
                                                    ads.createNewAd.data.size.map(({ nome }) => (
                                                        <Chip label={nome} size='small' sx={css.chip} />
                                                    )) :
                                                    'Selecione uma categoria'}
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <I
                                                        icon={['far', 'chevron-right']}
                                                        fontSize={16}
                                                        color={palette.dark}
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register('size', { required: ads.ad.sizes.isLoaded ? true : false })} />
                            </Grid>
                        )}

                        {ads.ad.sizes.isLoaded &&
                            ads.createNewAd.data.size &&
                            ads.createNewAd.data.size?.length > 0 && (
                                <Grid item xs={12} mb={1}>
                                    <TextField
                                        focused
                                        fullWidth
                                        onClick={() => dialogmeasurements.current?.show()}
                                        error={!!errors.measurements}
                                        label={'Tabela de medidas'}
                                        className='with-icon-end'
                                        id="measure-input"
                                        variant="outlined"
                                        helperText={errors.measurements ?
                                            errors.measurements.message : ''}
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: (
                                                <InputAdornment position="end">
                                                    {ads.createNewAd.data.measureTable &&
                                                        ads.createNewAd.data.measureTable?.length > 0 &&
                                                        ads.createNewAd.data.measureTable?.map(({ size }) => {
                                                            const mapSizes = ads.ad.sizes.data.map(({ tamanhos }) => {
                                                                return tamanhos.filter((item) => item._id === size);
                                                            })[0];
                                                            return (
                                                                mapSizes.map(({ nome }, i) => (
                                                                    <Chip key={`${i}-${nome}`} label={nome} size='small' sx={css.chip} />
                                                                ))
                                                            );
                                                        })}
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <I
                                                            icon={['far', 'chevron-right']}
                                                            fontSize={16}
                                                            color={palette.dark}
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                        {...register('measurements', { required: false })} />
                                </Grid>
                            )}

                        <Grid item xs={12} mb={1}>
                            <FormControl
                                fullWidth
                                className="custom_select"
                                focused
                                size='medium'>
                                <InputLabel id="Company-select-label" sx={css.inputLabel}>
                                    Peso *
                                </InputLabel>
                                <Controller
                                    name="weight"
                                    control={control}
                                    defaultValue=""
                                    render={({ field: { onChange, value } }) => (
                                        <Select
                                            error={!!errors.weight}
                                            defaultValue=""
                                            labelId="Company"
                                            className="input-container"
                                            id="Company"
                                            size="medium"
                                            value={value}
                                            {...register('weight', {
                                                required: true,
                                            })}
                                            onChange={onChange}
                                        >
                                            {ads.ad.weights.isLoaded ?
                                                ads.ad.weights.data.map(({ _id, nome }) => (
                                                    <MenuItem key={_id} value={_id}>{nome}</MenuItem>
                                                )) : 'Carregando pesos...'}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                            <FormHelperText sx={{
                                fontWeight: typography.fontWeightBold,
                                color: palette.dark,
                                fontSize: 14,
                                cursor: 'pointer',
                            }}
                                onClick={() =>
                                    dialogweight.current?.show()}>
                                Tabela de pesos
                            </FormHelperText>
                        </Grid>
                        {/* <Grid item xs={12} mb={1}>
                            <TextField
                                focused
                                fullWidth
                                label={'Preço *'}
                                variant="outlined"
                                id="outlined-basic"
                                type={'text'}
                                helperText={errors.price ?
                                    errors.price.message :
                                    ''}
                                error={!!errors.price}
                                {...register('price',
                                    { required: true },
                                )}
                                inputProps={{
                                    className: 'money',
                                    inputMode: 'decimal',
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography
                                                variant="h1"
                                                fontSize={13}
                                                fontWeight={typography.fontWeightMedium}
                                                color={palette.dark}
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Box component='span' mr={0.6}>
                                                    Valor a receber
                                                </Box>
                                                <Price size='tiny' value={priceWithCommission} />
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Typography
                                                variant="h1"
                                                fontSize={14}
                                                fontWeight={typography.fontWeightBold}
                                                color={palette.dark}
                                            >
                                                R$
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }} />
                            <FormHelperText
                                sx={{
                                    fontWeight: typography.fontWeightBold,
                                    color: palette.dark,
                                    fontSize: 14,
                                    cursor: 'pointer',
                                }}
                                onClick={() =>
                                    dialogprice.current?.show()}>
                                Entenda o valor a receber
                            </FormHelperText>
                        </Grid> */}
                        {/* <Grid item xs={12} mb={1}>
                            <TextField
                                focused
                                fullWidth
                                type='number'
                                label={'Quantidade em estoque *'}
                                variant="outlined"
                                helperText={errors.stockQuantity ?
                                    errors.stockQuantity.message :
                                    ''}
                                inputProps={{
                                    min: 1,
                                }}
                                error={!!errors.stockQuantity}
                                onFocus={(e) => e.target.addEventListener("wheel", function(e) {
                                    e.preventDefault();
                                }, { passive: false })}
                                {...register('stockQuantity',
                                    { required: true },
                                )} />
                        </Grid> */}
                        <Grid item xs={12} mb={1}>
                            <TextField
                                focused
                                fullWidth
                                label={
                                    <>
                                        Prazo para produção
                                        <IconButton
                                            aria-describedby={idPopover}
                                            onClick={handleClickPopover}
                                            aria-label="description quantity days"
                                            size="small">
                                            <I icon={['far', 'circle-info']} />
                                        </IconButton>
                                        <Popover
                                            id={idPopover}
                                            open={openPopover}
                                            anchorEl={anchorPopoverEl}
                                            onClose={() => setAnchorPopoverEl(null)}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            <Typography fontSize={12} sx={{ p: 1 }}>
                                                Se o produto é feito sob encomenda, você<br /> deve informar o prazo em dias para produção.
                                            </Typography>
                                        </Popover>
                                    </>}
                                variant="outlined"
                                id="outlined-basic"
                                type='number'
                                helperText={errors.quantity_days_available ?
                                    errors.quantity_days_available.message :
                                    ''}
                                error={!!errors.quantity_days_available}
                                {...register('quantity_days_available',
                                    { required: false },
                                )}
                                inputProps={{
                                    inputMode: 'numeric',
                                }}
                                onFocus={(e) => e.target.addEventListener("wheel", function(e) {
                                    e.preventDefault();
                                }, { passive: false })}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Typography
                                                variant="h1"
                                                fontSize={13}
                                                fontWeight={typography.fontWeightMedium}
                                                color={palette.dark}
                                            >
                                                Dias
                                            </Typography>
                                        </InputAdornment>
                                    ),
                                }} />
                        </Grid>
                        {/* <Grid item xs={12} mb={1}>
                            <TextField
                                focused
                                fullWidth
                                label={'SKU do item *'}
                                variant="outlined"
                                helperText={errors.sku ?
                                    errors.sku.message :
                                    ''}
                                error={!!errors.sku}
                                {...register('sku',
                                    { required: false },
                                )} />
                        </Grid> */}

                        <Grid item xs={12} mb={1}>
                            <Typography
                                variant="h1"
                                fontSize={14}
                                fontWeight={typography.fontWeightBold}
                                color={palette.dark}
                                mb={1}
                            >
                                Tipo de entrega *
                            </Typography>
                            <Grid item xs={3} mb={1}>
                                <DeliveryType
                                    type={'correios'}
                                    // error={!!errors.aplicativo_entrega}
                                    checkbox={{
                                        checked: true,
                                        ...register('aplicativo_entrega',
                                            {
                                                required: true,
                                            },
                                        ),
                                    }
                                    }
                                />
                            </Grid>
                            {/* {errors.aplicativo_entrega && (
                                <FormHelperText error>
                                    {errors.aplicativo_entrega?.message}
                                </FormHelperText>
                            )} */}
                        </Grid>
                    </Grid>

                    <Box
                        display='flex'
                        justifyContent='center'
                        mt={2}>
                        <Box
                            maxWidth={320}
                            width='100%'
                            justifyContent='center'>
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                type='submit'
                                className='MuiButton secondary'
                            >
                                {!isLoadingResponse ?
                                    'Continuar' : ([
                                        'Aguarde ',
                                        <CircularProgress color="inherit" size={18} />,
                                    ])}

                            </Button>
                        </Box>
                    </Box>
                </form>
            </Container>

            <MuiDialog
                maxWidth='sm'
                fullScreen={!matches}
                ref={dialogmeasurements}
                title='Tabela de medidas'
                showDialogTitle>
                <MeasurementsList>
                    <Box
                        display='flex'
                        justifyContent='center'
                        mt={2}
                        pb={2}>
                        <Box
                            maxWidth={320}
                            width='100%'
                            justifyContent='center'>
                            <Button
                                fullWidth
                                variant="contained"
                                size="large"
                                type='submit'
                                className='MuiButton secondary'
                                onClick={handleMeasuresDialog}
                            >
                                Salvar na lista de medidas
                            </Button>
                        </Box>
                    </Box>
                </MeasurementsList>
            </MuiDialog>

            <MuiDialog
                maxWidth='sm'
                fullScreen={!matches}
                ref={dialogsizes}
                title='Tamanhos do item'
                showDialogTitle>
                <SizesFilter
                    minHeightContent={53}
                    filterInputsType='checkbox'
                    onClearAction={() => clearAdData({ type: 'size' })}
                    onSubmitAction={() => handleExternalsComponents({ type: 'size' })}
                />
            </MuiDialog>

            <MuiDialog
                maxWidth='sm'
                fullScreen={!matches}
                ref={dialogcategories}
                contentPadding={false}
                onCancel={{
                    action: () => closeCategoriesDialog(),
                }}
                title='Categoria e Subcategoria'
                showDialogTitle>
                <CategoriesFilter
                    ref={categoriesref}
                    minHeightContent={62}
                    filterInputsType='radio'
                    onClearAction={() => clearAdData({ type: 'category' })}
                    onSubmitAction={() => handleExternalsComponents({ type: 'category' })}
                />
            </MuiDialog>

            <MuiDialog
                maxWidth='sm'
                fullScreen={!matches}
                ref={dialogweight}
                contentPadding={false}
                title='Tabela de pesos'
                showDialogTitle>
                <WeightInfo />
            </MuiDialog>

            <MuiDialog
                maxWidth='sm'
                fullScreen={!matches}
                ref={dialogprice}
                contentPadding={false}
                title='Valor a receber'
                showDialogTitle>
                <Box sx={{
                    padding: '0.9375rem',
                }}>
                    <Typography
                        variant="h1"
                        fontSize={16}
                        fontWeight={typography.fontWeightBold}
                        color={palette.dark}
                        mb={1}
                    >
                        Entenda o valor a receber:
                    </Typography>
                    <Typography
                        variant="h2"
                        fontSize={14}
                        fontWeight={typography.fontWeightRegular}
                        color={palette.dark}
                    >
                        O valor mostrado é calculado com base na taxa de comissão
                        negociada e que está em vigor no dia de hoje. No dia da venda,
                        caso tenha havido alguma outra negociação e a taxa tenha sido
                        alterada, o valor a receber será diferente.
                    </Typography>
                </Box>
            </MuiDialog>
        </Box >
    );
};

export { newAd };
