/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import {
    BoxContent,
    MenuItem,
    MuiDialog,
    Price,
    Product,
    SmallTableColumn,
    Snackbar,
    VariationHeading,
} from '@components';
import {
    Alert,
    AlertColor,
    Box,
    Button,
    Chip,
    CircularProgress,
    Collapse,
    Container,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import React from 'react';
import { FormStaticProps, ToggleStaticProps } from '@interfaces';
import { FormAddVariant } from './forms/add-variant';
import { useAds } from '@hooks';
import { formatNumberToPriceBRL, imagePathToFile, images } from '@utils';
import { AdImagesProps, LoadAdVariantionsByAdIDProps } from '@interfaces/Contexts';
// import { ResponseAsyncProps } from '@interfaces/ClientServicesApi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ProductPlaceholder from '@img/product-placeholder.png';
import { useVariations } from '../../../../../hooks/ads/vatiations';

const DEFAULTS = {
    ERRORS: {
        quantity: {
            message: '',
            error: false,
        },
    },
};

const Variants = () => {
    const dialogDeleteVariationRef = React.useRef<ToggleStaticProps>(null);
    const dialogAddVariant = React.useRef<ToggleStaticProps>(null);
    const refAddVariant = React.useRef<FormStaticProps>(null);
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const matches = useMediaQuery('(min-width:600px)');
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const { css } = sx();
    const { typography, palette } = useTheme();
    const rm = (px: number) => typography.pxToRem(px);

    const ads = useAds();
    const { variationsAD } = useVariations();

    const [variationID, setVariationID] = React.useState('');
    const [errors, setErrors] = React.useState(DEFAULTS.ERRORS);
    const [adStatus, setAdStatus] = React.useState({
        isLoaded: true,
    });
    const [responseAD, setResponseAD] = React.useState({
        message: '',
        type: '' as AlertColor,
    });

    const [currentVariation, setCurrentVariation] = React.useState<LoadAdVariantionsByAdIDProps>({
        fotos: [''],
    } as LoadAdVariantionsByAdIDProps);

    const excerpt = (text: string, count: number) => {
        if (count > text.length) {
            return text;
        }

        const substring = text.substring(0, count) + '...';
        return substring;
    };

    const loadVariations = async () => {
        setAdStatus((prev) => prev = { isLoaded: true });
        // const response = await ads.loadVariations(params.id as string);
        const response = await variationsAD.find.byAdID({ id: params.id as string });

        if (!response?.error) {
            setAdStatus((prev) => prev = { isLoaded: false });
        }
    };

    const clearVariationHookData = () => {
        variationsAD.images.variation.clear();
        variationsAD.colors.customColor.remove();
        variationsAD.colors.selected.clear();
        ads.variations.items.reset();
    };

    const handleEditVariant = async (variant: LoadAdVariantionsByAdIDProps) => {
        setErrors((prev) => prev = DEFAULTS.ERRORS);
        setVariationID((prev) => prev = variant._id);
        const responseFindVariation = await ads.variations.listByVariationID({ id: variant._id });
        const dataFindVariation = await responseFindVariation?.response.payload;

        const items = variant.itens.map((item, i) => {
            return {
                nome: item.nome ? item.nome : '',
                itemListID: 'item-' + i + 1,
                size: item.tamanho ? item.tamanho._id : '',
                sizeName: item.tamanho ? item.tamanho.nome : '',
                sku: item.sku,
                price: item.valor_por,
                stockQuantity: item.quantidade_estoque,
                _id: item._id,
            };
        });
        ads.variations.items.add({
            type: 'add', section: 'size', variants: {
                items: {
                    currentSizeID: '',
                    list: items,
                },
            },
        });

        ads.variationsAD.colors.selected.add({
            _id: variant.cor,
            imagem: variant.imagem_cor,
            nome: variant.nome_cor,
            customColor: !variant.cor ? true : false,
        });

        // if (variant.cor) {
        //     ads.variations.colors.add({
        //         _id: variant.cor,
        //         imagem: variant.imagem_cor,
        //         nome: variant.nome_cor,
        //         customColor: !variant.cor ? true : false,
        //     });
        //     clearVariationHookData('addCustom');
        // } else {
        //     ads.variations.colors.addCustom({
        //         _id: variant.cor,
        //         imagem: variant.imagem_cor,
        //         nome: variant.nome_cor,
        //         customColor: !variant.cor ? true : false,
        //     });
        //     clearVariationHookData('add');
        // }

        const imagesList = await Promise.all(dataFindVariation.fotos.map(async (image: string) => {
            const file = await imagePathToFile(images.load(image, 'server'));
            return ({
                id: image,
                src: images.load(image, 'server'),
                content: images.load(image, 'server'),
                file: file,
            });
        }));

        const fileColor = await imagePathToFile(images.load(dataFindVariation.imagem_cor, 'server'));
        const colorObject = {
            id: '',
            src: images.load(dataFindVariation.imagem_cor, 'server'),
            content: variant.nome_cor,
            file: fileColor,
        } as AdImagesProps;

        variationsAD.images.variation.addToEdit(imagesList);
        // ads.createNewAd.images.addToEdit('variation', imagesList);

        if (!variant.cor) {
            variationsAD.colors.customColor.addToEdit(colorObject);
            // ads.createNewAd.images.addToEdit('variation-custom-color', colorObject);
        } else {
            variationsAD.colors.customColor.addToEdit({} as AdImagesProps);
        }

        setTimeout(() => {
            dialogAddVariant.current?.show();
        }, 200);
    };

    const addNewVariantion = () => {
        setVariationID((prev) => prev = '');
        ads.variations.items.reset();
        ads.variations.images.clear();
        clearVariationHookData();

        setTimeout(() => {
            dialogAddVariant.current?.show();
        }, 200);
    };

    const openDialogDeleteVariationAD = (variation: LoadAdVariantionsByAdIDProps) => {
        setCurrentVariation((prev) => prev = variation);
        dialogDeleteVariationRef.current?.show();
    };

    const deleteVariation = async (_id: string) => {
        const response = await ads.createNewAd.variations.delete({
            idAd: params.id as string,
            variationID: _id,
        });

        if (!response?.error) {
            dialogDeleteVariationRef.current?.hide();
            setResponseAD((prev) => prev = {
                message: response?.response.message,
                type: 'success',
            });
            snackRef.current?.show();
            await loadVariations();
        }
    };

    const closeVariationDialog = () => {
        dialogAddVariant.current?.hide();
        clearVariationHookData();
    };

    const handleURLParamsToRedirect = () => {
        if (location.pathname.indexOf('/produtos/editar/variantes') !== -1) {
            return navigate('/meus-produtos');
        }

        if (location.pathname.indexOf('/produtos/adicionar/variantes') !== -1) {
            const variationsMap = variationsAD.list.byAdID.data.map((item) => item.itens)[0];
            const variationsDefault = variationsAD.list.byAdID.data.find((item) => item.variacao_padrao);
            // const findQuantity = variationsMap.find((variation) => variation.quantidade_estoque === 0);

            // if (findQuantity) {
            //     setErrors((prev) => prev = {
            //         ...prev,
            //         quantity: {
            //             message: 'Adicione uma quantidade de stoque para o produto',
            //             error: true,
            //         },
            //     });
            //     return;
            // };
            setErrors((prev) => prev = DEFAULTS.ERRORS);

            return navigate('/produto/revisar/' + params.id+'?variantion='+variationsDefault?._id);
        }
    };

    const checkAndOpenDefaultItemDialog = () => {
        const variationsMap = variationsAD.list.byAdID.data[0];

        if ((variationsMap.nome_cor === 'Padrão') &&
            (variationsMap.imagem_cor === 'default.png')) {
            handleEditVariant(variationsMap);
        }
    };

    React.useEffect(() => {
        if (!adStatus.isLoaded) {
            if (variationsAD.list.byAdID.isLoaded) {
                checkAndOpenDefaultItemDialog();
            }
        }
    }, [adStatus.isLoaded]);

    React.useEffect(() => {
        if (params.id) {
            loadVariations();
        }
    }, []);

    React.useEffect(() => {
        if (ads.externalActions.status.variationDialog.state) {
            dialogAddVariant.current?.hide();
            ads.externalActions.action({ action: 'variationDialog', state: false });
            clearVariationHookData();
        }
    }, [ads.externalActions.status.variationDialog.state]);

    return (
        <>
            <Snackbar
                ref={snackRef}
                message={responseAD.message}
                severity={responseAD.type}
            />
            {adStatus.isLoaded && (
                <Box sx={css.loadingBox}>
                    <CircularProgress sx={{ color: palette.dark }} size={20} />
                    <Typography
                        variant="h2"
                        component='h2'
                        fontSize={16}
                        fontWeight={typography.fontWeightMedium}
                        color={palette.dark}
                        pt={typography.pxToRem(8)}>
                        Aguarde..
                    </Typography>
                </Box>
            )}
            {!adStatus.isLoaded && (
                <Box sx={css.content.wrapper}>
                    <Container maxWidth='sm'>
                        <VariationHeading
                            title='Variações'
                            subtitle='Exemplos de variações são: Cores e tamanhos' />
                        {variationsAD.list.byAdID.isLoaded ?
                            variationsAD.list.byAdID.data.map((variation) => (
                                <BoxContent
                                    key={variation._id}
                                    paddingLeft
                                    borderTop
                                    borderBottom
                                    actions={[
                                        {
                                            actionType: 'edit',
                                            actionFunction:
                                                () => handleEditVariant(variation),
                                        },
                                        {
                                            actionType: 'delete',
                                            actionFunction:
                                                () => openDialogDeleteVariationAD(variation),
                                        }]} >
                                    <Product
                                        size={'medium'}
                                        align={'right'}
                                        contentAlign='center'
                                        src={images.load(variation.imagem_cor, 'server')}
                                        title={variation.nome_cor} />
                                    {variation.itens.map(({
                                        _id,
                                        tamanho,
                                        quantidade_estoque,
                                        sku,
                                        nome,
                                        valor_por }) => {
                                        return (
                                            <Stack
                                                key={_id}
                                                direction='row'
                                                alignItems='baseline'
                                                spacing={3}
                                                mb={rm(10)}>
                                                {tamanho && (
                                                    <SmallTableColumn
                                                        title='Tamanho'
                                                        value={<Chip
                                                            label={tamanho ? tamanho.nome : ''}
                                                            size='small'
                                                            sx={css.content.chip} />} />
                                                )}
                                                {nome && (
                                                    <SmallTableColumn
                                                        title='Tamanho'
                                                        value={<Chip
                                                            label={nome ? nome : ''}
                                                            size='small'
                                                            sx={css.content.chip} />} />
                                                )}
                                                <SmallTableColumn
                                                    title='Preço'
                                                    value={<Price
                                                        size='small'
                                                        value={
                                                            String(formatNumberToPriceBRL(valor_por))}
                                                    />} />
                                                <SmallTableColumn
                                                    title='Quantidade'
                                                    value={quantidade_estoque} />
                                                <SmallTableColumn
                                                    title='SKU'
                                                    value={<Typography
                                                        variant="body1"
                                                        fontSize={rm(14)}
                                                        fontWeight={500}
                                                        color={palette.dark}
                                                        component='p'>
                                                        {excerpt(sku, 5)}
                                                    </Typography>} />
                                            </Stack>
                                        );
                                    })}
                                </BoxContent>

                            )) : 'Carregando...'}
                        {errors.quantity.error && (
                            <Collapse in={errors.quantity.error} >
                                <Alert severity="info">{errors.quantity.message}</Alert>
                            </Collapse>
                        )}
                        <Box mb={rm(15)} />

                        <MenuItem
                            borderTop
                            borderType={'square'}
                            boxShadow
                            title={{
                                text: 'Adicionar variação',
                                fontWeight: 'bold',
                                size: 'medium',
                            }}
                            icon={{
                                path: ['fal', 'plus-square'],
                                size: 'medium',
                            }}
                            onClick={addNewVariantion}
                            children={
                                <Box p={2}>
                                    <I icon={['far', 'chevron-right']} />
                                </Box>
                            } />
                        <Box
                            display='flex'
                            justifyContent='center'
                            mt={2}>
                            <Box
                                maxWidth={320}
                                width='100%'
                                justifyContent='center'>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    type='submit'
                                    className='MuiButton secondary'
                                    onClick={handleURLParamsToRedirect}
                                >
                                    Continuar
                                </Button>
                            </Box>
                        </Box>
                    </Container>
                </Box>
            )}

            <MuiDialog
                maxWidth='sm'
                fullScreen={!matches}
                ref={dialogAddVariant}
                contentPadding={false}
                onCancel={{ action: closeVariationDialog }}
                title='Variação do produto'
                showDialogTitle>
                <FormAddVariant ref={refAddVariant} editVariationID={variationID} />
            </MuiDialog>
            <MuiDialog
                maxWidth='xs'
                ref={dialogDeleteVariationRef}
                contentPadding={false}
                title={'Excluir variação do anúncio?'}
                onConfirm={{
                    action: () => deleteVariation(currentVariation._id),
                    text: 'Excluir',
                }}
                onCancel={{
                    action: () => {
                        setCurrentVariation((prev) => prev = {} as LoadAdVariantionsByAdIDProps);
                        dialogDeleteVariationRef.current?.hide();
                    },
                }}
                showDialogTitle>
                <Box
                    sx={{ pl: 2 }}>
                    <BoxContent>
                        <Product
                            size='small'
                            align='right'
                            contentAlign='center'
                            src={currentVariation?.fotos ?
                                images.load(currentVariation?.fotos[0], 'server') :
                                images.load(ProductPlaceholder, 'local')}
                            title={currentVariation.nome_cor} />
                    </BoxContent>
                </Box>
            </MuiDialog>
        </>
    );
};

export { Variants };
