import { useTheme } from '@mui/material';

const sx = () => {
    const { typography, palette } = useTheme();
    const css = {
        status: {
            position: 'absolute',
            bottom: 0,
            right: 0,
        },
        texts: {
            actions: {
                fontSize: typography.pxToRem(12),
                fontWeight: typography.fontWeightBold,
                color: palette.dark,
                pt: typography.pxToRem(10),
                mb: typography.pxToRem(10),
                // textTransform: 'uppercase',
            },
        },
    };

    return { css };
};

export { sx };
