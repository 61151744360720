import {
    ChangePasswordProps,
    CreateAccountProps,
    LoginProps,
    RecoveryPasswordProps,
} from '@interfaces/ClientServicesApi';
import { client } from '../../client';

const login = async ({ email, password }: LoginProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/auth/local', {
            email,
            password,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const logout = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/auth/logout');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const createAccount = async ({ ...user }: CreateAccountProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/user', {
            ...user,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const validationAccount = async (validationCode: Number) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/user/validate', {
            validationCode,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const resendCodeValidation = async (sendEmail: boolean, sendSms: boolean) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(`/user/resendEmailValidation?sendEmail=${
            sendEmail}&sendSms=${sendSms}`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const recoveryPassword = async ({ email }: RecoveryPasswordProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/recoveryPassword', { email });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const changePassword = async ({ token, password }: ChangePasswordProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/recoveryPassword', { token, password });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export {
    login,
    logout,
    createAccount,
    validationAccount,
    resendCodeValidation,
    recoveryPassword,
    changePassword,
};
