import React from 'react';
import { MenuItem, Navigation } from '@components';
import { Box } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { IconProp } from '@fortawesome/fontawesome-svg-core';
// import { useNavigate } from 'react-router-dom';
import { BackItem } from '../../../../pages/menu/components/back-item';
import { sx } from './sx';
import { useNavigate } from 'react-router-dom';
import { useMainMenu } from '@hooks';

interface UserMenuListProps {
    id: string;
    title: string;
    icon: IconProp;
    link?: string;
}

interface UserMenuProps {
    menu: UserMenuListProps[];
    dontShowInMenu?: string[];
}

const Main = ({ menu, dontShowInMenu }: UserMenuProps) => {
    // const { palette } = useTheme();
    // const navigate = useNavigate();
    const css = sx();
    const navigate = useNavigate();
    const { showFirstLvL, handleFirstLvl } = useMainMenu();
    // const [showFirstLvL, setShowFirstLvL] = React.useState({
    //     visibility: false,
    // });
    const [navigation, setNavigation] =
        React.useState<'Categorias'| 'Marcas'>('Categorias');

    const handleMenuVisibility = (type: 'show' | 'hidden', titleNavigation: string) => {
        const obj = {
            show: true,
            hidden: false,
        };
        handleFirstLvl({
            visibility: obj[type],
        });
        setNavigation(titleNavigation as any);
    };

    const handleNavigation = {
        Categorias: <Navigation.Categories />,
        Marcas: <Navigation.Brands />,
    };

    return (
        <Box sx={{
            height: showFirstLvL.visibility ? '17.5rem' : 'auto',
            overflow: 'auto',
        }}>
            {showFirstLvL.visibility && (
                <>
                    <Box sx={css.sxBoxHistory}>
                        <BackItem
                            onClick={() => handleMenuVisibility('hidden', '')}
                            title={'Menu'} />
                    </Box>
                    {handleNavigation[navigation] || <></>}
                </>
            )}
            {showFirstLvL.visibility === false &&
                menu.map(({ id, icon, title, link }) => (
                    <MenuItem
                        key={id}
                        onClick={() => link ?
                            navigate(link) : handleMenuVisibility('show', title)}
                        borderType={'bottom'}
                        title={{
                            text: title,
                            size: 'medium',
                        }}
                        htmlFor={id}>
                        <Box pl={2} pr={2}>
                            <I
                                style={{ fontSize: '1rem' }}
                                icon={icon}
                            />
                        </Box>
                    </MenuItem>
                ))}
        </Box>
    );
};

export { Main };
