import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { HeaderPage, Page, Snackbar } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    IconButton,
    InputAdornment,
    TextField,
    useTheme,
} from '@mui/material';
import { Container } from '@mui/system';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Form } from './styles';
import { formSchema } from './yup';
import { ErrorsProps, ProfileFormPassword, ToggleStaticProps } from '@interfaces';
import { useUser } from '@hooks';

const Password = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { updateUser, loadUser } = useUser();
    const { register, handleSubmit, formState: { errors } } =
        useForm<ProfileFormPassword>({
            resolver: yupResolver(formSchema),
            mode: 'onBlur',
        });
    const [showPassword, setShowPassword] = React.useState(false);
    const [showRetype, setShowRetype] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
        isLoading: false,
    });
    const [passwordErrors, setPasswordErrors] = React.useState<ErrorsProps>({
        listErrors: {
            error: false,
            location: '',
            msg: '',
            param: '',
            value: '',
        },
    });
    const keys = Object.keys(passwordErrors);
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const onSubmit: SubmitHandler<ProfileFormPassword> = async (data) => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });
        const response = await updateUser(data);
        if (!response.error) {
            loadUser();
            snackRef.current?.show();
            setResponseData((prev) => prev = {
                ...prev,
                type: response?.response.type,
                message: response?.response.message,
                isLoading: false,
            });
            setPasswordErrors({
                listErrors: {
                    error: false,
                    location: '',
                    msg: '',
                    param: '',
                    value: '',
                },
            });
            setTimeout(() => navigate('/meu-perfil/alterar/dados-pessoais'), 2000);
        } else {
            if (response.error && response.response.errors) {
                if (response.response.errors.errors) {
                    setResponseData((prev) => prev = {
                        ...prev,
                        isLoading: false,
                    });
                    const filterErrors = response.response.errors.errors.
                        map((err: any, i: number) => {
                            const object = {
                                [err.param]: {
                                    location: err.location,
                                    msg: err.msg,
                                    param: err.param,
                                    error: true,
                                },
                            };

                            if (err.param !== keys[i]) {
                                return (object);
                            }

                            return (object);
                        });

                    const reduceErrors = filterErrors.reduce(
                        (acc: any, current: any) => ({
                            ...acc,
                            ...current,
                        }),
                        {},
                    );
                    setPasswordErrors((prev) => prev = reduceErrors);
                }
            } else {
                snackRef.current?.show();
                setResponseData((prev) => prev = {
                    ...prev,
                    type: response?.response.type,
                    message: response?.response.message,
                    isLoading: false,
                });
            }
        }
    };

    return (
        <Page.Wrapper border position='fixed'>
            <HeaderPage
                onClick={() => navigate('/meu-perfil/alterar/' +
                    'dados-pessoais')}
                titleAlign={'default'}
                title={'Alterar Senha'}
                icon={['far', 'chevron-left']}
            />
            <Page.Content paddingBottom>
                <Box sx={{
                    padding: '1.5rem 0 0 0',
                }}>
                    <Container maxWidth='lg'>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <Box sx={{
                                '& .MuiTextField-root': { mb: 2 },
                            }}>
                                <TextField
                                    focused={true}
                                    fullWidth
                                    type={showPassword ? 'text' : 'password'}
                                    label={'Senha atual'}
                                    className='with-icon-end'
                                    id="input-with-icon-textfield"
                                    variant="outlined"
                                    helperText={
                                        (passwordErrors.hasOwnProperty('senha') &&
                                            passwordErrors.senha.msg) ||
                                        (!!errors.senha?.message &&
                                            errors.senha.message)}
                                    error={
                                        (passwordErrors.hasOwnProperty('senha') &&
                                            !!passwordErrors.senha.error) ||
                                        !!errors.senha}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowPassword(!showPassword)}
                                                    sx={{
                                                        fontSize: '16px',
                                                        color: palette.common.black,
                                                    }}>
                                                    {showPassword ?
                                                        <I icon={
                                                            ['fas', 'eye-slash']} /> :
                                                        <I icon={['fas', 'eye']} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register('senha',
                                        { required: true },
                                    )} />
                                <TextField
                                    focused={true}
                                    fullWidth
                                    type={showRetype ? 'text' : 'password'}
                                    label={'Nova senha'}
                                    className='with-icon-end'
                                    id="input-with-icon-textfield"
                                    variant="outlined"
                                    helperText={
                                        (passwordErrors.hasOwnProperty('novaSenha') &&
                                            passwordErrors.novaSenha.msg) ||
                                        (!!errors.novaSenha?.message &&
                                            errors.novaSenha.message)}
                                    error={
                                        (passwordErrors.hasOwnProperty('novaSenha') &&
                                            !!passwordErrors.novaSenha.error) ||
                                        !!errors.novaSenha}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowRetype(!showRetype)}
                                                    sx={{
                                                        fontSize: '1rem',
                                                        color: palette.common.black,
                                                    }}>
                                                    {showRetype ?
                                                        <I icon={
                                                            ['fas', 'eye-slash']} /> :
                                                        <I icon={['fas', 'eye']} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register('novaSenha',
                                        { required: true })}
                                />
                                <TextField
                                    focused={true}
                                    fullWidth
                                    type={showRetype ? 'text' : 'password'}
                                    label={'Repita sua nova senha'}
                                    className='with-icon-end'
                                    id="input-with-icon-textfield"
                                    variant="outlined"
                                    helperText={errors.repetirSenha ?
                                        errors.repetirSenha.message : ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() =>
                                                        setShowRetype(!showRetype)}
                                                    sx={{
                                                        fontSize: '1rem',
                                                        color: palette.common.black,
                                                    }}>
                                                    {showRetype ?
                                                        <I icon={
                                                            ['fas', 'eye-slash']} /> :
                                                        <I icon={['fas', 'eye']} />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...register('repetirSenha',
                                        { required: true })}
                                />

                            </Box>
                            <Button
                                variant="contained"
                                size="large"
                                fullWidth
                                type='submit'
                                className='MuiButton primary'
                            >
                                Salvar alterações {responseData.isLoading && (
                                    <CircularProgress
                                        size={14}
                                        color='inherit'
                                        sx={{ ml: 0.5 }} />)}
                            </Button>
                        </Form>
                    </Container>
                </Box>
                <Snackbar
                    ref={snackRef}
                    message={responseData.message}
                    severity={responseData.type}
                />
            </Page.Content>
        </Page.Wrapper>
    );
};

export { Password };
