/* eslint-disable max-len */
import React from 'react';
import { TextsLayout } from '../texts-layout';
import { Typography } from '@mui/material';

const TermsOfUse: React.FC = () => {
    return (
        <TextsLayout>
            <Typography
                textAlign={'center'}
                fontSize={'1.375rem !important'}
                variant='h1'>
                TERMOS E CONDIÇÕES DE USO - MARIAZ
            </Typography>
            <Typography variant='h1'>
                GLOSSÁRIO
            </Typography>
            <Typography variant='body1'>
                No presente Termo, as palavras e expressões a seguir elencadas devem ter as seguintes interpretações:
            </Typography>
            <Typography variant='body2'>
                <ul style={{ listStyle: 'none' }}>
                    <li>
                        <strong>Catálogo:</strong> página ou conjunto de páginas promocionais da Plataforma, que pode ser eventualmente elaborado pelo MARIAZ para divulgação pontual de determinados Produtos conforme qualidade, evento ou sazonalidade.
                    </li>
                    <li>
                        <strong>Comissão:</strong> remuneração da Marca à MARIAZ pela utilização da Plataforma, a qual é retida pelo MARIAZ quando dos Repasses do Valor das Vendas às Marcas.
                    </li>
                    <li>
                        <strong>Loja:</strong> página virtual da Plataforma dedicada à publicação e divulgação de Produtos de determinada Marca.
                    </li>
                    <li>
                        <strong>Manual da Marca:</strong> documento orientativo que contém diretrizes, padrões de qualidade e regras para a criação, gerenciamento e controle das Lojas, Produtos e Vendas das Marcas.
                    </li>
                    <li>
                        <strong>Marca(s):</strong> empresa cadastrada para realizar vendas por meio da Plataforma.
                    </li>
                    <li>
                        <strong>Painel da Marca:</strong> área de acesso restrito às Marcas na Plataforma, contendo informações sobre seus pedidos, Vendas, Repasses, Retenções, devoluções, etc.
                    </li>
                    <li>
                        <strong>Página de Categoria:</strong> página ou conjunto de páginas da Plataforma que reúne Produtos de um tipo  ou característica específica, de uma ou mais Marcas.
                    </li>
                    <li>
                        <strong>Plataforma:</strong> conjunto de páginas virtuais, arquivos, sistemas, textos, imagens, gráficos e formulários sob o domínio virtual <a href="https://www.mariaz.com.br" target='_blank'>www.mariaz.com.br</a>.
                    </li>
                    <li>
                        <strong>Preço:</strong> valor determinado pela Marca para venda de determinado produto, o qual inclui tributos e demais encargos atinentes (exceto frete).
                    </li>
                    <li>
                        <strong>Produto(s):</strong> item ou conjunto de itens postos à Venda pelas Marcas por meio da Plataforma.
                    </li>
                    <li>
                        <strong>Produto(s) Customizado(s):</strong> produto a ser manufaturado pela Marca sob encomenda e/ou prévia aprovação do Usuário.
                    </li>
                    <li>
                        <strong>Repasse(s):</strong> valor a ser mensalmente creditado às Marcas correspondente às Vendas do mês anterior, líquido de Comissão e demais despesas e taxas inerentes.
                    </li>
                    <li>
                        <strong>Retenção(ões):</strong> valor que será retido pelo MARIAZ do repasse às Marcas, a título de Comissão e demais despesas e taxas inerentes.
                    </li>
                    <li>
                        <strong>Taxa de Devolução:</strong> taxa a ser cobrada das Marcas mediante devoluções, ou pedidos de reparo motivados por defeitos ou imperfeições do Produto.
                    </li>
                    <li>
                        <strong>Usuário(s):</strong> pessoa física ou jurídica que compra Produtos das Marcas por meio do MARIAZ, mediante prévio cadastro.
                    </li>
                    <li>
                        <strong>Valor da(s) Venda(s):</strong> somatória dos preços de determinada Venda ou de todas as Vendas realizadas pela Marca em determinado período.
                    </li>
                    <li>
                        <strong>Venda(s):</strong> transação pela qual um Usuário adquire Produto(s) de determinada Marca ou de mais de uma Marca por meio da Plataforma, mediante pagamento do(s) respectivo(s) Preço(s).
                    </li>
                </ul>
            </Typography>
            <Typography variant='h1'>SUMÁRIO DOS TERMOS E CONDIÇÕES DE USO DO MARIAZ</Typography>
            <Typography variant='body1'>
                Este documento é necessário para orientar os Usuários a operaram em nossa Plataforma de acordo com nossas políticas, regramentos e diretrizes, sendo imprescindível a leitura, compreensão e aceitação destes Termos e Condições de Uso.
            </Typography>
            <Typography variant='body1'>
                Resumidamente, encontram-se a seguir elencadas as principais cláusulas que possibilitam o uso da Plataforma e as principais limitações de direitos.
            </Typography>
            <Typography variant='body1'>
                <strong>1.</strong> As Marcas são legítimas fornecedoras dos Produtos divulgados em suas Lojas na Plataforma.
            </Typography>
            <Typography variant='body1'>
                <strong>2.</strong> O MARIAZ é prestadora de serviço de intermediação, ou seja, oferece uma Plataforma na internet que fornece espaço para que Marcas e Usuários cadastrados realizem transações de Produtos entre si.
            </Typography>
            <Typography variant='body1'>
                <strong>3.</strong> Para utilização da Plataforma MARIAZ, os Usuários deverão efetuar cadastro com e-mail, telefone e senha que são pessoais e intransferíveis. O MARIAZ não se responsabiliza pelo uso inadequado e divulgação desses dados para terceiros.
            </Typography>
            <Typography variant='body1'>
                <strong>4.</strong> O MARIAZ, por motivo de violação à legislação ou a estes Termos e Condições de Uso, conforme o caso, poderá recusar a solicitação ou excluir a conta de um Usuário já existente.
            </Typography>
            <Typography variant='body1'>
                <strong>5.</strong> O MARIAZ orienta os Usuários para que, sempre antes de decidir pela compra, se atentem às informações do Produto anunciado, como condições, disponibilidade, preço, forma de pagamento e detalhes da entrega.
            </Typography>
            <Typography variant='h1'>TERMOS E CONDIÇÕES DE USO DO MARIAZ</Typography>
            <Typography variant='body1'>
                Estes Termos e Condições de Uso aplicam-se ao uso do serviço oferecido por MARIAZ LTDA., inscrita no Cadastro Nacional de Pessoas Jurídicas sob o nº 50.346.464/0001-22, com sede na Avenida Nove de Julho, 5966, Jardim Paulista, CEP: 01406-902, na cidade de São Paulo (SP), doravante denominado MARIAZ.
            </Typography>
            <Typography variant='body1'>
                Qualquer Usuário que pretenda utilizar os serviços do MARIAZ deverá aceitar estes Termos e Condições de Uso.
            </Typography>
            <Typography variant='body1'>
                O MARIAZ poderá, a qualquer momento, modificar, alterar ou excluir parte do conteúdo do presente termo, visando seu aprimoramento e a melhoria dos serviços prestados, razão pela qual recomenda-se a leitura a cada acesso do usuário à plataforma.
            </Typography>
            <Typography variant='h1'>CLÁUSULA PRIMEIRA: DO OBJETO</Typography>
            <Typography variant='body1'><strong>1.1.</strong> O presente termo regula os serviços de intermediação oferecidos pelo MARIAZ, que consistem em:</Typography>
            <Typography variant='body2'><strong>1.1.1.</strong> Hospedar espaços na plataforma para que Marcas anunciem para venda a Usuários seus próprios Produtos, sejam eles novos, seminovos ou usados;</Typography>
            <Typography variant='body2'><strong>1.1.2.</strong> Viabilizar o contato entre Marcas e Usuários interessados em adquirir os Produtos ofertados, intermediando este contato para esclarecimento de dúvidas e compra de Produtos, mas sem qualquer intervenção na concretização da mesma. É vedado qualquer outro meio de comunicação entre Marcas e Usuários;</Typography>
            <Typography variant='body1'><strong>1.2.</strong> As negociações realizadas na Plataforma serão intermediadas pelo MARIAZ, mas são de responsabilidade exclusiva das Marcas e dos Usuários, que definem e aceitam valores por sua conta e risco, sem qualquer responsabilidade do MARIAZ.</Typography>
            <Typography variant='h1'>CLÁUSULA SEGUNDA: DO CADASTRO</Typography>
            <Typography variant='body1'><strong>2.2.</strong> O cadastro de Usuários para utilização da Plataforma somente poderá ser efetuado por pessoas físicas que preencham os seguintes requisitos:</Typography>
            <Typography variant='body2'><strong>2.2.1.</strong> Ser maior de 18 (dezoito) anos e capaz perante a legislação brasileira (observância aos artigos 4º e 5º, do Código Civil);</Typography>
            <Typography variant='body2'><strong>2.2.2.</strong> Ser devidamente inscrito no Cadastro de Pessoas Físicas;</Typography>
            <Typography variant='body2'><strong>2.2.3.</strong> Possuir número de telefone válido;</Typography>
            <Typography variant='body2'><strong>2.2.4.</strong> Possuir um endereço de e-mail válido;</Typography>
            <Typography variant='body2'><strong>2.2.5.</strong> Informar endereço residencial e/ou comercial válido.</Typography>
            <Typography variant='body1'><strong>2.3.</strong> O Usuário, ao efetuar o cadastro na Plataforma, deverá preencher todos os campos exigidos, assumindo o compromisso de atualizar os dados pessoais fornecidos sempre que sofrerem qualquer alteração.</Typography>
            <Typography variant='body2'><strong>2.3.1.</strong> O MARIAZ não se responsabiliza por quaisquer irregularidades no envio de Produtos oriundos de informações cadastrais equivocadas ou desatualizadas.</Typography>
            <Typography variant='body1'><strong>2.4.</strong> A conta do Usuário é pessoal, única e intransferível, ou seja, em nenhuma circunstância poderá ser vendida ou transferida para outra pessoa. A conta é acessada com a senha pessoal de segurança escolhida e deverá ser mantida sob estrita confidencialidade.</Typography>
            <Typography variant='body1'><strong>2.5.</strong> O Usuário garante que fornecerá informações exatas e verdadeiras, não havendo responsabilidade do MARIAZ na verificação dos dados pessoais fornecidos por ele fornecidos, respondendo estes, em qualquer caso, civil e criminalmente pela veracidade e autenticidade das informações fornecidas no cadastro à Plataforma.</Typography>
            <Typography variant='body2'><strong>2.5.1.</strong> O MARIAZ poderá, quando julgar necessário, realizar buscas para apuração de dados incorretos ou inverídicos inseridos por Usuários, bem como solicitar documentos adicionais com a finalidade de conferir os dados informados no cadastro.</Typography>
            <Typography variant='body1'><strong>2.6.</strong> O MARIAZ assegura-se no direito de recusar qualquer pedido de cadastro, assim como suspender ou cancelar cadastro anteriormente aceito, se verificar:</Typography>
            <Typography variant='body2'><strong>2.6.1.</strong> A inobservância a estes Termos e Condições de Uso;</Typography>
            <Typography variant='body2'><strong>2.6.2.</strong> A prática de atos fraudulentos ou dolosos;</Typography>
            <Typography variant='body2'><strong>2.6.3.</strong> A inconsistência nas informações fornecidas que não permitir a verificação da identidade do Usuário;</Typography>
            <Typography variant='body2'><strong>2.6.4.</strong> Quaisquer outros atos que estejam em desacordo com políticas e diretrizes da Plataforma.</Typography>
            <Typography variant='body1'><strong>2.7.</strong> Não poderão se registrar novamente na Plataforma Usuários que tenham sido desativados previamente. Também não poderão se registrar aqueles que estejam incluídos ou relacionados com pessoas que apareçam em listas nacionais ou internacionais de sanções.</Typography>
            <Typography variant='body1'><strong>2.8.</strong> No caso de detectar o uso de mais de uma conta, o MARIAZ poderá aplicar retenções, débitos e/ou qualquer outra medida se considerar que essa ação pode prejudicar o resto das pessoas que usam a Plataforma, além das sanções que possam corresponder.</Typography>
            <Typography variant='body1'><strong>2.9.</strong> O Usuário poderá, a qualquer tempo, sem a necessidade de justificativa, solicitar a exclusão de seu cadastro perante o MARIAZ. Para tanto, terá que enviar uma solicitação através da Plataforma, a qual será analisada no prazo de 15 (quinze) dias contados do envio da solicitação, a fim de verificar a existência de alguma transação ainda pendente.</Typography>
            <Typography variant='body2'><strong>2.9.1.</strong> Caso não haja pendências relacionadas a compras ou vendas, o MARIAZ excluirá o cadastro com todos os dados pessoais do Usuário, mas manterá em arquivo as informações sobre as transações realizadas pelo tempo exigido pela legislação civil.</Typography>
            <Typography variant='body1'><strong>2.10</strong>. O MARIAZ nem quaisquer de seus empregados ou prepostos solicitará, por qualquer meio físico ou eletrônico, que o Usuário informe sua senha de acesso criada no momento do cadastro.</Typography>
            <Typography variant='body1'>O MARIAZ faz uso responsável das informações pessoais, protegendo a privacidade dos Usuários que confiaram seus dados e tomando as medidas necessárias para garantir a segurança de sua utilização no âmbito da Plataforma, nos termos e diretrizes da <a href="/politica-de-privacidade">Política de Privacidade e Proteção de Dados do MARIAZ</a>.</Typography>
            <Typography variant='h1'>CLÁUSULA TERCEIRA: DA GESTÃO DE PAGAMENTOS</Typography>
            <Typography variant='body1'><strong>3.1.</strong> Os Usuários declaram ter ciência de que, para garantir a realização e integridade das transações financeiras, os dados fornecidos quando do cadastramento no MARIAZ serão compartilhados com a empresa gestora de pagamentos online, sem prejuízo da observância da <a href="/politica-de-privacidade">Política de Privacidade e Proteção de Dados do MARIAZ</a>.</Typography>
            <Typography variant='body1'><strong>3.2.</strong> A empresa gestora de pagamentos online se responsabiliza pela segurança e sigilo dos dados de Usuários compartilhados através do MARIAZ e se compromete, contratualmente, a não utilizar os dados para quaisquer finalidades diferentes daquelas estabelecidas nestes Termos e Condições de Uso.</Typography>
            <Typography variant='body1'><strong>3.3.</strong> O Usuário concorda em usar apenas os meios de pagamento que o MARIAZ disponibiliza para garantir a segurança das operações e o funcionamento dos respectivos programas de proteção.</Typography>
            <Typography variant='h1'>CLÁUSULA QUARTA: DA COMUNICAÇÃO ENTRE MARCAS E USUÁRIOS</Typography>
            <Typography variant='body1'><strong>4.1.</strong>A compra e venda dos Produtos anunciados no MARIAZ deverão, obrigatoriamente, ocorrer dentro na Plataforma, sendo proibido a Marcas e Usuários utilizarem quaisquer outros veículos de comunicação para concluir a compra e venda dos Produtos anunciados.</Typography>
            <Typography variant='body1'><strong>4.2.</strong> Usuários declaram ter ciência de que o MARIAZ poderá moderar comentários sobre  avaliações de experiência de compras e de Produtos, eliminando mensagens, palavras e expressões consideradas agressivas, caluniosas, injuriosas e difamatórias ou que violem qualquer outra regra destes Termos e Condições de Uso.</Typography>
            <Typography variant='h1'>CLÁUSULA QUINTA: DA REMUNERAÇÃO PELO USO DA PLATAFORMA.</Typography>
            <Typography variant='body1'><strong>5.1.</strong> Pelo desenvolvimento das atividades de intermediação e para manutenção da Plataforma, o MARIAZ cobra da Marca uma Comissão sobre cada transação realizada.</Typography>
            <Typography variant='body1'><strong>5.2.</strong> Dos Usuários não será cobrado nenhum valor a título de remuneração da Plataforma, uma vez que as Comissões são pagas exclusivamente pelas Marcas.</Typography>
            <Typography variant='h1'>CLÁUSULA SEXTA: DAS VENDAS E DAS ENTREGAS</Typography>
            <Typography variant='body1'><strong>6.1.</strong> Para vender um produto na Plataforma, é necessário que a Marca cumpra todos os requisitos e permissões exigidos por lei.</Typography>
            <Typography variant='body1'><strong>6.2.</strong> Em relação a cada Usuário, a Marca é obrigada a: </Typography>
            <Typography variant='body2'><strong>6.2.1.</strong> Respeitar as condições de venda publicadas na Plataforma;</Typography>
            <Typography variant='body2'><strong>6.2.2.</strong> Emitir a Nota Fiscal correspondente à Venda, a menos que a Marca esteja isenta da obrigação;</Typography>
            <Typography variant='body2'><strong>6.2.3.</strong> Garantir o direito de cancelar ou revogar a compra dentro do prazo legal;</Typography>
            <Typography variant='body2'><strong>6.2.4.</strong> Respeitar as garantias legais aplicáveis, conforme o caso;</Typography>
            <Typography variant='body2'><strong>6.2.5.</strong> Aceitar e pagar os custos de revogação ou devolução dos produtos.</Typography>
            <Typography variant='body1'><strong>6.3.</strong> A Marca é responsável por postar ao Usuário o(s) Produto(s) de cada Venda dentro do prazo de até 3 (três) dias úteis, exceto para Produtos Customizados, cujo prazo de postagem deverá constar na respectiva descrição do(s) Produto(s). </Typography>
            <Typography variant='body1'><strong>6.4.</strong> A Marca deve se utilizar apenas de empresas de entregas e correspondência disponíveis na Plataforma o MARIAZ para a entrega de seus Produtos.</Typography>
            <Typography variant='body1'><strong>6.5.</strong> O(s) Produto(s) deve(m) ser embalado(s) de forma que cheguem em boas condições ao seu destino.</Typography>
            <Typography variant='body1'><strong>6.6.</strong> A Marca é responsável por emitir a nota fiscal da Venda para o Usuário, e recolher todos os impostos a ela inerentes.</Typography>
            <Typography variant='body1'><strong>6.7.</strong> O Frete da postagem será arcado pelo MARIAZ ou pelo comprador, conforme opções que serão oferecidas ao Usuário quando da efetivação da Compra. </Typography>
            <Typography variant='body1'><strong>6.8.</strong> O Usuário se responsabilizará por atentar-se às formas de envio disponíveis para cada Produto e escolher a que melhor lhe aprouver.</Typography>
            <Typography variant='body2'><strong>6.8.1.</strong> A forma de envio do produto não poderá ser alterada após a conclusão da compra.</Typography>
            <Typography variant='body1'><strong>6.9.</strong> Quando optar pelo frete via Correios, o Usuário se responsabiliza pelo pagamento do valor do frete no momento da compra, o qual é calculado com base na distância entre a localização do estoque da Marca e o endereço do Usuário, combinado com o peso do produto.</Typography>
            <Typography variant='body1'><strong>6.10</strong>. O cumprimento do prazo de entrega do Produto, estabelecido no momento da postagem, é de responsabilidade exclusiva das empresas de entrega. Da mesma forma, serão elas as únicas responsáveis em caso de extravio da mercadoria.</Typography>
            <Typography variant='body2'><strong>6.10.1</strong>. O MARIAZ fará a intermediação entre Marca e Usuário para a resolução de problemas decorrentes de entregas e de pedidos de devoluções, porém não se responsabiliza pela entrega ou pelo estado em que o produto adquirido chegue ao Usuário.</Typography>
            <Typography variant='body1'><strong>6.11</strong>. Na hipótese da empresa de entrega não conseguir entregar o produto ao Usuário, por qualquer motivo, o Produto retornará ao endereço da Marca remetente. Após 3 (três) tentativas de devolução à Marca remetente, sem sucesso, a empresa de entrega manterá a mercadoria armazenada para retirada durante o prazo máximo de 120 (cento e vinte dias). Decorrido referido período, o Produto será encaminhado para destruição.</Typography>
            <Typography variant='body2'><strong>6.11.1</strong>. O MARIAZ não controla esse prazo, nem se responsabiliza por qualquer eventual dano decorrente desse procedimento.</Typography>
            <Typography variant='h1'>CLÁUSULA SÉTIMA: DO CANCELAMENTO</Typography>
            <Typography variant='body1'><strong>7.1.</strong> Os Usuários poderão desistir da compra antes do envio do produto. Para tanto, deverão solicitar o cancelamento através do campo disponível na Plataforma.</Typography>
            <Typography variant='body2'><strong>7.1.1.</strong> Efetivado o envio pela Marca, o cancelamento não poderá mais ser solicitado, uma vez que o produto já deverá ter sido encaminhado, caso em que, havendo interesse, o Usuário deverá solicitar a devolução do Produto.</Typography>
            <Typography variant='body1'><strong>7.2.</strong> Em casos de cancelamento, o valor pago referente ao frete será integralmente restituído ao Usuário.</Typography>
            <Typography variant='h1'>CLÁUSULA OITAVA: DAS DEVOLUÇÕES</Typography>
            <Typography variant='body1'><strong>8.1.</strong> O Usuário tem o prazo de 7 (sete) dias corridos, contados a partir da data de recebimento, para solicitar a devolução imotivada do produto, através da Plataforma.</Typography>
            <Typography variant='body1'><strong>8.2.</strong> Após o prazo de 7 (sete) dias corridos, contados a partir da data de recebimento, o Usuário somente poderá requerer a devolução do Produto motivada por erro de envio ou produto defeituoso, nos prazos previstos no Código de Defesa do Consumidor para bens duráveis ou não duráveis, conforme o caso.</Typography>
            <Typography variant='body1'><strong>8.3.</strong> O Usuário se responsabiliza pela devolução do produto nas exatas condições como foi recebido. Isso significa que, Produtos que tiveram suas etiquetas removidas, ou foram usados, lavados ou danificados, não poderão ser devolvidos à Marca.</Typography>
            <Typography variant='body1'><strong>8.4.</strong> O Usuário tem o prazo de 6 (seis) dias corridos para efetuar o envio do produto a ser devolvido, contados a partir da data de abertura do procedimento de devolução, via Correios, para o endereço da Marca, utilizando a etiqueta de postagem e a declaração de conteúdo que serão disponibilizadas pelo MARIAZ na Plataforma.</Typography>
            <Typography variant='body2'><strong>8.4.1.</strong> A não observância do prazo de postagem previsto nesta cláusula implicará no cancelamento do pedido de devolução, não sendo possível fazer um novo pedido.</Typography>
            <Typography variant='body2'><strong>8.4.2.</strong> No momento da embalagem para devolução, recomenda-se ao Usuário fotografar o produto e a embalagem e armazenar as fotografias para caso solicitadas, sejam apresentadas, a fim de comprovar as condições do produto e da embalagem no momento da postagem para devolução.</Typography>
            <Typography variant='body1'><strong>8.5.</strong> Caso o produto postado pelo Usuário para devolução à Marca não possa ser entregue pelos Correios em decorrência de culpa da Marca, como, por exemplo, em virtude de seu endereço cadastral estar errado, incompleto, ou inexistente, ou ainda em virtude de diversas tentativas de entrega sem sucesso, e, por esse (s) motivo (s), o Produto retorne ao endereço do Usuário, o Usuário terá o prazo improrrogável de 7 (sete) dias corridos, a contar do recebimento da mercadoria, para comunicar o ocorrido à MARIAZ, que tomará as providências cabíveis.</Typography>
            <Typography variant='body2'><strong>8.5.1.</strong> A não observância do prazo de postagem previsto nesta cláusula implicará no cancelamento do pedido de devolução, não sendo possível fazer um novo pedido.</Typography>
            <Typography variant='body1'><strong>8.6.</strong> O Usuário está ciente que em caso de devolução do produto, por qualquer motivo, não haverá a restituição do valor pago referente ao frete de envio.</Typography>
            <Typography variant='body1'><strong>8.7.</strong> A Marca, após receber o produto devolvido pelo Usuário, tem o prazo de 3 (três) dias corridos para contestar a devolução, entrando em contato com o MARIAZ, explicando os motivos e apresentando provas.</Typography>
            <Typography variant='body2'><strong>8.7.1.</strong> Caso o MARIAZ acate a justificativa da Marca para negar a devolução, o Produto será reenviado pela Marca ao Usuário no estado em que se encontra.</Typography>
            <Typography variant='body2'><strong>8.7.2.</strong> Caso a MARIAZ não acate a justificativa da Marca para negar a devolução, será dado regular prosseguimento à devolução. </Typography>
            <Typography variant='h1'>CLÁUSULA NONA: DOS LIMITES DA RESPONSABILIDADE DO MARIAZ</Typography>
            <Typography variant='body1'><strong>9.1.</strong> O MARIAZ será responsável por qualquer defeito na prestação de seu serviço, na medida em que lhe seja imputável e com o alcance previsto nas leis vigentes, observadas as limitações abaixo.</Typography>
            <Typography variant='body1'><strong>9.2.</strong> Em razão de o MARIAZ não ser a proprietária dos Produtos anunciados pelas Marcas, nem deter a posse dos itens anunciados e, ainda, não realizar ofertas de Venda nem intervir na entrega dos Produtos, fica isenta de qualquer responsabilidade advinda da compra e venda de tais Produtos, incluindo, mas não se limitando à qualidade, estado, descrição, integridade ou legitimidade dos produtos oferecidos.</Typography>
            <Typography variant='body1'><strong>9.3.</strong> Cada Marca conhece e aceita ser a única responsável pelos Produtos que anuncia. A Marca será a única e exclusiva responsável por quaisquer danos, indenizações e prejuízos eventualmente causados em virtude de seus Produtos, devendo esta indenizar o Usuário.</Typography>
            <Typography variant='body1'><strong>9.4.</strong> O MARIAZ não se responsabiliza por vícios ou defeitos técnicos e/ou operacionais oriundos do sistema da Marca ou de terceiros, inclusive de empresas gestoras de pagamentos online.</Typography>
            <Typography variant='body1'><strong>9.5.</strong> O MARIAZ não se responsabiliza pelas obrigações de natureza tributária que incidam sobre os produtos vendidos das marcas.</Typography>
            <Typography variant='body1'><strong>9.6.</strong> O MARIAZ não se responsabiliza por qualquer dano, prejuízo ou perda sofridos por Usuários em razão de falhas na Internet, no sistema ou no servidor utilizado pelo usuário, ou aqueles decorrentes de condutas de terceiros, de caso fortuito ou de força maior.</Typography>
            <Typography variant='body2'><strong>9.6.1.</strong> O MARIAZ também não será responsável por qualquer vírus ou malwares que possam atacar o equipamento do Usuário em decorrência do acesso, utilização ou navegação na Internet, ou como consequência da transferência de dados, arquivos, imagens, textos ou áudio.</Typography>
            <Typography variant='body1'><strong>9.7.</strong> Usuários indenizarão o MARIAZ, empresas controladas ou controladoras, diretores, administradores, colaboradores, representantes e empregados por qualquer demanda promovida por outros usuários ou terceiros decorrentes de suas atividades na Plataforma ou por seu descumprimento dos presentes termos e demais políticas do site, ou pela violação de qualquer lei ou direitos de terceiros, incluindo honorários advocatícios.</Typography>
            <Typography variant='h1'>CLÁUSULA DÉCIMA: DISPOSIÇÕES FINAIS</Typography>
            <Typography variant='body1'><strong>10.1.</strong> A concordância a estes Termos e Condições de Uso não geram contrato de sociedade, de mandato, de franquia ou relação de trabalho entre o MARIAZ e o usuário, sejam Marcas ou Usuários.</Typography>
            <Typography variant='body1'><strong>10.2.</strong> O uso comercial da expressão MARIAZ ou www.mariaz.com.br como marca, nome empresarial ou nome de domínio, assim como os programas, bancos de dados, redes, arquivos que permitem que o usuário acesse e use sua conta na Plataforma (doravante, a “Propriedade Intelectual”) são propriedade do MARIAZ e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais. O uso indevido e a reprodução total ou parcial dos referidos conteúdos são proibidos, salvo se expressamente autorizado pelo MARIAZ.</Typography>
            <Typography variant='body2'><strong>10.2.1</strong>. Embora o MARIAZ conceda permissão para usar seus serviços conforme previsto neste Termo, isso não implica uma autorização para usar sua Propriedade Intelectual, exceto consentimento prévio e expresso do MARIAZ e/ou suas sociedades relacionadas. Em qualquer caso, as Marcas que se utilizem dos serviços do MARIAZ não poderão utilizar sua Propriedade Intelectual de uma forma que cause confusão no público, e deverão realizar sua atividade comercial sob uma marca ou nome comercial próprio e distintivo, que não seja confundível com a marco MARIAZ.</Typography>
            <Typography variant='body2'><strong>10.2.2</strong>. É proibido usar nossos serviços para fins ilegais, realizar qualquer tipo de engenharia reversa ou obras derivadas, usar ferramentas de busca ou extração de dados e conteúdo de nossa Plataforma para reutilização e/ou criar bancos de dados próprios que incluam, no todo ou em parte, nosso conteúdo sem nossa expressa autorização.</Typography>
            <Typography variant='body2'><strong>10.2.3</strong>. É também proibido o uso indevido, sem autorização e/ou contrário às normas vigentes e/ou que gere confusão ou implique uso difamatório e/ou que cause prejuízo, danos ou perdas à MARIAZ. A utilização dos serviços do MARIAZ também não implica a autorização para usar propriedade intelectual de terceiros que possa estar envolvida, cujo uso ficará sob exclusiva responsabilidade da Marca.</Typography>
            <Typography variant='body1'><strong>10.3.</strong> O MARIAZ poderá, a seu critério, priorizar Produtos em virtude da geolocalização dos Usuários, bem como de outros fatores que considere relevantes, com o objetivo de melhorar sua experiência de compra.</Typography>
            <Typography variant='body1'><strong>10.4.</strong> Usuários manterão indene o MARIAZ e suas sociedades relacionadas, assim como aqueles que a dirigem, sucedem, administram, representam e/ou trabalham nelas, perante qualquer reclamação administrativa ou judicial iniciada por outros usuários, terceiros ou por qualquer entidade, em relação a suas atividades no âmbito da Plataforma.</Typography>
            <Typography variant='body2'><strong>10.4.1</strong>. Em virtude dessa responsabilidade, o MARIAZ poderá realizar compensações, retenções ou outras medidas necessárias para a reparação de perdas, danos e prejuízos, qualquer que seja sua natureza.</Typography>
            <Typography variant='h1'>CLÁUSULA DÉCIMA PRIMEIRA: DO FORO</Typography>
            <Typography variant='body1'><strong>11.1.</strong> Estes Termos e Condições de Uso são regidos pelas leis vigentes na República Federativa do Brasil. Para dirimir eventuais dúvidas ou questões relativas a ele, as partes elegem o Foro da Comarca de São Paulo (SP), com exclusão de qualquer outro, por mais privilegiado que seja.</Typography>
        </TextsLayout>
    );
};

export { TermsOfUse };
