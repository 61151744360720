/* eslint-disable max-len */
import * as Yup from 'yup';

export const formSchema = (sizeIsReq: boolean) => Yup.object().shape({
    title: Yup.string().required('Preencha o titulo'),
    description: Yup.string().required('Preencha o descrição'),
    quantity_days_available: Yup.number()
        .transform((value) => (isNaN(value) || value === null || value === undefined) ? 0 : value),
    composition: Yup.string().required('Campo obrigatório'),
    others: Yup.string(),
    category: Yup.string().required('Selecione uma categoria'),
    size: sizeIsReq ? Yup.string().required('Selecione um tamanho') : Yup.string(),
    weight: Yup.string().nullable().required().max(100),
    price: Yup.string(),
    // .matches(/$[0-9]+([,\.][0-9]+)?/,
    //     'Preencha com um valor monetário válido'),
    // stockQuantity: Yup.number()
    //     .typeError('Preencha a quantidade com números')
    //     .required('Preencha a quantidade em estoque'),
    // sku: Yup.string().required('Preencha o SKU'),
    aplicativo_entrega: Yup.boolean().oneOf([true], 'Selecione o tipo de entrega'),
});
