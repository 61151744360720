/* eslint-disable max-len */
import { HighlightedAdsProps } from '@interfaces';
import { ResponseAsyncProps } from '@interfaces/ClientServicesApi';
import {
    AdActionProps,
    AdColorsProps,
    AddVariationsToCreateProps,
    AdFindProps,
    AdImagesProps,
    AdListProps,
    AdStateProps,
    AdVariationsColorsProps,
    CreateAdVariation,
    CreateNewAdCreateProps,
    DeleteVariationProps,
    ExternalDiloagActionProps,
    FindMeasureTableByAdIDProps,
    HighlightedProps,
    LoadAdVariantionsByAdIDProps,
    LoadSizesProps,
    MeasuresAdProps,
    MeasuresProps,
    MeasuresTableProps,
    NewAdClearAdData,
    NewAdInsertDataProps,
    NewAdProps,
    NewAdRemoveData,
    SearchProps,
    UpdateAdVariation,
    VariationsColorProps,
    VariationsItemProps,
} from '@interfaces/Contexts';
import { ImagesActionType } from '@types';
import { createContext } from 'react';
import { DropResult } from 'react-beautiful-dnd';

interface AdsContextProps {
    // _id: string;
    highlighted: {
        load: ({ limit, aleatory }: HighlightedAdsProps) => Promise<{
            error: boolean;
            response: any;
        } | undefined>
        list: HighlightedProps;
    };
    measures: {
        measuresAd: MeasuresAdProps;
        addSelectedMeasuresAd: ({ _id, data }: {
            _id: string;
            data: MeasuresProps[];
        }) => void;
        addMeasuresAd: ({ _id, oldID, data }: {
            _id: string;
            oldID?: string;
            data: MeasuresTableProps[];
        }) => void;
        addMeasuresAdList: (data: MeasuresTableProps[]) => void;
        removeMeasuresAd: ({ _id }: {
            _id: string;
        }) => void;
        clearMeasuresAdData: () => void;
    }
    createNewAd: {
        images: {
            add: (event: React.ChangeEvent<HTMLInputElement>,
                type: ImagesActionType) => void;
            addNew: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
            addToEdit: (type: 'ad'
                | 'variation'
                | 'variation-color'
                | 'variation-custom-color', images: AdImagesProps[]) => void
            delete: (id: string) => void;
            list: AdImagesProps[];
            dnd: {
                onDragEnd: (result: DropResult) => void;
            };
        };
        create: ({ ...props }: CreateNewAdCreateProps) =>
            Promise<ResponseAsyncProps | undefined>;
        variations: {
            create: ({ adID, ad }: CreateAdVariation) => Promise<ResponseAsyncProps
                | undefined>;
            update: ({ ad }: UpdateAdVariation) => Promise<ResponseAsyncProps
                | undefined>;
            delete: ({ idAd, variationID }: DeleteVariationProps) => Promise<
                ResponseAsyncProps | undefined>;
        }
        insertData: ({ type, data }: NewAdInsertDataProps) => void;
        removeData: ({ type, data }: NewAdRemoveData) => void;
        clearAdData: ({ type, clearAll }: NewAdClearAdData) => void;
        data: NewAdProps;
    }
    search: ({ query, searchFor }: SearchProps) => Promise<
        ResponseAsyncProps | null | undefined>;
    loadConditions: () => Promise<void>;
    loadBrands: () => Promise<void>;
    loadWeights: () => Promise<void>;
    loadUserCommission: () => Promise<ResponseAsyncProps | undefined>;
    loadShippingOptions: () => Promise<ResponseAsyncProps | undefined>;
    loadMeasureTypes: () => Promise<ResponseAsyncProps | undefined>;
    loadColors: () => Promise<void>;
    // loadVariations: (adID: string) => Promise<ResponseAsyncProps | undefined>;
    update: (idAd: string, ad: CreateNewAdCreateProps) =>
        Promise<ResponseAsyncProps | undefined>
    loadSizes: ({ type, categoriesList }: LoadSizesProps) =>
        Promise<ResponseAsyncProps | undefined>;
    loadAllSizes: () => Promise<void>;
    list: ({ type }: AdListProps) => Promise<ResponseAsyncProps | undefined>;
    find: ({ id }: AdFindProps) => Promise<ResponseAsyncProps | undefined>;
    findVariation: ({ id }: AdFindProps) => Promise<ResponseAsyncProps | undefined>;
    findMeasureTableByAdID: ({ idAd }:
        FindMeasureTableByAdIDProps) => Promise<ResponseAsyncProps | undefined>;
    action: ({ type, id }: AdActionProps) => Promise<ResponseAsyncProps | undefined>;
    select: ({ _id, action }: {
        _id: string;
        action: 'add' | 'clear';
    }) => void;
    resetIsLoaded: () => void;
    ad: AdStateProps;
    variations: {
        listByAdID: {
            list: {
                data: LoadAdVariantionsByAdIDProps[];
                message: string;
                isLoaded: boolean;
            };
        },
        listByVariationID: ({ id }: AdFindProps) => Promise<ResponseAsyncProps
            | undefined>;
        images: {
            list: AdImagesProps[];
            colorsList: AdImagesProps[];
            customColorsList: AdImagesProps[];
            add: (event: React.ChangeEvent<HTMLInputElement>,
                type: ImagesActionType) => void;
            delete: (id: string, type: ImagesActionType) => void;
            clear: () => void;
        };
        items: {
            currentSizeID: string;
            list: VariationsItemProps[];
            add: ({ section, type, variants }: AddVariationsToCreateProps) => void;
            reset: () => void;
            addResponse: {
                error: boolean;
                message: string;
            } | undefined;
            remove: ({ _id }: {
                _id: string;
            }) => void;
        };
        colors: {
            add: ({ _id, imagem, nome, customColor }: AdColorsProps) => void
            addCustom: ({ _id, imagem, nome, customColor }: AdColorsProps) => void;
            data: {
                list: AdVariationsColorsProps;
                customList: AdVariationsColorsProps;
            }
        };
    };
    variationsAD: {
        find: {
            byAdID: ({ id }: AdFindProps) => Promise<ResponseAsyncProps | undefined>;
            byVariationID: ({ id }: AdFindProps) => Promise<ResponseAsyncProps | undefined>;
        };
        list: {
            byAdID: {
                data: LoadAdVariantionsByAdIDProps[];
                message: string;
                isLoaded: boolean;
            };
            byVariationID: {
                data: LoadAdVariantionsByAdIDProps;
                message: string;
                isLoaded: boolean;
            };
        };
        images: {
            variation: {
                add: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
                addToEdit: (images: AdImagesProps[]) => void;
                delete: (id: string) => void;
                clear: () => void;
                list: AdImagesProps[];
            };
            dnd: {
                onDragEnd: (result: DropResult) => void;
            }
        },
        colors: {
            load: () => Promise<ResponseAsyncProps | undefined>;
            list: VariationsColorProps;
            selected: {
                add: ({ ...props }: AdColorsProps) => void;
                list: AdColorsProps;
                clear: () => void;
            };
            customColor: {
                list: AdImagesProps;
                add: (event: React.ChangeEvent<HTMLInputElement>,
                    colorName: string) => Promise<void>;
                remove: () => void;
                addToEdit: ({ ...props }: AdImagesProps) => void;
            }
        }
    };
    externalActions: {
        status: {
            variationDialog: {
                state: boolean;
            };
            sizeDialog: {
                state: boolean;
            };
            colorsDialog: {
                state: boolean;
            };
        }
        action: ({ action, state }: ExternalDiloagActionProps) => void;
    }
}

export const AdsContext = createContext<AdsContextProps>({} as AdsContextProps);
