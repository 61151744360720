import React from 'react';
import { Box, Typography, useMediaQuery } from '@mui/material';

import { Main } from './styles';
import { sx } from './sx';
import { Image } from '@components';

interface ShowcaseItemProps {
    to: () => void;
    src: string;
    title?: string;
}

const ShowcaseItem: React.FC<ShowcaseItemProps> = ({
    to, src, title,
}: ShowcaseItemProps) => {
    const matches = useMediaQuery('(min-width:600px)');

    const { css } = sx();
    return (
        <Main onClick={() => to()}>
            <Box sx={{ ...css.sxBox }}>
                <Image src={src} alt={title} maxHeight={matches ? 445 : 180} />
            </Box>
            {title &&
                <Typography
                    variant='h3'
                    sx={{ ...css.sxTitle }}>
                    {title}
                </Typography>
            }
        </Main>
    );
};

export { ShowcaseItem };
