import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const { pxToRem } = typography;
    const matches = useMediaQuery('(min-width:600px)');

    const css = {
        sxBoxHeader: {
            boxShadow: !matches ? '0rem 0rem 0.625rem #00000033' :
                'none',
            borderBottom: matches ? `0.0625rem solid ${palette.clouds}` :
                'none',
            position: 'relative',
            pt: pxToRem(matches ? 12 : 14),
            pb: pxToRem(matches ? 12 : 14),
            zIndex: 470,
        },
        sxContainerHeader: {
            // padding: matches ? '0.75rem 0 !important' : '0.875rem 1rem',
        },
        sxBoxLogo: {
            position: 'absolute',
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
        },
        sxCol2: {
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        sxBoxIconSearchComponent: {
            padding: '0.875rem 1rem',
            cursor: 'pointer',
        },
        sxBoxIconSearch: {
            padding: '0.875rem 1rem',
            margin: '0.9375rem 1.25rem',
            cursor: 'pointer',
        },
        sxIconSearch: {
            fontSize: '1.125rem',
            color: palette.dark,
        },
        sxIconArrowLeft: {
            fontSize: '1.25rem',
            color: palette.dark,
        },
        sxBoxInputSearch: {
            'display': 'flex',
            'alignItems': 'center',
            'backgroundColor': palette.common.white,
            'border': `0.0625rem solid ${palette.clouds}`,
            'borderRadius': '0.3125rem',
            'width': '100%',
            'margin': '0.3369rem !important',
            'marginLeft': '1.3125rem !important',
            'boxShadow': '0rem 0.375rem 0.4375rem #0000001A',

            '@media (max-width: 1100px)': {
                width: '75%',
            },
            '@media (max-width: 800px)': {
                width: '65%',
            },
        },
        sxBoxInput: {
            width: '100%',
        },
        sxMenu: {
            '& > .MuiPaper-root': {
                minWidth: '17.5rem',
            },
        },
        usermenuAvatar: {
            ...{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
            },
            '&:hover': {
                '& .user-menu': {
                    display: 'block',
                },
            },
        },
        mainmenuAvatar: {
            ...{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
            },
            '&:hover': {
                '& .main-menu': {
                    display: 'block',
                },
            },
        },
    };
    return { css };
};
