/* eslint-disable indent */
/* eslint-disable max-len */
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    HeaderPage, MenuItem, MessageBox,
} from '@components';
import React, { ForwardRefExoticComponent, RefAttributes } from 'react';
import { Box, Checkbox, Grid } from '@mui/material';
import {
    FilterContent,
    ButtonSelectedFilter,
    BoxActiveFiltersList,
    FilterFooter,
} from '../../../components/filters';
import { useAds, useFilters } from '@hooks';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Item, Label } from './styles';

import {
    FilterComponentObjProps,
    FilterComponentProps,
    FormSizes,
    FormStaticProps,
} from '@interfaces';
import { FilterDataProps, FilterStateProps, NewAdDataProps } from '@interfaces/Contexts';
import Divider from '@mui/material/Divider';
import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

interface handleVisibilitySizesChildrenListProps {
    action: 'show' | 'hidden'
}

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

export const SizesFilter: ForwardRefExoticComponent<FilterComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            minHeightContent,
            filterInputsType,
            onClearAction,
            onSubmitAction,
        } = props;

        const { ad, loadSizes, loadAllSizes } = useAds();
        const filter = useFilters();
        const navigate = useNavigate();

        const [showSizesChildrenList,
            setShowSizesChildrenList] = React.useState<boolean>(false);

        const [sizeFilterSelected,
            setSizeFilterSelected] = React.useState<string>('');


        const [tempFilter, setTempFilter] = React.useState<FilterDataProps[]>(
            filter.filters.size.length > 0 ?
                filter.filters.size : [],
        );

        const handleVisibilitySizesChildrenList = (
            { action }: handleVisibilitySizesChildrenListProps) => {
            const actions = {
                show: true,
                hidden: false,
            };

            setShowSizesChildrenList((prev) => prev = actions[action]);
        };

        const handleSelectSizeGroup = (_id: string) => {
            setSizeFilterSelected((prev) => prev = _id);
        };

        const {
            register,
            handleSubmit,
            getValues,
            setValue,
            reset } =
            useForm<FormSizes>({
                mode: 'all',
            });

        const removeSizesFromHookForm = () => {
            const getFormValues = getValues('size');
            const filterFormValues = tempFilter.
                filter((item) => getFormValues[item._id as any]);
            setValue('size', filterFormValues);
        };

        const removeSelectedSizes = ({ _id, nome }: NewAdDataProps) => {
            const filter = tempFilter.filter((item) => item._id !== _id);
            setTempFilter((prev) => prev = [...filter]);
            setURLParams('size', undefined);
            filter.map((item) => {
                setURLParams('size', item._id+','+item.nome);
            });
            removeSizesFromHookForm();
        };

        const setURLParams = (key: string, value: string | undefined) => {
            const search = filter.handleSetURLParams(key, value);
            navigate({ search });
        };

        const clearAdData = () => {
            setTempFilter((prev) => prev = []);
            reset({
                size: [],
            });
            setURLParams('size', undefined);
        };

        const handleSizes = ({ _id, nome, type }: FilterComponentObjProps) => {
            if (type === 'multiple') {
                const find = tempFilter.find((item) => item._id === _id);

                if (!find) {
                    setTempFilter((prev) => prev = [...prev, {
                        _id: _id,
                        nome: nome,
                        param: 's=',
                        filter: 'size',
                    }]);
                    setValue('size', tempFilter);
                } else {
                    removeSelectedSizes({ _id, nome });
                    removeSizesFromHookForm();
                }
            }

            if (type === 'single') {
                setTempFilter((prev) => prev = [{
                    _id: _id,
                    nome: nome,
                    param: 's=',
                    filter: 'size',
                }]);
            }
        };

        const handleURLParams = async (data: FilterStateProps) => {
            const urlParams = new URLSearchParams(location.search);
            const response = await filter.handleURLParams(data, 'size');
            const search = response.params;
            search.map((item) => {
                const find = urlParams.getAll('size').find((findItem) =>
                    findItem === item[1]);
                if (!find) {
                    setURLParams(item[0], item[1]);
                }
            });
        };

        // const parseQueryStringURL = async (query: string, type: FiltersType, ad: AdStateProps) => {
        //     const response = await filter.parseQueryStringURL(query, type, ad);
        //     setTempFilter((prev) => prev = response.data);
        // };


        const onSubmit: SubmitHandler<FormSizes> = async (data) => {
            filter.add({
                type: 'size',
                data: tempFilter,
            });

            handleURLParams({ size: tempFilter } as any);
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        React.useEffect(() => {
            if (filter.filters.category.length > 0) {
                loadSizes({
                    type: 'byCategory',
                    categoriesList: filter.filters.category.map(({ _id }) => _id),
                });
            } else {
                loadAllSizes();
            }
        }, [filter.filters.category.length]);

        // React.useEffect(() => {
        //     parseQueryStringURL(location.search, 'sizes', ad);
        // }, []);

        return (
            <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)}>
                <Box mt={-1} height='100%'>
                    <FilterContent minHeightContent={minHeightContent}>
                        <Box>
                            {tempFilter.length > 0 && (
                                <>
                                    <Box pl={1}>
                                        <BoxActiveFiltersList>
                                            {tempFilter.map(({ _id, nome }) => (
                                                <ButtonSelectedFilter
                                                    key={_id}
                                                    value={nome}
                                                    onClick={() => removeSelectedSizes(
                                                        {
                                                            _id,
                                                            nome,
                                                        })} />
                                            ))}
                                        </BoxActiveFiltersList>
                                    </Box>
                                    <Divider light />
                                </>
                            )}
                            {ad.sizes.message && (ad.sizes.data.length > 0 ?
                                <>
                                    {!showSizesChildrenList && (
                                        <>
                                            {ad.sizes.data.map(({ _id, nome }) => (
                                                <MenuItem
                                                    key={_id}
                                                    onClick={() => {
                                                        handleVisibilitySizesChildrenList(
                                                            { action: 'show' },
                                                        ); handleSelectSizeGroup(_id);
                                                    }}
                                                    borderType={'bottom'}
                                                    title={{
                                                        text: nome,
                                                        size: 'small',
                                                        fontWeight: 'bold',
                                                    }}>
                                                    <Box p={2}>
                                                        <I icon={['far', 'chevron-right']} />
                                                    </Box>
                                                </MenuItem>
                                            ))}
                                        </>
                                    )}
                                    {showSizesChildrenList && (
                                        <Box>
                                            {ad.sizes.data.filter((size) => (size._id === sizeFilterSelected)).
                                                map(({ nome }) => (
                                                    <HeaderPage
                                                        titleAlign={'default'}
                                                        icon={['far', 'chevron-left']}
                                                        title={nome}
                                                        size='small'
                                                        onClick={() => handleVisibilitySizesChildrenList(
                                                            { action: 'hidden' },
                                                        )}
                                                    />
                                                ))}
                                            <Box p={2} pt={1}>
                                                <Grid container spacing={2}>
                                                    {ad.sizes.data.filter((size) => (size._id === sizeFilterSelected)).map(({ tamanhos, nome }) => (
                                                        tamanhos.map(({ _id, nome }) => (
                                                            <>
                                                                <Grid key={_id} item xs={3}>
                                                                    <Item className={!!tempFilter
                                                                        .find((item) =>
                                                                            item._id === _id) ?
                                                                        'active' :
                                                                        ''}>
                                                                        <Label htmlFor={_id}>
                                                                            {nome}
                                                                            {filterInputsType === 'checkbox' && (
                                                                                <Checkbox
                                                                                    id={_id}
                                                                                    size='small'
                                                                                    value={_id}
                                                                                    {...register('size', {
                                                                                        required: false,
                                                                                    })}
                                                                                    checked={!!tempFilter
                                                                                        .find((item) => item._id === _id)}
                                                                                    onChange={() => handleSizes({
                                                                                        _id: _id,
                                                                                        nome: nome,
                                                                                        type: 'multiple',
                                                                                    })}
                                                                                />
                                                                            )}
                                                                        </Label>
                                                                    </Item>
                                                                </Grid>
                                                            </>
                                                        ))
                                                    ))}
                                                </Grid>
                                            </Box>
                                        </Box>
                                    )}
                                </> :
                                <Box sx={{ p: 1 }}>
                                    <MessageBox message={'Não possuímos tamanhos para a categoria selecionada'} type={'info'} />
                                </Box>
                            )}
                        </Box>
                    </FilterContent>
                    {
                        showSizesChildrenList && (
                            ad.sizes.data.length > 0 && (
                                <FilterFooter
                                    onClearAction={onClearAction ? onClearAction as () => void : clearAdData}
                                    onSubmitAction={onSubmitAction ? onSubmitAction as () => void : () => { }}
                                // disabledOnSubmit={!(!!tempSizes.length)}
                                />
                            )
                        )
                    }
                </Box >
            </Box >
        );
    });
