import { Box } from '@mui/material';
import React from 'react';

import { Image, Anchor } from './styles';
import { sx } from './sx';
import { ImageSliderBlogProps } from '@interfaces';

const ImageSliderBlog: React.FC<ImageSliderBlogProps> = ({
    src, link,
}) => {
    const { sxBox } = sx();
    return (
        <Box sx={{ ...sxBox }}>
            <Anchor href={link}>
                <Image src={src} alt="" />
            </Anchor>
        </Box>
    );
};

export { ImageSliderBlog };
