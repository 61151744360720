import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        sxStack: {
            backgroundColor: palette.common.white,
            height: '3.125rem',
            bottom: '0',
            left: '0',
            position: 'fixed',
            width: '100%',
            boxShadow: '0rem -0.125rem 0.625rem rgba(0, 0, 0, 0.1)',
            zIndex: '1000',
            padding: '0 1.625rem',
        },
    };

    return {
        ...css,
    };
};
