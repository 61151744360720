import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { CheckboxProps } from '@mui/material';

const muiCheckbox: CheckboxProps = {
    icon: <I
        icon={['fat', 'square']}
        fontSize={20}
        color='#6A6F7A'
    />,
    checkedIcon: <I
        icon={['fat', 'square-check']}
        fontSize={20}
        color='#010101'
    />,
};

export { muiCheckbox };
