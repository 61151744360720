/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
    AvatarGroup,
    BoxContent,
    HeaderPage,
    MessageBox,
    MuiDialog,
    Page,
    Product,
    ProductStatus,
    SmallTableColumn,
    Snackbar,
    TabPanel,
} from '@components';
import {
    Box,
    CircularProgress,
    Container,
    Tab,
    Tabs,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ToggleStaticProps } from '@interfaces';
import { sx } from './sx';
import { usePurchases } from '../../../../hooks/shopping/purchases';
import { images } from '../../../../utils/images';
import Divider from '@mui/material/Divider/Divider';
import { formatDate } from '@utils';

function a11yProps(index: number, id: '') {
    return {
        'id': id,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const List = () => {
    const { palette, typography } = useTheme();
    const { css } = sx();
    const { purchases } = usePurchases();

    const matches = useMediaQuery('(min-width:600px)');

    const snackRef = React.useRef<ToggleStaticProps>(null);
    const dialogConfirm = React.useRef<ToggleStaticProps>(null);
    const navigate = useNavigate();

    const [tab, setTab] = React.useState(0);

    const handleDeleteItemCart = () => {
        dialogConfirm.current?.hide();
        snackRef.current?.show();
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    React.useEffect(() => {
        purchases.load();
    }, []);

    return (
        <Page.Wrapper border position='relative'>
            <Box>
                <HeaderPage
                    titleAlign={'default'}
                    title={'Minhas Compras'}
                    icon={['far', 'store']}
                />

                <Container
                    maxWidth='lg'
                    className={'no-padding'}>
                    <Tabs
                        value={tab}
                        onChange={handleChange}>
                        <Tab
                            label="Em aberto"
                            {...a11yProps(0, '')} />
                        <Tab
                            label="Concluídas"
                            {...a11yProps(1, '')} />
                    </Tabs>
                    <Divider light />
                    <TabPanel value={tab} index={0}>
                        <Box>
                            {purchases.list.isLoaded ?
                                purchases.list.data.map((item) => {
                                    // This condition returns false.
                                    const verify = ((item.status_atual === 'PAGAMENTO_NAO_APROVADO') ||
                                        (item.status_atual === 'EXPIRADO') ||
                                        (item.status_atual === 'CANCELADO') ||
                                        (item.status_atual === 'FINALIZADO'));
                                    if (verify) return;
                                    return (
                                        <Box onClick={() => navigate('/minhas-compras/detalhes/' + item._id + '?tab=em-aberto')}>
                                            <BoxContent
                                                key={item._id}
                                                borderBottom
                                                paddingLeft
                                                actions={[
                                                    {
                                                        actionType: 'goTo',
                                                        actionFunction:
                                                            () => navigate('/minhas-compras/detalhes/' + item._id + '?tab=em-aberto'),
                                                    }]}>
                                                <Box paddingBottom={typography.pxToRem(10)}>
                                                    {item.itens.map((product, i, list) => (
                                                        <Box mb={i + 1 === list.length ? 0 : 1}>
                                                            <Product
                                                                key={product._id}
                                                                size={matches ? 'medium' : 'small'}
                                                                align={'right'}
                                                                contentAlign='center'
                                                                src={images.load(product.id.fotos[0], 'server')}
                                                                title={product.id.id_anuncio ? product.id.id_anuncio.titulo && product.id.id_anuncio.titulo : product.id.titulo}
                                                            />
                                                        </Box>
                                                    ))}
                                                </Box>
                                                <SmallTableColumn
                                                    title='Data da compra'
                                                    value={formatDate(item.data_inclusao)} />

                                                <Box>
                                                    <SmallTableColumn
                                                        title='Vendedor'
                                                        value={
                                                            <AvatarGroup
                                                                align='right'
                                                                borderColor={palette.common.white}
                                                                size='tiny'
                                                                src={item.vendedor.imagens ?
                                                                    images.load(item.vendedor.imagens.avatar, 'server') : ''}
                                                                title={item.vendedor.nome} />
                                                        } />
                                                </Box>
                                                <Box pt={2} />
                                                <Box sx={css.status}>
                                                    <ProductStatus status={item.status_atual} />
                                                </Box>
                                            </BoxContent>
                                        </Box>
                                    );
                                }) : ''}
                        </Box>
                        {purchases.list.isLoaded ?
                            purchases.list.data.length > 0 ?
                                purchases.list.data.filter((item) => {
                                    // This condition returns false.
                                    return !((item.status_atual === 'PAGAMENTO_NAO_APROVADO') ||
                                        (item.status_atual === 'EXPIRADO') ||
                                        (item.status_atual === 'CANCELADO') ||
                                        (item.status_atual === 'FINALIZADO'));
                                }).length === 0 && <MessageBox
                                    message={'Nenhuma compra em aberto'}
                                    type={'info'}
                                /> : <MessageBox
                                    message={'Você ainda não realizou nenhuma compra'}
                                    type={'info'} /> :
                            <Box sx={{
                                p: 3,
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <CircularProgress color='inherit' size={24} />
                            </Box>}
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Box>
                            {purchases.list.isLoaded ?
                                purchases.list.data.map((item) => {
                                    // This condition returns false
                                    const verify = ((item.status_atual === 'AGUARDANDO_PAGAMENTO') ||
                                        (item.status_atual === 'ETIQUETA_GERADA') ||
                                        (item.status_atual === 'POSTADO') ||
                                        (item.status_atual === 'PAGO'));
                                    if (verify) return;
                                    return (
                                        <Box onClick={() => navigate('/minhas-compras/detalhes/' + item._id)}>
                                            <BoxContent
                                                key={item._id}
                                                borderBottom
                                                paddingLeft
                                                actions={[
                                                    {
                                                        actionType: 'goTo',
                                                        actionFunction:
                                                            () => navigate('/minhas-compras/detalhes/' + item._id),
                                                    }]} >
                                                <Box paddingBottom={typography.pxToRem(10)}>
                                                    {item.itens.map((product, i, list) => (
                                                        <Box mb={i + 1 === list.length ? 0 : 1}>
                                                            <Product
                                                                key={product._id}
                                                                size={matches ? 'medium' : 'small'}
                                                                align={'right'}
                                                                contentAlign='center'
                                                                src={images.load(product.id.fotos[0], 'server')}
                                                                title={product.id.titulo}
                                                            />
                                                        </Box>
                                                    ))}
                                                </Box>
                                                <SmallTableColumn
                                                    title='Data da compra'
                                                    value={formatDate(item.data_inclusao)} />
                                                <Box>
                                                    <SmallTableColumn
                                                        title='Vendedor'
                                                        value={
                                                            <AvatarGroup
                                                                align='right'
                                                                borderColor={palette.common.white}
                                                                size='tiny'
                                                                src={item.vendedor.imagens ?
                                                                    images.load(item.vendedor.imagens.avatar, 'server') : ''}
                                                                title={item.vendedor.nome} />
                                                        } />
                                                </Box>
                                                <Box pt={2} />
                                                <Box sx={css.status}>
                                                    <ProductStatus status={item.status_atual} />
                                                </Box>
                                            </BoxContent>
                                        </Box>
                                    );
                                }) :
                                <Box sx={{
                                    p: 3,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}>
                                    <CircularProgress color='inherit' size={24} />
                                </Box>}
                        </Box>

                        {purchases.list.isLoaded ?
                            purchases.list.data.length > 0 ?
                                purchases.list.data.filter((item) => {
                                    // This condition returns false.
                                    return !((item.status_atual === 'AGUARDANDO_PAGAMENTO') ||
                                        (item.status_atual === 'ETIQUETA_GERADA') ||
                                        (item.status_atual === 'POSTADO') ||
                                        (item.status_atual === 'PAGO'));
                                }).length === 0 && <MessageBox
                                    message={'Nenhuma compra marcada como concluída'}
                                    type={'info'}
                                /> : <MessageBox
                                    message={'Você ainda não realizou nenhuma compra'}
                                    type={'info'} /> : ''}
                    </TabPanel>
                </Container>

            </Box>
            <MuiDialog
                maxWidth='xs'
                ref={dialogConfirm}
                contentPadding={false}
                title='Deletar Favorito?'
                showDialogTitle
                onConfirm={{
                    action: handleDeleteItemCart,
                    text: 'Confirmar',
                }}>
                <Box
                    sx={{
                        '&:last-child .box-content': {
                            borderBottom: 0,
                        },
                    }}>
                    <BoxContent>
                        <Product
                            size={'big'}
                            align={'right'}
                            // eslint-disable-next-line max-len
                            src={'https://static.netshoes.com.br/produtos/tenis-adidas-breaknet-masculino/26/NQQ-4378-026/NQQ-4378-026_zoom1.jpg?ts=1661862748'}
                            title={'Tênis adidas'}
                            legend={'Bande Studio'}
                            price={'180,00'} />
                    </BoxContent>
                </Box>
            </MuiDialog>
            <Snackbar
                ref={snackRef}
                message='Item excluído dos favoritos'
                severity='success'
            />
        </Page.Wrapper>
    );
};

export { List };
