import { HeaderPage } from '@components';
import { Box, useTheme } from '@mui/material';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface AuthenticatedContent {
    children: React.ReactNode;
    headerIcon: IconProp;
    headerTitle: string;
    headerAction: () => void;
}

const AuthenticatedContent: React.FC<AuthenticatedContent> = (
    {
        children,
        headerIcon,
        headerTitle,
        headerAction,
    }: AuthenticatedContent) => {
    const { palette } = useTheme();

    return (
        <Box
            sx={{
                border: '1px solid ' + palette.clouds,
                // mb: 2,
            }}>
            <HeaderPage
                onClick={headerAction}
                titleAlign={'default'}
                title={headerTitle}
                icon={headerIcon} />
            <Box>
                {children}
            </Box>
        </Box>
    );
};

export { AuthenticatedContent };
