import { useTheme, alpha } from '@mui/material';

const sx = () => {
    const { typography, palette } = useTheme();
    const { pxToRem } = typography;
    const css = {
        wrapper: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: palette.common.white,
            borderWidth: pxToRem(1),
            borderStyle: 'solid',
            borderColor: palette.clouds,
            borderRadius: pxToRem(4),
            pt: pxToRem(15),
            pb: pxToRem(15),
            width: '100%',
            cursor: 'pointer',
            boxShadow: '0px 3px 6px ' + alpha(palette.dark, 0.1),
        },
        icon: {
            main: {
                width: pxToRem(50),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative',
            },
            status: (bgColor: string) => {
                return {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: pxToRem(16),
                    height: pxToRem(16),
                    borderRadius: pxToRem(100),
                    backgroundColor: bgColor,
                    position: 'absolute',
                    top: pxToRem(12),
                    right: pxToRem(8),
                };
            },
        },
    };
    return { css };
};

export { sx };
