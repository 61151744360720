import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');

    const css = {
        wrapper: {
            boxShadow: '0px 3px 6px #00000029',
            mb: typography.pxToRem(15),
        },
        header: {
            pt: 1,
            pb: 1,
            pl: 2,
            borderBottom: '1px solid' + palette.clouds,
        },
        total: {
            pt: 1,
            pb: 1,
            pl: 2,
            pr: 2,
            backgroundColor: palette.clouds,
        },
        totalWrapper: {
            position: 'fixed',
            zIndex: 99,
            backgroundColor: 'white',
            left: 0,
            bottom: 0,
            width: '100%',
            boxShadow: '0px -4px 6px #00000029',
        },
        footer: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexDirection: matches ? 'row' : 'column-reverse',
            // pl: 2,
            // pr: 2,
            pt: 1,
            pb: matches ? 1 : 0,
            shippingMob: {
                justifyContent: 'space-between',
                width: '100%',
                pl: 2,
                pr: 2,
                pb: 1,
                borderBottom: '1px solid' + palette.clouds,
            },
            shippingDesk: {
                pr: 2,
            },
        },
    };

    return { css };
};
