import React from 'react';
import { Button, useTheme } from '@mui/material';
import { ButtonInterestProps } from '@interfaces';

const ButtonInterest: React.FC<ButtonInterestProps> = ({
    text, onClick, color, backgroundColor, padding, margin, type,
}: ButtonInterestProps) => {
    const { typography } = useTheme();
    return (
        <Button type={type} onClick={onClick} sx={{
            'padding': padding,
            'backgroundColor': backgroundColor,
            '&:hover': {
                backgroundColor: backgroundColor,
            },
            'margin': margin,
            'color': color,
            'borderRadius': '0',
            'fontWeight': typography.fontWeightMedium,
        }}>
            {text}
        </Button>
    );
};

export { ButtonInterest };
