import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxAddressBox: {
            default: {
                borderRadius: '0.1875rem',
                display: 'flex',
                justifyContent: 'space-between',
                cursor: 'pointer',
            },
            add: {
                border: `0.0625rem dashed ${palette.americanRiver};`,
                backgroundColor: palette.common.white,
            },
            addError: {
                border: '0.0625rem dashed #d32f2f',
                backgroundColor: palette.common.white,
            },
            list: {
                backgroundColor: palette.clouds,
            },
            edit: {
                backgroundColor: palette.clouds,
            },
        },
        sxLocationIcon: {
            backgroundColor: palette.common.white,
            height: 32,
            width: 32,
            borderRadius: '1.5625rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0.625rem 0 0.625rem 0.625rem',
            padding: '0.625rem',
        },
        sxTypeAddress: {
            padding: '0.3125rem 1.25rem',
            fontSize: '0.875rem',
            fontWeight: 700,
            borderRadius: '0.1875rem',
            display: 'inline',
            backgroundColor: palette.common.white,
        },
        sxBoxIconAction: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0.9375rem',
        },
        sxTitle: {
            fontWeight: typography.fontWeightBold,
            marginBottom: '0.1875rem',
        },
        sxLegend: {
            fontWeight: typography.fontWeightMedium,
        },
        sxIcon: {
            color: palette.dark,
            fontSize: '1.125rem',
        },
        sxIconLocation: {
            color: palette.dark,
            fontSize: '1rem',
        },
    };
    return { css };
};
