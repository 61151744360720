import React from 'react';
import { Typography, Box, useTheme } from '@mui/material';

interface FilterButtonProps {
    id: string;
    filter: string;
    children?: React.ReactNode;
    position: 'left' | 'right';
    onClick?: () => void;
}

import { Label } from './styles';
import { sx } from './sx';

const FilterButton: React.FC<FilterButtonProps> = ({
    id, filter, children, position, onClick,
}: FilterButtonProps) => {
    const { css } = sx();
    const { palette } = useTheme();
    return (
        <Box onClick={onClick} sx={{ ...css.sxBoxWrap }}>
            <Label backgroundColor={palette.dark} htmlFor={id}>
                {filter}
                {children}
                <Box sx={{ ...css.sxBoxOverlay }}
                    className={`overlay ${position}`}>
                    <Typography
                        sx={{ ...css.sxText }}
                        variant='caption'>
                        {filter}
                    </Typography>
                </Box>
            </Label>
        </Box>
    );
};

export { FilterButton };
