/* eslint-disable no-unused-vars */
import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import Typography from '@mui/material/Typography';
import { Content, Header, Icon, Main } from './styles';
import { Link, useLocation, useParams } from 'react-router-dom';
// import { CartaoMessage, PixBoletoMessage } from './messages';
import { useCheckout } from '../../../../../../hooks/shopping/checkout';
import { ListOrderProps } from '@interfaces/Checkout';

interface TemplateSuccessProps {
    children: React.ReactNode;
}
const TemplateSuccess = ({ children }: TemplateSuccessProps) => {
    const { checkout } = useCheckout();
    const { palette, typography } = useTheme();
    const { pxToRem } = typography;
    // const [contentType, setContentType] = React.useState(CartaoMessage);
    const matches = useMediaQuery('(min-width:600px)');
    // const params = useParams();
    const location = useLocation();
    // const [order, setOrder] = React.useState<ListOrderProps>({} as ListOrderProps);

    // const findOrder = async () => {
    //     const id = params.orderID;
    //     if (id) {
    //         const response = await checkout.order.find({ orderID: id });
    //         if (!(response?.error)) {
    //             // setOrder((prev) => prev = response?.response.payload.pagamento);
    //         }
    //     };
    // };

    // const feedbackMessage = (type: 'CARTAO' | 'PIX' | 'BOLETO') => {
    //     switch (type) {
    //     case 'CARTAO':
    //         setContentType(CartaoMessage);
    //         break;
    //     case 'BOLETO':
    //         setContentType(PixBoletoMessage);
    //         break;
    //     case 'PIX':
    //         setContentType(PixBoletoMessage);
    //         break;
    //     }
    // };

    React.useEffect(() => {
        // feedbackMessage('CARTAO');
        // findOrder();
    }, []);


    return (
        <Main>
            <Header>
                <Icon component='div'>
                    <I
                        icon={['far', 'check']}
                        fontSize={!matches ? 28 : 36}
                        color={palette.status.accepted}
                    />
                </Icon>
                <Typography
                    variant='h2'
                    component='h2'
                    align={!matches ? 'center' : 'left'}
                    fontSize={!matches ? pxToRem(18) : pxToRem(28)}
                    fontWeight={typography.fontWeightBold}
                    ml={!matches ? 0 : pxToRem(12)}
                    mt={!matches ? pxToRem(12) : 0}
                    color={palette.common.white}>
                    Pedido realizado com sucesso!
                </Typography>
            </Header>

            <Content>
                {children}
            </Content>
        </Main>
    );
};

export { TemplateSuccess };
