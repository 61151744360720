import { Box, useTheme } from '@mui/material';

interface FilterWrapperProps {
    children: React.ReactNode;
}

const FilterWrapper: React.FC<FilterWrapperProps> = (
    { children }: FilterWrapperProps) => {
    const { palette } = useTheme();
    return (
        <Box className='content'
            sx={{
                position: 'fixed',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                right: 0,
                zIndex: 1002,
                backgroundColor: palette.common.white,
            }}>
            {children}
        </Box>
    );
};

export { FilterWrapper };
