import React from 'react';
import { filtersHookProvider } from '../../hooks/filters';
import { FiltersContext } from './context';

interface FiltersProviderProps {
    children: React.ReactNode;
};

const FiltersProvider = ({ children }: FiltersProviderProps) => {
    const filters = filtersHookProvider();

    return (
        <FiltersContext.Provider value={filters}>
            {children}
        </FiltersContext.Provider>
    );
};

export {
    FiltersProvider,
};
