import styled from 'styled-components';

export const Main = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.625rem 0 1.25rem 0;
`;

export const Button = styled.button`
    background: 0;
    border: none;
    cursor: pointer;
    z-index: 1000;
    padding: 0;
    margin: 0 0.625rem;
    color: #010101;
`;
