import * as Yup from 'yup';

export const formSchemaCheckbox = Yup.object().shape({
    condition: Yup.array().min(1, 'Selecione uma condição').
        nullable().
        required('Selecione uma condição'),
});

export const formSchemaRadio = Yup.object().shape({
    condition: Yup.string().required('Selecione uma condição'),
});
