/* eslint-disable max-len */
import React from 'react';
import {
    useMediaQuery,
    Container,
    TextField,
    Stack,
    Box,
    Typography,
    InputAdornment,
    IconButton,
    CircularProgress,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormLogin, ToggleStaticProps } from '@interfaces';

import {
    ButtonLarge,
    // OrDivisor,
    // ButtonFacebook,
    Snackbar,
} from '@components';

import { formSchema } from './yup';
import { sx } from './sx';
import { Form } from './styles';
import { useAuth, useUser } from '@hooks';
import { useCart } from '../../../hooks/shopping/cart';


const Login: React.FC = () => {
    const { cart } = useCart();
    const auth = useAuth();
    const { loadUser } = useUser();
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const { css } = sx();
    const [showPassword, setShowPassword] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const { register, handleSubmit, formState: { errors } } =
        useForm<FormLogin>({
            resolver: yupResolver(formSchema),
            mode: 'onBlur',
        });

    const onSubmit: SubmitHandler<FormLogin> = async (data) => {
        setIsLoading(true);
        const { email, password } = data;
        const response = await auth.login({ email: email, password: password });

        if (!response?.error) {
            await loadUser();

            if ((document as any).rewardHub) {
                (document as any).rewardHub
                    .initializeSession(response?.response.payload.hubSession);
                (document as any).rewardHub.openHub();
            }

            await cart.load();

            setTimeout(() => {
                navigate('/');
            }, 200);
            return;
        } else {
            if (response.response.payload) {
                const email = response.response.payload.validationEmailEnable;
                const sms = response.response.payload.validationSMSEnable;

                setFeedback({
                    message: response?.response
                        .message, type: response?.response.type,
                });

                snackRef.current?.show();
                setIsLoading(false);

                if (email) {
                    setTimeout(() => {
                        return navigate('/criar-conta/enviar-codigo/email');
                    }, 1500);
                }

                if (sms) {
                    setTimeout(() => {
                        return navigate('/criar-conta/enviar-codigo/sms-email');
                    }, 1500);
                }
                return;
            }

            setFeedback({
                message: response?.response
                    .message, type: response?.response.type,
            });

            snackRef.current?.show();
            setIsLoading(false);
        }
    };

    return (
        <Container
            maxWidth={'xs'}
            sx={{ ...css.sxContainer }}>
            <Box sx={{ ...css.sxBoxAccess }}>
                <Typography
                    variant='h1'
                    sx={{ ...css.sxAccessText }}
                >
                    Acesse sua conta
                </Typography>
                {/* <ButtonFacebook
                    text='Entrar com o Facebook'
                    onClick={() => null} /> */}
            </Box>
            {/* <OrDivisor /> */}
            <Form
                onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    focused={true}
                    label={'Login'}
                    placeholder='Login'
                    id="outlined-basic"
                    helperText={errors.email ? errors.email.message : ''}
                    variant="outlined"
                    {...register('email', { required: true })} />
                <TextField
                    focused={true}
                    label={'Senha atual'}
                    placeholder='Senha atual'
                    type={showPassword ? 'text' : 'password'}
                    id="input-with-icon-textfield"
                    helperText={errors.password ? errors.password.message : ''}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)}
                                    sx={{ ...css.sxIconButtonEye }}>
                                    {showPassword ?
                                        <I icon={['fas', 'eye-slash']} /> :
                                        <I icon={['fas', 'eye']} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{ paddingRight: 0 }}
                    {...register('password', { required: true })} />

                <Stack
                    spacing={matches ? 8 : 0}
                    justifyContent={matches ? 'start' : 'space-between'}
                    alignItems={'center'}
                    direction={'row'}
                    sx={{ marginBottom: '1.875rem' }}>
                    <Stack alignItems={'center'}>
                        <Typography
                            variant='caption'
                            onClick={() => navigate('/recuperar-senha/email')}
                            sx={{ ...css.sxForgotPassLink }}>
                            Esqueci minha senha
                        </Typography>
                    </Stack>
                </Stack>
                <ButtonLarge
                    {...css.sxButtonLarge}
                    type={'submit'}
                    text={'Fazer login'}
                    onClick={() => null}>
                    {isLoading &&
                        <CircularProgress
                            size={14}
                            color='inherit'
                            sx={{ ml: 0.5 }} />
                    }
                </ButtonLarge>
                <Box
                    sx={{ ...css.sxBoxEnd }}>
                    {/* <Typography
                        variant='caption'
                        sx={{ ...css.sxFirstAccessLink }}>
                        Primeiro acesso
                        <I
                            style={{ ...css.sxChevronRight }}
                            icon={['fas', 'chevron-right']} />
                    </Typography> */}
                    <Typography
                        variant='caption'
                        sx={{ ...css.sxAccountQuestion }}>
                        Ainda não possui uma conta?
                    </Typography>
                    <Typography
                        variant='caption'
                        onClick={() => navigate('/criar-conta')}
                        sx={{ ...css.sxCreateAccountLink }}>
                        Criar conta
                    </Typography>
                </Box>
            </Form>
            <Snackbar
                ref={snackRef}
                message={feedback ? feedback.message : ''}
                severity={feedback ? feedback.type as any : 'success'}
            />
        </Container>
    );
};

export { Login };
