import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Container, Stack, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ButtonInterest, FixedMenu } from '@components';

import { sx } from './sx';

const NotPermission: React.FC = () => {
    const { css } = sx();
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    return (
        <Container
            sx={{ ...css.sxContainer }}
            maxWidth='lg'>
            <Stack
                spacing={1}
                alignItems={'center'}>
                <I
                    style={{ ...css.sxLockIcon }}
                    icon={['fas', 'lock']} />
                <Typography sx={{ ...css.sxTitle }} variant='h2'>
                    Você não tem permissão para <br />
                    acessar essa página
                </Typography>
                <ButtonInterest
                    text={'Voltar para a home'}
                    color={'#fff'}
                    margin={'1.875rem 0 0 0 !important'}
                    backgroundColor={'#010101'}
                    padding={'0.625rem 2.5rem'}
                    onClick={() => navigate('/')} />
            </Stack>
            {!matches &&
                <FixedMenu />}
        </Container>
    );
};

export { NotPermission };
