import { Skeleton, useMediaQuery, useTheme } from '@mui/material';


export const skeleton = () => {
    const { typography } = useTheme();

    const rm = (px: number) => typography.pxToRem(px);
    const m = useMediaQuery('(min-width:600px)');


    const mediaQuery = {
        slider: {
            height: rm(m ? 600 : 300),
            width: '100%',
        },
        info: {
            brand: {
                height: rm(20),
                width: rm(80),
            },
            title: {
                height: rm(24),
                width: rm(260),
            },
            price: {
                height: rm(28),
                width: rm(120),
            },
        },
        sizes: {
            height: rm(30),
            width: rm(220),
        },
        measurement: {
            height: rm(42),
            width: rm(162),
        },
        shipping: {
            title: {
                height: rm(18),
                width: rm(120),
            },
            option: {
                height: rm(88),
                width: rm(88),
            },
        },
        quantity: {
            title: {
                height: rm(18),
                width: rm(100),
            },
            option: {
                height: rm(30),
                width: rm(128),
            },
        },
        buyBtn: {
            height: rm(42),
            width: '100%',
        },
        seller: {
            title: {
                height: rm(24),
                width: rm(80),
            },
            info: {
                avatar: {
                    height: rm(56),
                    width: rm(56),
                },
                title: {
                    height: rm(30),
                    width: rm(110),
                },
            },
        },
        description: {
            title: {
                height: rm(24),
                width: rm(80),
            },
            line1: {
                height: rm(14),
                width: rm(220),
            },
            line2: {
                height: rm(14),
                width: rm(260),
            },
            line3: {
                height: rm(14),
                width: rm(200),
            },
            line4: {
                height: rm(14),
                width: rm(230),
            },
        },
    };

    const elems = {
        slider: <Skeleton variant="rectangular" sx={mediaQuery.slider} />,
        info: [
            <Skeleton variant="rectangular" sx={mediaQuery.info.brand} />,
            <Skeleton variant="rectangular" sx={mediaQuery.info.title} />,
            <Skeleton variant="rectangular" sx={mediaQuery.info.price} />,
        ],
        sizes: <Skeleton variant="rectangular" sx={mediaQuery.sizes} />,
        measurement: <Skeleton variant="rectangular" sx={mediaQuery.measurement} />,
        shipping: [
            <Skeleton variant="rectangular" sx={mediaQuery.shipping.title} />,
            <Skeleton variant="rectangular" sx={mediaQuery.shipping.option} />,
        ],
        quantity: [
            <Skeleton variant="rectangular" sx={mediaQuery.quantity.title} />,
            <Skeleton variant="rectangular" sx={mediaQuery.quantity.option} />,
        ],
        buyBtn: <Skeleton variant="rectangular" sx={mediaQuery.buyBtn} />,
        seller: {
            title: <Skeleton variant="rectangular" sx={mediaQuery.seller.title} />,
            info: [
                <Skeleton variant="circular" sx={mediaQuery.seller.info.avatar} />,
                <Skeleton variant="rectangular" sx={mediaQuery.seller.info.title} />,
            ],
        },
        description: {
            title: <Skeleton variant="rectangular" sx={mediaQuery.description.title} />,
            info: [
                <Skeleton variant="rectangular" sx={mediaQuery.description.line1} />,
                <Skeleton variant="rectangular" sx={mediaQuery.description.line2} />,
                <Skeleton variant="rectangular" sx={mediaQuery.description.line3} />,
                <Skeleton variant="rectangular" sx={mediaQuery.description.line4} />,
            ],
        },
    };
    return elems;
};
