import { formatNumberToPriceBRL } from './formatNumberToPriceBRL';

const adPriceWithCommission = (price: number,
    commission: string) => {
    // const { value } = event.target;
    // const price = Number(value);

    if (price > 0) {
        const calc = price * (Number(commission) / 100);
        const descontValue = parseFloat(String(calc)).toFixed(2);
        const princeWithCommission = price - Number(descontValue);
        const originalPrice = formatNumberToPriceBRL(Number(princeWithCommission) ||
            Number('0,00'));

        return originalPrice;
    };

    return '0.00';
};

export { adPriceWithCommission };
