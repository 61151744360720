import React from 'react';
import { PaymentMethodContext } from './context';
import { paymentMethodProvider } from '../../hooks/payment-method';

interface PaymentProviderProps {
    children: React.ReactNode;
};

const PaymentMethodProvider = ({ children }: PaymentProviderProps) => {
    const paymentMethod = paymentMethodProvider();

    return (
        <PaymentMethodContext.Provider value={paymentMethod.paymentMethod}>
            {children}
        </PaymentMethodContext.Provider>
    );
};

export {
    PaymentMethodProvider,
};
