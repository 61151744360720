/* eslint-disable max-len */
export const regionTelephone = [
    {
        id: '95feee1d459c',
        name: 'BR',
        flag: 'https://images.emojiterra.com/google/noto-emoji/unicode-15/color/svg/1f1e7-1f1f7.svg',
        code: '+55',
    },
    {
        id: 'aecccf5e71dc',
        name: 'USA',
        flag: 'https://images.emojiterra.com/google/noto-emoji/unicode-15/color/svg/1f1fa-1f1f8.svg',
        code: '+1',
    },
    {
        id: 's45ecf5e718c',
        name: 'IT',
        flag: 'https://images.emojiterra.com/google/noto-emoji/unicode-15/color/svg/1f1ee-1f1f9.svg',
        code: '+39',
    },
];

export const maskCellphone = {
    BR: '(00) 00000-0000',
    USA: '(000) 000-0000',
    IT: '00 0000-0000',
};
