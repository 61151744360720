import { useStepper } from '@hooks';
import { Container } from '@mui/material';
import { Wizard } from './components/wizard';
import { CreateAccountForm } from './forms/create-form';

const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];

const CreateAccount = () => {
    const stepper = useStepper({ steps: steps });

    return (
        <Container maxWidth={'xs'}>

            <Wizard
                steps={steps}
                activeStep={stepper.activeStep}
                completed={stepper.completed}
                handleBack={stepper.handleBack}
                handleNext={stepper.handleNext}>

                {stepper.activeStep === 0 && (
                    <CreateAccountForm />
                )}
            </Wizard>
        </Container>
    );
};

export { CreateAccount };
