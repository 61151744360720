import React from 'react';
import {
    Container,
    Box,
    Typography,
    TextField,
    Button,
    IconButton,
    InputAdornment,
    useTheme,
    AlertColor,
    CircularProgress,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { FormRecoveryPassword, ToggleStaticProps } from '@interfaces';


import { formSchema } from './yup';
import { sx } from './sx';
import { Form } from './styles';
import { useAuth } from '@hooks';
import { Snackbar } from '@components';
import { useNavigate } from 'react-router-dom';

const RecoveryPasswordForm: React.FC = () => {
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const { palette } = useTheme();
    const { css } = sx();
    const { changePassword } = useAuth();

    const navigate = useNavigate();

    const [showPassword, setShowPassword] = React.useState(false);
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
        isLoading: false,
    });

    const searchURL = window.location.search;
    const tokenURL = new URLSearchParams(searchURL).get('token');

    const { register, handleSubmit, formState: { errors } } =
        useForm<FormRecoveryPassword>({
            resolver: yupResolver(formSchema),
            mode: 'onBlur',
        });

    const onSubmit: SubmitHandler<FormRecoveryPassword> = async (data) => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });

        if (tokenURL) {
            const response = await changePassword({
                token: tokenURL,
                password: data.password,
            });

            if (!response?.error) {
                snackRef.current?.show();
                setResponseData((prev) => prev = {
                    ...prev,
                    type: response?.response.type,
                    message: response?.response.message,
                    isLoading: false,
                });

                setTimeout(() => navigate('/login'), 1500);
            } else {
                snackRef.current?.show();
                setResponseData((prev) => prev = {
                    ...prev,
                    type: response?.response.type,
                    message: response?.response
                        .message ? response?.response
                            .message : response?.response
                            .errors.errors[0].msg,
                    isLoading: false,
                });
            }
        }
    };

    return (
        <Container
            maxWidth={'xs'}
            sx={{ ...css.sxContainer }}>
            <Box sx={{ ...css.sxBoxAccess }}>
                <Typography
                    variant='h2'
                    textAlign={'center'}
                    sx={{ ...css.sxAccessText }}
                >
                    Recuperar senha
                </Typography>
                <Typography
                    variant='caption'
                    textAlign={'center'}
                    sx={{ ...css.sxTypeText }}
                >
                    Digite sua nova senha.
                </Typography>
            </Box>
            <Form
                onSubmit={handleSubmit(onSubmit)}>

                <TextField
                    focused={true}
                    label={'Nova senha'}
                    type={showPassword ? 'text' : 'password'}
                    id="input-with-icon-textfield"
                    error={!!errors.password}
                    helperText={errors.password ? errors.password.message : ''}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)}>
                                    <I icon={[
                                        'fas', showPassword ?
                                            'eye-slash' :
                                            'eye']} fontSize={18}
                                    color={palette.dark} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{ paddingRight: 0 }}
                    {...register('password', { required: true })} />

                <TextField
                    focused={true}
                    label={'Repita sua senha'}
                    type={showPassword ? 'text' : 'password'}
                    id="input-with-icon-textfield"
                    error={!!errors.retype_password}
                    helperText={errors.retype_password ?
                        errors.retype_password.message :
                        ''}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)}>
                                    <I icon={[
                                        'fas', showPassword ?
                                            'eye-slash' :
                                            'eye']} fontSize={18}
                                    color={palette.dark} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    sx={{ paddingRight: 0 }}
                    {...register('retype_password', { required: true })} />

                <Box
                    display='flex'
                    justifyContent='center'
                    mt={2}>
                    <Box
                        maxWidth={320}
                        width='100%'
                        justifyContent='center'>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            type='submit'
                            className='MuiButton secondary'
                        >
                            Enviar {responseData.isLoading && (
                                <CircularProgress
                                    size={14}
                                    color='inherit'
                                    sx={{ ml: 0.5 }} />)}
                        </Button>
                    </Box>
                </Box>
            </Form>
            <Snackbar
                ref={snackRef}
                message={responseData.message}
                severity={responseData.type}
            />
        </Container>
    );
};

export { RecoveryPasswordForm };
