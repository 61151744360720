import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxUpdatePassButton: {
            'color': palette.dark,
            'fontWeight': typography.fontWeightBold,
            'textTransform': 'capitalize',
            ':hover': {
                backgroundColor: 'transparent',
            },
        },
        sxBtRegion: {
            'fontWeight': typography.fontWeightBold,
            'fontSize': '0.875rem',
            'color': palette.dark,
            ':hover': {
                backgroundColor: palette.common.white,
            },
            'padding': '0.625rem 1.25rem',
            'justifyContent': 'start',
        },
        sxStackRegion: {
            top: 10,
            border: `0.0625rem solid ${palette.clouds}`,
            borderRadius: '0.1875rem',
            boxShadow: '0rem 0.1875rem 0.375rem #00000010',
        },
        sxButtonRegionSelected: {
            justifyContent: 'center',
            borderRight: '1px solid #c4c4c4',
            marginRight: '0.375rem',
            height: '2.25rem',
        },
    };

    return { css };
};
