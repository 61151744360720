import { useMediaQuery } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            padding: matches ? '1.875rem 0' : '0',
            position: 'relative',
            maxWidth: matches ? '35rem' : '100%',
        },
    };
    return {
        ...css,
    };
};
