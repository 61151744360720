/* eslint-disable max-len */
import React from 'react';
import { ToggleStaticProps } from '@interfaces';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { MuiDialog, TopicsAction } from '@components';
import { FooterData } from './data';
import { Container, Box, Typography, useTheme } from '@mui/material';
import MariazLogo from '@img/mariaz-logo.png';

import { sx } from './sx';

const FooterDesktop: React.FC = () => {
    const { typography } = useTheme();
    const { css } = sx();
    const dialogManifest = React.useRef<ToggleStaticProps>(null);

    return (
        <Container maxWidth='lg'>
            <Box sx={{ ...css.sxBoxItems }}>
                <Box sx={{ ...css.sxBoxTopics }}>
                    <TopicsAction
                        key={FooterData[0].section}
                        section={FooterData[0].section}
                        items={[
                            {
                                title: 'Quem somos',
                                link: '/quem-somos',
                            },
                            {
                                title: 'Manifesto',
                                action: () => {
                                    dialogManifest.current?.show();
                                },
                            },
                            ...FooterData[0].items]} />
                    <TopicsAction
                        key={FooterData[1].section}
                        section={FooterData[1].section}
                        items={FooterData[1].items} />
                </Box>
                <a
                    style={{ marginRight: '5.625rem' }}
                    href="https://www.instagram.com/mariaz.com.br"
                    target="_blank">
                    <I
                        icon={['fab', 'instagram-square']}
                        style={{ ...css.sxIcon }}/>
                </a>
            </Box>
            <Box>
                <Typography
                    variant='body1'
                    fontSize={'0.8125rem'}
                    fontWeight={typography.fontWeightMedium}
                    sx={{ ...css.sxInfo }}>
                    Mariaz Ltda.
                </Typography>
            </Box>
            <Box sx={{ ...css.sxBoxContentFooter }}>
                <Box sx={{ ...css.sxBoxImage }}>
                    <img src={MariazLogo} alt=""
                        style={{ ...css.sxImage }} />
                </Box>
                <Box>
                    <Typography
                        variant='body1'
                        fontSize={'0.8125rem'}
                        fontWeight={typography.fontWeightMedium}>
                            Mariaz © 2023 - todos os direitos reservados.
                    </Typography>
                    <Typography
                        variant='body1'
                        fontSize={'0.8125rem'}
                        fontWeight={typography.fontWeightMedium}>
                            Av. Nove de Julho, 5966, Jardim Paulista,
                            São Paulo/SP CEP 01406-902 CNPJ: 50.346.464/0001-22
                    </Typography>
                </Box>
            </Box>
            <MuiDialog
                maxWidth='xs'
                ref={dialogManifest}
                contentPadding
                title='Manifesto'
                showDialogTitle>
                <Box>
                    <iframe width="100%" height="560" src="https://player.vimeo.com/video/848735384" title="Manifesto Mariaz" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                </Box>
            </MuiDialog>
        </Container>
    );
};

export { FooterDesktop };
