import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        navigation: {
            'border': '1px solid ' + palette.clouds,
            'padding': '5px 0',
            '& > .menu-item': {
                '&:last-child': {
                    'borderBottom': 0,
                },
            },
        },
        avatar: {
            avatarSize: (size: number) => {
                return {
                    width: size,
                    height: size,
                    border: '.125rem solid ' +
                        palette.common.white,
                };
            },
            group: {
                marginTop: '-28px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
            buttonUploadImageProfile: {
                backgroundColor: '#f1f1f1',
                border: '2px solid ' + palette.common.white,
                position: 'absolute',
                top: -4,
                right: -4,
                zIndex: 1,
            },
            typography: {
                group: (matches: boolean) => {
                    return {
                        marginTop: matches ? '-2.125rem' : 0,
                        marginLeft: matches ? '5rem' : 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: !matches ? 'center' : 'start',
                    };
                },
            },
        },
    };

    return { css };
};
