import React from 'react';
import { Badge, IconButton } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconBadgeProps } from '@interfaces';
import { sx } from './sx';

const IconBadge: React.FC<IconBadgeProps> = ({
    icon, badgeContent, marginRight, onClick,
}: IconBadgeProps) => {
    const { sxIconButton, sxBadge, sxIcon } = sx();
    return (
        <IconButton onClick={onClick}
            sx={{
                marginRight: marginRight ? marginRight : 0,
                ...sxIconButton,
            }}>
            <Badge badgeContent={badgeContent} sx={{ ...sxBadge }}>
                <I
                    icon={icon}
                    style={{ ...sxIcon }}/>
            </Badge>
        </IconButton>
    );
};

export { IconBadge };
