import { Box, Stack, Typography, useTheme, alpha } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { Points, Price } from '@components';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type ExtractStatusType = 'pending' | 'processing' | 'accepted' | 'rejected';

interface WalletExtractItemProps {
    title: string;
    status: ExtractStatusType;
    date: string;
    amount: string;
    type: 'money' | 'points';
}

const WalletExtractItem = ({
    title,
    status,
    date,
    amount,
    type,
}: WalletExtractItemProps) => {
    const { typography, palette } = useTheme();
    const { pxToRem } = typography;

    const { css } = sx();

    const statusObj = {
        pending: {
            iconColors: {
                status: palette.status.pending,
                mark: palette.status.pending,
            },
            title: 'Pendente',
        },
        processing: {
            iconColors: {
                status: palette.status.processing,
                mark: palette.status.processing,
            },
            title: 'Processando',
        },
        accepted: {
            iconColors: {
                status: palette.status.accepted,
                mark: palette.status.accepted,
            },
            title: 'Concluído',
        },
        rejected: {
            iconColors: {
                status: palette.status.rejected,
                mark: palette.status.rejected,
            },
            title: 'Cancelado',
        },
    };

    const typeObj = {
        money: {
            icon: ['far', 'building-columns'],
            value: <Price size='tiny' value={amount} />,
        },
        points: {
            icon: ['far', 'coins'],
            value: <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                }}>
                <Box mr={pxToRem(4)}>
                    <I
                        icon={['far', 'coins']}
                        color={palette.americanRiver}
                        fontSize={16}
                    />
                </Box>
                <Points size='tiny' value={amount} />
            </Box>,
        },
    };

    return (
        <Box
            sx={css.wrapper}
            className='history-item'
            onClick={() => { }}>
            <Box display='flex'>
                <Box
                    sx={css.icon.main}>
                    <Box sx={css.icon.status(statusObj[status].iconColors.status)}>
                        <I
                            icon={['fas', 'check']}
                            fontSize={10}
                            color={palette.common.white}
                        />
                    </Box>
                    <I
                        icon={typeObj[type].icon as IconProp}
                        fontSize={20}
                        color={alpha(palette.dark, 0.5)}
                    />
                </Box>
                <Box>
                    <Typography
                        variant='h2'
                        component='h2'
                        fontSize={pxToRem(16)}
                        fontWeight={typography.fontWeightBold}
                        mb={pxToRem(10)}
                    >
                        {title}
                    </Typography>
                    <Stack direction='row'>
                        <Box display='flex' alignItems='center'>
                            <Box
                                sx={{
                                    position: 'relative',
                                    top: pxToRem(1),
                                }}>
                                <I
                                    icon={['fas', 'bookmark']}
                                    color={statusObj[status].iconColors.mark}
                                    fontSize={14}
                                />
                            </Box>
                            <Typography
                                variant='h2'
                                component='h2'
                                fontSize={pxToRem(12)}
                                fontWeight={typography.fontWeightMedium}
                                color={palette.dark}
                                ml={pxToRem(4)}
                            >
                                {statusObj[status].title}
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' ml={pxToRem(15)}>
                            <Box
                                sx={{
                                    position: 'relative',
                                    top: pxToRem(1),
                                }}>
                                <I
                                    icon={['far', 'calendar-star']}
                                    color={palette.americanRiver}
                                    fontSize={14}
                                />
                            </Box>
                            <Typography
                                variant='h2'
                                component='h2'
                                fontSize={pxToRem(12)}
                                fontWeight={typography.fontWeightMedium}
                                color={palette.dark}
                                ml={pxToRem(4)}
                            >
                                {date}
                            </Typography>
                        </Box>
                        <Box
                            display='flex'
                            alignItems='center'
                            ml={pxToRem(15)}>
                            {typeObj[type].value}
                            {/* <Price size='tiny' value={amount} /> */}
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <Box sx={{
                width: pxToRem(50),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <I
                    icon={['fas', 'ellipsis-v']}
                    fontSize={16}
                    color={palette.common.black}
                />
            </Box>
        </Box>
    );
};

export { WalletExtractItem };
