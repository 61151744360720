import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
    Box,
    useTheme,
    IconButton,
    TextField,
    InputAdornment,
    Stack,
    Typography,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { FixedMenu } from '@components';

import { FormSearch } from '@interfaces';
import { formSchema } from './yup';
import { FilterButton } from './components/filter-button';
import { Form } from './styles';
import { sx } from './sx';

const placeholderInput = {
    products: 'Busque por “Colares”',
    brands: 'Busque por uma marca',
};

const SearchMobile: React.FC = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();
    const { css } = sx();

    const [flagValue, setFlagValue] = React.useState<'products'|'brands'>('products');
    const [searchInput, setSearchInput] = React.useState('');
    const { register, handleSubmit, setValue } = useForm<FormSearch>({
        mode: 'onBlur',
        resolver: yupResolver(formSchema),
    });

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        if (target) {
            setValue('key', target.value);
            setSearchInput((prev) => prev = target.value);
        }
    };


    const onSubmit: SubmitHandler<FormSearch> = async (data) => {
        if (data.key.length >= 1) {
            if (flagValue === 'brands') {
                navigate({
                    pathname: '/resultados-marcas',
                    search: `?key=${data.key}`,
                });
            } else {
                navigate({
                    pathname: '/resultados',
                    search: `?key=${data.key}`,
                });
            }
        }
    };


    return (
        <Box sx={{ ...css.sxBox }}>
            <Box sx={{
                ...css.sxBoxHeader,
            }}>
                <Box>
                    <IconButton
                        onClick={() => navigate(-1)}
                        sx={{ ...css.sxIconButton }}>
                        <I
                            color={palette.dark}
                            style={{ ...css.sxIcon }}
                            icon={['far', 'chevron-left']} />
                    </IconButton>
                </Box>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Box sx={{
                        ...css.sxBoxInput,
                    }}>
                        <TextField
                            value={searchInput}
                            focused={true}
                            placeholder={placeholderInput[flagValue]}
                            id="input-with-icon-textfield"
                            variant="outlined"
                            sx={{ ...css.sxInputSearch }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {searchInput.length > 0 &&
                                            <IconButton
                                                onClick={() => {
                                                    setSearchInput('');
                                                }}
                                                sx={{ ...css.sxButtonIconClear }}>
                                                <I
                                                    icon={['far', 'xmark']}
                                                    style={{ ...css.sxIconClear }}/>
                                            </IconButton>
                                        }
                                    </InputAdornment>
                                ),
                            }}
                            {...register('key',
                                { required: true },
                            )}
                            onChange={handleSearch}/>
                    </Box>
                    <Box sx={{ ...css.sxBoxContent }}>
                        <IconButton
                            type='submit'
                            sx={{ ...css.sxIconSearch }}>
                            <I
                                color={palette.dark}
                                style={{ ...css.sxIcon }}
                                icon={['far', 'magnifying-glass']} />
                        </IconButton>
                    </Box>
                </Form>
            </Box>
            <Box sx={{ ...css.sxBoxFilter }}>
                <Typography
                    sx={{ ...css.sxFilterTitle }}>
                    Filtrar por
                </Typography>
                <Stack
                    sx={{ pt: 1 }}
                    direction={'row'}>
                    <FilterButton
                        position='left'
                        onClick={() => {
                            setFlagValue('products');
                        }}
                        id={'produto'} filter={'Produtos'}>
                        <input
                            defaultChecked
                            id={'produto'}
                            value={'produtos'}
                            name='filter'
                            type='radio' />
                    </FilterButton>
                    <FilterButton
                        position='right'
                        onClick={() => {
                            setFlagValue('brands');
                        }}
                        id={'brands'} filter={'Marcas'}>
                        <input
                            id={'brands'}
                            value={'brands'}
                            name='filter'
                            type='radio' />
                    </FilterButton>
                </Stack>
            </Box>
            <FixedMenu />
        </Box>
    );
};

export { SearchMobile };
