const imagePathToFile = (image: string) => {
    return (fetch(image)
        .then(function(res) {
            return res.arrayBuffer();
        })
        .then(function(buf) {
            return new File([buf], image, { type: 'image/*' });
        }).catch((err) => { })
    );
};

export { imagePathToFile };
