import * as Yup from 'yup';

export const formSchema = () => {
    return Yup.object().shape({
        name: Yup.string().required('Nome completo é um campo obrigatório'),
        phone: Yup.string(),
        email: Yup.string().email('E-Mail inválido').required(
            'E-mail é um campo obrigatório'),
        cpf: Yup.string().required('CPF é um campo obrigatório')
            .length(14, 'O CPF deve conter 11 dígitos'),
        password: Yup.string()
            .required('Senha é um campo obrigatório')
            .min(8, 'A Senha precisa conter pelo menos 8 dígitos'),
        retype_password: Yup.string()
            .required('Repita a senha digitada')
            .oneOf([Yup.ref('password')], 'As senhas não são iguais'),
        terms: Yup.boolean().oneOf([true], 'Você precisa aceitar os termos de uso'),
    });
};
