/* eslint-disable max-len */
import React from 'react';
import {
    AvatarGroup,
    BoxContent,
    HeaderPage,
    Page,
    Price,
    Product,
    SmallTableColumn,
} from '@components';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { sx } from './sx';
import { SalesProps } from '@interfaces/Sales';
import { useSales } from '../../../../hooks/shopping/sales';
import { formatNumberToPriceBRL } from '../../../../utils/formatNumberToPriceBRL';
import { images } from '@utils';

const DEFAULTS = {
    SALE: {
        isLoaded: false,
        data: {} as SalesProps,
    },
};

const Details = () => {
    const { sales } = useSales();
    const { palette } = useTheme();
    const { css } = sx();
    const params = useParams();

    const navigate = useNavigate();

    const [saleItem, setSaleItem] = React.useState(DEFAULTS.SALE);

    const findSale = async () => {
        const response = await sales.load();
        const find = response?.
            response.
            payload.find((item: SalesProps) => item._id === params.id);

        if (find && !(response?.error)) {
            setSaleItem((prev) => prev = {
                data: find,
                isLoaded: true,
            });
        } else {
            setSaleItem((prev) => prev = {
                data: {} as SalesProps,
                isLoaded: false,
            });
        }
    };

    React.useEffect(() => {
        findSale();
    }, []);

    return (
        <React.Fragment>
            <Page.Wrapper border position='fixed'>
                <HeaderPage
                    titleAlign={'default'}
                    icon={['far', 'chevron-left']}
                    title={'Minhas Vendas'}
                    onClick={() => navigate('/minhas-vendas')} />
                <Page.Content>
                    {saleItem.isLoaded ? (
                        Object.keys(saleItem).length > 0 &&
                        <>
                            <Box sx={css.row}>
                                <SmallTableColumn
                                    title='Comprador'
                                    value={
                                        <AvatarGroup
                                            align='right'
                                            borderColor={palette.common.white}
                                            size='medium'
                                            src={
                                                images.load(
                                                    saleItem.data.comprador
                                                        .imagens?.avatar as string,
                                                    'server')}
                                            title={saleItem.data.comprador.nome} />
                                    } />

                            </Box>
                            <Box sx={css.row}>
                                <SmallTableColumn
                                    title='Número do pedido'
                                    value={saleItem.data._id} />
                            </Box>
                            <Box sx={css.row}>
                                <SmallTableColumn
                                    title='Total parcial'
                                    value={
                                        <Price
                                            size='small'
                                            value={formatNumberToPriceBRL(saleItem.data.total_parcial)} />} />
                            </Box>
                            <Box sx={css.row}>
                                <SmallTableColumn
                                    title='Total da compra'
                                    value={
                                        <Price
                                            size='small'
                                            value={formatNumberToPriceBRL(saleItem.data.total)} />
                                    } />
                            </Box>
                            <Box sx={{
                                ...css.row,
                                pb: 0,
                                borderBottom: 0,
                            }}>
                                <SmallTableColumn
                                    title='Produtos'
                                    value={
                                        <Box>
                                            {saleItem.data.itens.map((item, i, list) => (
                                                <BoxContent
                                                    key={item._id}
                                                    borderBottom={i < list.length - 1}
                                                    actions={[
                                                        {
                                                            actionType: 'goTo',
                                                            actionFunction:
                                                            () => {
                                                                navigate('/produto/' + item.id.id_anuncio._id + '?variantion=' + item.id._id);
                                                            },
                                                        }]} >
                                                    <Product
                                                        _id={item.id._id}
                                                        to={() => navigate('/produto/' + item.id.id_anuncio._id + '?variantion=' + item.id._id)}
                                                        size={'small'}
                                                        align={'right'}
                                                        contentAlign='center'
                                                        // eslint-disable-next-line max-len
                                                        src={images.load(item.id.fotos[0], 'server')}
                                                        title={item.id.id_anuncio ? item.id.id_anuncio.titulo && item.id.id_anuncio.titulo : item.id.titulo}
                                                        legend={`Quantidade: ${item.quantidade}`}
                                                    />
                                                </BoxContent>
                                            ))}
                                        </Box>
                                    } />
                            </Box>
                        </>
                    ) : <Box sx={{
                        p: 3,
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <CircularProgress color='inherit' size={24} />
                    </Box>}
                </Page.Content>
            </Page.Wrapper>
        </React.Fragment>
    );
};
export { Details };
