/* eslint-disable max-len */
import React from 'react';
import {
    BoxContent,
    FixedMenu,
    HeaderPage,
    MessageBox,
    MuiDialog,
    Page,
    Product,
    ProductStatus,
    SmallTableColumn,
    Snackbar,
    TabPanel,
} from '@components';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Divider,
    Tab,
    Tabs,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ToggleStaticProps } from '@interfaces';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { TagDetails } from '../tag/details';
import { TagCreate } from '../tag/create';
import { useSales } from '../../../../hooks/shopping/sales';
import { formatDate, images } from '@utils';


function a11yProps(index: number, id: '') {
    return {
        'id': id,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

interface TagInfoProps {
    action: 'VIEW' | 'GENERATE';
    idOrder: string;
}

const List = () => {
    const { typography } = useTheme();
    const { css } = sx();
    const { sales } = useSales();

    const matches = useMediaQuery('(min-width:600px)');

    const snackRef = React.useRef<ToggleStaticProps>(null);
    const dialogTagDetails = React.useRef<ToggleStaticProps>(null);
    const navigate = useNavigate();

    const [tab, setTab] = React.useState(0);
    const [tagInfo, setTagInfo] = React.useState<TagInfoProps>({
        action: 'GENERATE',
        idOrder: '',
    });

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    React.useEffect(() => {
        sales.load();
    }, []);

    return (
        <Page.Wrapper border position='relative'>
            <Box>
                <HeaderPage
                    onClick={() => navigate('/minhas-compras')}
                    titleAlign={'default'}
                    title={'Minhas Vendas'}
                    icon={['far', 'store']}
                />

                <Container
                    maxWidth='lg'
                    className={'no-padding'}>
                    <Tabs
                        value={tab}
                        onChange={handleChange}>
                        <Tab
                            label="Pendentes"
                            {...a11yProps(0, '')} />
                        <Tab
                            label="Concluídas"
                            {...a11yProps(1, '')} />
                    </Tabs>
                    <Divider light />
                    <TabPanel value={tab} index={0}>
                        <Box>
                            {sales.list.isLoaded &&
                                sales.list.data.map((data) => {
                                    const verify = ((data.status_atual === 'PAGAMENTO_NAO_APROVADO') ||
                                        (data.status_atual === 'EXPIRADO') ||
                                        (data.status_atual === 'CANCELADO') ||
                                        (data.status_atual === 'FINALIZADO'));
                                    if (verify) return;
                                    return (
                                        <BoxContent
                                            key={data._id}
                                            borderBottom
                                            paddingLeft
                                            actions={[
                                                {
                                                    actionType: 'goTo',
                                                    actionFunction:
                                                        () => navigate(`/minhas-vendas/detalhes/${data._id}`),
                                                }]} >
                                            <Box paddingBottom={typography.pxToRem(10)}>
                                                {data.itens.map((item, i, list) => (
                                                    <Box key={item._id} mb={i + 1 === list.length ? 0 : 1}>
                                                        <Product
                                                            size={matches ? 'medium' : 'small'}
                                                            align={'right'}
                                                            contentAlign='center'
                                                            src={images.load(item.id.fotos[0], 'server')}
                                                            title={item.id.id_anuncio ? item.id.id_anuncio.titulo && item.id.id_anuncio.titulo : item.id.titulo}
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                            <SmallTableColumn
                                                title='Data da venda'
                                                value={formatDate(data.data_inclusao)} />
                                            {data.status_atual === 'PAGO' && !(data.historico_status.find((item) => item.status === 'ETIQUETA_GERADA')) ?
                                                <>
                                                    <Typography
                                                        variant='h2'
                                                        component='p'
                                                        textTransform='uppercase'
                                                        sx={css.texts.actions}>
                                                        Ações
                                                    </Typography>
                                                    <SmallTableColumn
                                                        title='Etiqueta'
                                                        value={
                                                            <Button
                                                                variant="contained"
                                                                size='small'
                                                                className='MuiButton secondary'
                                                                onClick={() => {
                                                                    setTagInfo({
                                                                        action: 'GENERATE',
                                                                        idOrder: data._id,
                                                                    });
                                                                    dialogTagDetails.current?.show();
                                                                }}>
                                                                Gerar etiqueta
                                                                <I
                                                                    icon={['far', 'eye']}
                                                                    fontSize={14}
                                                                    style={{ marginLeft: typography.pxToRem(4) }}
                                                                />
                                                            </Button>
                                                        } />
                                                </> :
                                                (data.historico_status.find((item) => item.status === 'ETIQUETA_GERADA')) &&
                                                <>
                                                    <Typography
                                                        variant='h2'
                                                        component='p'
                                                        textTransform='uppercase'
                                                        sx={css.texts.actions}>
                                                        Ações
                                                    </Typography>
                                                    <SmallTableColumn
                                                        title='Etiqueta'
                                                        value={
                                                            <Button
                                                                variant="contained"
                                                                size='small'
                                                                className='MuiButton secondary'
                                                                onClick={() => {
                                                                    setTagInfo({
                                                                        action: 'VIEW',
                                                                        idOrder: data._id,
                                                                    });
                                                                    dialogTagDetails.current?.show();
                                                                }}>
                                                                Visualizar etiqueta
                                                                <I
                                                                    icon={['far', 'eye']}
                                                                    fontSize={14}
                                                                    style={{ marginLeft: typography.pxToRem(4) }}
                                                                />
                                                            </Button>
                                                        } />
                                                </>
                                            }
                                            <Box pt={2} />
                                            <Box sx={css.status}>
                                                <ProductStatus status={data.status_atual} />
                                            </Box>
                                        </BoxContent>);
                                })}
                        </Box>
                        {sales.list.isLoaded ?
                            sales.list.data.length > 0 ?
                                sales.list.data.filter((item) => {
                                    // This condition returns false.
                                    return !((item.status_atual === 'PAGAMENTO_NAO_APROVADO') ||
                                        (item.status_atual === 'EXPIRADO') ||
                                        (item.status_atual === 'CANCELADO') ||
                                        (item.status_atual === 'FINALIZADO'));
                                }).length === 0 &&
                                <MessageBox
                                    message={'Nenhuma venda pendente'}
                                    type={'info'}
                                /> : <MessageBox
                                    message={'Você ainda não realizou nenhuma venda'}
                                    type={'info'} /> :
                            <Box sx={{
                                p: 3,
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <CircularProgress color='inherit' size={24} />
                            </Box>}
                    </TabPanel>
                    <TabPanel value={tab} index={1}>
                        <Box>
                            {sales.list.isLoaded &&
                                sales.list.data.map((data) => {
                                    const verify = ((data.status_atual === 'AGUARDANDO_PAGAMENTO') ||
                                        (data.status_atual === 'ETIQUETA_GERADA') ||
                                        (data.status_atual === 'POSTADO') ||
                                        (data.status_atual === 'PAGO'));
                                    if (verify) return;
                                    return (
                                        <BoxContent
                                            key={data._id}
                                            borderBottom
                                            paddingLeft
                                            actions={[
                                                {
                                                    actionType: 'goTo',
                                                    actionFunction:
                                                        () => navigate(`/minhas-vendas/detalhes/${data._id}`),
                                                }]} >
                                            <Box paddingBottom={typography.pxToRem(10)}>
                                                {data.itens.map((item, i, list) => (
                                                    <Box key={item._id} mb={i + 1 === list.length ? 0 : 1}>
                                                        <Product
                                                            size={matches ? 'medium' : 'small'}
                                                            align={'right'}
                                                            contentAlign='center'
                                                            src={images.load(item.id.fotos[0], 'server')}
                                                            title={item.id.id_anuncio ? item.id.id_anuncio.titulo && item.id.id_anuncio.titulo : item.id.titulo}
                                                        />
                                                    </Box>
                                                ))}
                                            </Box>
                                            <SmallTableColumn
                                                title='Data da venda'
                                                value={formatDate(data.data_inclusao)} />
                                            {/* <Typography
                                                variant='h2'
                                                component='p'
                                                textTransform='uppercase'
                                                sx={css.texts.actions}>
                                                Ações
                                            </Typography>
                                            <SmallTableColumn
                                                title='Etiqueta'
                                                value={
                                                    <Button
                                                        variant="contained"
                                                        size='small'
                                                        className='MuiButton secondary'
                                                        onClick={() => dialogTagCreate.current?.show()}>
                                                        Visualizar etiqueta
                                                        <I
                                                            icon={['far', 'eye']}
                                                            fontSize={14}
                                                            style={{ marginLeft: typography.pxToRem(4) }}
                                                        />
                                                    </Button>
                                                } /> */}
                                            <Box pt={2} />
                                            <Box sx={css.status}>
                                                <ProductStatus status={data.status_atual} />
                                            </Box>
                                        </BoxContent>
                                    );
                                })}
                        </Box>
                        {sales.list.isLoaded ?
                            sales.list.data.length > 0 ?
                                sales.list.data.filter((item) => {
                                    // This condition returns false.
                                    return !((item.status_atual === 'AGUARDANDO_PAGAMENTO') ||
                                        (item.status_atual === 'ETIQUETA_GERADA') ||
                                        (item.status_atual === 'POSTADO') ||
                                        (item.status_atual === 'PAGO'));
                                }).length === 0 && <MessageBox
                                    message={'Nenhuma venda marcada como concluída'}
                                    type={'info'}
                                /> : <MessageBox
                                    message={'Você ainda não realizou nenhuma venda'}
                                    type={'info'} /> :
                            <Box sx={{
                                p: 3,
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <CircularProgress color='inherit' size={24} />
                            </Box>}
                    </TabPanel>
                </Container>
            </Box>
            <MuiDialog
                maxWidth={'sm'}
                ref={dialogTagDetails}
                contentPadding={false}
                title='Aqui está sua etiqueta do pedido!'
                showDialogTitle
                onCancel={{
                    action: () => {
                        dialogTagDetails.current?.hide();
                        sales.load();
                    },
                }}
                fullScreen={!matches}>
                {tagInfo.action === 'VIEW' ?
                    <TagDetails idOrder={tagInfo.idOrder} /> :
                    <TagCreate idOrder={tagInfo.idOrder} />
                }
            </MuiDialog>
            {/* <MuiDialog
                maxWidth='xs'
                ref={dialogTagCreate}
                contentPadding={false}
                title='Gerar etiqueta do pedido'
                showDialogTitle
                fullScreen={!matches}>
            </MuiDialog> */}
            <Snackbar
                ref={snackRef}
                message='Item excluído dos favoritos'
                severity='success'
            />
            {!matches &&
                <FixedMenu />
            }
        </Page.Wrapper>
    );
};

export { List };
