import * as Yup from 'yup';

export const formSchemaAdd = Yup.object().shape({
    cc_number: Yup.string().required('Preencha o número do seu cartão'),
    cc_name: Yup.string().required('Preecha o nome do titular do cartão'),
    cc_expires_month: Yup.string().required('Insita o mês de expiração do cartão'),
    cc_expires_year: Yup.string().required('Insira o ano de expiração do cartão'),
    cc_cvv: Yup.string().required('Insira o  segurança do cartão'),
    cc_cpf: Yup.string().required('CPF do titular é obrigatório'),
});

export const formSchemaUpdate = Yup.object().shape({
    cc_name: Yup.string().required('Preecha o nome do titular do cartão'),
    cc_expires_month: Yup.string().required('Insita o mês de expiração do cartão'),
    cc_expires_year: Yup.string().required('Insira o ano de expiração do cartão'),
    cc_cpf: Yup.string().required('CPF do titular é obrigatório'),
});
