import { useMediaQuery, useTheme } from '@mui/material';

const sx = () => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');

    const css = {
        wrapper: {
            backgroundColor: palette.clouds,
            padding: typography.pxToRem(15),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        box: {
            container: {
                height: matches ? '31.25rem' : '100%',
                overflowY: 'hidden',
            },
            content: {
                backgroundColor: palette.nephritis,
                height: typography.pxToRem(67),
                width: typography.pxToRem(67),
                borderRadius: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            title: {
                fontSize: typography.pxToRem(15),
                fontWeight: typography.fontWeightSemiBold,
                color: palette.dark,
                pt: typography.pxToRem(10),
                mb: typography.pxToRem(10),
            },
            loading: {
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            },
        },
        content: {
            title: {
                ...{
                    fontSize: typography.pxToRem(15),
                    fontWeight: typography.fontWeightSemiBold,
                    color: palette.dark,
                    pt: typography.pxToRem(10),
                    mb: typography.pxToRem(10),
                },
                '& a': {
                    color: palette.carminePink,
                    textDecoration: 'none',
                    fontWeight: typography.fontWeightBold,
                },
            },
        },
    };

    return { css };
};

export { sx };
