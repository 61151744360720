import React from 'react';
import { textSize } from '@utils';
import { customSizePts, customMarginSize } from './sx';
import { Typography, Box, useTheme } from '@mui/material';
import { PointsProps } from '@interfaces';

const Points: React.FC<PointsProps> = ({ size, value }: PointsProps) => {
    const { typography } = useTheme();
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'start',
        }}>
            <Typography
                variant='caption'
                fontSize={textSize[size]}
                fontWeight={typography.fontWeightBold}
                sx={{ marginTop: customMarginSize[size] }}>
                {value}
            </Typography>
            <Typography
                sx={{ marginLeft: '0.125rem' }}
                variant='caption'
                fontSize={customSizePts[size]}
                fontWeight={typography.fontWeightBold}>
                pts
            </Typography>
        </Box>
    );
};

export { Points };
