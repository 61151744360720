import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxBox: {
            margin: '0.9375rem',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        sxMessage: {
            color: palette.americanRiver,
            fontWeight: typography.fontWeightMedium,
            fontSize: '1rem',
        },
    };

    return { css };
};
