import { useTheme } from '@mui/material';

const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        radioGroupLabel: {
            ...{
                fontSize: 13,
                fontWeight: typography
                    .fontWeightBold,
                color: palette.dark,
            },
            '&.Mui-focused': {
                color: palette.dark,
            },
        },
    };

    return { css };
};

export { sx };
