import { Box, Button, useTheme, alpha, useMediaQuery } from '@mui/material';

interface FilterFooterProps {
    onClearAction: () => void;
    onSubmitAction: () => void;
    disabledOnSubmit?: boolean;
}

const FilterFooter = ({
    onClearAction,
    onSubmitAction,
    disabledOnSubmit,
}: FilterFooterProps) => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');

    return (
        <Box
            display='flex'
            p={[1, 2]}
            sx={{
                '& .MuiButton-outlined': {
                    m: 1,
                },
                ...{
                    position: 'relative',
                    backgroundColor: palette.common.white,
                    borderTopLeftRadius: 6,
                    borderTopRightRadius: 6,
                    boxShadow: !matches ? '0 -6px 12px 0 ' +
                        alpha(palette.common.black, 0.2) : 'none',
                },
            }}>
            <Button
                variant="outlined"
                fullWidth
                size='small'
                onClick={onClearAction}
                type='button'
                sx={{
                    '&:hover': {
                        backgroundColor: palette.grey[100],
                        border: 0,
                    },
                    ...{
                        borderRadius: 0.5,
                        textTransform: 'capitalize',
                        backgroundColor: palette.grey[100],
                        fontWeight: typography.fontWeightBold,
                        color: palette.dark,
                        border: 0,
                    },
                }}>
                Limpar
            </Button>
            <Button
                variant="outlined"
                disabled={disabledOnSubmit}
                fullWidth
                size='small'
                onClick={onSubmitAction}
                type='submit'
                sx={{
                    '&:hover': {
                        backgroundColor: palette.dark,
                        border: 0,
                    },
                    '&.Mui-disabled': {
                        color: palette.common.white,
                        opacity: 0.7,
                    },
                    ...{
                        borderRadius: 0.5,
                        textTransform: 'capitalize',
                        backgroundColor: palette.dark,
                        fontWeight: typography.fontWeightBold,
                        color: palette.common.white,
                        border: 0,
                    },
                }}>
                Aplicar
            </Button>
        </Box>
    );
};

export { FilterFooter };
