import React from 'react';

import {
    Grid,
    Container,
    Typography,
    Box,
    useMediaQuery,
} from '@mui/material';

import {
    FixedMenu,
    HeaderPage, SearchForm,
} from '@components';

import { Topic } from './components';
import { topics } from '@static/faq';

import { sx } from './sx';

const HelpCenter: React.FC = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx();
    return (
        <Container className={matches ? '' : 'no-padding'} maxWidth='lg'>
            <HeaderPage
                icon={['far', 'circle-question']}
                titleAlign={'default'}
                title={'Central de ajuda'} />
            <Box sx={{ ...css.sxBoxTitleForm }}>
                <Typography
                    sx={{ ...css.sxTitle }}
                    variant={'h1'}>
                    Com qual assunto você quer ajuda?
                </Typography>
                <Box sx={{ ...css.sxBoxForm }}>
                    <SearchForm
                        noFlag
                        noMargin
                        noShadow
                        fromFaq
                        placeholder='Busque por "Entrega"'/>
                </Box>
            </Box>
            <Box sx={{ ...css.sxBoxGrid }}>
                <Grid container spacing={matches ? 2 : 0}>
                    {topics.map((topic) => (
                        <Grid key={topic.url} item md={4} lg={4} sm={6} xs={12}>
                            <Topic
                                keyValue={topic.url}
                                title={topic.topic}
                                legend={topic.description} />
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {!matches && <FixedMenu />}
        </Container>
    );
};

export { HelpCenter };
