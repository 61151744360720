/* eslint-disable max-len */
import React from 'react';
import {
    Box,
    CircularProgress,
    Container,
    Grid,
    Skeleton,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { sx } from './sx';
import {
    AvatarGroup,
    ButtonInterest,
    FixedMenu,
    MessageBox,
    Product,
    ProfileCover,
} from '@components';
import Banner from '@img/user-cover.png';
import UserAvatar from '@img/avatar.png';
// import { filters } from '@filters';
// import {
//     utilVisibilityFilters,
// } from '../../hooks/filters/utils/visibility-filters';
import { generateRandomNumber, images } from '@utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useAds } from '@hooks';
import { StoreDataProps } from '@interfaces';

const Store = () => {
    // const { mobileFilters, desktopFilters } = utilVisibilityFilters();
    const navigate = useNavigate();
    const { palette } = useTheme();
    const params = useParams();
    const ad = useAds();
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx();
    const [loadMore, setLoadMore] = React.useState<boolean>(true);
    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();
    const [storeData, setStoreData] = React.useState<StoreDataProps>();
    const [limit, setLimit] = React.useState(8);
    const [aleatory] = React.useState(() => generateRandomNumber());


    const loadStoreData = async () => {
        const response = await ad.search({
            query: `&storeUrl=${params.storeUrl &&
                params.storeUrl}&aleatory=${aleatory}`,
            searchFor: 'ads',
            limit: limit,
        });

        if (!response?.error) {
            setLoadMore(false);
            setStoreData({
                isLoaded: true,
                data: response?.response.payload,
            });
        } else {
            setLoadMore(false);
            setStoreData({
                data: {} as any,
                isLoaded: true,
            });
            setFeedback({
                message: response?.response
                    .message, type: response?.response.type,
            });
        }
    };

    React.useEffect(() => {
        loadStoreData();
    }, [limit]);

    return (

        <Box>
            {storeData?.isLoaded && storeData.data.minha_loja ?
                <>
                    <Box sx={{ position: 'relative', mb: 2 }}>
                        <ProfileCover src={
                            storeData.data.imagens &&
                                storeData.data.imagens.banner ?
                                images.load(
                                    storeData.data.imagens.banner, 'server') :
                                images.load(Banner, 'local')
                        } />
                        <Box sx={{ mt: matches ? -6 : -3.5 }}>
                            <AvatarGroup
                                src={
                                    storeData.data.imagens &&
                                        storeData.data.imagens.avatar ?
                                        images.load(
                                            storeData.data.imagens.avatar, 'server') :
                                        images.load(UserAvatar, 'local')}
                                align='bottom'
                                size={matches ? 'extraBig' : 'medium'}
                                title={storeData.data.minha_loja.titulo}
                                legend={storeData.data.minha_loja.bio ?
                                    storeData.data.minha_loja.bio : ''}
                                borderWidth='2x'
                                borderColor='white'
                            />
                        </Box>
                    </Box>

                    {/* <Filter.Product filters={{
                        mobile: mobileFilters,
                        desktop: desktopFilters,
                        filtersList: filters.products,
                    }} /> */}

                    <Container maxWidth='lg' sx={css.products.container}>
                        {storeData.data.ad.length > 0 ?
                            <Grid container spacing={2}>
                                {storeData.data.ad.map((ad) => {
                                    return (
                                        <Grid key={ad._id} item md={3} xs={6}>
                                            <Product
                                                key={ad._id}
                                                stockQuantity={ad.variacoes.itens[0].quantidade_estoque}
                                                favorite={ad.isFavorite}
                                                _id={ad.variacoes._id}
                                                _idAd={ad._id}
                                                mode='buy'
                                                size={'fluid'}
                                                align={'bottom'}
                                                src={images.load(ad.variacoes.fotos[0], 'server')}
                                                title={`${ad.titulo}`}
                                                legend={ad.usuario.minha_loja ?
                                                    ad.usuario.minha_loja.titulo :
                                                    ''}
                                                price={String(ad.variacoes.itens[0].valor_por)}
                                                itemVariationId={ad.variacoes.itens[0]._id}
                                                to={() => navigate('/produto/' + ad._id + '?variantion=' + ad.variacoes._id)} />
                                        </Grid>
                                    );
                                })}
                                {loadMore &&
                                    [0, 1, 2, 3].map((i) => (
                                        <Grid key={`${i}`} item md={3} xs={6}>
                                            <Skeleton
                                                variant="rectangular"
                                                width={'100%'}
                                                height={matches ? 278 : 160} />
                                            <Skeleton variant="rectangular"
                                                sx={{ mt: 2 }}
                                                width={'100%'} height={80} />
                                        </Grid>
                                    ))
                                }
                            </Grid> :
                            <MessageBox
                                type='info'
                                // eslint-disable-next-line max-len
                                message='Essa marca ainda não possui produtos.'
                            />
                        }
                    </Container>
                </> :
                feedback &&
                <Box sx={{ ...css.sxBoxMessageBox }}>
                    <MessageBox
                        type={feedback.type as any}
                        message={feedback.message}
                    />
                </Box>
            }
            {!storeData?.isLoaded &&
                <Box sx={{ ...css.sxBoxCircular }}>
                    <CircularProgress size={40} sx={{ ...css.sxCircularProgress }} />
                </Box>
            }
            {storeData && storeData.isLoaded &&
                storeData.data.minha_loja && limit <= storeData?.data.ad.length &&
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '100%',
                }}>
                    <ButtonInterest
                        text={'CARREGAR MAIS'}
                        margin={'0 0 1.875rem 0'}
                        color={palette.common.white}
                        backgroundColor={palette.dark}
                        padding={'0.5rem 1.5625rem'}
                        onClick={() => {
                            setLimit(limit + 8);
                            setLoadMore(true);
                        }} />
                </Box>
            }
            {!matches &&
                <FixedMenu />
            }
        </Box >
    );
};

export { Store };
