
const sx = () => {
    const css = {
        button: {
            padding: 0,
            minWidth: 'auto',
            backgroundColor: 'transparent',
            border: 0,
            cursor: 'pointer',
        },
    };

    return { css };
};

export { sx };
