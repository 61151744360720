import { Box, Button, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { ButtonFilterMobile } from '../../filter/buttons/select-filter/mobile';
import { ButtonResetFilters } from '../../filter/buttons/reset-filters';

type PresentationFilterHeadType = 'clearFilters' | 'insideFilter';

interface FilterHeadProps {
    presentation: PresentationFilterHeadType;
    activeFilterName: string;
    actionClose: () => void;
    actionBackTo: () => void;
    onClearAllFilters: () => void;
}

const FilterHead = ({
    presentation,
    actionClose,
    actionBackTo,
    activeFilterName,
    onClearAllFilters,
}: FilterHeadProps) => {
    const { typography, palette } = useTheme();
    const { css } = sx();
    const viewHead = {
        clearFilters: false,
        insideFilter: true,
    };
    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            sx={{
                backgroundColor: palette.grey[100],
            }}>
            {viewHead[presentation] && (
                <Box
                    display='flex'
                    alignItems='center'
                    minWidth={123}
                    width='auto'
                    onClick={actionBackTo}>
                    <Button
                        variant="outlined"
                        size='large'
                        sx={css.buttonChevronLeft}
                    >
                        <Box>
                            <I
                                icon={['fas', 'chevron-left']}
                                fontSize={16}
                                color={palette.dark}
                            />
                        </Box>
                    </Button>
                    <Typography
                        variant="h2"
                        component='p'
                        fontSize={12}
                        textTransform='uppercase'
                        fontWeight={typography.fontWeightBold}
                        color={palette.dark}
                        textAlign='center'
                        whiteSpace='nowrap'>
                        {activeFilterName}
                    </Typography>
                </Box>
            )}
            <ButtonFilterMobile
                text='Filtros'
                icon={['far', 'sliders']}
                position='left'
                minWidth={123}
                width='auto'
            />
            {!viewHead[presentation] && (
                <ButtonResetFilters
                    onClick={onClearAllFilters}
                    text='Limpar filtros' />
            )}
            <Box
                display='flex'
                alignItems='flex-end'
                justifyContent='flex-end'
                flexDirection='column'
                minWidth={123}>
                <Button
                    variant="outlined"
                    size='large'
                    sx={{
                        ...css.buttonChevronLeft,
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: 10,
                        textTransform: 'capitalize',
                        color: palette.grey[600],
                    }}
                    onClick={actionClose}
                >
                    <Box lineHeight={1}>
                        <I
                            icon={['far', 'times']}
                            fontSize={16}
                            color={palette.dark}
                        />
                    </Box>
                    Fechar
                </Button>
            </Box>
        </Box>
    );
};

export { FilterHead };
