/* eslint-disable max-len */
import React from 'react';
import { AlertColor, Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import { TagProps } from '@interfaces/Sales';
import { useSales } from '../../../../../hooks/shopping/sales';
import { MessageBox } from '@components';

const TagDetails = ({ idOrder }: {idOrder: string}) => {
    const { palette } = useTheme();
    const { css } = sx();
    const { sales } = useSales();

    const [details, setDetails] = React.useState(
        {} as TagProps,
    );
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
    });

    const loadTagDetails = async () => {
        setDetails((prev) => prev = {
            ...prev, isLoaded: false,
        });

        const response = await sales.tag.print({
            idOrder: idOrder,
        });

        if (!response?.error) {
            setDetails({
                isLoaded: true,
                data: response?.response,
            });
        } else {
            setDetails((prev) => prev = {
                ...prev, isLoaded: true,
            });
            setResponseData((prev) => prev = {
                ...prev,
                type: response?.response.type,
                message: response?.response.message,
            });
        }
    };

    React.useEffect(() => {
        loadTagDetails();
    }, []);

    return (
        <Box sx={css.box.container}>
            {details.isLoaded ? !!details.data ?
                <>
                    <Box sx={css.wrapper}>
                        <Box sx={css.box.content}>
                            <I
                                icon={['far', 'check']}
                                fontSize={36}
                                color={palette.common.white}
                            />
                        </Box>
                        <Typography
                            variant='h2'
                            component='p'
                            align='center'
                            sx={css.box.title}>
                    Você pode visualizar e imprimir sua
                    etiqueta clicando no botão imprimir
                    logo abaixo.
                        </Typography>
                    </Box>
                    <Typography
                        variant='h2'
                        component='p'
                        align='center'
                        sx={css.content.title}>
                        Caso você não consiga visualizar a etiqueta
                        abaixo <a href={details.isLoaded && details.data ? details.data.payload : '#!'} target='_blank'>
                            clique aqui
                        </a>
                    </Typography>
                    <Box sx={{
                        height: 'calc(100% - 170px)',
                    }}>
                        <iframe width="100%" height="100%" src={details.data.payload} title="Etiqueta Melhor Envio" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </Box>
                </> :
                <MessageBox
                    message={responseData.message}
                    type={responseData.type} /> :
                <Box sx={css.box.loading}>
                    <CircularProgress color='inherit' size={24} />
                </Box>
            }
        </Box>
    );
};

export { TagDetails };
