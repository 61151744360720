import { AddressBox } from '@components';
import { Box, Button, Container, Typography, useTheme, Breakpoint } from '@mui/material';
import { useNavigate } from 'react-router-dom';
interface CheckoutAddressChangeProps {
    size?: Breakpoint;
    mode?: 'component' | 'page';
    cancelAction?: () => void;
}

const CheckoutAddressChange = ({
    size,
    mode,
    cancelAction,
}: CheckoutAddressChangeProps) => {
    const { palette, typography } = useTheme();

    const rem = (px: number) => typography.pxToRem(px);
    const navigate = useNavigate();

    const box = {
        pt: size !== 'xs' ? 0 : rem(30),
        pb: size !== 'xs' ? 0 : rem(30),
    };

    return (
        <Container maxWidth={size ?? 'xs'}>
            <Box pt={box.pt} pb={box.pt}>
                {mode === 'page' && (
                    <Typography
                        variant="body1"
                        fontWeight={typography.fontWeightBold}
                        color={palette.dark}
                        mb={rem(5)}>
                        Alterar endereço de entrega
                    </Typography>
                )}

                {mode === 'page' && (
                    <Typography
                        variant="subtitle2"
                        fontWeight={typography.fontWeightMedium}
                        color={palette.dark}>
                        Confirme seu endereço de entrega.
                    </Typography>
                )}

                <Box sx={{ mt: 2, mb: 2 }}>
                    <AddressBox
                        mode='list'
                        noIcon
                        radio={{ checked: true }}
                        nickname='Casa'
                        title='CEP: 00.000.000'
                        legend="R Bartolomeu bueno da silva, n359 -
                        Bairro ÁguaVerde, Curitiba - Paraná"
                        onClick={() => { }} />
                </Box>
                <Box sx={{ mt: 2, mb: 2 }}>
                    <AddressBox
                        mode='list'
                        noIcon
                        radio={{ checked: false }}
                        nickname='Casa'
                        title='CEP: 00.000.000'
                        legend="R Bartolomeu bueno da silva, n359 -
                        Bairro ÁguaVerde, Curitiba - Paraná"
                        onClick={() => { }} />
                </Box>
                <Box sx={{ mb: 1 }}>
                    <Button
                        fullWidth
                        variant="contained"
                        size="large"
                        type='submit'
                        className='MuiButton secondary'
                        onClick={() => { }}
                    >
                        Continuar
                    </Button>
                </Box>
                <Button
                    fullWidth
                    variant="text"
                    size="large"
                    type='button'
                    onClick={cancelAction ? cancelAction : () => navigate(-1)}
                    className='MuiButton text'
                >
                    Cancelar
                </Button>
            </Box>
        </Container>
    );
};

export { CheckoutAddressChange };
