import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        share: {
            copyToClipboard: {
                color: palette.dark,
                disabled: {
                    opacity: 0.5,
                },
            },
        },
        sxColorSelected: {
            fontWeight: typography.fontWeightMedium,
            fontSize: '0.875rem',
        },
        sxContainer: {
            paddingTop: '2.5rem',
        },
        sxTitlePost: {
            fontWeight: typography.fontWeightBold,
            fontSize: matches ? '1.75rem' : '1.5rem',
        },
        sxLegendPost: {
            fontWeight: typography.fontWeightMedium,
            fontSize: matches ? '1rem' : '0.875rem',
            mt: 1,
        },
        sxBoxImage: {
            'maxHeight': matches ? '30rem' : '15rem',
            'height': matches ? '30rem' : '15rem',
            'position': 'relative',
            'marginBottom': matches ? '1.875rem' : '1.25rem',
            '& > .sc-iframe': {
                'position': 'relative',
                'width': '100%',
                'height': '100%',

                'iframe': {
                    width: '100%',
                    height: '100%',
                },
            },
        },
        sxSidebarTitle: {
            fontWeight: typography.fontWeightBold,
            fontSize: matches ? '1.375rem' : '1.125rem',
        },
        sxBoxFollow: {
            paddingTop: matches ? '1.875rem' : '1.25rem',
            display: 'flex',
            flexDirection: 'column',
        },
        sxIgLink: {
            fontSize: '1.75rem',
            color: palette.dark,
            mt: 1,
            width: '1.5625rem',
        },
        sxIconButton: {
            color: palette.dark,
            fontSize: '1.125rem',
            display: 'flex',
            flexDirection: 'column',
            minWidth: '2.5rem',
            height: '2.5rem',
            padding: 0,
        },
        sxIconButtonText: {
            paddingTop: '0.1875rem',
            fontWeight: typography.fontWeightRegular,
            fontSize: '0.625rem',
        },
        sxStackButtons: {
            marginTop: matches ? '0' : '1.25rem',
            borderTop: `0.0625rem solid ${palette.clouds}`,
            justifyContent: matches ? 'flex-start' : 'center',
            padding: '0.9375rem 0',
        },
        sxBoxCircular: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 260px)',
        },
        sxCircularProgress: {
            color: palette.dark,
        },
    };

    return { css };
};
