import { RouteObject, Navigate } from 'react-router-dom';
import { stackRoutes as listRoutes } from './list.routes';

const routes = listRoutes.map((route) => ({
    element: route.element,
    path: route.path,
    index: !!route.index,
}));

export const stackRoutes: RouteObject[] = routes;

export const redirectRoute = ({ path }: { path: string }) => {
    return path.includes('membros') ?
        [
            {
                path: window.location.pathname,
                element: <Navigate to={'/criar-conta'} />,
            },
        ] :
        [
            {
                path: window.location.pathname,
                element: <Navigate to={'/login'} />,
            },
        ];
};

export const redirectLoginSuggestion = () => {
    return [
        {
            path: window.location.pathname,
            element: <Navigate to={'/login-suggestion'} />,
        },
    ];
};
