export const sx = () => {
    const css = {
        sxButton: {
            cursor: 'pointer',
            padding: '0.3125rem 0.5625rem',
            borderRadius: '0.1875rem',
            textTransform: 'initial',
            minWidth: '5.4375rem',
        },
        sxIcon: {
            fontSize: '.8125rem',
            marginLeft: '0.375rem',
        },
    };
    return {
        ...css,
    };
};
