import * as api from './calls';

const cart = {
    item: {
        add: api.add,
        remove: api.remove,
    },
    load: api.load,
    createSession: api.createSession,
    calculateShipping: api.calculateShipping,
};

export { cart };
