import * as api from './calls';

const user = {
    loadUser: api.loadUser,
    passwordChange: api.passwordChange,
    updateUser: api.updateUser,
    addAddress: api.addAddress,
    updateAddress: api.updateAddress,
    deleteAddress: api.deleteAddress,
    searchZipCode: api.searchZipCode,
    uploadImage: api.uploadImage,
    favorites: {
        add: api.addFavorite,
        load: api.loadFavorites,
        delete: api.deleteFavorite,
    },
    loadMenu: api.loadMenu,
};

export { user };
