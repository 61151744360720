import * as api from './calls';

const contents = {
    discover: api.discover,
    myContents: api.myContents,
    categories: api.categories,
    details: api.details,
    save: api.save,
};

export { contents };
