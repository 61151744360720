import React from 'react';
import { OthersContext } from './context';
import { othersHookProvider } from '../../hooks/others';

interface OthersProviderProps {
    children: React.ReactNode;
};

const OthersProvider = ({ children }: OthersProviderProps) => {
    const others = othersHookProvider();

    return (
        <OthersContext.Provider value={others}>
            {children}
        </OthersContext.Provider>
    );
};

export {
    OthersProvider,
};
