/* eslint-disable max-len */
import React from 'react';
import { Box, SxProps, Theme, Link as LinkMaterial, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { sx } from './sx';
import { Link } from 'react-router-dom';
import { MuiDialog } from '../../../feedback/dialog';
import { ToggleStaticProps } from '@interfaces';
import { ActionFooterType } from '@types';

interface LinkFooterMobileProps {
    section: string;
    linkSection?: string;
    items?: {
        title: string;
        link?: string;
        action?: ActionFooterType;
    }[]
}

const LinkFooterMobile: React.FC<LinkFooterMobileProps> = ({
    section, linkSection, items,
}: LinkFooterMobileProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const { sxBox, sxBoxExpanded, sxLink, sxLinkExpanded } = sx();
    const dialogManifest = React.useRef<ToggleStaticProps>(null);

    const actionFooter = {
        manifest: () => {
            dialogManifest.current?.show();
        },
    };

    return (
        <Box sx={{ ...sxBox }}>
            <LinkMaterial
                href={linkSection}
                onClick={() => setExpanded(!expanded)}
                variant='body1'
                sx={{ ...sxLink as SxProps<Theme> }}>
                {section}
                {items &&
                    <I
                        icon={expanded ? ['fal', 'minus'] : ['fal', 'plus']} />
                }
            </LinkMaterial>
            {items && expanded &&
                <Box sx={{ ...sxBoxExpanded }}>
                    {items.map(({ link, title, action }) => (
                        (action ?
                            <Box
                                onClick={actionFooter[action]}
                                sx={{ ...sxLinkExpanded }}>
                                <Typography variant='body2'>
                                    {title}
                                </Typography>
                            </Box> :
                            link &&
                            <Link
                                key={title}
                                to={link}
                                style={{ ...sxLinkExpanded, textTransform: 'lowercase' }}>
                                <Typography variant='body2'>
                                    {title}
                                </Typography>
                            </Link>
                        )
                    ))}
                </Box>}
            <MuiDialog
                maxWidth='xs'
                ref={dialogManifest}
                contentPadding
                fullScreen
                title='Manifesto'
                showDialogTitle>
                <iframe width="100%" height="100%" src="https://player.vimeo.com/video/848735384" title="Manifesto Mariaz" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </MuiDialog>
        </Box>
    );
};

export { LinkFooterMobile };
