import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();

    const css = {
        sxBoxFilter: {
            width: '100%',
            padding: '1.25rem 0.9375rem',
            paddingBottom: '0.625rem',
            display: 'flex',
            flexDirection: 'column',
        },
        sxRadioGroup: {
            display: 'flex',
            flexDirection: 'row',
        },
        sxFormControl: {
            'border': `0.0625rem solid ${palette.dark}`,
            'paddingRight': '0.625rem',
            'marginLeft': 0,
            '& > .MuiTypography-root': {
                fontSize: '0.8125rem',
                fontWeight: typography.fontWeightBold,
                textTransform: 'uppercase',
            },
        },
        sxFilterTitle: {
            fontSize: '1rem',
            fontWeight: typography.fontWeightMedium,
            paddingBottom: '0.625rem',
        },
        sxLoadMoreBox: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
        },
        sxBoxCircular: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '12.5rem',
            width: '100%',
        },
        sxCircularProgress: {
            color: '#010101',
        },
    };

    return { css };
};
