import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxIconButton: {
            width: '2.5rem',
            height: '2.5rem',
        },
        sxBadge: {
            '& .MuiBadge-badge': {
                color: palette.common.white,
                backgroundColor: palette.carminePink,
                fontSize: '0.625rem',
                fontWeight: typography.fontWeightBold,
            },
        },
        sxIcon: {
            fontSize: '1.25rem', color: palette.dark,
        },
    };
    return {
        ...css,
    };
};
