/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import {
    HeaderPage,
    ActionSmallButton,
    Page,
    AddressBox,
    MuiDialog,
    Snackbar,
} from '@components';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Container,
    Grid,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';

import { FormAddressProps, ToggleStaticProps, FormAddressAPI, ErrorsProps } from '@interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from './yup';

import $ from 'jquery';
import 'jquery-mask-plugin';
import { useUser, usePaymentMethod } from '@hooks';
import { addressFormat } from '@utils';
import { statesList } from '@static/statesList';
import { Label } from './styles';

interface AddressAddEditProps {
    checkoutMode?: boolean;
}

const AddressAddEdit: React.FC<AddressAddEditProps> = ({ checkoutMode }: AddressAddEditProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { creditCard } = usePaymentMethod();
    const [address] = React.useState<FormAddressAPI | null>(
        location.state && location.state.address ? location.state.address : null);
    const [saveAddress, setSaveAddress] = React.useState<boolean>(false);
    const [addressErrors, setAddressErrors] = React.useState<ErrorsProps>({
        listErrors: {
            error: false,
            location: '',
            msg: '',
            param: '',
            value: '',
        },
    });
    const keys = Object.keys(addressErrors);
    const [snackMessage, setSnackMessage] = React.useState('');
    const [valueAutocomplete, setValueAutocomplete] =
        React.useState<string | null>(address ?
            address.estado : '');
    const [inputValue, setInputValue] = React.useState('');
    const [mainAddress, setMainAddress] = React.useState(false);

    const snackRef = React.useRef<ToggleStaticProps>(null);
    const dialogConfirm = React.useRef<ToggleStaticProps>(null);

    const { handleAddress, deleteAddress, loadUser, searchZipCode } = useUser();
    const { typography, palette } = useTheme();

    const { register, handleSubmit, setValue,
        formState: { errors } } =
        useForm<FormAddressProps>({
            resolver: yupResolver(formSchema),
            mode: 'all',
        });

    const locationSearch = !!location.search.includes('action=payment');

    const handleZipCode = async (zipCode: string) => {
        if (zipCode.length === 9) {
            const response = await searchZipCode({
                zipCode: zipCode.replace('-', ''),
            });

            if (!response.erro) {
                setValue('city', response.localidade);
                setValueAutocomplete(response.uf);
                setValue('state', response.uf);
                setValue('neighborhood', response.bairro);
                setValue('address', response.logradouro);
            };
        }
    };

    const handleDeleteAddress = async () => {
        dialogConfirm.current?.hide();
        const response = await deleteAddress({
            idEndereco: address?._id as string,
        });

        if (response.error) {
            if (response.response.message) {
                setSnackMessage(response.response.message);
                return snackRef.current?.show();
            }
        } else {
            loadUser();
            navigate({
                pathname: '/meus-enderecos/listar',
                search: '?action=del',
            }, {
                state: {
                    feedback: response.response.message ? response.response.message :
                        'Endereço removido com sucesso.',
                },
            });
        }
    };

    const createPaymentMethod = async (data: FormAddressProps, locationSearch: boolean) => {
        const response = await handleAddress({
            action: address && address._id ? 'update' : 'add',
            address: {
                addressId: address && address._id ? address._id : undefined,
                apelido: data.nickname,
                cidade: data.city,
                bairro: data.neighborhood,
                cep: data.cep,
                estado: data.state,
                logradouro: data.address,
                numero: data.number,
                complemento: data.complement as string,
                principal: mainAddress,
            },
        });

        if (response.error === true) {
            if (response.response.message) {
                setSnackMessage(response.response.message);
                return snackRef.current?.show();
            }

            if (response.response.errors && response.response.errors.errors) {
                const filterErrors = response.response.errors.errors.
                    map((err: any, i: number) => {
                        const object = {
                            [err.param]: {
                                location: err.location,
                                msg: err.msg,
                                param: err.param,
                                error: true,
                            },
                        };

                        if (err.param !== keys[i]) {
                            return (object);
                        }

                        return (object);
                    });

                const reduceErrors = filterErrors.reduce(
                    (acc: any, current: any) => ({
                        ...acc,
                        ...current,
                    }),
                    {},
                );
                setAddressErrors((prev) => prev = reduceErrors);
            }
        } else {
            loadUser();
            if (locationSearch) {
                const editMode = location.search.includes('edit');
                navigate('/metodos-de-pagamento/adicionar', {
                    state: {
                        editMode: editMode,
                    },
                });
            } else {
                navigate({
                    pathname: '/meus-enderecos/listar',
                    search: `?action=${address && address._id ? 'edit' : 'add'}`,
                }, {
                    state: {
                        feedback: response.response.message ? response.response.message :
                            'Endereço alterado com sucesso.',
                    },
                });
            }
        };
    };

    const onSubmit: SubmitHandler<FormAddressProps> = async (data) => {
        if (locationSearch) {
            const editMode = location.search.includes('edit');
            creditCard.current.addCCData({
                action: 'credit_card_address',
                creditCard: {
                    token_cartao: '',
                    cardInfos: {
                        ...creditCard.current.data.cardInfos,
                        endereco: {
                            apelido: data.nickname,
                            bairro: data.neighborhood,
                            cep: data.cep,
                            cidade: data.city,
                            complemento: data.complement as string,
                            estado: data.state,
                            logradouro: data.address,
                            numero: data.number,
                            principal: mainAddress,
                        },
                    },
                },
            });

            if (saveAddress) {
                await createPaymentMethod(data, true);
            } else {
                navigate('/metodos-de-pagamento/adicionar', {
                    state: {
                        editMode: editMode,
                    },
                });
            }
        } else {
            await createPaymentMethod(data, false);
        };
    };

    const inputMasks = () => {
        $('.cep').mask('00000-000');
    };

    React.useEffect(() => {
        inputMasks();
        if (address) {
            setMainAddress(address.principal as boolean);
            setValue('state', address.estado);
        }
    }, []);

    return (
        <React.Fragment>
            <Page.Wrapper border={!checkoutMode} position={!checkoutMode ? 'fixed' : 'relative'}>

                {!checkoutMode && (
                    <HeaderPage
                        titleAlign={'default'}
                        icon={['far', 'chevron-left']}
                        title={'Meus endereços'}
                        onClick={() => navigate(
                            locationSearch ? '/metodos-de-pagamento/adicionar' :
                                '/meus-enderecos/listar',
                            {
                                state: {
                                    editMode: location.search.includes('edit'),
                                },
                            })}
                        action={
                            address ?
                                <ActionSmallButton
                                    backgroundColor={palette.dark}
                                    text={'Excluir'}
                                    icon={['far', 'trash-can']}
                                    onClick={() => dialogConfirm.
                                        current?.
                                        show()} /> :
                                null
                        } />
                )}
                <Page.Content paddingBottom>
                    <Container maxWidth='lg' className={checkoutMode ? 'no-padding' : ''}>
                        <Box sx={{ mt: !checkoutMode ? 4 : 1, mb: !checkoutMode ? 4 : 0 }}>
                            {!checkoutMode && (
                                <Typography
                                    variant="h2"
                                    component='p'
                                    mb={4}
                                    fontSize={16}
                                    fontWeight={typography.fontWeightMedium}
                                    color={palette.dark}
                                    textAlign='center'
                                    gutterBottom>
                                    Preencha os campos abaixo com os dados
                                    do endereço.
                                </Typography>
                            )}
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            label={'CEP'}
                                            defaultValue={address ? address.cep : ''}
                                            placeholder='00000-030'
                                            variant="outlined"
                                            helperText={
                                                (addressErrors.hasOwnProperty('cep') &&
                                                    addressErrors.cep.msg) ||
                                                (!!errors.cep?.message &&
                                                    errors.cep.message)}
                                            error={
                                                (addressErrors.hasOwnProperty('cep') &&
                                                    !!addressErrors.cep.error) ||
                                                !!errors.cep}
                                            inputProps={{
                                                className: 'cep',
                                            }}
                                            {...register('cep',
                                                { required: true },
                                            )}
                                            onChange={(e) => handleZipCode(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} mb={1}>
                                        <Autocomplete
                                            disablePortal
                                            fullWidth
                                            options={(statesList).map(
                                                (state) => state.sigla)}
                                            value={valueAutocomplete}
                                            onChange={(event: any,
                                                newValue: string | null) => {
                                                setValueAutocomplete(newValue);
                                            }}
                                            inputValue={inputValue}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    label="Estado"
                                                    helperText={
                                                        (addressErrors.
                                                            hasOwnProperty('estado') &&
                                                            addressErrors.estado.msg) ||
                                                        (!!errors.state?.message &&
                                                            errors.state.message)}
                                                    error={
                                                        (addressErrors.
                                                            hasOwnProperty('estado') &&
                                                            !!addressErrors.estado.error) ||
                                                        !!errors.state}
                                                    {...register('state',
                                                        { required: true },
                                                    )}
                                                />}
                                        />
                                    </Grid>
                                    <Grid item xs={6} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            label={'Cidade'}
                                            defaultValue={address ? address.cidade : ''}
                                            variant="outlined"
                                            helperText={
                                                (addressErrors.hasOwnProperty('cidade') &&
                                                    addressErrors.cidade.msg) ||
                                                (!!errors.city?.message &&
                                                    errors.city.message)}
                                            error={
                                                (addressErrors.hasOwnProperty('cidade') &&
                                                    !!addressErrors.cidade.error) ||
                                                !!errors.city}
                                            {...register('city',
                                                { required: true },
                                            )} />
                                    </Grid>
                                    <Grid item xs={12} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            defaultValue={address ?
                                                address.bairro : ''}
                                            label={'Bairro'}
                                            variant="outlined"
                                            helperText={
                                                (addressErrors.hasOwnProperty('bairro') &&
                                                    addressErrors.bairro.msg) ||
                                                (!!errors.neighborhood?.message &&
                                                    errors.neighborhood.message)}
                                            error={
                                                (addressErrors.hasOwnProperty('bairro') &&
                                                    !!addressErrors.bairro.error) ||
                                                !!errors.neighborhood}
                                            {...register('neighborhood',
                                                { required: true },
                                            )} />
                                    </Grid>
                                    <Grid item xs={8} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            label={'Endereço'}
                                            defaultValue={address ?
                                                address.logradouro : ''}
                                            variant="outlined"
                                            helperText={
                                                (addressErrors.
                                                    hasOwnProperty('logradouro') &&
                                                    addressErrors.logradouro.msg) ||
                                                (!!errors.address?.message &&
                                                    errors.address.message)}
                                            error={
                                                (addressErrors
                                                    .hasOwnProperty('logradouro') &&
                                                    !!addressErrors.logradouro.error) ||
                                                !!errors.address}
                                            {...register('address',
                                                { required: true },
                                            )} />
                                    </Grid>
                                    <Grid item xs={4} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            label={'Número'}
                                            defaultValue={address ? address.numero : ''}
                                            variant="outlined"
                                            helperText={
                                                (addressErrors.hasOwnProperty('numero') &&
                                                    addressErrors.numero.msg) ||
                                                (!!errors.number?.message &&
                                                    errors.number.message)}
                                            error={
                                                (addressErrors.hasOwnProperty('numero') &&
                                                    !!addressErrors.numero.error) ||
                                                !!errors.number}
                                            {...register('number',
                                                { required: true },
                                            )} />
                                    </Grid>
                                    <Grid item xs={12} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            defaultValue={address ?
                                                address.complemento : ''}
                                            label={'Complemento'}
                                            variant="outlined"
                                            {...register('complement',
                                                { required: true },
                                            )} />
                                    </Grid>
                                    <Grid item xs={12} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            label={'Apelido'}
                                            placeholder='Casa, apartamento, trabalho'
                                            defaultValue={address ? address.apelido : ''}
                                            variant="outlined"
                                            helperText={
                                                (addressErrors
                                                    .hasOwnProperty('apelido') &&
                                                    addressErrors.apelido.msg) ||
                                                (!!errors.nickname?.message &&
                                                    errors.nickname.message)}
                                            error={
                                                (addressErrors
                                                    .hasOwnProperty('apelido') &&
                                                    !!addressErrors.apelido.error) ||
                                                !!errors.nickname}
                                            {...register('nickname',
                                                { required: false },
                                            )} />
                                    </Grid>
                                    <Grid item xs={12} mb={1}>
                                        <Stack
                                            alignItems={'start'}
                                            direction={'row'}>
                                            <Checkbox
                                                checked={mainAddress}
                                                onChange={() => setMainAddress(!mainAddress)}
                                                id='main-address'
                                                sx={{
                                                    'color': palette.dark,
                                                    'borderRadius': 0,
                                                    'padding': '0',
                                                    'width': '1.125rem',
                                                    '&.Mui-checked': {
                                                        color: palette.dark,
                                                    },
                                                }} />
                                            <Typography
                                                variant="body1"
                                                color={palette.darkGrey}
                                                ml={1}
                                                onClick={() => setMainAddress(!mainAddress)}
                                                sx={{
                                                    cursor: 'pointer',
                                                    fontSize: '0.875rem',
                                                }}
                                            >
                                                Salvar como endereço principal
                                            </Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                {locationSearch &&
                                    <Stack
                                        mt={1}
                                        alignItems={'start'}
                                        direction={'row'}>
                                        <Checkbox
                                            checked={saveAddress}
                                            onChange={(e) =>
                                                setSaveAddress(e.target.checked)}
                                            id='save-address'
                                            sx={{
                                                'color': palette.dark,
                                                'borderRadius': 0,
                                                'padding': '0',
                                                'width': '1.125rem',
                                                '&.Mui-checked': {
                                                    color: palette.dark,
                                                },
                                            }} />
                                        <Label htmlFor='save-address'>
                                            Salvar endereço em Meus Endereços
                                        </Label>
                                    </Stack>
                                }
                                <Box
                                    display='flex'
                                    alignItems='center'
                                    flexDirection='column'
                                    mt={4}>
                                    <Box
                                        maxWidth={320}
                                        width='100%'
                                        mb={1}
                                        justifyContent='center'>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            size="large"
                                            type='submit'
                                            className='MuiButton secondary'
                                        >
                                            Salvar
                                        </Button>
                                    </Box>
                                    <Box
                                        maxWidth={320}
                                        width='100%'
                                        justifyContent='center'>
                                        <Button
                                            fullWidth
                                            variant="text"
                                            size="large"
                                            type='button'
                                            onClick={() => navigate(-1)}
                                            className='MuiButton text'
                                        >
                                            Cancelar
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Container>
                </Page.Content>
            </Page.Wrapper>
            {address &&
                <MuiDialog
                    maxWidth='xs'
                    ref={dialogConfirm}
                    contentPadding={false}
                    title='Deletar Endereço?'
                    showDialogTitle
                    onConfirm={{
                        action: handleDeleteAddress,
                        text: 'Confirmar',
                    }}>
                    <Box>
                        <AddressBox
                            mode={'list'}
                            nickname={address.apelido}
                            title={`CEP: ${address.cep}`}
                            legend={addressFormat({
                                logradouro: address.logradouro,
                                bairro: address.bairro,
                                cidade: address.cidade,
                                estado: address.estado,
                                numero: Number(address.numero),
                                complemento: address.complemento,
                            })} />
                    </Box>
                </MuiDialog>
            }
            <Snackbar
                ref={snackRef}
                message={snackMessage}
                severity='error'
            />
        </React.Fragment >
    );
};

export { AddressAddEdit };
