import { MenuItem } from '@components';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { weigthList } from './data';
import React from 'react';


interface ChildrenDataProps {
    id: string;
    title: string;
};

interface DataProps {
    id: string;
    title: string;
    children?: ChildrenDataProps[];
};

interface handleWeightListProps {
    list: ChildrenDataProps[];
    currentList: ChildrenDataProps,
}

const WeightInfo = () => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');

    const [data, setData] = React.useState<DataProps[]>(weigthList);
    const [listHistory, setListHistory] = React.useState<ChildrenDataProps[]>([]);

    const handleWeightList = ({ list, currentList }: handleWeightListProps) => {
        setData((prev) => prev = list);
        setListHistory((prev) => prev = [currentList]);
    };

    const resetHistory = () => {
        setData((prev) => prev = weigthList);
        setListHistory((prev) => prev = []);
    };

    return (
        <Box mb={2}>
            <Box sx={{
                padding: '0.9375rem',
            }}>
                <Typography
                    variant="h1"
                    fontSize={16}
                    fontWeight={typography.fontWeightBold}
                    color={palette.dark}
                    mb={1}
                >
                    Calma que vamos te ajudar nessa questão!
                </Typography>
                <Typography
                    variant="h1"
                    fontSize={14}
                    fontWeight={typography.fontWeightRegular}
                    color={palette.dark}
                >
                    Aqui temos a lista de referência de peso de
                    alguns produtos. Esses são pesos médios,
                    então você pode ajustar de acordo com as
                    características da sua peça.<br /><br />
                    Você pode utilizar também uma balança de
                    banheiro para pesar produtos maiores!
                </Typography>
            </Box>
            <Box sx={{
                ...{ border: '1px solid ' + palette.clouds },
                '& .menu-item:last-child > .MuiBox-root': {
                    borderBottom: 0,
                },
            }}>

                {listHistory.length > 0 && listHistory.map(({ id, title }) => (
                    <MenuItem
                        onClick={resetHistory}
                        key={id}
                        borderType={'bottom'}
                        title={{
                            text: title,
                            size: !matches ? 'tiny' : 'medium',
                            fontWeight: 'bold',
                        }}
                        icon={{
                            path: ['far', 'chevron-left'],
                            size: 'small',
                        }}>
                    </MenuItem>
                ))}

                {data.map(({ id, title, children }) => (
                    <MenuItem
                        onClick={!(listHistory.length > 0) ?
                            () => handleWeightList({
                                list: children as ChildrenDataProps[],
                                currentList: {
                                    id: id,
                                    title: title,
                                },
                            }) : () => { }}
                        key={id}
                        borderType={'bottom'}
                        title={{
                            text: title,
                            size: !matches ? 'tiny' : 'medium',
                        }}>
                        {!(listHistory.length > 0) && (
                            <Box pr={2}>
                                <I
                                    icon={['far', 'chevron-right']}
                                />
                            </Box>
                        )}
                    </MenuItem>
                ))}
            </Box>
        </Box>
    );
};

export { WeightInfo };
