import React from 'react';

import {
    Box,
    Typography,
    Stack,
    IconButton,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Image } from './styles';
import { sx } from './sx';

interface PromoctionBoxProps {
    image: string;
    title: string;
    legend: string;
    startDate: string;
    endDate: string;
    details: string;
}

const PromoctionBox: React.FC<PromoctionBoxProps> = ({
    image, title, legend, startDate, endDate, details,
}: PromoctionBoxProps) => {
    const { css } = sx();
    const [expand, setExpand] = React.useState(false);
    return (
        <Box sx={{ ...css.sxBox }}>
            <Box sx={{ ...css.sxBoxInfo1 }}>
                <Box sx={{ ...css.sxBoxImage }}>
                    <Image src={image} alt={title} />
                </Box>
                <Box sx={{ ...css.sxBoxInfo }}>
                    <Stack spacing={1}>
                        <Stack sx={{ ...css.sxStackTitle }} spacing={1}>
                            <Typography sx={{ ...css.sxTitle }} variant='h2'>
                                {title}
                            </Typography>
                            <Typography sx={{ ...css.sxLegend }} variant='body1'>
                                {legend}
                            </Typography>
                        </Stack>
                        <Stack spacing={1}>
                            <Typography
                                sx={{ ...css.sxPeriodTitle }}
                                variant='h3'>
                                Período:
                            </Typography>
                            <Stack
                                spacing={2}
                                direction={'row'}>
                                <Box sx={{ ...css.sxBoxPeriod }}>
                                    <Typography
                                        sx={{ ...css.sxTitleBox }}
                                        variant='h4'>
                                        Inicio
                                    </Typography>
                                    <Typography
                                        sx={{ ...css.sxDatePeriod }}
                                        variant='body1'>
                                        <I
                                            style={{ ...css.sxIconPeriod }}
                                            icon={['far', 'calendar-days']} />
                                        {startDate}
                                    </Typography>
                                </Box>
                                <Box sx={{ ...css.sxBoxPeriod }}>
                                    <Typography
                                        sx={{ ...css.sxTitleBox }}
                                        variant='h4'>
                                        Fim
                                    </Typography>
                                    <Typography
                                        sx={{ ...css.sxDatePeriod }}
                                        variant='body1'>
                                        <I
                                            style={{ ...css.sxIconPeriod }}
                                            icon={['far', 'calendar-days']} />
                                        {endDate}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Stack>
                    </Stack>
                    <IconButton
                        onClick={() => setExpand(!expand)}
                        sx={{ ...css.sxIconChevron }}>
                        <I icon={!expand ? ['far', 'chevron-down'] :
                            ['far', 'chevron-up']} />
                    </IconButton>
                </Box>
            </Box>
            {expand &&
                <Box sx={{ ...css.sxBoxInfo2 }}>
                    <Typography
                        sx={{ ...css.sxDetailsTitle }}
                        variant='h3'>
                        Detalhes
                    </Typography>
                    <Typography
                        variant='body1'
                        sx={{ ...css.sxDetailsLegend }}>
                        {details}
                    </Typography>
                    <Typography
                        variant='body2'
                        sx={{ ...css.sxPromotionValidate }}>
                        Promoção válida até às 23h59 do dia {endDate}
                    </Typography>
                </Box>
            }
        </Box>
    );
};

export { PromoctionBox };
