import React from 'react';
import { Button } from '@mui/material';
import { ButtonLargeProps } from '@interfaces';

import { sx } from './sx';

const ButtonLarge: React.FC<ButtonLargeProps> = ({
    text, onClick, color, backgroundColor, type, children, disabled,
}: ButtonLargeProps) => {
    const { css } = sx({
        backgroundColor: backgroundColor,
        color: color,
    });
    return (
        <Button
            type={type}
            onClick={onClick}
            sx={{ ...css.sxButton,
                opacity: disabled ? 0.7 : 1,
                cursor: disabled ? 'default' : 'pointer' }}>
            {text}
            {children}
        </Button>
    );
};

export { ButtonLarge };
