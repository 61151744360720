// import { imagePathToFile } from '../../../utils/imagePathToFile';
// import { images } from '../../../utils/images';
import { imagePathToFile } from '../../../utils/imagePathToFile';
import { images } from '../../../utils/images';
import { useAds } from '../index';

const useAdsActions = () => {
    const { createNewAd, measures } = useAds();

    // const responseMeasureTable = await findMeasureTableByAdID({ idAd: ad._id });
    // const responseMeasureTableData = await responseMeasureTable?.response.payload;

    // actions.edit();
    const edit = async (data: any, responseMeasureTableData: any) => {
        const measureTableList = responseMeasureTableData.length > 0 ?
            responseMeasureTableData.reduce((list: any, item: any) => {
                const map = item.medidas.map((m: any) => {
                    return {
                        _id: m._id,
                        nome: m.nome,
                        unidade_medida: m.unidade_medida,
                        value: m.valor,
                    };
                });
                return list = [...list, {
                    size: item.tamanho._id,
                    measures: map,
                }];
            }, []) : [];

        const selectedCategories = data.categorizacao.map((category: any) => ({
            _id: category._id,
            nome: category.nome,
        }));

        if (responseMeasureTableData.length > 0) {
            measures.addMeasuresAdList(measureTableList);
            createNewAd.insertData({
                type: 'measureTable', data: {
                    measureTable: measureTableList,
                },
            });
        };

        createNewAd.insertData({
            type: 'title', data: {
                title: data.titulo,
            },
        });

        createNewAd.insertData({
            type: 'description', data: {
                description: data.descricao,
            },
        });

        createNewAd.insertData({
            type: 'quantity_days_available', data: {
                quantity_days_available: data.quantidade_dias_disponivel,
            },
        });

        createNewAd.insertData({
            type: 'composition', data: {
                composition: data.composicao,
            },
        });

        if (data.outros) {
            createNewAd.insertData({
                type: 'others', data: {
                    others: data.outros,
                },
            });
        }

        createNewAd.insertData({
            type: 'stockQuantity', data: {
                stockQuantity: data.quantidade_estoque,
            },
        });
        createNewAd.insertData({
            type: 'sku', data: {
                sku: data.sku,
            },
        });

        createNewAd.insertData({
            type: 'price', data: {
                price: data.valor_por,
            },
        });

        createNewAd.insertData({
            type: 'condition', data: {
                condition: [{
                    nome: data.condicao[0].nome,
                    _id: data.condicao[0]._id,
                }],
            },
        });

        createNewAd.insertData({
            type: 'category', data: {
                category: selectedCategories,
            },
        });

        createNewAd.insertData({
            type: 'brand', data: {
                brand: [{
                    nome: data.marca[0].nome,
                    _id: data.marca[0]._id,
                }],
            },
        });

        createNewAd.insertData({
            type: 'weight', data: {
                weight: [{
                    nome: data.peso[0].nome,
                    _id: data.peso[0]._id,
                }],
            },
        });

        createNewAd.insertData({
            type: 'size', data: {
                size: data.tamanho,
            },
        });

        createNewAd.insertData({
            type: 'deliveryTypes', data: {
                deliveryTypes: {
                    aplicativo_entrega: data.aplicativo_entrega,
                },
            },
        });
        const findVariationDefaultImages = data.
            variacoes.
            find((variation: any) => variation.variacao_padrao);

        const imagesList = await Promise.all(findVariationDefaultImages.
            fotos
            .map(async (image: string) => {
                const file = await imagePathToFile(images.load(image, 'server'));

                return ({
                    id: image,
                    src: images.load(image, 'server'),
                    content: images.load(image, 'server'),
                    file: file,
                });
            }));

        createNewAd.images.addToEdit('ad', imagesList);
    };

    return { edit };
};

export { useAdsActions };
