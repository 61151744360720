import { useTheme } from '@mui/material';
import styled, { keyframes } from 'styled-components';

const animationExpand = keyframes`
    0% { opacity: 0; top: 0;}
    50% { opacity: 0; top: 32px;}
    100% {opacity: 1; top: 64px;}
`;

export const Item = styled.div`
    display: flex;
    cursor: pointer;
    align-items: center;
    padding: 1.25rem;
    cursor: pointer;

    :hover {
        .wrap-menu-visible {
            visibility: visible;
        }
    }
`;

export const WrapMenu = styled.div`
    position: absolute;
    min-height: 64px;
    visibility: hidden;
    -webkit-box-shadow: 0 8px 10px 4px rgba(100,100,100,0.1);
    box-shadow: 0 8px 10px 4px rgba(100,100,100,0.1);
    z-index: 100;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    &.wrap-menu-visible {
        visibility: visible;
        animation: ${animationExpand};
        animation-duration: 0.30s;
    }
`;


export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxBoxItem: {
        },
        sxContent: {
            'display': 'flex',
            'alignItems': 'center',
        },
        sxIcon: {
            'fontSize': '0.8125rem',
        },
        sxTitle: {
            fontWeight: typography.fontWeightBold,
            fontSize: '0.8125rem',
        },
        sxBoxIcon: {
            paddingLeft: '0.3125rem',
        },
        sxBox: {
            'display': 'flex',
            'justifyContent': 'center',
            'borderTop': `0.0625rem solid ${palette.clouds}`,
            'borderBottom': `0.0625rem solid ${palette.clouds}`,
        },
        sxBoxMenu: {
            minWidth: '18.75rem',
        },
        sxBoxLoading: {
            minWidth: '18.75rem',
            minHeight: '5.25rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sxBoxHistory: {
            display: 'flex',
            backgroundColor: palette.clouds,
            overflow: 'hidden',
            overflowX: 'auto',
        },
    };

    return { css };
};
