import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:37.5rem)');
    const { palette, typography } = useTheme();
    const css = {
        sxBox: {
            display: 'flex',
            flexDirection: 'column',
            border: `0.0625rem solid ${palette.clouds}`,
            borderRadius: '0.25rem',
        },
        sxBoxInfo1: {
            display: 'flex',
            flexDirection: matches ? 'row' : 'column',
            padding: '0.9375rem',
            paddingRight: 0,
            width: '100%',
        },
        sxBoxInfo2: {
            padding: '0.9375rem',
            width: '100%',
            borderTop: `0.0625rem solid ${palette.clouds}`,
        },
        sxBoxImage: {
            width: '100%',
            maxWidth: matches ? '28.6875rem' : '100%',
            maxHeight: matches ? '13.75rem' : '9.5rem',
            paddingRight: matches ? '0' : '0.9375rem',
        },
        sxStackTitle: {
            padding: '0.625rem 0',
        },
        sxTitle: {
            fontSize: matches ? '1.75rem' : '1.125rem',
            fontWeight: typography.fontWeightBold,
        },
        sxLegend: {
            fontSize: matches ? '1rem' : '0.875rem',
            fontWeight: typography.fontWeightMedium,
        },
        sxPeriodTitle: {
            fontSize: '1rem',
            fontWeight: typography.fontWeightBold,
        },
        sxBoxPeriod: {
            backgroundColor: palette.clouds,
            padding: '0.625rem 0.9375rem',
            borderRadius: '0.25rem',
        },
        sxTitleBox: {
            fontSize: '0.875rem',
            fontWeight: typography.fontWeightBold,
        },
        sxDatePeriod: {
            fontSize: '0.875rem',
            fontWeight: typography.fontWeightBold,
            paddingTop: '0.375rem',
        },
        sxIconPeriod: {
            fontSize: '1rem',
            marginRight: '0.25rem',
        },
        sxBoxInfo: {
            width: '100%',
            paddingTop: matches ? '0' : '1.25rem',
            paddingLeft: matches ? '1.25rem' : '0',
            display: 'flex',
            justifyContent: 'space-between',
        },
        sxIconChevron: {
            fontSize: '1rem',
            padding: '0 0.9375rem',
            color: palette.dark,
        },
        sxDetailsTitle: {
            fontSize: matches ? '1.25rem' : '1.125rem',
            fontWeight: typography.fontWeightBold,
            paddingBottom: '0.625rem',
        },
        sxDetailsLegend: {
            fontSize: matches ? '1rem' : '0.875rem',
            fontWeight: typography.fontWeightMedium,
        },
        sxPromotionValidate: {
            color: palette.dark,
            fontSize: matches ? '0.8125rem' : '0.75rem',
            fontWeight: typography.fontWeightMedium,
            paddingTop: '1.875rem',
        },
    };

    return { css };
};
