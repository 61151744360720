export const sx = () => {
    const css = {
        sxBox: {
            display: 'flex',
            alignItems: 'center',
            marginLeft: '0.9375rem',
            cursor: 'pointer',
        },
        sxIcon: {
            fontSize: '0.75rem',
        },
        sxTitle: {
            padding: '0.875rem 0',
            fontSize: '0.875rem',
            fontWeight: 700,
            marginLeft: '0.5rem',
            maxWidth: '5rem',
            width: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };
    return { ...css };
};
