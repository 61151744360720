import { useTheme } from '@mui/material';

const sx = () => {
    const { typography, palette } = useTheme();
    const { pxToRem } = typography;

    const css = {
        filters: {
            buttons: {
                default: {
                    ...{
                        backgroundColor: palette.common.white,
                        boxShadow: '0px 4px 8px #0000001D !important',
                        borderWidth: pxToRem(1),
                        borderStyle: 'solid',
                        borderColor: palette.clouds,
                        borderRadius: pxToRem(3),
                        textTransform: 'capitalize',
                        color: palette.dark,
                    },
                    '&:hover': {
                        backgroundColor: palette.common.white,
                        borderColor: palette.clouds,
                    },
                },
                active: {
                    ...{
                        backgroundColor: palette.dark,
                        boxShadow: '0px 4px 8px #0000001D !important',
                        borderRadius: pxToRem(3),
                        color: palette.common.white,
                        borderColor: palette.dark,
                    },
                    '&:hover': {
                        backgroundColor: 'black',
                    },
                },
            },
        },
    };

    return { css };
};

export { sx };
