import React from 'react';
import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { BoxContentProps } from '@interfaces';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { sx } from './sx';

const BoxContent: React.FC<BoxContentProps> = ({
    actions, children, borderBottom, borderTop, paddingLeft, customAction,
}: BoxContentProps) => {
    const { palette, typography } = useTheme();

    const { css } = sx();

    const titlesTooltip = {
        addToCart: 'Adicionar à sacola',
        edit: 'Editar',
        disable: 'Desativar',
        delete: 'Excluir',
        change: 'Alterar',
        goTo: '',
    };

    return (
        <Box className='box-content' sx={
            css.sxBox(borderBottom as boolean,
                paddingLeft as boolean,
                borderTop as boolean)
        }>
            <Box sx={{ ...css.sxBoxChildren }}>
                {children}
            </Box>
            {actions && (
                <Box sx={{ ...css.sxBoxActions }}>
                    {actions.map(({ actionType, actionFunction }, i) => {
                        return (
                            <IconButton key={`${i}-action`} onClick={actionFunction}
                                sx={{ ...css.sxIconButton }}>
                                <Box>
                                    <I
                                        icon={
                                            css.handleIconAction[actionType]
                                                .icon as IconProp}
                                        color={css.handleIconAction[actionType].color}
                                        style={{ fontSize: '1.125rem' }} />
                                    <Typography
                                        variant="caption"
                                        display="block"
                                        fontWeight="700"
                                        color={palette.darkGrey}
                                        fontSize={typography.pxToRem(11)}
                                        gutterBottom>
                                        {titlesTooltip[actionType]}
                                    </Typography>
                                </Box>
                            </IconButton>
                        );
                    })}
                    {customAction && customAction}
                </Box>
            )}
        </Box>
    );
};

export { BoxContent };
