/* eslint-disable no-unused-vars */
import React from 'react';
import { textSize } from '@utils';
import { customSizeMoney, customMarginSize } from './sx';
import { Typography, Box, useTheme } from '@mui/material';
import { PriceProps } from '@interfaces';

const Price: React.FC<PriceProps> = ({ size, value, negative }: PriceProps) => {
    const { typography, palette } = useTheme();
    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'start',
        }}>
            {negative && (
                <Typography
                    variant='caption'
                    fontSize={textSize[size]}
                    fontWeight={typography.fontWeightBold}
                    color={negative ? palette.error.main : palette.dark}
                    lineHeight={1}
                    sx={{ marginRight: '0.125rem' }}>
                    -
                </Typography>
            )}
            <Typography
                variant='caption'
                fontSize={customSizeMoney[size]}
                fontWeight={typography.fontWeightBold}
                color={negative ? palette.error.main : palette.dark}
                sx={{ marginRight: '0.125rem' }}>
                R$
            </Typography>
            <Typography
                variant='caption'
                fontSize={textSize[size]}
                fontWeight={typography.fontWeightBold}
                color={negative ? palette.error.main : palette.dark}
                sx={{ marginTop: customMarginSize[size] }}>
                {value}
            </Typography>
        </Box>
    );
};

export { Price };
