/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { AddressBox, MuiDialog } from '@components';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Grid,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';

import { FormAddressProps, SnackBarResponse, TemplateFormAddressProps, ToggleStaticProps } from '@interfaces';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from './yup';

import $ from 'jquery';
import 'jquery-mask-plugin';
import { statesList } from '@static/statesList';
import { useUser } from '../../../../../hooks/user';
import { useNavigate } from 'react-router-dom';
import { Snackbar } from '@components';
import { useCart } from '../../../../../hooks/shopping/cart';
import { searchZipCode } from '../../../../../api/services/user/calls';

const FormAddress: React.FC<TemplateFormAddressProps> = (
    { mode, address, cancelAction, onSubmitAction, location }: TemplateFormAddressProps) => {
    const { cart } = useCart();
    const dialogConfirm = React.useRef<ToggleStaticProps>(null);
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const { palette } = useTheme();
    const navigate = useNavigate();

    const { handleAddress, loadUser } = useUser();

    const [feedback, setFeedback] = React.useState<SnackBarResponse>({} as SnackBarResponse);
    const [mainAddress, setMainAddress] = React.useState(address && address.principal);
    const [isLoading, setIsLoading] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [valueAutocomplete, setValueAutocomplete] =
        React.useState<string | null>(address ?
            address.estado : '');

    const { register, handleSubmit, setValue,
        formState: { errors } } =
        useForm<FormAddressProps>({
            resolver: yupResolver(formSchema),
            mode: 'all',
        });

    const handleZipCode = async (zipCode: string) => {
        if (zipCode.length === 9) {
            const response = await searchZipCode({
                zipCode: zipCode.replace('-', ''),
            });

            if (!response.erro) {
                setValue('city', response.localidade);
                setValueAutocomplete(response.uf);
                setValue('state', response.uf);
                setValue('neighborhood', response.bairro);
                setValue('address', response.logradouro);
            };
        }
    };

    const onSubmit: SubmitHandler<FormAddressProps> = async (data) => {
        setIsLoading((prev) => prev = true);
        const response = await handleAddress({
            action: address && address._id ? 'update' : 'add',
            address: {
                addressId: address && address._id ? address._id : undefined,
                apelido: data.nickname,
                cidade: data.city,
                bairro: data.neighborhood,
                cep: data.cep,
                estado: data.state,
                logradouro: data.address,
                numero: data.number,
                complemento: data.complement as string,
                principal: mainAddress,
            },
        });

        if (!response.error) {
            setFeedback((prev) => prev = {
                type: 'success',
                message: response?.response.message,
            });
            snackRef.current?.show();
            await loadUser();
            onSubmitAction && onSubmitAction();
            await cart.calculateShipping();
            await cart.load();
            if (location === 'checkout') {
                setTimeout(() => {
                    navigate(`/checkout?shippingAddress=${response.response.payload._id}`);
                }, 1000);
            }
            if (location === 'address') {
                setTimeout(() => {
                    navigate(`/checkout/endereco?shippingAddress=${response.response.payload._id}`);
                }, 1000);
            }

            setIsLoading((prev) => prev = false);
        } else {
            setFeedback((prev) => prev = {
                type: 'info',
                message: response?.response.message,
            });
            snackRef.current?.show();
            setIsLoading((prev) => prev = false);
        }
    };

    const inputMasks = () => {
        $('.cep').mask('00000-000');
    };

    React.useEffect(() => {
        inputMasks();
        if (address) {
            setValue('state', address.estado);
        }
    }, []);

    return (
        <React.Fragment>
            <Snackbar
                ref={snackRef}
                message={feedback.message}
                severity={feedback?.type}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'CEP'}
                            defaultValue={address ? address.cep : ''}
                            placeholder='00000-030'
                            variant="outlined"
                            helperText={(!!errors.cep?.message &&
                                errors.cep.message)}
                            error={!!errors.cep}
                            inputProps={{
                                className: 'cep',
                            }}
                            {...register('cep',
                                { required: true },
                            )}
                            onChange={(e) => handleZipCode(e.target.value)} />
                    </Grid>
                    <Grid item xs={6} mb={1}>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            defaultValue={address ? address.estado : ''}
                            options={(statesList).map(
                                (state) => state.sigla)}
                            value={valueAutocomplete}
                            onChange={(event: any,
                                newValue: string | null) => {
                                setValueAutocomplete(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Estado"
                                    helperText={(!!errors.state?.message &&
                                        errors.state.message)}
                                    error={!!errors.state}
                                    {...register('state',
                                        { required: true },
                                    )}
                                />}
                        />
                    </Grid>
                    <Grid item xs={6} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'Cidade'}
                            variant="outlined"
                            defaultValue={address ? address.cidade : ''}
                            helperText={(!!errors.city?.message &&
                                errors.city.message)}
                            error={!!errors.city}
                            {...register('city',
                                { required: true },
                            )} />
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'Bairro'}
                            variant="outlined"
                            defaultValue={address ? address.bairro : ''}
                            helperText={(!!errors.neighborhood?.message &&
                                errors.neighborhood.message)}
                            error={!!errors.neighborhood}
                            {...register('neighborhood',
                                { required: true },
                            )} />
                    </Grid>
                    <Grid item xs={8} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'Endereço'}
                            variant="outlined"
                            defaultValue={address ? address.logradouro : ''}
                            helperText={(!!errors.address?.message &&
                                errors.address.message)}
                            error={!!errors.address}
                            {...register('address',
                                { required: true },
                            )} />
                    </Grid>
                    <Grid item xs={4} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'Número'}
                            variant="outlined"
                            defaultValue={address ? address.numero : ''}
                            helperText={(!!errors.number?.message &&
                                errors.number.message)}
                            error={!!errors.number}
                            {...register('number',
                                { required: true },
                            )} />
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'Complemento'}
                            variant="outlined"
                            defaultValue={address ? address.complemento : ''}
                            {...register('complement',
                                { required: true },
                            )} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            focused
                            fullWidth
                            label={'Apelido'}
                            placeholder='Casa, apartamento, trabalho'
                            variant="outlined"
                            defaultValue={address ? address.apelido : ''}
                            helperText={(!!errors.nickname?.message &&
                                errors.nickname.message)}
                            error={!!errors.nickname}
                            {...register('nickname',
                                { required: false },
                            )} />
                    </Grid>
                </Grid>
                {/* <Stack
                    mt={2}
                    mb={1}
                    alignItems={'start'}
                    direction={'row'}>
                    <Checkbox
                        checked={mainAddress}
                        onChange={() => setMainAddress(!mainAddress)}
                        id='main-address'
                        sx={{
                            'color': palette.dark,
                            'borderRadius': 0,
                            'padding': '0',
                            'width': '1.125rem',
                            '&.Mui-checked': {
                                color: palette.dark,
                            },
                        }} />
                    <Typography
                        variant="body1"
                        color={palette.darkGrey}
                        ml={1}
                        onClick={() => setMainAddress(!mainAddress)}
                        sx={{
                            cursor: 'pointer',
                            fontSize: '0.875rem',
                        }}
                    >
                        Salvar como endereço principal
                    </Typography>
                </Stack> */}

                {/* {mode === 'creditCardAddress' && (
                    <Stack
                        mt={1}
                        alignItems={'start'}
                        direction={'row'}>
                        <Checkbox
                            id='save-address'
                            sx={{
                                'color': palette.dark,
                                'borderRadius': 0,
                                'padding': '0',
                                'width': '1.125rem',
                                '&.Mui-checked': {
                                    color: palette.dark,
                                },
                            }} />
                        Salvar endereço em Meus Endereços
                    </Stack>
                )} */}

                <Box
                    display='flex'
                    alignItems='center'
                    flexDirection='column'
                    mt={2}>
                    <Box
                        maxWidth={320}
                        width='100%'
                        mb={1}
                        justifyContent='center'>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            type='submit'
                            className='MuiButton secondary'
                        >
                            Salvar {isLoading &&
                                <CircularProgress
                                    size={14}
                                    color='inherit'
                                    sx={{ ml: 0.5 }} />
                            }
                        </Button>
                    </Box>
                    {cancelAction && (
                        <Box
                            maxWidth={320}
                            width='100%'
                            justifyContent='center'>
                            <Button
                                fullWidth
                                variant="text"
                                size="large"
                                type='button'
                                onClick={cancelAction}
                                className='MuiButton text'
                            >
                                Cancelar
                            </Button>
                        </Box>
                    )}
                </Box>
            </form>
            <MuiDialog
                maxWidth='xs'
                ref={dialogConfirm}
                contentPadding={false}
                title='Deletar Endereço?'
                showDialogTitle
                onConfirm={{
                    action: () => { },
                    text: 'Confirmar',
                }}>
                <Box>
                    <AddressBox
                        mode={'list'}
                        nickname={'casa'}
                        title='CEP: 85050-070'
                        legend='R Bartolomeu bueno da silva, n359 -
                        Bairro ÁguaVerde, Curitiba - Paraná' />
                </Box>
            </MuiDialog>
        </React.Fragment >
    );
};

export { FormAddress };
