// import { RouteObject } from 'react-router-dom';
import { Layout } from '@components';
import { MembersArea } from '@pages';
import {
    authStackRoutes,
    authStackRoutesLayout,
    membersHomeLayout } from './list.routes';
import { RouteObject } from 'react-router-dom';


const routesAuth = () => {
    return authStackRoutes.map((route) => ({
        element: route.element,
        path: route.path,
    }));
};

const routesAuthLayout = authStackRoutesLayout.map((route) => ({
    element: route.element,
    path: route.path,
}));


export const authenticatedRoutes: () => RouteObject[] =
    () => {
        return [
            {
                element: <Layout.Authenticated />,
                children: routesAuthLayout,
            },
            ...routesAuth(),
            {
                element: <MembersArea.HomeBase />,
                children: membersHomeLayout,
            },
        ];
    };
