import { useTheme } from '@mui/material';

interface SxProps {
    checkedRadio?: boolean;
}

export const sx = ({
    checkedRadio,
}: SxProps) => {
    const { typography, palette } = useTheme();
    const css = {
        sxBoxImage: {
            default: (active: boolean) => {
                return {
                    cursor: 'pointer',
                    position: 'relative',
                    boxShadow: active ? '0px 0.1875rem 0.375rem #00000029' : 'none',
                    padding: '0.3125rem',
                    border: active ? `0.0625rem solid ${palette.clouds}` : 0,
                    display: 'flex',
                };
            },
            medium: {
                width: '4.375rem',
                height: '4.375rem',
            },
            small: {
                width: '2.875rem',
                height: '2.875rem',
            },
        },
        sxRadio: {
            'padding': '10',
            'position': 'absolute',
            'top': 0,
            'right': 0,
            '&:hover': {
                backgroundColor: 'transparent',
            },
            '& > span': {
                backgroundColor: palette.common.white,
                borderRadius: 100,
            },
        },
        sxText: {
            paddingTop: '0.25rem',
            fontSize: '0.75rem',
            fontWeight: typography.fontWeightBold || 700,
            textAlign: 'center',
        },
    };

    return { css };
};
