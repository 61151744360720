import * as Yup from 'yup';

export const formSchema = Yup.object().shape({
    senha: Yup.string().required('Insira sua senha atual'),
    novaSenha: Yup.string().required('Insira sua nova senha'),
    repetirSenha: Yup.string()
        .required('Repita sua nova senha')
        .oneOf([Yup.ref('novaSenha')], 'As senhas não são iguais'),
});

