/* eslint-disable max-len */
import React from 'react';
import { ThemeProvider } from '@mui/material';

import './assets/css/index.scss';
import { theme } from './themes';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { BrowserRouter } from 'react-router-dom';
import { Routes } from './routes';
import './libs/fontawesome';

import {
    HandleFooter,
    Header,
} from '@components';

import { UserProvider } from './contexts/user/provider';
import { OthersProvider } from './contexts/others/provider';
import { PaymentMethodProvider } from './contexts/payment-method/provider';
// import { ScrollToTop } from '@utils';
import { AdsProvider } from './contexts/ads/provider';
import { FiltersProvider } from './contexts/filters/provider';
import { MainMenuProvider } from './contexts/main-menu/provider';
import { ShoppingProvider } from './contexts/shopping/provider';
import { ScrollToTop } from '@utils';
import { WalletProvider } from './contexts/wallet/provider';
import ReactGA from 'react-ga4';

// google analytics homolog G-DS7NCNSCXG
// google analytics prod G-H20DQD5VEC
ReactGA.initialize('G-H20DQD5VEC');

export default function App() {
    // React.useEffect(() => {
    //     if (!document.getElementById('widget-fidelity')) {
    //         const script = document.createElement('script');
    //         script.id = 'widget-fidelity';
    //         // script.src = 'http://localhost:3002/embedded-loyalty.js';
    //         script.src = 'https://rewardhub.h1.campanhasonline.com.br/embedded-loyalty.js';
    //         script.async = true;

    //         document.body.appendChild(script);
    //     }
    // }, []);

    return (
        <ThemeProvider theme={theme}>
            <UserProvider>
                <OthersProvider>
                    <MainMenuProvider>
                        <FiltersProvider>
                            <AdsProvider>
                                <WalletProvider>
                                    <PaymentMethodProvider>
                                        <ShoppingProvider>
                                            <BrowserRouter>
                                                <ScrollToTop />
                                                <Header />
                                                <Routes />
                                                <HandleFooter />
                                            </BrowserRouter>
                                        </ShoppingProvider>
                                    </PaymentMethodProvider>
                                </WalletProvider>
                            </AdsProvider>
                        </FiltersProvider>
                    </MainMenuProvider>
                </OthersProvider>
            </UserProvider>
        </ThemeProvider >
    );
}
