import * as Yup from 'yup';

export const formSchemaCode = Yup.object().shape({
    code1: Yup.string().required(''),
    code2: Yup.string().required(''),
    code3: Yup.string().required(''),
    code4: Yup.string().required(''),
    code5: Yup.string().required(''),
    code6: Yup.string().required(''),
});
