import { Box, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { PurchaseHistoryStatusType } from '@types';
import { purchaseStatus } from '@utils';

interface ProductStatusProps {
    status: PurchaseHistoryStatusType;
}

// type ProductStatusType = 'finalizado' |
// 'finalizado_devolucao' |
// 'cancelado' |
// 'cancelado_devolucao' |
// 'reembolsado' |
// 'returned' |
// 'pago' |
// 'pago_devolucao' |
// 'pagamento_pendente' |
// 'pagamento_pendente_devolucao' |
// 'etiqueta_gerada' |
// 'etiqueta_gerada_devolucao' |
// 'postado' |
// 'postado_devolucao' |
// 'aguardando_estorno_boleto' |
// 'aguardando_estorno_pix' |
// 'pagamento_nao_aprovado' |
// 'expirado';

const ProductStatus = ({ status }: ProductStatusProps) => {
    const { typography, palette } = useTheme();

    // const statusObj = {
    //     finalizado: {

    //         title: 'Entregue',
    //         colors: {
    //             status: palette.status.accepted,
    //         },
    //     },
    //     finalizado_devolucao: {

    //         title: 'Entregue',
    //         colors: {
    //             status: palette.status.accepted,
    //         },
    //     },
    //     cancelado: {

    //         title: 'Cancelado',
    //         colors: {
    //             status: palette.status.rejected,
    //         },
    //     },
    //     cancelado_devolucao: {

    //         title: 'Cancelado',
    //         colors: {
    //             status: palette.status.rejected,
    //         },
    //     },
    //     reembolsado: {

    //         title: 'Cancelado',
    //         colors: {
    //             status: palette.status.rejected,
    //         },
    //     },
    //     returned: {

    //         title: 'Devolvido',
    //         colors: {
    //             status: palette.status.accepted,
    //         },
    //     },
    //     pago: {

    //         title: 'Pago',
    //         colors: {
    //             status: palette.status.accepted,
    //         },
    //     },
    //     pago_devolucao: {

    //         title: 'Pago',
    //         colors: {
    //             status: palette.status.accepted,
    //         },
    //     },
    //     pagamento_pendente: {

    //         title: 'Aguardando Pagamento',
    //         colors: {
    //             status: palette.status.pending,
    //         },
    //     },
    //     pagamento_pendente_devolucao: {

    //         title: 'Aguardando Pagamento',
    //         colors: {
    //             status: palette.status.pending,
    //         },
    //     },
    //     etiqueta_gerada: {

    //         title: 'Aguardando Envio',
    //         colors: {
    //             status: palette.status.pending,
    //         },
    //     },
    //     etiqueta_gerada_devolucao: {

    //         title: 'Aguardando Envio',
    //         colors: {
    //             status: palette.status.pending,
    //         },
    //     },
    //     postado: {

    //         title: 'Postado',
    //         colors: {
    //             status: palette.status.pending,
    //         },
    //     },
    //     postado_devolucao: {

    //         title: 'Postado',
    //         colors: {
    //             status: palette.status.pending,
    //         },
    //     },
    //     aguardando_estorno_boleto: {

    //         title: 'Aguardando Reembolso',
    //         colors: {
    //             status: palette.status.pending,
    //         },
    //     },
    //     aguardando_estorno_pix: {

    //         title: 'Aguardando Reembolso',
    //         colors: {
    //             status: palette.status.pending,
    //         },
    //     },
    //     pagamento_nao_aprovado: {

    //         title: 'Pagamento não realizado',
    //         colors: {
    //             status: palette.status.rejected,
    //         },
    //     },
    //     expirado: {

    //         title: 'Pagamento não realizado',
    //         colors: {
    //             status: palette.status.pending,
    //         },
    //     },
    // };

    return (
        <Box
            sx={{
                backgroundColor: palette.clouds,
                display: 'table',
                padding: '.1875rem .625rem',
                borderTopLeftRadius: 6,
            }}>
            <Typography
                component='p'
                sx={{
                    fontSize: typography.pxToRem(12),
                    fontWeight: typography.fontWeightBold,
                    color: palette.dark,
                    display: 'flex',
                }}>
                <Box mr={typography.pxToRem(4)}>
                    <I icon={['fas', 'circle']}
                        color={purchaseStatus(status)[status].color}
                        fontSize={10} />
                </Box>
                {purchaseStatus(status)[status].title}
            </Typography>
        </Box>
    );
};

export { ProductStatus };
