import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Box, Typography, useTheme } from '@mui/material';
import { CloseIcon, Item } from './styles';

interface ButtonSelectedFilterProps {
    title?: string | any;
    value: string | any;
    onClick?: () => void;
}

const ButtonSelectedFilter = ({ title, value, onClick }: ButtonSelectedFilterProps) => {
    const { typography, palette } = useTheme();
    return (
        <Box display='table' m={1} onClick={onClick}>
            <Item>
                <Box>
                    {title && (
                        <Typography
                            variant='h2'
                            component='h2'
                            fontSize={10}
                            whiteSpace='nowrap'
                            fontWeight={typography.fontWeightBold}>
                            {title}
                        </Typography>
                    )}
                    <Typography
                        variant='h2'
                        component='p'
                        fontSize={12}
                        whiteSpace='nowrap'
                        fontWeight={typography.fontWeightBold}>
                        {value}
                    </Typography>
                </Box>
                <CloseIcon>
                    <I
                        icon={['far', 'times']}
                        color={palette.common.white}
                    />
                </CloseIcon>
            </Item>
        </Box>
    );
};

export { ButtonSelectedFilter };
