import styled from 'styled-components';

interface LabelProps {
    backgroundColor: string;
}

export const Label = styled.label<LabelProps>`
    position: relative;
    padding: 0.3125rem 0.375rem;
    cursor: pointer;
    color: transparent;
    border-radius: 0.25rem;
    white-space: nowrap;
    text-transform: uppercase;
    font-size: 0.75rem;

    & > input {
        display: none;
    }

    @media (max-width: 600px) {
        padding: 0.1875rem 0.5625rem;
        font-size: 0.625rem;
    }

    input:checked ~ .overlay {
        color: #fff;
        background-color: ${(props) => props.backgroundColor};
        border: 1px solid ${(props) => props.backgroundColor};
    }
`;
