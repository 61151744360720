import { useMediaQuery } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            default: {
                display: 'flex',
                position: 'relative',
            },
            fluid: {
                width: '100%',
                height: matches ? '17.375rem' : '10rem',
            },
            big: {
                width: '6.625rem',
                height: '6.625rem',
            },
            medium: {
                width: '5rem',
                height: '5rem',
            },
            small: {
                width: '3.125rem',
                height: '3.125rem',
            },
        },
    };
    return {
        ...css,
    };
};
