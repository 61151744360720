import React from 'react';
import { ResponseCreateUserProps, UserMenuProps, UserProps } from '@interfaces/Contexts';
import {
    FavoriteCallProps,
    FavoriteProps,
    FormAddressAPI,
    ProfileForm,
    UpdatePassForm,
    UploadImageProps,
} from '@interfaces';
import { UserContext } from '../../contexts/user/context';
import { client } from '../../api/client';
import { localStorage } from '../../utils/localStorage';
import { useCart } from '../shopping/cart';

interface ListFavoritesProps {
    total: number;
    data: FavoriteProps[]
}

// const DEFAULTS = {
//     user: {
//         data: {} as ResponseCreateUserProps,
//         isLoaded: false,
//     },
// };

const userProvider = () => {
    const { cart } = useCart();
    const [user, setUser] = React.useState<UserProps>({} as UserProps);
    const [responseCreateUser, setResponseCreateUser] =
        React.useState<ResponseCreateUserProps | undefined>();
    const [userMenu, setUserMenu] = React.useState<UserMenuProps>(
        {} as UserMenuProps);
    const [listFavorites, setListFavorites] = React.useState<ListFavoritesProps>(
        {} as ListFavoritesProps,
    );

    const loadMenu = async () => {
        const { services } = client();
        const response = await services.user.loadMenu();
        if (!response?.error) {
            return setUserMenu({ data: response?.response.message, isLoaded: true });
        } else {
            return setUserMenu({ data: undefined, isLoaded: false });
        }
    };

    const loadUser = async () => {
        const { services } = client();

        const auth = localStorage({
            action: 'get',
            key: '@auth',
        });

        if (auth) {
            const response = await services.user.loadUser();
            if (!response?.error) {
                await loadFavorites({
                    limit: 8,
                });
                await loadMenu();
                return setUser({ data: response?.response.payload, isLoaded: true });
            } else {
                return setUser({ data: undefined, isLoaded: false });
            }
        } else {
            await cart.createSession();
        }
    };

    const passwordChange = async (email: string) => {
        const { services } = client();
        const response = await services.user.passwordChange(email);
        return response;
    };

    const updateUser = async ({ ...profile }: ProfileForm | UpdatePassForm) => {
        const { services } = client();
        try {
            const response = await services.user.updateUser({
                ...profile,
            });
            return response;
        } catch (error: any) {
            return error;
        }
    };

    const uploadImage = async (data: UploadImageProps) => {
        const { services } = client();
        try {
            const response = await services.user.uploadImage(data);
            return response;
        } catch (error: any) {
            return error;
        }
    };


    React.useEffect(() => {
        if (!user.data) {
            loadUser();
        }
    }, []);

    const handleAddress = async ({ address, action }: {
        address: FormAddressAPI,
        action: 'add' | 'update',
    }) => {
        const { services } = client();
        try {
            if (action === 'update') {
                const response = await services.user.updateAddress({
                    ...address,
                });
                return response;
            } else {
                const response = await services.user.addAddress({
                    ...address,
                });
                return response;
            }
        } catch (error: any) {
            return error;
        }
    };

    const loadFavorites = async ({ limit }: { limit: number }) => {
        const { services } = client();

        if (!user.data?.cnpj) {
            const response = await services.user.favorites.load(limit);
            if (response?.response.payload) {
                setListFavorites({
                    total: response?.response.payload.total,
                    data: response?.response.payload.favorites,
                });
            } else {
                setListFavorites({} as ListFavoritesProps);
            }
            return response;
        }
    };

    const deleteFavorite = async (favoriteID: string) => {
        const { services } = client();
        const response = await services.user.favorites.delete(favoriteID);
        return response;
    };

    const addResponseCreate = (data: ResponseCreateUserProps) => {
        setResponseCreateUser(data);
    };

    const clearResponseCreate = () => {
        setResponseCreateUser(undefined);
    };

    const addFavorites = async (data: FavoriteCallProps) => {
        const { services } = client();
        try {
            const response = await services.user.favorites.add(data);
            return response;
        } catch (error: any) {
            return error;
        }
    };

    const searchZipCode = async ({ zipCode }: {
        zipCode: string;
    }) => {
        const { services } = client();
        try {
            const response = await services.user.searchZipCode({
                zipCode: zipCode,
            });
            return response;
        } catch (error: any) {
            return error;
        }
    };


    const deleteAddress = async ({ idEndereco }: {
        idEndereco: string;
    }) => {
        const { services } = client();
        try {
            const response = await services.user.deleteAddress({
                idEndereco: idEndereco,
            });
            return response;
        } catch (error: any) {
            return error;
        }
    };

    const favorites = {
        add: addFavorites,
        list: listFavorites,
        load: loadFavorites,
        delete: deleteFavorite,
    };

    return {
        user,
        userMenu,
        responseCreateUser,
        addResponseCreate,
        clearResponseCreate,
        loadUser,
        updateUser,
        uploadImage,
        passwordChange,
        handleAddress,
        deleteAddress,
        searchZipCode,
        favorites,
    };
};

const useUser = () => {
    const context = React.useContext(UserContext);
    return context;
};

export { useUser, userProvider };
