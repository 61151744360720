import styled from 'styled-components';

export const Hero = styled('div')(({ theme }) => `
& > .WrapAnchorPicture {
        height: 100vh;
        max-height: 120px;

        ${[theme.breakpoints.up('sm')]} {
            max-height: 270px;
        }

        & > .AnchorPicture {
            display: block;
            height: 100%;
            width: 100%;
            overflow: hidden;

            & > .WrapPicture {
                height: 100%;
                width: 100%;
                picture {
                    source,
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border: 0;
                        max-width: 100%;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
`);
