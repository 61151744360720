import React from 'react';
import Slider from 'react-slick';

import { Box } from '@mui/material';
import { settings } from './slider-settings';
import { SliderItem } from './components/slider-item';

import { sx } from './sx';

const SliderTest: React.FC = () => {
    const { sxBoxSlider } = sx();

    return (
        <Box sx={{ ...sxBoxSlider }}>
            <Slider {...settings} className='contents-home-slider'>
                <SliderItem src={'https://placehold.co/600x400/EEE/31343C'} />
                <SliderItem src={'https://placehold.co/600x400/EEE/31343C'} />
                <SliderItem src={'https://placehold.co/600x400/EEE/31343C'} />
            </Slider>
        </Box>
    );
};

export { SliderTest };
