import React from 'react';
import { Container, Box, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { LinkFooterMobile } from '@components';
import { FooterMobileProps } from '@interfaces';

import MariazLogo from '@img/mariaz-logo.png';

import { FooterData } from './data';
import { sx } from './sx';
import { Logo } from './styles';

const FooterMobile: React.FC<FooterMobileProps> = ({
    fixedMenu,
}: FooterMobileProps) => {
    const { typography } = useTheme();

    const { sxContainer, sxBoxLogo, sxBoxInfo,
        sxInstaIcon, sxEndInfoBox } = sx({
        fixedMenu: fixedMenu,
    });

    return (
        <Container maxWidth='md' sx={{
            ...sxContainer,
        }}>
            <Box sx={{ ...sxBoxLogo }}>
                <Logo src={MariazLogo} alt="" />
            </Box>
            <Box>
                {FooterData.map(({ section, items }, i) => (
                    <LinkFooterMobile
                        key={`${section}-${i}`}
                        section={section}
                        items={items} />
                ))}
            </Box>
            <Box sx={{ ...sxBoxInfo }}>
                {/* <Typography
                    variant='h4'
                    fontSize={'1rem'}
                    fontWeight={typography.fontWeightBold}
                    sx={{ ...sxFollow }}>
                    Instagram
                </Typography> */}
                <a href="https://www.instagram.com/mariaz.com.br" target="_blank">
                    <I
                        icon={['fab', 'square-instagram']}
                        style={{ ...sxInstaIcon }} />
                </a>
            </Box>
            <Box sx={{ ...sxEndInfoBox }}>
                <Typography
                    fontSize={'0.75rem'}
                    fontWeight={typography.fontWeightMedium}
                    textAlign={'center'}
                    sx={{ padding: '1.25rem' }}>
                    Mariaz © 2023 - todos os direitos reservados.
                </Typography>
            </Box>
        </Container>
    );
};

export { FooterMobile };
