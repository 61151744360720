import { useTheme } from '@mui/material';

interface SxFooter {
    fixedMenu ?: boolean;
}

export const sx = ({
    fixedMenu,
}: SxFooter) => {
    const { palette } = useTheme();
    const css = {
        sxContainer: {
            borderTop: `1px solid ${palette.clouds}`,
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '0',
            paddingTop: '1.875rem',
            paddingBottom: fixedMenu ? '3.125rem' : 0,
        },
        sxBoxLogo: {
            display: 'flex',
            justifyContent: 'center',
        },
        sxBoxInfo: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        sxFollow: {
            paddingTop: '1.25rem',
            paddingBottom: '0.9375rem',
        },
        sxInstaIcon: {
            fontSize: '1.75rem',
            color: palette.dark,
            paddingBottom: '1.25rem',
        },
        sxEndInfoBox: {
            backgroundColor: palette.clouds,
        },
    };
    return {
        ...css,
    };
};
