import * as api from './calls';

const paymentMethod = {
    creditCard: {
        update: api.updateCreditCard,
        list: api.listPaymentsMethods,
        delete: api.deleteCreditCard,
    },
};

export { paymentMethod };
