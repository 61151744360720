import React from 'react';
import { Box, Typography, useTheme, Stack } from '@mui/material';
import { ProductImage, Price, ButtonIcon, Snackbar } from '@components';
import { SizesType } from '@types';
import { ProductProps, ToggleStaticProps } from '@interfaces';

import {
    BoxInfoSx,
    MarginTitle,
    PriceSize,
    BoxIconsButtons,
    StackIcons,
} from './sx';
import { useUser } from '@hooks';
import { formatNumberToPriceBRL } from '@utils';
import { useCart } from '../../../../hooks/shopping';

const Product: React.FC<ProductProps> = ({
    src,
    title,
    legend,
    price,
    size,
    align,
    mode,
    contentAlign,
    _id,
    favorite,
    stockQuantity,
    to,
    itemVariationId,
    _idAd,
}: ProductProps) => {
    const { palette, typography } = useTheme();
    const handleBoxDirection = align === 'bottom' ? 'column' : 'row';
    const handleMarginContent = align === 'bottom' ? '0' : '0.625rem';
    const handleMarginLegend = size === 'small' ? '0.1875rem' : '0.25rem';

    const { favorites } = useUser();
    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const [isFavorite, setIsFavorite] = React.useState(favorite);

    const { cart } = useCart();

    const fontSizeObj = {
        fluid: {
            fontSize: typography.pxToRem(16),
        },
        big: {
            fontSize: typography.pxToRem(16),
        },
        medium: {
            fontSize: typography.pxToRem(14),
        },
        small: {
            fontSize: typography.pxToRem(14),
        },
    };

    const addToCart = async () => {
        const object = {
            quantity: 1,
            idVariation: _id as string,
            variationItemId: itemVariationId as string,
        };
        const response = await cart.item.add({ ...object });
        if (!response?.error) {
            setFeedback((prev) => prev = {
                type: 'success',
                message: response?.response.message,
            });
            snackRef.current?.show();
            await cart.load();
        } else {
            setFeedback((prev) => prev = {
                type: 'info',
                message: response.response.message,
            });
            snackRef.current?.show();
        }
    };

    const handleFavorite = async () => {
        const response = await favorites.add({
            idAd: _idAd as string,
            idVariation: _id as string,
            idVariationItem: itemVariationId as string,
        });

        if (!response?.error) {
            if (response?.response.message.includes('salvo')) {
                setIsFavorite(true);
            } else {
                setIsFavorite(false);
            }
            await favorites.load({
                limit: 5,
            });
        }

        snackRef.current?.show();
        setFeedback({
            type: response?.response.type,
            message: response?.response.message,
        });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                cursor: 'pointer',
                flexDirection: handleBoxDirection,
                position: 'relative',
            }} >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 80,
                }}
                onClick={() => _id && to ? to() : null}
            />
            <Box sx={{ position: 'relative' }}>

                <ProductImage
                    size={size}
                    src={src}>
                </ProductImage>
                {mode === 'buy' &&
                    <Box sx={{ ...BoxIconsButtons }}>
                        <Stack
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="flex-end"
                            spacing={1}
                            sx={{ ...StackIcons }}>
                            <ButtonIcon
                                onClick={() => handleFavorite()}
                                active={isFavorite}
                                size={'small'}
                                icon={'heart'} />
                            <ButtonIcon
                                disabled={stockQuantity === 0}
                                onClick={addToCart}
                                size={'small'}
                                icon={'bag-shopping'} />
                        </Stack>
                    </Box>
                }
            </Box>
            <Box
                sx={{
                    ...BoxInfoSx.default,
                    ...BoxInfoSx[size],
                    marginLeft: handleMarginContent,
                    justifyContent: contentAlign ?? 'flex-start',
                }}>
                <Box>
                    <Typography
                        variant='h3'
                        fontSize={fontSizeObj[size].fontSize}
                        fontWeight={typography.fontWeightMedium}
                        sx={{ ...MarginTitle[size], ...MarginTitle['default'] }}>
                        {title}
                    </Typography>
                    {legend && (
                        <Typography
                            variant='body1'
                            fontWeight={typography.fontWeightSemiBold}
                            fontSize={'0.75rem'}
                            color={palette.darkGrey}
                            sx={{
                                marginBottom: handleMarginLegend,
                                wordBreak: 'break-word',
                            }}>
                            {legend}
                        </Typography>
                    )}
                </Box>
                {price && (
                    <Price size={PriceSize[size] as SizesType}
                        // product
                        value={formatNumberToPriceBRL(Number(price))} />
                )}
                <Snackbar
                    ref={snackRef}
                    message={feedback ? feedback.message : ''}
                    severity={feedback ? feedback.type as any : 'info'}
                />
            </Box>
        </Box >
    );
};

export { Product };
