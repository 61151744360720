import { useMediaQuery } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            padding: matches ? '30px 0' : '15px 0',
        },
        sxBoxSlider: {
            width: '100%',
            margin: '0 auto',
            marginBottom: '2.8125rem',
        },
    };
    return {
        ...css,
    };
};
