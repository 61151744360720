import React from 'react';

import {
    Box,
    IconButton,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import { TopicProps } from '@interfaces';

import { sx } from './sx';

const Topic: React.FC<TopicProps> = ({
    legend, title, keyValue,
}: TopicProps) => {
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx();
    return (
        <Link to={`/ajuda/${keyValue}`} style={{
            ...css.sxLink,
        }}>
            <Box sx={{ ...css.sxBox }}>
                <Box sx={{ ...css.sxBoxTitle }}>
                    <Typography
                        sx={{ ...css.sxTitle }}
                        variant='h3'>
                        {title}
                    </Typography>
                    {!matches &&
                        <IconButton sx={{ ...css.sxIconButton }}>
                            <I icon={['far', 'chevron-right']} />
                        </IconButton>
                    }
                </Box>
                {matches &&
                <>
                    <Typography
                        sx={{ ...css.sxLegend }}
                        variant='body1'>
                        {legend}
                    </Typography>
                    <Typography
                        sx={{ ...css.sxLinkText }}
                        variant='caption'>
                        saiba mais
                    </Typography>
                </>
                }
            </ Box>
        </Link>
    );
};

export { Topic };
