import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    TextField,
    Checkbox,
    Stack,
    Box,
    Typography,
    InputAdornment,
    IconButton,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';

import {
    ButtonLarge,
    Snackbar,
} from '@components';

import { FormCreateAccount, ToggleStaticProps } from '@interfaces';

import $ from 'jquery';
import 'jquery-mask-plugin';

import { formSchema } from './yup';
import { sx } from './sx';
import { Form, Label, ErrorTerms } from './styles';
import { useNavigate } from 'react-router-dom';

const CreateAccountForm: React.FC = () => {
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const { css } = sx();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = React.useState(false);

    const { register, handleSubmit, formState: { errors } } =
        useForm<FormCreateAccount>({
            resolver: yupResolver(formSchema),
            mode: 'onBlur',
        });

    const onSubmit: SubmitHandler<FormCreateAccount> = async (data) => {};

    const inputMasks = () => {
        $('.cellphone').mask('(00) 00000-0000');
        $('.cpf').mask('000.000.000-00');
    };

    React.useEffect(() => {
        inputMasks();
    }, []);

    return (
        <>

            <Box sx={{ ...css.sxBoxAccess }}>
                <Typography
                    variant='h1'
                    sx={{ ...css.sxAccessText }}
                >
                    Cadastre-se agora no Mariaz
                </Typography>
            </Box>

            <Form
                onSubmit={handleSubmit(onSubmit)}>
                <TextField
                    focused={true}
                    label={'Nome completo'}
                    placeholder='Nome completo'
                    id="outlined-basic"
                    variant="outlined"
                    helperText={errors.name ? errors.name.message : ''}
                    {...register('name', { required: true })} />
                <TextField
                    focused={true}
                    label={'E-mail'}
                    placeholder='examplodeemail@exemplo.com'
                    id="outlined-basic"
                    variant="outlined"
                    helperText={errors.email ? errors.email.message : ''}
                    {...register('email', { required: true })} />
                <TextField
                    focused={true}
                    label={'Telefone'}
                    placeholder='(00) 00000-0000'
                    id="outlined-basic"
                    variant="outlined"
                    helperText={errors.phone ? errors.phone.message : ''}
                    inputProps={{
                        className: 'cellphone',
                    }}
                    {...register('phone', { required: true })} />
                <TextField
                    focused={true}
                    label={'CPF'}
                    placeholder='000.000.000-00'
                    id="outlined-basic"
                    variant="outlined"
                    helperText={errors.cpf ? errors.cpf.message : ''}
                    inputProps={{
                        className: 'cpf',
                    }}
                    {...register('cpf', { required: true })} />
                <TextField
                    focused={true}
                    type={showPassword ? 'text' : 'password'}
                    label={'Senha'}
                    placeholder='Senha'
                    className='with-icon-end'
                    id="input-with-icon-textfield"
                    variant="outlined"
                    helperText={errors.password ?
                        errors.password.message : ''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)}
                                    sx={{ ...css.sxIconButtonEye }}>
                                    {showPassword ?
                                        <I icon={['fas', 'eye-slash']} /> :
                                        <I icon={['fas', 'eye']} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    {...register('password', { required: true })} />
                <TextField
                    focused={true}
                    type={showPassword ? 'text' : 'password'}
                    label={'Repita sua senha'}
                    placeholder='Repita sua senha'
                    className='with-icon-end'
                    id="input-with-icon-textfield"
                    variant="outlined"
                    helperText={errors.retype_password ?
                        errors.retype_password.message : ''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setShowPassword(!showPassword)}
                                    sx={{ ...css.sxIconButtonEye }}>
                                    {showPassword ?
                                        <I icon={['fas', 'eye-slash']} /> :
                                        <I icon={['fas', 'eye']} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    {...register('retype_password', { required: true })} />


                <Stack
                    alignItems={'start'}
                    direction={'column'}
                    sx={{ marginBottom: '1.875rem' }}>
                    <Stack
                        alignItems={'start'}
                        direction={'row'}>
                        <Checkbox
                            id='keep-logged'
                            sx={{ ...css.sxCheckbox }}
                            {...register('terms', { required: true })} />

                        <Label htmlFor='keep-logged'>
                            Ao clicar em Criar Conta,
                            voce está de acordo com
                            nossos <Typography
                                variant='caption'
                                sx={{ ...css.sxCreateAccountLink }}>
                                Termos de uso</Typography>.
                        </Label>
                    </Stack>
                    {errors.terms &&
                        <ErrorTerms>{errors.terms.message}</ErrorTerms>}
                </Stack>
                <ButtonLarge
                    {...css.sxButtonLarge}
                    type='submit'
                    text={'Criar conta'}
                    onClick={() => null} />
                <Box
                    sx={{ ...css.sxBoxEnd }}>
                    <Typography
                        variant='caption'
                        sx={{ ...css.sxAccountQuestion }}>
                        Já possui uma conta?
                    </Typography>
                    <Typography
                        variant='caption'
                        onClick={() => navigate('/login')}
                        sx={{ ...css.sxCreateAccountLink }}>
                        Faça o login
                    </Typography>
                </Box>
            </Form>
            <Snackbar
                ref={snackRef}
                message={'snackbar message'}
                severity={'success'}
            />
        </>
    );
};

export { CreateAccountForm };
