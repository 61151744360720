import React from 'react';
import { ListSalesProps } from '@interfaces/Sales';
import { ShoppingContext } from '../../../contexts/shopping/context';
import { client } from '../../../api/client';

const responseList = {
    data: [],
    message: '',
    isLoaded: false,
};

const DEFAULTS = {
    SALES: {
        LIST: responseList,
    },
};

const salesProviderHook = () => {
    const [salesList,
        setSalesList] = React.useState<ListSalesProps>(DEFAULTS.SALES.LIST);
    const { services } = client();

    const load = async () => {
        const response = await services.shopping.sales.load();
        setSalesList((prev) => prev = {
            data: response?.response.payload ?
                response?.response.payload : [],
            message: response?.response.message,
            isLoaded: !!(!response?.error),
        });
        return response;
    };

    const sales = {
        load,
        list: salesList,
        tag: {
            generate: services.shopping.sales.tag.generate,
            print: services.shopping.sales.tag.print,
        },
    };

    return { sales };
};

const useSales = () => {
    const context = React.useContext(ShoppingContext);
    return context;
};

export { salesProviderHook, useSales };
