import styled from 'styled-components';

export const Label = styled.label`
    font-size: 0.875rem;
    font-weight: 500;
    color: #010101;
    cursor: pointer;
    font-family: 'Nunito';
    margin-left: 0.25rem;
`;
