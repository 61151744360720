import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxBox: {
            height: '100%',
            position: 'absolute',
            backgroundColor: palette.common.white,
            top: 0,
            left: 0,
            right: 0,
            zIndex: 999,
        },
        sxBoxHeader: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderBottom: `0.0625rem solid ${palette.lightGrey}`,
            backgroundColor: palette.common.white,
        },
        sxIconButton: {
            padding: '1.25rem 1.125rem',
            cursor: 'pointer',
        },
        sxIconSearch: {
            padding: '0.8125rem',
            cursor: 'pointer',
        },
        sxIcon: {
            fontSize: '1.125rem',
        },
        sxBoxContent: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '0.9375rem',
        },
        sxBoxAction: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '3.125rem',
            cursor: 'pointer',
        },
        sxBoxInput: {
            width: '100%',
            margin: '0 0.625rem',
        },
        sxButtonIconClear: {
            'backgroundColor': palette.clouds,
            'fontSize': '0.875rem',
            'width': '1.75rem',
            'height': '1.75rem',
            ':hover': {
                backgroundColor: `${palette.clouds} !important`,
            },
        },
        sxIconClear: {
            fontSize: '0.875rem',
            color: palette.dark,
        },
        sxInputSearch: {
            'width': '100%',
            '& > .MuiOutlinedInput-root': {
                '& > .MuiInputBase-input': {
                    padding: '0.75rem',
                    paddingRight: '0',
                    fontSize: '0.875rem',
                    fontWeight: 700,
                },
            },
        },
        sxBoxFilter: {
            padding: '15px',
        },
        sxFilterTitle: {
            fontSize: '0.875rem',
            fontWeight: typography.fontWeightMedium,
        },
    };

    return { css };
};
