import React from 'react';
import { CancelOrderProps, ListPurchasesProps } from '@interfaces/Purchases';
import { ShoppingContext } from '../../../contexts/shopping/context';
import { client } from '../../../api/client';

const responseList = {
    data: [],
    message: '',
    isLoaded: false,
};

const DEFAULTS = {
    PURCHASES: {
        LIST: responseList,
    },
};

const purchasesProviderHook = () => {
    const [purchasesList,
        setPurchasesList] = React.useState<ListPurchasesProps>(DEFAULTS.PURCHASES.LIST);

    const load = async () => {
        const { services } = client();
        const response = await services.shopping.purchases.load();
        setPurchasesList((prev) => prev = {
            data: response?.response.payload ?
                response?.response.payload : [],
            message: response?.response.message,
            isLoaded: !!(!response?.error),
        });
        return response;
    };

    const cancelOrder = async ({ orderID }: CancelOrderProps) => {
        const { services } = client();
        const response = services.shopping.purchases.order.actions.cancel({ orderID });

        return response;
    };

    const purchases = {
        load,
        list: purchasesList,
        order: {
            actions: {
                cancel: cancelOrder,
            },
        },
    };

    return { purchases };
};

const usePurchases = () => {
    const context = React.useContext(ShoppingContext);
    return context;
};

export { purchasesProviderHook, usePurchases };
