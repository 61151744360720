import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        sxContainer: {
            default: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            },
            mobile: {
                padding: '0.9375rem',
                backgroundColor: palette.clouds,
            },
            desktop: {
                padding: '1.875rem 0.9375rem',
                marginBottom: '1.875rem',
                backgroundColor: palette.common.white,
            },
        },
        sxTitle: {
            desktop: {
                fontSize: '1.75rem',
                marginBottom: '0.625rem',
            },
            mobile: {
                fontSize: '1.125rem',
                marginBottom: '0.625rem',
            },
        },
        sxLegend: {
            desktop: {
                fontSize: '1rem',
                marginBottom: '1.875rem',
            },
            mobile: {
                fontSize: '0.875rem',
                marginBottom: '1.25rem',
            },
        },
        sxForm: {
            desktop: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                maxWidth: '36.875rem',
                width: '100%',
            },
            mobile: {
                display: 'flex',
                width: '100%',
            },
        },
        sxButton: {
            desktop: {
                'borderRadius': 0,
                'fontSize': '0.9375rem',
                'padding': '0.9375rem 0.9375rem',
                'boxShadow': 'none',
                'left': '-0.1875rem',
                'minWidth': '11rem',
                'backgroundColor': palette.dark,
                '&:hover': {
                    backgroundColor: palette.dark,
                    boxShadow: 'none',
                },
            },
            mobile: {
                'borderRadius': 0,
                'height': '2.625rem',
                'width': '100%',
                'fontSize': '0.9375rem',
                'padding': '0 0.9375rem',
                'boxShadow': 'none',
                'margin': '0.9375rem 0',
                'backgroundColor': palette.dark,
                '&:hover': {
                    backgroundColor: palette.dark,
                    boxShadow: 'none',
                },
            },
        },
        sxInput: {
            backgroundColor: palette.common.white,
            width: '100%',
            fontWeight: 700,
        },
    };
    return {
        ...css,
    };
};
