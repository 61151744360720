import { Grid, Skeleton, Stack, useMediaQuery, useTheme } from '@mui/material';


const skeletonElems = () => {
    const { typography } = useTheme();

    const rm = (px: number) => typography.pxToRem(px);
    const m = useMediaQuery('(min-width:600px)');


    const mediaQuery = {
        productImage: {
            height: rm(m ? 278 : 300),
            width: '100%',
        },
        productTitle: {
            height: 22,
            width: '40%',
        },
        productPrice: {
            height: 24,
            width: '20%',
        },
    };

    const elems = {
        product: [
            <Skeleton variant="rectangular" sx={mediaQuery.productImage} />,
            <Skeleton variant="rectangular" sx={mediaQuery.productTitle} />,
            <Skeleton variant="rectangular" sx={mediaQuery.productPrice} />,
        ],
    };
    return elems;
};

export const SkeletonProduct = () => {
    const { product } = skeletonElems();
    return (
        <Grid container spacing={3}>
            {Array.from(Array(4).keys()).map(() => (
                <Grid item lg={3} md={3} sm={6} xs={6}>
                    <Stack spacing={1}>
                        {product.map((skel) => skel)}
                    </Stack>
                </Grid>
            ))}
        </Grid>
    );
};
