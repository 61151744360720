import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { typography } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxBox: {
            'paddingBottom': '1.875rem',
            'fontFamily': typography.fontFamily,

            '& > h1': {
                width: '100%',
                fontSize: matches ? '1.375rem' : '1.125rem',
                mb: 1,
                fontWeight: typography.fontWeightBold,
            },
            '& > h2, h3': {
                width: '100%',
                fontSize: matches ? '1.25rem' : '1rem',
                mb: 1,
                fontWeight: typography.fontWeightBold,
            },
            '& > p': {
                width: '100%',
                fontSize: matches ? '1rem' : '0.875rem',
                mb: 1,
                fontWeight: typography.fontWeightRegular,
            },
            '& > h4, h5, h6': {
                width: '100%',
                fontSize: matches ? '1rem' : '0.875rem',
                mb: 1,
                fontWeight: typography.fontWeightSemiBold,
            },
            '& > img': {
                width: '100%',
                height: 'auto',
                maxWidth: matches ? '400px' : '250px',
                mb: 1,
            },
        },
        // sxTitle: {
        // },
        // sxText: {
        //     fontSize: matches ? '1rem' : '0.875rem',
        //     fontWeight: typography.fontWeightMedium,
        // },
    };

    return { css };
};
