import {
    useMediaQuery,
    useTheme,
} from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { typography, palette } = useTheme();
    const css = {
        sxContainer: {
            paddingTop: matches ? '5rem' : '0.625rem',
            paddingBottom: matches ? '5rem' : '2.5rem',
        },
        sxBoxAccess: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        sxAccessText: {
            fontWeight: typography.fontWeightBold,
            fontSize: '1.125rem',
            padding: '0.625rem',
        },
        sxIconButtonEye: {
            fontSize: '1rem',
            padding: '1.25rem 0.9375rem',
            color: palette.dark,
        },
        sxCheckbox: {
            'color': palette.dark,
            'borderRadius': 0,
            'padding': '0',
            'width': '1.125rem',
            '&.Mui-checked': {
                color: palette.dark,
            },
        },
        sxForgotPassLink: {
            'cursor': 'pointer',
            'fontFamily': 'Nunito',
            'textDecoration': 'none',
            'fontSize': '0.875rem',
            'fontWeight': 700,
            'color': palette.dark,
            ':hover': {
                textDecoration: 'underline',
            },
        },
        sxFirstAccessLink: {
            'cursor': 'pointer',
            'textDecoration': 'none',
            'fontSize': '0.9375rem',
            'fontWeight': 700,
            'fontFamily': 'Nunito',
            'color': palette.dark,
            'display': 'flex',
            'alignItems': 'center',
            'padding': '0.3125rem 0.625rem',
            'marginBottom': '0.9375rem',
            ':hover': {
                textDecoration: 'underline',
            },
        },
        sxBoxEnd: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '1.875rem',
        },
        sxButtonLarge: {
            backgroundColor: palette.dark,
            color: palette.common.white,
        },
        sxAccountQuestion: {
            fontSize: '0.875rem',
            fontWeight: typography.fontWeightMedium,
        },
        sxChevronRight: {
            fontSize: '0.75rem',
            marginLeft: '0.3125rem',
        },
        sxCreateAccountLink: {
            'cursor': 'pointer',
            'textDecoration': 'none',
            'fontFamily': 'Nunito',
            'fontSize': '0.875rem',
            'fontWeight': typography.fontWeightBold,
            ':hover': {
                textDecoration: 'underline !important',
            },
        },
        sxStackRegion: {
            top: 10,
            border: `0.0625rem solid ${palette.clouds}`,
            borderRadius: '0.1875rem',
            boxShadow: '0rem 0.1875rem 0.375rem #00000010',
        },
        sxButtonRegion: {
            'fontWeight': typography.fontWeightBold,
            'fontSize': '1rem',
            'color': palette.dark,
            ':hover': {
                backgroundColor: palette.common.white,
            },
            'padding': '0.625rem 1.375rem',
            'justifyContent': 'start',
            'cursor': 'default',
            'opacity': '0.5',
        },
        sxButtonRegionSelected: {
            justifyContent: 'center',
            borderRight: '1px solid #c4c4c4',
            marginRight: '0.375rem',
            height: '2.25rem',
        },
    };

    return { css };
};
