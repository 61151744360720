/* eslint-disable indent */
/* eslint-disable max-len */
import React from 'react';
import {
    Box,
    Container,
    Fade,
    Grid,
    Skeleton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { sx } from './sx';
import { Headline, Product, Filter, MessageBox, FixedMenu, ButtonInterest } from '@components';
import {
    utilVisibilityFilters,
} from '../../hooks/filters/utils/visibility-filters';
import { filterLength, handleActiveFilterName, handleActiveFilterValues, images } from '@utils';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useAds, useFilters, useOthers } from '@hooks';
// import { BrandsFilter } from '../../filters/results/brands';
// import { LocationFilter } from '../../filters/results/location';
import { CategoriesFilter } from '../../filters/results/categories';
import { SizesFilter } from '../../filters/results/sizes';
import { OrderByFilter } from '../../filters/results/order-by';
import { PriceFilter } from '../../filters/results/price';
import { FormStaticProps, ListAdsProps, ResultAdsProps } from '@interfaces';
import { BoxActiveFiltersList } from './styles';
import { ButtonSelectedFilter } from '../../components/filters';
import { ButtonResetFilters } from '../../components/filters/products/filter/buttons/reset-filters';
import { orderByList } from '@static/filter-orderby';
import { filterPriceList } from '@static/filter-price';
import { FilterDataProps } from '@interfaces/Contexts';

const Results = () => {
    // const brandsRef = React.useRef<FormStaticProps>(null);
    // const locationRef = React.useRef<FormStaticProps>(null);
    const categoriesRef = React.useRef<FormStaticProps>(null);
    const sizesRef = React.useRef<FormStaticProps>(null);
    const orderByRef = React.useRef<FormStaticProps>(null);
    const priceRef = React.useRef<FormStaticProps>(null);

    const { search, ad, loadBrands, loadAllSizes, loadShippingOptions } = useAds();
    const params = useParams();
    const filter = useFilters();
    const { categories, locations } = useOthers();
    const [searchParams] = useSearchParams();

    const urlSearch = new URLSearchParams(window.location.search);
    const { palette, typography } = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx();
    const { mobileFilters, desktopFilters } = utilVisibilityFilters();

    const [keySearch, setKeySearch] = React.useState<string>();
    const [searchResult, setSearchResult] =
        React.useState<ResultAdsProps>({
            isLoaded: false,
            ads: [] as ListAdsProps[],
        });
    const [limit, setLimit] = React.useState<number>(8);

    const searchAds = async (query: string) => {
        setSearchResult((prev) => prev = {
            ...prev,
            isLoaded: true,
        });

        const response = await search({
            query: query,
            searchFor: 'ads',
            limit: limit,
        });

        if (response && !response.error) {
            setSearchResult({
                ads: response.response.payload,
                isLoaded: false,
            });
        } else {
            setSearchResult((prev) => prev = {
                ads: [] as ListAdsProps[],
                isLoaded: false,
            });
        }
    };

    const listStates = async () => {
        return await locations.load.states();
    };

    const setURLParams = (key: string, value: string | undefined) => {
        const search = filter.handleSetURLParams(key, value);
        navigate({ search });
    };

    const removeFilter = (type: string) => {
        filter.add({ type: type, data: [] });
        setURLParams(type, undefined);
    };

    const removeAllFilters = () => {
        for (let i = 0; i < filter.filtersTypeList.length; i++) {
            filter.add({ type: filter.filtersTypeList[i], data: [] });
            setURLParams(filter.filtersTypeList[i], undefined);
        };
        setURLParams('pricemin', undefined);
        setURLParams('pricemax', undefined);
        setURLParams('state', undefined);
        setURLParams('city', undefined);
    };

    React.useEffect(() => {
        (async () => {
            const response = await filter.url.parseURLParams({ searchParams: searchParams });
            const query = filter.filterQuery(response.data);

            test();
            if (location.search.includes('quickLinkId')) {
                return await searchAds('&quickLinkId=647e45594baed477ae82c541');
            } else {
                const key = urlSearch.get('key');
                if (params.key) {
                    setKeySearch(params.key);
                } else if (key) {
                    setKeySearch(key);
                }
                setLimit(8);
                return await searchAds((params.key ? `&key=${params.key}` : '') + query);
            }
        })();
    }, [location]);

    React.useEffect(() => {
        (async () => {
            test();
            const response = await filter.url.parseURLParams({ searchParams: searchParams });
            const query = filter.filterQuery(response.data);
            // if (location.search.includes('key')) {
            const key = urlSearch.get('key');
            if (params.key) {
                setKeySearch(params.key);
            } else if (key) {
                setKeySearch(key);
            }
            await searchAds((params.key ? `&key=${params.key}` : '') + query);
            // };
        })();
    }, [limit]);

    React.useEffect(() => {
        if (urlSearch.get('key') && Array.from(urlSearch).length === 1) {
            setTimeout(() => {
                for (let i = 0; i < filter.filtersTypeList.length; i++) {
                    filter.add({ type: filter.filtersTypeList[i], data: [] });
                    setURLParams(filter.filtersTypeList[i], undefined);
                };
            }, 20);
        }
        if (!ad.brand.isLoaded &&
            !ad.brand.data.length) {
            loadBrands();
            filter.add({ type: 'brands', data: filter.tempFilters.brand });
            // checkQueryParams({ type: 'conditions' });
        }
        if (!ad.sizes.isLoaded &&
            !ad.sizes.data.length) {
            loadAllSizes();
        }
        if (!ad.deliveryTypes.isLoaded &&
            !ad.deliveryTypes.data.length) {
            loadShippingOptions();
        }
        if (!ad.sizes.isLoaded) {
            loadAllSizes();
            filter.add({ type: 'size', data: filter.tempFilters.size });
        }
        if (!categories.list.categories.length) {
            categories.handleCategorization({});
            filter.add({
                type: 'category',
                data: filter.tempFilters.category,
            });
        }
    }, []);

    React.useEffect(() => {
        filter.add({ type: 'price', data: filter.tempFilters.price });
    }, [filter.tempFilters.price]);

    React.useEffect(() => {
        if (filter.tempFilters.orderby.length > 0) {
            filter.add({ type: 'orderby', data: filter.tempFilters.orderby });
        }
    }, [filter.tempFilters.orderby]);

    React.useEffect(() => {
        filter.add({ type: 'locations', data: filter.tempFilters.locations });
    }, [filter.tempFilters.locations]);

    React.useEffect(() => {
        listStates();
    }, []);

    const onlyTrueListElems = (list: any[]) => {
        return list.filter((el) => el !== undefined);
    };

    const test = async () => {
        const response = await filter.url.parseURLParams({ searchParams: searchParams });

        if (response.data.length > 0) {
            const category = response.data.map((item) => {
                if (item.filterName === 'category') {
                    return {
                        _id: item.value,
                        nome: item.name,
                    };
                };
            });

            const orderby = response.data.map((item) => {
                if (item.filterName === 'orderby') {
                    const res = orderByList.find((i) => i.value === item.value);
                    return {
                        _id: res?._id,
                        nome: res?.nome,
                        param: 'o=',
                        filter: item.value,
                    };
                };
            });

            const location = response.data.map((item) => {
                if (item.filterName === 'state') {
                    return {
                        _id: item.value,
                        nome: item.name,
                        filter: item.name,
                    };
                };

                if (item.filterName === 'city') {
                    return {
                        _id: item.value,
                        nome: item.name,
                    };
                };
            });

            const price = response.data.map((item) => {
                const res = filterPriceList.find((i) => i.nome === item.name);

                if (item.filterName === 'pricemin') {
                    return {
                        _id: res?._id,
                        nome: item.name,
                        filter: +item.value,
                    };
                };

                if (item.filterName === 'pricemax') {
                    return {
                        _id: res?._id,
                        nome: item.name,
                        filter: +item.value,
                    };
                };
            });

            // const brand = response.data.map((item) => {
            //     if (item.filterName === 'brand') {
            //         return {
            //             _id: item.value,
            //             nome: item.name,
            //         };
            //     };
            // });

            const size = response.data.map((item) => {
                if (item.filterName === 'size') {
                    return {
                        _id: item.value,
                        nome: item.name,
                        filter: 'size',
                    };
                };
            });

            filter.add({
                type: 'category',
                data: onlyTrueListElems(category).length > 0 ? onlyTrueListElems(category) : [] as FilterDataProps[],
            });

            filter.add({
                type: 'orderby',
                data: onlyTrueListElems(orderby).length > 0 ? onlyTrueListElems(orderby) : [] as FilterDataProps[],
            });

            filter.add({
                type: 'locations',
                data: onlyTrueListElems(location).length > 0 ? onlyTrueListElems(location) : [] as FilterDataProps[],
            });

            filter.add({
                type: 'price',
                data: onlyTrueListElems(price).length > 0 ? onlyTrueListElems(price) : [] as FilterDataProps[],
            });

            // filter.add({
            //     type: 'brand',
            //     data: onlyTrueListElems(brand).length > 0 ? onlyTrueListElems(brand) : [] as FilterDataProps[],
            // });

            filter.add({
                type: 'size',
                data: onlyTrueListElems(size).length > 0 ? onlyTrueListElems(size) : [] as FilterDataProps[],
            });
        };
    };

    React.useEffect(() => {
        test();
    }, []);

    return (
        <Box>
            {keySearch &&
                <Box
                    pt={matches ? 4 : 2}
                    pb={matches ? 4 : 2}
                    sx={{
                        backgroundColor: palette.grey[100],
                    }}>
                    <Container maxWidth='lg'>
                        <Headline size={matches ? 'big' : 'medium'}
                            title={
                                `Resultados para
                                        "${keySearch}"`}
                            paddingBottom />
                    </Container>
                </Box>
            }

            <Filter.Product filters={{
                mobile: mobileFilters,
                desktop: desktopFilters,
                filtersList: [
                    {
                        id: 'd3de169427b4',
                        name: 'Categorias',
                        key: 'categorias',
                        historyFilters: handleActiveFilterValues('category', filter.filters) as any,
                        active: filterLength('category', filter.filters),
                        filterContent: <CategoriesFilter
                            filterInputsType='checkbox'
                            minHeightContent={114}
                            ref={categoriesRef}
                            onSubmitAction={() => {
                                categoriesRef.current?.onSubmit(); desktopFilters.afterSubmit(); setTimeout(() => mobileFilters.
                                    handleVisibilityMobileFilters({
                                        action: 'close',
                                        type: 'all',
                                    }), 100);
                            }}
                        />,
                        enabled: true,
                        order: 1,
                    },
                    {
                        id: 'd3de169421b1',
                        name: 'Tamanhos',
                        key: 'tamanhos',
                        historyFilters: handleActiveFilterValues('size', filter.filters) as any,
                        active: filterLength('size', filter.filters),
                        filterContent: <SizesFilter
                            filterInputsType='checkbox'
                            minHeightContent={106}
                            ref={sizesRef}
                            onSubmitAction={() => {
                                sizesRef.current?.onSubmit(); desktopFilters.afterSubmit(); setTimeout(() => mobileFilters.
                                    handleVisibilityMobileFilters({
                                        action: 'close',
                                        type: 'all',
                                    }), 100);
                            }}
                        />,
                        enabled: true,
                        order: 2,
                    },
                    // {
                    //     id: 'd3de425421c2',
                    //     name: 'Marcas',
                    //     key: 'marcas',
                    //     historyFilters: handleActiveFilterValues('brand', filter.filters) as any,
                    //     active: filterLength('brand', filter.filters),
                    //     filterContent: <BrandsFilter
                    //         filterInputsType='checkbox'
                    //         minHeightContent={155}
                    //         ref={brandsRef}
                    //         onSubmitAction={() => {
                    //             brandsRef.current?.onSubmit(); desktopFilters.afterSubmit();
                    //         }}
                    //     />,
                    //     enabled: true,
                    //     order: 3,
                    // },
                    {
                        id: 'a7d238a26847',
                        name: 'Ordenar por',
                        key: 'ordenar-por',
                        historyFilters: handleActiveFilterValues('orderby', filter.filters) as any,
                        active: filterLength('orderby', filter.filters),
                        filterContent: <OrderByFilter
                            filterInputsType='checkbox'
                            minHeightContent={114}
                            ref={orderByRef}
                            onSubmitAction={() => {
                                orderByRef.current?.onSubmit(); desktopFilters.afterSubmit(); setTimeout(() => mobileFilters.
                                    handleVisibilityMobileFilters({
                                        action: 'close',
                                        type: 'all',
                                    }), 100);
                            }}
                        />,
                        enabled: false,
                        order: 5,
                    },
                    // {
                    //     id: 'd3de823459c2',
                    //     name: 'Estado/Cidades',
                    //     key: 'estadocidades',
                    //     historyFilters: handleActiveFilterValues('locations', filter.filters) as any,
                    //     active: filterLength('locations', filter.filters),
                    //     filterContent: <LocationFilter
                    //         filterInputsType='checkbox'
                    //         minHeightContent={115}
                    //         maxHeight={matches ? 185 : 'none'}
                    //         ref={locationRef}
                    //         onSubmitAction={() => {
                    //             locationRef.current?.onSubmit(); desktopFilters.afterSubmit(); setTimeout(() => mobileFilters.
                    //             handleVisibilityMobileFilters({
                    //                 action: 'close',
                    //                 type: 'all',
                    //             }), 100);
                    //         }}
                    //     />,
                    //     enabled: true,
                    //     order: 4,
                    // },
                    {
                        id: 'f13dba6a7361',
                        name: 'Preço',
                        key: 'preco',
                        historyFilters: handleActiveFilterValues('price', filter.filters) as any,
                        active: filterLength('price', filter.filters),
                        filterContent: <PriceFilter
                            filterInputsType='checkbox'
                            minHeightContent={114}
                            ref={priceRef}
                            onSubmitAction={() => {
                                priceRef.current?.onSubmit(); desktopFilters.afterSubmit(); setTimeout(() => mobileFilters.
                                    handleVisibilityMobileFilters({
                                        action: 'close',
                                        type: 'all',
                                    }), 100);
                            }}
                        />,
                        enabled: true,
                        order: 3,
                    },
                ],
            }} />
            {filter.filtersDataLenght > 0 &&
                <Container maxWidth='lg'>
                    <Box display='flex' alignItems='center' pt={0} pb={1}>
                        <Typography
                            variant='h2'
                            component='h2'
                            fontSize={10}
                            fontWeight={typography.fontWeightBold}>
                            Filtros<br />
                            Ativos:
                        </Typography>
                        <BoxActiveFiltersList>
                            {filterLength('brand', filter.filters) && (
                                <ButtonSelectedFilter
                                    onClick={() => removeFilter('brand')}
                                    title={handleActiveFilterName('brand', filter.filters, 'Marcas', 'Marca')}
                                    value={handleActiveFilterValues('brand', filter.filters)} />
                            )}

                            {filterLength('category', filter.filters) && (
                                <ButtonSelectedFilter
                                    onClick={() => removeFilter('category')}
                                    title={handleActiveFilterName('category', filter.filters, 'Categorias', 'Categoria')}
                                    value={handleActiveFilterValues('category', filter.filters)} />
                            )}
                            {filterLength('locations', filter.filters) && (
                                <ButtonSelectedFilter
                                    onClick={() => {
                                        removeFilter('locations');
                                        removeFilter('state');
                                        removeFilter('city');
                                    }}
                                    title={handleActiveFilterName('locations', filter.filters, 'Estado/Cidades', 'Estado/Cidade')}
                                    value={handleActiveFilterValues('locations', filter.filters)} />
                            )}
                            {filterLength('orderby', filter.filters) && (
                                <ButtonSelectedFilter
                                    onClick={() => removeFilter('orderby')}
                                    title={handleActiveFilterName('orderby', filter.filters, 'Ordenar por', 'Ordenar por')}
                                    value={handleActiveFilterValues('orderby', filter.filters)} />
                            )}
                            {filterLength('price', filter.filters) && (
                                <ButtonSelectedFilter
                                    onClick={() => {
                                        removeFilter('price');
                                        removeFilter('pricemin');
                                        removeFilter('pricemax');
                                    }}
                                    title={handleActiveFilterName('price', filter.filters, 'Preço', 'Preço')}
                                    value={handleActiveFilterValues('price', filter.filters)} />
                            )}
                            {filterLength('size', filter.filters) && (
                                <ButtonSelectedFilter
                                    onClick={() => removeFilter('size')}
                                    title={handleActiveFilterName('size', filter.filters, 'Tamanho', 'Tamanhos')}
                                    value={handleActiveFilterValues('size', filter.filters)} />
                            )}
                            <ButtonResetFilters
                                text='Limpar filtros'
                                minWidth={120}
                                onClick={removeAllFilters}
                            />
                        </BoxActiveFiltersList>
                    </Box>
                </Container>
            }

            <Container maxWidth='lg' sx={css.products.container}>
                <Fade in={desktopFilters.open}>
                    <Box sx={css.products.overlayProductsGrid} />
                </Fade>
                {searchResult.ads.length > 0 &&
                    <Grid container spacing={2}>
                        {searchResult.ads.map((ad) => (
                            <Grid key={ad._id} item md={3} xs={6}>
                                <Product
                                    key={ad._id}
                                    stockQuantity={ad.variacoes.itens[0].quantidade_estoque}
                                    favorite={ad.isFavorite}
                                    _id={ad.variacoes._id}
                                    _idAd={ad._id}
                                    mode='buy'
                                    size={'fluid'}
                                    align={'bottom'}
                                    src={images.load(ad.variacoes.fotos[0], 'server')}
                                    title={`${ad.titulo}`}
                                    legend={ad.usuario.minha_loja ?
                                        ad.usuario.minha_loja.titulo :
                                        ''}
                                    price={String(ad.variacoes.itens[0].valor_por)}
                                    itemVariationId={ad.variacoes.itens[0]._id}
                                    to={() => navigate('/produto/' + ad._id + '?variantion=' + ad.variacoes._id)} />
                            </Grid>
                        ))}
                    </Grid>}
                {!searchResult.isLoaded && searchResult.ads.length === 0 &&
                    <MessageBox
                        type='info'
                        // eslint-disable-next-line max-len
                        message='Nenhum produto encontrado.'
                    />
                }
                {searchResult.isLoaded &&
                    <Grid container spacing={2}>
                        {[0, 1, 2, 3].map((i) => (
                            <Grid key={`${i}`} item md={3} xs={6}>
                                <Skeleton
                                    variant="rectangular"
                                    width={'100%'}
                                    height={matches ? 278 : 160} />
                                <Skeleton variant="rectangular"
                                    sx={{ mt: 2 }} width={'100%'} height={80} />
                            </Grid>
                        ))}
                    </Grid>
                }

                {/* {products.map(({ title, price, src, legend }, i) => (
                        <Grid item md={3} xs={6}>
                            <Product
                                key={`${i}-${title}`}
                                mode='buy'
                                size={'fluid'}
                                align={'bottom'}
                                src={src}
                                title={title}
                                legend={legend}
                                price={price} />
                        </Grid>
                    ))}
                </Grid> */}
                {!searchResult.isLoaded && limit <= searchResult.ads.length &&
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}>
                        <ButtonInterest
                            text={'CARREGAR MAIS'}
                            margin={'1.875rem'}
                            color={palette.common.white}
                            backgroundColor={palette.dark}
                            padding={'0.5rem 1.5625rem'}
                            onClick={() => setLimit(limit + 8)} />
                    </Box>
                }
            </Container >
            {!matches && <FixedMenu />}
        </Box >
    );
};

export { Results };
