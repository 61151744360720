import React from 'react';
import { Link } from 'react-router-dom';
import { Box, IconButton, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { RelatedPostProps } from '@interfaces';

import { sx } from './sx';
import { Image } from './styles';

const RelatedPostLink: React.FC<RelatedPostProps> = ({
    src, title, link,
}: RelatedPostProps) => {
    const { css } = sx();

    return (
        <Link to={link} style={{ ...css.sxLink }}>
            <Box sx={{ ...css.sxBoxInfo }}>
                <Image src={src} alt="" />
                <Typography
                    sx={{ ...css.sxTitlePost }}>
                    {title}
                </Typography>
            </Box>
            <IconButton sx={{ ...css.sxIconButton }}>
                <I
                    icon={['far', 'chevron-right']} />
            </IconButton>
        </Link>
    );
};

export { RelatedPostLink };
