/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import { AddressBox, Snackbar } from '@components';
import { Box, Button, CircularProgress, Container, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '../../../../../hooks/user';
import { addressFormat } from '../../../../../utils/addressFormat';
import { ToggleStaticProps } from '@interfaces';
import { useCart } from '../../../../../hooks/shopping/cart';
import { Address } from '@interfaces/Contexts';

const CheckoutAddress = () => {
    const { palette, typography } = useTheme();
    const { cart } = useCart();
    const { user, handleAddress } = useUser();
    const { search } = useLocation();
    // const urlSearch = new URLSearchParams(search);
    const rem = (px: number) => typography.pxToRem(px);
    const navigate = useNavigate();
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const [selectedValue, setSelectedValue] = React.useState('');
    const [currentAddress, setCurrentAddress] = React.useState<Address>({} as Address);
    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = React.useState(false);

    const existsAddress = (user.data &&
        user.data.endereco &&
        user.data.endereco.length === 0);

    const handleChangeAddress = async (address: Address) => {
        setSelectedValue(address._id);
        setCurrentAddress((prev) => prev = address);
        setSearchParams(`?shippingAddress=${address._id}`);
    };

    const handleContinue = async () => {
        setIsLoading((prev) => prev = true);
        if (selectedValue) {
            await handleAddress({
                action: 'update',
                address: {
                    ...currentAddress,
                    addressId: currentAddress._id,
                    principal: true,
                },
            });
            await cart.calculateShipping();
            await cart.load();
            history.pushState({}, '', `?shippingAddress=${selectedValue}`);
            navigate(`/checkout?shippingAddress=${selectedValue}`);
            setIsLoading((prev) => prev = false);
        } else {
            snackRef.current?.show();
            setIsLoading((prev) => prev = false);
        }
    };

    const findAddressByID = () => {
        const addressId = searchParams.get('shippingAddress');

        if (addressId) {
            const mainAddress = user.data?.endereco.find((address) => address._id === addressId);
            return mainAddress as Address;
        }
    };

    const findMainAddress = () => {
        const mainAddress = user.data &&
            user.data.endereco &&
            user.data.endereco.length > 0 &&
            user.data?.endereco.find((address) => address.principal === true);
        return mainAddress as Address;
    };

    React.useEffect(() => {
        const addressId = searchParams.get('shippingAddress');

        if (addressId) {
            if (user.data &&
                user.data.endereco &&
                user.data.endereco.length > 0) {
                const addressByID = findAddressByID();
                setCurrentAddress((prev) => prev = addressByID as Address);
            }
        }
    }, [user.data?.endereco]);

    React.useEffect(() => {
        const addressId = searchParams.get('shippingAddress');

        if (addressId) {
            setSelectedValue(addressId);
        } else {
            const mainAddress = findMainAddress();
            setCurrentAddress((prev) => prev = mainAddress as Address);
            mainAddress && setSelectedValue(mainAddress._id);
        }
    }, []);

    return (
        <Container maxWidth="xs">
            <Box pt={rem(30)} pb={rem(30)}>
                <Typography
                    variant="body1"
                    fontWeight={typography.fontWeightBold}
                    color={palette.dark}
                    mb={rem(5)}>
                    Endereço de entrega
                </Typography>

                <Typography
                    variant="subtitle2"
                    fontWeight={typography.fontWeightMedium}
                    color={palette.dark}>
                    Confirme seu endereço de entrega.
                </Typography>
                {user.data &&
                    user.data.endereco &&
                    user.data.endereco.length > 0 &&
                    user.data?.endereco.map((address, i) => (
                        <Box key={address._id} sx={{ mt: 2, mb: 2 }}>
                            <AddressBox
                                mode={'list'}
                                noIcon
                                nickname={`${address.apelido} ${address.principal ? '- Principal' : ''}`}
                                title={`CEP: ${address.cep}`}
                                radio={{
                                    // defaultChecked: address.principal,
                                    checked: selectedValue === address._id,
                                    value: i + 1,
                                    onChange: () => handleChangeAddress(address),
                                    name: 'addres',
                                }}
                                legend={addressFormat({
                                    logradouro: address.logradouro,
                                    bairro: address.bairro,
                                    cidade: address.cidade,
                                    estado: address.estado,
                                    numero: Number(address.numero),
                                    complemento: address.complemento,
                                })}
                            />
                        </Box>
                    ))}
                <Box sx={{ mt: 2, mb: 2 }}>
                    <AddressBox
                        mode={'add'}
                        title={'Adicionar Endereço'}
                        legend={'Clique para adicionar ' +
                            'um novo endereço'}
                        onClick={() => {
                            navigate('/checkout/endereco/adicionar');
                        }} />
                </Box>
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    type='submit'
                    className='MuiButton secondary'
                    disabled={existsAddress || isLoading}
                    onClick={() => handleContinue()}>
                    Continuar {isLoading &&
                        <CircularProgress
                            size={14}
                            color='inherit'
                            sx={{ ml: 0.5 }} />}
                </Button>
            </Box>
            <Snackbar
                ref={snackRef}
                message={'Selecione um endereço para continuar'}
                severity={'warning'}
            />
        </Container>
    );
};

export { CheckoutAddress };
