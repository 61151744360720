import styled from 'styled-components';

export const Button = styled.button`
    padding: 0;
    border-radius: 1.5625rem;
    border: none;
    display: flex;
    align-items: center;
    background-color: #2B4477;
    cursor: pointer;
    margin-top: 0.3125rem;
    margin-bottom: 1.25rem;
`;

export const Circle = styled.div`
    height: 2.875rem;
    width: 2.875rem;
    border-radius: 50%;
    background-color: #4161A2;
    display: flex;
    align-items: center;
    justify-content: center;
`;
