import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        sxBox: (borderBottom: boolean, paddingLeft: boolean, borderTop: boolean) => {
            return {
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: paddingLeft ? 2 : 0,
                borderBottom: borderBottom ?
                    `0.0625rem solid ${palette.clouds}` :
                    0,
                borderTop: borderTop ?
                    `0.0625rem solid ${palette.clouds}` :
                    0,
            };
        },
        sxBoxChildren: {
            padding: '0.9375rem 0',
            paddingRight: '.625rem',
            width: '100%',
        },
        sxBoxActions: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            maxWidth: '80px',
        },
        sxIconButton: {
            minHeight: '3.75rem',
            minWidth: '2.875rem',
        },
        handleIconAction: {
            addToCart: {
                icon: ['fal', 'bag-shopping'],
                color: palette.dark,
            },
            edit: {
                icon: ['fal', 'pen-to-square'],
                color: palette.dark,
            },
            delete: {
                icon: ['fal', 'trash-can'],
                color: palette.pomegarnate,
            },
            disable: {
                icon: ['fal', 'power-off'],
                color: palette.dark,
            },
            change: {
                icon: ['fal', 'pen-to-square'],
                color: palette.dark,
            },
            goTo: {
                icon: ['far', 'chevron-right'],
                color: palette.dark,
            },
        },
    };

    return { css };
};
