import { UploadImagePreviewType } from '@interfaces';
import { useTheme } from '@mui/material';

const sx = () => {
    const { palette } = useTheme();

    const css = {
        wrapper: (type: UploadImagePreviewType, height: number) => {
            return {
                height: height ?? 85,
                width: '100%',
                borderRadius: '.1875rem',
                overflow: 'hidden',
                borderWidth: type === 'upload' ? 1 : 0,
                borderColor: palette.inputBorderColor,
                borderStyle: 'dashed',
                display: 'block',
            };
        },
        iconWrapper: {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: palette.common.white,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        icon: {
            height: 20,
            width: 20,
            backgroundColor: palette.common.white,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: -8,
            right: -8,
            borderRadius: 100,
        },
    };
    return { css };
};

export { sx };
