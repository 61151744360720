/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable max-len */
import React, { RefAttributes } from 'react';
import { FormStaticProps } from '@interfaces';
import {
    Box,
    Container,
    FormHelperText,
    Grid,
    IconButton,
    Typography,
    useTheme,
} from '@mui/material';
import { ForwardRefExoticComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { FilterContent, FilterFooter } from '../../../../../../../components/filters';
import { useAds } from '@hooks';
import { images } from '@utils';
import { AdColorsProps } from '@interfaces/Contexts';
import { FilterFormType } from '@types';
import { sx } from './sx';
import { Image } from '@components';

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

interface FormAddVariantProps {
    color: FilterFormType;
    colorName: string;
}

interface ComponentProps {
    children: React.ReactNode;
    onClearAction?: () => void;
    onSubmitAction?: () => void;
}

export const FormAddColor: ForwardRefExoticComponent<ComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            // children,
            onClearAction,
            onSubmitAction,
        } = props;

        const { palette, typography } = useTheme();
        const { css } = sx();
        const { variationsAD, externalActions } = useAds();
        const [tempColors, setTempColors] = React.useState<AdColorsProps>(
            variationsAD.colors.selected.list.imagem ?
                variationsAD.colors.selected.list : {} as AdColorsProps,
        );
        // const [tempColors, setTempColors] = React.useState<AdColorsProps>(
        //     variations.colors.data.list.color.imagem ?
        //         variations.colors.data.list.color : {} as AdColorsProps,
        // );
        // const [tempCustomColors] = React.useState<AdColorsProps>(
        //     Object.keys(variationsAD.colors.customColor.list).length > 0 ?
        //         variationsAD.colors.selected.list : {} as AdColorsProps,
        // );
        const [uploadedColorName, setUploadedColorName] = React.useState(
            variationsAD.colors.selected.list.customColor ?
                variationsAD.colors.selected.list.nome : '',
        );

        const customArr = variationsAD.colors.list.data;
        // const customArr = Object.keys(tempCustomColors).length > 0 ?
        //     [...variationsAD.colors.list.data, tempCustomColors] :
        //     [...variationsAD.colors.list.data];

        const {
            register,
            setValue,
            reset,
            handleSubmit,
            formState: { errors },
        } =
            useForm<FormAddVariantProps>({
                mode: 'all',
            });

        const handleUploadedColorName = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;
            setUploadedColorName((prev) => prev = value);
            setTempColors((prev) => {
                // const find = prev.find((is) => is.customColor);
                if (prev.customColor) {
                    return {
                        ...prev,
                        nome: value,
                    };
                }
                return prev as any;
            });
        };

        const handleColors = ({ _id, nome, imagem, customColor }: AdColorsProps) => {
            setTempColors((prev) => prev = {
                _id,
                nome,
                imagem,
                customColor,
            });
            setValue('color', nome);
        };

        const resetData = () => {
            setTempColors((prev) => prev = {} as AdColorsProps);
            reset({ color: [], colorName: '' });
            variationsAD.colors.customColor.remove();
            variationsAD.colors.selected.clear();
        };

        const onSubmit: SubmitHandler<FormAddVariantProps> = async (data) => {
            variationsAD.colors.selected.add({
                _id: tempColors._id,
                imagem: tempColors.imagem,
                nome: tempColors.nome,
                customColor: tempColors.customColor,
            });
            externalActions.action({ action: 'colorsDialog', state: true });

            // if (tempColors.customColor) {
            // } else {
            //     variationsAD.colors.selected.add({
            //         _id: tempColors._id,
            //         imagem: tempColors.imagem,
            //         nome: tempColors.nome,
            //         customColor: tempColors.customColor,
            //     });
            // }
        };

        const removeCustomColorImage = () => {
            variationsAD.colors.customColor.remove();
            variationsAD.colors.selected.clear();
            resetData();
            // if (uploadref.current?.value) {
            //     variationsAD.colors.customColor.remove();
            //     return uploadref.current.value = '';
            // };
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        React.useEffect(() => {
            if (!variationsAD.colors.list.isLoaded &&
                !variationsAD.colors.list.data.length) {
                variationsAD.colors.load();
            }
        }, []);

        return (
            <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)} pt={2}>
                <FilterContent minHeightContent={61}>
                    <Container maxWidth='sm'>

                        {errors.color && (
                            <FormHelperText error>Selecione uma cor</FormHelperText>
                        )}

                        <Typography
                            variant="h1"
                            fontSize={16}
                            fontWeight={typography.fontWeightBold}
                            color={palette.dark}
                            mb={1}
                        >
                            Cores
                        </Typography>
                        <Grid container spacing={1}>
                            {variationsAD.colors.list.isLoaded ? customArr.map(({ _id, nome, imagem }, i) => (
                                <Grid key={_id ? _id : 'custom-' + i} item md={3} xs={4} mb={1}>
                                    <Box sx={css.colorsBox}
                                        component='label'
                                        htmlFor={_id ? _id : 'custom-' + i}>
                                        <Box sx={{
                                            position: 'absolute',
                                            height: '100%',
                                            width: '100%',
                                            top: 0,
                                            left: 0,
                                            cursor: 'pointer',
                                        }}>
                                            <Box sx={{
                                                ...{
                                                    height: '24px',
                                                    width: '24px',
                                                    backgroundColor: 'white',
                                                    borderRadius: '100px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    position: 'absolute',
                                                    top: typography.pxToRem(5),
                                                    right: typography.pxToRem(5),
                                                },
                                                '& > input': {
                                                    margin: 0,
                                                },
                                            }}>
                                                <input
                                                    type='radio'
                                                    id={_id ? _id : 'custom-' + i}
                                                    value={_id ? _id : 'custom-' + i}
                                                    {...register('color',
                                                        {
                                                            required: true,
                                                        })}
                                                    checked={!!(tempColors._id === _id)}
                                                    onChange={() => handleColors({
                                                        _id,
                                                        imagem,
                                                        nome,
                                                        customColor: false,
                                                    })} />
                                            </Box>
                                        </Box>
                                        <Image src={images.load(
                                            imagem, 'server')} maxHeight={80} />

                                        <Box sx={{
                                            paddingTop: 1,
                                            paddingBottom: 1,
                                            paddingLeft: 1,
                                            paddingRight: 1,
                                        }}>

                                            <Typography
                                                variant="h1"
                                                fontSize={12}
                                                align='center'
                                                fontWeight={typography.fontWeightBold}
                                                color={palette.dark}
                                            >
                                                {nome}
                                            </Typography>
                                        </Box>
                                    </Box>

                                </Grid>
                            )) : '...'}
                        </Grid>

                        <Box mt={typography.pxToRem(30)}>
                            <Typography
                                variant="h1"
                                fontSize={16}
                                fontWeight={typography.fontWeightBold}
                                color={palette.dark}
                                mb={1}
                            >
                                Cor personalizada
                            </Typography>
                            {(Object.keys(variationsAD.colors.customColor.list).length > 0) && (
                                <Box sx={css.customColorBox}>
                                    <Box
                                        p={typography.pxToRem(15)}
                                        sx={css.custtomColorBoxImage}
                                        component='label'
                                        htmlFor='custom-image'>
                                        <IconButton
                                            onClick={removeCustomColorImage}
                                            aria-label="delete">
                                            <I icon={['fas', 'xmark-circle']} />
                                        </IconButton>
                                        <img
                                            src={variationsAD.colors.customColor.list.src}
                                            height={60} />
                                        <input
                                            type='radio'
                                            id='custom-image'
                                            value={uploadedColorName}
                                            {...register('color',
                                                {
                                                    required: true,
                                                })}
                                            checked={!!(tempColors.customColor)}
                                            onChange={() => handleColors({
                                                _id: variationsAD.colors.customColor.list.id,
                                                imagem: variationsAD.colors.customColor.list.src,
                                                nome: uploadedColorName,
                                                customColor: true,
                                            })} />
                                    </Box>
                                    <Box sx={css.customColorBoxTextField}>
                                        <input
                                            type='text'
                                            placeholder='Nome da cor'
                                            {...register('colorName', {
                                                required: tempColors.customColor,
                                            })}
                                            value={uploadedColorName}
                                            onChange={handleUploadedColorName} />
                                        <IconButton>
                                            <I
                                                icon={['far', 'edit']}
                                                fontSize={14}
                                                color={palette.darkGrey} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            )}
                            {errors.colorName && (
                                <FormHelperText error>Digite o nome da cor</FormHelperText>
                            )}
                            {!(Object.keys(variationsAD.colors.customColor.list).length > 0) && (
                                <Box sx={css.customColorBoxUpload}
                                    component='label'
                                    htmlFor='upload-color'>
                                    <input
                                        hidden
                                        accept="image/png,image/jpeg"
                                        type="file"
                                        id="upload-color"
                                        onChange={(e) => variationsAD.colors.customColor.add(e, 'algo')}
                                    />
                                    <Box p={typography.pxToRem(10)}>
                                        <I
                                            icon={['fas', 'cloud-arrow-up']}
                                            fontSize={28}
                                            color={palette.darkGrey} />
                                    </Box>
                                    <Typography
                                        variant="h1"
                                        fontSize={14}
                                        fontWeight={typography.fontWeightRegular}
                                        color={palette.dark}
                                    >
                                        Clique para fazer o upload da imagem
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Container>
                </FilterContent>
                {/* {children} */}
                {/* <FilterFooter
                    onClearAction={onClearAction ? onClearAction as () => void : resetData}
                    onSubmitAction={onSubmitAction ? onSubmitAction as () => void : () => { }}
                    disabledOnSubmit={!(!!tempColors.imagem)}
                /> */}
                <FilterFooter
                        onClearAction={resetData}
                        onSubmitAction={() => {}}
                        // disabledOnSubmit={!(!!tempSizes.length)}
                    />
            </Box>
        );
    });
