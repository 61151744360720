import { useTheme, alpha } from '@mui/material';

const sx = () => {
    const { palette } = useTheme();

    const css = {
        selectFilterBarButtons: {
            '& .MuiButton-outlined ': {
                '&:first-child': {
                    borderRight: '1px solid' +
                        palette.grey[300],
                },
            },
            ...{
                backgroundColor: palette.clouds,
            },
        },
        filters: {
            desktop: {
                buttonSpace: {
                    '& .MuiButton-root': {
                        ...{ m: 0.8 },
                        '&:first-child': {
                            ml: 0,
                        },
                    },
                },
            },
        },
        products: {
            overlayProductsGrid: {
                position: 'absolute',
                height: '100%',
                width: '100%',
                top: 0,
                left: 0,
                zIndex: 1,
                backgroundColor: alpha(palette.common.white, 0.8),
            },
            container: {
                position: 'relative',
                mt: 1,
                pb: 4,
            },
        },
    };

    return { css };
};

export { sx };
