export const settings = (lenght: number) => {
    return {
        className: 'slick-carousel-center',
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: lenght < 2 ? lenght : 2,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '60',
        autoplay: true,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    centerPadding: '-5',
                    slidesToShow: lenght < 2 ? lenght : 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 900,
                settings: {
                    centerPadding: '100',
                    slidesToShow: lenght < 2 ? lenght : 2,
                    slidesToScroll: 1,
                    centerMode: true,
                },
            },
            {
                breakpoint: 738,
                settings: {
                    centerPadding: '60',
                    slidesToShow: lenght < 2 ? lenght : 2,
                    slidesToScroll: 1,
                    centerMode: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: lenght < 2 ? lenght : 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '50',
                },
            },
            {
                breakpoint: 510,
                settings: {
                    slidesToShow: lenght < 2 ? lenght : 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '10',
                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: lenght < 2 ? lenght : 2,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: '-10',
                },
            },
        ],
    };
};
