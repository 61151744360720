import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Typography,
    useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { sx } from './sx';
import React from 'react';
import { useSales } from '../../../../../hooks/shopping/sales';
import { TagDetails } from '../details';
import { MessageBox } from '@components';

const TagCreate = ({ idOrder }: {idOrder: string}) => {
    const { palette } = useTheme();
    const { css } = sx();
    const { sales } = useSales();

    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
        isLoading: false,
    });
    const [generated, setGenerated] = React.useState(false);

    const generateTag = async () => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });
        const response = await sales.tag.generate({
            idOrder: idOrder,
        });

        if (!response?.error) {
            setGenerated(true);
        } else {
            setResponseData((prev) => prev = {
                ...prev,
                type: response?.response.type,
                message: response?.response.message,
                isLoading: false,
            });
        }
    };

    return (
        <>
            {!generated ?
                responseData.isLoading ?
                    <Box sx={css.box.loading}>
                        <CircularProgress color='inherit' size={24} />
                    </Box> :
                    responseData.message ?
                        <MessageBox
                            message={responseData.message}
                            type={responseData.type} /> :
                        <Box>
                            <Box sx={css.wrapper}>
                                <Box sx={css.box.content}>
                                    <I
                                        icon={['fal', 'ticket-simple']}
                                        fontSize={36}
                                        color={palette.dark}
                                    />
                                </Box>
                                <Typography
                                    variant='h2'
                                    component='p'
                                    align='center'
                                    sx={css.box.title}>
                            Clique no botão abaixo para realizar
                            a emissão da etiqueta.
                                </Typography>
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    width='100%'
                                    mt={3}>
                                    <Box
                                        maxWidth={320}
                                        width='100%'
                                        justifyContent='center'>
                                        <Button
                                            onClick={() => generateTag()}
                                            fullWidth
                                            variant="contained"
                                            size="large"
                                            type='submit'
                                            className='MuiButton primary'
                                        >
                                    Emitir Etiqueta
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </Box> :
                <TagDetails idOrder={idOrder} />
            }
        </>
    );
};

export { TagCreate };
