import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        sxBox: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingRight: 0,
            borderBottom: `0.0625rem solid ${palette.lightGrey}`,
        },
        sxIconButton: {
            default: {
                padding: '1rem 0.625rem',
                paddingLeft: '1rem',
                marginRight: '0.3125rem',
            },
            center: {
                padding: '1.25rem 1.125rem',
                cursor: 'pointer',
                zIndex: '500',
            },
        },
        sxBoxContent: {
            display: 'flex',
            alignItems: 'center',
            marginRight: '0.9375rem',
        },
        sxBoxAction: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '3.125rem',
            cursor: 'pointer',
        },
        sxBoxTitle: {
            default: {
                display: 'block',
            },
            center: {
                position: 'absolute',
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                left: 0,
                right: 0,
            },
        },
    };

    return { css };
};
