/* eslint-disable max-len */
import React from 'react';
import { TextsLayout } from '../texts-layout';
import { Typography } from '@mui/material';

const PrivacyPolicy: React.FC = () => {
    return (
        <TextsLayout>
            <Typography
                textAlign={'center'}
                fontSize={'1.375rem !important'}
                variant='h1'>
                POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS - MARIAZ
            </Typography>
            <Typography variant='body1'>Sua privacidade é muito importante para o MARIAZ, e nos esforçamos para protegê-la.</Typography>
            <Typography variant='body1'>O MARIAZ, a fim de poder oferecer seus serviços, trata, coleta e, em alguns casos, acessa informações sobre usuários e visitantes de nossos site e Plataforma. A presente Política de Privacidade e Proteção de Dados descreve as informações que o MARIAZ coleta, e o que ela e os respectivos titulares dos dados podem fazer relativamente a tais informações.</Typography>
            <Typography variant='body1'><strong>Ao visitar a Plataforma e utilizar os Serviços do MARIAZ, usuários e visitantes, na qualidade de pessoas naturais de 18 anos ou mais, ACEITAM a coleta, uso, transmissão, produção, recepção, classificação, acesso, reprodução, distribuição, gerenciamento, arquivamento, armazenamento, eliminação, avaliação e/ou controle de informações, modificação, comunicação, transferência, disseminação, extração ou qualquer atividade de processamento (“Processamento”) de seus dados e informações pessoais, conforme abaixo descrito e detalhado nesta Política de Privacidade e Proteção de Dados.</strong></Typography>
            <Typography variant='body1'>Esta Política de Privacidade e Proteção de Dados é parte dos Termos e Condições de Uso do MARIAZ. O consentimento voluntário, expresso e informado aos termos deste documento é um requisito essencial para poder contratar e/ou estabelecer qualquer tipo de relacionamento com o MARIAZ, em consonância com a legislação brasileira em vigor.</Typography>
            <Typography variant='h1'>1. DO MARIAZ</Typography>
            <Typography variant='body1'><strong>MARIAZ LTDA.</strong>, inscrito no Cadastro Nacional de Pessoas Jurídicas sob o nº 50.346.464/0001-22, com sede na Avenida Nove de Julho, 5966, Jardim Paulista, CEP 01406-902, na cidade de São Paulo (SP), doravante denominado MARIAZ, atua na prestação de serviço de intermediação através de sua Plataforma na Rede Mundial de Computadores (“Internet”), pela qual fornece espaço virtual para Marcas e Usuários cadastrados realizem compra e venda de produtos entre si.</Typography>
            <Typography variant='body1'>Os serviços do MARIAZ destinam-se a vendedores (“Marcas”) e compradores (“Usuários”) localizados em território brasileiro, através do domínio www.mariaz.com.br.</Typography>
            <Typography variant='h1'>2. DA RESPONSABILIDADE PELO TRATAMENTO DE DADOS PESSOAIS</Typography>
            <Typography variant='body1'>O MARIAZ é responsável pelo processamento dos dados das Marcas, Usuários e visitantes de sua Plataforma.</Typography>
            <Typography variant='body1'>Como responsável, é o MARIAZ quem decide sobre o processamento dos dados pessoais dos Usuários de sua Plataforma, bem como daqueles coletados durante a navegação dos visitantes em seu site.</Typography>
            <Typography variant='body1'>Para isso, determina os propósitos ou usos para os quais as informações pessoais serão utilizadas e os meios que serão utilizados para tal processamento.</Typography>
            <Typography variant='h1'>3. DAS INFORMAÇÕES COLETADAS E PROCESSADAS</Typography>
            <Typography variant='body1'>O MARIAZ coleta informações pessoais para que visitantes de nosso site e Usuários em nossa Plataforma possam desfrutar de nossos serviços, e para poder aprimorá-los continuamente.</Typography>
            <Typography variant='body1'>Em alguns casos, as informações serão fornecidas pelos próprios Usuários e visitantes, ao registrar-se ou ao fornecer informações para utilização de algum de nossos serviços. Em outros, o MARIAZ as coleta automaticamente, mediante navegação em nossas páginas ou utilização de nossos serviços. O MARIAZ também pode coletar informações por meio de outras fontes confiáveis.</Typography>
            <Typography variant='body1'>O MARIAZ esclarece que Usuários ou visitantes não são obrigados a fornecer as informações pessoais detalhadas abaixo; no entanto, trata-se de um requisito essencial para que possa contratar e/ou ter qualquer tipo de relacionamento com o MARIAZ e, caso não forneça essas informações, não seremos capazes de lhe fornecer nossos serviços ou nossa capacidade de fazê-lo poderá ser significativamente prejudicada.</Typography>
            <Typography variant='body1'>A imprecisão ou inexatidão dos dados pessoais fornecidos poderá ocasionar a suspensão dos serviços prestados pelo MARIAZ. Da mesma forma, o MARIAZ poderá suspender ou desabilitar permanentemente os usuários que violarem esta Declaração de Privacidade.</Typography>
            <Typography variant='body1'>Estes são os tipos de dados que o MARIAZ coleta:</Typography>
            <Typography variant='body2'>
                a) Informações que Usuários e visitantes nos fornecem diretamente ao se registrarem ou ao utilizarem nossos serviços:
                <ul style={{ listStyle: 'none' }}>
                    <li></li>
                    <li>- Apelido ou pseudônimo para operar na Plataforma do MARIAZ;</li>
                    <li>- Nome e imagem pessoal (foto pessoal ou foto do documento);</li>
                    <li>- Número de documento ou identificação válido;</li>
                    <li>- Informações de contato (como número de telefone, endereço, endereço de e-mail);</li>
                    <li>- Dados da conta bancária;</li>
                    <li>- Informações e meios de pagamento;</li>
                    <li>- Medidas do corpo</li>
                    <li>- Descrição do Estilo Pessoal</li>
                </ul>
            </Typography>
            <Typography variant='body2'>
                b) Informações que coletamos automaticamente, independentemente se está registrado ou não:
                <ul style={{ listStyle: 'none' }}>
                    <li></li>
                    <li>- Informações dos dispositivos ou computadores a partir dos quais acessa a Plataforma e outros dados capturados automaticamente (como o tipo ou versão do navegador ou o sistema operacional, configurações e parâmetros);</li>
                    <li>- Endereço IP da internet que utiliza ao se conectar ao nosso serviço ou ao navegar em nosso site;</li>
                    <li>- Informações sobre transações e movimentos na Plataformas do MARIAZ (compras, pagamentos, devoluções, créditos recebidos, faturamento, dados fiscais, perguntas, reclamações, contas bancárias, mensagens internas);</li>
                    <li>- Certas informações sobre a atividade dos usuários e visitantes em nosso site, como, por exemplo, a URL de onde vêm ou quais URLs acessam em seguida (estejam ou não em nosso site). Também poderão ser armazenadas e retidas as páginas visitadas, as interações com as referidas páginas, pesquisas realizadas, publicações, compras ou vendas, classificações e respostas, reclamações feitas e recebidas, mensagens nos fóruns, entre outras informações;</li>
                    <li>- Informações sobre localização (geolocalização), que podem ser utilizadas para oferecer descontos, recalls de produtos, entre outros;</li>
                    <li>- Links entre contas e usuários, para ações de prevenção de fraudes;</li>
                    <li>- Dados para a gestão de reclamações e sentenças (informações para a elaboração de documentos, antecedentes e estratégias);</li>
                </ul>
            </Typography>
            <Typography variant='body2'>Caso não tenha se registrado ou acessado através de sua Conta Pessoal, o MARIAZ somente coletará e armazenará, além das informações indicadas neste item, seus números de telefone celular, seu endereço de e-mail e e-mails que nos enviar.</Typography>
            <Typography variant='body2'>
                c) Informações que coletamos de outras fontes:
                <ul style={{ listStyle: 'none' }}>
                    <li></li>
                    <li>- Informações coletadas para fins de prevenção de fraudes e cumprimento dos regimes de informações (listas PEPs, OFAC etc.);</li>
                    <li>- Informações de crédito, positivas ou negativas, obtidas a partir de bases de risco de crédito e/ou fontes de acesso público, de acordo com a lei aplicável;</li>
                    <li>- Dados utilizados para validação ou autenticação de identidade ou para completar ou corrigir informação, obtidos a partir de fontes seguras e confiáveis, como órgãos públicos, prestadores de serviços ou parceiros de negócios com os quais trabalhamos;</li>
                </ul>
            </Typography>
            <Typography variant='body1'>Em alguns casos, os dados pessoais poderão ser considerados sensíveis de acordo com a lei aplicável. Sempre que for necessário, pedimos seu consentimento expresso para tratá-los. </Typography>
            <Typography variant='h1'>4. DA UTILIZAÇÃO DOS DADOS PESSOAIS</Typography>
            <Typography variant='body1'>A coleta e o processamento das informações pessoais de Usuários e visitantes nos permitem prestar um excelente serviço para que você possa encontrar produtos e realizar transações com rapidez e segurança, e oferecer funcionalidades que melhor se adaptam às suas necessidades. Exceto nos casos proibidos pelas regulamentações aplicáveis, o MARIAZ poderá utilizar suas informações pessoais para os seguintes fins:</Typography>
            <Typography variant='body2'>
                a) Fornecer os serviços e/ou benefícios oferecidos pela Plataformas MARIAZ:
                <ul style={{ listStyle: 'none' }}>
                    <li></li>
                    <li>- Identificá-lo e contatá-lo;</li>
                    <li>- Registrá-lo em nossos sistemas;</li>
                    <li>- Verificar sua identidade em conformidade com os requisitos legais;</li>
                    <li>- Validar, atualizar e corrigir sua informação;</li>
                    <li>- Fornecer os produtos, serviços e/ou benefícios que solicitar ou contratar conosco;</li>
                    <li>- Facilitar o contato direto com a Marca para os fins da transação que realizou ou pretende realizar;</li>
                    <li>- Elaborar e manter um registro das operações realizadas, bem como informá-lo sobre elas e realizar o acompanhamento adequado;</li>
                    <li>- Colocar à sua disposição a nossa Plataforma de comércio eletrônico e o respectivo sistema de pagamento online;</li>
                    <li>- Atender seus comentários, reclamações e sugestões, além de fornecer suporte;</li>
                    <li>- Consultar e reportar dados nas centrais de risco creditício, realizar processos de verificação de risco comercial e/ou de crédito, analisar a viabilidade de celebrar ou manter uma relação comercial;</li>
                    <li>- Elaborar perfis para fins de análise de crédito;</li>
                    <li>- Cobrança judicial e/ou extrajudicial;</li>
                    <li>- Possibilitar o envio de produtos adquiridos pela Plataforma;</li>
                    <li>- Permitir a sua participação em concursos, leilões ou sorteios, caso sejam realizados e aplicáveis a você, além de notificá-lo caso seja o vencedor, sempre em conformidade com os regulamentos aplicáveis a sorteios e concursos;</li>
                    <li>- Contribuir para a segurança das relações, comunicações e transações entre os usuários da nossa Plataforma.</li>
                </ul>
            </Typography>
            <Typography variant='body2'>
                b) Aprimorar nossos serviços, desenvolver novos serviços e oferecer-lhe uma melhor experiência:
                <ul style={{ listStyle: 'none' }}>
                    <li></li>
                    <li>- Desenvolver estudos internos sobre interesses e comportamentos de Usuários e visitantes, para lhes oferecer melhores serviços e produtos;</li>
                    <li>- Traçar perfis analisando diversas variáveis, como conduta ou interações na Plataforma, análise e previsão de capacidade econômica, preferências, interesses, histórico de transações, comportamento e localização, entre outras, para melhorar nossas iniciativas comerciais e promocionais, exibir publicidade ou banners de interesse, notícias, ou aprimorar nossa oferta de conteúdo;</li>
                    <li>- Oferecer os serviços e funcionalidades que melhor atendam às suas necessidades e personalizar nossos serviços para tornar sua experiência com o MARIAZ a melhor possível;</li>
                    <li>- Aprimorar nossas iniciativas comerciais e promocionais e analisar as páginas visitadas, as pesquisas realizadas pelos usuários, para aprimorar nossa oferta de conteúdo e artigos, personalizar o conteúdo, sua apresentação e serviços;</li>
                    <li>- Capacitar colaboradores e consultores independentes a fim de aprimorar a oferta de serviços às Marcas, Usuários e visitantes;</li>
                    <li>- Fornecer informações através de diferentes canais (por e-mail, mensagens curtas de texto (SMS), mensagens push, ligações telefônicas ou qualquer outro meio) sobre melhorias ou novas funções ou serviços da Plataforma;</li>
                    <li>- Conquistar sua fidelidade através de um programa de benefícios;</li>
                    <li>- Permitir que terceiros, por meio do uso de APIs públicas, criem aplicativos que utilizem conteúdo da Plataforma e interajam com ela para facilitar, aprimorar e/ou aumentar seu uso e suas funcionalidades e os serviços fornecidos pelo MARIAZ.</li>
                </ul>
            </Typography>
            <Typography variant='body2'>
                c) Cumprir as obrigações e requisitos legais das autoridades competentes:
                <ul style={{ listStyle: 'none' }}>
                    <li></li>
                    <li>- Cumprir os regulamentos aplicáveis à MARIAZ em geral;</li>
                    <li>- Cumprimento das normas de Prevenção à Lavagem de Dinheiro e Financiamento ao Terrorismo (ações de validação de identidade e verificação de usuários (KYC), verificação de identidade contra Pessoas Politicamente Expostas, verificação de perfil e histórico de transações, em conformidade com a regulamentação aplicável sobre prevenção à lavagem de dinheiro, verificação contra listas OFAC e outras) conforme legislação aplicável;</li>
                    <li>- Cumprimento dos regimes de informação em geral, conforme legislação aplicável;</li>
                    <li>- Cumprimento dos regimes tributários de arrecadação, registro, informações, auditoria e cobrança, em qualquer nível do governo (federal, estadual e municipal);</li>
                    <li>- Cumprimento dos requisitos de informação das autoridades administrativas ou judiciais competentes;</li>
                    <li>- Fornecer informações de usuários a entidades governamentais com acordos de colaboração para o cumprimento de suas competências.</li>
                </ul>
            </Typography>
            <Typography variant='body2'>
                d) Detectar e prevenir fraudes, abusos e crimes relacionados a fim de proteger a segurança de nossos usuários e a sustentabilidade de nossa Plataforma:
                <ul style={{ listStyle: 'none' }}>
                    <li></li>
                    <li>- Fazer com que a Plataforma cresça de forma sustentável e segura por meio de ferramentas e ações de prevenção de fraudes e crimes relacionados;</li>
                    <li>- Treinar o modelo de algoritmo de detecção e prevenção automatizada de fraudes.</li>
                </ul>
            </Typography>
            <Typography variant='body2'>
                e) Proteger os direitos de usuários, terceiros ou da próprio MARIAZ:
                <ul style={{ listStyle: 'none' }}>
                    <li></li>
                    <li>- Defender os direitos, ativos tangíveis e intangíveis do MARIAZ;</li>
                    <li>- Reivindicar os direitos do MARIAZ em caso de violação de seus Termos e Condições de Uso;</li>
                    <li>- Permitir que outros usuários ou terceiros reivindiquem seus direitos.</li>
                </ul>
            </Typography>
            <Typography variant='body2'>
                f) Colaborar com os proprietários de propriedade intelectual no exercício de seus direitos:
                <ul style={{ listStyle: 'none' }}>
                    <li></li>
                    <li>- Colaborar com a proteção dos direitos de propriedade intelectual ou industrial, próprios ou de terceiros, investigações de violação de marcas e patentes, assim como falsificações;</li>
                    <li>- Colaborar com as entidades que intervêm na resolução de disputas envolvendo propriedade intelectual;</li>
                    <li>- Colaborar com entidades privadas que representam interesses coletivos com um acordo de colaboração, para facilitar a resolução de disputas.</li>
                </ul>
            </Typography>
            <Typography variant='body2'>
                g) Fazer anúncios e contatos publicitários e promocionais:
                <ul style={{ listStyle: 'none' }}>
                    <li></li>
                    <li>- Contatá-lo por meio de diferentes canais (por e-mail, mensagens curtas de texto (SMS), mensagens push, chamadas telefônicas ou outros meios) para fins publicitários e/ou promocionais de produtos e serviços do MARIAZ e/ou de terceiros;</li>
                    <li>- Realizar qualquer tipo de atividade de marketing, publicidade, prospecção comercial e/ou pesquisa de mercado.</li>
                </ul>
            </Typography>
            <Typography variant='body1'>Algumas das finalidades acima descritas podem não ser aplicáveis, enquanto não se utilizar o serviço associado a tais finalidades.</Typography>
            <Typography variant='body1'>O uso de seus dados para qualquer outra finalidade que não seja compatível com as acima descritas será comunicado antes de prosseguirmos com o processamento.</Typography>
            <Typography variant='body1'>O MARIAZ manterá suas informações pessoais pelo tempo que for necessário para cumprir os fins para os quais foram coletadas.</Typography>
            <Typography variant='h1'>5. DO COMPARTILHAMENTO DOS DADOS PESSOAIS</Typography>
            <Typography variant='body1'>O MARIAZ não vende ou comercializa informações que identifiquem suas Marcas, Usuários ou visitantes. Também não compartilha ou transfere de qualquer forma informações pessoais para terceiros, exceto nas situações abaixo elencadas:</Typography>
            <Typography variant='body1'>O MARIAZ poderá ceder, transmitir e/ou transferir suas informações pessoais para:</Typography>
            <Typography variant='body2'>(i) “Prestadores de Serviços”: prestadores de serviços ou empresas são terceiros contratados para atuar em nome do MARIAZ para prestar serviços seguindo nossas instruções e de acordo com o estabelecido nesta Política de Privacidade e Proteção de Dados, a fim de ajudar a melhorar ou facilitar as operações através da nossa Plataforma, como: (a) empresas de transporte, logística, courier e de entrega para o envio dos Produtos comprados; (b) meios de pagamento, intermediários na gestão de pagamentos, para obtenção do pagamento pelos serviços ou produtos contratados, bem como para fornecer proteção em relação aos produtos adquiridos, (c) provedores de sistemas de computador, provedores de serviços em nuvem, provedores de bancos de dados e provedores de serviços tecnológicos em geral, (d) call centers ou centros de atendimento ao cliente, (e) empresas que gerenciam programas de fidelidade para a concessão de benefícios, (f) agências de publicidade ou marketing, (g) análise de dados, (h) agências de cobrança, (i) entidades governamentais ou empresas especializadas em riscos, com o objetivo de verificar informações. Esses Prestadores de Serviços acessam apenas as informações estritamente necessárias para a prestação dos serviços acordados e não podem utilizá-los para outros fins que não lhes tenham sido confiados pelo MARIAZ;</Typography>
            <Typography variant='body2'>(ii) “Parceiros comerciais": empresas com atividades financeiras ou comerciais com as quais o MARIAZ mantém um relacionamento de colaboração ou parceria, a fim de oferecer promoções, produtos e serviços dessas empresas ou co-branding. Nesses casos, o MARIAZ toma todas as medidas cabíveis para garantir o cumprimento dos padrões de confidencialidade e segurança, firmando acordos ou contratos com o objetivo de proteger a privacidade dos dados pessoais de nossos usuários e o cumprimento da legislação aplicável;</Typography>
            <Typography variant='body2'>(iii) “Grupo MARIAZ”: empresas pertencentes ao nosso grupo econômico, que operam sob nossos mesmos processos e políticas internas, sejam elas controladas, controladoras ou afiliadas à MARIAZ, para cumprir com nossas políticas internas, evitar fraudes, gerenciar riscos e facilitar a gestão dos serviços e produtos do MARIAZ;</Typography>
            <Typography variant='body2'>(iv) “Contraparte na transação”: outros usuários das plataformas do MARIAZ, nas condições previstas, a fim de colocá-lo em contato com o vendedor ou comprador para efeitos da transação que deseja realizar;</Typography>
            <Typography variant='body2'>(v) “Autoridades públicas”: autoridades administrativas e judiciais que, no exercício de sua competência, exijam informações, mesmo que não haja ordem ou citação executiva ou judicial para esse efeito, para os seguintes fins: (a) colaborar na investigação e denunciar fraudes, pirataria, violação de direitos de propriedade intelectual ou qualquer outro ato ilícito, bem como qualquer atividade ou circunstância que possa gerar responsabilidade legal para o MARIAZ e/ou aos seus usuários; (b) resguardar um interesse público, a aplicação ou administração da justiça, o reconhecimento, exercício ou defesa de um direito em um processo judicial ou administrativo e/ou a resolução de disputas; e (c) cumprir com qualquer lei, regulamento ou disposição legal aplicável, ou algum mandato de autoridade competente devidamente fundamentado e motivado;</Typography>
            <Typography variant='body2'>(vii) “Intervenientes em Disputas”: autoridades, compositores amigáveis, tribunais ou entidades que intervenham na resolução de disputas, a fim de resolver disputas que possam surgir entre usuários ou entre eles e o MARIAZ.</Typography>
            <Typography variant='body2'>(viii) “Usuários e/ou visitantes em geral”: outros usuários e/ou visitantes de nossa plataforma, a fim de fornecer um serviço de qualidade ao consumidor final, fornecendo informações para que possam tomar uma decisão de compra informada. O número de vendas realizadas pela Marca poderá ser publicado na plataforma, assim como a reputação, atendimento ao cliente, opiniões dos Usuários (cujo conteúdo não é de responsabilidade do MARIAZ) e pontualidade na entrega dos produtos;</Typography>
            <Typography variant='body2'>(ix) “Entidades públicas e/ou privadas que prestam serviços de informação creditícia”: o MARIAZ poderá, sempre que a lei aplicável permitir, compartilhar, com entidades públicas ou privadas que fornecem serviços de informação ou histórico de crédito, informações vinculadas ao comportamento creditício dos consumidores ou ao cumprimento ou descumprimento de obrigações de conteúdo patrimonial.</Typography>
            <Typography variant='body1'>Da mesma forma, o MARIAZ poderá divulgar suas informações pessoais a seu critério a outros usuários do Site, entidades ou terceiros quando houver motivos suficientes para considerar que sua atividade é suspeita de tentar ou cometer um crime ou tentar prejudicar outras pessoas.</Typography>
            <Typography variant='body1'>Caso o MARIAZ decida compartilhar suas informações pessoais com terceiros que não os mencionados, solicitaremos consentimento prévio e expresso, desde que não haja autorização ou obrigação legal que permita que seja realizado sem tal consentimento.</Typography>
            <Typography variant='body1'>Assim, por meio da aceitação do presente documento, <strong>Marcas, Usuários e visitantes dão o seu consentimento expresso e informado para que o MARIAZ ceda, transmita ou transfira suas informações pessoais aos destinatários descritos nesta Política de Privacidade e Proteção de Dados</strong>.</Typography>
            <Typography variant='body1'>O MARIAZ não será responsável pelo uso indevido de suas informações pessoais por parte de terceiros se tais terceiros tiverem coletado e/ou processado diretamente suas informações pessoais.</Typography>
            <Typography variant='h1'>6. DA TRANSFERÊNCIA INTERNACIONAL DE DADOS</Typography>
            <Typography variant='body1'>Os serviços prestados pelo MARIAZ requerem o suporte de uma infraestrutura tecnológica, como servidores e serviços em nuvem, que podem ser próprios ou fornecidos por terceiros.</Typography>
            <Typography variant='body1'>Parte dessa infraestrutura pode ser estabelecida fora do Brasil.</Typography>
            <Typography variant='body1'>Também pode acontecer de os destinatários dos dados indicados anteriormente no item “DO COMPARTILHAMENTO DOS DADOS PESSOAIS” estarem em um país diferente.</Typography>
            <Typography variant='body1'>Os países receptores dos dados eventualmente transferidos podem não oferecer os níveis adequados de proteção de dados pessoais conforme os regulamentos aplicáveis na legislação brasileira.</Typography>
            <Typography variant='body1'>Nesses casos, o MARIAZ adota medidas para proteger seus dados, por meio de cláusulas contratuais ou regras corporativas vinculativas que impõem as mesmas medidas de proteção descritas nesta Política de Privacidade e Proteção de Dados.</Typography>
            <Typography variant='h1'>7. DO PRAZO DE ARMAZENAMENTO DOS DADOS PESSOAIS</Typography>
            <Typography variant='body1'>O MARIAZ armazena as informações pessoais somente pelo tempo que for necessário para cumprir o objetivo para o qual foram coletadas, cumprir requisitos legais ou regulamentares ou durante o prazo de prescrição legal de possíveis responsabilidades legais ou contratuais.</Typography>
            <Typography variant='body1'>Após o término do período, os dados serão excluídos ou anonimizados de forma que nenhuma pessoa possa ser individualizada, conforme permitido pela lei e regulamentação vigente. </Typography>
            <Typography variant='h1'>8. DAS DECISÕES AUTOMATIZADAS E DA CRIAÇÃO DE PERFIS</Typography>
            <Typography variant='body1'>No MARIAZ, temos o compromisso de fornecer cada vez mais e melhores serviços que melhoram e simplificam a sua experiência de compra pela Internet.</Typography>
            <Typography variant='body1'>Para isso, podemos recorrer a ferramentas que nos ajudam a ser mais eficientes por meio de técnicas conhecidas como “inteligência artificial”, “machine learning” ou “Big Data” para diversos fins, como prevenção de fraudes, análise e previsão da capacidade econômica, personalização de serviços e publicidade, mecanismos automatizados de segurança cibernética ou validação de identidade por meio do reconhecimento de imagens.</Typography>
            <Typography variant='body1'>Decisões automatizadas são aquelas tomadas com base no uso de algoritmos e programas de computador, sem que nenhum ser humano esteja envolvido no processo de tomada de decisão.</Typography>
            <Typography variant='body1'>Por outro lado, a criação de perfis é a avaliação de alguns aspectos pessoais, como seus interesses, preferências, comportamentos ou localização, realizada através do processamento automatizado das informações pessoais com procedimentos estatísticos. Como resultado desse processamento, o MARIAZ pode enviar comunicações personalizadas ou mostrá-las em nossa Plataforma, que acredite ser do seu interesse.</Typography>
            <Typography variant='body1'>Sem prejuízo do disposto na cláusula 13 desta Política de Privacidade e Proteção de Dados, você também poderá exercer seu direito de acesso a qualquer momento, para saber quais Informações Pessoais foram utilizadas como parte da tomada de decisão correspondente e, se aplicável, o direito de retificação, quando considerar que qualquer dado pessoal utilizado é impreciso ou incompleto, entrando em contato pelo email contato@mariaz.com.br, a qualquer tempo.</Typography>
            <Typography variant='h1'>9. DA CONFIDENCIALIDADE DAS INFORMAÇÕES PESSOAIS E DA RESPONSABILIDADE DOS USUÁRIOS</Typography>
            <Typography variant='body1'>O MARIAZ se compromete a fazer tudo o que estiver ao seu alcance para proteger a privacidade das informações pessoais de suas Marcas, Usuários e visitantes. O usuário será responsável por todos os atos que ocorrerem através do uso de seu apelido e senha. Se, por algum motivo, você achar que alguém sabe sua senha, modifique-a entrando no respectivo campo constante do menu de navegação da Plataforma.</Typography>
            <Typography variant='h1'>10. DOS MENORES DE IDADE</Typography>
            <Typography variant='body1'>A Plataforma MARIAZ é destinada exclusivamente para Usuários maiores de 18 anos. O MARIAZ não se responsabiliza pela visualização do conteúdo de seu site ou pela utilização de sua Plataforma por menores de 18 anos de idade mediante violação do cadastro.</Typography>
            <Typography variant='h1'>11. DO USO DAS INFORMAÇÕES PESSOAIS POR OUTROS USUÁRIOS</Typography>
            <Typography variant='body1'>O MARIAZ pode permitir que os usuários tenham acesso limitado a determinados dados (como nome, apelido, email e outras informações de contato) de outros usuários para facilitar a interação entre eles. Sob nenhuma circunstância outros usuários poderão comunicar suas informações pessoais a terceiros sem o seu consentimento e/ou o consentimento do MARIAZ, conforme apropriado.</Typography>
            <Typography variant='body1'>Quando uma Marca recebe informações pessoais de seus compradores, ela se torna responsável pelo processamento desses dados e adquire as obrigações que isso implica. Portanto, não lhe é permitido utilizar os dados para uma finalidade distinta ou incompatível com a que originou a coleta. Por esse motivo, elas podem utilizar apenas as informações pessoais de outros usuários obtidas no site para: (a) fins relacionados a transações na Plataforma do MARIAZ, (b) utilizar serviços oferecidos no MARIAZ e (c) qualquer outra finalidade para a qual o usuário correspondente consinta expressamente depois que as informações exigidas por lei forem previamente comunicadas ou que a normativa local permita.</Typography>
            <Typography variant='body1'>O MARIAZ não aceita condutas consideradas como “spam”. É absolutamente proibido o envio indiscriminado de mensagens de qualquer natureza entre os usuários do MARIAZ.</Typography>
            <Typography variant='body1'>O MARIAZ não controla, armazena ou tem acesso às mensagens e conteúdos enviados pelos usuários por outros meios que não aqueles oriundos de sua Plataforma, e portanto, não assume nenhuma responsabilidade por qualquer dano que possa ser causado por seu uso.</Typography>
            <Typography variant='body1'>O MARIAZ não é responsável pelo uso que qualquer usuário ou outra pessoa possa fazer das informações publicadas na Plataforma. Os usuários concordam que o MARIAZ não será responsável por perdas ou danos que possam ser gerados como resultado de negociações entre os usuários. Consequentemente, você isenta o MARIAZ de qualquer tipo de responsabilidade caso tenha qualquer conflito com um ou mais usuários.</Typography>
            <Typography variant='h1'>12. DOS LINKS PARA OUTROS SITES</Typography>
            <Typography variant='body1'>Por meio da Plataforma, o MARIAZ ou as Marcas podem incluir links para sites de terceiros, o que não indica que pertençam ou sejam operados pelo MARIAZ. O processamento das informações pessoais por terceiros responsáveis pelos sites mencionados acima não é abrangido por esta Política de Privacidade. Da mesma forma, Marcas, Usuários e visitantes reconhecem e aceitam que o MARIAZ não tem controle sobre esses sites e que ela não é e nem será responsável pelo conteúdo ou pelos serviços fornecidos por tais sites, nem pela forma como processam suas informações pessoais, dado que você acessa esses sites por sua conta e risco.</Typography>
            <Typography variant='h1'>13. DO EXERCÍCIO DE DIREITOS DE CONTROLE DOS DADOS PESSOAIS</Typography>
            <Typography variant='body1'>A legislação brasileira concede aos titulares de dados certos direitos sobre suas informações pessoais, como por exemplo: (i) acesso; (ii) atualização; (iii) retificação; (iv) cessação do envio de anúncios, ofertas e promoções; (v) exclusão; (vi) revogação do consentimento; (vii) confidencialidade e (viii) revisão de decisões automatizadas, dentre outros.</Typography>
            <Typography variant='body1'>Você pode fazer perguntas e/ou solicitações com relação aos seus dados pessoais por meio do email contato@mariaz.com.br, a qualquer tempo.</Typography>
            <Typography variant='body1'>Em caso de dúvidas sobre o uso dos seus dados conforme estabelecido nesta Política de Privacidade e Proteção de Dados, ou se você quiser exercer seus direitos de Privacidade, por favor, entre em contato por meio do email contato@mariaz.com.br, a qualquer tempo. </Typography>
            <Typography variant='body1'>Em certos casos, e sempre que permitido ou imposto pela lei aplicável, o MARIAZ manterá em seus arquivos informações pessoais que foram objeto de solicitação de eliminação, por um prazo limitado. Uma vez cumprido esse prazo, procederemos com a devida eliminação.</Typography>
            <Typography variant='h1'>14. DOS COOKIES E OUTRAS TECNOLOGIAS</Typography>
            <Typography variant='body1'>Marcas, Usuários e visitantes devem ter ciência de que, caso desativem os cookies, é possível que não consigam utilizar todos os recursos que o MARIAZ oferece.</Typography>
            <Typography variant='body1'>O usuário reconhece e aceita expressamente que o MARIAZ poderá utilizar um sistema de monitoramento de comportamento através do uso de “cookies” e/ou outras tecnologias de monitoramento semelhantes.</Typography>
            <Typography variant='body1'>Essas tecnologias são utilizadas para conhecer os interesses e o comportamento daqueles que visitam ou são usuários do nosso site e, dessa forma, oferecer um serviço melhor ou fornecer informações relacionadas. Também utilizamos as informações obtidas por meio de cookies para analisar as páginas navegadas pelo visitante ou usuário, as pesquisas realizadas, aprimorar nossas iniciativas comerciais e promocionais, exibir publicidade ou promoções, banners de interesse, notícias sobre o MARIAZ, aperfeiçoar nossa oferta de conteúdo e artigos, apresentações e serviços, bem como promover e fazer cumprir as regras e a segurança do site; também as utilizamos para que o usuário não precise digitar sua senha com tanta frequência durante uma sessão de navegação, como também para contabilizar e corroborar os registros, a atividade do usuário e outros conceitos de contratos comerciais, sempre visando a instalação de cookies o benefício do usuário que o recebe, e que não serão utilizadas para outros fins não relacionados à MARIAZ. Da mesma forma, armazenamos cookies para oferecer uma experiência mais interativa no site, com base nas ações do usuário.</Typography>
            <Typography variant='body1'>Seus dados pessoais obtidos por meio dessas tecnologias não serão transferidos a terceiros de forma diferente das descritas nesta Política de Privacidade e Proteção de Dados.</Typography>
            <Typography variant='body1'>Além disso, “cookies” ou outros sistemas semelhantes instalados por terceiros podem ser encontrados em determinadas páginas de nosso site ou utilizados por anunciantes fora do MARIAZ. </Typography>
            <Typography variant='body1'>Saiba que a instalação, permanência e existência de cookies em seu computador ou dispositivo depende de sua exclusiva vontade, podendo ser removidos quando desejar.  Você pode configurar suas preferências de cookies pelo seu navegador.</Typography>
            <Typography variant='body1'>Nesse sentido, a qualquer momento você pode excluir os cookies armazenados, configurar o navegador para que solicite aprovação antes de armazenar cookies ou impedir diretamente o armazenamento de cookies. Este procedimento é realizado de forma distinta em diferentes navegadores, sendo necessário fazê-lo em cada navegador que utilizar. A seguir, disponibilizamos os diferentes procedimentos para gerenciar essa configuração nos navegadores mais utilizados:</Typography>
            <Typography variant='body1'>
                <ul style={{ listStyle: 'none' }}>
                    <li><i>Internet Explorer (Windows) - <a target='_blank' href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies">http://windows.microsoft.com/es-es/windows-vista/block-or-allow-cookies</a></i></li>
                    <li><i>Microsoft Edge - <a href='https://support.microsoft.com/es-ar/help/4027947/microsoft-edge-delete-cookies' target='_blank'>https://support.microsoft.com/es-ar/help/4027947/microsoft-edge-delete-cookies</a></i></li>
                    <li><i>Firefox - <a href='https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-web' target='_blank'>https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-que-los-sitios-web</a></i></li>
                    <li><i>Google Chrome - <a href='http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647' target='_blank'>http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a></i></li>
                    <li><i>Opera - <a href='http://help.opera.com/Windows/11.50/es-ES/cookies.html' target='_blank'>http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></i></li>
                    <li><i>Safari (Mac) - <a href='http://www.apple.com/legal/privacy/es/cookies' target='_blank'>http://www.apple.com/legal/privacy/es/cookies</a></i></li>
                </ul>
            </Typography>
            <Typography variant='body1'>Tenha em mente que, caso desative os cookies, é possível que não consiga utilizar todos os recursos que o MARIAZ oferece.</Typography>
            <Typography variant='h1'>15. DA SEGURANÇA E ARMAZENAMENTO DOS DADOS PESSOAIS</Typography>
            <Typography variant='body1'>O MARIAZ cumpre a lei aplicável e adota medidas de segurança de acordo com os padrões do setor para proteger suas informações pessoais.</Typography>
            <Typography variant='body1'>Na medida em que o MARIAZ tenha cumprido com as normas e adotado as medidas mencionadas acima, não se responsabilizará por interceptações ilegais ou violações de seus sistemas ou bancos de dados, nem pela utilização por pessoas não autorizadas. O MARIAZ também não se responsabiliza pelo uso inadequado das informações obtidas por esses meios.</Typography>
            <Typography variant='h1'>16. DAS BASES LEGAIS PARA TRATAMENTO DE DADOS NO BRASIL</Typography>
            <Typography variant='body1'>A coleta de dados pessoais pelo MARIAZ, para os fins acima descritos e nos termos da legislação brasileira em vigor, pode-se basear não apenas no consentimento expresso do titular dos dados, mas também: (i) no cumprimento das obrigações legais ou regulamentares do MARIAZ; (ii) no exercício regular dos direitos em processos judiciais, administrativos ou arbitrais; (iii) proteção da vida e tutela da saúde; (iv) na execução da relação comercial e contratual entre o MARIAZ e os usuários; (iv) no interesse legítimo do MARIAZ ou de terceiros; e (vi) na proteção de crédito.</Typography>
            <Typography variant='h1'>17. DO EXERCÍCIO DO DIREITO DE CONTROLE DE DADOS PESSOAIS PELOS TITULARES</Typography>
            <Typography variant='body1'>A legislação nacional e os regulamentos aplicáveis concedem aos titulares certos direitos sobre suas Informações Pessoais:</Typography>
            <Typography variant='body2'>(i) Confirmação da existência do processamento de suas Informações Pessoais;</Typography>
            <Typography variant='body2'>(ii) Acesso;</Typography>
            <Typography variant='body2'>(iii) Correção de Informações Pessoais incompletas, imprecisas ou desatualizadas;</Typography>
            <Typography variant='body2'>(iv) Anonimização, bloqueio ou exclusão de Informações Pessoais desnecessárias, excessivas ou processadas em violação da legislação aplicável;</Typography>
            <Typography variant='body2'>(v) Portabilidade das Informações Pessoais;</Typography>
            <Typography variant='body2'>
                (vi) Exclusão das Informações Pessoais quando não forem mais necessárias para os fins para os quais foram coletadas, exceto no caso de conservação necessária para:
                <ul style={{ listStyle: 'none' }}>
                    <li></li>
                    <li>a) O cumprimento de uma obrigação legal ou regulamentar;</li>
                    <li>b) A transferência para terceiros por qualquer outro motivo autorizado por lei ou qualquer regulamento que não seja a lei ou regulamento que regula a proteção de dados pessoais no Brasil, incluindo a Lei Brasileira de Proteção de Dados Pessoais – LGPD (Lei n° 13.709/18 ); ou</li>
                    <li>c) O uso exclusivo do controlador de forma anônima;</li>
                </ul>
            </Typography>
            <Typography variant='body2'>(vii) Informações sobre as entidades públicas e privadas com as quais o MARIAZ compartilhou suas Informações Pessoais;</Typography>
            <Typography variant='body2'>(viii) Informações sobre a possibilidade de não dar consentimento e sobre as consequências de sua recusa;</Typography>
            <Typography variant='body2'>(ix) Revogação do consentimento;</Typography>
            <Typography variant='body2'>(x) Fazer uma reclamação perante a Autoridade Nacional de Proteção de Dados Pessoais relacionada ao processamento de suas Informações Pessoais; e</Typography>
            <Typography variant='body2'>(xi) Opor-se ao processamento de suas Informações Pessoais devido ao não cumprimento da legislação aplicável.</Typography>
            <Typography variant='body1'>Sem prejuízo do disposto nesta Política de Privacidade e Proteção de Dados, você também poderá realizar consultas e/ou exercer os direitos de acesso, retificação e exclusão de suas Informações Pessoais por correio postal para Avenida Nove de Julho, 5966. Jardim Paulista, São Paulo – SP. CEP: 01406-902.</Typography>
            <Typography variant='h1'>18. DAS ALTERAÇÕES DESTA POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS</Typography>
            <Typography variant='body1'>O MARIAZ realizará atualizações periódicas desta Política de Privacidade e Proteção de Dados para refletir as alterações dos serviços que oferece. Essas atualizações sempre informarão, de modo transparente, a forma com que o MARIAZ trata as informações pessoais. Marcas e Usuários serão notificados dessas mudanças por e-mail. Nos casos em que couber, solicitaremos o devido consentimento.</Typography>
            <Typography variant='h1'>19. DA LEI APLICÁVEL E DO FORO</Typography>
            <Typography variant='body1'>A presente Política de Privacidade e Proteção de Dados é regida pelas leis vigentes na República Federativa do Brasil. Para dirimir eventuais dúvidas ou questões relativas a ele, as partes elegem o Foro da Comarca de São Paulo (SP), com exclusão de qualquer outro, por mais privilegiado que seja.</Typography>
        </TextsLayout>
    );
};

export { PrivacyPolicy };
