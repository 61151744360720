export const sx = () => {
    const css = {
        sxBoxSlider: {
            width: '100%',
            margin: '0 auto',
        },
    };
    return {
        ...css,
    };
};
