/* eslint-disable camelcase */
import { MenuItem, MessageBox } from '@components';
// import { yupResolver } from '@hookform/resolvers/yup';
import { useAds, useOthers } from '@hooks';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    FilterComponentObjProps,
    // FilterComponentObjProps,
    FilterComponentProps,
    FormCategories,
    FormStaticProps,
} from '@interfaces';
import { ListCategorizationProps, NewAdDataProps } from '@interfaces/Contexts';
import {
    Box,
    Checkbox,
    FormHelperText,
    Radio,
    // Radio,
    // useMediaQuery,
    useTheme,
} from '@mui/material';
import React, { RefAttributes } from 'react';
import { ForwardRefExoticComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    FilterContent,
    FilterFooter,
} from '../../../components/filters';
import { BackItem } from '../../../pages/menu/components/back-item';
// import { formSchemaCheckbox, formSchemaRadio } from './yup';
// import { CategorizationDirectionType } from '@types';

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

export const CategoriesFilter: ForwardRefExoticComponent<FilterComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            minHeightContent,
            filterInputsType,
            onClearAction,
            onSubmitAction,
        } = props;

        const { createNewAd } = useAds();
        const { categories } = useOthers();

        const { palette } = useTheme();

        const [tempCategories, setTempCategories] = React.useState<NewAdDataProps[]>(
            createNewAd.data.category ?
                createNewAd.
                    data.category.length > 0 ?
                    createNewAd.data.category : [] : []);

        // const fieldTypes = {
        //     formSchema: {
        //         checkbox: formSchemaCheckbox,
        //         radio: formSchemaRadio,
        //     },
        // };

        const {
            register,
            handleSubmit,
            getValues,
            setValue,
            reset,
            formState: { errors },
        } =
            useForm<FormCategories>({
                // resolver: yupResolver(fieldTypes.formSchema[filterInputsType]),
                mode: 'all',
            });


        const removeBrandsFromHookForm = () => {
            const getFormValues = getValues('category');
            const filterFormValues = tempCategories.
                filter((item) => getFormValues[item._id as any]);
            setValue('category', filterFormValues);
        };

        const removeSelectedBrands = ({ _id, nome }: NewAdDataProps) => {
            const filter = tempCategories.filter((item) => item._id !== _id);
            createNewAd.removeData({ type: 'category', data: { _id, nome } });
            setTempCategories((prev) => prev = [...filter]);
            removeBrandsFromHookForm();
        };

        const clearAdData = () => {
            setTempCategories((prev) => prev = []);
            reset({
                category: [],
            });
        };

        const handleCheckedCategories = ({
            _id,
            nome,
            type,
        }: FilterComponentObjProps) => {
            if (type === 'multiple') {
                const find = tempCategories.find((item) => item._id === _id);

                if (!find) {
                    setTempCategories((prev) => prev = [...prev, {
                        _id: _id,
                        nome: nome,
                    }]);
                    setValue('category', tempCategories);
                } else {
                    removeSelectedBrands({ _id, nome });
                    removeBrandsFromHookForm();
                }
            }

            if (type === 'single') {
                setTempCategories((prev) => prev = [{
                    _id: _id,
                    nome: nome,
                }]);
            }
        };

        interface CategoriesProps extends ListCategorizationProps {
            lastLevel: boolean;
        }

        const handleCategories = async ({
            _id,
            direction,
            title,
            categoria_pai,
            lastLevel,
        }: CategoriesProps) => {
            await categories.
                handleCategorization({
                    _id: _id,
                    direction: direction,
                    lastLevel: lastLevel,
                    title: title,
                    categoria_pai: categoria_pai,
                });
        };

        const handleHistoryCategorization = async ({
            _id,
            categoria_pai,
            direction,
        }: ListCategorizationProps) => {
            await categories.historyCategorization({
                _id: _id,
                categoria_pai: categoria_pai,
                direction: direction,
            });

            if (categories.list.history.length === 1) {
                clearAdData();
            };
        };

        const onSubmit: SubmitHandler<FormCategories> = async (data) => {
            const categoriesHistory = (): NewAdDataProps[] => {
                const categoriesHist = categories.
                    list.
                    history.
                    reduce((list: any, item) => {
                        const newList = [...list, {
                            nome: item.title,
                            _id: item.categoria_pai ?
                                item.categoria_pai :
                                item._id,
                        }];
                        return newList;
                    }, []);

                return categoriesHist;
            };

            createNewAd.insertData({
                type: 'category',
                data: {
                    category: tempCategories,
                },
            });

            createNewAd.insertData({
                type: 'categoryHistory',
                data: {
                    categoryHistory: categoriesHistory(),
                },
            });
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        React.useEffect(() => {
            if (!(categories.list.categories.length > 0)) {
                categories.handleCategorization({});
            }
        }, []);


        // React.useEffect(() => {
        //     if (createNewAd.data.category &&
        //         !createNewAd.data.category.length) {
        //         clearAdData();
        //     }
        // }, [createNewAd.data.category]);

        // React.useEffect(() => {
        //     if (!(!!categories.list.history.length)) {
        //         clearAdData();
        //     }
        // }, [categories.list.history.length]);

        return (
            <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)}>
                {!!errors.category && (
                    <Box pl={1} pr={2}>
                        <FormHelperText error>
                            {errors.category && errors.category.message}
                        </FormHelperText>
                    </Box>
                )}
                <FilterContent minHeightContent={minHeightContent}>
                    <Box sx={{
                        display: 'flex',
                        backgroundColor: palette.clouds,
                        overflow: 'hidden',
                        overflowX: 'auto',
                        mt: -1,
                    }}>
                        {categories.
                            list.
                            history.map(({ title, _id, categoria_pai }, i) => (
                                <Box
                                    key={categoria_pai as string + i}>
                                    <BackItem
                                        onClick={() => handleHistoryCategorization({
                                            _id: _id,
                                            categoria_pai: categoria_pai,
                                            direction: 'BACK',
                                        })}
                                        title={title as string} />
                                </Box>
                            ))}
                    </Box>
                    {
                        categories.list.categories.length > 0 ?
                            categories.list.categories.map((category) => {
                                return (category.ativo && <MenuItem
                                    key={`${category.nome}-${category._id}`}
                                    onClick={!category.lastLevel ? () =>
                                        handleCategories({
                                            lastLevel: category.lastLevel,
                                            _id: category._id,
                                            direction: 'FORWARD',
                                            title: category.nome,
                                            categoria_pai: category.categoria_pai,
                                        }) : () => { }}
                                    borderType={'bottom'}
                                    title={{
                                        text: category.nome,
                                        color: palette.dark,
                                        size: 'medium',
                                    }}
                                    leftChildren={
                                        <Box pl={2} pr={2}>
                                            {!category.lastLevel && (
                                                <I
                                                    style={{ fontSize: '1rem' }}
                                                    icon={['far', 'plus']}
                                                />
                                            )}
                                        </Box>}
                                    htmlFor={category.lastLevel ? category._id : ''}
                                >
                                    {/* {category.lastLevel ? ( */}
                                    <>
                                        {filterInputsType === 'radio' && (
                                            <Radio
                                                id={category._id}
                                                size='small'
                                                value={category._id}
                                                {...register('category', {
                                                    required: true,
                                                })}
                                                checked={!!tempCategories
                                                    .find((item) =>
                                                        item._id === category._id)}
                                                onChange={(event) =>
                                                    handleCheckedCategories({
                                                        _id: category._id,
                                                        nome: category.nome,
                                                        type: 'single',
                                                        isChecked: event.
                                                            target.
                                                            checked,
                                                    })}
                                            />
                                        )}
                                        {filterInputsType === 'checkbox' && (
                                            <Checkbox
                                                id={category._id}
                                                size='small'
                                                value={category._id}
                                                {...register('category', {
                                                    required: true,
                                                })}
                                                checked={!!tempCategories
                                                    .find((item) =>
                                                        item._id === category._id)}
                                                onChange={(event) =>
                                                    handleCheckedCategories({
                                                        _id: category._id,
                                                        nome: category.nome,
                                                        type: 'multiple',
                                                        isChecked: event.
                                                            target.
                                                            checked,
                                                    })}
                                            />
                                        )}
                                    </>
                                    {/* ) : (
                                        <Box pl={2} pr={2}>
                                            <I
                                                style={{ fontSize: '1rem' }}
                                                icon={['far', 'chevron-right']}
                                            />
                                        </Box>
                                    )} */}

                                </MenuItem>
                                );
                            }) : <MessageBox
                                type='info'
                                message='Nenhuma categoria disponível'
                            />}

                </FilterContent>
                <FilterFooter
                    onClearAction={onClearAction ?
                        onClearAction as () => void :
                        clearAdData}
                    onSubmitAction={onSubmitAction ?
                        onSubmitAction as () => void :
                        () => { }}
                    disabledOnSubmit={!(!!tempCategories.length)}
                />
            </Box>
        );
    });
