import { useTheme, useMediaQuery } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        share: {
            copyToClipboard: {
                color: palette.dark,
                disabled: {
                    opacity: 0.5,
                },
            },
        },
        action: {
            content: {
                backgroundColor: palette.common.white,
                p: 2,
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                zIndex: 9999,
                borderTopLeftRadius: 4,
                borderTopRightRadius: 4,
                boxShadow: '0px -5px 6px #00000029',
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
            },
            breadcrumbs: {
                display: 'inline-block',
                backgroundColor: palette.clouds,
                borderRadius: 0.5,
                pl: 1,
                pr: 1,
                pt: 0.2,
            },
        },
        sxBrandText: {
            color: palette.dark,
            fontSize: '0.875rem',
            fontWeight: typography.fontWeightBold,
        },
        sxProductText: {
            fontSize: '1.5rem',
            fontWeight: typography.fontWeightMedium,
        },
        sxBoxIconsButtons: {
            position: 'absolute',
            bottom: 0,
            right: 0,
        },
        sxBoxIconsButtons2: {
            paddingBottom: matches ?
                '1.25rem' : '0.3125rem',
        },
        sxStackIcons: {
            height: '100%',
            padding: '0.9375rem',
        },
        sxBoxDeliveryInfo: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '0.9375rem',
            paddingBottom: '1.25rem',
        },
        sxDeliveryText: {
            fontSize: '0.875rem',
            fontWeight: typography.fontWeightMedium,
        },
        sxCircleInfo: {
            marginLeft: '0.3125rem',
        },
        sxStackDelivery: {
            paddingTop: '0.75rem',
        },
        sxBoxGrid2: {
            padding: matches ? '1.875rem' : '0.9375rem',
            paddingRight: matches ? '0' : '0.9375rem',
        },
        sxGridPadding: {
            paddingTop: matches ? '1rem' : '0 !important',
        },
        sxBoxPaddingDescription: {
            paddingRight: matches ? '1.875rem' : '0.9375rem',
        },
        sxBoxPaddingAdvertiser: {
            paddingLeft: matches ? '1.875rem' : '0.9375rem',
        },
        sxBoxDescription: {
            padding: matches ? '1.875rem' : '0.9375rem',
            paddingLeft: matches ? '0' : '0.9375rem',
            paddingRight: 0,
            paddingTop: matches ? '1.875rem' : 0,
            borderTop: matches ?
                `0.0625rem solid ${palette.clouds}` : 'none',
        },
        sxBoxAdvertiser: {
            padding: matches ? '1.875rem' : '0.9375rem',
            paddingTop: matches ? '1.875rem' : 0,
            paddingLeft: 0,
            borderTop: matches ?
                `0.0625rem solid ${palette.clouds}` : 'none',
            cursor: 'pointer',
        },
        sxTitleGrid: {
            padding: '0.625rem 0',
            fontSize: '0.9375rem',
            fontWeight: typography.fontWeightSemiBold,
            marginBottom: '0.3125rem',
        },
        sxDescriptionText: {
            fontWeight: typography.fontWeightRegular,
            fontSize: '0.9375rem',
            color: palette.darkGrey,
        },
        sxIconButtonLarge: {
            fontSize: '0.9375rem',
            marginLeft: '0.4375rem',
        },
        sxCharacteristicsBox: {
            padding: matches ? '1.875rem' : '0',
            paddingLeft: 0,
            paddingBottom: '1.875rem',
        },
        sxCharacteristicsTitle: {
            fontSize: '0.9375rem',
            fontWeight: typography.fontWeightSemiBold,
            marginBottom: '0.3125rem',
            padding: matches ? '0.625rem 0' : '0.625rem 0.9375rem',
        },
        sxProductsBox: {
            padding: matches ? '1.875rem 0' : '1.25rem 0',
            paddingTop: 0,
        },
        sxStackLargeButtons: {
            maxWidth: matches ? '28.625rem' : '100%',
        },
        sxBoxColors: {
            paddingTop: '0.9375rem',
        },
        sxColorTitle: {
            fontWeight: typography.fontWeightBold,
            fontSize: '1rem',
        },
        sxColorSelected: {
            fontWeight: typography.fontWeightMedium,
            fontSize: '0.875rem',
        },
        productionTime: {
            fontWeight: typography.fontWeightBold,
            fontSize: '0.875rem',
        },
        sxSizesTitle: {
            fontWeight: typography.fontWeightBold,
            fontSize: '0.75rem',
            marginBottom: '0.3125rem',
        },
        sxMeasurementButton: {
            'border': `1px solid ${palette.clouds}`,
            '&:hover': {
                backgroundColor: 'transparent',
            },
            'color': palette.dark,
            'fontWeight': 500,
            'fontSize': '14px',
        },
        sxBoxCircular: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 'calc(100vh - 60px)',
        },
        sxCircularProgress: {
            color: palette.dark,
        },
        sxBoxMessageBox: {
            display: 'flex',
            height: '15.625rem',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sxBoxCircularTable: {
            display: 'flex',
            height: '6.25rem',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sxAlert: {
            color: '#d32f2f',
            marginTop: '0.625rem',
            padding: '0 0.625rem',
            maxWidth: '28.625rem',
        },
    };
    return { css };
};
