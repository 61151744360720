import { MenuItem } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAds } from '@hooks';
import {
    FilterComponentObjProps,
    FilterComponentProps,
    FormCondition,
    FormStaticProps,
} from '@interfaces';
import { NewAdDataProps } from '@interfaces/Contexts';
import { Box, Checkbox, FormHelperText, Radio, useMediaQuery } from '@mui/material';
import React, { ForwardRefExoticComponent, RefAttributes } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    FilterContent,
    ButtonSelectedFilter,
    BoxActiveFiltersList,
    FilterFooter,
} from '../../../components/filters';
import { formSchemaCheckbox, formSchemaRadio } from './yup';

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

export const ConditionFilter: ForwardRefExoticComponent<FilterComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            minHeightContent,
            filterInputsType,
            onClearAction,
            onSubmitAction,
        } = props;

        const { ad, createNewAd } = useAds();

        const matches = useMediaQuery('(min-width:600px)');
        const [tempCondition, setTempCondition] = React.useState<NewAdDataProps[]>(
            createNewAd.data.condition ?
                createNewAd.
                    data.condition.length > 0 ?
                    createNewAd.data.condition : [] : []);

        const fieldTypes = {
            formSchema: {
                checkbox: formSchemaCheckbox,
                radio: formSchemaRadio,
            },
        };

        const {
            register,
            handleSubmit,
            getValues,
            setValue,
            reset,
            formState: { errors } } =
            useForm<FormCondition>({
                resolver: yupResolver(fieldTypes.formSchema[filterInputsType]),
                mode: 'all',
            });


        const removeConditionFromHookForm = () => {
            const getFormValues = getValues('condition');
            const filterFormValues = tempCondition.
                filter((item) => getFormValues[item._id as any]);
            setValue('condition', filterFormValues);
        };

        const removeSelectedCondition = ({ _id, nome }: NewAdDataProps) => {
            const filter = tempCondition.filter((item) => item._id !== _id);
            createNewAd.removeData({ type: 'condition', data: { _id, nome } });
            setTempCondition((prev) => prev = [...filter]);
            removeConditionFromHookForm();
        };

        const clearAdData = () => {
            setTempCondition((prev) => prev = []);
            reset({
                condition: [],
            });
        };

        // * o argumento @multiple indica o tipo de insersção que ocorrera
        // * multiple[checkbox] = multiplas inserções no array de obj
        // * single[radio] = uma unica inserção no array de obj
        const handleCondition = ({ _id, nome, type }: FilterComponentObjProps) => {
            if (type === 'multiple') {
                const find = tempCondition.find((item) => item._id === _id);

                if (!find) {
                    setTempCondition((prev) => prev = [...prev, {
                        _id: _id,
                        nome: nome,
                    }]);
                    setValue('condition', tempCondition);
                } else {
                    removeSelectedCondition({ _id, nome });
                    removeConditionFromHookForm();
                }
            }

            if (type === 'single') {
                setTempCondition((prev) => prev = [{
                    _id: _id,
                    nome: nome,
                }]);
            }
        };

        const onSubmit: SubmitHandler<FormCondition> = async (data) => {
            createNewAd.insertData({
                type: 'condition',
                data: {
                    condition: tempCondition,
                },
            });
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        // React.useEffect(() => {
        //     if (!ad.conditions.isLoaded &&
        //         !ad.conditions.data.length) {
        //         loadConditions();
        //     }
        // }, []);

        React.useEffect(() => {
            if (createNewAd.data.condition &&
                !createNewAd.data.condition.length) {
                clearAdData();
            }
        }, [createNewAd.data.condition]);

        return (
            <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)}>

                <FilterContent minHeightContent={minHeightContent}>
                    {createNewAd.data.condition &&
                        createNewAd.data.condition.length > 0 && (
                        <BoxActiveFiltersList>
                            {createNewAd.
                                data.
                                condition.map(({ _id, nome }) => (
                                    <ButtonSelectedFilter
                                        key={_id}
                                        value={nome}
                                        onClick={() => removeSelectedCondition({
                                            _id,
                                            nome,
                                        })} />
                                ))}
                        </BoxActiveFiltersList>
                    )}

                    <Box pl={1} pr={2}>
                        <FormHelperText error>
                            {errors.condition && errors.condition.message}
                        </FormHelperText>
                    </Box>

                    <FilterContent minHeightContent={114}>

                        {ad.conditions.isLoaded ?
                            ad.conditions.data.map(({ _id, nome }) => (
                                <MenuItem
                                    key={_id}
                                    onClick={() => { }}
                                    borderType={'bottom'}
                                    title={{
                                        text: nome,
                                        size: !matches ? 'small' : 'tiny',
                                    }}
                                    htmlFor={_id}>
                                    {filterInputsType === 'radio' && (
                                        <Radio
                                            id={_id}
                                            size='small'
                                            value={_id}
                                            {...register('condition', {
                                                required: true,
                                            })}
                                            checked={!!tempCondition
                                                .find((item) => item._id === _id)}
                                            onChange={() => handleCondition({
                                                _id: _id,
                                                nome: nome,
                                                type: 'single',
                                            })}
                                        />
                                    )}
                                    {filterInputsType === 'checkbox' && (
                                        <Checkbox
                                            id={_id}
                                            size='small'
                                            value={_id}
                                            {...register('condition', {
                                                required: true,
                                            })}
                                            checked={!!tempCondition
                                                .find((item) => item._id === _id)}
                                            onChange={() => handleCondition({
                                                _id: _id,
                                                nome: nome,
                                                type: 'multiple',
                                            })}
                                        />
                                    )}
                                </MenuItem>
                            )) : 'Carregando marcas...'}
                    </FilterContent>

                </FilterContent>
                <FilterFooter
                    onClearAction={onClearAction ?
                        onClearAction as () => void :
                        clearAdData}
                    onSubmitAction={onSubmitAction ?
                        onSubmitAction as () => void :
                        () => { }}
                    disabledOnSubmit={!(!!tempCondition.length)}
                />
            </Box>
        );
    });
