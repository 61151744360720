import { useRef, useState } from 'react';
import { HeaderPage, Page, Snackbar } from '@components';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    ErrorsProps,
    ProfileStore,
    ToggleStaticProps,
    SnackbarCustomProps } from '@interfaces';
import { Box, Button, CircularProgress, TextField } from '@mui/material';
import { Container } from '@mui/system';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useStore, useUser } from '@hooks';

import { Form } from './styles';
import { formSchema } from './yup';

const Store = () => {
    const navigate = useNavigate();
    const { store } = useStore();
    const { user, loadUser } = useUser();
    const snackRef = useRef<ToggleStaticProps>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [snackData, setSnackData] = useState<SnackbarCustomProps>({
        message: 'Dados da loja atualizados!',
        severity: 'success',
    });
    const [storeErrors, setStoreErrors] = useState<ErrorsProps>({
        listErrors: {
            error: false,
            location: '',
            msg: '',
            param: '',
            value: '',
        },
    });
    const keys = Object.keys(storeErrors);

    const { register, handleSubmit, formState: { errors } } =
        useForm<ProfileStore>({
            resolver: yupResolver(formSchema),
            mode: 'onBlur',
        });

    const onSubmit: SubmitHandler<ProfileStore> = async (data) => {
        setLoading(true);
        const response = await store.create({
            titulo: data.titulo,
            bio: data.bio,
            url: user.data ? user.data.minha_loja?.url as string : '',
        });
        if (!response?.error) {
            snackRef.current?.show();
            setStoreErrors({
                listErrors: {
                    error: false,
                    location: '',
                    msg: '',
                    param: '',
                    value: '',
                },
            });
            loadUser();
            setLoading(false);
            setTimeout(() => {
                navigate('/meu-perfil');
            }, 1500);
        } else {
            if (response.response.errors) {
                setLoading(false);
                const filterErrors = response.response.errors.errors.
                    map((err: any, i: number) => {
                        const object = {
                            [err.param]: {
                                location: err.location,
                                msg: err.msg,
                                param: err.param,
                                error: true,
                            },
                        };

                        if (err.param !== keys[i]) {
                            return (object);
                        }

                        return (object);
                    });

                const reduceErrors = filterErrors.reduce(
                    (acc: any, current: any) => ({
                        ...acc,
                        ...current,
                    }),
                    {},
                );
                setStoreErrors((prev) => prev = reduceErrors);
            } else {
                setLoading(false);
                setSnackData({
                    message: response.response.message,
                    severity: 'error',
                });
                snackRef.current?.show();
            }
        }
    };

    return (
        <Page.Wrapper border position='fixed'>
            <HeaderPage
                // eslint-disable-next-line max-len
                onClick={() => navigate('/meu-perfil')}
                titleAlign={'default'}
                title={'Minha Loja'}
                icon={['far', 'chevron-left']}
            />
            <Page.Content paddingBottom>
                <Box sx={{
                    padding: '1.5rem 0',
                }}>
                    <Container maxWidth='lg'>
                        {user.data &&
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <Box sx={{
                                    '& .MuiTextField-root': { mb: 2 },
                                }}>

                                    <TextField
                                        focused
                                        fullWidth
                                        defaultValue={user.data.minha_loja?.titulo}
                                        label={'Título'}
                                        id="outlined-basic"
                                        variant="outlined"
                                        helperText={
                                            (storeErrors.hasOwnProperty('titulo') &&
                                                storeErrors.titulo.msg) ||
                                            (!!errors.titulo?.message &&
                                                errors.titulo.message)}
                                        error={
                                            (storeErrors.hasOwnProperty('titulo') &&
                                            !!storeErrors.titulo.error) ||
                                            !!errors.titulo}
                                        {...register('titulo',
                                            { required: true },
                                        )} />

                                    <TextField
                                        focused
                                        fullWidth
                                        defaultValue={user.data.minha_loja?.bio}
                                        label={'Bio'}
                                        id="outlined-basic"
                                        variant="outlined"
                                        helperText={
                                            (storeErrors.hasOwnProperty('bio') &&
                                                storeErrors.bio.msg) ||
                                            (!!errors.bio?.message &&
                                                errors.bio.message)}
                                        error={
                                            (storeErrors.hasOwnProperty('bio') &&
                                            !!storeErrors.bio.error) ||
                                            !!errors.bio}
                                        {...register('bio', { required: false })} />

                                    <TextField
                                        focused
                                        fullWidth
                                        defaultValue={user.data.minha_loja?.url}
                                        label={'URL'}
                                        disabled
                                        id="outlined-basic"
                                        variant="outlined" />

                                </Box>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    type='submit'
                                    disabled={loading}
                                    className='MuiButton primary'
                                >
                                    Salvar alterações {loading &&
                                        <CircularProgress
                                            size={14}
                                            sx={{ ml: 0.5, color: '#fff' }}
                                        /> }
                                </Button>
                            </Form>
                        }
                    </Container>
                </Box>
            </Page.Content>
            <Snackbar
                ref={snackRef}
                message={snackData.message}
                severity={snackData.severity}
            />
        </Page.Wrapper>
    );
};

export { Store };
