import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;

    & > :nth-child(1) {
        margin-top: 35px;
    }

    & > .MuiTextField-root {
        margin-bottom: 25px;
    }

    & > :nth-child(5) {
        & > .MuiOutlinedInput-root {
            padding-right: 0;
        }
    }

    & > :nth-child(6) {
        & > .MuiOutlinedInput-root {
            padding-right: 0;
        }
    }
`;

export const Label = styled.label`
    font-size: 0.875rem;
    font-weight: 500;
    color: #010101;
    cursor: pointer;
    font-family: 'Nunito';
    margin-left: 0.25rem;

    & > a {
        color: #010101;
        text-decoration: none;
        font-weight: bold;

        &:hover {
            text-decoration: underline;
        }
    }
`;

export const ErrorTerms = styled.p`
    margin: 3px 22px;
    margin-bottom: 0;
    font-size: 0.75rem;
    color: #BC1823;
    font-family: 'Nunito';
`;
