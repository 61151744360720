import React from 'react';
import {
    HeaderPage,
    ActionSmallButton,
    MenuItem,
    Page,
} from '@components';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { useNavigate } from 'react-router-dom';
import { CreditCardFlagType } from '@interfaces';
import { Box } from '@mui/material';
import { usePaymentMethod } from '@hooks';
import { Flag } from './styles';
import { flagsIcon } from '@static/payment-method';

const PaymentListMethods: React.FC = () => {
    const { creditCard } = usePaymentMethod();

    const navigate = useNavigate();

    React.useEffect(() => {
        creditCard.loadList();
    }, []);

    return (
        <Page.Wrapper border position='relative'>
            <HeaderPage
                titleAlign={'default'}
                icon={['far', 'credit-card']}
                title={'Formas de pagamento'}
                action={
                    <ActionSmallButton
                        text={'Adicionar'}
                        icon={['far', 'plus']}
                        onClick={() => navigate('/metodos-de-pagamento/adicionar')} />
                } />
            {creditCard.list.isLoaded && creditCard.list.data &&
                creditCard.list.data.map((card, i) => (
                    <MenuItem
                        htmlFor={`card-${card._id}`}
                        key={`method-${i}`}
                        borderType={'bottom'}
                        title={{ text: card.bandeira }}
                        legend={{
                            text: card.numero_cartao,
                            fontWeight: 'bold',
                        }}
                        iconCustom={{
                            path:
                                <Flag
                                    src={flagsIcon[card.bandeira as CreditCardFlagType]}
                                    alt='cc-visa' />,
                        }}
                        onClick={() => navigate('/metodos-de-pagamento/editar', {
                            state: {
                                card: card,
                            },
                        })}
                        children={(
                            <Box pr={2}>
                                <I icon={['far', 'chevron-right']} />
                            </Box>
                        )} />
                ))
            }
        </Page.Wrapper>
    );
};

export { PaymentListMethods };
