/* eslint-disable max-len */
import React from 'react';
import {
    Stack,
    Container,
    Typography,
    useMediaQuery,
    Grid,
    useTheme,
} from '@mui/material';
import { ButtonInterest, FixedMenu, HeaderPage, Image } from '@components';
import { Box } from '@mui/system';

import IMG from '@img/quem-somos.png';
import { SliderTest } from './components/slider-test';
import { Link } from 'react-router-dom';


const WhoWeAre: React.FC = () => {
    const matches = useMediaQuery('(min-width:37.5rem)');
    const { palette } = useTheme();

    return (
        <Container>
            <HeaderPage
                icon={['fas', 'circle-user']}
                titleAlign={'default'}
                title={'Quem somos'} />
            <Box
                pt={matches ? '3.75rem' : '1.875rem'}
                pb={matches ? '3.75rem' : '1.875rem'}>
                <Grid container spacing={6}>
                    <Grid item md={12} xs={12}>
                        <Image src={'https://placehold.co/1080x120/EEE/31343C'} maxHeight={120} />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Stack alignItems={matches ? 'flex-start' : 'center'} spacing={4}>
                            <Typography
                                fontSize={matches ? '1.125rem' : '1rem'}
                                component={'p'}>
                                O Mariaz é o lugar onde mulheres e marcas despertas,
                                autênticas e revolucionárias se encontram.
                            </Typography>
                            <Typography
                                fontSize={matches ? '1.125rem' : '1rem'}
                                component={'p'}>
                                Uma plataforma que acredita no protagonismo feminino como
                                chave para construção de um novo mundo. Um mundo que segue
                                uma lógica diferente de produção, consumo e relacionamentos.
                                Onde nos integramos com a natureza, remuneramos de forma justa e não
                                impomos padrões, tendências nem ritmos artificiais. Onde não só temos
                                espaço para sermos nós mesmas, como fazemos questão de celebrar
                                a autenticidade.
                            </Typography>
                            <Link target='_blank' to={'https://www.instagram.com/mariaz.com.br'}>
                                <ButtonInterest
                                    text={'Junte-se a nós!'}
                                    margin={'0'}
                                    color={palette.common.white}
                                    backgroundColor={palette.dark}
                                    padding={'0.625rem 4.4437rem'}
                                    onClick={() => null} />
                            </Link>
                        </Stack>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Image src={IMG} maxHeight={380} />
                    </Grid>
                    {matches &&
                        <Grid item md={6} xs={12}>
                            <Image src={IMG} maxHeight={380} />
                        </Grid>
                    }
                    <Grid item md={6} xs={12}>
                        <Stack alignItems={matches ? 'flex-start' : 'center'} spacing={4}>
                            <Typography
                                fontSize={matches ? '1.125rem' : '1rem'}
                                component={'p'}>
                                Abrimos conversas nas redes sociais, mas é na nossa plataforma onde
                                aprofundamos nossas reflexões. A gente acredita que a vida é mais
                                interessante fora dos vídeos curtos e depois do limite de
                                2200 caracteres 😉
                            </Typography>
                            <Link to={'/membros/landing-page'}>
                                <ButtonInterest
                                    text={'Vem com a gente'}
                                    margin={'0'}
                                    color={palette.common.white}
                                    backgroundColor={palette.dark}
                                    padding={'0.625rem 3.9375rem'}
                                    onClick={() => null} />
                            </Link>
                        </Stack>
                    </Grid>
                    {!matches &&
                        <Grid item md={6} xs={12}>
                            <Image src={IMG} maxHeight={380} />
                        </Grid>
                    }
                    <Grid item md={12} xs={12}>
                        <Image src={'https://placehold.co/1080x120/EEE/31343C'} maxHeight={120} />
                    </Grid>
                    <Grid display={'flex'} justifyContent={'center'} item md={12} xs={12}>
                        <Typography
                            maxWidth={'56.25rem'}
                            textAlign={'center'}
                            fontSize={matches ? '1.125rem' : '1rem'}
                            component={'p'}>
                                O Mariaz reune uma curadoria de marcas com causa, uma experiência de
                                compras segura e hiper-personalizada e uma comunidade de gente que
                                ousa fazer diferente para criar o mundo onde queremos - e merecemos -
                                viver.
                        </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <SliderTest />
                    </Grid>
                    <Grid item md={12} xs={12}>
                        <Image src={'https://placehold.co/1080x120/EEE/31343C'} maxHeight={120} />
                    </Grid>
                </Grid>
            </Box>
            {!matches &&
                <FixedMenu />}
        </Container>
    );
};

export { WhoWeAre };
