import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { FiltersProductsListProps } from '@interfaces';
import { Box, Button, useTheme } from '@mui/material';

interface ButtonFilterDesktopProps {
    filter: FiltersProductsListProps;
    isOpened: boolean;
    currentFilterOpened: FiltersProductsListProps;
    active: boolean;
    onClick: ({ event, filter }: {
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
        filter: FiltersProductsListProps
    }) => void;


}

const ButtonFilterDesktop = (
    {
        isOpened,
        onClick,
        currentFilterOpened,
        filter,
        active,
    }: ButtonFilterDesktopProps) => {
    const { typography, palette } = useTheme();

    return (
        <Button
            id={filter.id}
            key={filter.id}
            variant='outlined'
            size='small'
            aria-controls={isOpened ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isOpened ? 'true' : undefined}
            onClick={(event) => onClick({ event, filter })}
            sx={{
                ...{
                    fontSize: 14,
                    fontWeight: typography.fontWeightMedium,
                    textTransform: 'capitalize',
                    borderColor: palette.grey[300],
                    whiteSpace: 'nowrap',
                    backgroundColor: active ? palette.dark : palette.common.white,
                    color: active ? palette.common.white : palette.dark,
                    pl: 1,
                    pr: 1,
                },
                '&:hover': {
                    borderColor: active ? palette.dark : palette.grey[500],
                    backgroundColor: active ? palette.dark : palette.common.white,
                },
            }}
        >
            {filter.name}
            <Box ml={1}>
                <I
                    icon={currentFilterOpened.
                        id === filter.id ?
                        ['far', 'chevron-up'] :
                        ['far', 'chevron-down']}
                    fontSize={12}
                />
            </Box>
        </Button>
    );
};

export { ButtonFilterDesktop };
