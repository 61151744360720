import { FiltersProductsListProps } from '@interfaces';
import { BrandsFilter } from './products/brands';
import { CategoriesFilter } from './products/categories';
import { ConditionFilter } from './products/condition';
import { FreightFilter } from './products/freight';
import { LocationFilter } from './products/location';
import { OrderByFilter } from './products/order-by';
import { PriceFilter } from './products/price';
import { SizesFilter } from './products/sizes';

const filtersProductsList: FiltersProductsListProps[] = [
    {
        id: 'a7d238a26847',
        name: 'Ordenar por',
        key: 'ordenar-por',
        active: true,
        filterContent: <OrderByFilter />,
        enabled: true,
        order: 1,
    },
    {
        id: '56e1d4e82b4b',
        name: 'Categorias',
        key: 'categorias',
        historyFilters: 'Mulher / Roupas / Calças',
        active: true,
        filterContent: <CategoriesFilter
            filterInputsType='checkbox'
            minHeightContent={155} />,
        enabled: true,
        order: 2,
    },
    {
        id: '5dde79522d5f',
        name: 'Tamanho',
        key: 'tamanho',
        active: true,
        filterContent: <SizesFilter
            filterInputsType='checkbox'
            minHeightContent={155} />,
        enabled: true,
        order: 3,
    },
    {
        id: '4395c2c4119a',
        name: 'Marcas',
        key: 'marcas',
        active: true,
        filterContent: <BrandsFilter
            filterInputsType='checkbox'
            minHeightContent={155} />,
        enabled: true,
        order: 4,
    },
    {
        id: 'd3de169427c1',
        name: 'Condição',
        key: 'condicao',
        active: true,
        filterContent: <ConditionFilter
            filterInputsType='checkbox'
            minHeightContent={155} />,
        enabled: true,
        order: 5,
    },
    {
        id: '69d5af51ac49',
        name: 'Estado/Cidade',
        key: 'localizacao',
        active: true,
        filterContent: <LocationFilter />,
        enabled: true,
        order: 6,
    },
    {
        id: '9fdcb9f15ebb',
        name: 'Frete',
        key: 'frete',
        active: true,
        filterContent: <FreightFilter />,
        enabled: true,
        order: 7,
    },
    {
        id: 'db28e775b864',
        name: 'Preço',
        active: true,
        filterContent: <PriceFilter />,
        enabled: true,
        order: 8,
    },
];

export const filtersWalletPeriodList = [
    {
        id: '0e64c6b15c1f',
        title: 'todos',
        type: 'all',
        active: true,
    },
    {
        id: 'a768c3d973e5',
        title: '1 mês',
        type: 'one-month',
        active: false,
    },
    {
        id: 'ec125d23400b',
        title: '3 meses',
        type: 'three-month',
        active: false,
    },
    {
        id: '2649f5974caa',
        title: '6 meses',
        type: 'six-month',
        active: false,
    },
    {
        id: 'd5203533e63c',
        title: '1 ano',
        type: 'one-year',
        active: false,
    },
    {
        id: '5c27d1d874a4',
        title: 'Personalizar',
        type: 'custom',
        active: false,
    },
];

const filters = {
    products: filtersProductsList,
    wallet: {
        period: filtersWalletPeriodList,
    },
};

export { filters };
