/* eslint-disable max-len */
/* eslint-disable camelcase */
import { MountURLParamsForResultsProps } from '@interfaces';

export const mountURLParamsForResults = ({
    type, filter_type, _id, filters,
}: MountURLParamsForResultsProps) => {
    let query = '';
    if (filter_type === 'filters') {
        if (filters.filterbyKey) {
            query += '?key=' + filters.filterbyKey;
        }
        if (filters.categoryList) {
            if (filters.categoryList.length > 0) {
                const category = filters.categoryList[0];
                const nome = category.nome.replace(/&/g, '%26');

                query += '&category=' + category._id + ',' + nome;
            }
        }
        if (filters.orderBy) {
            query += '&orderby=' + filters.orderBy + ',orderby';
        }
        if (filters.points) {
            const [min, max] = filters.points.split('-');
            query += '&pricemin=' + min + ',price_min';
            query += '&pricemax=' + max + ',price_max';
        }
        const verifyQueryStr = query.includes('?');
        if (verifyQueryStr) {
            return '/resultados' + query;
        } else {
            const split = query.split('&');
            const slice = split.slice(1, split.length).join('&');

            return '/resultados' + '?' + slice;
        }
    };

    if (filter_type === 'products') {
        const setType = (type === 'showcase' && 'showcaseId') ||
            (type === 'quicklink' && 'quickLinkId');
        query = '?' + setType + '=' + _id;
        return '/resultados' + query;
    }
};
