import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();

    const css = {
        sxStack: {
            border: `0.0625rem solid ${palette.clouds}`,
            borderRadius: '0.25rem',
            width: '8rem',
        },
        sxDivider: {
            borderColor: palette.clouds,
            height: 'auto',
        },
        sxButton: {
            'borderRadius': '0',
            'width': '2.75rem',
            'height': '1.875rem',
            'color': palette.dark,
            ':disabled': {
                color: palette.clouds,
            },
            'fontSize': '0.875rem',
        },
        sxValueBox: {
            width: '2.25rem',
            height: '1.875rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        sxNumber: {
            fontWeight: typography.fontWeightBold,
            fontSize: '0.875rem',
        },
    };

    return { css };
};
