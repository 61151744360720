import { Box, Button, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface ButtonResetFiltersProps {
    text: string;
    icon?: IconProp,
    maxWidth?: number;
    minWidth?: number;
    noPadding?: boolean;
    onClick?: () => void;
}

const ButtonResetFilters = (
    { text, icon, maxWidth, minWidth, noPadding, onClick }: ButtonResetFiltersProps) => {
    const { typography, palette } = useTheme();

    return (
        <Button
            variant="outlined"
            fullWidth
            size='large'
            onClick={onClick}
            sx={{
                '&:hover': {
                    border: 0,
                },
                ...{
                    fontSize: 12,
                    fontWeight: typography.fontWeightBold,
                    color: palette.pomegarnate,
                    border: 0,
                    borderRadius: 0,
                    paddingTop: !noPadding ? 2 : 0,
                    paddingBottom: !noPadding ? 2 : 0,
                    maxWidth: maxWidth,
                    minWidth: minWidth,
                    whiteSpace: 'nowrap',
                    width: 'auto',
                },
            }}
        >
            {text}
            {icon && (
                <Box mt={-0.2} ml={0.5}>
                    <I
                        icon={icon}
                        fontSize={12}
                        color={palette.dark}
                    />
                </Box>
            )}
        </Button>
    );
};

export { ButtonResetFilters };
