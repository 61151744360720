/* eslint-disable max-len */
export const topics = [
    {
        topic: 'Sobre o Mariaz',
        url: 'sobre-o-mariaz',
        description: 'Entenda melhor quem somos, o que fazemos e como sugerir marcas para nossa curadoria',
    },
    {
        topic: 'Compras',
        url: 'compras',
        description: 'Sobre prazos de entrega, formas de pagamento e frete.',
    },
    {
        topic: 'Cancelamentos e Devoluções',
        url: 'cancelamentos-e-devoluções',
        description: 'Sobre prazos, condições e procedimento para cancelamentos e devoluções',
    },
    {
        topic: 'Contato',
        url: 'contato',
        description: 'Sobre como falar com alguém da nossa equipe',
    },
];

export const questionsData = [
    {
        id: '72835cd1a63d',
        question: 'O que é o Mariaz?',
        topic: 'Sobre o Mariaz',
        urlTopic: 'sobre-o-mariaz',
        answer: 'Mariaz é o lugar onde mulheres e marcas despertas, autênticas e revolucionárias se encontram. Uma plataforma que acredita no protagonismo feminino como chave para abertura de caminhos alternativos quando se fala em construção de estilo. <br /><br /> Somos um marketplace com uma curadoria de marcas independentes, autorais e 100% lideradas por mulheres. Oferecemos uma experiência de compras personalizada, através do suporte de consultoras de estilo e de conteúdos que te ajudam a refletir sobre quem você é e o que significa viver a sua vida nos seus próprios termos para que você possa, sem hesitações, fazê-lo. <br /> <br /> Queremos ser o seu destino para compras únicas e especiais e também um lugar de respiro e inspiração onde celebramos a diversidade sem impor padrões, tendências, nem ritmos artificiais.',
    },
    {
        id: '29a08156ecff',
        question: 'O vocês querem dizer com marcas lideradas por mulheres?',
        topic: 'Sobre o Mariaz',
        urlTopic: 'sobre-o-mariaz',
        answer: 'São marcas que possuem mulheres na liderança do negócio e/ou liderança criativa. A gente inclui marcas com times de liderança mistos, desde que pelo menos uma mulher esteja numa posição relevante de tomada de decisão.',
    },
    {
        id: '500d49ac7080',
        question: 'O que é Novo Mundo. Nosso Mundo?',
        topic: 'Sobre o Mariaz',
        urlTopic: 'sobre-o-mariaz',
        answer: 'Novo Mundo. Nosso Mundo é o começo de tudo que o Mariaz se propõe a ser e a fazer. É a nossa curadoria de conteúdos por e para mulheres despertas, autênticas e revolucionárias. Para que juntas, a gente amplie o nosso entendimento de passado/presente e juntas, a gente sonhe e crie o presente/futuro onde queremos e merecemos estar. <br /><br /> Esse conteúdo vem no formato de bate papos organizados dentro dos nossos pilares (para despertar, para ser cada vez mais autêntica e para fazer a revolução acontecer). São vídeos que moram dentro da área de Conteúdos na nossa plataforma e que você pode acessar de forma totalmente gratuita! (basta criar sua conta no Mariaz e nada mais)',
    },
    {
        id: 'd3525954ec78',
        question: 'Sou uma marca e gostaria de vender meus produtos com vocês. O que devo fazer?',
        topic: 'Sobre o Mariaz',
        urlTopic: 'sobre-o-mariaz',
        answer: 'Que alegria! Envia pra gente um email no <strong>contato@mariaz.com.br</strong>, incluindo o seu Instagram e/ou site, por favor.',
    },
    {
        id: '22d785b40b16',
        question: 'Minha marca favorita não está no Mariaz. Como posso indicá-la para vocês?',
        topic: 'Sobre o Mariaz',
        urlTopic: 'sobre-o-mariaz',
        answer: 'Vamos amar receber sua indicação! Nos envie um email no <strong>contato@mariaz.com.br</strong>, incluindo o Instagram e/ou site da marca, por favor.',
    },
    {
        id: '2a9acdf732f8',
        question: 'Por que alguns prazos de entrega são tão longos?',
        topic: 'Compras',
        urlTopic: 'compras',
        answer: 'Porque algumas marcas produzem os itens apenas após realização da compra, especialmente para você <3 É importante ficar atenta aos prazos de produção de cada item presentes na descrição do produto.',
    },
    {
        id: '5e6933ae8440',
        question: 'Quais são as opções de frete disponíveis?',
        topic: 'Compras',
        urlTopic: 'compras',
        answer: 'As opções de frete disponíveis hoje são aquelas oferecidas pelos Correios: PAC e Sedex. Você pode escolher entre priorizar prazo ou preço!',
    },
    {
        id: 'e1ebc8e34cbf',
        question: 'Por que vejo mais de uma taxa de frete no meu carrinho?',
        topic: 'Compras',
        urlTopic: 'compras',
        answer: 'Nosso modelo de estoque é descentralizado, ou seja, cada marca tem o seu estoque próprio. Quando você compra itens de mais de uma marca, cada uma delas enviará o produto do seu endereço de origem e, por isso, você poderá ver no seu carrinho múltiplos valores de fretes - um para cada marca. <br /><br /> Porém, você nunca pagará mais do que R$29,99 de frete em uma compra! Qualquer valor acima desse teto, ficará por nossa conta ;)',
    },
    {
        id: '9097099ab6b7',
        question: 'Porque eu estou recebendo mais de um pacote na minha casa?',
        topic: 'Compras',
        urlTopic: 'compras',
        answer: 'Nosso modelo de estoque é descentralizado, ou seja, cada marca tem o seu estoque próprio. Quando você compra itens de mais de uma marca, cada uma delas enviará o produto do seu endereço de origem e, por isso, você receberá um pacote de cada marca que você comprou.',
    },
    {
        id: 'dad07c6624bf',
        question: 'Quais são as opções de pagamento oferecidas pelo Mariaz?',
        topic: 'Compras',
        urlTopic: 'compras',
        answer: 'As opções de pagamento hoje são Cartão de Crédito em até 3 vezes, PIX e boleto.',
    },
    {
        id: '50aa4014b7d1',
        question: 'O Mariaz aceita PIX?',
        topic: 'Compras',
        urlTopic: 'compras',
        answer: 'Sim! No check out você pode optar pelo pagamento via PIX.',
    },
    {
        id: 'ea9b9b400635',
        question: 'Como faço para realizar o pagamento por Pix na prática?',
        topic: 'Compras',
        urlTopic: 'compras',
        answer: 'Ao fechar um pedido por Pix, a opção de pagamento fica habilitada por 10 (dez) minutos, contados a partir do fechamento do pedido. Basta acessar seu banco com o QR code do pedido ou Chave informada na tela de sucesso e realizar seu pagamento.',
    },
    {
        id: '67ae2429570e',
        question: 'Qual o prazo de pagamento do pix?',
        topic: 'Compras',
        urlTopic: 'compras',
        answer: 'A chave pix está disponível para pagamento por 10 (dez) minutos, contados a partir do fechamento do pedido.',
    },
    {
        id: '430ffa679d10',
        question: 'Perdi o prazo de pagamento do pix. É possível gerar um novo pedido?',
        topic: 'Compras',
        urlTopic: 'compras',
        answer: 'Após o vencimento, não é possível gerar uma nova chave para pagamento. Você pode gerar um novo pedido por pix e realizar o pagamento dentro do prazo de 10 (dez) minutos. Ou gerar um novo pedido escolhendo uma outra forma de pagamento.',
    },
    {
        id: 'cbe112b17b54',
        question: 'Como posso acompanhar o status do meu pedido?',
        topic: 'Compras',
        urlTopic: 'compras',
        answer: 'Acesse o site com seu login e senha, clique na foto do seu perfil e, em seguida, em “Minhas compras”. Você irá visualizar o número do pedido e ao lado o status do mesmo.',
    },
    {
        id: '6d5e09f7fae9',
        question: 'Fiz uma compra no site, mas não recebi a confirmação. Como sei que a compra foi aprovada?',
        topic: 'Compras',
        urlTopic: 'compras',
        answer: 'Acesse o site com seu login e senha, clique na foto do seu perfil e, em seguida, em “Minhas compras”. Você irá visualizar o número do pedido e ao lado o status do mesmo.',
    },
    {
        id: '0816349e19eb',
        question: 'Qual a diferença entre cancelamento e devolução?',
        topic: 'Cancelamentos e Devoluções',
        urlTopic: 'cancelamentos-e-devoluções',
        answer: 'O cancelamento acontece quando você desiste da compra antes mesmo do item ter sido enviado para você. Já a devolução acontece quando você recebe o item, desiste da compra e nos envia o produto de volta.',
    },
    {
        id: 'cdc7bd332240',
        question: 'Como faço para cancelar ou devolver uma compra?',
        topic: 'Cancelamentos e Devoluções',
        urlTopic: 'cancelamentos-e-devoluções',
        answer: 'Manda um email para gente no <strong>contato@mariaz.com.br</strong> que vamos te dar todas as instruções!',
    },
    {
        id: '7645f581be65',
        question: 'Quais são as condições para devolução de um item?',
        topic: 'Cancelamentos e Devoluções',
        urlTopic: 'cancelamentos-e-devoluções',
        answer: 'Você tem até 7 dias úteis para solicitar a devolução de uma compra sem necessidade de justificativa. Essa solicitação deve acontecer pelo email <strong>contato@mariaz.com.br</strong>. Nós iremos te enviar uma etiqueta pré paga para os Correios (sim, o frete de devolução é por nossa conta!) e você deverá colar esta etiqueta em uma embalagem contendo o item a ser devolvido e despachar nos correios em até 3 dias úteis após emissão da etiqueta. <br /> <br /> Para que a devolução seja aprovada, é importante que o produto esteja nas condições corretas de devolução, ou seja: produtos não lavados, não usados, sem odores, que não tenham sido alterados (bainha e ajustes, por exemplo), entre outros. Em resumo: O produto deverá estar intacto e com etiqueta afixada à peça. <br /> <br /> Quem faz esse controle é a marca e, apenas com a aprovação dela, podemos confirmar que a devolução foi aprovada e seguir para o processo de reembolso do valor do item.',
    },
    {
        id: '561dbd0fc172',
        question: 'Eu posso realizar uma troca de produto?',
        topic: 'Cancelamentos e Devoluções',
        urlTopic: 'cancelamentos-e-devoluções',
        answer: 'Não existe opção de troca no nosso site. Você deverá solicitar a devolução do produto e então realizar uma nova compra.',
    },
    {
        id: '2f7c9b51c12b',
        question: 'Qual é o valor reembolsado no caso de cancelamento?',
        topic: 'Cancelamentos e Devoluções',
        urlTopic: 'cancelamentos-e-devoluções',
        answer: 'O valor reembolsado nos casos de cancelamento será: <br /> <br /> <ul class=listdecimal><li>Exatamente o valor que foi pago pelo item (incluindo qualquer tipo de cupom ou desconto que você tenha utilizado na hora do check out)</li> <br /> <li>O valor do frete referente à entrega do item APENAS se este for o único produto comprado daquela marca dentro do pedido  </li></ul>',
    },
    {
        id: '7a6ff20e1132',
        question: 'Qual é o valor reembolsado no caso de devolução?',
        topic: 'Cancelamentos e Devoluções',
        urlTopic: 'cancelamentos-e-devoluções',
        answer: 'O valor reembolsado será exatamente o valor que foi pago pelo item (incluindo qualquer tipo de cupom ou desconto que você tenha utilizado na hora do check out). O frete de envio do produto não é reembolsado em caso de devolução.',
    },
    {
        id: 'f7d2a5180de8',
        question: 'Quais produtos podem ser devolvidos?',
        topic: 'Cancelamentos e Devoluções',
        urlTopic: 'cancelamentos-e-devoluções',
        answer: 'Produtos que foram recebidos em no máximo 7 dias úteis e que estejam nas condições corretas de devolução, ou seja: <br /> <br /> <ul class=listdisc> <li> não lavados </li> <li> não usados </li > <li> sem odores </li> <li> que não tenham sido alterados de nenhuma forma (bainha e ajustes, por exemplo) </li> </ul> <br /> Em resumo, o produto deverá estar intacto e com etiqueta afixada à peça e a confirmação da devolução acontecerá mediante validação pela marca de que o produto recebido está de acordo com o estabelecido acima.',
    },
    {
        id: '359e0de70829',
        question: 'Como entrar em contato com o Mariaz?',
        topic: 'Contato',
        urlTopic: 'contato',
        answer: 'Para qualquer pergunta, sugestão ou comentário, por favor, escreva para <strong>contato@mariaz.com.br</strong>',
    },
];

export const helpData = [
    {
        topic: 'Sobre o Mariaz',
        url: 'sobre-o-mariaz',
        description: 'Entenda melhor quem somos, o que fazemos e como sugerir marcas para nossa curadoria',
        questions: [
            {
                question: 'O que é o Mariaz?',
                answer: 'Mariaz é o lugar onde mulheres e marcas despertas, autênticas e revolucionárias se encontram. Uma plataforma que acredita no protagonismo feminino como chave para abertura de caminhos alternativos quando se fala em construção de estilo. <br /><br /> Somos um marketplace com uma curadoria de marcas independentes, autorais e 100% lideradas por mulheres. Oferecemos uma experiência de compras personalizada, através do suporte de consultoras de estilo e de conteúdos que te ajudam a refletir sobre quem você é e o que significa viver a sua vida nos seus próprios termos para que você possa, sem hesitações, fazê-lo. <br /> <br /> Queremos ser o seu destino para compras únicas e especiais e também um lugar de respiro e inspiração onde celebramos a diversidade sem impor padrões, tendências, nem ritmos artificiais.',
            },
            {
                question: 'O vocês querem dizer com marcas lideradas por mulheres?',
                answer: 'São marcas que possuem mulheres na liderança do negócio e/ou liderança criativa. A gente inclui marcas com times de liderança mistos, desde que pelo menos uma mulher esteja numa posição relevante de tomada de decisão.',
            },
            {
                question: 'O que é Novo Mundo. Nosso Mundo?',
                answer: 'Novo Mundo. Nosso Mundo é o começo de tudo que o Mariaz se propõe a ser e a fazer. É a nossa curadoria de conteúdos por e para mulheres despertas, autênticas e revolucionárias. Para que juntas, a gente amplie o nosso entendimento de passado/presente e juntas, a gente sonhe e crie o presente/futuro onde queremos e merecemos estar. <br /><br /> Esse conteúdo vem no formato de bate papos organizados dentro dos nossos pilares (para despertar, para ser cada vez mais autêntica e para fazer a revolução acontecer). São vídeos que moram dentro da área de Conteúdos na nossa plataforma e que você pode acessar de forma totalmente gratuita! (basta criar sua conta no Mariaz e nada mais)',
            },
            {
                question: 'Sou uma marca e gostaria de vender meus produtos com vocês. O que devo fazer?',
                answer: 'Que alegria! Envia pra gente um email no <strong>contato@mariaz.com.br</strong>, incluindo o seu Instagram e/ou site, por favor.',
            },
            {
                question: 'Minha marca favorita não está no Mariaz. Como posso indicá-la para vocês?',
                answer: 'Vamos amar receber sua indicação! Nos envie um email no <strong>contato@mariaz.com.br</strong>, incluindo o Instagram e/ou site da marca, por favor.',
            },
        ],
    },
    {
        topic: 'Compras',
        url: 'compras',
        description: 'Sobre prazos de entrega, formas de pagamento e frete.',
        questions: [
            {
                question: 'Por que alguns prazos de entrega são tão longos?',
                answer: 'Porque algumas marcas produzem os itens apenas após realização da compra, especialmente para você <3 É importante ficar atenta aos prazos de produção de cada item presentes na descrição do produto.',
            },
            {
                question: 'Quais são as opções de frete disponíveis?',
                answer: 'As opções de frete disponíveis hoje são aquelas oferecidas pelos Correios: PAC e Sedex. Você pode escolher entre priorizar prazo ou preço!',
            },
            {
                question: 'Por que vejo mais de uma taxa de frete no meu carrinho?',
                answer: 'Nosso modelo de estoque é descentralizado, ou seja, cada marca tem o seu estoque próprio. Quando você compra itens de mais de uma marca, cada uma delas enviará o produto do seu endereço de origem e, por isso, você poderá ver no seu carrinho múltiplos valores de fretes - um para cada marca. <br /><br /> Porém, você nunca pagará mais do que R$29,99 de frete em uma compra! Qualquer valor acima desse teto, ficará por nossa conta ;)',
            },
            {
                question: 'Porque eu estou recebendo mais de um pacote na minha casa?',
                answer: 'Nosso modelo de estoque é descentralizado, ou seja, cada marca tem o seu estoque próprio. Quando você compra itens de mais de uma marca, cada uma delas enviará o produto do seu endereço de origem e, por isso, você receberá um pacote de cada marca que você comprou.',
            },
            {
                question: 'Quais são as opções de pagamento oferecidas pelo Mariaz?',
                answer: 'As opções de pagamento hoje são Cartão de Crédito em até 3 vezes, PIX e boleto.',
            },
            {
                question: 'O Mariaz aceita PIX?',
                answer: 'Sim! No check out você pode optar pelo pagamento via PIX.',
            },
            {
                question: 'Como faço para realizar o pagamento por Pix na prática?',
                answer: 'Ao fechar um pedido por Pix, a opção de pagamento fica habilitada por 10 (dez) minutos, contados a partir do fechamento do pedido. Basta acessar seu banco com o QR code do pedido ou Chave informada na tela de sucesso e realizar seu pagamento.',
            },
            {
                question: 'Qual o prazo de pagamento do pix?',
                answer: 'A chave pix está disponível para pagamento por 10 (dez) minutos, contados a partir do fechamento do pedido.',
            },
            {
                question: 'Perdi o prazo de pagamento do pix. É possível gerar um novo pedido?',
                answer: 'Após o vencimento, não é possível gerar uma nova chave para pagamento. Você pode gerar um novo pedido por pix e realizar o pagamento dentro do prazo de 10 (dez) minutos. Ou gerar um novo pedido escolhendo uma outra forma de pagamento.',
            },
            {
                question: 'Como posso acompanhar o status do meu pedido?',
                answer: 'Acesse o site com seu login e senha, clique na foto do seu perfil e, em seguida, em “Minhas compras”. Você irá visualizar o número do pedido e ao lado o status do mesmo.',
            },
            {
                question: 'Fiz uma compra no site, mas não recebi a confirmação. Como sei que a compra foi aprovada?',
                answer: 'Acesse o site com seu login e senha, clique na foto do seu perfil e, em seguida, em “Minhas compras”. Você irá visualizar o número do pedido e ao lado o status do mesmo.',
            },
        ],
    },
    {
        topic: 'Cancelamentos e Devoluções',
        url: 'cancelamentos-e-devoluções',
        description: 'Sobre prazos, condições e procedimento para cancelamentos e devoluções',
        questions: [
            {
                question: 'Qual a diferença entre cancelamento e devolução?',
                answer: 'O cancelamento acontece quando você desiste da compra antes mesmo do item ter sido enviado para você. Já a devolução acontece quando você recebe o item, desiste da compra e nos envia o produto de volta.',
            },
            {
                question: 'Como faço para cancelar ou devolver uma compra?',
                answer: 'Manda um email para gente no <strong>contato@mariaz.com.br</strong> que vamos te dar todas as instruções!',
            },
            {
                question: 'Quais são as condições para devolução de um item?',
                answer: 'Você tem até 7 dias úteis para solicitar a devolução de uma compra sem necessidade de justificativa. Essa solicitação deve acontecer pelo email <strong>contato@mariaz.com.br</strong>. Nós iremos te enviar uma etiqueta pré paga para os Correios (sim, o frete de devolução é por nossa conta!) e você deverá colar esta etiqueta em uma embalagem contendo o item a ser devolvido e despachar nos correios em até 3 dias úteis após emissão da etiqueta. <br /> <br /> Para que a devolução seja aprovada, é importante que o produto esteja nas condições corretas de devolução, ou seja: produtos não lavados, não usados, sem odores, que não tenham sido alterados (bainha e ajustes, por exemplo), entre outros. Em resumo: O produto deverá estar intacto e com etiqueta afixada à peça. <br /> <br /> Quem faz esse controle é a marca e, apenas com a aprovação dela, podemos confirmar que a devolução foi aprovada e seguir para o processo de reembolso do valor do item.',
            },
            {
                question: 'Eu posso realizar uma troca de produto?',
                answer: 'Não existe opção de troca no nosso site. Você deverá solicitar a devolução do produto e então realizar uma nova compra.',
            },
            {
                question: 'Qual é o valor reembolsado no caso de cancelamento?',
                answer: 'O valor reembolsado nos casos de cancelamento será: <br /> <br /> <ul class=listdecimal><li>Exatamente o valor que foi pago pelo item (incluindo qualquer tipo de cupom ou desconto que você tenha utilizado na hora do check out)</li> <br /> <li>O valor do frete referente à entrega do item APENAS se este for o único produto comprado daquela marca dentro do pedido  </li></ul>',
            },
            {
                question: 'Qual é o valor reembolsado no caso de devolução?',
                answer: 'O valor reembolsado será exatamente o valor que foi pago pelo item (incluindo qualquer tipo de cupom ou desconto que você tenha utilizado na hora do check out). O frete de envio do produto não é reembolsado em caso de devolução.',
            },
            {
                question: 'Quais produtos podem ser devolvidos?',
                answer: 'Produtos que foram recebidos em no máximo 7 dias úteis e que estejam nas condições corretas de devolução, ou seja: <br /> <br /> <ul class=listdisc> <li> não lavados </li> <li> não usados </li > <li> sem odores </li> <li> que não tenham sido alterados de nenhuma forma (bainha e ajustes, por exemplo) </li> </ul> <br /> Em resumo, o produto deverá estar intacto e com etiqueta afixada à peça e a confirmação da devolução acontecerá mediante validação pela marca de que o produto recebido está de acordo com o estabelecido acima.',
            },
        ],
    },
    {
        topic: 'Contato',
        url: 'contato',
        description: 'Sobre como falar com alguém da nossa equipe',
        questions: [
            {
                question: 'Como entrar em contato com o Mariaz?',
                answer: 'Para qualquer pergunta, sugestão ou comentário, por favor, escreva para <strong>contato@mariaz.com.br</strong>',
            },
        ],
    },
];
