/* eslint-disable max-len */
import React from 'react';
import {
    Box,
    IconButton,
    Grid,
    useMediaQuery,
    Stack,
    Container,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import Mariaz from '@img/mariaz-logo.png';
import Avatar from '@img/avatar.png';
import {
    IconBadge,
    AvatarGroup,
    SearchForm,
    Navigation,
} from '@components';

import { Logo, MainMenuWrapper, UserMenuWrapper } from './styles';
import { sx } from './sx';
import { mainMenu } from '@static/main-menu';
import { useFilters, useMainMenu, useUser } from '@hooks';

import { HeaderLayoutType } from '@types';
import { routeLayout, images } from '@utils';
import { useOnClickOutside } from '../../../hooks/click-outside';
import { useCart } from '../../../hooks/shopping';

const Header: React.FC = () => {
    const { cart } = useCart();
    const location = useLocation();
    const { user, favorites, userMenu } = useUser();
    const { handleLeaveMainMenu } = useMainMenu();
    const filter = useFilters();
    const filtersKey = localStorage.getItem('@filters:search');
    const authKey = localStorage.getItem('@auth');

    const { css } = sx();
    const refForm = React.useRef();
    const navigate = useNavigate();
    const matches = useMediaQuery('(min-width:600px)');
    const [searchMode, setSearchMode] = React.useState<boolean>();

    const opacityItem = (item: boolean) => item ? 0.3 : 1;

    const [layout, setLayout] = React.useState<HeaderLayoutType>('default');

    const handleLayoutType = (locationPathname: string) => {
        setLayout((prev) => prev = routeLayout(locationPathname));
    };

    const handleSetOpen = () => {
        setSearchMode(false);
    };
    useOnClickOutside(refForm, handleSetOpen);

    const resetHooksData = () => {
        if (location.pathname !== '/resultados') {
            filter.clearAllFiltersData();
        }
    };

    React.useEffect(() => {
        handleLayoutType(location?.pathname as string);
        if (filtersKey) {
            resetHooksData();
        }
    }, [location?.pathname]);

    React.useEffect(() => {
        if (!authKey) {
            cart.session.create();
            if (!cart.stores.isLoaded) {
                cart.load();
            }
        }

        if (authKey) {
            if (!cart.stores.isLoaded) {
                cart.load();
            }
        }
        // cart.load();
    }, []);

    const cartItemsCount = cart.stores.data.map((store) => store.itens).reduce((list, item) => {
        return list + item.length;
    }, 0);

    return (
        <Box sx={css.sxBoxHeader}>
            <Helmet
                title={'Mariaz'} />
            <Container
                maxWidth={'lg'}
                sx={css.sxContainerHeader}>
                <Grid container>
                    <Grid item md={5} sm={5} xs={3}>
                        <Stack
                            alignItems={'center'}
                            direction="row"
                            justifyContent="start"
                            spacing={3}
                            height='100%'
                            position='relative'>
                            {layout === 'simple' ?
                                <IconButton
                                    onClick={() => navigate(-1)}
                                    className='x45x45'
                                    sx={{
                                        opacity: opacityItem(searchMode as boolean),
                                        width: '2.5rem',
                                        height: '2.5rem',
                                    }}>
                                    <I
                                        icon={['far', 'arrow-left-long']}
                                        style={css.sxIconArrowLeft} />
                                </IconButton> :
                                <IconButton
                                    sx={matches ? {
                                        ...css.mainmenuAvatar,
                                        opacity: opacityItem(searchMode as boolean),
                                    } : {}}
                                    onClick={() => matches ? null : navigate('/menu')}
                                    onMouseLeave={() => handleLeaveMainMenu()}>
                                    <I
                                        icon={['far', 'bars-staggered']}
                                        style={css.sxIconSearch} />
                                    <MainMenuWrapper
                                        className='main-menu'>
                                        <Navigation.Main menu={mainMenu} />
                                    </MainMenuWrapper>
                                </IconButton>
                            }

                            {matches && !searchMode && !(layout === 'simple') &&
                                <IconButton
                                    aria-controls=''
                                    onClick={() => setSearchMode(true)}
                                    sx={{
                                        opacity: opacityItem(searchMode as boolean),
                                        width: '2.5rem',
                                        height: '2.5rem',
                                        margin: '0.5rem 1.25rem !important',
                                    }}>
                                    <I
                                        icon={['far', 'magnifying-glass']}
                                        style={css.sxIconSearch} />
                                </IconButton>
                            }
                            {searchMode &&
                                <SearchForm
                                    refForm={refForm}
                                    autofocus={searchMode}
                                    onBlurInput={() => setSearchMode(false)} />
                            }

                        </Stack>
                    </Grid>
                    <Grid
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        item md={2} sm={2} xs={6}>
                        <Stack
                            onClick={() => navigate('/')}
                            direction={'column'}
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                                height: '100%',
                                opacity: opacityItem(searchMode as boolean),
                            }}>
                            <Logo src={Mariaz} alt="" />
                        </Stack>
                    </Grid>
                    <Grid
                        justifyContent={'flex-end'}
                        display={'flex'}
                        alignItems={'center'}
                        item md={5} sm={5} xs={3}>
                        <Stack
                            alignItems={'center'}
                            direction="row"
                            justifyContent="end"
                            spacing={1}
                            position='relative'
                            height='100%'
                            sx={{ opacity: opacityItem(searchMode as boolean) }}>
                            {matches && !(layout === 'simple') && user.data?.cpf && (
                                <React.Fragment>
                                    <IconBadge
                                        icon={['fal', 'heart']}
                                        onClick={() => navigate('/meus-favoritos')}
                                        badgeContent={favorites.list ? favorites.list.total : 0} />
                                </React.Fragment>
                            )}
                            {matches && !(layout === 'simple') && user.data?.cpf && (
                                <IconButton
                                    onClick={() => navigate('/minha-sacola')}>
                                    <IconBadge
                                        icon={['fal', 'bag-shopping']}
                                        badgeContent={cartItemsCount} />
                                </IconButton>
                            )}
                            {matches && !(layout === 'simple') && !(user.isLoaded) && (
                                <IconButton
                                    onClick={() => navigate('/minha-sacola')}>
                                    <IconBadge
                                        icon={['fal', 'bag-shopping']}
                                        badgeContent={cartItemsCount} />
                                </IconButton>
                            )}
                            {user.isLoaded ?
                                <IconButton sx={matches ? css.usermenuAvatar : {}}
                                    onClick={() => matches ?
                                        null :
                                        navigate('/menu-usuario')}>
                                    <Box>
                                        <AvatarGroup
                                            align={'bottom'}
                                            size={'tiny'}
                                            // eslint-disable-next-line max-len
                                            src={user.data?.imagens && user.data?.imagens.avatar ?
                                                images.load(user.data?.imagens.avatar, 'server') :
                                                images.load(Avatar, 'local')} />
                                        {userMenu.isLoaded && userMenu.data &&
                                            <UserMenuWrapper className='user-menu'>
                                                <Navigation.User
                                                    menu={userMenu.data} />
                                            </UserMenuWrapper>
                                        }
                                    </Box>
                                </IconButton> :
                                <IconBadge
                                    onClick={
                                        () => navigate(
                                            user.isLoaded ?
                                                '/menu-user' :
                                                '/login')}
                                    icon={['far', 'user']} />}
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </Box >
    );
};

export { Header };
