import React from 'react';
import { Box, Radio, Typography } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { AddressBoxProps } from '@interfaces';

import { sx } from './sx';

const AddressBox: React.FC<AddressBoxProps> = ({
    nickname,
    legend,
    title,
    mode,
    noIcon,
    onClick,
    radio,
}: AddressBoxProps) => {
    const { css } = sx();

    const handleMargin = nickname ?
        { marginBottom: '0.9375rem' } :
        { margin: '0.9375rem 0' };

    const Icon = {
        add: ['fal', 'square-plus'],
        addError: ['fal', 'square-plus'],
        list: ['far', 'chevron-right'],
        edit: ['far', 'pen-to-square'],
    };


    return (
        <Box
            sx={{
                ...css.sxAddressBox.default,
                ...css.sxAddressBox[mode],
            }}
            onClick={onClick}>
            <Box sx={{ display: 'flex' }}>
                <Box style={{
                    ...css.sxLocationIcon,
                }}>
                    <I
                        icon={['fas', 'location-dot']}
                        style={{ ...css.sxIconLocation }} />
                </Box>
                <Box sx={{
                    ...handleMargin,
                }}>
                    {nickname &&
                        <Box sx={{
                            margin: '0.625rem 0.9375rem',
                        }}>
                            <Box sx={{
                                ...css.sxTypeAddress,
                            }}>
                                {nickname}
                            </Box>
                        </Box>
                    }
                    <Box sx={{
                        margin: '0 0.9375rem',
                    }}>
                        <Typography
                            variant='body1'
                            sx={{ ...css.sxTitle }}>
                            {title}
                        </Typography>
                        <Typography
                            variant='body2'
                            sx={{ ...css.sxLegend }}>
                            {legend}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                ...css.sxBoxIconAction,
            }}>
                {!noIcon &&
                    <I
                        icon={Icon[mode] as any}
                        style={{ ...css.sxIcon }} />
                }
                {radio && (
                    <Radio
                        size='small'
                        sx={{}}
                        {...radio}
                    />
                )}
            </Box>
        </Box>
    );
};

export { AddressBox };
