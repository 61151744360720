import { useTheme } from '@mui/material';

const sx = () => {
    const { typography, palette } = useTheme();
    const { pxToRem } = typography;
    const css = {
        wrapper: (disabled: boolean) => {
            return {
                display: 'flex',
                alignItems: 'center',
                backgroundColor: palette.clouds,
                borderWidth: pxToRem(1),
                borderStyle: 'solid',
                borderColor: palette.clouds,
                borderRadius: pxToRem(4),
                height: pxToRem(60),
                width: '100%',
                cursor: 'pointer',
                opacity: disabled ? 0.7 : 1,
            };
        },
        icon: {
            pl: pxToRem(12),
            pr: pxToRem(2),
            pt: pxToRem(20),
            pb: pxToRem(20),
        },
    };
    return { css };
};

export { sx };
