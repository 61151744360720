export const responseLists = {
    data: [],
    message: '',
    isLoaded: false,
};

export const DEFAULTS = {
    location: {
        states: [],
        cities: [],
    },
    quicklinks: responseLists,
    showcases: responseLists,
};
