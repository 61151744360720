import styled from 'styled-components';

export const Input = styled.input`
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    width: 100%;
    padding: 1rem 0;
    padding-right: 0.9375rem;
    border: none;
    outline: none;
    border-radius: 0.25rem;
`;

interface FormProps {
    noMargin?: boolean;
    noShadow?: boolean;
    fromFaq?: boolean;
}

export const Form = styled.form<FormProps>`
    display: flex;
    align-Items: center;
    border: 0.0625rem solid #ECF0F1;
    border-Radius: 0.3125rem;
    background-color: #fff;
    width: 100%;
    max-width: 434px;
    min-width: ${(props: FormProps) => props.fromFaq ? 'auto' : '434px'};
    margin: 0.0125rem 0 !important;
    margin-left: ${(props: FormProps) => props.noMargin ? 0 : '0.75rem !important'};
    box-shadow: ${(props: FormProps) =>
        props.noShadow ? 'none' :
            '0px 0.375rem 0.4375rem #0000001A'};
    z-index: 999;
`;
