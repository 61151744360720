import { useTheme } from '@mui/material';

const sx = () => {
    const { typography } = useTheme();
    const { pxToRem } = typography;

    const css = {
        actions: {
            buttonsMoney: {
                '& .button-action:last-child': {
                    ml: pxToRem(10),
                },
            },
        },
        extract: {
            separate: {
                '& .history-item': {
                    mb: pxToRem(10),
                },
            },
        },
    };

    return { css };
};

export { sx };
