import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        sxInfo: {
            padding: '1.125rem 0',
            borderBottom: `0.0625rem solid ${palette.clouds}`,
        },
        sxBoxContentFooter: {
            display: 'flex',
            alignItems: 'center',
        },
        sxBoxImage: {
            margin: '1.25rem 1.875rem 1.25rem 0',
        },
        sxImage: {
            maxHeight: '1.6875rem',
            margin: '0.5rem 0',
        },
        sxBoxTopics: {
            display: 'flex',
            justifyContent: 'space-evenly',
            width: '100%',
            maxWidth: '37.5rem',
        },
        sxIcon: {
            fontSize: '1.75rem',
            width: '1.75rem',
            cursor: 'pointer',
            color: palette.dark,
        },
        sxBoxItems: {
            borderTop: `0.0625rem solid ${palette.clouds}`,
            borderBottom: `0.0625rem solid ${palette.clouds}`,
            display: 'flex',
            justifyContent: 'space-between',
            padding: '2.5rem',
        },
    };
    return {
        css,
    };
};
