import { useTheme } from '@mui/material';

const sx = () => {
    const { palette } = useTheme();

    const css = {
        content: {
            chip: {
                backgroundColor: palette.common.black,
                color: palette.common.white,
                textTransform: 'uppercase',
            },
        },
    };
    return { css };
};

export { sx };
