import React, { ForwardRefExoticComponent, RefAttributes } from 'react';
import { Alert, Snackbar as MuiSnackbar } from '@mui/material';

import { SnackbarCustomProps, ToggleStaticProps } from '@interfaces';

const STATIC_PROPS: ToggleStaticProps = {
    show: () => Boolean,
    hide: () => Boolean,
};

const Snackbar: ForwardRefExoticComponent<SnackbarCustomProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            message,
            autoHideDuration,
            severity,
        } = props;

        const [open, setOpen] = React.useState(false);

        const handleClickOpen = () => {
            setOpen(true);
        };

        const handleClose = (
            event?: React.SyntheticEvent | Event, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }

            setOpen(false);
        };
        React.useImperativeHandle(ref, () => ({
            show: () => handleClickOpen(),
            hide: () => handleClose(),
        }));

        return (
            <MuiSnackbar
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                open={open}
                autoHideDuration={autoHideDuration ?? 3000}
                onClose={handleClose}>
                <Alert onClose={handleClose}
                    severity={severity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </MuiSnackbar>
        );
    });

export { Snackbar };
