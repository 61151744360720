import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette, typography } = useTheme();
    const css = {
        sxBox: {
            'my': '1.875rem',
            'padding': '1.875rem 0.9375rem',
            'border': `0.0625rem solid ${palette.clouds}`,
            '& > h1': {
                fontSize: matches ? '1.25rem' : '1.125rem',
                fontWeight: typography.fontWeightSemiBold,
                pb: 3,
                pt: 1,
            },
            '& > h2': {
                fontSize: matches ? '1.125rem' : '1rem',
                fontWeight: typography.fontWeightMedium,
                paddingLeft: '0.9375rem',
                pb: 3,
            },
            '& > p': {
                'fontSize': '0.875rem',
                'mb': 2,
                '& > ul': {
                    'paddingLeft': '0.9375rem',
                    'listStyle': 'disc',
                    '& > li': {
                        pb: 2,
                    },
                },
            },
            '& > .MuiTypography-body2': {
                paddingLeft: '0.9375rem',
            },
        },
    };

    return { css };
};
