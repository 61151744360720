import React, { useState, useEffect } from 'react';

import {
    useParams,
    Link,
} from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
    Grid,
    Typography,
    Stack,
    Box,
    IconButton,
    Divider,
    Container,
    useMediaQuery,
} from '@mui/material';

import { questionsData } from '@static/faq';
import { HeaderPage, MessageBox } from '@components';

import { sx } from './sx';

interface QuestionsProps {
    id: string;
    question: string;
    topic: string;
    urlTopic: string;
    answer: string;
}

const Questions: React.FC = () => {
    const { topic, key } = useParams();
    const [questions, setQuestions] = useState([] as QuestionsProps[]);
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx();

    useEffect(() => {
        if (key) {
            const result =
                questionsData.filter((item) =>
                    item.question.toLowerCase().includes(key.toLowerCase()) ||
                    item.answer.toLowerCase().includes(key.toLowerCase()));
            setQuestions(result);
        } else {
            if (topic) {
                const result =
                    questionsData.filter((item) => item.urlTopic === topic);
                setQuestions(result);
            }
        }
    }, []);

    return (
        <Container>
            {matches &&
                <HeaderPage
                    icon={['far', 'circle-question']}
                    titleAlign={'default'}
                    title={'Central de ajuda'} />
            }
            <Box sx={{ ...css.sxBoxContainer }}>
                <Link
                    to={'/ajuda'}
                    style={{ ...css.sxLink }}>
                    <Typography
                        sx={{ ...css.sxTextLink }} variant='caption'>
                        <IconButton sx={{ ...css.sxIconLinkBack }}>
                            <I icon={['far', 'arrow-left-long']} />
                        </IconButton>
                        Voltar para a Central de Ajuda
                    </Typography>
                </Link>
                <Box sx={{ ...css.sxBoxGrid }}>
                    <Grid container spacing={2}>
                        <Grid item md={8} lg={8} sm={12} xs={12}>
                            <Typography sx={{ ...css.sxTitle }} variant='h2'>
                                {key ? 'Resultados para "'+key+'"' :
                                    questions.length > 0 && questions[0].topic}
                            </Typography>
                        </Grid>
                        {questions.length > 0 ?
                            <Grid item md={8} lg={8} sm={12} xs={12}>
                                <Stack
                                    divider={
                                        <Divider
                                            flexItem
                                            sx={{ ...css.sxDivider }}
                                            orientation="horizontal" />}>
                                    {questions.map((question) => (
                                        <Link
                                            key={question.id}
                                            style={{ ...css.sxLinkQuestion }}
                                            // eslint-disable-next-line max-len
                                            to={'/ajuda/'+question.urlTopic+'/'+question.id}
                                            state={{ fromSearch: key ? true : false }}>
                                            <Box
                                                sx={{ ...css.sxBoxQuestion }}>
                                                <Typography
                                                    sx={{ ...css.sxQuestionText }}
                                                    variant='h3'>
                                                    {question.question}
                                                </Typography>
                                                <I icon={['far', 'chevron-right']} />
                                            </Box>
                                        </Link>
                                    ))}
                                </Stack>
                            </Grid> :
                            <Grid item md={12} lg={12} sm={12} xs={12}>
                                <MessageBox
                                    message={'Não foi possível encontrar resultados'}
                                    type={'info'} />
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export { Questions };
