import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { HeaderPage, Layout, TableList } from '@components';
import {
    Box,
    IconButton,
    Table,
    TableBody,
    Typography,
    useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@hooks';
import { formatPhoneNumber } from '@utils';

const TabKey = ({ title }: { title: string }): JSX.Element => {
    const { typography } = useTheme();
    return (
        <Typography
            variant="subtitle1"
            component='span'
            sx={{
                fontSize: '.875rem',
                fontWeight: typography.fontWeightBold,
            }}>
            {title}
        </Typography>
    );
};


const Base = () => {
    const { palette } = useTheme();
    const { user } = useUser();
    const navigate = useNavigate();

    return (
        <Layout.AuthenticatedContent
            headerIcon={['far', 'gear']}
            headerTitle='Meu perfil'
            headerAction={() => { }}
        >
            {user.data &&
                <>
                    {user.data.cnpj &&
                        <Box>
                            <HeaderPage
                                titleAlign={'default'}
                                title={'Minha Loja'}
                                backgroundColor={palette.clouds}
                                action={
                                    <IconButton
                                        aria-label="edit"
                                        onClick={() =>
                                            navigate('/meu-perfil/alterar/loja')}
                                    >
                                        <I
                                            icon={['far', 'pen-to-square']}
                                            fontSize={18}
                                            color={palette.dark}
                                        />
                                    </IconButton>
                                }
                            />

                            <Table>
                                <TableBody>
                                    <TableList
                                        align='right'
                                        keyTab={<TabKey title='Título' />}
                                        value={user.data.minha_loja ?
                                            user.data.minha_loja.titulo :
                                            '-'}
                                    />
                                    <TableList
                                        align='right'
                                        keyTab={<TabKey title='Bio' />}
                                        value={user.data.minha_loja ?
                                            user.data.minha_loja.bio :
                                            '-'}
                                    />
                                    <TableList
                                        align='right'
                                        keyTab={<TabKey title='URL' />}
                                        value={user.data.minha_loja ?
                                            user.data.minha_loja.url :
                                            '-'}
                                    />
                                </TableBody>
                            </Table>
                        </Box>
                    }
                    <Box>
                        <HeaderPage
                            titleAlign={'default'}
                            title={'Dados Pessoais'}
                            backgroundColor={palette.clouds}
                            action={
                                <IconButton
                                    aria-label="edit"
                                    onClick={
                                        () =>
                                            navigate('/meu-perfil/alterar/dados-pessoais')
                                    }
                                >
                                    <I
                                        icon={['far', 'pen-to-square']}
                                        fontSize={18}
                                        color={palette.dark}
                                    />
                                </IconButton>
                            }
                        />

                        <Table>
                            {user.data &&
                                <TableBody>
                                    <TableList
                                        align='right'
                                        keyTab={<TabKey title='Nome' />}
                                        value={user.data.nome}
                                    />
                                    <TableList
                                        align='right'
                                        keyTab={<TabKey title='E-mail' />}
                                        value={user.data.email}
                                    />
                                    <TableList
                                        align='right'
                                        keyTab={<TabKey title='Senha' />}
                                        value={'*************'}
                                    />
                                    {user.data.cpf &&
                                        <TableList
                                            align='right'
                                            keyTab={<TabKey title='CPF' />}
                                            value={user.data.cpf}
                                        />
                                    }
                                    {user.data.telefone && (
                                        <TableList
                                            align='right'
                                            keyTab={<TabKey title='Celular' />}
                                            value={formatPhoneNumber({
                                                ddi: user.data.telefone?.ddi,
                                                ddd: user.data.telefone?.ddd,
                                                numero: user.data.telefone.numero,
                                            })}
                                        />
                                    )}
                                </TableBody>
                            }
                        </Table>
                    </Box>
                </>
            }
        </Layout.AuthenticatedContent>
    );
};

export { Base };
