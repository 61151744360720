export const weigthList = [
    {
        id: 'ae5a17d87a77',
        title: 'Adulto',
        children: [
            {
                id: 'c69f098852d4',
                title: 'Bermuda jeans = 400g',
            },
            {
                id: 'dd79f525e667',
                title: 'Blusa feminina = 150g',
            },
            {
                id: '5399144f9e35',
                title: 'Bota cano alto = 1,5k',
            },
            {
                id: '82a98110bd6c',
                title: 'Calça jeans adulto = 700g',
            },
            {
                id: 'b844ac1f56d3',
                title: 'Camisa = 250g',
            },
            {
                id: '968b22ddc158',
                title: 'Camiseta = 180g',
            },
            {
                id: 'f6795e3e3f11',
                title: 'Conjunto moletom adulto = 700g',
            },
            {
                id: '37b410cbee31',
                title: 'Jaqueta jeans = 400g',
            },
            {
                id: 'b0a7b0b00c9c',
                title: 'Pijamas = 300g',
            },
            {
                id: 'b271e5e738fe',
                title: 'Sandálias = 500g',
            },
            {
                id: 'efec054e9614',
                title: 'Short jeans = 300g',
            },
            {
                id: '69c0a3a0cd88',
                title: 'Tênis = 850g',
            },
            {
                id: '40ed54e5dee2',
                title: 'Tricots = 350g',
            },
            {
                id: 'b92c0197550d',
                title: 'Vestido = 200g',
            },

        ],
    },
    {
        id: '91d286fcb6eb',
        title: 'Infantil',
        children: [
            {
                id: '04fe19ac0f8a',
                title: 'Calça jeans infantil = 400g',
            },
            {
                id: '251f11c0d342',
                title: 'Conjunto moletom infantil = 500g',
            },
            {
                id: '4f558f64ce53',
                title: 'Roupas de bebê = 80g',
            },
            {
                id: '0632f5eab628',
                title: 'Roupas de criança = 120g',
            },
        ],
    },
];
