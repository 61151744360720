/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react';
import { MenuItem, MessageBox } from '@components';
import { Box, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { useOthers } from '@hooks';
import { BackItem } from '../../../../pages/menu/components/back-item';
import { useNavigate } from 'react-router-dom';

const Categories = () => {
    const { palette } = useTheme();
    const navigate = useNavigate();

    const { categories } = useOthers();
    const outString = (sourceString: string) => {
        if (sourceString) {
            return sourceString.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, '');
        }
    };

    React.useEffect(() => {
        if (!categories.list.categories.length) {
            categories.handleCategorization({});
        }
    }, []);

    return (
        <React.Fragment>
            <Box sx={{
                display: 'flex',
                backgroundColor: palette.clouds,
                overflow: 'hidden',
                overflowX: 'auto',
            }}>
                {categories.
                    list.
                    history.map(({ title, _id, categoria_pai }, i) => (
                        <Box
                            key={categoria_pai as string + i}>
                            <BackItem
                                onClick={async () =>
                                    await categories.historyCategorization({
                                        _id: _id,
                                        categoria_pai: categoria_pai,
                                        direction: 'BACK',
                                    })}
                                title={title as string} />
                        </Box>
                    ))}
            </Box>
            {
                categories.list.categories.length > 0 ?
                    <>
                        {categories.list.history.length > 0 &&
                            <MenuItem
                                onClick={() => {
                                    const categoryData =
                                        categories.
                                            list.
                                            history[categories.list.history.length - 1];
                                    // eslint-disable-next-line max-len
                                    navigate(`/resultados?category=${categoryData._id},${outString(categoryData.title as string)}`);
                                }}
                                borderType={'bottom'}
                                title={{
                                    text: 'Ver todos',
                                    color: palette.dark,
                                    size: 'medium',
                                }} />
                        }
                        {categories.list.categories.map(({
                            _id,
                            ativo,
                            lastLevel,
                            nome,
                            categoria_pai,
                        }) => {
                            return (ativo &&
                                <MenuItem
                                    key={`${nome}-${_id}`}
                                    onClick={() => !lastLevel ? categories.
                                        handleCategorization({
                                            _id: _id,
                                            direction: 'FORWARD',
                                            title: nome,
                                            categoria_pai: categoria_pai,
                                        }) : navigate(`/resultados?category=${_id},${outString(nome)}`)}
                                    borderType={'bottom'}
                                    title={{
                                        text: nome,
                                        color: palette.dark,
                                        size: 'medium',
                                    }}
                                    htmlFor={_id}>
                                    {!lastLevel && (
                                        <Box pl={2} pr={2}>
                                            <I
                                                style={{ fontSize: '1rem' }}
                                                icon={['far', 'chevron-right']}
                                            />
                                        </Box>
                                    )}
                                </MenuItem>
                            );
                        })}
                    </> :
                    <MessageBox
                        type='info'
                        message='Nenhuma categoria disponível'
                    />}
        </React.Fragment>
    );
};

export { Categories };
