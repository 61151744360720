import React from 'react';
import { useMediaQuery } from '@mui/material';
import { FooterDesktop, FooterMobile } from '@components';

const HandleFooter: React.FC = () => {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <>
            {matches ?
                <FooterDesktop /> :
                <FooterMobile
                    fixedMenu />
            }
        </>
    );
};

export { HandleFooter };
