import Mastercard from '@img/mastercard.svg';
import Amex from '@img/amex.svg';
import Dinners from '@img/dinners.svg';
import Hipercard from '@img/hipercard.svg';
import Visa from '@img/visa.svg';
import Default from '@img/default.png';

export const flagsIcon = {
    UnknownFlag: Default,
    Visa: Visa,
    Mastercard: Mastercard,
    DinnersClub: Dinners,
    AmericanExpress: Amex,
    Hipercard: Hipercard,
};
