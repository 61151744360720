/* eslint-disable max-len */
/* eslint-disable valid-jsdoc */
import {
    CartAddProps,
    CartListResumeProps,
    IncrementOrDecrementProps,
    StateCartListResumeProps,
    StateCartListStoresProps,
    StateCartSessionProps,
} from '@interfaces/Cart';
// import { CartRemoveProps } from '@types';
import React from 'react';
import { client } from '../../../api/client';
import { ShoppingContext } from '../../../contexts/shopping/context';
import { DEFAULTS } from './defaults';

const cartHookProvider = () => {
    const [cartStores,
        setCartStores] = React.useState<StateCartListStoresProps>(DEFAULTS.cartStores);
    const [cartResume,
        setCartResume] = React.useState<StateCartListResumeProps>(DEFAULTS.cartResume);
    const [cartSession,
        setCartSession] = React.useState<StateCartSessionProps>(DEFAULTS.cartSession);

    /**
     * Adiciona um item ao carrinho.
     * @param {number} idAd id do anúncio
     * @param {number} idVariation id da variação
     * @param {number} variationItemId id do item da variação (tamanho, cor).
     * @param {number} quantity quantidade de items.
     * @returns {void}
     */
    const add = async ({
        idAd,
        quantity,
        idVariation,
        variationItemId,
        replaceItem,
    }: CartAddProps) => {
        const { services } = client();
        const response = await services.shopping.cart.item.add({
            idAd,
            quantity,
            idVariation,
            variationItemId,
            replaceItem,
        });
        return response;
    };

    /**
     * Adiciona um item ao carrinho.
     * @param {number} idAd id do anúncio
     * @param {number} idVariation id da variação
     * @param {number} variationItemId id do item da variação (tamanho, cor).
     * @returns {void}
     */
    const remove = async ({ idAd, idVariation, variationItemId, quantity }: CartAddProps) => {
        const { services } = client();
        const response = await services.shopping.cart.item.remove({
            idAd,
            idVariation,
            variationItemId,
            quantity,
        });
        return response;
    };

    const incrementOrDecrement = ({
        storeID, productID, action,
    }: IncrementOrDecrementProps) => {
        const filterStore = cartStores.data.map((store) => {
            if (store._id === storeID) {
                const find = store.itens.find((product) => {
                    const equal = product.id._id === productID;

                    if (equal) {
                        const qtd = (action === 'increment' &&
                            (product.quantidade += 1) ||
                            action === 'decrement' &&
                            (product.quantidade -= 1)) as number;
                        const price = product.id.valor_por;
                        product.id.valor_por = price * qtd;

                        return product;
                    }
                });

                const filter = store.itens.filter((product) => product._id !== find?.id._id);

                return { ...store, itens: filter };
            }
            return store;
        });

        setCartStores((prev) => prev = {
            ...prev,
            data: filterStore,
        });
    };

    const calculateShipping = async () => {
        const { services } = client();
        const response = await services.shopping.cart.calculateShipping();

        return response;
    };

    const loadCartItems = async () => {
        const { services } = client();
        const response = await services.shopping.cart.load();

        setCartStores((prev) => prev = {
            data: response?.response.payload ?
                response?.response.payload.lojas :
                [],
            message: response?.response.message,
            isLoaded: !!(!response?.error),
        });

        setCartResume((prev) => prev = {
            data: response?.response.payload ?
                {
                    _id: response?.response.payload._id,
                    desconto: response?.response.payload.desconto,
                    total: response?.response.payload.total,
                    total_cashback: response?.response.payload.total_cashback,
                    total_frete: response?.response.payload.total_frete,
                    total_parcial: response?.response.payload.total_parcial,
                    total_produtos: response?.response.payload.total_produtos,
                } as CartListResumeProps :
                {} as CartListResumeProps,
            message: response?.response.message,
            isLoaded: !!(!response?.error),
        });
        return response;
    };

    // * Session to create cart
    const createSession = async () => {
        const { services } = client();
        const response = await services.shopping.cart.createSession();

        setCartSession((prev) => prev = {
            message: response?.response.message,
            isLoaded: !!(!response?.error),
        });
        return response;
    };

    const reset = () => {
        setCartStores((prev) => prev = DEFAULTS.cartStores);
        setCartResume((prev) => prev = DEFAULTS.cartResume);
        setCartSession((prev) => prev = DEFAULTS.cartSession);
    };

    const cart = {
        createSession,
        reset,
        item: {
            add,
            action: {
                increment: ({ storeID, productID }: IncrementOrDecrementProps) => incrementOrDecrement({ storeID, productID, action: 'increment' }),
                decrement: ({ storeID, productID }: IncrementOrDecrementProps) => incrementOrDecrement({ storeID, productID, action: 'decrement' }),
            },
            remove,
        },
        session: {
            status: cartSession,
            create: createSession,
        },
        stores: cartStores,
        resume: cartResume,
        load: loadCartItems,
        calculateShipping: calculateShipping,
    };
    return { cart };
};

const useCart = () => {
    const context = React.useContext(ShoppingContext);
    return context;
};

export { useCart, cartHookProvider };

// cart.item.add();
// cart.item.remove();
// cart.list();
