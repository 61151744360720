import React from 'react';
import { ShoppingContext } from './context';
import { cartHookProvider } from '../../hooks/shopping/cart';
import { checkoutHookProvider } from '../../hooks/shopping/checkout';
import { purchasesProviderHook } from '../../hooks/shopping/purchases';
import { salesProviderHook } from '../../hooks/shopping/sales';

interface ShoppingProviderProps {
    children: React.ReactNode;
};

const ShoppingProvider = ({ children }: ShoppingProviderProps) => {
    const cart = cartHookProvider();
    const checkout = checkoutHookProvider();
    const purchases = purchasesProviderHook();
    const sales = salesProviderHook();

    return (
        <ShoppingContext.Provider value={{
            ...cart,
            ...checkout,
            ...purchases,
            ...sales,
        }}>
            {children}
        </ShoppingContext.Provider>
    );
};

export { ShoppingProvider };
