import { useTheme, useMediaQuery } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        sxLink: {
            textDecoration: 'none',
            color: palette.dark,
        },
        sxBox: {
            'border': matches ? `1px solid ${palette.clouds}` : 'none',
            'borderBottom': `1px solid ${palette.clouds}`,
            'padding': matches ? '0.9375rem 1.875rem' : '0',
            'cursor': 'pointer',
            '&:hover': {
                'span': {
                    textDecoration: 'underline',
                },
            },
        },
        sxBoxTitle: {
            display: 'flex',
            justifyContent: matches ? 'flex-start' : 'space-between',
            alignItems: matches ? 'normal' : 'center',
        },
        sxTitle: {
            fontWeight: typography.fontWeightSemiBold,
            fontSize: matches ? '1.125rem' : '1rem',
            padding: matches ? '0.5625rem 0' : '0.75rem 0.9375rem',
        },
        sxLegend: {
            fontWeight: typography.fontWeightRegular,
            fontSize: '0.875rem',
            paddingBottom: '1.875rem',
        },
        sxLinkText: {
            color: palette.dark,
            fontWeight: typography.fontWeightSemiBold,
            fontSize: '0.875rem',
        },
        sxIconButton: {
            padding: '0.9375rem',
            fontSize: '1rem',
            color: palette.dark,
        },
    };
    return { css };
};
