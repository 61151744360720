/* eslint-disable quotes */
/* eslint-disable no-unused-vars */
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Navigation, ProfileCover, Snackbar } from '@components';
import {
    Avatar,
    Box,
    Container,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { ButtonUploadCover } from './styles';
import { sx } from './sx';
import { useUser } from '../../../../hooks/user';
import { images } from '@utils';
import Banner from '@img/user-cover.png';
import UserAvatar from '@img/avatar.png';
import { ToggleStaticProps } from '@interfaces';
import { skeleton } from './skeleton';
interface HandleImageUploadProps {
    file: File;
    type: 'banner' | 'avatar';
};

const DEFAULTS = {
    avatar: {
        isLoading: false,
    },
    banner: {
        isLoading: false,
    },
};

const Authenticated = () => {
    const { css } = sx();
    const { user, userMenu, uploadImage, loadUser } = useUser();
    const { typography, palette } = useTheme();
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const matches = useMediaQuery('(min-width:600px)');
    const skel = skeleton();
    const uploadref = React.useRef<HTMLInputElement>(null);

    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();
    const [loading, setLoading] = React.useState(DEFAULTS);

    const avatarSize = !matches ? 56 : 70;

    const clearRef = () => {
        if (uploadref.current?.value) {
            return uploadref.current.value = '';
        };
    };

    const handleImageUpload = async ({ file, type }: HandleImageUploadProps) => {
        const fileTypes = ['image/png', 'image/jpeg'];

        for (let i = 0; fileTypes.length > i; i++) {
            if (fileTypes[i].includes(file.type)) {
                setLoading((prev) => prev = {
                    ...prev,
                    [type]: {
                        isLoading: true,
                    },
                });
                const formDataImage = new FormData();
                formDataImage.append('imageToChange', file);

                const response = await uploadImage({
                    formDataImage: formDataImage,
                    type: type,
                });

                if (!response?.error) {
                    loadUser();
                }
                snackRef.current?.show();
                setFeedback({
                    type: response?.response.type,
                    message: response?.response.message,
                });
                setLoading((prev) => prev = {
                    ...prev,
                    [type]: {
                        isLoading: false,
                    },
                });
                return;
            }
        };

        clearRef();
        snackRef.current?.show();
        setFeedback({
            type: 'error',
            message: `o tipo do arquivo ${file.name} não é válido`,
        });
    };

    return (
        <React.Fragment>
            <Container maxWidth="lg" className={!matches ? 'no-padding' : ''} >
                <Box>
                    <Box sx={{ position: 'relative' }}>
                        <ButtonUploadCover
                            size="medium"
                            variant="contained">
                            <label htmlFor="upload-cover">
                                <input
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            handleImageUpload({
                                                file: e.target.files[0],
                                                type: 'banner',
                                            });
                                        }
                                    }
                                    }
                                    id="upload-cover"
                                    hidden
                                    accept="image/png,image/jpeg"
                                    type="file" />

                                Alterar foto da vitrine
                            </label>
                        </ButtonUploadCover>

                        {!loading.banner.isLoading ? (
                            <ProfileCover src={
                                user.data?.imagens && user.data?.imagens.banner ?
                                    images.load(user.data?.imagens.banner, 'server') :
                                    images.load(Banner, 'local')
                            } />
                        ) : skel.banner}
                    </Box>
                    <Box
                        sx={{
                            ...css.avatar.group,
                            alignItems: !matches ? 'center' : 'start',
                            paddingLeft: !matches ? 0 : '15px',
                            paddingBottom: !matches ? 0 : '24px',
                            border: matches ?
                                '1px solid ' + palette.clouds :
                                0,
                        }}
                    >
                        <Box sx={{ position: 'relative' }}>
                            <IconButton aria-label="delete" size="small"
                                sx={{
                                    ...css.avatar.buttonUploadImageProfile,
                                }}
                                component='label'>
                                <input
                                    onChange={(e) => {
                                        if (e.target.files) {
                                            handleImageUpload({
                                                file: e.target.files[0],
                                                type: 'avatar',
                                            });
                                        }
                                    }}
                                    ref={uploadref}
                                    hidden
                                    accept="image/png,image/jpeg"
                                    type="file" />
                                <I
                                    icon={['fas', 'camera']}
                                    fontSize={16}
                                    color={palette.common.black}
                                />
                            </IconButton>
                            {!loading.avatar.isLoading ? (

                                <Avatar
                                    alt={user.data ? user.data.nome : ''}
                                    src={user.data?.imagens && user.data?.imagens.avatar ?
                                        images.load(user.data?.imagens.avatar, 'server') :
                                        images.load(UserAvatar, 'local')}
                                    sx={{
                                        ...css.avatar.avatarSize(avatarSize),
                                    }}
                                />
                            ) : skel.avatar}
                        </Box>
                        <Box
                            sx={{
                                ...css.avatar.typography.group(matches),
                            }}>
                            <Typography
                                variant='h2'
                                fontSize={16}
                                fontWeight={typography.fontWeightBold}
                                sx={{
                                    marginTop: '.375rem',
                                }}>
                                {user.data ? user.data.nome : ''}
                            </Typography>
                            <Typography
                                variant='body1'
                                fontSize={12}
                                fontWeight={typography.fontWeightMedium}>
                                {user.data && user.data?.endereco.length > 0 &&

                                    `${user.data?.endereco[0]
                                        .cidade} - ${user.data?.endereco[0].estado}`
                                }
                            </Typography>
                        </Box>
                    </Box>

                </Box>
            </Container>
            <Container maxWidth="lg" className={!matches ? 'no-padding' : ''}>

                <Box sx={{ marginTop: 2, marginBottom: 4 }}>
                    <Grid
                        container
                        spacing={2}>
                        {matches && (
                            <Grid item xs={3} sm={4} md={3}>
                                <Box
                                    sx={css.navigation}>
                                    {userMenu.isLoaded && userMenu.data &&
                                        <Navigation.User
                                            menu={userMenu.data.filter(
                                                (item) => item.nome !== 'Ajuda')
                                            } />
                                    }
                                </Box>
                            </Grid>
                        )}
                        <Grid item xs={matches ? 9 : 12} sm={8} md={9}>
                            <Outlet />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <Snackbar
                ref={snackRef}
                message={feedback ? feedback.message : ''}
                severity={feedback ? feedback.type as any : 'info'}
            />
        </React.Fragment>
    );
};

export { Authenticated };
