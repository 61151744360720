import { Box, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

interface VariationHeadingProps {
    title: string;
    subtitle?: string;
    children?: React.ReactNode;
};

const VariationHeading: React.FC<VariationHeadingProps> = (
    { title, subtitle, children }: VariationHeadingProps) => {
    const { typography, palette } = useTheme();

    const rm = (px: number) => typography.pxToRem(px);
    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'
            mb={rm(20)}>
            <Box
                display='flex'
                alignItems='center'>
                <Box mt={-0.5} mr={rm(20)}>
                    <I
                        icon={['far', 'clothes-hanger']}
                        fontSize={23}
                        color={palette.darkGrey} />
                </Box>
                <Box>
                    <Typography
                        variant="h1"
                        fontSize={rm(16)}
                        fontWeight={700}
                        color={palette.dark}
                        gutterBottom>
                        {title}
                    </Typography>
                    {subtitle && (
                        <Typography
                            variant="body1"
                            fontSize={rm(14)}
                            fontWeight={500}
                            color={palette.dark}
                            component='p'>
                            {subtitle}
                        </Typography>
                    )}
                </Box>
            </Box>
            {children && (
                <Box pl={2}>
                    {children}
                </Box>
            )}
        </Box>
    );
};

export { VariationHeading };
