import { Template } from '@components';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const CheckoutAddressAddEdit = () => {
    const { palette, typography } = useTheme();
    const rem = (px: number) => typography.pxToRem(px);
    const navigate = useNavigate();

    return (
        <Container maxWidth="xs">
            <Box pt={rem(30)} pb={rem(30)}>
                <Typography
                    variant="body1"
                    fontWeight={typography.fontWeightBold}
                    color={palette.dark}
                    mb={rem(5)}>
                    Endereço de entrega
                </Typography>

                <Typography
                    variant="subtitle2"
                    fontWeight={typography.fontWeightMedium}
                    color={palette.dark}>
                    Confirme seu endereço de entrega.
                </Typography>
                <Box mt={rem(20)}>
                    <Template.FormAddress
                        // address={existsAddress ?
                        //     location.state :
                        //     undefined}
                        location='address'
                        cancelAction={() => navigate('/checkout/endereco')} />
                </Box>
            </Box>
        </Container>
    );
};

export { CheckoutAddressAddEdit };
