import React from 'react';
import $ from 'jquery';
import 'jquery-mask-plugin';

import {
    HeaderPage,
    ActionSmallButton,
    Page,
    AddressBox,
    MuiDialog,
    MenuItem,
    Snackbar,
} from '@components';

import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Container,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';

import {
    CreditCardProps,
    ErrorsProps,
    FormAddressAPI,
    FormPaymentMethodCreditCard,
    ToggleStaticProps,
} from '@interfaces';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { CreditCardIMG } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchemaAdd, formSchemaUpdate } from './yup';
import { usePaymentMethod, useUser } from '@hooks';
import { addressFormat } from '@utils';
import { flagsIcon } from '@static/payment-method';

interface CreditCardFlagRegexp {
    Visa: RegExp;
    Mastercard: RegExp;
    DinersClub: RegExp;
    AmericanExpress: RegExp;
    Hipercard: RegExp;
};

type CreditCardFlagType =
    'UnknownFlag' | 'Visa' | 'Mastercard' |
    'DinnersClub' | 'AmericanExpress' | 'Hipercard';

const PaymentAddEditMethod: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user, loadUser } = useUser();
    const { typography, palette } = useTheme();
    const { creditCard } = usePaymentMethod();

    const [defaultCard] = React.useState<CreditCardProps | null>(
        location.state && location.state.card ? location.state.card : null);
    const [editMode] = React.useState<boolean | null>(
        defaultCard ? true :
            location.state && location.state.editMode ? location.state.editMode : null,
    );
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const dialogConfirm = React.useRef<ToggleStaticProps>(null);
    const dialogSelectAddress = React.useRef<ToggleStaticProps>(null);
    const [billingAddress, setBillingAddress] =
        React.useState<FormAddressAPI | undefined>(
            defaultCard ? defaultCard.endereco_cobranca : undefined);
    const [addressError, setAddressError] = React.useState<boolean>(false);
    const [creditCardFlag, setCreditCardFlag] = React.useState<
        CreditCardFlagType>('UnknownFlag');
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
        isLoading: false,
    });

    const [cardErrors, setCardErrors] = React.useState<ErrorsProps>({
        listErrors: {
            error: false,
            location: '',
            msg: '',
            param: '',
            value: '',
        },
    });
    const keys = Object.keys(cardErrors);

    const { register, handleSubmit, getValues, formState: { errors }, setValue } =
        useForm<FormPaymentMethodCreditCard>({
            resolver: yupResolver(editMode ? formSchemaUpdate : formSchemaAdd),
            mode: 'onBlur',
        });

    const handleDeleteItemCart = async () => {
        if (defaultCard) {
            const response = await creditCard.delete({
                cardId: defaultCard._id,
            });
            if (!response?.error) {
                dialogConfirm.current?.hide();
                snackRef.current?.show();
                setResponseData((prev) => prev = {
                    ...prev,
                    type: response?.response.type,
                    message: response?.response.message,
                    isLoading: false,
                });
                setTimeout(() => navigate('/metodos-de-pagamento/meus-metodos'), 1500);
            }
        }
    };

    const handleAddAddress = () => {
        const data = getValues();
        creditCard.current.addCCData({
            action: 'credit_card',
            creditCard: {
                token_cartao: '',
                cardInfos: {
                    _id: defaultCard ? defaultCard._id :
                        creditCard.current.data &&
                        creditCard.current.data.cardInfos &&
                        creditCard.current.data.cardInfos._id,
                    ano_expiracao: data.cc_expires_year,
                    bandeira: 'Cartão de crédito',
                    cpf: data.cc_cpf,
                    cvv: data.cc_cvv,
                    mes_expiracao: data.cc_expires_month,
                    numero_cartao: defaultCard ?
                        defaultCard.numero_cartao : data.cc_number,
                    nome: data.cc_name,
                    endereco: billingAddress,
                },
            },
        });
        if (user.data && user.data.endereco.length < 1) {
            navigate({
                pathname: '/meus-enderecos/adicionar',
                search: `?action=payment-${defaultCard ? 'edit' : 'add'
                }`,
            });
        } else {
            dialogSelectAddress.current?.show();
        }
    };

    const checkCreditCardType = (e: string) => {
        if (e !== undefined && e.length > 3) {
            const cardnumber = e.replace(' ', '').replace(/[^0-9]+/g, '');
            const emptyFields = 16 - cardnumber.length;
            let zeros = '';
            for (let count = 0; count < emptyFields; count++) {
                zeros += '0';
            }

            const cards: CreditCardFlagRegexp = {
                Visa: /\b4\d{3}[ -]*\d{4}[ -]*\d{4}[ -]*\d(?:\d{3})?\b/,
                Mastercard: /\b5[1-5]\d{2}[ -]*\d{4}[ -]*\d{4}[ -]*\d{4}\b/,
                DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
                AmericanExpress: /^3[47][0-9]{13}$/,
                Hipercard: /\b(3841|6062)\d{12}\b/,
            };

            for (const flag in cards) {
                if (cards[flag as keyof CreditCardFlagRegexp]
                    .test((cardnumber + zeros))) {
                    return setCreditCardFlag(flag as CreditCardFlagType);
                }
            }

            return setCreditCardFlag('UnknownFlag');
        } else {
            return setCreditCardFlag('UnknownFlag');
        }
    };


    const onSubmit: SubmitHandler<FormPaymentMethodCreditCard> = async (data) => {
        checkCreditCardType(data.cc_number);
        creditCard.current.addCCData({
            action: 'credit_card',
            creditCard: {
                token_cartao: '',
                cardInfos: {
                    ...creditCard.current.data.cardInfos &&
                    creditCard.current.data.cardInfos,
                    ano_expiracao: data.cc_expires_year,
                    bandeira: creditCardFlag,
                    cpf: data.cc_cpf,
                    cvv: data.cc_cvv,
                    mes_expiracao: data.cc_expires_month,
                    numero_cartao: data.cc_number,
                    nome: data.cc_name,
                    endereco: billingAddress,
                },
            },
        });
        if (!billingAddress) {
            setAddressError(true);
        } else {
            setResponseData((prev) => prev = {
                ...prev,
                isLoading: true,
            });
            const response = await creditCard.handlePaymentMethod(
                {
                    creditCardId: defaultCard ? defaultCard._id :
                        creditCard.current.data.cardInfos &&
                        creditCard.current.data.cardInfos._id &&
                        creditCard.current.data.cardInfos._id,
                    cardInfos: {
                        ...creditCard.current.data.cardInfos &&
                        creditCard.current.data.cardInfos,
                        ano_expiracao: data.cc_expires_year,
                        bandeira: creditCardFlag,
                        cpf: data.cc_cpf,
                        cvv: data.cc_cvv,
                        mes_expiracao: data.cc_expires_month,
                        numero_cartao: data.cc_number,
                        nome: data.cc_name,
                        endereco: billingAddress,
                    },
                });


            if (response && !response.error) {
                snackRef.current?.show();
                creditCard.current.resetData();
                loadUser();
                setCardErrors({
                    listErrors: {
                        error: false,
                        location: '',
                        msg: '',
                        param: '',
                        value: '',
                    },
                });
                setResponseData((prev) => prev = {
                    ...prev,
                    type: response?.response.type,
                    message: response?.response.message,
                    isLoading: false,
                });
                setTimeout(() => navigate('/metodos-de-pagamento/meus-metodos'), 1500);
            } else {
                creditCard.current.resetData();
                if (response &&
                    response.response.errors && response.response.errors.errors) {
                    setResponseData((prev) => prev = {
                        ...prev,
                        isLoading: false,
                    });
                    const filterErrors = response.response.errors.errors.
                        map((err: any, i: number) => {
                            const paramsKeys = err.param.split('.');
                            const key = paramsKeys[paramsKeys.length - 1];
                            const object = {
                                [key]: {
                                    location: err.location,
                                    msg: err.msg,
                                    param: err.param,
                                    error: true,
                                },
                            };

                            if (err.param !== keys[i]) {
                                return (object);
                            }

                            return (object);
                        });

                    const reduceErrors = filterErrors.reduce(
                        (acc: any, current: any) => ({
                            ...acc,
                            ...current,
                        }),
                        {},
                    );
                    setCardErrors((prev) => prev = reduceErrors);
                } else {
                    if (response && response.response.message) {
                        snackRef.current?.show();
                        setResponseData((prev) => prev = {
                            ...prev,
                            type: response?.response.type,
                            message: response?.response.message,
                            isLoading: false,
                        });
                    }
                }
            }
        }
    };

    const inputMasks = () => {
        $('.number').mask('ZZZZ ZZZZ ZZZZ ZZZZ', {
            translation: {
                'Z': {
                    pattern: /[0-9*]/,
                },
            },
        });
        $('.cpf').mask('000.000.000-00');
        $('.month').mask('00');
        $('.year').mask('0000');
        $('.cvv').mask('ZZZ', {
            translation: {
                'Z': {
                    pattern: /[0-9*]/,
                },
            },
        });
    };

    React.useEffect(() => {
        inputMasks();
        if (defaultCard) {
            setBillingAddress(defaultCard.endereco_cobranca);
            setAddressError(false);
            creditCard.current.addCCData({
                action: 'credit_card_address',
                creditCard: {
                    cardInfos: {
                        ...creditCard.current.data.cardInfos,
                        _id: defaultCard._id,
                        endereco: defaultCard.endereco_cobranca,
                    },
                },
            });
        }
        if (creditCard.current && creditCard.current.data &&
            creditCard.current.data.cardInfos) {
            checkCreditCardType(creditCard.current.data.cardInfos.numero_cartao);
            if (creditCard.current.data.cardInfos.endereco?.cep) {
                setBillingAddress(creditCard.current.data.cardInfos.endereco);
                setAddressError(false);
            }
        }
    }, []);

    return (
        <React.Fragment>
            <Page.Wrapper border position='fixed'>
                <HeaderPage
                    titleAlign={'default'}
                    icon={['far', 'chevron-left']}
                    title={'Método de pagamento'}
                    onClick={() => {
                        creditCard.current.resetData();
                        navigate('/metodos-de-pagamento/meus-metodos');
                    }}
                    action={
                        !(window.location.pathname ===
                            '/metodos-de-pagamento/adicionar') ?
                            <ActionSmallButton
                                backgroundColor={palette.dark}
                                text={'Excluir'}
                                icon={['far', 'trash-can']}
                                onClick={() => dialogConfirm.
                                    current?.
                                    show()} /> :
                            null
                    } />
                <Page.Content paddingBottom heightCalc={1}>
                    <Container maxWidth='lg'>
                        <Box sx={{ mt: 4, mb: 4 }}>
                            <Typography
                                variant="h2"
                                component='p'
                                mb={4}
                                fontSize={16}
                                fontWeight={typography.fontWeightMedium}
                                color={palette.dark}
                                textAlign='center'
                                gutterBottom>
                                Preencha os campos abaixo com os dados
                                do seu cartão de crédito.
                            </Typography>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            disabled={!!defaultCard || !!editMode}
                                            defaultValue={
                                                (defaultCard &&
                                                    defaultCard.numero_cartao) ||
                                                (creditCard.current.data.cardInfos &&
                                                    creditCard.current.data
                                                        .cardInfos.numero_cartao)}
                                            label={'Número do cartão'}
                                            placeholder='0000 0000 0000 0000'
                                            variant="outlined"
                                            helperText={
                                                (cardErrors.hasOwnProperty(
                                                    'numero_cartao') &&
                                                    cardErrors.numero_cartao.msg) ||
                                                (!!errors.cc_number?.message &&
                                                    errors.cc_number.message)}
                                            error={
                                                (cardErrors.hasOwnProperty(
                                                    'numero_cartao') &&
                                                    !!cardErrors.numero_cartao.error) ||
                                                !!errors.cc_number}
                                            inputProps={{
                                                className: 'number',
                                                onBlur: (e) => {
                                                    setValue('cc_number', e.target.value);
                                                    checkCreditCardType(e.target.value);
                                                },
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start">
                                                        <IconButton>
                                                            <CreditCardIMG
                                                                src={
                                                                    flagsIcon[
                                                                        creditCardFlag]}
                                                                alt='credit card' />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...register('cc_number',
                                                { required: true },
                                            )}
                                            onChange={(e) => {
                                                setValue('cc_number', e.target.value);
                                                checkCreditCardType(e.target.value);
                                            }}
                                            onSelect={(e) => {
                                                if (!getValues('cc_number')) {
                                                    checkCreditCardType('');
                                                }
                                            }} />
                                    </Grid>
                                    <Grid item xs={12} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            defaultValue={
                                                (defaultCard &&
                                                    defaultCard.nome) ||
                                                (creditCard.current.data.cardInfos &&
                                                    creditCard.current.data
                                                        .cardInfos.nome)}
                                            label={'Nome do titular como no cartão'}
                                            variant="outlined"
                                            helperText={
                                                (cardErrors.hasOwnProperty('nome') &&
                                                    cardErrors.nome.msg) ||
                                                (!!errors.cc_name?.message &&
                                                    errors.cc_name.message)}
                                            error={
                                                (cardErrors.hasOwnProperty(
                                                    'nome') &&
                                                    !!cardErrors.nome.error) ||
                                                !!errors.cc_name}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start">
                                                        <IconButton>
                                                            <I
                                                                icon={
                                                                    ['fas', 'user']
                                                                }
                                                                fontSize={16}
                                                                color={
                                                                    palette.dark
                                                                }
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...register('cc_name',
                                                { required: true },
                                            )} />
                                    </Grid>
                                    <Grid item xs={12} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            defaultValue={
                                                (defaultCard &&
                                                    defaultCard.cpf) ||
                                                (creditCard.current.data.cardInfos &&
                                                    creditCard.current.data
                                                        .cardInfos.cpf)}
                                            label={'CPF do titular'}
                                            placeholder='000.000.000-00'
                                            variant="outlined"
                                            helperText={
                                                (cardErrors.hasOwnProperty('cpf') &&
                                                    cardErrors.cpf.msg) ||
                                                (!!errors.cc_cpf?.message &&
                                                    errors.cc_cpf.message)}
                                            error={
                                                (cardErrors.hasOwnProperty(
                                                    'cpf') &&
                                                    !!cardErrors.cpf.error) ||
                                                !!errors.cc_cpf}
                                            inputProps={{
                                                className: 'cpf',
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment
                                                        position="start">
                                                        <IconButton>
                                                            <I
                                                                icon={
                                                                    ['fas',
                                                                        'address-card']
                                                                }
                                                                fontSize={16}
                                                                color={
                                                                    palette.dark
                                                                }
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...register('cc_cpf',
                                                { required: true },
                                            )} />
                                    </Grid>
                                    <Grid item xs={4} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            placeholder='MM'
                                            label={'Mês'}
                                            defaultValue={
                                                (defaultCard &&
                                                    defaultCard.mes_expiracao) ||
                                                (creditCard.current.data.cardInfos &&
                                                    creditCard.current.data
                                                        .cardInfos.mes_expiracao)}
                                            inputProps={{
                                                className: 'month',
                                            }}
                                            variant="outlined"
                                            helperText={
                                                (cardErrors.hasOwnProperty(
                                                    'mes_expiracao') &&
                                                    cardErrors.mes_expiracao.msg) ||
                                                (!!errors.cc_expires_month?.message &&
                                                    errors.cc_expires_month.message)}
                                            error={
                                                (cardErrors.hasOwnProperty(
                                                    'mes_expiracao') &&
                                                    !!cardErrors.mes_expiracao.error) ||
                                                !!errors.cc_expires_month}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton>
                                                            <I
                                                                icon={['fas',
                                                                    'calendar-star']
                                                                }
                                                                fontSize={16}
                                                                color={palette.
                                                                    dark}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...register('cc_expires_month',
                                                { required: true },
                                            )} />
                                    </Grid>
                                    <Grid item xs={4} mb={1}>
                                        <TextField
                                            focused
                                            placeholder='AAAA'
                                            fullWidth
                                            label={'Ano'}
                                            defaultValue={
                                                (defaultCard &&
                                                    defaultCard.ano_expiracao) ||
                                                (creditCard.current.data.cardInfos &&
                                                    creditCard.current.data
                                                        .cardInfos.ano_expiracao)}
                                            inputProps={{
                                                className: 'year',
                                            }}
                                            variant="outlined"
                                            helperText={
                                                (cardErrors.hasOwnProperty(
                                                    'ano_expiracao') &&
                                                    cardErrors.ano_expiracao.msg) ||
                                                (!!errors.cc_expires_year?.message &&
                                                    errors.cc_expires_year.message)}
                                            error={
                                                (cardErrors.hasOwnProperty(
                                                    'ano_expiracao') &&
                                                    !!cardErrors.ano_expiracao.error) ||
                                                !!errors.cc_expires_year}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton>
                                                            <I
                                                                icon={['fas',
                                                                    'calendar-days']
                                                                }
                                                                fontSize={16}
                                                                color={palette.
                                                                    dark}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...register('cc_expires_year',
                                                { required: true },
                                            )} />
                                    </Grid>
                                    <Grid item xs={4} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            label={'CVV'}
                                            disabled={!!defaultCard || !!editMode}
                                            defaultValue={(!!editMode && '***') ||
                                                (creditCard.current.data.cardInfos &&
                                                    creditCard.current.data
                                                        .cardInfos.cvv)}
                                            inputProps={{
                                                className: 'cvv',
                                            }}
                                            variant="outlined"
                                            helperText={
                                                (cardErrors.hasOwnProperty(
                                                    'cvv') &&
                                                    cardErrors.cvv.msg) ||
                                                (!!errors.cc_cvv?.message &&
                                                    errors.cc_cvv.message)}
                                            error={
                                                (cardErrors.hasOwnProperty(
                                                    'cvv') &&
                                                    !!cardErrors.cvv.error) ||
                                                !!errors.cc_cvv}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <IconButton>
                                                            <I
                                                                icon={['fas',
                                                                    'lock']}
                                                                fontSize={16}
                                                                color={palette.
                                                                    dark}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            {...register('cc_cvv',
                                                { required: true },
                                            )} />
                                    </Grid>
                                </Grid>
                                <Box sx={{ mt: 2, mb: 2 }}>
                                    {billingAddress ?
                                        <AddressBox
                                            mode={'edit'}
                                            onClick={() => handleAddAddress()}
                                            nickname={billingAddress.apelido}
                                            title={`CEP: ${billingAddress.cep}`}
                                            legend={addressFormat({
                                                logradouro: billingAddress.logradouro,
                                                bairro: billingAddress.bairro,
                                                cidade: billingAddress.cidade,
                                                estado: billingAddress.estado,
                                                numero: Number(billingAddress.numero),
                                                complemento: billingAddress.complemento,
                                            })} /> :
                                        <AddressBox
                                            mode={addressError ? 'addError' : 'add'}
                                            title={'Adicionar Endereço de Cobrança'}
                                            legend={'Clique para adicionar ' +
                                                'um endereço'}
                                            onClick={() => handleAddAddress()}
                                        />
                                    }
                                </Box>
                                {addressError &&
                                    <Typography sx={{
                                        fontSize: '12px',
                                        margin: '0 14px',
                                        position: 'relative',
                                        top: -10,
                                        color: '#d32f2f',
                                    }}>
                                        Adicionar um endereço é obrigatório
                                    </Typography>
                                }
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    mt={4}>
                                    <Box
                                        maxWidth={320}
                                        width='100%'
                                        justifyContent='center'>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            size="large"
                                            type='submit'
                                            onClick={() => {
                                                if (!billingAddress) {
                                                    setAddressError(true);
                                                }
                                            }}
                                            className='MuiButton secondary'
                                        >
                                            Salvar {responseData.isLoading && (
                                                <CircularProgress
                                                    size={14}
                                                    color='inherit'
                                                    sx={{ ml: 0.5 }} />)}
                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Container>
                    <Snackbar
                        ref={snackRef}
                        message={responseData.message}
                        severity={responseData.type}
                    />
                </Page.Content>
            </Page.Wrapper>
            <MuiDialog
                title='Endereço de cobrança'
                showDialogTitle
                ref={dialogSelectAddress}
                maxWidth={'sm'}>
                <Box sx={{ padding: '15px' }}>
                    {user.data && user.data.endereco.length > 0 &&
                        user.data.endereco.map((address) => (
                            <Box key={address._id} sx={{ mb: 1 }}>
                                <AddressBox
                                    mode={'list'}
                                    nickname={address.apelido}
                                    title={`CEP: ${address.cep}`}
                                    legend={addressFormat({
                                        logradouro: address.logradouro,
                                        bairro: address.bairro,
                                        cidade: address.cidade,
                                        estado: address.estado,
                                        numero: Number(address.numero),
                                        complemento: address.complemento,
                                    })}
                                    noIcon
                                    onClick={() => {
                                        setBillingAddress(address);
                                        setAddressError(false);
                                    }}
                                    radio={{
                                        id: '2',
                                        value: '2',
                                        name: 'address',
                                        checked: billingAddress?._id === address._id,
                                    }} />
                            </Box>
                        ))
                    }
                    <Box
                        display='flex'
                        justifyContent='center'
                        mt={4}>
                        <Box
                            maxWidth={320}
                            width='100%'
                            justifyContent='center'>
                            <Button
                                fullWidth
                                variant="contained"
                                size="medium"
                                type='submit'
                                className='MuiButton secondary'
                                onClick={() => {
                                    navigate({
                                        pathname: '/meus-enderecos/adicionar',
                                        search: `?action=payment-${defaultCard ?
                                            'edit' :
                                            'add'
                                        }`,
                                    });
                                }}
                            >
                                Adicionar novo endereço
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </MuiDialog>
            <MuiDialog
                maxWidth='xs'
                ref={dialogConfirm}
                contentPadding={false}
                title='Deletar Cartão?'
                showDialogTitle
                onConfirm={{
                    action: handleDeleteItemCart,
                    text: 'Confirmar',
                }}>
                {defaultCard &&
                    <Box>
                        <MenuItem
                            borderType={'none'}
                            title={{
                                text: defaultCard.bandeira,
                            }}
                            legend={{
                                text: defaultCard.numero_cartao,
                            }}
                            iconCustom={{
                                path:
                                    <CreditCardIMG
                                        src={flagsIcon[
                                            defaultCard.bandeira as
                                            CreditCardFlagType]}
                                        alt='cc' />,
                            }} />
                    </Box>
                }
            </MuiDialog>
        </React.Fragment >
    );
};

export { PaymentAddEditMethod };
