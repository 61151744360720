/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable max-len */
import React, { RefAttributes } from 'react';
import { FormStaticProps } from '@interfaces';
import {
    Box,
    Container,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { ForwardRefExoticComponent } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FilterContent } from '../../../../../../../components/filters';
import { useAds } from '@hooks';
import { MeasuresTableProps } from '@interfaces/Contexts';
// import { MeasuresProps } from '@interfaces/Contexts';

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

// interface FormAddSizeProps {
//     size: string;
//     price: string;
//     quantity: string;
//     product_sku: string;
// }

interface FormAddSizeProps {
    [key: string]: string;
}

interface ComponentProps {
    children: React.ReactNode;
    editID?: string;
}

interface MeasuresTableEditProps extends MeasuresTableProps {
    isLoaded: boolean;
}

export const FormAddMeasures: ForwardRefExoticComponent<ComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            editID,
            children,
        } = props;
        const { measures, createNewAd } = useAds();
        const { palette, typography } = useTheme();

        const [sizeToEdit, setSizeToEdit] = React.useState<MeasuresTableEditProps>({
            isLoaded: false,
        } as MeasuresTableEditProps);

        const {
            register,
            handleSubmit,
            setValue,
            control,
            formState: { errors },
        } =
            useForm<FormAddSizeProps>({
                mode: 'all',
            });

        const filterEditByID = (_id: string) => {
            const filter = measures.measuresAd.list.filter((item) => item.size === _id)[0];
            setSizeToEdit((prev) => prev = {
                isLoaded: true,
                ...filter,
            });

            for (let i = 0; i < filter.measures.length; i++) {
                setValue(filter.measures[i]._id, filter.measures[i].value as string);
            }
        };

        const onSubmit: SubmitHandler<FormAddSizeProps> = async (data) => {
            const test = [data].reduce((list: any, item) => {
                const key = Object.keys(data).map((_id) => {
                    if (_id !== 'size') {
                        const red = measures.measuresAd.listSelected.data.reduce(
                            (list: any, item) => {
                                if (item._id === _id) {
                                    return list = { ...item };
                                }
                                return list;
                            }, {});
                        return list = {
                            ...red,
                            value: item[_id],
                        };
                    }
                });
                return key.filter((el) => el !== undefined);
            }, []);

            const obj = [{
                size: data.size as string,
                measures: test,
            }];

            measures.addMeasuresAd({
                _id: data.size as string,
                oldID: editID as string,
                data: obj,
            });
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        React.useEffect(() => {
            if (!!(editID)) {
                filterEditByID(editID as string);
                setValue('size', editID as string);
            }
        }, []);

        return (
            <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)} pt={2}>
                <FilterContent minHeightContent={74}>
                    <Container maxWidth='sm'>

                        <Grid container spacing={2}>
                            <Grid item xs={12} mb={1}>
                                <FormControl
                                    fullWidth
                                    className="custom_select"
                                    focused
                                    size='medium'>
                                    <InputLabel id="SizeField-select-label" className="mb-2">
                                        Tamanho
                                    </InputLabel>
                                    <Controller
                                        name="size"
                                        control={control}
                                        defaultValue=""
                                        render={({ field: { onChange, value } }) => (
                                            <Select
                                                error={!!errors.size}
                                                defaultValue=""
                                                labelId="SizeField-select-label"
                                                className="input-container"
                                                id="SizeField-select-label"
                                                size="medium"
                                                value={value}
                                                {...register('size', {
                                                    required: true,
                                                })}
                                                onChange={onChange}
                                            >
                                                {createNewAd.data.size ? createNewAd.data.size.map(({ _id, nome }) => (
                                                    <MenuItem value={_id}>{nome}</MenuItem>
                                                )) : 'Nenhum tamanho disponível'}
                                                {/* {ad.sizes.data.map(({ tamanhos }) => (
                                                    tamanhos.map(({ _id, nome }) => (
                                                        <MenuItem value={_id}>{nome}</MenuItem>
                                                    ))
                                                ))} */}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>

                            {editID && sizeToEdit.isLoaded && sizeToEdit.measures.map(({ _id, nome, unidade_medida }) => (
                                <Grid item xs={12} mb={1}>
                                    <TextField
                                        focused
                                        fullWidth
                                        label={nome}
                                        variant="outlined"
                                        helperText={errors._id ?
                                            errors._id.message :
                                            ''}
                                        error={!!errors._id}
                                        {...register(_id,
                                            { required: true },
                                        )}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography
                                                        variant="h1"
                                                        fontSize={14}
                                                        fontWeight={typography.fontWeightBold}
                                                        color={palette.dark}
                                                    >
                                                        {unidade_medida}
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                            ))}

                            {!editID && measures.measuresAd.listSelected.data.map(({ _id, nome, unidade_medida }) => (
                                <Grid item xs={12} mb={1}>
                                    <TextField
                                        focused
                                        fullWidth
                                        label={nome}
                                        type='number'
                                        variant="outlined"
                                        helperText={errors._id ?
                                            errors._id.message :
                                            ''}
                                        error={!!errors._id}
                                        {...register(_id,
                                            { required: true },
                                        )}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography
                                                        variant="h1"
                                                        fontSize={14}
                                                        fontWeight={typography.fontWeightBold}
                                                        color={palette.dark}
                                                    >
                                                        {unidade_medida}
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </FilterContent>
                {children}
            </Box>
        );
    });
