import React from 'react';
import {
    HeaderPage,
    Page,
    FormControlSelect,
    Snackbar,
} from '@components';
import {
    AlertColor,
    Autocomplete,
    Box,
    Button,
    Container,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    Radio,
    RadioGroup,
    TextField,
} from '@mui/material';

import { BankProps, ToggleStaticProps } from '@interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from './yup';
import { bankAccounts } from '@static/bank-accounts';
import { sx } from './sx';
import { useWallet } from '../../../../../../hooks/wallet';

type BankAccountsType = 'cc' | 'cp';

interface BankAccountListType {
    value: string;
    label: string;
    dig_ag: boolean;
    dig_cc: boolean;
}

interface FormAccountBank {
    bank: string;
    accountType: BankAccountsType;
    agency: string;
    agencyDig: string;
    accountNumber: string;
    accountDig: string;
};

const BankAccountsAddEdit: React.FC = () => {
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const { wallet } = useWallet();
    const navigate = useNavigate();
    const location = useLocation();

    const { css } = sx();

    const [bankAccountsList] = React.useState<BankAccountListType[]>(bankAccounts);
    const [defaultBank] = React.useState<BankProps | null>(
        location.state && location.state.bank ? location.state.bank : null);
    const [selectedBank, setSelectedBank] = React.useState<BankAccountListType>({
        label: '',
        value: '',
        dig_ag: false,
        dig_cc: false,
    });
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
        isLoading: false,
    });

    const { register, handleSubmit, formState: { errors }, setValue } =
        useForm<FormAccountBank>({
            resolver: yupResolver(formSchema),
            mode: 'all',
        });

    const findSelectedBank = (bankName: string) => {
        const findResult = bankAccountsList.find((bank) => bank.label === bankName);
        if (findResult) {
            setValue('bank', findResult.label);
            setSelectedBank(findResult);
            if (!findResult.dig_ag) {
                setValue('agencyDig', 'x');
            } else {
                if (defaultBank) {
                    setValue('agencyDig', defaultBank.bank_ag.split('-')[1]);
                } else {
                    setValue('agencyDig', '');
                }
            }
            if (!findResult.dig_cc) {
                setValue('accountDig', 'x');
            } else {
                if (defaultBank) {
                    setValue('accountDig', defaultBank.bank_cc.split('-')[1]);
                } else {
                    setValue('accountDig', '');
                }
            }
        } else {
            setValue('bank', '');
        }
    };

    const onSubmit: SubmitHandler<FormAccountBank> = async (data) => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });

        const digAgFormated = data.agencyDig === '0' ? 'x' : data.agencyDig;

        const dataFormated = {
            bank: data.bank,
            account_type: data.accountType,
            // eslint-disable-next-line max-len
            bank_ag: `${data.agency}${selectedBank.dig_ag ? '-' : ''}${selectedBank.value === '001' ? digAgFormated : selectedBank.dig_ag ? data.agencyDig : ''}`,
            // eslint-disable-next-line max-len
            bank_cc: `${data.accountNumber}${selectedBank.dig_cc ? '-' + data.accountDig : ''}`,
        };

        const response = defaultBank ?
            await wallet.bankAccount.update(dataFormated) :
            await wallet.bankAccount.add(dataFormated);

        if (!response?.error) {
            setResponseData((prev) => prev = {
                ...prev,
                type: response?.response.type,
                message: response?.response.message,
                isLoading: false,
            });
            snackRef.current?.show();
            setTimeout(() => {
                navigate(-1);
            }, 2000);
        } else {
            setResponseData((prev) => prev = {
                ...prev,
                type: response?.response.type,
                message: response?.response.message,
                isLoading: false,
            });
            snackRef.current?.show();
        }
    };

    React.useEffect(() => {
        if (defaultBank) {
            findSelectedBank(defaultBank.bank);
        }
    }, []);

    return (
        <React.Fragment>
            <Page.Wrapper border position='fixed'>
                <HeaderPage
                    titleAlign={'default'}
                    icon={['far', 'chevron-left']}
                    title={'Contas Bancárias'}
                    onClick={() => navigate('/minha-carteira/contas-bancarias')}
                    // action={
                    //     !(window.location.pathname ===
                    //         '/minha-carteira/contas-bancarias/adicionar') ?
                    //         <ActionSmallButton
                    //             backgroundColor={palette.pomegarnate}
                    //             text={'Excluir'}
                    //             icon={['far', 'trash-can']}
                    //             onClick={() => dialogConfirm.
                    //                 current?.
                    //                 show()} /> :
                    //         null
                    // }
                />
                <Page.Content paddingBottom>
                    <Container maxWidth='lg'>
                        <Box sx={{ mt: 4, mb: 4 }}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} mb={1}>
                                        <FormControlSelect
                                            label='Banco'
                                            id='bank-list'>
                                            <FormControl
                                                fullWidth
                                                error={!!errors.bank}>
                                                <Autocomplete
                                                    disablePortal
                                                    fullWidth
                                                    defaultValue={
                                                        defaultBank &&
                                                        defaultBank.bank as any}
                                                    id="bank-list"
                                                    options={bankAccountsList}
                                                    onInputChange={(_, newBankValue) =>
                                                        findSelectedBank(newBankValue)}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        helperText={errors.bank ?
                                                            errors.bank.message :
                                                            ''}
                                                        error={!!errors.bank}
                                                    />}
                                                />
                                            </FormControl>
                                        </FormControlSelect>
                                    </Grid>
                                    <Grid item xs={12} mb={1}>
                                        <FormControl>
                                            <FormLabel
                                                id="account-type"
                                                sx={css.radioGroupLabel}>
                                                Tipo de conta
                                            </FormLabel>
                                            <RadioGroup
                                                aria-labelledby="account-type"
                                                // eslint-disable-next-line max-len
                                                defaultValue={defaultBank && defaultBank.account_type}
                                                name="radio-buttons-group"
                                                row
                                            >
                                                <FormControlLabel
                                                    value="Corrente"
                                                    control={<Radio
                                                        size='small'
                                                        {...register('accountType',
                                                            {
                                                                required: true,
                                                            },
                                                        )}
                                                    />}
                                                    label="Corrente" />
                                                <FormControlLabel
                                                    value="Poupança"
                                                    control={<Radio
                                                        size='small' />}
                                                    label="Poupança"
                                                    {...register('accountType',
                                                        {
                                                            required: true,
                                                        },
                                                    )}
                                                />
                                            </RadioGroup>
                                            {errors.accountType && (
                                                <FormHelperText error>
                                                    {errors.accountType?.message}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={selectedBank.dig_ag ? 8 : 12} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            // eslint-disable-next-line max-len
                                            defaultValue={defaultBank && defaultBank.bank_ag.split('-')[0]}
                                            label={'Agência *'}
                                            variant="outlined"
                                            helperText={errors.agency ?
                                                errors.agency.message :
                                                ''}
                                            error={!!errors.agency}
                                            {...register('agency',
                                                { required: true },
                                            )} />
                                    </Grid>
                                    {selectedBank.dig_ag &&
                                        <Grid item xs={4} mb={5}>
                                            <TextField
                                                focused
                                                fullWidth
                                                // eslint-disable-next-line max-len
                                                defaultValue={defaultBank && defaultBank.bank_ag.split('-')[1]}
                                                label={'Dígito verificador *'}

                                                variant="outlined"
                                                helperText={errors.agencyDig ?
                                                    errors.agencyDig.message :
                                                    ''}
                                                error={!!errors.agencyDig}
                                                {...register('agencyDig',
                                                    { required: selectedBank.dig_ag },
                                                )} />
                                        </Grid>
                                    }
                                    <Grid item xs={selectedBank.dig_cc ? 8 : 12} mb={1}>
                                        <TextField
                                            focused
                                            fullWidth
                                            // eslint-disable-next-line max-len
                                            defaultValue={defaultBank && defaultBank.bank_cc.split('-')[0]}
                                            label={'Número da conta *'}
                                            variant="outlined"
                                            helperText={errors.accountNumber ?
                                                errors.accountNumber.message :
                                                ''}
                                            error={!!errors.accountNumber}
                                            {...register('accountNumber',
                                                { required: true },
                                            )} />
                                    </Grid>
                                    {selectedBank.dig_cc &&
                                        <Grid item xs={4} mb={5}>
                                            <TextField
                                                focused
                                                fullWidth
                                                // eslint-disable-next-line max-len
                                                defaultValue={defaultBank && defaultBank.bank_cc.split('-')[1]}
                                                label={'Digito Verificador *'}

                                                variant="outlined"
                                                helperText={errors.accountDig ?
                                                    errors.accountDig.message :
                                                    ''}
                                                error={!!errors.accountDig}
                                                {...register('accountDig',
                                                    { required: selectedBank.dig_cc },
                                                )} />
                                        </Grid>
                                    }
                                </Grid>
                                <Box
                                    display='flex'
                                    justifyContent='center'
                                    mt={4}>
                                    <Box
                                        maxWidth={320}
                                        width='100%'
                                        justifyContent='center'>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            size="large"
                                            type='submit'
                                            className='MuiButton primary'
                                        >
                                            {!(window.location.pathname ===
                                                // eslint-disable-next-line max-len
                                                '/minha-carteira/contas-bancarias/adicionar') ?
                                                'Alterar dados' :
                                                'Salvar'}

                                        </Button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Container>
                </Page.Content>

            </Page.Wrapper>
            {/* <MuiDialog
                maxWidth='xs'
                ref={dialogConfirm}
                contentPadding={false}
                title='Deletar Conta Bancária?'
                showDialogTitle
                onConfirm={{
                    action: handleDeleteAddress,
                    text: 'Confirmar',
                }}>
                <Box
                    mt={pxToRem(-16)}
                    mb={pxToRem(-16)}
                    ml={pxToRem(-24)}
                    mr={pxToRem(-24)}>
                    <MenuItem
                        borderType={'none'}
                        title={{
                            text: <strong>Banco do Brasil S.A.</strong>,
                            size: 'medium',
                        }}
                        icon={{
                            path: ['far', 'building-columns'],
                        }} />
                </Box>
            </MuiDialog> */}
            <Snackbar
                ref={snackRef}
                message={responseData.message}
                severity={responseData.type}
            />
        </React.Fragment >
    );
};

export { BankAccountsAddEdit };
