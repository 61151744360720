import { Hero } from './styles';

const ProfileCover = ({ src }: {src: string}) => {
    return (
        <Hero>
            <div className="WrapAnchorPicture">
                <a className="AnchorPicture" href="#!">
                    <div className="WrapPicture">
                        <picture>
                            <img
                                src={src}
                                alt="Banner de perfil"
                            />
                        </picture>
                    </div>
                </a>
            </div>
        </Hero>
    );
};

export { ProfileCover };
