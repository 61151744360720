import styled from 'styled-components';

export const Image = styled.img`
    width: 100%;
    height: 100%;
    min-height: 13.375rem;
    object-fit: cover;
`;

export const Anchor = styled.a`
    display: block;
`;
