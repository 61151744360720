/* eslint-disable max-len */
import { Box, Button, ButtonProps, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type PositionContent = 'center' | 'left' | 'right';

interface ButtonFilterMobileProps extends ButtonProps {
    text: string;
    icon: IconProp,
    maxWidth?: number;
    minWidth?: number;
    position?: PositionContent;
    width?: 'auto' | 'fluid';
}

const ButtonFilterMobile = (props: ButtonFilterMobileProps) => {
    const {
        text,
        icon,
        maxWidth,
        minWidth,
        position,
        width,
    } = props;
    const { typography, palette } = useTheme();

    const contentPosition = {
        center: 'center',
        left: 'flex-start',
        right: 'flex-end',
    };

    const buttonWidth = {
        auto: 'auto',
        fluid: '100%',
    };

    return (
        <Button
            variant="outlined"
            fullWidth
            size='large'
            sx={{
                '&:hover': {
                    border: 0,
                },
                ...{
                    fontSize: 12,
                    fontWeight: typography.fontWeightBold,
                    color: palette.dark,
                    border: 0,
                    borderRadius: 0,
                    paddingTop: 2,
                    paddingBottom: 2,
                    maxWidth: maxWidth,
                    minWidth: minWidth,
                    display: 'flex',
                    width: buttonWidth[width ?? 'fluid'],
                    justifyContent: contentPosition[position ?? 'center'],
                },
            }}
            {...props}>
            {text}
            <Box mt={-0.2} ml={0.5}>
                <I
                    icon={icon}
                    fontSize={12}
                    color={palette.dark}
                />
            </Box>
        </Button>
    );
};

export { ButtonFilterMobile };
