/* eslint-disable no-unused-vars */
/* eslint-disable space-before-function-paren */
/* eslint-disable indent */
/* eslint-disable max-len */
import React, { RefAttributes } from 'react';
import { FilterComponentObjProps, FormStaticProps, ToggleStaticProps } from '@interfaces';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    Box,
    Container,
    debounce,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
    alpha,
    Radio,
} from '@mui/material';
import { ForwardRefExoticComponent } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FilterContent, FilterFooter } from '../../../../../../../components/filters';
import { ButtonIcon, MuiDialog, Price, Snackbar } from '@components';
import { useAds } from '@hooks';
import { adPriceWithCommission, formatNumberToPriceBRL } from '@utils';
import { NewAdDataProps, VariationsItemProps } from '@interfaces/Contexts';
import { useParams } from 'react-router-dom';

import $ from 'jquery';
import 'jquery-mask-plugin';
import { Item } from './styles';
import IconButton from '@mui/material/IconButton';

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

interface FormAddSizeProps {
    size: string;
    price: string;
    quantity: string;
    product_sku: string;
}

interface ComponentProps {
    children: React.ReactNode;
    editID?: string;
    itemListID?: string;
    action: 'add' | 'edit';
}

export const FormAddSizes: ForwardRefExoticComponent<ComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            children,
            editID,
            itemListID,
            action,
        } = props;
        const dialogsizes = React.useRef<ToggleStaticProps>(null);
        const snackRef = React.useRef<ToggleStaticProps>(null);
        const params = useParams();

        const ads = useAds();
        const { palette, typography } = useTheme();
        const matches = useMediaQuery('(min-width:600px)');

        const [priceWithCommission, setPriceWithCommission] = React.useState('0.00');
        const [sizesList, setSizesList] = React.useState<any[]>([]);
        const [lockSizes, setLockSizes] = React.useState(false);
        const [itemName, setItemName] = React.useState('');

        const [tempSizes, setTempSizes] = React.useState<NewAdDataProps[]>([]);

        const debounceSave = React.useRef(debounce((value: number) => commission(value), 1000)).current;
        let sellerCommission = 0;
        const {
            register,
            handleSubmit,
            setValue,
            control,
            reset,
            clearErrors,
            setError,
            formState: { errors },
        } =
            useForm<FormAddSizeProps>({
                mode: 'all',
            });

        // se existir tamanho no anuncio ele vai chamar essa função
        const filterEditByID = (_id: string) => {
            const filter = ads.variations.items.list.find((item) => item._id === _id);
            if (!!(filter)) {
                if (filter.sizeName) {
                    setValue('size', filter.sizeName);
                }
                if (filter.nome) {
                    setValue('size', filter.nome);
                    setItemName((prev) => prev = filter.nome as string);
                }
                setValue('quantity', String(filter.stockQuantity));
                setValue('product_sku', filter.sku);
                debounceSave(Number(filter.price));
                setValue('price', formatNumberToPriceBRL(Number(filter.price)));
            }
        };


        const commission = (price: number) => {
            setPriceWithCommission((prev) =>
                prev = adPriceWithCommission(Number(price), String(sellerCommission)));
        };

        const handlePrice = (priceProps: string) => {
            debounceSave(parseFloat(priceProps.replace(/\./g, '').replace(',', '.')));
        };

        const handleSizes = ({ _id, nome, type }: FilterComponentObjProps) => {
            setTempSizes((prev) => prev = [{
                _id: _id,
                nome: nome,
            }]);
        };

        const onSubmit: SubmitHandler<FormAddSizeProps> = async (data) => {
            const [size, name] = data.size.split('-');

            // if (+data.quantity === 0) {
            //     setError('quantity', {
            //         message: 'Quantidade obrigatória.',
            //     });
            //     return;
            // }

            if (editID) {
                const itemsData = [{
                    nome: tempSizes.length > 0 ? '' : itemName,
                    itemListID: itemListID,
                    size: tempSizes.length > 0 ? tempSizes[0]._id : '', // size,
                    sizeName: tempSizes.length > 0 ? tempSizes[0].nome : '', // size => nome(Padrão),
                    sku: data.product_sku,
                    price: parseFloat(data.price.replace(/\./g, '').replace(',', '.')),
                    stockQuantity: Number(data.quantity),
                    _id: editID ? editID : '',
                }] as VariationsItemProps[];

                ads.variations.items.add({
                    type: 'edit', section: 'size', variants: {
                        items: {
                            currentSizeID: editID,
                            list: itemsData,
                        },
                    },
                });

                ads.externalActions.action({ action: 'sizeDialog', state: true });
            } else {
                // neste caso ele vai estar criando um novo item
                const countListItems = ads.variations.items.list.length + 1;
                const itemsData = [{
                    nome: tempSizes.length > 0 ? '' : itemName,
                    itemListID: `item-${countListItems}`,
                    size: tempSizes.length > 0 ? tempSizes[0]._id : '', // size,
                    sizeName: tempSizes.length > 0 ? tempSizes[0].nome : '', // size => nome(Padrão),
                    sku: data.product_sku,
                    price: parseFloat(data.price.replace(/\./g, '').replace(',', '.')),
                    stockQuantity: Number(data.quantity),
                }] as VariationsItemProps[];

                ads.variations.items.add({
                    type: 'add', section: 'size', variants: {
                        items: {
                            currentSizeID: editID ? editID : data.size,
                            list: itemsData,
                        },
                    },
                });
                ads.externalActions.action({ action: 'sizeDialog', state: true });
            }
        };

        const resetSize = () => {
            setItemName((prev) => prev = '');
            setTempSizes((prev) => prev = []);
            reset({ size: '' });
        };

        const resetFormData = () => {
            resetSize();
            reset({
                price: '',
                product_sku: '',
                quantity: '',
                size: '',
            });
            setPriceWithCommission((prev) => prev = '0.00');
        };

        const findSizesAD = async () => {
            const response = await ads.find({ id: params.id as string });
            const data = await response?.response.payload;
            const sizes = ads.variations.items.list.map((item) => {
                return item.size;
            });
            // const look = data.tamanho.filter((size: any) => !sizes.includes(size._id));
            if (data.tamanho) {
                if (action === 'edit') {
                    const filterSizeList = data.tamanho.filter((size: any) => sizes.includes(size._id));
                    const findCurrent = ads.variations.items.list.find((item) => item._id === editID);

                    const existsCurrent = data.tamanho.filter((size: any) => size._id === findCurrent?.size);

                    if (data.tamanho) {
                        setLockSizes((prev) => prev = !(filterSizeList.length > 0));
                        setSizesList(data.tamanho);
                        setTempSizes((prev) => prev = existsCurrent);
                    }
                }

                if (action === 'add') {
                    const filterSizeList = data.tamanho.filter((size: any) => !sizes.includes(size._id));
                    setSizesList(filterSizeList);
                }
                if (!!(editID)) {
                    filterEditByID(editID as string);
                    // setValue('size', editID as string);
                }
            } else {
                const variation = ads.variations.items.list.find((item) => item._id === editID) as VariationsItemProps;
                setValue('size', variation.nome ?
                    String(variation.nome) :
                    variation?.sizeName ?
                        variation?.sizeName : '');
                setValue('quantity', String(variation.stockQuantity));
                setValue('product_sku', variation.sku);
                debounceSave(Number(variation.price));
                setValue('price', formatNumberToPriceBRL(Number(variation.price)));
                setItemName((prev) => prev = variation.nome ? variation.nome : '');
            }
        };

        const loadUserCommission = async () => {
            const response = await ads.loadUserCommission();
            if (!response?.error) {
                sellerCommission = response?.response.payload;
            }
        };

        const handleXCloseSizesDialog = ({ resetSizes }: { resetSizes: boolean }) => {
            if (resetSizes) {
                if (!(tempSizes.length > 0)) {
                    setTempSizes((prev) => prev = []);
                }
            }
            dialogsizes.current?.hide();
        };

        const handleClearSizesDialog = () => {
            setTempSizes((prev) => prev = []);
            reset({ size: '' });
            dialogsizes.current?.hide();
        };

        const handleSubmitSizeDialog = () => {
            dialogsizes.current?.hide();
            setValue('size', tempSizes[0].nome);
            setItemName((prev) => prev = '');
            clearErrors('size');
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        React.useEffect(() => {
            // if (editID) {
            findSizesAD();
            loadUserCommission();
            // }
        }, []);

        React.useEffect(() => {
            $('.money').mask('000.000.000.000.000,00', {
                reverse: true, onChange: (e) => handlePrice(e),
            });
        }, []);

        return (
            <>
                <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)} pt={2}>
                    <FilterContent minHeightContent={61}>
                        <Container maxWidth='sm'>

                            <Grid container spacing={2}>
                                {/* <Grid item xs={8} mb={1}>
                                    <FormControl
                                        fullWidth
                                        className="custom_select"
                                        focused
                                        size='medium'>
                                        <InputLabel id="Company-select-label" className="mb-2">
                                            Tamanhos
                                        </InputLabel>
                                        <Controller
                                            name="size"
                                            control={control}
                                            defaultValue=""
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    disabled={lockSizes}
                                                    error={!!errors.size}
                                                    defaultValue=""
                                                    labelId="Company"
                                                    className="input-container"
                                                    id="Company"
                                                    size="medium"
                                                    value={value}
                                                    {...register('size', {
                                                        required: true,
                                                    })}
                                                    onChange={onChange}
                                                >
                                                    {sizesList.length > 0 ? sizesList.map(({ _id, nome }) => (
                                                        <MenuItem key={_id} value={`${_id}-${nome}`}>{nome}</MenuItem>
                                                    )) : 'Aguarde...'}
                                                </Select>
                                            )}
                                        />
                                    </FormControl>
                                </Grid> */}
                                <Grid item xs={sizesList.length > 0 ? 8 : 12} mb={1}>
                                    <TextField
                                        focused
                                        fullWidth
                                        label='Tamanho'
                                        variant="outlined"
                                        helperText={errors.size ?
                                            errors.size.message :
                                            ''}
                                        error={!!errors.size}
                                        {...register('size',
                                            { required: true },
                                        )}
                                        onChange={(e) => setItemName(e.target.value)}
                                        InputProps={{
                                            readOnly: !!(tempSizes.length > 0),
                                            disabled: !!(tempSizes.length > 0),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    {!!(tempSizes.length > 0) && (
                                                        <IconButton onClick={resetSize}>
                                                            <I
                                                                icon={['far', 'xmark']}
                                                                color={palette.darkGrey}
                                                                fontSize={16} />
                                                        </IconButton>
                                                    )}
                                                    {!!(itemName) && (
                                                        <IconButton onClick={resetSize}>
                                                            <I
                                                                icon={['far', 'xmark']}
                                                                color={palette.darkGrey}
                                                                fontSize={16} />
                                                        </IconButton>
                                                    )}
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                {sizesList.length > 0 && (
                                    <Grid item xs={4} mb={1}>
                                        <Box sx={{
                                            height: 56,
                                            width: '100%',
                                            border: '1px dashed ' + alpha(palette.darkGrey, 0.5),
                                            borderRadius: typography.pxToRem(4),
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: typography.pxToRem(14),
                                            color: palette.dark,
                                            cursor: 'pointer',
                                            opacity: !itemName ? 1 : 0.5,
                                        }}
                                            onClick={!itemName ? () => dialogsizes.current?.show() : () => { }}>
                                            Selecionar tamanho +
                                        </Box>
                                    </Grid>
                                )}
                                <Grid item xs={12} mb={1}>
                                    <TextField
                                        focused
                                        fullWidth
                                        label={'Preço'}
                                        type='text'
                                        variant="outlined"
                                        // disabled={!(!!sizesList.length)}
                                        helperText={errors.price ?
                                            errors.price.message :
                                            ''}
                                        error={!!errors.price}
                                        {...register('price',
                                            { required: true },
                                        )}
                                        // onChange={(e) => handlePriceWithCommission(Number(e.target.value))}
                                        inputProps={{
                                            className: 'money',
                                            inputMode: 'decimal',
                                            placeholder: '0,00',
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Typography
                                                        variant="h1"
                                                        fontSize={13}
                                                        fontWeight={typography.fontWeightMedium}
                                                        color={palette.dark}
                                                        sx={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Box component='span' mr={0.6}>
                                                            Valor a receber
                                                        </Box>
                                                        <Price size='tiny' value={priceWithCommission} />
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography
                                                        variant="h1"
                                                        fontSize={14}
                                                        fontWeight={typography.fontWeightBold}
                                                        color={palette.dark}
                                                    >
                                                        R$
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }} />
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                    <TextField
                                        focused
                                        fullWidth
                                        label='Quantidade em estoque'
                                        variant="outlined"
                                        // disabled={!(!!sizesList.length)}
                                        helperText={errors.quantity ?
                                            errors.quantity.message :
                                            ''}
                                        error={!!errors.quantity}
                                        onFocus={(e) => e.target.addEventListener('wheel', function (e) {
                                            e.preventDefault();
                                        }, { passive: false })}
                                        {...register('quantity',
                                            { required: true },
                                        )} />
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                    <TextField
                                        focused
                                        fullWidth
                                        label='SKU da variação'
                                        variant="outlined"
                                        // disabled={!(!!sizesList.length)}
                                        helperText={errors.product_sku ?
                                            errors.product_sku.message :
                                            ''}
                                        error={!!errors.product_sku}
                                        {...register('product_sku',
                                            { required: true },
                                        )} />
                                </Grid>
                            </Grid>
                        </Container>
                    </FilterContent>
                    <FilterFooter
                        onClearAction={resetFormData}
                        onSubmitAction={() => { }} />
                    {ads.variations.items.addResponse?.error && (
                        <Snackbar
                            ref={snackRef}
                            message={ads.variations.items.addResponse?.message as string}
                            severity={'error'}
                        />
                    )}
                </Box>
                <MuiDialog
                    maxWidth='sm'
                    fullScreen={!matches}
                    ref={dialogsizes}
                    title='Tamanhos do item'
                    onCancel={{
                        action: () => handleXCloseSizesDialog({ resetSizes: true }),
                    }}
                    showDialogTitle>
                    <Box p={3}>
                        <Grid container spacing={2}>
                            {sizesList.length > 0 ? sizesList.map(({ _id, nome }) => (
                                <Grid key={_id} item xs={2}>
                                    <Item className={!!tempSizes
                                        .find((item) =>
                                            item._id === _id) ?
                                        'active' :
                                        ''}>
                                        <label htmlFor={_id}>
                                            {nome}
                                            <Radio
                                                hidden
                                                id={_id}
                                                size='small'
                                                value={_id}
                                                checked={!!tempSizes
                                                    .find((item) =>
                                                        item._id === _id)}
                                                onChange={() => handleSizes({
                                                    _id: _id,
                                                    nome: nome,
                                                    type: 'single',
                                                })}
                                            />
                                        </label>
                                    </Item>
                                </Grid>
                            )) : 'Aguarde...'}
                        </Grid>
                    </Box>
                    <FilterFooter
                        onClearAction={handleClearSizesDialog}
                        onSubmitAction={handleSubmitSizeDialog}
                        disabledOnSubmit={!(!!tempSizes.length)}
                    />
                </MuiDialog>
            </>
        );
    });
