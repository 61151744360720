/* eslint-disable max-len */
// import { RoutesListProps } from '@interfaces';
import * as Page from '@pages';
import { Navigate } from 'react-router-dom';

export const stackRoutes = [
    {
        path: '/login',
        headerLayout: 'default',
        element: <Page.Login />,
    },
    {
        path: '/',
        headerLayout: 'default',
        index: true,
        element: <Page.Home />,
    },
    {
        path: '/menu',
        headerLayout: 'default',
        element: <Page.Menu />,
    },
    {
        path: '/menu-usuario',
        headerLayout: 'default',
        element: <Page.UserMenu />,
    },
    {
        path: '/criar-conta',
        headerLayout: 'default',
        element: <Page.CreateAccount />,
    },
    {
        path: '/conteudos/post',
        headerLayout: 'simple',
        element: <Page.MembersArea.Post />,
    },
    {
        path: '/conteudos/criar-conta',
        headerLayout: 'simple',
        element: <Page.MembersArea.Auth.CreateAccount />,
    },
    {
        path: '/criar-conta/enviar-codigo/:to',
        headerLayout: 'default',
        element: <Page.ChooseShippingMethod />,
    },
    {
        path: '/produto/:id',
        headerLayout: 'default',
        element: <Page.ProductView />,
    },
    {
        path: '/produto/:revisar/:id',
        headerLayout: 'default',
        element: <Page.ProductView />,
    },
    {
        path: '/codigo-seguranca/:type',
        headerLayout: 'default',
        element: <Page.VerificationCode />,
    },
    {
        path: '/recuperar-senha',
        headerLayout: 'default',
        element: <Page.RecoveryPasswordForm />,
    },
    {
        path: '/recuperar-senha/email',
        headerLayout: 'default',
        element: <Page.RecoveryPasswordEmail />,
    },
    {
        path: '/buscar',
        headerLayout: 'default',
        element: <Page.SearchMobile />,
    },
    {
        path: '/resultados',
        headerLayout: 'default',
        element: <Page.Results />,
    },
    {
        path: '/resultados/:key',
        headerLayout: 'default',
        element: <Page.Results />,
    },
    {
        path: '/resultados-marcas',
        headerLayout: 'default',
        element: <Page.ResultsBrands />,
    },
    {
        path: '/lojas/:storeUrl',
        headerLayout: 'default',
        element: <Page.Store />,
    },
    {
        path: '/ajuda',
        headerLayout: 'simple',
        element: <Page.HelpCenter />,
    },
    {
        path: '/ajuda/:topic',
        headerLayout: 'simple',
        element: <Page.Questions />,
    },
    {
        path: '/ajuda/resultados/:key',
        headerLayout: 'simple',
        element: <Page.Questions />,
    },
    {
        path: '/ajuda/:topic/:questionId',
        headerLayout: 'simple',
        element: <Page.Answer />,
    },
    {
        path: '/promocoes',
        headerLayout: 'default',
        element: <Page.Promoctions />,
    },
    {
        path: '/membros/landing-page',
        headerLayout: 'default',
        element: <Page.MembersArea.LandingPage />,
    },
    {
        path: '/login-suggestion',
        headerLayout: 'default',
        element: <Page.CommonPages.LoginSuggestion />,
    },
    {
        path: '/not-permission',
        headerLayout: 'default',
        element: <Page.CommonPages.NotPermission />,
    },
    {
        path: '/codigo-de-etica',
        headerLayout: 'default',
        element: <Page.CodeOfEthics />,
    },
    {
        path: '/termos-de-uso',
        headerLayout: 'default',
        element: <Page.TermsOfUse />,
    },
    {
        path: '/politica-de-privacidade',
        headerLayout: 'default',
        element: <Page.PrivacyPolicy />,
    },
    {
        path: '/minha-sacola',
        headerLayout: 'default',
        element: <Page.Shopping.Cart />,
    },
    // {
    //     path: '/checkout/endereco',
    //     headerLayout: 'simple',
    //     element: <Page.Shopping.CheckoutAddress />,
    // },
    // {
    //     path: '/checkout/endereco/alterar',
    //     headerLayout: 'simple',
    //     element: <Page.Shopping.CheckoutAddressChange />,
    // },
    // {
    //     path: '/checkout/endereco/adicionar',
    //     headerLayout: 'simple',
    //     element: <Page.Shopping.CheckoutAddressAddEdit />,
    // },
    // {
    //     path: '/checkout/endereco/editar',
    //     headerLayout: 'simple',
    //     element: <Page.Shopping.CheckoutAddressAddEdit />,
    // },
    // {
    //     path: '/checkout',
    //     headerLayout: 'simple',
    //     element: <Page.Shopping.Checkout />,
    // },
    // {
    //     path: '/checkout/success',
    //     headerLayout: 'default',
    //     element: <Page.FeedbackPage.Success />,
    // },
    {
        path: '/fale-conosco',
        headerLayout: 'default',
        element: <Page.ContactUs />,
    },
    {
        path: '/quem-somos',
        headerLayout: 'default',
        element: <Page.WhoWeAre />,
    },
    {
        path: '*',
        headerLayout: 'default',
        element: <Page.CommonPages.NotFound />,
    },
];

export const authStackRoutes = [
    {
        path: '/produtos/adicionar',
        headerLayout: 'simple',
        element: <Page.Ads.newAd />,
    },
    {
        path: '/produtos/adicionar/variantes/:id',
        headerLayout: 'simple',
        element: <Page.Ads.Variants />,
    },
    {
        path: '/produtos/editar/variantes/:id',
        headerLayout: 'simple',
        element: <Page.Ads.Variants />,
    },
    {
        path: '/produtos/editar/:id',
        headerLayout: 'simple',
        element: <Page.Ads.newAd />,
    },
    {
        path: '/membros/conteudo/:idContent',
        headerLayout: 'simple',
        element: <Page.MembersArea.Post />,
    },
    {
        path: '/checkout/endereco',
        headerLayout: 'simple',
        element: <Page.Shopping.CheckoutAddress />,
    },
    {
        path: '/checkout/endereco/alterar',
        headerLayout: 'simple',
        element: <Page.Shopping.CheckoutAddressChange />,
    },
    {
        path: '/checkout/endereco/adicionar',
        headerLayout: 'simple',
        element: <Page.Shopping.CheckoutAddressAddEdit />,
    },
    {
        path: '/checkout/endereco/editar',
        headerLayout: 'simple',
        element: <Page.Shopping.CheckoutAddressAddEdit />,
    },
    {
        path: '/checkout',
        headerLayout: 'simple',
        element: <Page.Shopping.Checkout />,
    },
    {
        path: '/checkout/success/:orderID/:paymentMethodType',
        headerLayout: 'default',
        element: <Page.FeedbackPage.Success />,
    },
];

export const membersHomeLayout = [
    {
        headerLayout: 'default',
        path: '/membros/descobrir',
        element: <Page.MembersArea.HomeDiscover />,
    },
    {
        headerLayout: 'default',
        path: '/membros/meus-conteudos',
        element: <Page.MembersArea.HomeMyContent />,
    },
];

export const blockAccessRoute = [
    {
        headerLayout: 'default',
        path: window.location.pathname,
        element: <Navigate to={'/login'} />,
    },
];

export const authStackRoutesLayout = [
    {
        path: '/meu-perfil',
        headerLayout: 'default',
        element: <Page.Profile.Base />,
    },
    {
        path: '/meu-perfil/alterar/loja',
        headerLayout: 'default',
        element: <Page.Profile.Store />,
    },
    {
        path: '/meu-perfil/alterar/dados-pessoais',
        headerLayout: 'default',
        element: <Page.Profile.Personal />,
    },
    {
        path: '/meu-perfil/alterar/dados-pessoais/senha',
        headerLayout: 'default',
        element: <Page.Profile.Password />,
    },
    {
        path: '/meus-favoritos',
        headerLayout: 'default',
        element: <Page.Favorites />,
    },
    {
        path: '/meus-produtos',
        headerLayout: 'default',
        element: <Page.Ads.MyAds />,
    },
    {
        path: '/minha-carteira/dinheiro',
        headerLayout: 'default',
        element: <Page.Wallet.Money />,
    },
    {
        path: '/minha-carteira/pontos',
        headerLayout: 'default',
        element: <Page.Wallet.Points />,
    },
    {
        path: '/minha-carteira/contas-bancarias',
        headerLayout: 'default',
        element: <Page.Wallet.BankAccounts />,
    },
    {
        path: '/minha-carteira/contas-bancarias/adicionar',
        headerLayout: 'default',
        element: <Page.Wallet.BankAccountsAddEdit />,
    },
    {
        path: '/minha-carteira/contas-bancarias/editar',
        headerLayout: 'default',
        element: <Page.Wallet.BankAccountsAddEdit />,
    },
    {
        path: '/metodos-de-pagamento/meus-metodos',
        headerLayout: 'default',
        element: <Page.Payments.PaymentListMethods />,
    },
    {
        path: '/metodos-de-pagamento/adicionar',
        headerLayout: 'default',
        element: <Page.Payments.PaymentAddEditMethod />,
    },
    {
        path: '/metodos-de-pagamento/editar',
        headerLayout: 'default',
        element: <Page.Payments.PaymentAddEditMethod />,
    },
    {
        path: '/meus-enderecos/listar',
        headerLayout: 'default',
        element: <Page.Address.AddressList />,
    },
    {
        path: '/meus-enderecos/adicionar',
        headerLayout: 'default',
        element: <Page.Address.AddressAddEdit />,
    },
    {
        path: '/meus-enderecos/editar',
        headerLayout: 'default',
        element: <Page.Address.AddressAddEdit />,
    },
    {
        path: '/minhas-compras',
        headerLayout: 'default',
        element: <Page.Purchases.List />,
    },
    {
        path: '/minhas-compras/detalhes/:id',
        headerLayout: 'default',
        element: <Page.Purchases.Details />,
    },
    {
        path: '/minhas-vendas',
        headerLayout: 'default',
        element: <Page.Sales.List />,
    },
    {
        path: '/minhas-vendas/detalhes/:id',
        headerLayout: 'default',
        element: <Page.Sales.Details />,
    },
];
