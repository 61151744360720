import { useTheme } from '@mui/material';

const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        colorsBox: {
            ...{
                // height: typography.pxToRem(60),
                width: '100%',
                backgroundColor: 'white',
                display: 'block',
                border: '1px solid #f1f1f1',
                position: 'relative',
            },
            '& > input': {
                display: 'block',
            },
        },
        customColorBox: {
            border: '1px solid' + palette.clouds,
            maxWidth: typography.pxToRem(165),
        },
        custtomColorBoxImage: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
        },
        customColorBoxTextField: {
            ...{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderTop: '1px solid ' + palette.clouds,
                borderRadius: typography.pxToRem(4),
            },
            '& > input': {
                width: '100%',
                height: typography.pxToRem(40),
                textAlign: 'center',
                border: 0,
                outline: 0,
            },
        },
        customColorBoxUpload: {
            border: '1px dashed ' + palette.clouds,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: typography.pxToRem(15),
            mt: typography.pxToRem(20),
        },
    };
    return { css };
};

export { sx };
