import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';

interface SmallTableColumnProps {
    title: string;
    value: string | React.ReactNode;
}

const SmallTableColumn: React.FC<SmallTableColumnProps> = (
    { title, value }: SmallTableColumnProps) => {
    const { typography, palette } = useTheme();
    return (
        <Box mt={1}>
            <Typography
                variant="h2"
                component='h2'
                fontSize={11}
                fontWeight={typography.fontWeightBold}
                color={palette.dark}
                gutterBottom>
                {title}
            </Typography>
            <Typography
                fontSize={14}
                fontWeight={typography.fontWeightBold}
                color={palette.dark}
                component='div'>
                {value}
            </Typography>
        </Box>
    );
};

export { SmallTableColumn };
