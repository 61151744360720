import { CartListResumeProps } from '@interfaces/Cart';

export const responseList = {
    data: [],
    message: '',
    isLoaded: false,
};

export const responseListObject = {
    data: {} as CartListResumeProps,
    message: '',
    isLoaded: false,
};

export const responseCartSessionObject = {
    message: '',
    isLoaded: false,
};

export const DEFAULTS = {
    cartStores: responseList,
    tempCartStores: responseList,
    cartResume: responseListObject,
    cartSession: responseCartSessionObject,
};
