import { Box, useMediaQuery } from '@mui/material';

interface FilterContentProps {
    children: React.ReactNode;
    minHeightContent?: number;
    maxHeight?: number;
    paddingTop?: number;
}

const FilterContent: React.FC<FilterContentProps> = (
    { children, minHeightContent, maxHeight, paddingTop }: FilterContentProps) => {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <Box className='content'
            sx={{
                // eslint-disable-next-line quotes
                height: `calc(100% - ${minHeightContent ?? 129}px)`,
                pt: paddingTop ?? 1,
                pb: !matches ? 2 : 0,
                overflow: 'auto',
                maxHeight: maxHeight ? maxHeight + 'px' : 'max-content',
            }}>
            {children}
        </Box>
    );
};

export { FilterContent };
