import {
    // AdConditionsProps,
    AdStateProps,
    FilterAddProps,
    FilterDataProps,
    FilterStateProps,
    ParseURLParamsObjectProps,
    ParseURLParamsProps,
    ParseURLParamsResponseProps,
} from '@interfaces/Contexts';
import { FiltersType } from '@types';
import { createContext } from 'react';

interface FiltersContextProps {
    filters: FilterStateProps;
    tempFilters: FilterStateProps;
    filtersTypeList: FiltersType[];
    add: ({ type, data }: FilterAddProps) => void;
    handleSetURLParams: (key: string, value: string | undefined, type?: 'single')
        => string;
    handleURLParams: (data: Partial<FilterStateProps>, type: FiltersType) => Promise<{
        params: string[][];
        error: boolean;
        existData: boolean;
    }>;
    parseQueryStringURL: (query: string,
        type: FiltersType,
        ad: AdStateProps,
        filter?: FilterDataProps[]) => Promise<{
            data: any;
        }>;
    filterQuery: (data: ParseURLParamsObjectProps[]) => string;
    clearAllFiltersData: () => void;
    filtersDataLenght: number;
    // reset: () => void;
    url: {
        parseURLParams: ({
            searchParams,
        }: ParseURLParamsProps) => Promise<ParseURLParamsResponseProps>
    }
}

export const FiltersContext = createContext<FiltersContextProps>(
    {} as FiltersContextProps);
