import { client } from '../../client';

interface ListContentProps {
    type?: 'ARTIGO' | 'VIDEO' | '';
    idCategory?: string;
    limit?: number;
}

const discover = async ({ type, idCategory, limit }: ListContentProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(
            // eslint-disable-next-line max-len
            `/contents?limit=${limit?limit:10}${type&&'&type='+type}${idCategory&&'&idCategory='+idCategory}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const myContents = async ({ type, idCategory, limit }: ListContentProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(
            // eslint-disable-next-line max-len
            `/mycontents?limit=${limit?limit:10}${type&&'&type='+type}${idCategory&&'&idCategory='+idCategory}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const categories = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/categorization/contents');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const save = async ({ idContent }: {idContent: string}) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/mycontents', {
            idContent: idContent,
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const details = async ({ idContent }: {idContent: string}) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(
            `/contents/unique?idContent=${idContent}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export {
    discover,
    myContents,
    categories,
    save,
    details,
};
