import React from 'react';
import { Container, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ButtonInterest } from '@components';

import { sx } from './sx';

const NotFound: React.FC = () => {
    const { css } = sx();
    const navigate = useNavigate();
    return (
        <Container
            sx={{ ...css.sxContainer }}
            maxWidth='lg'>
            <Typography
                sx={{ ...css.sx404 }}
                variant='h1'>
                404
            </Typography>
            <Stack
                spacing={1}
                alignItems={'center'}>
                <Typography sx={{ ...css.sxTitle }} variant='h2'>
                    <span>
                        Oops..</span> Página não encontrada!
                </Typography>
                <Typography sx={{ ...css.sxSubtitle }}>
                    O endereço da página mudou ou a página<br/> não existe.
                </Typography>
                <ButtonInterest
                    text={'Voltar para a home'}
                    color={'#fff'}
                    margin={'1.875rem 0 0 0 !important'}
                    backgroundColor={'#010101'}
                    padding={'0.625rem 2.5rem'}
                    onClick={() => navigate('/')} />
            </Stack>
        </Container>
    );
};

export { NotFound };
