import { Box, Container } from '@mui/material';
import React from 'react';
import { sx } from './sx';

interface TextsLayoutProps {
    children: React.ReactNode;
}

const TextsLayout: React.FC<TextsLayoutProps> = ({
    children,
}: TextsLayoutProps) => {
    const { css } = sx();
    return (
        <Container>
            <Box sx={{ ...css.sxBox }}>
                {children}
            </Box>
        </Container>
    );
};

export { TextsLayout };
