import axios from 'axios';
import {
    ProfileForm,
    FormAddressAPI,
    UpdatePassForm,
    UploadImageProps,
    FavoriteCallProps,
} from '@interfaces';
import { client } from '../../client';

const loadUser = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/user');

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const passwordChange = async (email: string) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/recoveryPassword', {
            email,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};


const updateUser = async ({ ...profile }: ProfileForm | UpdatePassForm) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/user', {
            ...profile,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const addAddress = async ({ ...address }: FormAddressAPI) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/address', {
            ...address,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const updateAddress = async ({ ...address }: FormAddressAPI) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/address', {
            ...address,
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const deleteAddress = async ({ idEndereco }: {
    idEndereco: string;
}) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.delete('/address', {
            data: {
                idEndereco: idEndereco,
            },
        });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const addFavorite = async (data: FavoriteCallProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/favorite', data);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadFavorites = async (limit: number) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get(`/favorite?limit=${limit}`);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const deleteFavorite = async (favoriteID: string) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.delete(`/favorite?idFavorite=${favoriteID}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const searchZipCode = async ({ zipCode }: {
    zipCode: string;
}) => {
    try {
        const response = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`);

        return response.data;
    } catch (error) {
        return error;
    }
};

const loadMenu = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/menu');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const uploadImage = async (data: UploadImageProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put(
            `/user/change/${data.type}`, data.formDataImage,
            { headers: { 'Content-Type': 'multipart/form-data' } });

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export {
    loadUser,
    updateUser,
    passwordChange,
    addAddress,
    updateAddress,
    addFavorite,
    loadFavorites,
    deleteFavorite,
    deleteAddress,
    searchZipCode,
    loadMenu,
    uploadImage,
};
