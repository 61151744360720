import React from 'react';
import { WalletContext } from './context';
import { walletProvider } from '../../hooks/wallet';

interface WalletProviderProps {
    children: React.ReactNode;
};

const WalletProvider = ({ children }: WalletProviderProps) => {
    const wallet = walletProvider();

    return (
        <WalletContext.Provider value={wallet}>
            {children}
        </WalletContext.Provider>
    );
};

export {
    WalletProvider,
};
