import { CancelOrderProps } from '@interfaces/Purchases';
import { client } from '../../../client';

const cancelOrder = async ({ orderID }: CancelOrderProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/corder/cancel/order/' + orderID);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const load = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/listorder/shopping');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export { load, cancelOrder };
