import React from 'react';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { ButtonIconProps } from '@interfaces';

import { sx } from './sx';
import { LightTooltip } from '@components';
import { useTheme } from '@mui/material';

const ButtonIcon: React.FC<ButtonIconProps> = ({
    size, icon, onClick, active, tooltip, disabled, boxShadow,
}: ButtonIconProps) => {
    const { shadows } = useTheme();
    const { sxIcon, sxIconButton } = sx();
    const handleIcon = [active ? 'fas' : 'far', icon];

    return (
        <LightTooltip
            title={tooltip}>
            <IconButton
                onClick={disabled ? () => {} : onClick}
                sx={{
                    boxShadow: boxShadow ? shadows[2] : 'none',
                    ...sxIconButton.default,
                    ...sxIconButton[size],
                    opacity: disabled ? 0.5 : 1,
                    cursor: disabled ? 'default' : 'pointer',
                }}>
                <I
                    style={{
                        ...sxIcon.default,
                        ...sxIcon[size],
                    }}
                    icon={handleIcon as any} />
            </IconButton>
        </LightTooltip>
    );
};

export { ButtonIcon };
