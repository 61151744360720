import { MenuItem } from '@components';
import { Box, Radio, useMediaQuery } from '@mui/material';
import React from 'react';
import {
    FilterContent,
    FilterFooter,
    ButtonSelectedFilter,
    BoxActiveFiltersList,
} from '../../../components/filters';

const orderByList = [
    { id: '1d6342cfeade', name: 'Maior preço', value: 'maior' },
    { id: 'a1d48ef4903b', name: 'Menor preço', value: 'menor' },
];

const OrderByFilter = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const [selectedValue, setSelectedValue] = React.useState('maior');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    return (
        <Box height='100%' component='form'>

            <FilterContent minHeightContent={114}>
                <BoxActiveFiltersList>
                    <ButtonSelectedFilter value='Maior preço' />
                </BoxActiveFiltersList>

                {orderByList.map(({ id, name, value }) => (
                    <MenuItem
                        key={id}
                        onClick={() => { }}
                        borderType={'bottom'}
                        title={{
                            text: name,
                            size: !matches ? 'small' : 'tiny',
                        }}
                        htmlFor={id}>
                        <Radio
                            checked={selectedValue === value}
                            onChange={handleChange}
                            value={value}
                            name="radio-buttons"
                            id={id}
                            size='small'
                        />
                    </MenuItem>
                ))}
            </FilterContent>
            <FilterFooter
                onClearAction={() => { }}
                onSubmitAction={() => { }}
            />
        </Box>

    );
};

export { OrderByFilter };
