import React from 'react';
import { MainMenuContext } from './context';
import { mainMenuProvider } from '../../hooks/main-menu';

interface MainMenuProviderProps {
    children: React.ReactNode;
};

const MainMenuProvider = ({ children }: MainMenuProviderProps) => {
    const mainMenu = mainMenuProvider();

    return (
        <MainMenuContext.Provider value={mainMenu}>
            {children}
        </MainMenuContext.Provider>
    );
};

export {
    MainMenuProvider,
};
