import { Paper, styled } from '@mui/material';

export const Item = styled(Paper)(({ theme }) => ({
    ...{
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1),
        textAlign: 'center',
        fontSize: 12,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.dark,
        textTransform: 'uppercase',
        cursor: 'pointer',
    },
    '&.active': {
        backgroundColor: theme.palette.dark,
        color: theme.palette.common.white,
        fontWeight: theme.typography.fontWeightBold,
    },
    '& label': {
        position: 'relative',
        display: 'block',
    },
    '& label > .MuiRadio-root, & label > .MuiCheckbox-root': {
        position: 'absolute',
        left: 0,
        top: -10,
        opacity: 0,
    },
}));
