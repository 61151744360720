import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Typography, useTheme, Button } from '@mui/material';
import { sx } from './sx';
import { ActionSmallButtonProps } from '@interfaces';

const ActionSmallButton: React.FC<ActionSmallButtonProps> = ({
    text, color, backgroundColor, icon, onClick,
}: ActionSmallButtonProps) => {
    const { palette } = useTheme();
    const { sxButton, sxIcon } = sx();
    return (
        <Button size='small' onClick={onClick} sx={{
            ...sxButton,
            'backgroundColor': backgroundColor ?
                backgroundColor : palette.dark,
            '&:hover': {
                backgroundColor: backgroundColor ?
                    backgroundColor : palette.dark,
            },
        }}>
            <Typography
                fontSize={'12px'}
                variant='caption'
                color={color ? color : palette.common.white}
                sx={{
                    position: 'relative',
                    top: '.0625rem',
                }}
            >
                {text}
            </Typography>
            <I
                style={{
                    color: color ? color : palette.common.white,
                    ...sxIcon,
                }}
                icon={icon} />
        </Button>
    );
};

export { ActionSmallButton };
