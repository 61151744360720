import { Box } from '@mui/material';
import styled from 'styled-components';

export const Main = styled(Box)`
    text-decoration: none;
    display: flex;
    flex-direction: column;
`;

export const Image = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    min-height: 11.25rem;
`;
