import * as api from './calls';

const auth = {
    login: api.login,
    logout: api.logout,
    account: {
        create: api.createAccount,
        securityCode: {
            send: () => { },
            validate: () => { },
        },
        password: {
            recovery: api.recoveryPassword,
            change: api.changePassword,
        },
    },
    validationAccount: api.validationAccount,
    resendCode: api.resendCodeValidation,
};

export { auth };
