import { useMediaQuery } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:37.5rem)');

    const css = {
        sxBox: {
            maxWidth: '37.5rem',
            maxHeight: '25rem',
            width: '100%',
            height: '100%',
            paddingRight: matches ? '1.25rem' : 0,
        },
    };

    return { css };
};
