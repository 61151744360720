import * as api from './calls';

const others = {
    categorization: api.categorization,
    quicklinks: {
        load: api.loadQuickLinks,
    },
    showcases: {
        load: api.loadShowCases,
    },
    location: {
        states: api.listStates,
        cities: api.listCities,
    },
    newsletter: {
        postEmail: api.postEmailList,
    },
    shortLink: {
        generate: api.generateShortLink,
    },
    forms: {
        getInTouch: {
            send: api.getInTouch,
        },
    },
};

export { others };
