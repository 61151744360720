import React from 'react';
import { useTheme, TableRow, TableCell } from '@mui/material';
import { TableListProps } from '@interfaces';

import { handleFontSize } from './sx';

const TableList: React.FC<TableListProps> = ({
    align, keyTab, value, noPaddingSide,
}: TableListProps) => {
    const { palette, typography } = useTheme();

    const handleFontWeight = {
        left: {
            keyTab: typography.fontWeightBold,
            value: typography.fontWeightRegular,
        },
        right: {
            keyTab: typography.fontWeightSemiBold,
            value: typography.fontWeightRegular,
        },
        between: {
            keyTab: typography.fontWeightMedium,
            value: typography.fontWeightRegular,
        },
    };

    return (
        <React.Fragment>
            <TableRow
                sx={{ '&:last-child th': { border: 0 } }}
            >
                <TableCell
                    sx={{
                        fontSize: handleFontSize[align].keyTab,
                        fontWeight: handleFontWeight[align].keyTab,
                        borderColor: palette.clouds,
                        padding: '0.9375rems',
                        paddingLeft: noPaddingSide ? '0' : '0.9375rem',
                    }}
                >{keyTab}</TableCell>
                <TableCell
                    align={align !== 'between' ? align : 'right'}
                    sx={{
                        fontSize: handleFontSize[align].value,
                        fontWeight: handleFontWeight[align].value,
                        borderColor: palette.clouds,
                        padding: '0.9375rem',
                        paddingRight: noPaddingSide ? '0' : '0.9375rem',
                    }}>{value}</TableCell>
            </TableRow>
        </React.Fragment>
    );
};

export { TableList };
