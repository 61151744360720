import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const matches1 = useMediaQuery('(min-width:900px)');
    const matches2 = useMediaQuery('(max-width:600px)');
    const css = {
        sxLink: {
            default: {
                'width': '100%',
                'textDecoration': 'none',
                'cursor': 'pointer',
            },
            slider: {
                'cursor': 'pointer',
                'display': 'block',
                'width': '100%',
                'textDecoration': 'none',
                'paddingRight': '0.9375rem',
            },
        },
        sxBoxImage: {
            position: 'relative',
            height: '100vh',
            width: '100%',
            maxHeight: matches1 ? '278px' : matches2 ? '160px' : '228px',
            // maxWidth: matches1 ? '287px' : matches2 ? '165px' : '237px',
        },
        sxBoxText: {
            maxWidth: matches1 ? '287px' : matches2 ? '165px' : '237px',
            marginTop: '8px',
            display: 'flex',
            justifyContent: 'space-between',
        },
        sxTag: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            maxWidth: matches2 ? '9.375rem' : 'auto',
            backgroundColor: palette.dark,
            color: palette.common.white,
            padding: '0.1875rem 0.9375rem',
            fontSize: '0.75rem',
            borderRadius: '1.5625rem 1.5625rem 0 1.5625rem',
            fontWeight: typography.fontWeightBold || 700,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        sxIconButton: {
            width: '2.375rem',
            height: '2.375rem',
            fontSize: '1.25rem',
            color: palette.dark,
        },
    };
    return { css };
};
