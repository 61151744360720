import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;

    .MuiOutlinedInput-root {
        font-size: 28px;
        font-weight: 700;
        max-height: 57px;

        input {

            text-align: center;
        }

        & > fieldset {
            border-color: #ECF0F1;
        }
        

        &.Mui-focused {
            & > fieldset {
                border: 1px solid #010101 !important;
            }
        }
    }

`;

export const Label = styled.label`
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    font-family: 'Nunito';
    margin-left: 0.25rem;
`;

export const Input = styled.input`
`;
