/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react';
import {
    BoxContent,
    HeaderPage,
    MuiDialog,
    Page,
    Product,
    SmallTableColumn,
    MessageBox,
    TabPanel,
} from '@components';
import { Box, Button, CircularProgress, Container, Divider, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ToggleStaticProps } from '@interfaces';

import { Snackbar } from '../../../../components/feedback/snackbar';
import { useAds, useOthers } from '@hooks';
import { formatDate, images } from '@utils';
import { ListAdProps, AdListProps, AdActionProps, AdListType } from '@interfaces/Contexts';

import ProductPlaceholder from '@img/product-placeholder.png';
import { useNavigate } from 'react-router-dom';
import { PinkSwitch } from './styles';
import { useAdsActions } from '../../../../hooks/ads/utils/actions-ad';
import { Stack } from '@mui/system';
import { ProductVariations } from './variations';
import { useVariations } from '../../../../hooks/ads/vatiations';

function a11yProps(index: number, id: AdListType) {
    return {
        'id': id,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const dialog = {
    title: {
        active: 'Ativar produto?',
        inactive: 'Desativar produto?',
        edit: 'Editar produto?',
    },
    buttonConfirm: {
        active: 'Confirmar',
        inactive: 'Confirmar',
        edit: 'Editar',
    },
};

const MyAds = () => {
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const dialogDisableAd = React.useRef<ToggleStaticProps>(null);
    const productvariations = React.useRef<ToggleStaticProps>(null);

    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');

    const ads = useAds();
    const { variationsAD } = useVariations();
    const adActions = useAdsActions();
    const { categories } = useOthers();
    // const adAction = editAD();

    const [tab, setTab] = React.useState(0);

    const [ad, setAd] = React.useState<ListAdProps>({
        fotos: [''],
    } as ListAdProps);
    const [actionAdType, setActionAdType] = React.useState<AdListType>('active');
    const [snackMessage, setSnackMessage] = React.useState<string>('');
    // const [imagesToEdit, setImagesToEdit] = React.useState<any>({});
    const [loading, setLoading] = React.useState({
        editAD: false,
    });

    const navigate = useNavigate();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
        const id = event.currentTarget.id as AdListType;
        setActionAdType((prev) => prev = id);
    };

    const openDialogActionAd = ({ ad, action }: { ad: ListAdProps, action?: AdListType }) => {
        setAd((prev) => prev = ad);
        setActionAdType((prev) => prev = action as AdListType);
        if (action === 'edit') {
            ads.createNewAd.images.addToEdit('ad', []);
        } else {
            setActionAdType((prev) => {
                if (ad.ativo) {
                    return 'inactive';
                } else {
                    return 'active';
                }
            });
        }
        dialogDisableAd.current?.show();
    };

    const closeDialog = ({ action }: { action: AdListType }) => {
        if (action === 'edit') {
            ads.select({ _id: '' as any, action: 'add' });
            dialogDisableAd.current?.hide();
        }
    };

    const handleActionAd = async ({ type }: Omit<AdActionProps, 'id'>) => {
        setLoading((prev) => prev = { ...prev, editAD: true });
        if (actionAdType === 'edit') {
            ads.select({ _id: ad._id, action: 'add' });

            const response = await ads.find({ id: ad._id });
            const data = await response?.response.payload;

            const responseMeasureTable = await ads.findMeasureTableByAdID({ idAd: ad._id });
            const responseMeasureTableData = await responseMeasureTable?.response.payload;

            await categories.handleCategorization({});

            await adActions.edit(data, responseMeasureTableData);

            navigate('/produtos/editar/' + ad._id);
            setLoading((prev) => prev = { ...prev, editAD: false });
        } else {
            const response = await ads.action({
                type: type,
                id: ad._id,
            });

            if (!response?.error) {
                dialogDisableAd.current?.hide();
                setSnackMessage(response?.response.message);
                snackRef.current?.show();
            }

            await listAds({ type: ad.ativo ? 'active' : 'inactive' });
            setLoading((prev) => prev = { ...prev, editAD: false });
        }
    };

    const listAds = async ({ type }: AdListProps) => {
        await ads.list({ type: type });
    };

    const handleOnChangeAD = (event: React.ChangeEvent<HTMLInputElement>, ad: ListAdProps) => {
        const { checked } = event.target;
        if (checked) {
            openDialogActionAd({ ad, action: 'active' });
        } else {
            openDialogActionAd({ ad, action: 'inactive' });
        }
    };

    const handleShowProductVariations = (ad: ListAdProps) => {
        productvariations.current?.show();
        setAd((prev) => prev = ad);
    };

    React.useEffect(() => {
        listAds({ type: 'active' });
    }, []);

    return (
        <Page.Wrapper border position='relative'>
            <Box>
                <HeaderPage
                    titleAlign={'default'}
                    title={'Meus Produtos'}
                    icon={['far', 'megaphone']}
                />

                <Container
                    maxWidth='lg'
                    className={'no-padding'}>
                    <Tabs
                        value={tab}
                        onChange={handleChange}>
                        <Tab
                            label="Ativos"
                            {...a11yProps(0, 'active')}
                            onClick={() => listAds({ type: 'active' })} />
                        <Tab
                            label="Inativos"
                            {...a11yProps(1, 'inactive')}
                            onClick={() => listAds({ type: 'inactive' })} />
                    </Tabs>
                    <Divider light />
                    <TabPanel value={tab} index={tab}>
                        <Box>
                            {ads.ad.list.isLoaded && !(ads.ad.list.data.length) ? (
                                <MessageBox type='info' message={ads.ad.list.message} />
                            ) : !ads.ad.list.isLoaded &&
                            <Box sx={{
                                p: 3,
                                display: 'flex',
                                justifyContent: 'center',
                            }}>
                                <CircularProgress color='inherit' size={24} />
                            </Box>
                            }
                            {ads.ad.list.isLoaded ? (
                                ads.ad.list.data.length >= 1 &&
                                ads.ad.list.data.map((ad) => (
                                    <BoxContent
                                        key={ad._id}
                                        paddingLeft
                                        borderBottom
                                        actions={[
                                            {
                                                actionType: 'edit',
                                                actionFunction:
                                                    () => openDialogActionAd({ ad: ad, action: 'edit' }),
                                            },
                                        ]}
                                        customAction={
                                            <Box sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}>
                                                <PinkSwitch
                                                    size='small'
                                                    checked={ad.ativo}
                                                    onChange={(e) => handleOnChangeAD(e, ad)}
                                                />
                                                <Typography
                                                    variant="caption"
                                                    display="block"
                                                    textAlign="center"
                                                    fontWeight="700"
                                                    color={palette.darkGrey}
                                                    fontSize={typography.pxToRem(11)}
                                                    gutterBottom>
                                                    {tab === 1 ?
                                                        dialog.title['active'] :
                                                        dialog.title['inactive']}
                                                </Typography>
                                            </Box>}>
                                        <Product
                                            _id={ad._id}
                                            size={'big'}
                                            align={'right'}
                                            src={ad?.fotos ?
                                                images.load(ad?.variacoes[0].fotos[0], 'server') :
                                                images.load(ProductPlaceholder, 'local')}
                                            title={ad.titulo}
                                            price={String(ad.variacoes[0].itens[0].valor_por)}
                                            to={() => navigate('/produto/' + ad._id + '?variantion=' + ad.variacoes[0]._id)} />
                                        <SmallTableColumn
                                            title='Variação padrão'
                                            value={'Quantidade em estoque: ' + ad.variacoes[0].itens[0].quantidade_estoque} />
                                        <SmallTableColumn
                                            title='Variações'
                                            value={
                                                <Button
                                                    variant="contained"
                                                    type="button"
                                                    size="small"
                                                    className="MuiButton secondary"
                                                    onClick={() => handleShowProductVariations(ad)}>
                                                    Ver variações do produto
                                                </Button>
                                            } />
                                        <Stack direction='row' alignItems='baseline' spacing={4}>
                                            <SmallTableColumn
                                                title='Cadastrado em'
                                                value={formatDate(ad.data_publicacao)} />
                                        </Stack>
                                    </BoxContent>))
                            ) : ''}
                        </Box>
                    </TabPanel>
                </Container>

            </Box>
            <MuiDialog
                maxWidth='xs'
                ref={dialogDisableAd}
                contentPadding={false}
                title={dialog.title[actionAdType]}
                showDialogTitle
                onConfirm={{
                    action: () => handleActionAd({ type: actionAdType }),
                    text: [dialog.buttonConfirm[actionAdType],
                    loading.editAD ?
                        <CircularProgress
                            key='circular-progress-edit'
                            color="inherit"
                            size={14}
                            sx={{ ml: 1 }} /> :
                        ''] as any,
                }}
                onCancel={{
                    action: () => actionAdType === 'edit' ?
                        closeDialog({ action: 'edit' }) :
                        dialogDisableAd.current?.hide(),
                }}>
                <Box
                    sx={{
                        'pl': 2,
                        '&:last-child .box-content': {
                            borderBottom: 0,
                        },
                    }}>
                    <BoxContent>
                        {ad.variacoes &&
                            <Product
                                size={'big'}
                                align={'right'}
                                src={ad?.fotos ?
                                    images.load(ad?.variacoes[0].fotos[0], 'server') :
                                    images.load(ProductPlaceholder, 'local')}
                                title={ad.titulo}
                                price={String(ad.variacoes[0].itens[0].valor_por)} />
                        }
                    </BoxContent>
                </Box>
            </MuiDialog>
            <MuiDialog
                maxWidth='sm'
                fullScreen={!matches}
                ref={productvariations}
                title='Variações do produto'
                showDialogTitle>
                <ProductVariations productID={ad._id} />
            </MuiDialog>
            <Snackbar
                ref={snackRef}
                message={snackMessage}
                severity='success'
            />
        </Page.Wrapper>
    );
};

export { MyAds };
