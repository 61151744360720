import React from 'react';
import { Box, IconButton, Stack } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { sx } from './sx';
import { Input, Form } from './styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formSchema } from './yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FilterButton } from './components/filter-button';
import { useNavigate } from 'react-router-dom';
import { FormSearch } from '@interfaces';

export interface SearchFormProps {
    refForm?: React.MutableRefObject<any>;
    autofocus?: boolean;
    onBlurInput?: () => void;
    onClickButton?: () => void;
    placeholder?: string;
    noMargin?: boolean;
    noShadow?: boolean;
    noFlag?: boolean;
    fromFaq?: boolean;
}

const placeholderInput = {
    products: 'Busque por “Colares”',
    brands: 'Busque por uma marca',
};

const SearchForm: React.FC<SearchFormProps> = ({
    autofocus, onBlurInput, onClickButton, fromFaq,
    noMargin, noShadow, noFlag, placeholder, refForm,
}: SearchFormProps) => {
    const navigate = useNavigate();
    // const location = useLocation();
    const { sxBoxInput, sxIconSearch } = sx();
    const [searchInput, setSearchInput] = React.useState<string>('');
    const [flagValue, setFlagValue] = React.useState<'products' | 'brands'>('products');

    const { register, handleSubmit, setValue } = useForm<FormSearch>({
        mode: 'onBlur',
        resolver: yupResolver(formSchema),
    });

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        if (target) {
            setValue('key', target.value);
            setSearchInput((prev) => prev = target.value);
        }
    };


    const onSubmit: SubmitHandler<FormSearch> = async (data) => {
        if (data.key.length >= 1) {
            onBlurInput && onBlurInput();
            if (flagValue === 'brands') {
                navigate({
                    pathname: '/resultados-marcas',
                    search: `?key=${data.key}`,
                });
            } else {
                navigate({
                    pathname: (fromFaq ? `/ajuda/resultados/${data.key}` :
                        `/resultados/${data.key}`),
                });
            }
        }
    };

    return (
        <Form
            ref={refForm}
            fromFaq={fromFaq}
            onSubmit={handleSubmit(onSubmit)}
            noShadow={noShadow}
            noMargin={noMargin}>
            <IconButton
                type='submit'
                onClick={searchInput.length >= 1 ? handleSubmit(onSubmit) : onClickButton}
                sx={{
                    padding: '1.125rem',
                }}>
                <I
                    icon={['far', 'magnifying-glass']}
                    style={{ ...sxIconSearch }} />
            </IconButton>
            <Box sx={{ ...sxBoxInput }}>
                <Input
                    autoFocus={autofocus}
                    placeholder={placeholder ? placeholder : placeholderInput[flagValue]}
                    type="text"
                    {...register('key',
                        { required: true },
                    )}
                    onChange={handleSearch}
                    onBlur={searchInput.length >= 1 ? () => null : () => null}
                />
            </Box>
            {!noFlag &&
                <Stack
                    sx={{ paddingRight: '15px' }}
                    direction={'row'}>
                    <FilterButton
                        position='left'
                        id={'produto'}
                        // onClick={() => {
                        //     setFlagValue('products');
                        // }}
                        filter={'Produtos'}>
                        <input
                            checked={flagValue === 'products'}
                            onChange={() => setFlagValue('products')}
                            id={'produto'}
                            value={'produtos'}
                            name='filter'
                            type='radio' />
                    </FilterButton>
                    <FilterButton
                        // onClick={() => {
                        //     setFlagValue('brands');
                        // }}
                        position='right'
                        id={'brands'} filter={'Marcas'}>
                        <input
                            checked={flagValue === 'brands'}
                            onChange={() => setFlagValue('brands')}
                            id={'brands'}
                            value={'brands'}
                            name='filter'
                            type='radio' />
                    </FilterButton>
                </Stack>
            }
        </Form>
    );
};

export { SearchForm };
