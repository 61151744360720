import React from 'react';
import {
    Container,
    Box,
    TextField,
    Button,
    useMediaQuery,
    useTheme,
    Typography,
    FormHelperText,
    Alert,
    AlertColor,
} from '@mui/material';

import Snackbar from '@mui/material/Snackbar';

import { sx } from './sx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formSchema } from './yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormNewsletterProps } from '@interfaces';
import { useOthers } from '../../../../hooks/others';

interface NewsLetterProps {
    location: 'home' | 'landing-page';
}

const NewsLetter: React.FC<NewsLetterProps> = ({
    location,
}: NewsLetterProps) => {
    const matches = useMediaQuery('(min-width:600px)');
    const { typography, palette } = useTheme();
    const { sxForm, sxButton, sxInput, sxLegend } = sx();
    const { newsletter } = useOthers();
    const [newsletterResponse, setNewsletterResponse] = React.useState({
        type: 'success',
        message: '',
    });

    const [open, setOpen] = React.useState(false);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const { register,
        handleSubmit,
        reset,
        formState: { errors } } =
        useForm<FormNewsletterProps>({
            resolver: yupResolver(formSchema),
            mode: 'all',
        });

    const onSubmit: SubmitHandler<FormNewsletterProps> = async (data) => {
        const { email } = data;
        const response = await newsletter.postEmail({ email });


        if (!response?.error) {
            setOpen(true);
            setNewsletterResponse((prev) => prev = {
                ...prev,
                type: 'success',
                message: response?.response.message,
            });
            reset({ email: '' });
        } else {
            setOpen(true);
            setNewsletterResponse((prev) => prev = {
                ...prev,
                type: 'error',
                message: response?.response.message,
            });
        }
    };

    return (
        <>
            <Box sx={{
                backgroundColor: palette.clouds,
                mt: location === 'home' ? '1.875rem' : 0,
                pt: location === 'home' ? 6 : 0,
                pb: location === 'home' ? 6 : 0,
            }}>
                <Container
                    className={location === 'home' ? '' : 'no-padding'}
                    maxWidth={'lg'}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        maxWidth: '36.875rem',
                        width: '100%',
                        margin: '0 auto',
                    }}>
                        {location === 'home' &&
                            <Box>
                                <Typography
                                    variant='h2'
                                    fontSize={matches ? '1.875rem' : '1.25rem'}
                                    textTransform={'uppercase'}
                                    fontWeight={typography.fontWeightRegular}
                                    textAlign={'center'}>
                                    Inspire-se
                                </Typography>
                                <Typography
                                    variant='body2'
                                    textAlign={'center'}
                                    fontWeight={typography.fontWeightRegular}
                                    sx={{ ...sxLegend[matches ? 'desktop' : 'mobile'] }}>
                                    receba nosso conteúdo em primeira mão
                                    {!matches && <br />}
                                    &nbsp;e faça parte dessa revolução
                                </Typography>
                            </Box>
                        }
                        <form
                            style={{
                                flexDirection: matches ? 'row' : 'column',
                                ...sxForm[matches ? 'desktop' : 'mobile'],
                            }}
                            onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                focused={matches ? true : false}
                                placeholder='Digite seu email'
                                id="outlined-basic"
                                variant="outlined"
                                sx={{ ...sxInput }}
                                {...register('email',
                                    { required: true },
                                )}
                            />
                            <Button
                                variant="contained"
                                type="submit"
                                sx={{ ...sxButton[matches ? 'desktop' : 'mobile'] }}>
                                CADASTRAR EMAIL
                            </Button>
                        </form>
                        <FormHelperText error={!!errors.email}>
                            {errors.email?.message}
                        </FormHelperText>
                        <Snackbar
                            sx={{
                                position: 'relative',
                                bottom: '0 !important',
                                transform: 'none !important',
                                left: 'auto !important',
                            }}
                            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                            open={open}
                            autoHideDuration={5000}
                            onClose={handleClose}>
                            <Alert
                                onClose={handleClose}
                                severity={newsletterResponse.type as AlertColor}
                                sx={{ width: '100%' }}>
                                {newsletterResponse.message}
                            </Alert>
                        </Snackbar>
                    </Box>
                </Container>
            </Box>
        </>
    );
};

export { NewsLetter };
