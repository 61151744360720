import React from 'react';
import {
    CreditCardListProps,
    HandleCreditCardProps,
    PaymentMethodForm,
    UpdateCreditCardProps,
} from '@interfaces';
import { PaymentMethodContext } from '../../contexts/payment-method/context';
import { client } from '../../api/client';

const paymentMethodProvider = () => {
    const [currentCreditCard, setCurrentCreditCard] = React.useState<PaymentMethodForm>(
        {} as PaymentMethodForm,
    );

    const [listCreditCards, setListCreditCards] = React.useState<CreditCardListProps>(
        {} as CreditCardListProps);

    const handlePaymentMethod = async ({
        cardInfos, creditCardId }: UpdateCreditCardProps) => {
        const { services } = client();
        const response = await services.paymentMethod.creditCard.update({
            cardInfos: cardInfos,
            creditCardId: creditCardId,
        });
        return response;
    };

    const handleCreditCard = ({
        creditCard, action,
    }: HandleCreditCardProps) => {
        if (action === 'credit_card') {
            setCurrentCreditCard((prev) => prev = {
                ...prev,
                cardInfos: {
                    ...creditCard.cardInfos,
                },
            });
        }
        if (action === 'credit_card_address') {
            setCurrentCreditCard((prev) => prev = {
                ...prev,
                cardInfos: {
                    ...prev.cardInfos,
                    endereco: creditCard.cardInfos.endereco,
                },
            });
        }
    };

    const deleteCreditCard = async ({ cardId }: {cardId: string}) => {
        const { services } = client();
        const response = await services.paymentMethod.creditCard.delete({
            cardId: cardId,
        });
        await loadPaymentMethods();
        return response;
    };

    const loadPaymentMethods = async () => {
        const { services } = client();
        const response = await services.paymentMethod.creditCard.list();
        if (!response?.error && response?.response.payload) {
            return setListCreditCards(
                { data: response?.response.payload[0].cartoes, isLoaded: true });
        } else {
            return setListCreditCards(
                { data: undefined, isLoaded: false });
        }
    };

    const resetData = () => {
        setCurrentCreditCard({} as PaymentMethodForm);
    };

    const paymentMethod = {
        creditCard: {
            current: {
                data: currentCreditCard,
                addCCData: handleCreditCard,
                resetData: resetData,
            },
            loadList: loadPaymentMethods,
            list: listCreditCards,
            handlePaymentMethod: handlePaymentMethod,
            delete: deleteCreditCard,
        },
    };

    return {
        paymentMethod,
    };
};

const usePaymentMethod = () => {
    const context = React.useContext(PaymentMethodContext);
    return context;
};

export { usePaymentMethod, paymentMethodProvider };
