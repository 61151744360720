// import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const FooterData = [
    {
        section: 'Sobre',
        items: [
            {
                title: 'Código de Ética',
                link: '/codigo-de-etica',
            },
            {
                title: 'Termos de Uso',
                link: '/termos-de-uso',
            },
            {
                title: 'Política de privacidade',
                link: '/politica-de-privacidade',
            },
        ],
    },
    {
        section: '  Ajuda',
        items: [
            {
                title: 'Perguntas frequentes',
                link: '/ajuda',
            },
            {
                title: 'Fale conosco',
                link: '/fale-conosco',
            },
        ],
    },
    // {
    //     section: 'Instagram',
    //     items: [
    //         {
    //             title: 'Instagram',
    //             icon: ['fab', 'square-instagram'] as IconProp,
    //             link: '#!',
    //         },
    //     ],
    // },
];
