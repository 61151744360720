import React from 'react';
import {
    ActionSmallButton,
    HeaderPage,
    MenuItem,
    MessageBox,
    Page,
    Snackbar,
} from '@components';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { useLocation, useNavigate } from 'react-router-dom';
import { BankProps, ToggleStaticProps } from '@interfaces';
import { AlertColor, Box, CircularProgress } from '@mui/material';
import { useWallet } from '../../../../../../hooks/wallet';

interface ResponseDataProps {
    type: AlertColor;
    message: string;
    isLoading: boolean;
}

const BankAccounts: React.FC = () => {
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const { wallet } = useWallet();
    const location = useLocation();
    const locationSearch = !!location.search.includes('action=del');

    const navigate = useNavigate();
    const [bank, setBank] = React.useState<BankProps>(
        {} as BankProps,
    );

    const [responseData, setResponseData] = React.useState<ResponseDataProps>(
        {
            isLoading: true,
            message: '',
            type: 'success',
        },
    );


    const loadBankAccount = async () => {
        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });
        const response = await wallet.bankAccount.load();

        if (response) {
            if (!response?.response.message.bank) {
                setResponseData({
                    isLoading: false,
                    message: response.response.message,
                    type: response.response.type,
                });
            } else {
                setResponseData((prev) => prev = {
                    ...prev,
                    isLoading: false,
                });
                setBank(response.response.message);
            }
        }
    };

    React.useEffect(() => {
        if (locationSearch) {
            snackRef.current?.show();
        }
    }, [locationSearch]);

    React.useEffect(() => {
        loadBankAccount();
    }, []);

    return (
        <React.Fragment>
            <Page.Wrapper border position='fixed'>
                <HeaderPage
                    titleAlign={'default'}
                    icon={['far', 'chevron-left']}
                    title={'Contas Bancárias'}
                    onClick={() => navigate('/minha-carteira/dinheiro')}
                    action={
                        !responseData.isLoading && !bank.bank &&
                        <ActionSmallButton
                            text={'Adicionar'}
                            icon={['far', 'plus']}
                            onClick={() =>
                                navigate('/minha-carteira/contas-bancarias/adicionar')}
                        />
                    }
                />
                <Page.Content paddingBottom>
                    {!responseData.isLoading ?
                        (bank.bank ?
                            <MenuItem
                                htmlFor={bank.bank}
                                borderType={'bottom'}
                                title={{
                                    text: bank.bank,
                                    size: 'medium',
                                    fontWeight: 'bold',
                                }}
                                icon={{
                                    path: ['far', 'building-columns'],
                                    size: 'medium',
                                }}
                                onClick={() => navigate({
                                    pathname: '/minha-carteira/contas-bancarias/editar',
                                    search: '?action=edit',
                                }, {
                                    state: {
                                        bank: bank,
                                    },
                                })}
                                children={(
                                    <Box pr={2}>
                                        <I icon={['far', 'chevron-right']} />
                                    </Box>
                                )} /> :
                            <MessageBox
                                message={responseData.message}
                                type={responseData.type} />
                        ) :
                        <Box sx={{
                            p: 3,
                            display: 'flex',
                            justifyContent: 'center',
                        }}>
                            <CircularProgress color='inherit' size={24} />
                        </Box>
                    }
                    <Snackbar
                        ref={snackRef}
                        message={'Conta bancária excluída'}
                        severity='success'
                    />
                </Page.Content>
            </Page.Wrapper>
        </React.Fragment>
    );
};

export { BankAccounts };
