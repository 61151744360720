import { client } from '../../client';
import { HighlightedAdsProps, SearchAdsProps } from '@interfaces';
import {
    AdActionProps,
    AdFindProps,
    AdListProps,
    CreateAdVariation,
    CreateNewAdCreateProps,
    DeleteVariationProps,
    FindMeasureTableByAdIDProps,
    FindMeasureTableProps,
    LoadSizesByCategoryProps,
    UpdateAdVariation,
} from '@interfaces/Contexts';
import { AdStylesType } from '@types';

const search = async ({
    website, limit, query,
}: SearchAdsProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const domain = `/ad/find/products/?limit=${limit}${query}&website=${website}`;
        const response = await instance.get(domain);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const highlighted = async ({
    limit,
    aleatory,
}: HighlightedAdsProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const domain =
            `/ad/find/products/?limit=${limit}&tag=em_destaque&aleatory=${aleatory}`;
        const response = await instance.get(domain);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const list = async ({ type }: AdListProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.
            get('/ad/' + type ?? 'active' + '?website=shopping');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadMaterials = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.
            get('ad/materials');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadStyles = async (style: AdStylesType) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.
            get('/ad/styles?type=' + style);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadSustainabilityOptions = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.
            get('/ad/sustainabilities');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadMeasureTypes = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.
            get('/ad/measureTypes');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const find = async ({ id }: AdFindProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('ad/find/' + id);

        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};


const findVariationByID = async ({ id }: AdFindProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/ad/variations/' + id);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

// * Enable or disable an ad
const action = async ({ type, id }: AdActionProps) => {
    const { instance, handleErrors, handleResponse } = client();

    const ad = {
        state: (type === 'active') && true ||
            (type === 'inactive') && false,
        idAd: id,
    };

    try {
        const response = await instance.put('/ad/setState/', ad);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadConditions = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/domain/conditions');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadBrands = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/domain/brands');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadWeights = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/domain/weights');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadSizes = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/domain/list/sizeGroup');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadColors = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/ad/colors');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadSizesByCategory = async ({ categoriesList }: LoadSizesByCategoryProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/domain/sizeGroup?website=shopping',
            categoriesList);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadShippingOptions = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/shippingOptions');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const userAdCommission = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/commission/getUserCommission');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

// const loadVariations = async (adID: string) => {
//     const { instance, handleErrors, handleResponse } = client();

//     try {
//         const response = await instance.get('/ad/variations?ad=' + adID);
//         return handleResponse(response);
//     } catch (error: any) {
//         return handleErrors(error);
//     }
// };

const findVariationByAdID = async ({ id }: AdFindProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/ad/variations?ad=' + id);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};


const createVariation = async ({
    adID,
    ad,
}: CreateAdVariation) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/ad/variations?ad=' + adID,
            ad,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const updateVariation = async ({
    ad,
}: UpdateAdVariation) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/ad/variations',
            ad,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const create = async (ad: CreateNewAdCreateProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/ad?website=shopping',
            ad,
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const update = async (idAd: string, ad: CreateNewAdCreateProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/ad/update/' + idAd + '?website=shopping',
            ad,
            {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const findMeasureTable = async (data: FindMeasureTableProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance
            .get('/ad/' + data.idAd + '/measureItem/size/' + data.idSize);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const findMeasureTableByAdID = async ({ idAd }: FindMeasureTableByAdIDProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance
            .get('/ad/' + idAd + '/measureTable');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const deleteVariation = async ({ idAd, variationID }: DeleteVariationProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.delete('/ad/'+idAd+'/variation/'+variationID);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export {
    create,
    update,
    createVariation,
    updateVariation,
    deleteVariation,
    search,
    list,
    find,
    findVariationByAdID,
    findVariationByID,
    findMeasureTable,
    findMeasureTableByAdID,
    action,
    loadConditions,
    loadBrands,
    loadWeights,
    loadSizes,
    loadSizesByCategory,
    loadColors,
    // loadVariations,
    highlighted,
    userAdCommission,
    loadShippingOptions,
    loadMaterials,
    loadStyles,
    loadSustainabilityOptions,
    loadMeasureTypes,
};
