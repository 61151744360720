/* eslint-disable max-len */
/* eslint-disable camelcase */
import { MenuItem, MessageBox } from '@components';
import { useFilters, useOthers } from '@hooks';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    FilterComponentObjProps,
    FilterComponentProps,
    FormCategories,
    FormStaticProps,
} from '@interfaces';
import {
    // AdStateProps,
    FilterDataProps,
    FilterStateProps,
    ListCategorizationProps,
    NewAdDataProps,
} from '@interfaces/Contexts';
import {
    Box,
    Checkbox,
    useTheme,
} from '@mui/material';
import React, { RefAttributes } from 'react';
import { ForwardRefExoticComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    BoxActiveFiltersList,
    ButtonSelectedFilter,
    FilterContent,
    FilterFooter,
} from '../../../components/filters';
import { BackItem } from '../../../pages/menu/components/back-item';
import { useNavigate } from 'react-router-dom';

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

export const CategoriesFilter: ForwardRefExoticComponent<FilterComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            minHeightContent,
            onClearAction,
            onSubmitAction,
        } = props;

        // const { ad } = useAds();
        const { categories } = useOthers();
        const filter = useFilters();

        const navigate = useNavigate();
        // const location = useLocation();

        const { palette } = useTheme();

        const [tempFilter, setTempFilter] = React.useState<FilterDataProps[]>(
            filter.filters.category.length > 0 ?
                filter.filters.category : [],
        );
        const {
            register,
            handleSubmit,
            getValues,
            setValue,
            reset,
        } = useForm<FormCategories>({
            mode: 'all',
        });


        const removeBrandsFromHookForm = () => {
            const getFormValues = getValues('category');
            const filterFormValues = tempFilter.
                filter((item) => getFormValues[item._id as any]);
            setValue('category', filterFormValues);
        };

        const handleRemoveSelectedFilter = ({ _id, nome }: NewAdDataProps) => {
            const filter = tempFilter.filter((item) => item._id !== _id);
            setTempFilter((prev) => prev = [...filter]);
            setURLParams('category', undefined);
            filter.map((item) => {
                setURLParams('category', item._id+','+item.nome);
            });
            removeBrandsFromHookForm();
        };

        const setURLParams = (key: string, value: string | undefined) => {
            const search = filter.handleSetURLParams(key, value);
            navigate({ search });
        };

        const resetFilterData = () => {
            setTempFilter((prev) => prev = []);
            filter.add({
                type: 'category',
                data: [],
            });
            categories.reset({ type: 'history' });
            reset({
                category: [],
            });
            setURLParams('category', undefined);
        };

        const handleCheckedCategories = ({
            _id,
            nome,
            type,
        }: FilterComponentObjProps) => {
            if (type === 'multiple') {
                const find = tempFilter.find((item) => item._id === _id);

                if (!find) {
                    setTempFilter((prev) => prev = [...prev, {
                        _id: _id,
                        nome: nome,
                        param: 'd=',
                        filter: 'category',
                    }]);
                    setValue('category', tempFilter);
                } else {
                    handleRemoveSelectedFilter({ _id, nome });
                    removeBrandsFromHookForm();
                }
            }

            if (type === 'single') {
                setTempFilter((prev) => prev = [{
                    _id: _id,
                    nome: nome,
                    param: 'd=',
                    filter: 'category',
                }]);
            }
        };

        interface CategoriesProps extends ListCategorizationProps {
            lastLevel: boolean;
        }

        const handleCategories = async ({
            _id,
            direction,
            title,
            categoria_pai,
            lastLevel,
        }: CategoriesProps) => {
            await categories.
                handleCategorization({
                    _id: _id,
                    direction: direction,
                    lastLevel: lastLevel,
                    title: title,
                    categoria_pai: categoria_pai,
                });
        };

        const handleHistoryCategorization = async ({
            _id,
            categoria_pai,
            direction,
        }: ListCategorizationProps) => {
            await categories.historyCategorization({
                _id: _id,
                categoria_pai: categoria_pai,
                direction: direction,
            });

            if (categories.list.history.length === 1) {
                resetFilterData();
            };
        };

        const handleURLParams = async (data: FilterStateProps) => {
            const urlParams = new URLSearchParams(location.search);
            const response = await filter.handleURLParams(data, 'category');
            const search = response.params;
            search.map((item) => {
                const find = urlParams.getAll('category').find((findItem) =>
                    findItem === item[1]);
                if (!find) {
                    setURLParams(item[0], item[1]);
                }
            });
        };

        // const parseQueryStringURL = async (query: string, type: FiltersType, ad: AdStateProps, filterData: FilterDataProps[]) => {
        //     const response = await filter.parseQueryStringURL(query, type, ad, filterData);

        //     setTempFilter((prev) => prev = response.data);
        // };

        const onSubmit: SubmitHandler<FormCategories> = async (data) => {
            filter.add({
                type: 'category',
                data: tempFilter,
            });
            await categories.handleCategorization({});
            categories.reset({ type: 'history' });

            handleURLParams({ category: tempFilter } as any);
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        React.useEffect(() => {
            if (!categories.list.categories.length) {
                categories.handleCategorization({});
            }
        }, []);

        React.useEffect(() => {
            // parseQueryStringURL(location.search, 'categories', ad, filter.filters.categories);
        }, []);

        return (
            <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)}>
                <FilterContent minHeightContent={minHeightContent}>
                    <Box sx={{
                        display: 'flex',
                        backgroundColor: palette.clouds,
                        overflow: 'hidden',
                        overflowX: 'auto',
                        mt: -1,
                    }}>
                        {categories.
                            list.
                            history.map(({ title, _id, categoria_pai }, i) => (
                                <Box
                                    key={categoria_pai as string + i}>
                                    <BackItem
                                        onClick={() => handleHistoryCategorization({
                                            _id: _id,
                                            categoria_pai: categoria_pai,
                                            direction: 'BACK',
                                        })}
                                        title={title as string} />
                                </Box>
                            ))}
                    </Box>
                    <BoxActiveFiltersList>
                        {tempFilter.map(({ _id, nome }) => (
                            <ButtonSelectedFilter
                                key={_id}
                                value={nome}
                                onClick={() => handleRemoveSelectedFilter({
                                    _id,
                                    nome,
                                })}
                            />
                        ))}
                    </BoxActiveFiltersList>
                    {
                        categories.list.categories.length > 0 ?
                            categories.list.categories.map((category) => {
                                return (category.ativo && <MenuItem
                                    key={`${category.nome}-${category._id}`}
                                    // onClick={() =>
                                    //     handleCategories({
                                    //         lastLevel: category.lastLevel,
                                    //         _id: category._id,
                                    //         direction: 'FORWARD',
                                    //         title: category.nome,
                                    //         categoria_pai: category.categoria_pai,
                                    //     })}
                                    onClick={!category.lastLevel ? () =>
                                        handleCategories({
                                            lastLevel: category.lastLevel,
                                            _id: category._id,
                                            direction: 'FORWARD',
                                            title: category.nome,
                                            categoria_pai: category.categoria_pai,
                                        }) : () => { }}
                                    borderType={'bottom'}
                                    title={{
                                        text: category.nome,
                                        color: palette.dark,
                                        size: 'medium',
                                    }}
                                    leftChildren={
                                        <Box pl={2} pr={2}
                                            onClick={() =>
                                                handleCategories({
                                                    lastLevel: category.lastLevel,
                                                    _id: category._id,
                                                    direction: 'FORWARD',
                                                    title: category.nome,
                                                    categoria_pai: category.categoria_pai,
                                                })}>
                                            {!category.lastLevel && (
                                                <I
                                                    style={{ fontSize: '1rem' }}
                                                    icon={['far', 'plus']}
                                                />
                                            )}
                                        </Box>}
                                    htmlFor={category.lastLevel ? category._id : ''}>
                                    <Checkbox
                                        id={category._id}
                                        size='small'
                                        value={category._id}
                                        {...register('category', {
                                            required: false,
                                        })}
                                        checked={!!tempFilter
                                            .find((item) =>
                                                item._id === category._id)}
                                        onChange={(event) =>
                                            handleCheckedCategories({
                                                _id: category._id,
                                                nome: category.nome,
                                                type: 'multiple',
                                            })}
                                    />
                                    {/* {category.lastLevel ? (
                                        <Checkbox
                                            id={category._id}
                                            size='small'
                                            value={category._id}
                                            {...register('category', {
                                                required: false,
                                            })}
                                            checked={!!tempFilter
                                                .find((item) =>
                                                    item._id === category._id)}
                                            onChange={(event) =>
                                                handleCheckedCategories({
                                                    _id: category._id,
                                                    nome: category.nome,
                                                    type: 'multiple',
                                                })}
                                        />
                                    ) : (
                                        <Box pl={2} pr={2}>
                                            <I
                                                style={{ fontSize: '1rem' }}
                                                icon={['far', 'chevron-right']}
                                            />
                                        </Box>
                                    )} */}

                                </MenuItem>
                                );
                            }) : <MessageBox
                                type='info'
                                message='Nenhuma categoria disponível'
                            />}

                </FilterContent>
                <FilterFooter
                    onClearAction={onClearAction ?
                        onClearAction as () => void :
                        resetFilterData}
                    onSubmitAction={onSubmitAction ?
                        onSubmitAction as () => void :
                        () => { }}
                />
            </Box>
        );
    });
