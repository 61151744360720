import { RouteObject, useLocation, useRoutes } from 'react-router-dom';
import { authenticatedRoutes } from './authenticated.stack.routes';
import { stackRoutes, redirectRoute, redirectLoginSuggestion } from './stack.routes';

import { localStorage } from '../utils/localStorage';
import { useAds } from '../hooks/ads';
import React from 'react';
import { useOthers } from '../hooks/others';
import ReactGA from 'react-ga4';

const test: RouteObject[] = [
    {
        path: undefined,
        element: undefined,
    },
];

export const Routes = () => {
    const ads = useAds();
    const { categories } = useOthers();

    const location = useLocation();
    const auth = localStorage({
        action: 'get',
        key: '@auth',
    });

    const path = window.location.pathname;

    const isAuthenticated = (auth: Boolean): RouteObject[] | undefined => {
        const key = location.pathname.split('/')[1];
        const find = stackRoutes.find((route) => route.path?.includes(key));
        return auth ? authenticatedRoutes() :
            !find ? redirectRoute({
                path: path,
            }) : path.includes('membros') ?
                redirectLoginSuggestion() : test;
    };

    const resetHooks = () => {
        ads.createNewAd.clearAdData({ clearAll: true });
    };

    React.useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
        if ((location.pathname.indexOf('/produtos/adicionar') !== -1) ||
            (location.pathname.indexOf('/produtos/editar') !== -1) ||
            (location.pathname.indexOf('/produtos/adicionar/variantes') !== -1)) {
            return;
        } else {
            if (ads.createNewAd.data.isThereData) {
                resetHooks();
                categories.reset({ type: 'history' });
                categories.reset({ type: 'categories' });
            }
            if (ads.ad.weights.isLoaded) {
                ads.resetIsLoaded();
                categories.reset({ type: 'history' });
                categories.reset({ type: 'categories' });
            }
        }
    }, [location.pathname]);

    return useRoutes([...stackRoutes, ...isAuthenticated(!!auth) as any]);
};
