import React from 'react';
import {
    Container,
    Grid, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import { ShowcaseItem } from './components/showcase-item';
import { MountURLParamsForResultsProps } from '@interfaces';
import { useOthers } from '../../../../hooks/others';
import { images } from '../../../../utils/images';
import { mountURLParamsForResults } from '@utils';
import { useNavigate } from 'react-router-dom';

const OurSelection: React.FC = () => {
    const { showcase } = useOthers();
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();

    const loadShowCases = async () => {
        return await showcase.load();
    };

    const mountURLParams = ({ ...props }: MountURLParamsForResultsProps) => {
        const response = mountURLParamsForResults({ ...props }) as string;
        return navigate(response);
    };

    React.useEffect(() => {
        loadShowCases();
    }, []);

    return (
        showcase.list.isLoaded && showcase.list.data.length > 0 ?
            <Container
                sx={{
                    pt: matches ? '1.875rem' : '0.9375rem',
                    pb: matches ? '1.875rem' : '0.9375rem',
                }}
                maxWidth='lg'>
                <Stack>
                    <Typography
                        textAlign={'center'}
                        color={palette.dark}
                        fontSize={matches ? '2.375rem' : '1.5rem'}
                        fontWeight={typography.fontWeightRegular}
                        variant='h2'>
                    CURADORIA
                    </Typography>
                    <Typography
                        textAlign={'center'}
                        color={palette.dark}
                        fontSize={matches ? '1rem' : '0.875rem'}
                        fontWeight={typography.fontWeightRegular}
                        variant='body1'>
                    descubra nossa seleção de produtos por categoria
                    </Typography>
                </Stack>
                <Grid
                    sx={{ paddingTop: matches ? '1.875rem' : '0.9375rem' }}
                    container spacing={4}>
                    {showcase.list.isLoaded ?
                        showcase.list.data.length > 0 &&
                    showcase.list.data.map((showcase) => (
                        <Grid
                            key={`collection-${showcase._id}`}
                            item lg={6} md={6} sm={6} xs={6}>
                            <ShowcaseItem
                                src={images.load(showcase.banners[0], 'server')}
                                to={() => mountURLParams({
                                    ...showcase,
                                    type: 'showcase',
                                })} />
                        </Grid>
                    )) :
                        ''}
                </Grid>
            </Container> : null
    );
};

export { OurSelection };
