import React from 'react';

import {
    Container,
    Stack,
} from '@mui/material';

import Banner from '@img/banner-home.png';
import { HeaderPage } from '@components';
import { PromoctionBox } from './components/promoction-box';

const Promoctions: React.FC = () => {
    return (
        <Container className='no-padding' maxWidth='lg'>
            <HeaderPage
                icon={['far', 'bullhorn']}
                titleAlign={'default'}
                title={'Promoções'} />

            <Stack
                sx={{ padding: '16px' }}
                spacing={2}>
                <PromoctionBox
                    image={Banner}
                    title={'Frete R$9,90 para todo o Brasil'}
                    legend={'Promo frete R$9,90 para todo o Brasil até 0,5kg'}
                    startDate={'12/11/2019'}
                    endDate={'12/11/2019'}
                    details={'Cadastre-se na plataforma e ganhe 50 pontos!'} />
                <PromoctionBox
                    image={Banner}
                    title={'Frete R$9,90 para todo o Brasil'}
                    legend={'Promo frete R$9,90 para todo o Brasil até 0,5kg'}
                    startDate={'12/11/2019'}
                    endDate={'12/11/2019'}
                    details={'Cadastre-se na plataforma e ganhe 50 pontos!'} />
            </Stack>
        </Container>
    );
};

export { Promoctions };
