/* eslint-disable max-len */
import { styled } from '@mui/material';

export const ButtonSeeMore = styled('button')`
    border: 0;
    text-decoration: underline;
    font-size: .875rem;
    position: relative;
    background-color: ${({ theme }) => theme.palette.grey[100]};
    text-transform: lowercase;
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    cursor: pointer;
  &:before {
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -30px;
    height: auto;
    width: 30px;
    background: transparent linear-gradient(90deg, #FFFFFF00 0%, #F9F9F9E6 36%, #F9F9F9 68%, #F9F9F9 100%) 0% 0% no-repeat padding-box;
  }
`;
