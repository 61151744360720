import React from 'react';
import {
    FiltersProductsListProps,
    SelectedFilterProps,
    VisibilityFiltersProps,
    VisibilityMobileFiltersProps,
} from '@interfaces';
import { ConditionShowFiltersType } from '@types';

const utilVisibilityFilters = (): VisibilityFiltersProps => {
    const [visibilityMobileFilters,
        setVisibilityMobileFilters] = React.useState<boolean>(false);

    const [selectedFilter,
        setSelectedFilter] = React.useState<FiltersProductsListProps>(
            {} as FiltersProductsListProps,
        );
    const [mobileFilteringFilters,
        setMobileFilteringFilters] = React.useState<ConditionShowFiltersType>('all');

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleResetSelectedFilters = () => {
        setSelectedFilter((prev) => prev = {} as any);
    };

    const handleVisibilityMobileFilters = (
        { action, type }: VisibilityMobileFiltersProps) => {
        const actions = {
            open: true,
            close: false,
        };

        setVisibilityMobileFilters((prev) => actions[action]);

        switch (type) {
        case 'all':
            setMobileFilteringFilters((prev) => prev = 'all');
            break;
        case 'onlyOrderBy':
            setMobileFilteringFilters((prev) => prev = 'onlyOrderBy');
            break;

        default:
            setMobileFilteringFilters((prev) => prev = 'all');
            break;
        }

        if (action === 'close') {
            handleResetSelectedFilters();
        }
    };

    const handleSelectedFilter = ({ filter }: SelectedFilterProps) => {
        setSelectedFilter((prev) => prev = filter);
    };

    const handleMobileFilterCondition = ({ filter, cond }: {
        cond: ConditionShowFiltersType,
        filter: FiltersProductsListProps
    }) => {
        let result;

        if (cond === 'onlyOrderBy') {
            return result = (filter.key !== 'ordenar-por');
        }

        if (cond === 'all') {
            return result = (filter.key === 'ordenar-por');
        }

        return result;
    };


    const showFilter = (event: React.MouseEvent<HTMLButtonElement>,
        filter: FiltersProductsListProps) => {
        if (event.currentTarget !== anchorEl) {
            setAnchorEl(event.currentTarget);
            handleSelectedFilter({
                filter: filter,
            });
        } else {
            hideFilter();
        }
    };
    const hideFilter = () => {
        setAnchorEl(null);
        handleResetSelectedFilters();
    };

    const afterSubmit = () => {
        setAnchorEl(null);
        setSelectedFilter((prev) => prev = {
            ...prev,
            id: 'id-simulator',
        });
    };

    return {
        mobileFilters: {
            visibilityMobileFilters,
            selectedFilter,
            mobileFilteringFilters,
            handleResetSelectedFilters,
            handleVisibilityMobileFilters,
            handleSelectedFilter,
            handleMobileFilterCondition,
        },
        desktopFilters: {
            anchorEl,
            open,
            showFilter,
            hideFilter,
            afterSubmit,
        },
    };
};

export { utilVisibilityFilters };
