export const settings = {
    className: 'slick-carousel-center',
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '100',
    autoplay: true,
    responsive: [
        {
            breakpoint: 1100,
            settings: {
                centerPadding: '0',
                slidesToShow: 3,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 900,
            settings: {
                centerPadding: '100',
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
            },
        },
        {
            breakpoint: 738,
            settings: {
                centerPadding: '60',
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
            },
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '50',
            },
        },
        {
            breakpoint: 510,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '10',
            },
        },
        {
            breakpoint: 400,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: '-10',
            },
        },
    ],
};
