/* eslint-disable max-len */
import { MenuItem } from '@components';
import { useFilters } from '@hooks';
import {
    FilterComponentObjProps,
    FilterComponentProps,
    FormOrderBy,
    FormStaticProps,
} from '@interfaces';
import { FilterDataProps } from '@interfaces/Contexts';
import { Box, Radio, useMediaQuery } from '@mui/material';
import React, { ForwardRefExoticComponent, RefAttributes } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
    FilterContent,
    FilterFooter,
    ButtonSelectedFilter,
    BoxActiveFiltersList,
} from '../../../components/filters';

import { orderByList } from '@static/filter-orderby';

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

export const OrderByFilter: ForwardRefExoticComponent<FilterComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            minHeightContent,
            onClearAction,
            onSubmitAction,
        } = props;

        // const { ad } = useAds();
        const filter = useFilters();
        const navigate = useNavigate();

        const matches = useMediaQuery('(min-width:600px)');

        const [tempFilter, setTempFilter] = React.useState<FilterDataProps[]>(
            filter.filters.orderby.length > 0 ?
                filter.filters.orderby : [],
        );

        const {
            register,
            handleSubmit,
            getValues,
            setValue,
            reset,
        } =
            useForm<FormOrderBy>({
                mode: 'all',
            });


        const removeFromHookForm = () => {
            const getFormValues = getValues('orderby');
            const filterFormValues = tempFilter.
                filter((item) => getFormValues[item._id as any]);
            setValue('orderby', filterFormValues);
        };

        const handleRemoveSelectedFilter = ({ _id }: FilterDataProps) => {
            const filtering = tempFilter.filter((item) => item._id !== _id);
            setTempFilter((prev) => prev = [...filtering]);
            removeFromHookForm();
        };

        const setURLParams = (key: string, value: string | undefined) => {
            const search = filter.handleSetURLParams(key, value, 'single');
            navigate({ search });
        };

        const resetFilterData = () => {
            setTempFilter((prev) => prev = []);
            filter.add({
                type: 'orderby',
                data: [],
            });
            reset({
                orderby: [],
            });
            setURLParams('orderby', undefined);
        };

        const handleTempFilters = ({ _id, nome, type, filter }: FilterComponentObjProps) => {
            if (type === 'multiple') {
                const find = tempFilter.find((item) => item._id === _id);

                if (!find) {
                    setTempFilter((prev) => prev = [...prev, {
                        _id: _id,
                        nome: nome,
                        param: 'o=',
                        filter: filter,
                    }]);
                    setValue('orderby', tempFilter);
                } else {
                    handleRemoveSelectedFilter({ _id, nome });
                    removeFromHookForm();
                }
            }

            if (type === 'single') {
                setTempFilter((prev) => prev = [{
                    _id: _id,
                    nome: nome,
                    param: 'o=',
                    filter: filter,
                }]);
            }
        };

        // const handleURLParams = async (data: FilterStateProps) => {
        //     const response = await filter.handleURLParams(data, 'orderby');
        //     const params = response.params.join(',');
        //     setURLParams('orderby', params);
        // };

        // const parseQueryStringURL = async (query: string, type: FiltersType, ad: AdStateProps, filterData: FilterDataProps[]) => {
        //     const response = await filter.parseQueryStringURL(query, type, ad, filterData);

        //     setTempFilter((prev) => prev = response.data);
        // };

        const onSubmit: SubmitHandler<FormOrderBy> = async (data) => {
            filter.add({
                type: 'orderby',
                data: tempFilter,
            });

            setURLParams('orderby', tempFilter[0].filter+',orderby');

            // handleURLParams({ orderby: tempFilter } as any);
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        // React.useEffect(() => {
        //     parseQueryStringURL(location.search, 'orderby', ad, filter.tempFilters.orderby);
        // }, []);

        return (
            <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)}>

                <FilterContent minHeightContent={minHeightContent}>
                    <BoxActiveFiltersList>
                        {tempFilter.map(({ _id, nome }) => (
                            <ButtonSelectedFilter
                                key={_id}
                                value={nome}
                                onClick={() => handleRemoveSelectedFilter({
                                    _id,
                                    nome,
                                })}
                            />
                        ))}
                    </BoxActiveFiltersList>

                    {/* <Box pl={1} pr={2}>
                        <FormHelperText error>
                            {errors.condition && errors.condition.message}
                        </FormHelperText>
                    </Box> */}

                    <FilterContent minHeightContent={114}>

                        {orderByList.map(({ _id, nome, value }) => (
                            <MenuItem
                                key={_id}
                                onClick={() => { }}
                                borderType={'bottom'}
                                title={{
                                    text: nome,
                                    size: !matches ? 'small' : 'tiny',
                                }}
                                htmlFor={_id}>
                                <Radio
                                    id={_id}
                                    size='small'
                                    value={_id}
                                    {...register('orderby', {
                                        required: false,
                                    })}
                                    checked={!!tempFilter
                                        .find((item) => item._id === _id)}
                                    onChange={() => handleTempFilters({
                                        _id: _id,
                                        nome: nome,
                                        type: 'single',
                                        filter: value,
                                    })}
                                />
                            </MenuItem>
                        ))}
                    </FilterContent>

                </FilterContent>
                <FilterFooter
                    onClearAction={onClearAction ?
                        onClearAction as () => void :
                        resetFilterData
                    }
                    onSubmitAction={onSubmitAction ?
                        onSubmitAction as () => void :
                        () => { }}
                // disabledOnSubmit={!(!!tempFilter.length)}
                />
            </Box>
        );
    });


// const OrderByFilter = () => {
//     const matches = useMediaQuery('(min-width:600px)');
//     const [selectedValue, setSelectedValue] = React.useState('maior');

//     const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         setSelectedValue(event.target.value);
//     };

//     return (
//         <Box height='100%' component='form'>

//             <FilterContent minHeightContent={114}>
//                 <BoxActiveFiltersList>
//                     <ButtonSelectedFilter value='Maior preço' />
//                 </BoxActiveFiltersList>

//                 {orderByList.map(({ id, name, value }) => (
//                     <MenuItem
//                         key={id}
//                         onClick={() => { }}
//                         borderType={'bottom'}
//                         title={{
//                             text: name,
//                             size: !matches ? 'small' : 'tiny',
//                         }}
//                         htmlFor={id}>
//                         <Radio
//                             checked={selectedValue === value}
//                             onChange={handleChange}
//                             value={value}
//                             name="radio-buttons"
//                             id={id}
//                             size='small'
//                         />
//                     </MenuItem>
//                 ))}
//             </FilterContent>
//             <FilterFooter
//                 onClearAction={() => { }}
//                 onSubmitAction={() => { }}
//             />
//         </Box>

//     );
// };

// export { OrderByFilter };
