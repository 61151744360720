import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const css = {
        sxIconButton: {
            default: {
                'backgroundColor': palette.common.white,
                'borderRadius': '0.1875rem',
            },
            small: {
                padding: '0.4375rem',
                border: `0.0625rem solid ${palette.lightGrey}`,
            },
            medium: {
                border: `0.0625rem solid ${palette.lightGrey}`,
                padding: '0.625rem',
            },
        },
        sxIcon: {
            default: {
                color: palette.dark,
            },
            small: {
                fontSize: '1rem',
            },
            medium: {
                fontSize: '1.125rem',
            },
        },
    };
    return { ...css };
};
