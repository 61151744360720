import { useMediaQuery, useTheme } from '@mui/material';

const sx = () => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const { pxToRem } = typography;

    const css = {
        cart: {
            wrapper: {
                border: '1px solid' + palette.clouds,
            },
            header: {
                pt: 1,
                pb: 1,
                pl: 2,
                borderBottom: '1px solid' + palette.clouds,
            },
            footer: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                flexDirection: 'row',
                pt: 1,
                shippingMob: {
                    justifyContent: 'space-between',
                    width: '100%',
                    pl: 2,
                    pr: 2,
                    pb: 1,
                    borderBottom: '1px solid' + palette.clouds,
                },
            },
        },
        resume: {
            wrapper: {
                border: '1px solid' + palette.clouds,
            },
            total: {
                pt: 1,
                pb: 1,
                pl: 2,
                pr: 2,
                backgroundColor: palette.clouds,
            },
        },
        box: {
            pt: matches ? 2 : 1,
            pl: 2,
            pr: 2,
            backgroundColor: 'white',
            offset: {
                ml: !matches ? -2 : 0,
                mr: !matches ? -2 : 0,
            },
        },
        grid: {
            container: {
                alignItems: 'stretch',
                mt: 0,
            },
            divider: {
                borderRightWidth: pxToRem(1),
                borderRightStyle: 'solid',
                borderRightColor: palette.clouds,
            },
        },
        products: {
            shipping: {
                wrapper: {
                    direction: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '5px 10px',
                    backgroundColor: palette.clouds,
                    maxWidth: '88px',
                    borderRadius: '3px',
                },
                icon: {
                    position: 'relative',
                    top: pxToRem(1),
                    ml: pxToRem(4),
                },
                title: {
                    fontSize: pxToRem(12),
                    fontWeight: typography.fontWeightBold,
                    color: palette.dark,
                    display: 'flex',
                },
            },
            listWrapper: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
        },
        discount: {
            symbol: {
                fontSize: pxToRem(18),
                position: 'relative',
                top: pxToRem(-2),
            },
            negativePrice: {
                display: 'flex',
                alignItems: 'center',
                color: palette.pomegarnate,
                pl: 2,
            },
        },
        buttons: {
            cancel: {
                'color': palette.common.white,
                'backgroundColor': '#f6bbbf',
                ':hover': {
                    'backgroundColor': '#f6bbbf',
                },
            },
            confirm: {
                'backgroundColor': palette.pomegarnate,
                ':hover': {
                    'backgroundColor': palette.pomegarnate,
                },
            },
            typography: {
                ':hover': {
                    textDecoration: 'underline',
                },
                'margin': '15px',
                'cursor': 'pointer',
                'textAlign': 'center',
                'fontSize': '0.875rem',
            },
        },
    };

    return { css };
};

export { sx };
