import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');

    const css = {
        hero: {

            sxBox: {
                display: 'flex',
                marginTop: !matches ? '0.625rem' : '0',
                flexDirection: 'column',
            },
            sxBoxContent: {
                padding: matches ? '1.875rem' : '1.25rem 0.9375rem',
                backgroundColor: palette.common.white,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            },
            sxBoxImage: {
                maxHeight: '460px',
                width: '100%',
                padding: matches ? 0 : '0 0.9375rem',
            },
            sxImage: {
                height: '100%',
                width: '100%',
                objectFit: 'cover',
            },
        },
    };
    return { css };
};
