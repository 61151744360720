/* eslint-disable indent */
import React from 'react';
import $ from 'jquery';
import 'jquery-mask-plugin';

import {
    AlertColor,
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
    CreditCardFlagRegexp,
    CreditCardFlagType,
    ErrorsProps,
    FormPaymentMethodCreditCard,
    ToggleStaticProps,
} from '@interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { formSchema } from './yup';
import { CreditCardIMG } from './styles';
import { flagsIcon } from '@static/payment-method';
import { usePaymentMethod, useUser } from '@hooks';
import { Snackbar } from '@components';

const FormCreditCard = ({ onSubmitAction }: {
    onSubmitAction?: () => void;
}) => {
    const urlSearch = new URLSearchParams(window.location.search);
    const { palette, typography } = useTheme();
    const { loadUser, user } = useUser();
    const { creditCard } = usePaymentMethod();
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const { register, handleSubmit, formState: { errors }, getValues, setValue } =
        useForm<FormPaymentMethodCreditCard>({
            resolver: yupResolver(formSchema),
            mode: 'all',
        });

    const [creditCardFlag, setCreditCardFlag] = React.useState<
        CreditCardFlagType>('UnknownFlag');
    const [responseData, setResponseData] = React.useState({
        type: 'success' as AlertColor,
        message: '',
        isLoading: false,
    });

    const [cardErrors, setCardErrors] = React.useState<ErrorsProps>({
        listErrors: {
            error: false,
            location: '',
            msg: '',
            param: '',
            value: '',
        },
    });
    const keys = Object.keys(cardErrors);

    const checkCreditCardType = (e: string) => {
        if (e.length > 3) {
            const cardnumber = e.replace(' ', '').replace(/[^0-9]+/g, '');
            const emptyFields = 16 - cardnumber.length;
            let zeros = '';
            for (let count = 0; count < emptyFields; count++) {
                zeros += '0';
            }

            const cards: CreditCardFlagRegexp = {
                Visa: /\b4\d{3}[ -]*\d{4}[ -]*\d{4}[ -]*\d(?:\d{3})?\b/,
                Mastercard: /\b5[1-5]\d{2}[ -]*\d{4}[ -]*\d{4}[ -]*\d{4}\b/,
                DinersClub: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
                AmericanExpress: /^3[47][0-9]{13}$/,
                Hipercard: /\b(3841|6062)\d{12}\b/,
            };

            for (const flag in cards) {
                if (cards[flag as keyof CreditCardFlagRegexp]
                    .test((cardnumber + zeros))) {
                    return setCreditCardFlag(flag as CreditCardFlagType);
                }
            }

            return setCreditCardFlag('UnknownFlag');
        } else {
            return setCreditCardFlag('UnknownFlag');
        }
    };

    const onSubmit: SubmitHandler<FormPaymentMethodCreditCard> = async (data) => {
        checkCreditCardType(data.cc_number);
        setResponseData((prev) => prev = {
            ...prev,
            isLoading: true,
        });
        const addressId = urlSearch.get('shippingAddress');
        const address = user.data?.endereco.find((ad) => ad._id === addressId);
        if (address) {
            const response = await creditCard.handlePaymentMethod({
                cardInfos: {
                    ano_expiracao: data.cc_expires_year,
                    bandeira: creditCardFlag,
                    cpf: data.cc_cpf,
                    cvv: data.cc_cvv,
                    mes_expiracao: data.cc_expires_month,
                    numero_cartao: data.cc_number,
                    nome: data.cc_name,
                    endereco: address,
                },
            });
            if (response && !response.error) {
                snackRef.current?.show();
                setCardErrors({
                    listErrors: {
                        error: false,
                        location: '',
                        msg: '',
                        param: '',
                        value: '',
                    },
                });
                setResponseData((prev) => prev = {
                    ...prev,
                    type: response?.response.type,
                    message: response?.response.message,
                    isLoading: false,
                });
                creditCard.loadList();
                setTimeout(() => {
                    onSubmitAction && onSubmitAction();
                }, 1500);
            } else {
                if (response &&
                    response.response.errors && response.response.errors.errors) {
                    setResponseData((prev) => prev = {
                        ...prev,
                        isLoading: false,
                    });
                    const filterErrors = response.response.errors.errors.
                        map((err: any, i: number) => {
                            const paramsKeys = err.param.split('.');
                            const key = paramsKeys[paramsKeys.length - 1];
                            const object = {
                                [key]: {
                                    location: err.location,
                                    msg: err.msg,
                                    param: err.param,
                                    error: true,
                                },
                            };

                            if (err.param !== keys[i]) {
                                return (object);
                            }

                            return (object);
                        });

                    const reduceErrors = filterErrors.reduce(
                        (acc: any, current: any) => ({
                            ...acc,
                            ...current,
                        }),
                        {},
                    );
                    setCardErrors((prev) => prev = reduceErrors);
                } else {
                    if (response && response.response.message) {
                        snackRef.current?.show();
                        setResponseData((prev) => prev = {
                            ...prev,
                            type: response?.response.type,
                            message: response?.response.message,
                            isLoading: false,
                        });
                    }
                }
            }
        } else {
            snackRef.current?.show();
            setResponseData((prev) => prev = {
                ...prev,
                type: 'error',
                message: 'Não encontramos seu endereço',
                isLoading: false,
            });
        }
    };

    const inputMasks = () => {
        $('.number').mask('ZZZZ ZZZZ ZZZZ ZZZZ', {
            translation: {
                'Z': {
                    pattern: /[0-9*]/,
                },
            },
        });
        $('.cpf').mask('000.000.000-00');
        $('.month').mask('00');
        $('.year').mask('0000');
        $('.cvv').mask('ZZZ', {
            translation: {
                'Z': {
                    pattern: /[0-9*]/,
                },
            },
        });
    };

    React.useEffect(() => {
        inputMasks();
        loadUser();
    }, []);

    return (
        <>
            <Typography
                variant="h2"
                component='p'
                mb={4}
                fontSize={16}
                fontWeight={typography.fontWeightMedium}
                color={palette.dark}
                textAlign='center'
                gutterBottom>
                Preencha os campos abaixo com os dados
                do seu cartão de crédito.
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={12} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'Número do cartão'}
                            placeholder='0000 0000 0000 0000'
                            variant="outlined"
                            helperText={
                                (cardErrors.hasOwnProperty(
                                    'numero_cartao') &&
                                    cardErrors.numero_cartao.msg) ||
                                (!!errors.cc_number?.message &&
                                    errors.cc_number.message)}
                            error={
                                (cardErrors.hasOwnProperty(
                                    'numero_cartao') &&
                                    !!cardErrors.numero_cartao.error) ||
                                !!errors.cc_number}
                            inputProps={{
                                className: 'number',
                                onBlur: (e) => {
                                    setValue('cc_number', e.target.value);
                                    checkCreditCardType(e.target.value);
                                },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <CreditCardIMG
                                                src={
                                                    flagsIcon[
                                                        creditCardFlag]}
                                                alt='credit card' />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            {...register('cc_number',
                                { required: true },
                            )}
                            onChange={(e) => {
                                setValue('cc_number', e.target.value);
                                checkCreditCardType(e.target.value);
                            }}
                            onSelect={(e) => {
                                if (!getValues('cc_number')) {
                                    checkCreditCardType('');
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'Nome do titular como no cartão'}
                            variant="outlined"
                            helperText={
                                (cardErrors.hasOwnProperty('nome') &&
                                    cardErrors.nome.msg) ||
                                (!!errors.cc_name?.message &&
                                    errors.cc_name.message)}
                            error={
                                (cardErrors.hasOwnProperty(
                                    'nome') &&
                                    !!cardErrors.nome.error) ||
                                !!errors.cc_name}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start">
                                        <IconButton>
                                            <I
                                                icon={['fas', 'user']}
                                                fontSize={16}
                                                color={palette.dark} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            {...register('cc_name',
                                { required: true },
                            )} />
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'CPF do titular'}
                            placeholder='000.000.000-00'
                            variant="outlined"
                            helperText={
                                (cardErrors.hasOwnProperty('cpf') &&
                                    cardErrors.cpf.msg) ||
                                (!!errors.cc_cpf?.message &&
                                    errors.cc_cpf.message)}
                            error={
                                (cardErrors.hasOwnProperty(
                                    'cpf') &&
                                    !!cardErrors.cpf.error) ||
                                !!errors.cc_cpf}
                            inputProps={{
                                className: 'cpf',
                            }}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment
                                        position="start">
                                        <IconButton>
                                            <I
                                                icon={['fas',
                                                    'address-card']}
                                                fontSize={16}
                                                color={palette.dark} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            {...register('cc_cpf',
                                { required: true },
                            )} />
                    </Grid>
                    <Grid item xs={4} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            placeholder='MM'
                            label={'Mês'}
                            inputProps={{
                                className: 'month',
                            }}
                            variant="outlined"
                            helperText={
                                (cardErrors.hasOwnProperty(
                                    'mes_expiracao') &&
                                    cardErrors.mes_expiracao.msg) ||
                                (!!errors.cc_expires_month?.message &&
                                    errors.cc_expires_month.message)}
                            error={
                                (cardErrors.hasOwnProperty(
                                    'mes_expiracao') &&
                                    !!cardErrors.mes_expiracao.error) ||
                                !!errors.cc_expires_month}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <I
                                                icon={['fas',
                                                    'calendar-star']}
                                                fontSize={16}
                                                color={palette.
                                                    dark} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            {...register('cc_expires_month',
                                { required: true },
                            )} />
                    </Grid>
                    <Grid item xs={4} mb={1}>
                        <TextField
                            focused
                            placeholder='AAAA'
                            fullWidth
                            label={'Ano'}
                            inputProps={{
                                className: 'year',
                            }}
                            variant="outlined"
                            helperText={
                                (cardErrors.hasOwnProperty(
                                    'ano_expiracao') &&
                                    cardErrors.ano_expiracao.msg) ||
                                (!!errors.cc_expires_year?.message &&
                                    errors.cc_expires_year.message)}
                            error={
                                (cardErrors.hasOwnProperty(
                                    'ano_expiracao') &&
                                    !!cardErrors.ano_expiracao.error) ||
                                !!errors.cc_expires_year}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <I
                                                icon={['fas',
                                                    'calendar-days']}
                                                fontSize={16}
                                                color={palette.
                                                    dark} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            {...register('cc_expires_year',
                                { required: true },
                            )} />
                    </Grid>
                    <Grid item xs={4} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'CVV'}
                            inputProps={{
                                className: 'cvv',
                            }}
                            variant="outlined"
                            helperText={
                                (cardErrors.hasOwnProperty(
                                    'cvv') &&
                                    cardErrors.cvv.msg) ||
                                (!!errors.cc_cvv?.message &&
                                    errors.cc_cvv.message)}
                            error={
                                (cardErrors.hasOwnProperty(
                                    'cvv') &&
                                    !!cardErrors.cvv.error) ||
                                !!errors.cc_cvv}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <IconButton>
                                            <I
                                                icon={['fas',
                                                    'lock']}
                                                fontSize={16}
                                                color={palette.
                                                    dark} />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            {...register('cc_cvv',
                                { required: true },
                            )} />
                    </Grid>
                </Grid>
                <Box
                    display='flex'
                    justifyContent='center'
                    mt={2}>
                    <Box
                        maxWidth={320}
                        width='100%'
                        justifyContent='center'>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            type='submit'
                            className='MuiButton secondary'
                        >
                            Salvar {responseData.isLoading && (
                                <CircularProgress
                                    size={14}
                                    color='inherit'
                                    sx={{ ml: 0.5 }} />)}
                        </Button>
                    </Box>
                </Box>
            </form>
            <Snackbar
                ref={snackRef}
                message={responseData.message}
                severity={responseData.type}
            />
        </>
    );
};

export { FormCreditCard };
