import { Box, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Col, Cols, DividerV, IconBox, WrapperTimeLine } from './styles';
import React from 'react';

interface ProductStatusItemProps {
    status: ProductStatusType;
    title: string;
    active: boolean;
    date: string;
    hour: string;
}

type ProductStatusType = 'finalizado' |
    'pagamento_pendente' |
    'postado' |
    'etiqueta_gerada' |
    'urls_rastreio';

interface ProductStatusTimelineProps {
    children: React.ReactNode;
}

const ProductStatusTimeline = ({ children }: ProductStatusTimelineProps) => {
    return (
        <WrapperTimeLine>
            {children}
        </WrapperTimeLine>
    );
};

const ProductStatusTimelineItem = ({
    status, title, active, date, hour,
}: ProductStatusItemProps) => {
    const { typography, palette } = useTheme();

    // const statusObj = {
    //     finalizado: {
    //         data: '00/00/0000',
    //         hora: '10:35',
    //     },
    //     pagamento_pendente: {
    //         data: '00/00/0000',
    //         hora: '10:35',
    //     },
    //     postado: {
    //         data: '00/00/0000',
    //         hora: '10:35',
    //     },
    //     etiqueta_gerada: {
    //         data: '00/00/0000',
    //         hora: '10:35',
    //     },
    //     urls_rastreio: {
    //         data: '00/00/0000',
    //         hora: '10:35',
    //     },
    // };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
            }}
            className='product-status-item'>

            <Cols>

                <Col>
                    <IconBox sx={{
                        backgroundColor: active ? palette.dark : palette.clouds,
                    }}>
                        <I icon={['fal', 'check']}
                            color={active ? palette.common.white : palette.silverBlue}
                            fontSize={18} />
                    </IconBox>
                    <Typography
                        component='p'
                        sx={{
                            fontSize: typography.pxToRem(14),
                            fontWeight: active ?
                                typography.fontWeightBold :
                                typography.fontWeightRegular,
                            color: active ?
                                palette.dark :
                                palette.silverBlue,
                            display: 'flex',
                            ml: typography.pxToRem(10),
                        }}>
                        {title}
                    </Typography>
                </Col>
                {active && date && hour && (

                    <Col sx={{ justifyContent: 'flex-end' }}>
                        <Box>
                            <Typography
                                variant="h2"
                                component='h2'
                                fontSize={14}
                                fontWeight={typography.fontWeightBold}
                                color={active ? palette.dark : palette.silverBlue}>
                                {date}
                            </Typography>
                            <Typography
                                fontSize={12}
                                fontWeight={typography.fontWeightBold}
                                color={palette.americanRiver}>
                                {hour}
                            </Typography>
                        </Box>
                    </Col>
                )}
            </Cols>
            <DividerV className='divider' sx={{
                backgroundColor: active ? palette.dark : palette.clouds,
            }} />
        </Box>
    );
};

export { ProductStatusTimelineItem, ProductStatusTimeline };
