import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { MenuItem } from '@components';
import {
    Box,
    Checkbox,
    IconButton,
    Radio,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Form, SearchFieldInput } from './styles';

import {
    FilterContent,
    ButtonSelectedFilter,
    BoxActiveFiltersList,
    FilterFooter,
} from '../../../components/filters';
import { ForwardRefExoticComponent, RefAttributes } from 'react';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchemaCheckbox, formSchemaRadio } from './yup';

import { useAds } from '@hooks';
import FormHelperText from '@mui/material/FormHelperText';

import {
    FilterComponentObjProps,
    FilterComponentProps,
    FormBrands,
    FormStaticProps,
} from '@interfaces';
import { NewAdDataProps } from '@interfaces/Contexts';

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

export const BrandsFilter: ForwardRefExoticComponent<FilterComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            minHeightContent,
            filterInputsType,
            onClearAction,
            onSubmitAction,
        } = props;

        const { ad, createNewAd } = useAds();

        const { palette } = useTheme();
        const matches = useMediaQuery('(min-width:600px)');
        const [tempBrands, setTempBrands] = React.useState<NewAdDataProps[]>(
            createNewAd.data.brand ?
                createNewAd.
                    data.brand.length > 0 ?
                    createNewAd.data.brand : [] : []);
        const [searchBrand, setSearchBrand] = React.useState<string>('');

        const fieldTypes = {
            formSchema: {
                checkbox: formSchemaCheckbox,
                radio: formSchemaRadio,
            },
        };

        const {
            register,
            handleSubmit,
            getValues,
            setValue,
            reset,
            formState: { errors } } =
            useForm<FormBrands>({
                resolver: yupResolver(fieldTypes.formSchema[filterInputsType]),
                mode: 'all',
            });


        const removeBrandsFromHookForm = () => {
            const getFormValues = getValues('brand');
            const filterFormValues = tempBrands.
                filter((item) => getFormValues[item._id as any]);
            setValue('brand', filterFormValues);
        };

        const removeSelectedBrands = ({ _id, nome }: NewAdDataProps) => {
            const filter = tempBrands.filter((item) => item._id !== _id);
            createNewAd.removeData({ type: 'brand', data: { _id, nome } });
            setTempBrands((prev) => prev = [...filter]);
            removeBrandsFromHookForm();
        };

        const clearAdData = () => {
            setTempBrands((prev) => prev = []);
            reset({
                brand: [],
            });
        };

        // * o argumento @multiple indica o tipo de insersção que ocorrera
        // * multiple[checkbox] = multiplas inserções no array de obj
        // * single[radio] = uma unica inserção no array de obj
        const handleBrands = ({ _id, nome, type }: FilterComponentObjProps) => {
            if (type === 'multiple') {
                const find = tempBrands.find((item) => item._id === _id);

                if (!find) {
                    setTempBrands((prev) => prev = [...prev, {
                        _id: _id,
                        nome: nome,
                    }]);
                    setValue('brand', tempBrands);
                } else {
                    removeSelectedBrands({ _id, nome });
                    removeBrandsFromHookForm();
                }
            }

            if (type === 'single') {
                setTempBrands((prev) => prev = [{
                    _id: _id,
                    nome: nome,
                }]);
            }
        };

        const onSubmit: SubmitHandler<FormBrands> = async (data) => {
            createNewAd.insertData({
                type: 'brand',
                data: {
                    brand: tempBrands,
                },
            });
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        // React.useEffect(() => {
        //     if (!ad.brands.isLoaded &&
        //         !ad.brands.data.length) {
        //         loadBrands();
        //     }
        // }, []);

        React.useEffect(() => {
            if (createNewAd.data.brand &&
                !createNewAd.data.brand.length) {
                clearAdData();
            }
        }, [createNewAd.data.brand]);

        return (
            <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)}>

                <Box
                    width='100%'
                    sx={{
                        borderBottom: '1px solid ' + palette.grey[200],
                    }}>
                    <Form>
                        <Box width='100%'>
                            <SearchFieldInput
                                type='search'
                                placeholder='Busque uma marca'
                                value={searchBrand}
                                onChange={(e) => setSearchBrand(e.target.value)} />
                        </Box>

                        {searchBrand.length > 0 && (
                            <IconButton
                                aria-label="clear-search"
                                onClick={() => setSearchBrand((prev) => prev = '')}>
                                <I
                                    icon={['far', 'times']}
                                    size='xs'
                                    color={palette.dark} />
                            </IconButton>
                        )}

                        <Box minWidth={64} display='flex' justifyContent='center'>
                            <IconButton
                                aria-label="delete"
                                size="small"
                                type='submit'
                            >
                                <I
                                    icon={['far', 'search']}
                                    color='black'
                                    fontSize={16}
                                />
                            </IconButton>
                        </Box>

                    </Form>
                </Box>
                <FilterContent minHeightContent={minHeightContent}>
                    {createNewAd.data.brand && createNewAd.data.brand.length > 0 && (
                        <BoxActiveFiltersList>
                            {createNewAd.data.brand.map(({ _id, nome }) => (
                                <ButtonSelectedFilter
                                    key={_id}
                                    value={nome}
                                    onClick={() => removeSelectedBrands({ _id, nome })} />
                            ))}
                        </BoxActiveFiltersList>
                    )}

                    <Box pl={1} pr={2}>
                        <FormHelperText error>
                            {errors.brand && errors.brand.message}
                        </FormHelperText>
                    </Box>

                    {ad.brand.isLoaded ?
                        ad.brand.data.filter(({ nome }) => nome
                            .match(new RegExp(searchBrand, 'i')))
                            .map(({ _id, nome }) => (
                                <MenuItem
                                    key={_id}
                                    onClick={() => { }}
                                    borderType={'bottom'}
                                    title={{
                                        text: nome,
                                        size: !matches ? 'small' : 'tiny',
                                    }}
                                    htmlFor={_id}>
                                    {filterInputsType === 'radio' && (
                                        <Radio
                                            id={_id}
                                            size='small'
                                            value={_id}
                                            {...register('brand', {
                                                required: true,
                                            })}
                                            checked={!!tempBrands
                                                .find((item) => item._id === _id)}
                                            onChange={() => handleBrands({
                                                _id: _id,
                                                nome: nome,
                                                type: 'single',
                                            })}
                                        />
                                    )}
                                    {filterInputsType === 'checkbox' && (
                                        <Checkbox
                                            id={_id}
                                            size='small'
                                            value={_id}
                                            {...register('brand', {
                                                required: true,
                                            })}
                                            checked={!!tempBrands
                                                .find((item) => item._id === _id)}
                                            onChange={() => handleBrands({
                                                _id: _id,
                                                nome: nome,
                                                type: 'multiple',
                                            })}
                                        />
                                    )}
                                </MenuItem>
                            )) : 'Carregando marcas...'}

                </FilterContent>
                <FilterFooter
                    onClearAction={onClearAction ?
                        onClearAction as () => void :
                        clearAdData}
                    onSubmitAction={onSubmitAction ?
                        onSubmitAction as () => void :
                        () => { }}
                    disabledOnSubmit={!(!!tempBrands.length)}
                />
            </Box>
        );
    });
