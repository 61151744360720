import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { sx } from './sx';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

type FontWeightType = 'bold' | 'regular' | 'medium';

type BoxSizeType = 'tiny' | 'small' | 'medium';

interface IconProps {
    path: IconProp | string | React.ReactNode;
    color?: string;
    backgroundColor?: string;
    size?: BoxSizeType;
};

export interface MenuProps {
    borderType: 'square' | 'bottom' | 'none';
    title: {
        text: string | React.ReactNode;
        fontWeight?: FontWeightType;
        size?: BoxSizeType;
        color?: string;
    };
    legend?: {
        text: string | React.ReactNode;
        fontWeight?: FontWeightType;
        size?: BoxSizeType;
        color?: string;
    };
    icon?: IconProps;
    iconCustom?: IconProps;
    onClick?: () => void;
    onClickOnleftChildren?: () => void;
    children?: React.ReactNode;
    leftChildren?: any;
    boxShadow?: boolean;
    borderTop?: boolean;
    htmlFor?: string;
}

const MenuItem: React.FC<MenuProps> = ({
    children,
    title,
    legend,
    icon,
    borderType,
    onClick,
    onClickOnleftChildren,
    boxShadow,
    borderTop,
    iconCustom,
    leftChildren,
    htmlFor,
}: MenuProps) => {
    const { palette, typography } = useTheme();
    const { pxToRem } = typography;
    const { css } = sx();

    const sizeObj = {
        tiny: {
            title: {
                fontSize: pxToRem(12),
            },
            legend: {
                fontSize: pxToRem(12),
            },
            icon: {
                height: pxToRem(34),
                width: pxToRem(34),
                fontSize: pxToRem(16),
                marginLeft: pxToRem(4),
            },
        },
        small: {
            title: {
                fontSize: pxToRem(14),
            },
            legend: {
                fontSize: pxToRem(14),
            },
            icon: {
                height: pxToRem(38),
                width: pxToRem(42),
                fontSize: pxToRem(18),
                marginLeft: pxToRem(0),
            },
        },
        medium: {
            title: {
                fontSize: pxToRem(14),
            },
            legend: {
                fontSize: pxToRem(16),
            },
            icon: {
                height: pxToRem(56),
                width: pxToRem(60),
                fontSize: pxToRem(22),
                marginLeft: pxToRem(0),
            },
        },
    };

    const colorsObj = {
        icon: {
            color: palette.dark,
        },
        title: {
            color: palette.dark,
        },
        legend: {
            color: palette.dark,
        },
    };

    const titleFwObj = {
        bold: {
            fontWeight: typography.fontWeightBold,
        },
        regular: {
            fontWeight: typography.fontWeightRegular,
        },
        medium: {
            fontWeight: typography.fontWeightMedium,
        },
    };

    const handleBorderType = {
        square: {
            boxShadow: boxShadow ?
                '0rem 0.1875rem 0.375rem #00000010' :
                'none',
            border: `0.0625rem solid ${palette.clouds}`,
            borderTop: borderTop ? `0.0625rem solid ${palette.clouds}` :
                'none',
            paddingLeft: 0,
        },
        bottom: {
            borderBottom: `0.0625rem solid ${palette.clouds}`,
        },
        none: {
            border: 'none',
        },
    };

    return (
        <Box
            component={'label'}
            htmlFor={htmlFor ?? ''}
            className='menu-item'
            sx={{
                cursor: 'pointer',
                width: '100%',
                backgroundColor: palette.common.white,
                border: 0,
            }}
            onClick={htmlFor ? onClick : () => {}}
        // type={buttonType ?? 'button'}
        >
            <Box
                sx={{
                    ...css.sxBox,
                    ...handleBorderType[borderType],
                }}>
                <Box sx={{ ...css.sxBoxContent }} onClick={onClick}>
                    <Box>
                        {leftChildren && (
                            <Box sx={{ width: '30px' }}>
                                {leftChildren}
                            </Box>
                        )}
                        {iconCustom?.path && (

                            <Box sx={{
                                height: sizeObj[(iconCustom?.size ?? 'small') ||
                                    (iconCustom?.size ?? 'small')].icon.height,
                                width: sizeObj[(iconCustom?.size ?? 'small') ||
                                    (iconCustom?.size ?? 'small')].icon.width,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: iconCustom?.backgroundColor ??
                                    palette.common.white,
                            }}>
                                <>
                                    {iconCustom?.path && iconCustom.path}
                                </>

                            </Box>
                        )}
                        {icon?.path && (

                            <Box sx={{
                                height: sizeObj[(icon?.size ?? 'small') ||
                                    (iconCustom?.size ?? 'small')].icon.height,
                                width: sizeObj[(icon?.size ?? 'small') ||
                                    (iconCustom?.size ?? 'small')].icon.width,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                backgroundColor: icon?.backgroundColor ??
                                    palette.common.white,
                                marginLeft: sizeObj[(icon?.size ?? 'small') ||
                                    (iconCustom?.size ?? 'small')].icon.marginLeft,
                            }}>
                                {[icon?.path].length > 0 &&
                                    <I
                                        fontSize={sizeObj[(icon?.size ?? 'small') ||
                                            (iconCustom?.size ?? 'small')].
                                            icon.fontSize}
                                        color={icon.color ?? colorsObj.icon.color}
                                        icon={icon.path as IconProp} />
                                }
                            </Box>
                        )}
                    </Box>
                    <Box sx={{
                        ...css.sxBoxTexts,
                        ml: pxToRem(10),
                        pt: pxToRem(12),
                        pb: pxToRem(12),
                        // paddingLeft: leftChildren ? typography.pxToRem(30) : 0,
                    }}>
                        <Typography
                            variant='h3'
                            sx={{
                                wordBreak: 'break-all',
                                color: title.color ?? colorsObj.title.color,
                                fontSize: sizeObj[title.size ?? 'small'].title.fontSize,
                                fontWeight: titleFwObj[title.fontWeight ?? 'regular']
                                    .fontWeight,
                            }}>
                            {title.text}
                        </Typography>
                        {legend && (
                            <Typography
                                variant='body1'
                                fontSize={sizeObj[legend?.size ?? 'small']
                                    .legend.fontSize}
                                color={legend?.color ?? colorsObj.legend.color}
                                fontWeight={titleFwObj[legend.
                                    fontWeight ?? 'regular'].
                                    fontWeight}>
                                {legend?.text}
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Box sx={{
                    ...css.sxBoxChildren,
                }}>
                    {children}
                </Box>
            </Box>
        </Box>
    );
};

export { MenuItem };
