export const paymentMethods = [
    {
        _id: '63da6540599c',
        title: 'Cartão de crédito',
        icon: ['fal', 'credit-card-front'],
        type: 'CARTAO',
    },
    {
        _id: '80a7d95a0bfe',
        title: 'Pix',
        icon: ['fab', 'pix'],
        type: 'PIX',
    },
    {
        _id: '0e3930b9bafd',
        title: 'Boleto',
        icon: ['far', 'barcode'],
        type: 'BOLETO',
    },
];
