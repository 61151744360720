import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { CollectionsSliderArrowsProps } from '@interfaces';

import { Main, Button } from './styles';


const CollectionsSliderArrows: React.FC<CollectionsSliderArrowsProps> = ({
    onClickPrev, onClickNext,
}: CollectionsSliderArrowsProps) => {
    return (
        <Main>
            <Button onClick={onClickPrev}>
                <I icon={['far', 'chevron-left']} style={{ height: 20 }} />
            </Button>
            <Button onClick={onClickNext}>
                <I icon={['far', 'chevron-right']} style={{ height: 20 }} />
            </Button>
        </Main>
    );
};

export { CollectionsSliderArrows };
