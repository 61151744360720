/* eslint-disable indent */
/* eslint-disable quotes */
/* eslint-disable max-len */
import React from 'react';
import { ButtonInterest, MessageBox, Product } from '@components';
import { Box, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';

// import { sx } from './sx';
import { useAds } from '../../../../hooks/ads';
import { generateRandomNumber } from '../../../../utils/randomNumber';
import { images } from '../../../../utils/images';
import { useNavigate } from 'react-router-dom';
import { SkeletonProduct } from './skeleton';

const HighlightedAds: React.FC = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const ads = useAds();
    const navigate = useNavigate();
    const { palette, typography } = useTheme();
    const [aleatory] = React.useState(() => generateRandomNumber());

    const loadHilighedAds = async () => {
        return await ads.highlighted.load(
            {
                limit: 4,
                aleatory: aleatory,
            });
    };

    React.useEffect(() => {
        loadHilighedAds();
    }, []);

    return (
        <Container maxWidth='lg'
            sx={{
                pb: matches ? '3.75rem' : '2.8125rem',
                pt: matches ? '3.75rem' : '2.8125rem',
            }}>
            {ads.highlighted.list.isLoaded &&
                <Stack>
                    <Typography
                        textAlign={'center'}
                        color={palette.dark}
                        fontSize={matches ? '2.375rem' : '1.5rem'}
                        fontWeight={typography.fontWeightRegular}
                        variant='h2'>
                        HIGHLIGHTS
                    </Typography>
                    <Typography
                        textAlign={'center'}
                        color={palette.dark}
                        fontSize={matches ? '1rem' : '0.875rem'}
                        fontWeight={typography.fontWeightRegular}
                        variant='body1'>
                        conheça alguns itens incríveis que acabaram de chegar
                    </Typography>
                </Stack>
            }
            {ads.highlighted.list.isLoaded ? (
                <Grid
                    sx={{
                        pb: 4,
                        pt: 4,
                    }}
                    container spacing={3}>
                    {ads.highlighted.list.data.map((product) => (
                        <Grid key={product.variacoes._id} item
                            lg={3} md={3} sm={6} xs={6}>
                            <Product
                                stockQuantity={product.variacoes.itens[0].quantidade_estoque}
                                favorite={product.isFavorite}
                                _id={product.variacoes._id}
                                _idAd={product._id}
                                mode='buy'
                                size={'fluid'}
                                align={'bottom'}
                                src={images.load(
                                    product.variacoes.fotos[0], 'server')}
                                title={`${product.titulo}`}
                                legend={product.usuario.minha_loja ?
                                    product.usuario.minha_loja.titulo :
                                    ''}
                                itemVariationId={product.variacoes.itens[0]._id}
                                price={String(product.variacoes.itens[0].valor_por)}
                                to={() => navigate('/produto/' + product._id + '?variantion=' + product.variacoes._id)} />
                        </Grid>
                    ))}
                </Grid>
            ) : <SkeletonProduct />}

            {ads.highlighted.list.data.length > 0 &&
                <Stack
                    sx={{ pt: 2 }}
                    alignItems={'center'}
                    justifyContent={'center'}>
                    <ButtonInterest
                        text={'VER MAIS'}
                        margin={'0'}
                        color={palette.common.white}
                        backgroundColor={palette.dark}
                        padding={'0.625rem 5.8125rem'}
                        onClick={() => navigate({
                            pathname: '/resultados',
                            search: `?limit=8&website=shopping&tag=em_destaque&aleatory=${aleatory}`,
                        })} />
                </Stack>
            }
            {ads.highlighted.list.isLoaded &&
                !(ads.highlighted.list.data.length > 0) && (
                    <Box sx={{ marginTop: '2.8125rem' }}>
                        <MessageBox
                            type='info'
                            message='Não há escolhas no momento'
                        />
                    </Box>
                )}
        </Container>
    );
};

export { HighlightedAds };
