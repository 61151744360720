import { Box } from '@mui/material';
import styled from 'styled-components';

export const ImageSection1 = styled.img`
    max-width: 36.875rem;
    height: 100%;
    width: auto;
    object-fit: cover;
    max-height: 590px;

    @media(max-width:600px) {
        width: 100%;
        max-width: auto;
    }
`;
export const ImageSection2 = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const DividerV = styled(Box)(({ theme }) => ({
    height: '1.875rem',
    width: '0.125rem',
    borderRight: `0.0625rem  dashed ${theme.palette.dark}`,
    paddingLeft: '1.75rem',
    marginBottom: '0.625rem',
}));

