import * as api from './calls';

export const checkout = {
    fakePurchase: api.fakePurchase,
    coupon: {
        apply: api.applyDiscountCoupon,
        remove: api.removeDiscountCoupon,
    },
    points: {
        apply: api.applyPoints,
        remove: api.removePoints,
    },
    wallet: {
        apply: api.applyWallet,
        remove: api.removeWallet,
    },
    purchase: api.purchase,
    order: {
        find: api.findOrder,
    },
};
