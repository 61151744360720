import React from 'react';
import { Box } from '@mui/material';
import { ArticleProps } from '@interfaces';

import { sx } from './sx';

const Article: React.FC<ArticleProps> = ({
    content,
}: ArticleProps) => {
    const { css } = sx();
    return (
        <Box
            sx={{
                ...css.sxBox,
                overflowWrap: 'break-word',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center' }}
            dangerouslySetInnerHTML={{ __html: content }}>
        </Box>
    );
};

export { Article };
