import { StepperProps } from '@interfaces/Hooks';
import React from 'react';

const useStepper = ({ steps }: StepperProps) => {
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const goToStep = (step: number) => {
        setActiveStep(step);
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted() ?
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed)) :
                activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };

    return {
        completed,
        activeStep,
        totalSteps,
        completedSteps,
        isLastStep,
        allStepsCompleted,
        handleNext,
        handleBack,
        handleStep,
        handleComplete,
        handleReset,
        goToStep,
    };
};

export { useStepper };
