import { client } from '../../../client';

const load = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/listorder/sales');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const generateTag = async ({ idOrder }: {idOrder: string}) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post(
            '/shipment/generate/complete/tag/order/'+idOrder);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const printTag = async ({ idOrder }: {idOrder: string}) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/shipment/print/tag/order/'+idOrder);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export { load, printTag, generateTag };
