import { styled } from '@mui/material';

export const Form = styled('form')`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const SearchFieldInput = styled('input')`
    width: 100%;
    padding: 12px 15px;
    font-size: 14px;
    font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
    border: 0;
    outline: 0;
`;
