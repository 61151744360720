/* eslint-disable indent */
/* eslint-disable max-len */
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { HeaderPage, MenuItem } from '@components';
import React, { ForwardRefExoticComponent, RefAttributes } from 'react';
import {
    Box,
    Checkbox,
    FormHelperText,
    Grid,
    Radio,
} from '@mui/material';
import {
    FilterContent,
    ButtonSelectedFilter,
    BoxActiveFiltersList,
    FilterFooter,
} from '../../../components/filters';
import { useAds } from '@hooks';
// import { formSchemaCheckbox, formSchemaRadio } from './yup';
import { SubmitHandler, useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
import { Item } from './styles';
import Divider from '@mui/material/Divider/Divider';

import {
    FilterComponentObjProps,
    FilterComponentProps,
    FormSizes,
    FormStaticProps,
} from '@interfaces';
import { NewAdDataProps } from '@interfaces/Contexts';

interface handleVisibilitySizesChildrenListProps {
    action: 'show' | 'hidden'
}

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

export const SizesFilter: ForwardRefExoticComponent<FilterComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            minHeightContent,
            filterInputsType,
            onClearAction,
            onSubmitAction,
        } = props;

        const { ad, createNewAd } = useAds();

        const [showSizesChildrenList,
            setShowSizesChildrenList] = React.useState<boolean>(false);

        const [sizeFilterSelected,
            setSizeFilterSelected] = React.useState<string>('');

        const [tempSizes, setTempSizes] = React.useState<NewAdDataProps[]>(
            createNewAd.data.size ?
                createNewAd.
                    data.size.length > 0 ?
                    createNewAd.data.size : [] : []);

        // const fieldTypes = {
        //     formSchema: {
        //         checkbox: formSchemaCheckbox,
        //         radio: formSchemaRadio,
        //     },
        // };

        const handleVisibilitySizesChildrenList = (
            { action }: handleVisibilitySizesChildrenListProps) => {
            const actions = {
                show: true,
                hidden: false,
            };

            setShowSizesChildrenList((prev) => prev = actions[action]);
        };

        const handleSelectSizeGroup = (_id: string) => {
            setSizeFilterSelected((prev) => prev = _id);
        };

        const {
            register,
            handleSubmit,
            getValues,
            setValue,
            reset,
            formState: { errors } } =
            useForm<FormSizes>({
                // resolver: yupResolver(fieldTypes.formSchema[filterInputsType]),
                mode: 'all',
            });

        const removeSizesFromHookForm = () => {
            const getFormValues = getValues('size');
            const filterFormValues = tempSizes.
                filter((item) => getFormValues[item._id as any]);
            setValue('size', filterFormValues);
        };

        const removeSelectedSizes = ({ _id, nome }: NewAdDataProps) => {
            const filter = tempSizes.filter((item) => item._id !== _id);
            // createNewAd.removeData({ type: 'size', data: { _id, nome } });
            setTempSizes((prev) => prev = [...filter]);
            // removeSizesFromHookForm();
        };

        const clearAdData = () => {
            setTempSizes((prev) => prev = []);
            reset({
                size: [],
            });
        };

        const handleSizes = ({ _id, nome, type }: FilterComponentObjProps) => {
            if (type === 'multiple') {
                const find = tempSizes.find((item) => item._id === _id);

                if (!find) {
                    setTempSizes((prev) => prev = [...prev, {
                        _id: _id,
                        nome: nome,
                    }]);
                    // setValue('size', tempSizes);
                } else {
                    removeSelectedSizes({ _id, nome });
                    removeSizesFromHookForm();
                }
            }

            if (type === 'single') {
                setTempSizes((prev) => prev = [{
                    _id: _id,
                    nome: nome,
                }]);
            }
        };

        const onSubmit: SubmitHandler<FormSizes> = async (data) => {
            createNewAd.insertData({
                type: 'size',
                data: {
                    size: tempSizes,
                },
            });
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        React.useEffect(() => {
            if (createNewAd.data.size &&
                !createNewAd.data.size.length) {
                clearAdData();
            }
        }, [createNewAd.data.size]);

        return (
            <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)}>
                <Box mt={-1} height='100%'>
                    <FilterContent minHeightContent={minHeightContent}>

                        <Box>
                            {tempSizes.length > 0 && (
                                <>
                                    <Box pl={1}>
                                        <BoxActiveFiltersList>
                                            {tempSizes.map(({ _id, nome }) => (
                                                <ButtonSelectedFilter
                                                    key={_id}
                                                    value={nome}
                                                    onClick={() => removeSelectedSizes(
                                                        {
                                                            _id,
                                                            nome,
                                                        })} />
                                            ))}
                                        </BoxActiveFiltersList>
                                    </Box>
                                    <Divider light />
                                </>
                            )}
                            {!showSizesChildrenList && (
                                <>
                                    {ad.sizes.data.map(({ _id, nome }) => (
                                        <MenuItem
                                            key={_id}
                                            onClick={() => {
                                                handleVisibilitySizesChildrenList(
                                                    { action: 'show' },
                                                ); handleSelectSizeGroup(_id);
                                            }}
                                            borderType={'bottom'}
                                            title={{
                                                text: nome,
                                                size: 'small',
                                                fontWeight: 'bold',
                                            }}>
                                            <Box p={2}>
                                                <I icon={['far', 'chevron-right']} />
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </>
                            )}

                            <Box pl={1} pr={2}>
                                <FormHelperText error>
                                    {errors.size && errors.size.message}
                                </FormHelperText>
                            </Box>
                            {showSizesChildrenList && (
                                <Box>
                                    {showSizesChildrenList && (
                                        <Box>
                                            {ad.sizes.data.filter((size) => (size._id === sizeFilterSelected)).
                                                map(({ nome }) => (
                                                    <HeaderPage
                                                        key={`key-${nome}`}
                                                        titleAlign={'default'}
                                                        icon={['far', 'chevron-left']}
                                                        title={nome}
                                                        size='small'
                                                        onClick={() => handleVisibilitySizesChildrenList(
                                                            { action: 'hidden' },
                                                        )}
                                                    />
                                                ))}

                                            <Box p={2} pt={1}>

                                                <Grid container spacing={2}>
                                                    {ad.sizes.data.filter((size) => (size._id === sizeFilterSelected)).map(({ tamanhos, nome }) => (
                                                        tamanhos.map(({ _id, nome }) => (
                                                            <React.Fragment key={_id}>
                                                                <Grid key={_id} item xs={2}>
                                                                    <Item className={!!tempSizes
                                                                        .find((item) =>
                                                                            item._id === _id) ?
                                                                        'active' :
                                                                        ''}>
                                                                        <label htmlFor={_id}>
                                                                            {nome}
                                                                            {filterInputsType === 'radio' && (
                                                                                <Radio
                                                                                    hidden
                                                                                    id={_id}
                                                                                    size='small'
                                                                                    value={_id}
                                                                                    {...register('size', {
                                                                                        required: true,
                                                                                    })}
                                                                                    checked={!!tempSizes
                                                                                        .find((item) =>
                                                                                            item._id === _id)}
                                                                                    onChange={() => handleSizes({
                                                                                        _id: _id,
                                                                                        nome: nome,
                                                                                        type: 'single',
                                                                                    })}
                                                                                />
                                                                            )}
                                                                            {filterInputsType === 'checkbox' && (
                                                                                <Checkbox
                                                                                    id={_id}
                                                                                    size='small'
                                                                                    value={_id}
                                                                                    {...register('size', {
                                                                                        required: false,
                                                                                    })}
                                                                                    checked={!!tempSizes
                                                                                        .find((item) => item._id === _id)}
                                                                                    onChange={() => handleSizes({
                                                                                        _id: _id,
                                                                                        nome: nome,
                                                                                        type: 'multiple',
                                                                                    })}
                                                                                />
                                                                            )}
                                                                        </label>
                                                                    </Item>
                                                                </Grid>
                                                            </React.Fragment>
                                                        ))
                                                    ))}
                                                </Grid>
                                            </Box>
                                        </Box>)}
                                </Box>
                            )}
                        </Box>
                    </FilterContent>
                    {showSizesChildrenList && (
                        ad.sizes.data.length > 0 && (
                            <FilterFooter
                                onClearAction={onClearAction ? onClearAction as () => void : clearAdData}
                                onSubmitAction={onSubmitAction ? onSubmitAction as () => void : () => { }}
                                disabledOnSubmit={!(!!tempSizes.length)}
                            />
                        )
                    )}
                </Box>
            </Box>
        );
    });
