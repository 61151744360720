import { StoreFormProps } from '@interfaces';
import { store } from '../../api/services/store';

const useStore = () => {
    const create = async ({ ...props }: StoreFormProps) => {
        const response = await store.create({
            ...props,
        });
        return response;
    };

    return { store: {
        create: create,
    } };
};

export { useStore };
