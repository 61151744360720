import { CartAddProps } from '@interfaces/Cart';
// import { CartRemoveProps } from '@types';
import { client } from '../../../client';

const add = async ({ ...cartProps }: CartAddProps) => {
    const { instance, handleErrors, handleResponse } = client();
    const props = cartProps;
    try {
        if (!props.idAd) delete props.idAd;
        if (!props.idVariation) delete props.idVariation;
        if (!props.variationItemId) delete props.variationItemId;
        if (!props.replaceItem) delete props.replaceItem;

        const response = await instance.post('/cart?website=shopping', {
            ...cartProps,
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const load = async () => {
    const { instance, handleErrors, handleResponse } = client();
    try {
        const response = await instance.get('/cart?website=shopping');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const createSession = async () => {
    const { instance, handleErrors, handleResponse } = client();
    try {
        const response = await instance.post('/auth/session');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const calculateShipping = async () => {
    const { instance, handleErrors, handleResponse } = client();
    try {
        const response = await instance.get('/shipment/calculate');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const remove = async ({ ...cartProps }: CartAddProps) => {
    const { instance, handleErrors, handleResponse } = client();
    const props = cartProps;

    try {
        if (!props.idAd) delete props.idAd;
        if (!props.idVariation) delete props.idVariation;
        if (!props.variationItemId) delete props.variationItemId;

        const response = await instance.delete('/cart?website=shopping',
            { data: cartProps });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};


export { add, remove, load, createSession, calculateShipping };
