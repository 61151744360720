/* eslint-disable camelcase */
/* eslint-disable max-len */
import React from 'react';
import {
    Box,
    Container,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';

import {
    // AvatarStackGroup,
    ButtonInterest,
    FixedMenu,
    Image,
    NewsLetter,
} from '@components';

import {
    BlogSlider,
    ContentSlider,
    OurSelection,
    HighlightedAds,
    QuickLink,
} from './components';

import { sx } from './sx';

import { banners } from '../../api/services/banners';
import { ScrollToTop, images } from '@utils';
import { useOthers } from '../../hooks/others';
import { SkeletonQuicklink } from './skeleton';
import { useNavigate } from 'react-router-dom';

const Home: React.FC = () => {
    const { quicklink } = useOthers();
    const { palette, typography } = useTheme();
    const { css } = sx();
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();

    const [cover, setCover] = React.useState();

    const loadCover = async () => {
        const response = await banners.cover();

        if (!response?.error) {
            setCover(response?.response.payload.anexo);
        }
    };

    React.useEffect(() => {
        loadCover();
        quicklink.load();
        setTimeout(() => window.scrollTo(0, 0), 200);
    }, []);

    return (
        <>
            <ScrollToTop />
            <Container className={'no-padding'} maxWidth='lg'>
                <Box sx={css.hero.sxBox}>
                    {cover &&
                        <Box sx={css.hero.sxBoxImage}>
                            <Image src={images.load(cover, 'server')} maxHeight={matches ? 462 : 194} alt="" />
                        </Box>
                    }
                    <Box sx={css.hero.sxBoxContent}>
                        <Typography
                            variant='h2'
                            fontSize={matches ? '2.375rem' : '1.5rem'}
                            textTransform={'uppercase'}
                            fontWeight={typography.fontWeightRegular}
                            textAlign={'center'}>
                            Mulheres que ousam fazer diferente.
                        </Typography>
                        <Typography
                            variant='body1'
                            fontWeight={typography.fontWeightRegular}
                            marginTop={'0.3125rem'}
                            textTransform={'lowercase'}
                            marginBottom={matches ? '1.875rem' : '0.9375rem'}
                            textAlign={'center'}>
                            curadoria especial de produtos e conteúdos por e para mulheres despertas, autênticas
                            e revolucionárias, {matches && <br />} que enxergam muito além da última tendência e que buscam algo diferente
                            do que existe por aí.
                        </Typography>

                        <ButtonInterest
                            text={'SAIBA MAIS'}
                            margin={'0.9375rem 0'}
                            color={'white'}
                            backgroundColor={'black'}
                            padding={'0.625rem 5.3125rem'}
                            onClick={() => navigate('/quem-somos')} />

                    </Box>
                </Box>
                {!(quicklink.list.isLoaded && quicklink.list.data.length === 0) &&
                    <Stack
                        direction={'row'}
                        justifyContent={matches ? 'center' : 'flex-start'}
                        spacing={2}
                        width={'100%'}
                        paddingY={4}
                        paddingX={2}
                        overflow={matches ? 'hidden' : 'scroll'}
                        sx={{ backgroundColor: palette.common.white }}>
                        {quicklink.list.isLoaded ?
                            quicklink.list.data.map((quicklink) => {
                                return (
                                    <QuickLink data={quicklink} />
                                );
                            }) : <SkeletonQuicklink />}
                    </Stack>
                }
            </Container>
            {/* <BannerHome
                fastLinks={fastLinks}
                legend={'Check out our F/W 2022 collection!'}
                title={'Despertas. Autênticas. Revolucionárias.'} /> */}
            {/* {matches && (<CategoriesMenu />)} */}
            <BlogSlider />
            <ContentSlider />
            <OurSelection />
            <HighlightedAds />
            {/* {!matches &&
                <CollectionsSlider
                    title={'Navegue por coleções'}
                    data={collections} />
            } */}
            {/* <PartnersList /> */}
            <NewsLetter location='home' />
            {!matches &&
                <FixedMenu />
            }
        </>
    );
};

export { Home };
