/* eslint-disable max-len */
/* eslint-disable camelcase */
import {
    BoxContent,
    MessageBox,
    Price,
    Product,
    SmallTableColumn,
} from '@components';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Container,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { sx } from './sx';
import React from 'react';
import { formatNumberToPriceBRL, images } from '@utils';
import { useVariations } from '../../../../../hooks/ads/vatiations';
import { useNavigate } from 'react-router-dom';

const ProductVariations = ({ productID }: { productID: string }) => {
    const navigate = useNavigate();
    const { css } = sx();
    const { typography, palette } = useTheme();
    const rm = (px: number) => typography.pxToRem(px);

    const { variationsAD } = useVariations();

    const [adStatus, setAdStatus] = React.useState({
        isLoaded: true,
    });

    const excerpt = (text: string, count: number) => {
        const split = text.split('');
        const splice = split.splice(0, count).join('') + '...';
        return splice;
    };

    const loadVariations = async () => {
        setAdStatus((prev) => prev = { isLoaded: true });
        // const response = await ads.loadVariations(params.id as string);
        const response = await variationsAD.find.byAdID({ id: productID });

        if (!response?.error) {
            setAdStatus((prev) => prev = { isLoaded: false });
        }
    };

    React.useEffect(() => {
        if (productID) {
            loadVariations();
        }
    }, []);

    return (
        <>
            {adStatus.isLoaded && (
                <Box sx={css.loadingBox}>
                    <CircularProgress sx={{ color: palette.dark }} size={20} />
                    <Typography
                        variant="h2"
                        component='h2'
                        fontSize={16}
                        fontWeight={typography.fontWeightMedium}
                        color={palette.dark}
                        pt={typography.pxToRem(8)}>
                        Aguarde..
                    </Typography>
                </Box>
            )}
            {!adStatus.isLoaded && (
                <Box sx={css.content.wrapper}>
                    {variationsAD.list.byAdID.isLoaded ?
                        variationsAD.list.byAdID.data.length > 0 &&
                        <Container maxWidth='sm'>
                            {variationsAD.list.byAdID.data.map((variation) => (
                                <BoxContent
                                    key={variation._id}
                                    paddingLeft
                                    borderBottom>
                                    <Product
                                        size={'medium'}
                                        align={'right'}
                                        contentAlign='center'
                                        src={images.load(variation.imagem_cor, 'server')}
                                        title={variation.nome_cor} />
                                    {variation.itens.map(({
                                        _id,
                                        tamanho,
                                        nome,
                                        quantidade_estoque,
                                        sku,
                                        valor_por }) => {
                                        return (
                                            <Stack
                                                key={_id}
                                                direction='row'
                                                alignItems='baseline'
                                                spacing={3}
                                                mb={rm(10)}>
                                                {tamanho && (
                                                    <SmallTableColumn
                                                        title='Tamanho'
                                                        value={<Chip
                                                            label={tamanho ? tamanho.nome : ''}
                                                            size='small'
                                                            sx={css.content.chip} />} />
                                                )}
                                                {nome && (
                                                    <SmallTableColumn
                                                        title='Tamanho'
                                                        value={<Chip
                                                            label={nome ? nome : ''}
                                                            size='small'
                                                            sx={css.content.chip} />} />
                                                )}
                                                <SmallTableColumn
                                                    title='Preço'
                                                    value={<Price
                                                        size='small'
                                                        value={
                                                            String(formatNumberToPriceBRL(valor_por))}
                                                    />} />
                                                <SmallTableColumn
                                                    title='Quantidade'
                                                    value={quantidade_estoque} />
                                                <SmallTableColumn
                                                    title='SKU'
                                                    value={<Typography
                                                        variant="body1"
                                                        fontSize={rm(14)}
                                                        fontWeight={500}
                                                        color={palette.dark}
                                                        component='p'>
                                                        {excerpt(sku, 5)}
                                                    </Typography>} />
                                            </Stack>
                                        );
                                    })}
                                </BoxContent>
                            ))}
                            <Box mb={rm(15)} />
                            <Box
                                display='flex'
                                justifyContent='center'
                                mt={2}>
                                <Box
                                    maxWidth={320}
                                    width='100%'
                                    justifyContent='center'>
                                    <Button
                                        fullWidth
                                        variant="contained"
                                        size="large"
                                        type='submit'
                                        className='MuiButton secondary'
                                        onClick={() => navigate('/produtos/editar/variantes/' + productID)}
                                    >
                                        Editar Variações
                                    </Button>
                                </Box>
                            </Box>
                        </Container> :
                        null}
                    {variationsAD.list.byAdID.isLoaded ? (
                        !(variationsAD.list.byAdID.data.length) && (
                            <Box pt={typography.pxToRem(30)}>
                                <MessageBox
                                    type='info'
                                    message='Não existem variações para este produto.'
                                />
                            </Box>)
                    ) : null}
                </Box>
            )}
        </>
    );
};

export { ProductVariations };
