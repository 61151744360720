import {
    useMediaQuery,
    useTheme,
} from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { typography, palette } = useTheme();
    const css = {
        sxContainer: {
            paddingTop: matches ? '5rem' : '0.625rem',
            paddingBottom: matches ? '5rem' : '2.5rem',
        },
        sxBoxAccess: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '1.25rem',
        },
        sxAccessText: {
            fontWeight: typography.fontWeightBold,
            fontSize: '1.125rem',
            paddingBottom: '0.625rem',
        },
        sxTypeText: {
            fontWeight: typography.fontWeightMedium,
            fontSize: '0.875rem',
            paddingBottom: '0.625rem',
        },
        sxButtonLarge: {
            backgroundColor: palette.dark,
            color: palette.common.white,
        },
        sxResendLink: {
            'cursor': 'pointer',
            'textDecoration': 'none',
            'fontSize': '0.9375rem',
            'fontWeight': 700,
            'color': palette.dark,
            'padding': '1.5625rem 0',
            ':hover': {
                textDecoration: 'underline',
            },
        },
    };

    return { css };
};
