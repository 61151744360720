import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette } = useTheme();

    const css = {
        sxIconSearch: {
            fontSize: '1.125rem',
            color: palette.dark,
        },
        sxBoxInputSearch: {
            'display': 'flex',
            'alignItems': 'center',
            'border': `0.0625rem solid ${palette.clouds}`,
            'borderRadius': '0.3125rem',
            'width': '100%',
            'margin': '0.0125rem 0',
            'marginLeft': '0.75rem',
            'boxShadow': '0px 0.375rem 0.4375rem #0000001A',
        },
        sxBoxInput: {
            width: '100%',
            backgroundColor: palette.common.white,
        },
    };
    return {
        ...css,
    };
};
