export const customSizePts = {
    big: 14,
    medium: 12,
    small: 10,
    tiny: 8,
};

export const customMarginSize = {
    big: '-0.3125rem',
    medium: '-0.1875rem',
    small: '-0.125rem',
    tiny: '-0.125rem',
};
