import { ActionFooterType } from '@types';

export const FooterData = [
    {
        section: 'Sobre',
        items: [
            {
                title: 'Quem somos',
                link: '/quem-somos',
            },
            {
                title: 'manifesto',
                action: 'manifest' as ActionFooterType,
            },
            {
                title: 'Código de Ética',
                link: '/codigo-de-etica',
            },
            {
                title: 'Termos de Uso',
                link: '/termos-de-uso',
            },
            {
                title: 'Política de privacidade',
                link: '/politica-de-privacidade',
            },
        ],
    },
    {
        section: 'Ajuda',
        items: [
            {
                title: 'Perguntas frequentes',
                link: '/ajuda',
            },
            {
                title: 'Fale conosco',
                link: '/fale-conosco',
            },
        ],
    },
];
