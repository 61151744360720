/* eslint-disable max-len */
import React from 'react';
import Slider from 'react-slick';
import {
    Box,
    Container,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import {
    ButtonInterest,
    CollectionsSliderArrows,
    ContentItem,
    MessageBox,
} from '@components';

import {
    ContentProps,
    ListContentsProps,
} from '@interfaces';

import { contents } from '../../../../api/services/contents';
import { settings } from './slider-settings';
import { sx } from './sx';
import { useNavigate } from 'react-router-dom';
import { images } from '@utils';

const ContentSlider: React.FC = () => {
    const navigate = useNavigate();
    const slider = React.useRef<any>(null);
    const { palette, typography } = useTheme();
    const matches = useMediaQuery('(min-width:900px)');
    const { css } = sx();

    const { discover } = contents;
    const [listContents, setListContents] = React.useState<ListContentsProps>({
        isLoaded: true,
        contents: [] as ContentProps[],
    });

    const loadContents = async () => {
        setListContents({
            isLoaded: false,
            contents: listContents.contents,
        });
        const response = await discover({
            type: '',
            idCategory: '',
            limit: 4,
        });

        if (!response?.error && response?.response.payload) {
            setListContents({
                isLoaded: true,
                contents: response.response.payload,
            });
        } else {
            setListContents({
                isLoaded: true,
                contents: [] as ContentProps[],
            });
        }
    };

    React.useEffect(() => {
        loadContents();
    }, []);

    return (
        <Container sx={{ ...css.sxContainer }}>
            <Grid container>
                <Grid item
                    lg={4} md={4} sm={12} xs={12}>
                    <Box sx={{ ...css.sxBoxInfo }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}>
                            <Typography
                                textAlign={matches ? 'center' : 'start'}
                                color={palette.dark}
                                fontSize={matches ? '2rem' : '1.25rem'}
                                fontWeight={typography.fontWeightRegular}
                                variant='h2'>
                                CONTEÚDOS DISPONÍVEIS
                            </Typography>
                            <Typography
                                textAlign={matches ? 'center' : 'start'}
                                color={palette.dark}
                                fontSize={matches ? '1rem' : '0.875rem'}
                                fontWeight={typography.fontWeightRegular}
                                variant='body1'>
                                navegue pelos nossos conteúdos {matches && <br />}
                                exclusivos
                            </Typography>
                        </Box>
                        {matches ?
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <ButtonInterest
                                    text={'ACESSO AO CONTEÚDO'}
                                    margin={'1.875rem 0'}
                                    color={palette.common.white}
                                    backgroundColor={palette.dark}
                                    padding={'0.625rem 2.5625rem'}
                                    onClick={() => navigate('/membros/landing-page')} />
                            </Box> :
                            <IconButton
                                onClick={() => navigate('membros/landing-page')}
                                sx={{
                                    ...css.sxIconButton,
                                }}>
                                <I icon={['fas', 'chevron-right']} />
                            </IconButton>

                        }
                    </Box>
                </Grid>
                <Grid item
                    lg={8} md={8} sm={12} xs={12}>
                    <Box sx={{
                        width: '100%',
                    }}>
                        {listContents.isLoaded && listContents.contents.length > 0 ?
                            <Slider
                                ref={slider} {...settings(listContents.contents.length)}
                                className='contents-home-slider'>
                                {listContents.contents.length > 0 &&
                                    listContents.contents.map((content) => (
                                        <ContentItem
                                            slider
                                            key={content._id}
                                            link={'/membros/conteudo/' + content._id}
                                            srcImage={images.load(content.capa, 'server')}
                                            title={content.titulo}
                                            legend={content.legenda}
                                            tag={content.categoria ?
                                                content.categoria.nome :
                                                ''} />
                                    ))}
                            </Slider> :
                            <MessageBox
                                message={'Não existem conteúdos disponíveis no momento.'}
                                type={'info'} />
                        }
                        {listContents.isLoaded && listContents.contents.length > 1 &&
                            <CollectionsSliderArrows
                                onClickPrev={() => slider.current.slickPrev()}
                                onClickNext={() => slider.current.slickNext()} />
                        }
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export { ContentSlider };
