import { IconProp } from '@fortawesome/fontawesome-svg-core';

export const homeBaseTabs = [
    {
        label: 'Todos os Conteúdos',
        path: '/membros/descobrir',
        icon: ['fal', 'compass'] as IconProp,
    },
    {
        label: 'Meus Conteúdos',
        path: '/membros/meus-conteudos',
        icon: ['fal', 'square-list'] as IconProp,
    },
];
