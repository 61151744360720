/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
    Box,
    ClickAwayListener,
    Fade,
    IconButton,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Stack,
    Typography,
    useTheme,
    Button,
} from '@mui/material';
import { SmallerDashedListProps } from '@interfaces';
import { LightTooltip } from '@components';
import { sx } from './sx';


const SmallerDashedList = ({
    title,
    titleBold,
    subtitle,
    popper,
    divider,
    listItemId,
    secondaryAction,
    size,
    disablePaddingLR,
    disable,
    backgroundColor,
    onClick,
}: SmallerDashedListProps) => {
    const { typography, palette } = useTheme();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const { css } = sx();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen((prev) => prev = !prev);
    };

    const closePopper = () => {
        setAnchorEl(null);
        setOpen((prev) => prev = false);
    };

    const sizeObj = {
        medium: {
            fontSize: 14,
        },
        small: {
            fontSize: 12,
        },
    };

    return (
        <>
            <ListItem
                divider={divider}
                sx={{
                    ...{
                        cursor: 'default',
                        borderStyle: 'dashed',
                        pl: 0,
                        backgroundColor: !backgroundColor ?
                            palette.common.white :
                            backgroundColor,
                    },
                    'opacity': disable ? 0.5 : 1,
                    '&:hover .MuiListItemButton-root': {
                        cursor: 'default',
                        backgroundColor: palette.common.white,
                    },
                }}
                secondaryAction={
                    <Box mr={disablePaddingLR ? -2 : 0} mt={0.5}>
                        {secondaryAction}
                    </Box>}
                disablePadding
            >
                <ListItemButton sx={{ pl: disablePaddingLR ? 0 : 2 }}>
                    <ListItemText
                        id={listItemId}
                        primary={
                            <>
                                <Stack direction='row' alignItems='center'>
                                    <Box sx={onClick ? css.button : {}}
                                        component={onClick ? 'button' : 'div'}
                                        onClick={onClick ? onClick : () => { }}>
                                        <Typography
                                            component='p'
                                            fontWeight={titleBold ? 700 : 600}
                                            color={palette.dark}
                                            fontSize={size ?
                                                sizeObj[size as 'medium' | 'small'].fontSize :
                                                sizeObj.small.fontSize
                                            }
                                        >
                                            {title}
                                            <Popper sx={{
                                                position: 'relative',
                                            }} open={open} anchorEl={anchorEl} placement='top' transition>
                                                {({ TransitionProps }) => (
                                                    <Fade {...TransitionProps} timeout={350}>
                                                        <Paper>
                                                            <Typography
                                                                variant='subtitle2'
                                                                sx={{ p: 1, pr: 3 }}
                                                            >
                                                                {popper}
                                                            </Typography>
                                                            <IconButton
                                                                onClick={closePopper}
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: 0,
                                                                    right: 0,
                                                                }}>
                                                                <I icon={['fas', 'xmark']}
                                                                    fontSize={12}
                                                                    color={palette.dark}
                                                                />
                                                            </IconButton>
                                                        </Paper>
                                                    </Fade>
                                                )}
                                            </Popper>
                                        </Typography>
                                    </Box>
                                    {popper && (
                                        <IconButton onClick={handleClick}>
                                            <I icon={['far', 'circle-question']} fontSize={12} />
                                        </IconButton>
                                    )}
                                </Stack>
                                <Typography
                                    component='p'
                                    fontWeight={typography.fontWeightBold}
                                    fontSize={size ?
                                        sizeObj[size as 'medium' | 'small'].fontSize :
                                        sizeObj.small.fontSize
                                    }
                                >
                                    {subtitle}
                                </Typography>
                            </>
                        }
                        sx={{
                            '& span': {
                                fontSize: 12,
                                fontWeight: typography.fontWeightBold,
                            },
                        }}
                    />
                </ListItemButton>
            </ListItem>
        </>
    );
};

export { SmallerDashedList };
