import { useMediaQuery, useTheme } from '@mui/material';

const sx = () => {
    const { typography, palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const css = {
        wrapper: {
            backgroundColor: palette.clouds,
            padding: typography.pxToRem(15),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
        },
        box: {
            content: {
                backgroundColor: palette.common.white,
                height: typography.pxToRem(67),
                width: typography.pxToRem(67),
                borderRadius: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            },
            title: {
                fontSize: typography.pxToRem(15),
                fontWeight: typography.fontWeightSemiBold,
                color: palette.dark,
                pt: typography.pxToRem(10),
                mb: typography.pxToRem(10),
            },
            loading: {
                height: matches ? '31.25rem' : '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
        },
    };

    return { css };
};

export { sx };
