export const sx = () => {
    const css = {
        sxBox: {
            width: '100%',
            height: '100%',
            maxHeight: '28.875rem',
            minHeight: '13.375rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };
    return {
        ...css,
    };
};
