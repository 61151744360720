import { useTheme } from '@mui/material';

const sx = () => {
    const { typography, palette } = useTheme();
    const rm = (px: number) => typography.pxToRem(px);

    const css = {
        loadingBox: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '320px',
        },
        content: {
            wrapper: {
                pb: typography.pxToRem(30),
            },
            image: {
                width: rm(80),
                height: rm(80),
                borderRadius: rm(3),
                overflow: 'hidden',
                border: '.0625rem solid ' + palette.clouds,
            },
            chip: {
                backgroundColor: palette.common.black,
                color: palette.common.white,
                textTransform: 'uppercase',
            },
        },
    };
    return { css };
};

export { sx };
