/* eslint-disable max-len */
/* eslint-disable quotes */
import { GetInTouchProps } from '@interfaces';
import { CategorizationProps, GenerateShortLinkProps, PostEmailListProps } from '@interfaces/Contexts';
import axios from 'axios';
import { client } from '../../client';

const categorization = async ({ _id, direction }: CategorizationProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/categorization' +
            `${_id ? '?category=' + _id : ''}` +
            `${direction ? '&direction=' + direction : ''}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadQuickLinks = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/quicklink?website=shopping');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadShowCases = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/showcase?website=shopping');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const listStates = async () => {
    const { handleErrors, handleResponse } = client();

    try {
        const response = await axios.
            get('https://servicodados.ibge.gov.br/api/v1/localidades/estados', {
                headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Content-Type': 'application/json',
                },
            });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const listCities = async (stateId: number) => {
    const { handleErrors, handleResponse } = client();

    try {
        const response = await axios.
            get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/' + stateId + '/municipios');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const postEmailList = async ({ name, email }: PostEmailListProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/maillist', {
            name,
            email,
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const generateShortLink = async ({ type, idAd, urlInfos }: GenerateShortLinkProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/short/' + type, {
            ...type === 'content' && { idContent: idAd },
            ...type === 'ad' && { idAd: idAd },
            urlInfos,
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const getInTouch = async ({ ...props }: GetInTouchProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/talktous/', {
            ...props,
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export {
    categorization,
    loadQuickLinks,
    loadShowCases,
    listStates,
    listCities,
    postEmailList,
    generateShortLink,
    getInTouch,
};
