import { styled, Switch } from '@mui/material';

export const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        'color': theme.palette.pomegarnate,
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: theme.palette.pomegarnate,
    },
}));

export const Flag = styled('img')`
    height: 1rem;
    width: 100%;
    object-fit: contain;
`;
