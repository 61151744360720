import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { typography } = useTheme();

    const css= {
        sxContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: matches ? '90px' : '3.75rem',
            paddingBottom: matches ? '90px' : '3.75rem',
        },
        sx404: {
            fontWeight: typography.fontWeightSemiBold,
            fontSize: matches ? '4.75rem' : '2.875rem',
        },
        sxTitle: {
            'fontSize': matches ? '1.125rem' : '0.875rem',
            'fontWeight': typography.fontWeightSemiBold,
            'textAlign': 'center',
            '& > span': {
                fontSize: matches ? '1.625rem' : '1.125rem',
            },
        },
        sxSubtitle: {
            textAlign: 'center',
        },
    };

    return { css };
};
