import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Box } from '@mui/material';
import { HeaderPage, MenuItem, Navigation, Page } from '@components';

import { BackItem } from './components/back-item';
import { sx } from './sx';
import { useMainMenu } from '@hooks';
import { mainMenu } from '@static/main-menu';

const Menu: React.FC = () => {
    const { sxBoxHistory, sxBox } = sx();
    const navigate = useNavigate();
    const { handleFirstLvl, showFirstLvL, handleLeaveMainMenu } = useMainMenu();
    const [navigation, setNavigation] =
        React.useState<'Categorias'| 'Marcas'>('Categorias');

    const handleMenuVisibility = (type: 'show' | 'hidden', titleNavigation: string) => {
        const obj = {
            show: true,
            hidden: false,
        };
        handleFirstLvl({
            visibility: obj[type],
        });
        setNavigation(titleNavigation as any);
    };


    const handleNavigation = {
        Categorias: <Navigation.Categories />,
        Marcas: <Navigation.Brands />,
    };

    return (
        <Page.Wrapper border position='fixed'>
            <Page.Content paddingBottom>
                <Box sx={{ ...sxBox }}>
                    <HeaderPage
                        onClick={() => {
                            handleLeaveMainMenu();
                            navigate(-1);
                        }}
                        titleAlign={'center'}
                        title={'Menu'}
                        icon={['far', 'chevron-left']} />
                    {showFirstLvL.visibility && (
                        <>
                            <Box sx={{ ...sxBoxHistory }}>
                                <BackItem
                                    onClick={() => handleMenuVisibility('hidden', '')}
                                    title={'Menu'} />
                            </Box>
                            {handleNavigation[navigation] || <></>}
                        </>
                    )}
                    {!showFirstLvL.visibility &&
                        mainMenu.map(({ id, link, title }) => (
                            <MenuItem
                                key={id}
                                onClick={() => link ?
                                    navigate(link) : handleMenuVisibility('show', title)}
                                borderType={'bottom'}
                                title={{
                                    text: title,
                                    size: 'medium',
                                }} htmlFor={title}>
                                <Box pl={2} pr={2}>
                                    <I
                                        style={{ fontSize: '1rem' }}
                                        icon={['far', 'chevron-right']}
                                    />
                                </Box>
                            </MenuItem>
                        ))}
                </Box>
            </Page.Content>
        </Page.Wrapper>
    );
};

export { Menu };
