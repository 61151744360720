import { MenuItem } from '@components';
import { Box, Checkbox, useMediaQuery } from '@mui/material';
import {
    FilterContent,
    FilterFooter,
    ButtonSelectedFilter,
    BoxActiveFiltersList,
} from '../../../components/filters';

const freightList = [
    { id: '0135d51e8624', name: 'Correios', value: 'correios' },
    { id: '216b9c9bb88a', name: 'Entrega em mãos', value: 'entrega-maos' },
];

const FreightFilter = () => {
    const matches = useMediaQuery('(min-width:600px)');
    return (
        <Box height='100%' component='form'>
            <FilterContent minHeightContent={114}>

                <BoxActiveFiltersList>
                    <ButtonSelectedFilter value='Correios' />
                </BoxActiveFiltersList>

                {freightList.map(({ id, name, value }) => (
                    <MenuItem
                        key={id}
                        onClick={() => { }}
                        borderType={'bottom'}
                        title={{
                            text: name,
                            size: !matches ? 'small' : 'tiny',
                        }}
                        htmlFor={id}>
                        <Checkbox
                            id={id}
                            size='small'
                            value={value}
                        />
                    </MenuItem>
                ))}
            </FilterContent>
            <FilterFooter
                onClearAction={() => { }}
                onSubmitAction={() => { }}
            />
        </Box>

    );
};

export { FreightFilter };
