import React from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';

import { sx } from './sx';

interface SliderItemProps {
    src: string;
    alt?: string;
};


const SliderItem: React.FC<SliderItemProps> = ({
    src, alt,
}: SliderItemProps) => {
    const { css } = sx();

    return (
        <Box sx={{ ...css.sxBox }}>
            <ImgSlider
                alt={alt}
                src={src} />
        </Box>
    );
};

const ImgSlider = styled.img`
    width: 100%;
    height: 100%;
`;

export { SliderItem };
