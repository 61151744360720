/* eslint-disable max-len */
import {
    Filter,
    HeaderPage,
    MessageBox,
    Page,
    Price,
    SmallerDashedList,
    TabPanel,
    WalletButtonAction,
} from '@components';
import {
    Box,
    Container,
    List,
    Stack,
    Tab,
    Tabs,
    Typography,
    useTheme,
} from '@mui/material';
import React from 'react';
import { sx } from './sx';
import { filters } from '@filters';
import { useNavigate } from 'react-router-dom';
import { useWallet } from '../../../../hooks/wallet';

function a11yProps(index: number) {
    return {
        'id': 'simple-tab-' + index,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Money = () => {
    const { wallet } = useWallet();
    const balances = wallet.iugu.balances;
    // const history = wallet.iugu.history;

    const { typography, palette } = useTheme();
    const { pxToRem } = typography;
    const { css } = sx();
    const navigate = useNavigate();

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const removeCurrencySymbol = (price: string = '0.00') => {
        const splitBySymbol = price.split('R$');
        const [total] = splitBySymbol.join('').split('BRL');
        return total.toString().trim();
    };

    // const loadHistory = async () => {
    //     const responseMovements = await history.load.movements();
    //     console.log('RESPONSE MOVEMENTS: ', responseMovements);

    //     const responseReceivables = await history.load.receivables();
    //     console.log('RESPONSE RECEIVABLES: ', responseReceivables);

    //     const responseBankTransfers = await history.load.bankTransfers();
    //     console.log('RESPONSE BANK_TRANSFERS: ', responseBankTransfers);
    // };

    React.useEffect(() => {
        // loadHistory();
        if (!balances.list.isLoaded) {
            balances.load();
        }
    }, []);

    return (
        <Page.Wrapper border position='relative'>
            <HeaderPage
                titleAlign={'default'}
                icon={['far', 'wallet']}
                title='Minha Carteira'
                legend='Reais' />

            <Container maxWidth='lg'>
                <Box pt={pxToRem(30)} pb={pxToRem(20)}>
                    <Typography
                        variant='h2'
                        component='h2'
                        fontSize={14}
                        fontWeight={typography.fontWeightBold}
                        mb={pxToRem(3)}
                    >
                        Saldo disponível
                    </Typography>
                    {balances.list.isLoaded ? (
                        <Price size='big' value={removeCurrencySymbol(balances.list.data.balance_available_for_withdraw)} />
                    ) : '...'}
                </Box>

                <Stack direction='row'
                    sx={css.actions.buttonsMoney}>
                    <WalletButtonAction
                        icon={['far', 'arrows-left-right']}
                        title='Transferir'
                        legend='Sacar dinheiro'
                        onClick={() => { }}
                        disabled
                    />
                    <WalletButtonAction
                        icon={['far', 'building-columns']}
                        title='Conta bancária'
                        legend='Ver ou adicionar'
                        onClick={() => navigate('/minha-carteira/contas-bancarias')}
                    />
                </Stack>
                <List dense sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                }}>
                    <SmallerDashedList
                        divider
                        secondaryAction={
                            balances.list.isLoaded ?
                                (<Price
                                    size='small'
                                    value={removeCurrencySymbol(balances.list.data.balance_in_protest)}
                                />) :
                                '...'
                        }
                        title={'Bloqueado'} />
                    <SmallerDashedList
                        secondaryAction={
                            balances.list.isLoaded ?
                                (<Price
                                    size='small'
                                    value={removeCurrencySymbol(balances.list.data.balance)}
                                />) :
                                '...'
                        }
                        title={'A liberar'} />
                </List>

                <Box
                    p={'.75rem .9375rem'}
                    sx={{
                        backgroundColor: palette.clouds,
                    }}>
                    <Typography
                        variant='h2'
                        component='h2'
                        fontSize={12}
                        fontWeight={typography.fontWeightBold}
                    >
                        Atualizado às 14:30 de 15/04/2021
                    </Typography>
                </Box>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={value}
                        onChange={handleChange}>
                        <Tab label="Movimentações" {...a11yProps(0)} />
                        <Tab label="Transferência" {...a11yProps(1)} />
                        <Tab label="A liberar" {...a11yProps(2)} />
                    </Tabs>
                </Box>

                <Filter.Wallet filterList={filters.wallet.period} />

                <TabPanel value={value} index={0}>
                    {/* <Box pt={pxToRem(15)} pb={pxToRem(15)}
                        sx={css.extract.separate}>
                        <WalletExtractItem
                            title='Transferência realizada'
                            status='pending'
                            date='10/01/2023'
                            amount='50.00'
                            type='money' />
                        <WalletExtractItem
                            title='Transferência realizada'
                            status='accepted'
                            date='10/08/2023'
                            amount='78.00'
                            type='money'
                        />
                        <WalletExtractItem
                            title='Transferência realizada'
                            status='processing'
                            date='24/02/2023'
                            amount='36.00'
                            type='money'
                        />
                    </Box> */}
                    <MessageBox type='info' message='Nada para exibir.' />
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <MessageBox type='info' message='Nada para exibir.' />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <MessageBox type='info' message='Nada para exibir.' />
                </TabPanel>
            </Container>
        </Page.Wrapper>
    );
};

export { Money };
