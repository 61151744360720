/* eslint-disable max-len */
import React from 'react';
import { Stack, Box, Radio, Typography, RadioProps, useTheme } from '@mui/material';

import { Image } from './styles';
import { sx } from './sx';

interface VariationItemProps {
    onClick?: () => void;
    image?: string;
    color?: string;
    radio?: RadioProps;
    title?: string;
    checkedRadio?: boolean;
    size?: 'small' | 'medium';
    _id?: string;
}

const VariationItem: React.FC<VariationItemProps> = ({
    size, image, color, radio, title, checkedRadio, onClick, _id,
}: VariationItemProps) => {
    const { palette } = useTheme();

    const { css } = sx({
        checkedRadio: checkedRadio,
    });

    return (
        <Stack
            width={size === 'small' ? 46 : 70}
            direction={'column'}
            component='label'
            htmlFor={_id}>
            <Box
                onClick={onClick}
                sx={{
                    ...css.sxBoxImage['default'](!!radio?.checked || !!checkedRadio),
                    ...css.sxBoxImage[size ? size : 'medium'],
                }} >
                {radio &&
                    <Radio
                        size='small'
                        sx={{ ...css.sxRadio }}
                        id={_id as string}
                        {...radio}
                    />
                }
                {image && (
                    <Box sx={{
                        border: '1px solid ' + palette.clouds,
                        width: '100%',
                    }}>
                        <Image src={image} alt="" />
                    </Box>
                )}

                {color && (
                    <Box sx={{
                        width: size === 'small' ? 46 : 70,
                        // height: size === 'small' ? 46 : 70,
                        backgroundColor: color,
                    }} />
                )}
            </Box>
            {title &&
                <Typography
                    sx={{ ...css.sxText }}>
                    {title}
                </Typography>
            }
        </Stack>
    );
};

export { VariationItem };
