import { OnlyImageProps } from '@interfaces';
import { styled } from '@mui/material';

type ImageProps = Omit<OnlyImageProps, 'src' | 'alt'>

export const Wrapper = styled('div')<ImageProps>(({ theme, maxHeight }) => `
& > .WrapAnchorPicture {
        height: 100vh;
        max-height: ${maxHeight ?? 120}px;
        cursor: pointer;

        & > .AnchorPicture {
            display: block;
            height: 100%;
            width: 100%;
            overflow: hidden;

            & > .WrapPicture {
                height: 100%;
                width: 100%;
                picture {
                    source,
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border: 0;
                        max-width: 100%;
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
`);
