import { PurchaseHistoryStatusType } from '@types';

export const purchaseStatus = (key: PurchaseHistoryStatusType) => {
    const object = {
        PAGAMENTO_PENDENTE: { // N
            title: 'Aguardando pagamento',
            status: !!(key === 'PAGAMENTO_PENDENTE'),
            color: '#e3b487',
        },
        PAGAMENTO_PENDENTE_DEVOLUCAO: { // N
            title: 'Aguardando pagamento',
            status: !!(key === 'PAGAMENTO_PENDENTE_DEVOLUCAO'),
            color: '#e3b487',
        },
        AGUARDANDO_PAGAMENTO: {
            title: 'Aguardando pagamento',
            status: !!(key === 'AGUARDANDO_PAGAMENTO'),
            color: '#e3b487',
        },
        PAGO: {
            title: 'Pago',
            status: !!(key === 'PAGO'),
            color: '#27ae60',
        },
        PAGO_DEVOLUCAO: { // N
            title: 'Pago',
            status: !!(key === 'PAGO_DEVOLUCAO'),
            color: '#27ae60',
        },
        POSTADO: {
            title: 'Postado',
            status: !!(key === 'POSTADO'),
            color: '#f25e35',
        },
        POSTADO_DEVOLUCAO: { // N
            title: 'Postado',
            status: !!(key === 'POSTADO_DEVOLUCAO'),
            color: '#f25e35',
        },
        ETIQUETA_GERADA: {
            title: 'Aguardando envio',
            status: !!(key === 'ETIQUETA_GERADA'),
            color: '#27ae60',
        },
        ETIQUETA_GERADA_DEVOLUCAO: {
            title: 'Aguardando envio',
            status: !!(key === 'ETIQUETA_GERADA_DEVOLUCAO'),
            color: '#e3b487',
        },
        FINALIZADO: {
            title: 'Finalizado',
            status: !!(key === 'FINALIZADO'),
            color: '#27ae60',
        },
        FINALIZADO_DEVOLUCAO: { // N
            title: 'Finalizado',
            status: !!(key === 'FINALIZADO_DEVOLUCAO'),
            color: '#27ae60',
        },
        CANCELADO: {
            title: 'Cancelado',
            status: !!(key === 'CANCELADO'),
            color: '#c2403f',
        },
        CANCELADO_DEVOLUCAO: { // N
            title: 'Cancelado',
            status: !!(key === 'CANCELADO_DEVOLUCAO'),
            color: '#c2403f',
        },
        REEMBOLSADO: { // N
            title: 'Cancelado',
            status: !!(key === 'REEMBOLSADO'),
            color: '#c2403f',
        },
        RETURNED: { // N
            title: 'Devolvido',
            status: !!(key === 'RETURNED'),
            color: '#e3b487',
        },
        EXPIRADO: {
            title: 'Pagamento expirado',
            status: !!(key === 'EXPIRADO'),
            color: '#c2403f',
        },
        PAGAMENTO_NAO_APROVADO: {
            title: 'Pagamento não aprovado',
            status: !!(key === 'PAGAMENTO_NAO_APROVADO'),
            color: '#c2403f',
        },
        AGUARDANDO_ESTORNO_BOLETO: { // N
            title: 'Aguardando Reembolso',
            status: !!(key === 'AGUARDANDO_ESTORNO_BOLETO'),
            color: '#f25e35',
        },
        AGUARDANDO_ESTORNO_PIX: { // N
            title: 'Aguardando Reembolso',
            status: !!(key === 'AGUARDANDO_ESTORNO_PIX'),
            color: '#f25e35',
        },
    };
    return object;
};
