import React from 'react';
import { MainMenuContext } from '../../contexts/main-menu/context';
import { useOthers } from '../others';

const mainMenuProvider = () => {
    const [showFirstLvL, setShowFirstLvL] = React.useState({
        visibility: false,
    });
    const { categories } = useOthers();

    const handleFirstLvl = ({ visibility }: {visibility: boolean}) => {
        setShowFirstLvL((prev) => prev = {
            ...prev,
            visibility: visibility,
        });
    };

    const handleLeaveMainMenu = () => {
        categories.reset({
            type: 'categories',
        });
        categories.reset({
            type: 'history',
        });
        handleFirstLvl({
            visibility: false,
        });
    };

    return {
        showFirstLvL,
        handleFirstLvl,
        handleLeaveMainMenu,
    };
};

const useMainMenu = () => {
    const context = React.useContext(MainMenuContext);
    return context;
};

export { useMainMenu, mainMenuProvider };
