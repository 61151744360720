import { useTheme } from '@mui/material';

const sx = () => {
    const { palette } = useTheme();

    const css = {
        buttonChevronLeft: {
            '&:hover': {
                border: 0,
            },
            ...{
                fontSize: 12,
                fontWeight: 700,
                color: 'black',
                border: 0,
                borderRadius: 0,
                paddingTop: 1,
                paddingBottom: 1,
                paddingLeft: 1,
                paddingRight: 1,
                minWidth: 42,
                width: 'auto',
                whiteSpace: 'nowrap',
                backgroundColor: palette.grey[100],
            },
        },
        buttonCloseFilter: {
        },
    };

    return { css };
};

export { sx };
