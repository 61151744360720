
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { sx } from './sx';
import { Image } from '@components';
import { UploadImagePreviewProps } from '@interfaces';

const UploadImagePreview: React.FC<UploadImagePreviewProps> = (props:
    UploadImagePreviewProps) => {
    const {
        refprop,
        height,
        src,
        type,
        id,
        onDelete,
    } = props;

    const { css } = sx();
    const { typography, palette } = useTheme();

    return (
        <Box
            component='label'
            htmlFor={id}
            sx={{ cursor: 'pointer' }}
        >
            <Box position='relative'
                sx={css.wrapper(type, height as number)}>

                {type === 'upload' && (
                    <input
                        {...props}
                        hidden
                        accept="image/png,image/jpeg"
                        id={id}
                        multiple
                        type="file"
                        ref={refprop}
                    />
                )}
                {type === 'upload' && (

                    <Box sx={css.iconWrapper}>
                        <Box position='relative' display='inline-block'>
                            <I
                                icon={['fal', 'camera']}
                                fontSize={26}
                            />
                            <Box sx={css.icon}>
                                <I
                                    icon={['fal', 'plus']}
                                    fontSize={15}
                                />
                            </Box>
                        </Box>
                    </Box>
                )}

                {type === 'preview' && src && (
                    <Image
                        maxHeight={height}
                        src={src as string}
                    />
                )}

            </Box>
            {type === 'preview' && src && (
                <Button
                    variant="outlined"
                    fullWidth
                    onClick={onDelete}
                    sx={{
                        '&:hover': {
                            border: 0,
                        },
                        ...{
                            padding: '4px .75rem',
                            border: 0,
                        },
                    }}>
                    <Typography
                        variant="h1"
                        fontSize={12}
                        fontWeight={typography.fontWeightBold}
                        color={palette.pomegarnate}
                        textTransform='capitalize'
                        mr={0.4}
                    >
                        Deletar
                    </Typography>
                    <I
                        icon={['fal', 'trash-can']}
                        fontSize={15}
                        color={palette.pomegarnate}
                    />
                </Button>
            )}
        </Box>
    );
};

export { UploadImagePreview };
