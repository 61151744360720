import { alpha, StepConnector, stepConnectorClasses, styled } from '@mui/material';

export const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: alpha(theme.palette.dark, 0.3),
    zIndex: 1,
    color: '#fff',
    width: 36,
    height: 36,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: theme.typography.fontWeightBold,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(16),
    paddingLeft: 0,
    paddingRight: 0,
    ...(ownerState.active && {
        backgroundColor: theme.palette.dark,
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor: theme.palette.dark,
    }),
}));

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    marginLeft: -8,
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.dark,
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: theme.palette.dark,
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor: alpha(theme.palette.dark, 0.3),
        borderRadius: 1,
    },
}));
