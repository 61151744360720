import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxLink: {
            padding: '0.875rem',
            paddingRight: 0,
            textDecoration: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            borderBottom: `1px solid ${palette.clouds}`,
        },
        sxBoxInfo: {
            display: 'flex',
            alignItems: 'center',
        },
        sxTitlePost: {
            padding: '0.625rem',
            fontWeight: typography.fontWeightBold,
            fontSize: '0.875rem',
            color: palette.dark,
        },
        sxIconButton: {
            width: '2.5rem',
            fontSize: '1rem',
            color: palette.dark,
        },
    };

    return { css };
};
