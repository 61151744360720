import * as Yup from 'yup';

export const formSchemaCheckbox = Yup.object().shape({
    condition: Yup.array(),
    // required('Selecione uma condição'),
});

export const formSchema = Yup.object().shape({
    price_min: Yup.number()
        .typeError('Preencha apenas com números')
        .required('Preço mínimo é obrigatório para filtragem'),
    price_max: Yup.number()
        .typeError('Preencha apenas com números')
        .required('Preço máximo é obrigatório para filtragem'),
    // .required('Selecione uma condição'),
});
