import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Box, useTheme, Typography, IconButton } from '@mui/material';
import { HeaderPageProps } from '@interfaces';

import { sx } from './sx';

const HeaderPage: React.FC<HeaderPageProps> = ({
    title,
    legend,
    icon,
    iconCustom,
    action,
    onClick,
    titleAlign,
    backgroundColor,
    size,
}: HeaderPageProps) => {
    const { palette, typography } = useTheme();
    const { css } = sx();
    const handlePadding = icon ? '0rem' : '0.9375rem';

    const sizeHead = {
        small: {
            fontSize: 14,
            iconFontSize: 14,
        },
        medium: {
            fontSize: 16,
            iconFontSize: 18,
        },
    };

    return (
        <Box sx={{
            ...css.sxBox,
            paddingLeft: handlePadding,
            backgroundColor: backgroundColor ?
                backgroundColor : palette.common.white,
        }}>
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}>
                {icon &&
                    <IconButton
                        onClick={onClick}
                        sx={{ ...css.sxIconButton[titleAlign] }}>
                        <I
                            color={palette.dark}
                            fontSize={sizeHead[size ?? 'medium'].iconFontSize}
                            icon={icon} />
                    </IconButton>
                }
                {iconCustom && iconCustom}
                <Box sx={{
                    ...css.sxBoxTitle[titleAlign],
                }}>
                    <Typography
                        variant='h2'
                        fontSize={sizeHead[size ?? 'medium'].fontSize}
                        fontWeight={typography.fontWeightBold}
                        lineHeight={1.7}
                    >
                        {title}
                    </Typography>
                    {legend && (
                        <Typography
                            variant='h2'
                            component='p'
                            fontSize={14}
                            fontWeight={typography.fontWeightMedium}
                            pt={0.2}
                        >
                            {legend}
                        </Typography>
                    )}
                </Box>
            </Box>
            {action &&
                <Box sx={{ ...css.sxBoxContent }}>
                    <Box sx={{ ...css.sxBoxAction }}>
                        {action}
                    </Box>
                </Box>
            }
        </Box>
    );
};

export { HeaderPage };
