import { styled } from '@mui/material';

export const Logo = styled('img')`
    max-height: 1.75rem;
    max-width: 9.6875rem;
    cursor: pointer;

    @media(max-width:600px) {
        max-height: 1.375rem;
    }
`;

export const Input = styled('input')`
    font-family: 'Nunito', sans-serif;
    font-weight: 700;
    font-size: 1rem;
    width: 100%;
    padding-right: 0.9375rem;
    border: none;
    outline: none;
`;

export const UserMenuWrapper = styled('div')`
    display: none;
    position: absolute;
    top: 3.125rem;
    right: 0;
    width: 17.5rem;
    background-color: ${({ theme }) => theme.palette.common.white};
    box-shadow: 0px 3px 6px rgba(0,0,0,0.29);
    border-radius: .1875rem;
    border: 1px solid ${({ theme }) => theme.palette.clouds};
    z-index: 510;
`;

export const MainMenuWrapper = styled('div')`
    display: none;
    position: absolute;
    top: 3.125rem;
    left: 0;
    width: 17.5rem;
    background-color: ${({ theme }) => theme.palette.common.white};
    box-shadow: 0px 3px 6px rgba(0,0,0,0.29);
    border-radius: .1875rem;
    border: 1px solid ${({ theme }) => theme.palette.clouds};
`;
