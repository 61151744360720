import React from 'react';
import { adsHookProvider } from '../../hooks/ads';
import { useVariationsProviderHook } from '../../hooks/ads/vatiations';
import { AdsContext } from './context';

interface AdsProviderProps {
    children: React.ReactNode;
};

const AdsProvider = ({ children }: AdsProviderProps) => {
    const ads = adsHookProvider();
    const variations = useVariationsProviderHook();

    return (
        <AdsContext.Provider value={{ ...ads, ...variations }}>
            {children}
        </AdsContext.Provider>
    );
};

export {
    AdsProvider,
};
