import { HeaderLayoutType } from '@types';
import {
    stackRoutes,
    authStackRoutes,
    authStackRoutesLayout,
    membersHomeLayout,
} from '../routes/list.routes';

const routes = [
    ...stackRoutes,
    ...authStackRoutes,
    ...authStackRoutesLayout,
    ...membersHomeLayout,
];

export const routeLayout = (pathname: string): HeaderLayoutType => {
    if (pathname) {
        const route = routes.find(({ path }) => path === pathname);
        return route?.headerLayout as HeaderLayoutType;
    }

    return 'default';
};
