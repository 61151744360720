import { ErrorDataProps, ErrorsDataPayloadProps } from '@interfaces/ClientServicesApi';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { auth } from './services/auth';
import { user } from './services/user';
import { ads } from './services/ads';
import { others } from './services/others';
import { paymentMethod } from './services/payment-method';
import { checkout } from './services/shopping/checkout';
import { purchases } from './services/shopping/purchases';
import { sales } from './services/shopping/sales';
import { cart } from './services/shopping/cart';
import { wallet } from './services/wallet';
import configs from '../configs/config.json';

const client = () => {
    const services = {
        auth,
        user,
        paymentMethod,
        ads,
        others,
        checkout,
        shopping: {
            cart,
            purchases,
            sales,
        },
        wallet,
    };

    const instance = axios.create({
        baseURL: configs.apis.url,
        timeout: 60000,
        withCredentials: true,
    });

    instance.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        if (error.response && error.response.status === 401) {
            if (error.response.config.url !== '/user' &&
             error.response.config.url !== '/auth/local') {
                window.location.href = '/login';
            }
        };
        return Promise.reject(error);
    });

    const handleResponse = (response: AxiosResponse) => {
        return {
            error: false,
            response: response.data,
        };
    };

    const handleErrors = (error: any) => {
        if (error instanceof AxiosError) {
            const response = error.
                response?.data as ErrorDataProps | ErrorsDataPayloadProps;
            return {
                error: true,
                response,
            };
        }
    };

    return {
        services,
        instance,
        handleErrors,
        handleResponse,
    };
};


export { client };
