import * as Yup from 'yup';

export const formSchemaCheckbox = Yup.object().shape({
    brand: Yup.array().min(1, 'Selecione ao menos uma marca').
        nullable().
        required('Selecione ao menos uma marca'),
});

export const formSchemaRadio = Yup.object().shape({
    brand: Yup.string().required('Selecione ao menos uma marca'),
});
