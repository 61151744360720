import styled from 'styled-components';

export const Form = styled.form`
    display: flex;
    flex-direction: column;

    & > :nth-child(1) {
        margin-top: 1.875rem;
        margin-bottom: 1.5625rem;
    }

    & > :nth-child(2) {
        margin-bottom: 0.9375rem;
        
        & > .MuiOutlinedInput-root {
            padding-right: 0;
        }
    }
`;

export const Label = styled.label`
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    font-family: 'Nunito';
    margin-left: 0.25rem;
`;
