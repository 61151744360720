import React from 'react';
import { useMediaQuery, Box, Avatar, Typography, useTheme } from '@mui/material';
import { MountURLParamsListForListProps } from '@interfaces/Contexts';
import { useNavigate } from 'react-router-dom';
import { images, mountURLParamsForResults } from '@utils';
import { MountURLParamsForResultsProps } from '@interfaces';

interface QuickLinkProps {
    data: MountURLParamsListForListProps;
}

const QuickLink: React.FC<QuickLinkProps> = ({
    data,
}: QuickLinkProps) => {
    const matches = useMediaQuery('(min-width:600px)');
    const { typography } = useTheme();
    const navigate = useNavigate();

    const mountURLParams = ({ ...props }: MountURLParamsForResultsProps) => {
        const response = mountURLParamsForResults({ ...props }) as string;
        return navigate(response);
    };

    const sizes = {
        quicklinks: {
            avatar: {
                width: matches ? 98 : 70,
                height: matches ? 98 : 70,
            },
            title: matches ? 14 : 12,
        },
    };

    return (
        <Box
            sx={{
                maxWidth: matches ? '6.25rem' : '4.6875rem',
                width: '100%',
                cursor: 'pointer',
            }}
            key={data._id}
            display='flex'
            flexDirection='column'
            justifyItems='center'
            alignItems='center'
            onClick={() => mountURLParams({
                ...data,
                type: 'quicklink',
            })}>
            <Avatar
                alt={data.title}
                src={images.load(data.banners[0], 'server')}
                sx={sizes.quicklinks.avatar} />
            <Typography
                variant='body1'
                fontWeight={typography.fontWeightMedium}
                marginTop={1}
                fontSize={sizes.quicklinks.title}
                textAlign={'center'}>
                {data.title}
            </Typography>
        </Box>
    );
};

export { QuickLink };
