export const BoxInfoSx = {
    default: {
        display: 'flex',
        flexDirection: 'column',
    },
    fluid: {
        paddingTop: '0.625rem',
    },
    big: {
        paddingTop: '0.5rem',
    },
    medium: {
        paddingTop: '0',
    },
    small: {
        paddingTop: '0',
    },
};

export const MarginTitle = {
    default: {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    fluid: {
        marginBottom: '0.25rem',
    },
    big: {
        marginBottom: '0.25rem',
    },
    medium: {
        marginBottom: '0.1875rem',
    },
    small: {
        marginBottom: '0.125rem',
    },
};

export const PriceSize = {
    fluid: 'small',
    big: 'small',
    medium: 'tiny',
    small: 'tiny',
};

export const BoxIconsButtons = {
    position: 'absolute',
    bottom: 0,
    right: 0,
    zIndex: 90,
};

export const StackIcons = {
    height: '100%',
    padding: '0.625rem',
};
