import { styled } from '@mui/material';

export const Logo = styled('img')`
    max-height: 1.75rem;
    max-width: 9.6875rem;
    cursor: pointer;

    @media(max-width:600px) {
        max-height: 1.375rem;
    }
`;
