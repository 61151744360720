import { Box } from '@mui/material';
import styled from 'styled-components';

export const ImageBack = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

export const Hero = styled(Box)(({
    position: 'relative',
}));

export const ICButton = styled(Box)(({
    position: 'absolute',
    backgroundColor: 'white',
    top: 10,
    right: 10,
}));
