import React from 'react';
import { ShoppingContext } from '../../../contexts/shopping/context';
import { client } from '../../../api/client';
import { CheckoutPurchaseProps, FindOrderProps } from '@interfaces/Checkout';

const checkoutHookProvider = () => {
    const addDiscountCoupon = async ({ name }: { name: string }) => {
        const { services } = client();

        const response = await services.checkout.coupon.apply({
            name,
        });
        return response;
    };

    const removeDiscountCoupon = async () => {
        const { services } = client();

        const response = await services.checkout.coupon.remove();
        return response;
    };

    const applyPoints = async () => {
        const { services } = client();

        const response = await services.checkout.points.apply();
        return response;
    };

    const removePoints = async () => {
        const { services } = client();

        const response = await services.checkout.points.remove();
        return response;
    };

    const applyWallet = async () => {
        const { services } = client();

        const response = await services.checkout.wallet.apply();
        return response;
    };

    const removeWallet = async () => {
        const { services } = client();

        const response = await services.checkout.wallet.remove();
        return response;
    };

    const findOrder = async ({ orderID }: FindOrderProps) => {
        const { services } = client();

        const response = await services.checkout.order.find({ orderID });
        return response;
    };

    const purchase = async ({
        deliverymethod,
        paymentMethod,
        paymentType,
        creditCardId,
        installmentNumber,
    }: CheckoutPurchaseProps) => {
        const { services } = client();
        const response = await services.checkout.purchase({
            deliverymethod,
            paymentMethod,
            paymentType,
            creditCardId,
            installmentNumber,
        });
        return response;
    };


    return {
        checkout: {
            coupon: {
                apply: addDiscountCoupon,
                remove: removeDiscountCoupon,
            },
            points: {
                apply: applyPoints,
                remove: removePoints,
            },
            wallet: {
                apply: applyWallet,
                remove: removeWallet,
            },
            purchase,
            order: {
                find: findOrder,
            },
        },
    };
};

const useCheckout = () => {
    const context = React.useContext(ShoppingContext);
    return context;
};

export { useCheckout, checkoutHookProvider };
