/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import React from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    TextField,
    useMediaQuery,
} from '@mui/material';
import { ButtonInterest, FixedMenu, HeaderPage, Snackbar } from '@components';
import { FormControlLabel, FormControlSelect, Textarea } from './styles';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
    ErrorsProps,
    GetInTouchProps,
    SnackBarResponse,
    ToggleStaticProps,
} from '@interfaces';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from './yup';
import { useOthers } from '../../hooks/others';
import { DEFAULTS } from './defaults';

const ContactUs: React.FC = () => {
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const { forms } = useOthers();
    const matches = useMediaQuery('(min-width:600px)');
    const [feedback, setFeedback] = React.useState<SnackBarResponse>({
        message: '',
        type: 'info',
    });

    const [cardErrors,
        setCardErrors] = React.useState<ErrorsProps>(DEFAULTS.BACKEND_ERRORS);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const keys = Object.keys(cardErrors);

    const { register, handleSubmit, reset, formState: { errors } } =
        useForm<GetInTouchProps>({
            resolver: yupResolver(formSchema),
            mode: 'onSubmit',
        });

    const onSubmit: SubmitHandler<GetInTouchProps> = async (data) => {
        setIsLoading((prev) => prev = true);
        const response = await forms.getInTouch.send({ ...data });

        if (!(response?.error)) {
            setFeedback((prev) => prev = {
                message: response?.response.message,
                type: 'success',
            });
            snackRef.current?.show();
            setCardErrors((prev) => prev = DEFAULTS.BACKEND_ERRORS);
            reset({
                name: '',
                email: '',
                subject: '',
                text: '',
            });
            setIsLoading((prev) => prev = false);
        } else {
            const filterErrors = response.response.errors.errors.
                map((err: any, i: number) => {
                    const paramsKeys = err.param.split('.');
                    const key = paramsKeys[paramsKeys.length - 1];
                    const object = {
                        [key]: {
                            location: err.location,
                            msg: err.msg,
                            param: err.param,
                            error: true,
                        },
                    };

                    if (err.param !== keys[i]) {
                        return (object);
                    }

                    return (object);
                });

            const reduceErrors = filterErrors.reduce(
                (acc: any, current: any) => ({
                    ...acc,
                    ...current,
                }),
                {},
            );
            setCardErrors((prev) => prev = reduceErrors);
            setIsLoading((prev) => prev = false);
        }
    };

    return (
        <Container>
            <Snackbar
                ref={snackRef}
                autoHideDuration={3000}
                message={feedback ? feedback.message : ''}
                severity={feedback ? feedback.type as any : 'info'}
            />
            <HeaderPage
                icon={['fas', 'messages']}
                titleAlign={'default'}
                title={'Fale conosco'} />
            <form
                style={{
                    maxWidth: '56.25rem',
                    padding: '3.75rem 0',
                }}
                onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid lg={7} sm={7} item xs={12} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'Nome *'}
                            variant="outlined"
                            error={(cardErrors.hasOwnProperty(
                                'name')) || (!!errors.name)}
                            helperText={(cardErrors.hasOwnProperty(
                                'name') &&
                                cardErrors.name.msg) ||
                                (errors.name && errors.name.message)}
                            {...register('name',
                                { required: true },
                            )} />
                    </Grid>
                    <Grid item lg={7} sm={7} xs={12} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'E-mail *'}
                            error={(cardErrors.hasOwnProperty(
                                'email')) || (!!errors.email)}
                            variant="outlined"
                            helperText={(cardErrors.hasOwnProperty(
                                'email') && cardErrors.email.msg) ||
                                (errors.email && errors.email.message)}
                            {...register('email',
                                { required: true },
                            )} />
                        <FormHelperText sx={{ margin: '0.1875rem 0.875rem 0' }}>
                            Confira seu e-mail para garantir
                            que nossa resposta chegará até você
                        </FormHelperText>
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <TextField
                            focused
                            fullWidth
                            label={'Assunto *'}
                            error={(cardErrors.hasOwnProperty(
                                'subject')) || (!!errors.subject)}
                            variant="outlined"
                            helperText={(cardErrors.hasOwnProperty(
                                'subject') && cardErrors.subject.msg) ||
                                (errors.subject && errors.subject.message)}
                            {...register('subject',
                                { required: true },
                            )} />
                    </Grid>
                    <Grid item xs={12} mb={1}>
                        <FormControlSelect>
                            <FormControlLabel id="others-label">
                                Mensagem *
                            </FormControlLabel>
                            <FormControl fullWidth error={(cardErrors.hasOwnProperty(
                                'text')) || (!!errors.text)}>
                                <Textarea
                                    aria-label="minimum height"
                                    minRows={3}
                                    className={(cardErrors.hasOwnProperty(
                                        'text') ? 'error' : '') ||
                                        (errors.text ? 'error' : '')}
                                    {...register('text',
                                        { required: true },
                                    )}
                                />
                                {errors.text && (
                                    <FormHelperText>
                                        {errors.text?.message}
                                    </FormHelperText>
                                )}
                                {(cardErrors.hasOwnProperty(
                                    'text')) && (
                                        <FormHelperText>
                                            {cardErrors.text.msg}
                                        </FormHelperText>
                                    )}
                            </FormControl>
                        </FormControlSelect>
                    </Grid>
                </Grid>
                <Box
                    display='flex'
                    justifyContent={!matches ? 'center' : 'flex-start'}
                    mt={2}>
                    <Box
                        maxWidth={320}
                        width='100%'
                        justifyContent='center'>
                        <Button
                            fullWidth
                            variant="contained"
                            size="large"
                            type='submit'
                            className='MuiButton secondary'
                        >
                            ENVIAR
                            {isLoading && (
                                <CircularProgress
                                    color="inherit"
                                    size={16}
                                    sx={{
                                        ml: 1,
                                    }} />
                            )}

                        </Button>
                    </Box>
                </Box>
            </form>
            {!matches &&
                <FixedMenu />}
        </Container>
    );
};

export { ContactUs };
