export const DEFAULTS = {
    BACKEND_ERRORS: {
        listErrors: {
            error: false,
            location: '',
            msg: '',
            param: '',
            value: '',
        },
    },
};
