import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { HeaderPage, MenuItem } from '@components';
import React from 'react';
import { Box, Checkbox, IconButton, useMediaQuery, useTheme } from '@mui/material';
import { Form, SearchFieldInput } from './styles';
import {
    FilterContent,
    FilterFooter,
    ButtonSelectedFilter,
    BoxActiveFiltersList,
} from '../../../components/filters';

interface handleVisibilitycitiesChildrenListProps {
    action: 'show' | 'hidden'
}

const statesList = [
    { id: '1638e207a641', name: 'Acre', acronym: 'ac' },
    { id: '331c424ade11', name: 'Alagoas', acronym: 'al' },
    { id: '9fe531de3a34', name: 'Amapá', acronym: 'ap' },
    { id: 'e8dd4cb2ec2e', name: 'Amazonas', acronym: 'am' },
];

const citiesChildrenList = [
    { id: '271eba38e490', name: 'Maceió', value: 'maceio', acronym: 'al' },
    { id: '09cd584ecf4c', name: 'Arapiraca', value: 'arapiraca', acronym: 'al' },
    { id: 'de0b1ce52f55', name: 'Maragogi', value: 'maragogi', acronym: 'al' },
];

const LocationFilter = () => {
    const { palette } = useTheme();
    const matches = useMediaQuery('(min-width:600px)');
    const [showcitiesChildrenList,
        setShowcitiesChildrenList] = React.useState<boolean>(false);

    const handleVisibilitycitiesChildrenList = (
        { action }: handleVisibilitycitiesChildrenListProps) => {
        const actions = {
            show: true,
            hidden: false,
        };

        setShowcitiesChildrenList((prev) => prev = actions[action]);
    };

    return (
        <Box height='100%' component='form'>

            <FilterContent minHeightContent={114}>

                {!showcitiesChildrenList && statesList.map(({ id, name }) => (
                    <MenuItem
                        key={id}
                        onClick={() => handleVisibilitycitiesChildrenList(
                            { action: 'show' },
                        )}
                        borderType={'bottom'}
                        title={{
                            text: name,
                            size: !matches ? 'small' : 'tiny',
                        }}
                        legend={{
                            text: id === '331c424ade11' ? 'Maceió' : '',
                        }}>
                        <I
                            icon={['far', 'chevron-right']}
                        />
                    </MenuItem>
                ))}
                {showcitiesChildrenList && (
                    <Box>
                        <HeaderPage
                            titleAlign={'default'}
                            icon={['far', 'chevron-left']}
                            title={'Alagoas'}
                            size='small'
                            onClick={() => handleVisibilitycitiesChildrenList(
                                { action: 'hidden' },
                            )}
                        />
                        <Box
                            width='100%'
                            sx={{
                                borderBottom: '1px solid ' + palette.grey[200],
                            }}>
                            <Form>
                                <Box width='100%'>
                                    <SearchFieldInput
                                        type='search'
                                        placeholder='Busque uma cidade' />
                                </Box>

                                <Box minWidth={64} display='flex' justifyContent='center'>
                                    <IconButton
                                        aria-label="delete"
                                        size="small"
                                        type='submit'
                                    >
                                        <I
                                            icon={['far', 'search']}
                                            color='black'
                                            fontSize={16}
                                        />
                                    </IconButton>
                                </Box>

                            </Form>
                        </Box>
                        <BoxActiveFiltersList>
                            <ButtonSelectedFilter value='Maceió' />
                        </BoxActiveFiltersList>
                        <Box>
                            {citiesChildrenList.map(({ id, name, value }, i) => (
                                <MenuItem
                                    key={id}
                                    onClick={() => { }}
                                    borderType={'bottom'}
                                    title={{
                                        text: name,
                                        size: !matches ? 'small' : 'tiny',
                                    }}
                                    htmlFor={id}>
                                    <Checkbox
                                        id={id}
                                        size='small'
                                        value={value}
                                    />
                                </MenuItem>
                            ))}
                        </Box>
                    </Box>
                )}
            </FilterContent>
            {showcitiesChildrenList && (
                <FilterFooter
                    onClearAction={() => { }}
                    onSubmitAction={() => { }}
                />
            )}
        </Box>

    );
};

export { LocationFilter };
