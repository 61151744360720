import React from 'react';
import {
    Box,
    CircularProgress,
    Divider,
    IconButton,
    Stack,
    Typography,
    useTheme,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { sx } from './sx';

interface ControllQuantityProps {
    value: number;
    limit: number;
    remove: () => void;
    add: () => void;
    disabled?: boolean;
    loading?: boolean;
}

const ControllQuantity: React.FC<ControllQuantityProps> = ({
    add, remove, value, limit, disabled, loading,
}: ControllQuantityProps) => {
    const { palette } = useTheme();
    const { css } = sx();
    return (
        <Stack
            sx={{ ...css.sxStack }}
            divider={
                <Divider
                    orientation="vertical"
                    sx={{ ...css.sxDivider }} />
            }
            direction={'row'}>
            <IconButton
                size='small'
                disabled={value <= 1}
                onClick={disabled ? () => { } : remove} sx={{ ...css.sxButton }}>
                <I icon={['far', 'minus']} />
            </IconButton>
            <Box sx={{ ...css.sxValueBox }}>
                {loading ? (
                    <CircularProgress
                        size={14}
                        sx={{
                            color: palette.darkGrey,
                        }} />
                ) : (

                    <Typography sx={{ ...css.sxNumber }}>
                        {value}
                    </Typography>
                )}
            </Box>
            <IconButton
                size='small'
                disabled={value === limit}
                onClick={disabled ? () => { } : add} sx={{ ...css.sxButton }}>
                <I icon={['far', 'plus']} />
            </IconButton>
        </Stack>
    );
};

export { ControllQuantity };
