import React from 'react';
import {
    Box,
    CircularProgress,
    FormControlLabel,
    Grid,
    Radio,
    RadioGroup,
    Typography,
    useTheme,
} from '@mui/material';
import { ButtonInterest, ContentItem, MessageBox } from '@components';
import { ContentProps, ListContentsProps } from '@interfaces';

import { contents } from '../../../../api/services/contents';

import { sx } from './sx';
import { images } from '@utils';
import { useNavigate } from 'react-router-dom';

const HomeMyContent: React.FC = () => {
    const { css } = sx();
    const { palette } = useTheme();
    const [type, setType] = React.useState('');
    const { myContents } = contents;
    const navigate = useNavigate();
    const [limit, setLimit] = React.useState<number>(8);
    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();
    const [listContents, setListContents] = React.useState<ListContentsProps>({
        isLoaded: true,
        contents: [] as ContentProps[],
    });

    const loadContents = async ({ type }: { type: string }) => {
        setListContents((prev) => prev = {
            ...prev,
            isLoaded: true,
        });
        const response = await myContents({
            type: type as any,
            idCategory: '',
            limit: limit,
        });

        if (!response?.error && response?.response.payload) {
            setListContents({
                isLoaded: false,
                contents: response.response.payload,
            });
        } else {
            setFeedback({
                type: response?.response.type,
                message: response?.response.message,
            });
            setListContents({
                isLoaded: false,
                contents: [] as ContentProps[],
            });
        }
    };

    const handleFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
        setType((event.target as HTMLInputElement).value);
    };

    React.useEffect(() => {
        loadContents({
            type: type,
        });
    }, [type, limit]);

    return (
        <Grid container spacing={2}>
            <Box sx={{ ...css.sxBoxFilter }}>
                <Typography
                    sx={{ ...css.sxFilterTitle }}
                    variant='caption'>
                    Filtrar por:
                </Typography>
                <RadioGroup
                    defaultValue="video"
                    name="filter"
                    value={type}
                    onChange={handleFilter}
                    sx={{ ...css.sxRadioGroup }}>
                    <FormControlLabel
                        sx={{ ...css.sxFormControl }}
                        value="VIDEO" control={<Radio />} label="Vídeos" />
                    <FormControlLabel
                        sx={{ ...css.sxFormControl }}
                        value="ARTIGO" control={<Radio />} label="Artigos" />
                </RadioGroup>
            </Box>
            {listContents.contents.length > 0 ?
                listContents.contents.map((content) => (
                    <Grid
                        key={content._id}
                        display={'flex'}
                        justifyContent={'center'}
                        item lg={3} md={3} sm={4} xs={6}>
                        <ContentItem
                            link={'/membros/conteudo/' + content._id}
                            // eslint-disable-next-line max-len
                            srcImage={images.load(content.capa, 'server')}
                            title={content.titulo}
                            legend={content.legenda}
                            tag={content.categoria ?
                                content.categoria.nome :
                                ''} />
                    </Grid>
                )) :
                feedback !== undefined &&
                <Grid
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    item lg={12} md={12} sm={12} xs={12}>
                    <MessageBox
                        type={feedback.type as any}
                        message={feedback.message}
                    />
                    {!listContents.isLoaded && listContents.contents.length === 0 &&
                        <ButtonInterest
                            text={'Ir para conteúdos'}
                            color={'#fff'}
                            margin={'1.875rem 0 0 0 !important'}
                            backgroundColor={'#010101'}
                            padding={'0.625rem 2.5rem'}
                            onClick={() => navigate('/membros/descobrir')} />
                    }
                </Grid>
            }
            {listContents.isLoaded &&
                <Box sx={{ ...css.sxBoxCircular }}>
                    <CircularProgress size={40} sx={{ ...css.sxCircularProgress }} />
                </Box>
            }
            {!listContents.isLoaded && limit <= listContents.contents.length &&
                <Box sx={{ ...css.sxLoadMoreBox }}>
                    <ButtonInterest
                        text={'CARREGAR MAIS'}
                        margin={'3.125rem 0 0 0'}
                        color={palette.common.white}
                        backgroundColor={palette.dark}
                        padding={'0.5rem 1.5625rem'}
                        onClick={() => setLimit(limit + 8)} />
                </Box>
            }
        </Grid>
    );
};

export { HomeMyContent };
