import { client } from '../../client';

const loadCover = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance
            .get('/banners/cover');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const loadCarroussel = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance
            .get('/banners/carrosseis');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};


export { loadCover, loadCarroussel };
