import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { typography } = useTheme();
    const css = {
        sxBoxTitleForm: {
            paddingTop: matches ? '3.125rem' : '1.5625rem',
            paddingBottom: '0.75rem',
            paddingLeft: matches ? 0 : '0.9375rem',
            paddingRight: matches ? 0 : '0.9375rem',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
        },
        sxTitle: {
            fontSize: matches ? '1.75rem' : '1.125rem',
            fontWeight: typography.fontWeightBold || 700,
            textAlign: 'center',
            padding: '0.625rem',
        },
        sxBoxForm: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        },
        sxBoxGrid: {
            'padding': matches ? '1.875rem 0' : '0.9375rem 0 2.875rem 0',
            'minHeight': matches ? 'none' : '25.3125rem',
        },
    };
    return { css };
};
