import { useMediaQuery, useTheme } from '@mui/material';

export const sx = () => {
    const matches = useMediaQuery('(min-width:600px)');
    const { palette, typography } = useTheme();

    const css = {
        sxBoxSection1: {
            display: 'flex',
            flexDirection: matches ? 'row' : 'column',
            padding: matches ? 0 : '1.875rem 0',
        },
        sxBoxTextsSection1: {
            position: matches ? 'absolute' : 'relative',
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '47.5rem',
            flexDirection: 'column',
            height: '100%',
            maxHeight: matches ? '36.875rem' : 'auto',
        },
        sxTitleSection1: {
            'fontSize': matches ? '2.25rem' : '1.75rem',
            'fontWeight': typography.fontWeightMedium || 500,
            'textAlign': matches ? 'start' : 'center',
            'textTransform': 'uppercase',
            // 'paddingBottom': '1.875rem',
        },
        sxLegendSection1: {
            maxWidth: '34.5rem',
            paddingTop: '0.625rem',
            fontWeight: typography.fontWeightRegular || 400,
            textAlign: matches ? 'start' : 'center',
        },
        sxBoxImageSection1: {
            width: '100%',
            display: 'flex',
            height: '100%',
            maxHeight: matches ? '36.875rem' : 'auto',
            justifyContent: matches ? 'flex-end' : 'normal',
        },
        sxBoxButtonSection: {
            display: 'flex',
            justifyContent: 'center',
            marginTop: '1.25rem',
        },
        sxBoxSectionGray: {
            width: '100%',
            padding: matches ? '3.75rem 0' : '1.875rem 0',
            backgroundColor: palette.clouds,
            display: 'flex',
        },
        sxContainerSection2: {
            display: 'flex',
            flexDirection: matches ? 'row' : 'column-reverse',
        },
        sxBoxImageSection: {
            width: '100%',
            height: '100%',
            maxHeight: matches ? '31.0625rem' : 'auto',
        },
        sxBoxTextsSection2: {
            paddingLeft: matches ? '1rem' : '0',
            maxWidth: '30.75rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        sxTitleSection: {
            textAlign: matches ? 'start' : 'center',
            fontSize: matches ? '2.25rem' : '1.625rem',
            fontWeight: typography.fontWeightMedium || 400,
            textTransform: 'uppercase',
            paddingBottom: '2.5rem',
            maxWidth: '36.875rem',
        },
        sxLegendSection: {
            textAlign: matches ? 'start' : 'center',
            fontWeight: typography.fontWeightRegular || 400,
            fontSize: matches ? '1rem' : '0.875rem',
            padding: matches ? '0.625rem 0 1.875rem 0' : '0.625rem 0 1.25rem 0',
            textTransform: 'lowercase',
        },
        sxContainerSection: {
            display: 'flex',
            alignItems: 'center',
        },
        sxBoxTextsSection3: {
            'width': '100%',
            'maxWidth': matches ? '26.875rem' : 'auto',
            'display': 'flex',
            'flexDirection': 'column',
            'justifyContent': 'center',
            'paddingRight': matches ? '2.25rem' : 0,
            '& > :last-child': {
                '& > :last-child': {
                    display: 'none',
                },
            },
        },
        sxTitleSection3: {
            'textAlign': matches ? 'start' : 'center',
            'fontSize': matches ? '2.25rem' : '1.625rem',
            'paddingBottom': '1.875rem',
            'fontWeight': typography.fontWeightMedium || 500,
            'textTransform': 'uppercase',
        },
        sxBoxNewsLetter: {
            maxWidth: '30.5625rem',
            width: '100%',
            marginLeft: matches ? '1rem' : 0,
        },
    };

    return { css };
};
