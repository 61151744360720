/* eslint-disable camelcase */
/* eslint-disable indent */
/* eslint-disable max-len */
import React, { RefAttributes } from 'react';
import { FormStaticProps, ToggleStaticProps } from '@interfaces';
import {
    Box,
    Button,
    Checkbox,
    Chip,
    Container,
    Grid,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { ForwardRefExoticComponent } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FilterContent } from '../../../../../../components/filters';
import { BoxContent, MenuItem, MuiDialog, SmallTableColumn } from '@components';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { sx } from './sx';
import { useAds } from '@hooks';
import { MeasuresProps } from '@interfaces/Contexts';
import { FormAddMeasures } from '../forms/add-measures';

const STATIC_PROPS: FormStaticProps = {
    onSubmit: () => Boolean,
};

interface FormAddSizeProps {
    measure: string;
}

interface ComponentProps {
    children: React.ReactNode;
}

export const MeasurementsList: ForwardRefExoticComponent<ComponentProps &
    RefAttributes<typeof STATIC_PROPS>> = React.forwardRef((props, ref) => {
        const {
            children,
        } = props;

        const dialogAddMeasures = React.useRef<ToggleStaticProps>(null);
        const addMeasuresRef = React.useRef<FormStaticProps>(null);

        const ads = useAds();
        const { css } = sx();
        const matches = useMediaQuery('(min-width:600px)');

        const [tempList, setTempList] = React.useState<MeasuresProps[]>(
            ads.createNewAd.data.measureTable ?
                ads.createNewAd.data.measureTable.length ?
                    ads.createNewAd.data.measureTable?.reduce((list: MeasuresProps[], item) => {
                        const filter = item.measures.filter((items) => {
                            return items.value && items;
                        }) as MeasuresProps[];
                        return list = filter.map((res) => {
                            return {
                                _id: res._id,
                                nome: res.nome,
                                unidade_medida: res.unidade_medida,
                            };
                        });
                    }, []) as MeasuresProps[] : [] : [],
        );
        const [selectToEdit, setSelectToEdit] = React.useState<string>('');

        const {
            register,
            handleSubmit,
        } =
            useForm<FormAddSizeProps>({
                mode: 'all',
            });

        const { typography, palette } = useTheme();
        const rm = (px: number) => typography.pxToRem(px);

        const reduceContentList = ads.createNewAd.data.measureTable?.reduce((list: MeasuresProps[], item) => {
            const filter = item.measures.filter((items) => {
                return items.value && items;
            }) as MeasuresProps[];
            return list = filter;
        }, []) as MeasuresProps[];

        const handleMeasures = ({ _id, nome, unidade_medida }: MeasuresProps) => {
            const find = tempList.find((item) => item._id === _id);

            if (!find) {
                setTempList((prev) => prev = [...prev, {
                    _id,
                    nome,
                    unidade_medida,
                }]);
            } else {
                const filter = tempList.filter((item) => item._id !== _id);
                setTempList((prev) => prev = [...filter]);
            }
        };

        const onSubmit: SubmitHandler<FormAddSizeProps> = async (data) => {
            ads.createNewAd.insertData({
                type: 'measureTable', data: {
                    measureTable: ads.measures.measuresAd.list,
                },
            });
        };

        const addMeasure = () => {
            handleSubmit(onSubmit);
            ads.measures.addSelectedMeasuresAd({ _id: '9f65e94579da', data: tempList });
            dialogAddMeasures.current?.show();
            setSelectToEdit('');
        };

        const saveMeasure = () => {
            addMeasuresRef.current?.onSubmit();
            dialogAddMeasures.current?.hide();
        };

        const editMeasure = (_id: string) => {
            setSelectToEdit(_id);
            dialogAddMeasures.current?.show();
        };

        React.useImperativeHandle(ref, () => ({
            onSubmit: () => handleSubmit(onSubmit),
        }));

        return (
            <>
                <Box height='100%' component='form' onSubmit={handleSubmit(onSubmit)} pt={2}>
                    <FilterContent minHeightContent={74}>
                        <Container maxWidth='sm'>

                            <Grid container spacing={2}>
                                {ads.ad.measures.isLoaded ? (
                                    ads.ad.measures.data.map(({ _id, nome, unidade_medida }) => {
                                        const find = !!reduceContentList.find((item) => item._id === _id);

                                        return (<Grid key={_id} item xs={4} mb={1}>
                                            <Stack
                                                direction='row'
                                                alignItems='center'
                                                component='label'
                                                htmlFor={_id}
                                                sx={{ opacity: !(ads.measures.measuresAd.list.length > 0) ? 1 : 0.5 }}>
                                                <Checkbox
                                                    disabled={(ads.measures.measuresAd.list.length > 0)}
                                                    id={_id}
                                                    defaultChecked={find}
                                                    {...register('measure',
                                                        { required: false },
                                                    )}
                                                    onChange={() => handleMeasures({ _id, nome, unidade_medida })} />
                                                <Typography
                                                    variant="body1"
                                                    fontSize={rm(14)}
                                                    fontWeight={500}
                                                    color={palette.dark}
                                                    component='p'>
                                                    {nome}
                                                </Typography>
                                            </Stack>
                                        </Grid>
                                        );
                                    })
                                ) : 'Carregando medidas...'}
                            </Grid>
                            {ads.measures.measuresAd.list.map(({ size, measures }) => (
                                <BoxContent
                                    paddingLeft
                                    borderTop
                                    borderBottom
                                    actions={[
                                        {
                                            actionType: 'edit',
                                            actionFunction:
                                                () => editMeasure(size),
                                        },
                                        {
                                            actionType: 'delete',
                                            actionFunction: () => ads.measures.removeMeasuresAd({ _id: size }),
                                        }]} >
                                    <Grid container spacing={2}>
                                        <>{ads.ad.sizes.data.map(({ tamanhos }) => {
                                            const sizes = tamanhos.find(({ _id }) => size === _id);

                                            return (
                                                <Grid item xs={4} mb={1}>
                                                    <SmallTableColumn
                                                        title='Tamanho'
                                                        value={<Chip label={sizes?.nome} size='small' sx={css.content.chip} />} />
                                                </Grid>
                                            );
                                        })[0]}
                                            {measures.map(({ _id, nome, value, unidade_medida }) => (
                                                <Grid key={_id} item xs={4} mb={1}>
                                                    <SmallTableColumn
                                                        title={nome}
                                                        value={
                                                            <Typography
                                                                variant="body1"
                                                                fontSize={rm(16)}
                                                                fontWeight={700}
                                                                color={palette.dark}
                                                                component='p'
                                                                sx={{
                                                                    '&>span': {
                                                                        fontSize: 12,
                                                                    },
                                                                }}>
                                                                {value}<span>{unidade_medida}</span>
                                                            </Typography>
                                                        } />
                                                </Grid>
                                            ))}
                                        </>
                                    </Grid>
                                </BoxContent>
                            ))}
                            <Box pt={2} />
                            <Box sx={{ opacity: tempList.length > 0 ? 1 : 0.5 }}>
                                <MenuItem
                                    borderTop
                                    borderType={'square'}
                                    boxShadow
                                    title={{
                                        text: 'Adicionar medida',
                                        fontWeight: 'bold',
                                        size: 'medium',
                                    }}
                                    icon={{
                                        path: ['fal', 'plus-square'],
                                        size: 'medium',
                                    }}
                                    onClick={() => addMeasure()}
                                    children={
                                        <Box p={2}>
                                            <I icon={['far', 'chevron-right']} />
                                        </Box>
                                    } />
                            </Box>
                        </Container>
                    </FilterContent>
                    {children}
                </Box>
                <MuiDialog
                    maxWidth='sm'
                    fullScreen={!matches}
                    ref={dialogAddMeasures}
                    title='Adicionar medidas'
                    showDialogTitle>
                    <FormAddMeasures ref={addMeasuresRef} editID={selectToEdit}>
                        <Box
                            display='flex'
                            justifyContent='center'
                            mt={2}
                            pb={2}>
                            <Box
                                maxWidth={320}
                                width='100%'
                                justifyContent='center'>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    size="large"
                                    type='submit'
                                    className='MuiButton secondary'
                                    onClick={saveMeasure}
                                >
                                    Salvar medida
                                </Button>
                            </Box>
                        </Box>
                    </FormAddMeasures>
                </MuiDialog>

            </>
        );
    });
