import React from 'react';
import {
    Grid,
    useTheme, Box, CircularProgress,
} from '@mui/material';

import { ContentItem, ButtonInterest, MessageBox } from '@components';

import { contents } from '../../../../api/services/contents';

import { sx } from './sx';
import { ContentProps, ListContentsProps } from '@interfaces';
import { images } from '@utils';

const HomeDiscover: React.FC = () => {
    const { palette } = useTheme();
    const { discover } = contents;
    const [limit, setLimit] = React.useState<number>(8);
    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();
    const [listContents, setListContents] = React.useState<ListContentsProps>({
        isLoaded: true,
        contents: [] as ContentProps[],
    });

    const { css } = sx();

    const loadContents = async ({ limit }: { limit: number }) => {
        setListContents({
            isLoaded: true,
            contents: listContents.contents,
        });
        const response = await discover({
            type: '',
            idCategory: '',
            limit: limit,
        });

        if (!response?.error && response?.response.payload) {
            setListContents({
                isLoaded: false,
                contents: response.response.payload,
            });
        } else {
            setFeedback({
                type: response?.response.type,
                message: response?.response.message,
            });
            setListContents({
                isLoaded: false,
                contents: [] as ContentProps[],
            });
        }
    };

    React.useEffect(() => {
        loadContents({
            limit: limit,
        });
    }, [limit]);

    return (
        <Grid container spacing={2}>
            {listContents.contents.length > 0 ?
                listContents.contents.map((content) => (
                    <Grid
                        key={content._id}
                        display={'flex'}
                        justifyContent={'center'}
                        item lg={3} md={3} sm={4} xs={6}>
                        <ContentItem
                            isFavorite={content.isFavorite}
                            idContent={content._id}
                            link={'/membros/conteudo/' + content._id}
                            srcImage={images.load(content.capa, 'server')}
                            title={content.titulo}
                            legend={content.legenda}
                            tag={content.categoria ?
                                content.categoria.nome :
                                ''} />
                    </Grid>
                )) :
                feedback !== undefined &&
                <Grid
                    display={'flex'}
                    justifyContent={'center'}
                    item lg={12} md={12} sm={12} xs={12}>
                    <MessageBox
                        type={feedback.type as any}
                        message={feedback.message}
                    />
                </Grid>
            }
            {listContents.isLoaded &&
                <Box sx={{ ...css.sxBoxCircular }}>
                    <CircularProgress size={40} sx={{ ...css.sxCircularProgress }} />
                </Box>
            }
            {!listContents.isLoaded && limit <= listContents.contents.length &&
                <Box sx={{ ...css.sxLoadMoreBox }}>
                    <ButtonInterest
                        text={'CARREGAR MAIS'}
                        margin={'3.125rem 0 0 0'}
                        color={palette.common.white}
                        backgroundColor={palette.dark}
                        padding={'0.5rem 1.5625rem'}
                        onClick={() => setLimit(limit + 8)} />
                </Box>
            }
        </Grid>
    );
};

export { HomeDiscover };
