import React from 'react';
import {
    Box,
    Container,
    Grid,
    Skeleton,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import {
    Headline,
    FixedMenu,
    MessageBox,
    AvatarGroup,
    ButtonInterest,
} from '@components';
// import {
//     images,
// } from '@utils';
import UserAvatar from '@img/avatar.png';
import { store } from '../../api/services/store';
import { sx } from './sx';
import { MyStoreProps, ResultStoresProps } from '@interfaces';
import { useLocation, useNavigate } from 'react-router-dom';
import { images } from '@utils';

const ResultsBrands: React.FC = () => {
    const { palette } = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const urlSearch = new URLSearchParams(window.location.search);
    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx();
    const { list } = store;

    const [searchResult, setSearchResult] =
        React.useState<ResultStoresProps>({
            isLoaded: false,
            data: [] as MyStoreProps[],
        });
    const [keySearch, setKeySearch] = React.useState<string>('');
    const [limit, setLimit] = React.useState<number>(8);

    const searchStores = async ({ key }: { key: string }) => {
        setSearchResult((prev) => prev = {
            ...prev,
            isLoaded: true,
        });

        const response = await list({
            key: key,
            limit: limit,
        });

        if (response && !response.error) {
            setSearchResult({
                data: response.response.payload,
                isLoaded: false,
            });
        } else {
            setSearchResult((prev) => prev = {
                data: [] as MyStoreProps[],
                isLoaded: false,
            });
        }
    };

    React.useEffect(() => {
        (async () => {
            if (urlSearch.get('key')) {
                const keySearch = String(urlSearch.get('key')).replace('%20', ' ').
                    replace('+', ' ');
                setKeySearch(keySearch);
                setLimit(8);
                return await searchStores({
                    key: keySearch,
                });
            }
        })();
    }, [location]);

    React.useEffect(() => {
        (async () => {
            if (urlSearch.get('key')) {
                const keySearch = String(urlSearch.get('key')).replace('%20', ' ')
                    .replace('+', ' ');
                setKeySearch(keySearch);
                return await searchStores({
                    key: keySearch,
                });
            }
        })();
    }, [limit]);

    return (
        <Box>
            <Box
                pt={matches ? 4 : 2}
                pb={matches ? 4 : 2}
                sx={{
                    backgroundColor: palette.grey[100],
                }}>
                <Container maxWidth='lg'>
                    {keySearch &&
                        <Headline size={matches ? 'big' : 'medium'}
                            title={
                                `Resultados para
                                    "${keySearch}" em marcas`}
                            paddingBottom />}
                </Container>
            </Box>

            <Container maxWidth='lg' sx={css.products.container}>
                {searchResult.data.length > 0 &&
                    <Grid container spacing={3}>
                        {searchResult.data.map((store) => (
                            <Grid item md={3} xs={6}>
                                <Box onClick={() => {
                                    navigate(`/lojas/${store.minha_loja.url}`);
                                }}>
                                    <AvatarGroup
                                        align={'bottom'}
                                        size={matches ? 'super' : 'extraBig'}
                                        src={
                                            store.imagens &&
                                                store.imagens.avatar ?
                                                images.load(
                                                    store.imagens.avatar, 'server') :
                                                images.load(UserAvatar, 'local')
                                        }
                                        borderWidth={'none'}
                                        title={store.minha_loja.titulo} />
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                }
                {!searchResult.isLoaded && searchResult.data.length === 0 &&
                    <MessageBox
                        type='info'
                        // eslint-disable-next-line max-len
                        message='Não encontramos resultados para sua busca.'
                    />
                }
                {searchResult.isLoaded &&
                    <Grid container spacing={3}>
                        {[0, 1, 2, 3].map((i) => (
                            <Grid
                                key={i}
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                                item md={3} xs={6}>
                                <Skeleton
                                    variant="circular"
                                    width={matches ? 120 : 98}
                                    height={matches ? 120 : 98} />
                                <Skeleton variant="rectangular"
                                    sx={{ mt: 2 }} width={'70%'} height={20} />
                            </Grid>
                        ))}
                    </Grid>
                }
                {!searchResult.isLoaded && limit <= searchResult.data.length &&
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                    }}>
                        <ButtonInterest
                            text={'CARREGAR MAIS'}
                            margin={'1.875rem'}
                            color={palette.common.white}
                            backgroundColor={palette.dark}
                            padding={'0.5rem 1.5625rem'}
                            onClick={() => setLimit(limit + 8)} />
                    </Box>
                }
            </Container >
            {!matches && <FixedMenu />}
        </Box >
    );
};

export { ResultsBrands };
