import { useTheme } from '@mui/material';

const sx = () => {
    const { typography, palette } = useTheme();
    const { pxToRem } = typography;
    const css = {
        row: {
            // borderTopWidth: typography.pxToRem(1),
            borderBottomWidth: typography.pxToRem(1),
            borderStyle: 'solid',
            borderColor: palette.clouds,
            pt: typography.pxToRem(15),
            pb: typography.pxToRem(15),
            pl: 3,
            pr: 3,
        },
        texts: {
            actions: {
                fontSize: typography.pxToRem(12),
                fontWeight: typography.fontWeightBold,
                color: palette.dark,
                pt: typography.pxToRem(10),
                mb: typography.pxToRem(10),
            },
        },
        shipping: {
            icon: {
                position: 'relative',
                top: pxToRem(1),
                mr: pxToRem(4),
            },
            title: {
                fontSize: pxToRem(14),
                fontWeight: typography.fontWeightRegular,
                color: palette.dark,
                display: 'flex',
                ml: pxToRem(10),
            },
            ulList: {
                'listStyle': 'decimal',
                'padding': '0 0.9375rem',
                '& > li': {
                    paddingBottom: '0.625rem !important',
                },
            },
        },
    };

    return { css };
};

export { sx };
