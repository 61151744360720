import { CheckoutPurchaseProps, FindOrderProps } from '@interfaces/Checkout';
import { FakeCheckoutProps } from '@interfaces/Contexts';
import { client } from '../../../client';

const fakePurchase = async ({ products }: FakeCheckoutProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/fake/checkout', {
            products,
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const applyDiscountCoupon = async ({ name }: { name: string }) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put(`/coupon/${name}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const removeDiscountCoupon = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/cart/coupon/remove');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const applyPoints = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/cart/points');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const removePoints = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/cart/points/remove');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const applyWallet = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/cart/wallet');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const removeWallet = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.put('/cart/wallet/remove');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const purchase = async ({ ...purchaseProps }: CheckoutPurchaseProps) => {
    const { instance, handleErrors, handleResponse } = client();
    const props = purchaseProps;

    try {
        if (!props.creditCardId) delete props.creditCardId;
        if (!props.installmentNumber) delete props.installmentNumber;

        const response = await instance.post('/compra', {
            ...props,
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const findOrder = async ({ orderID }: FindOrderProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/order?orderId=' + orderID);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export {
    fakePurchase,
    applyDiscountCoupon,
    removeDiscountCoupon,
    applyPoints,
    removePoints,
    applyWallet,
    removeWallet,
    purchase,
    findOrder,
};
