/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable max-len */
import React from 'react';
import {
    Grid,
    Stack,
    Box,
    Typography,
    Table,
    TableBody,
    useMediaQuery,
    useTheme,
    Container,
    Checkbox,
    Button,
    CircularProgress,
    FormHelperText,
} from '@mui/material';
import { WhatsappShareButton } from 'react-share';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import {
    ButtonLarge,
    Price,
    ButtonIcon,
    DeliveryType,
    AvatarGroup,
    TableList,
    ProductsGrid,
    FixedMenu,
    VariationItem,
    MuiDialog,
    MeasureTable,
    Snackbar,
    Product,
} from '@components';

import {
    AdVariantionsProps,
    CreateSliderProps,
    ListAdsProps,
    MeasurementTableProps,
    ResultAdsProps,
    ToggleStaticProps,
} from '@interfaces';

import UserAvatar from '@img/avatar.png';
import ProductPlaceholder from '@img/product-placeholder.png';
import { ControllQuantity, ProductSlider } from './components';
import { sx } from './sx';
import { Item } from './styles';
import { useAds } from '../../hooks/ads';
import { ads } from '../../api/services/ads';
import { formatNumberToPriceBRL, generateRandomNumber, images } from '@utils';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../hooks/user';
import { ReviewAdBar } from './components/review-ad-bar';
import { skeleton } from './skeleton';
import { useAdsActions } from '../../hooks/ads/utils/actions-ad';
import { useOthers } from '../../hooks/others';
import { useShopping } from '@hooks';
import { useCart } from '../../hooks/shopping';
import { DEFAULTS } from './defaults';

interface ItemProps {
    tamanho: {
        _id: string;
        nome: string;
    };
    valor_por: number;
    quantidade_estoque: number;
};

interface MeasureProps {
    isLoaded: boolean;
    data: MeasurementTableProps[];
}

type KeyUrl = 'variantion' | 'size' | 'quantity' | 'sizeItemID';

const DEFULTS = {
    shortLink: {
        text: 'Copiar Link',
        isLoaded: false,
    },
};

const ProductView: React.FC = () => {
    const adActions = useAdsActions();
    const { categories, product } = useOthers();
    const { cart } = useCart();
    const { user, favorites } = useUser();

    const snackRef = React.useRef<ToggleStaticProps>(null);
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const urlSearch = new URLSearchParams(window.location.search);
    const ad = useAds();
    const measurementChartTable = React.useRef<ToggleStaticProps>(null);
    const shareref = React.useRef<ToggleStaticProps>(null);
    const matches = useMediaQuery('(min-width:600px)');
    const { palette, typography } = useTheme();
    const { css } = sx();
    const sk = skeleton();

    // const [defaultVariantion, setDefaultVariantion] = React.useState<AdVariantionsProps>(
    //     {} as AdVariantionsProps,
    // );
    const [variantionSelected, setVariantionSelected] = React.useState<AdVariantionsProps>(
        {} as AdVariantionsProps,
    );
    const [price, setPrice] = React.useState<number>();
    const [limitQuantity, setLimitQuantity] = React.useState(1);
    const [quantity, setQuantity] = React.useState(1);
    const [isFavorite, setIsFavorite] = React.useState(false);
    const [sizeSelected, setSizeSelected] = React.useState({
        _id: '',
        nome: '',
    });
    const [itemSizeVariationID, setItemSizeVariationID] = React.useState({
        _id: '',
    });
    const [measurementTable, setMeasurementTable] = React.useState<MeasureProps>(
        {} as MeasureProps,
    );
    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();
    const [sellerdAds, setSellerAds] =
        React.useState<ResultAdsProps>({
            isLoaded: false,
            ads: [] as ListAdsProps[],
        });
    const [newADMode, setNewAdMode] = React.useState<boolean>(false);
    const [copyShortLink, setCopyShortLink] = React.useState(DEFULTS.shortLink);
    const [errors, setErrors] = React.useState(DEFAULTS.ERRORS);

    const handleSetURLParams = (key: KeyUrl, value: string | undefined) => {
        if (!value) {
            urlSearch.delete(key);
        } else if (urlSearch.get(key)) {
            urlSearch.set(key, value);
        } else {
            urlSearch.append(key, value);
        }

        const newurl = window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + urlSearch.toString();
        return window.history.pushState({ path: newurl }, '', newurl);
    };

    const handleQuantity = ({ action }: { action: 'add' | 'remove' }) => {
        const actionFuncion = {
            add: () => {
                if (quantity < limitQuantity) {
                    setQuantity(quantity + 1);
                    handleSetURLParams('quantity', String(quantity + 1));
                }
            },
            remove: () => {
                if (quantity > 1) {
                    setQuantity(quantity - 1);
                    handleSetURLParams('quantity', String(quantity - 1));
                } else {
                    handleSetURLParams('quantity', undefined);
                }
            },
        };
        return actionFuncion[action]();
    };

    const handleSizeSelected = ({ _id, nome, itemSizeID }: { _id: string, nome: string, itemSizeID: string }) => {
        if (_id === sizeSelected._id) {
            handleSetURLParams('size', undefined);
            handleSetURLParams('sizeItemID', undefined);
            return setSizeSelected({
                _id: '',
                nome: '',
            });
        }

        handleSetURLParams('size', _id);
        handleSetURLParams('sizeItemID', itemSizeID);
        setItemSizeVariationID((prev) => prev = {
            _id: itemSizeID,
        });
        setErrors((prev) => prev = {
            ...prev,
            size: DEFAULTS.ERRORS.size,
        });
        return setSizeSelected({
            _id: _id,
            nome: nome,
        });
    };

    const loadMeansureTable = async (idAd: string) => {
        setMeasurementTable((prev) => prev = {
            ...prev,
            isLoaded: false,
        });
        const response = await ads.findMeasureTableByAdID({
            idAd: idAd,
        });

        if (!response?.error) {
            setMeasurementTable({
                isLoaded: true,
                data: response?.response.payload,
            });
        } else {
            setMeasurementTable((prev) => prev = {
                ...{} as MeasureProps,
                isLoaded: true,
            });
        }
    };

    const closeMeasurementDialog = () => {
        measurementChartTable.current?.hide();
    };

    const loadSellerAds = async ({ storeUrl }: { storeUrl: string }) => {
        setSellerAds({
            isLoaded: false,
            ads: [] as ListAdsProps[],
        });
        const response = await ad.search({
            query: `&storeUrl=${storeUrl}&=aleatory=${generateRandomNumber()}`,
            searchFor: 'ads',
            limit: 4,
        });

        if (!response?.error) {
            setSellerAds({
                isLoaded: true,
                ads: response?.response.payload.ad,
            });
        } else {
            setSellerAds({
                isLoaded: true,
                ads: [] as ListAdsProps[],
            });
        }
    };

    const formatItem = (
        tamanhos: { _id: string; nome: string }[],
        valorPor: number,
        quantidadeEstoque: number): ItemProps[] => {
        const itens = tamanhos.reduce((list: any, item) => {
            const newList = [...list];
            return [...newList, {
                tamanho: {
                    _id: item._id,
                    nome: item.nome,
                },
                valor_por: valorPor,
                quantidade_estoque: quantidadeEstoque,
            }];
        }, []);

        return itens;
    };

    const addToCart = async ({ redirect }: { redirect?: 'cart' | 'checkout' }) => {
        const findVariationItemID = variantionSelected.itens ?
            variantionSelected.itens.find((item) => (item.tamanho ? item.tamanho._id : item._id) === sizeSelected._id) : '' as any;

        const object = {
            idAd: !(urlSearch.get('variantion')) ? params.id : variantionSelected._id !== params.id ? '' : params.id,
            quantity: quantity,
            // idVariation -> se caso não existir uma variação ja selecionada ele
            // cria uma variação do anuncio principal.
            // por isso do params.id ser igual ao idVariation.
            idVariation: variantionSelected._id,
            variationItemId: findVariationItemID?._id as string,
        };

        if (variantionSelected.itens) {
            if (!(sizeSelected._id)) {
                setErrors((prev) => prev = {
                    ...prev,
                    size: {
                        error: true,
                        message: 'Selecione um tamanho',
                    },
                    noSize: false,
                });
                return;
            }
        };

        const response = await cart.item.add({ ...object });
        if (!response?.error) {
            setFeedback((prev) => prev = {
                type: 'success',
                message: response?.response.message,
            });
            snackRef.current?.show();
            await cart.load();
            if (redirect === 'cart') {
                return navigate('/minha-sacola');
            }
            if (redirect === 'checkout') {
                return navigate('/checkout/endereco');
            }
        } else {
            setFeedback((prev) => prev = {
                type: 'info',
                message: response.response.message,
            });
            snackRef.current?.show();
        }
    };

    const findProduct = async () => {
        if (!urlSearch.get('size')) {
            setSizeSelected({
                _id: '',
                nome: '',
            });
        }
        if (urlSearch.get('quantity')) {
            setQuantity(Number(urlSearch.get('quantity')));
        }
        const productResponse = await ad.find({ id: params.id ? params.id : '' });
        loadMeansureTable(params.id as string);
        if (!productResponse?.error) {
            let itens;

            if (productResponse?.response.payload.tamanho) {
                itens = formatItem(
                    productResponse?.response.payload.tamanho,
                    productResponse?.response.payload.valor_por,
                    productResponse?.response.payload.quantidade_estoque ?
                        productResponse?.response.payload.quantidade_estoque : 0);
            } else {
                setLimitQuantity(productResponse?.response.payload.quantidade_estoque);
                setErrors((prev) => prev = {
                    ...prev,
                    size: DEFAULTS.ERRORS.size,
                    noSize: true,
                });
            }
            setPrice(productResponse?.response.payload.valor_por);
            // setDefaultVariantion({
            //     _id: productResponse?.response.payload._id,
            //     id_anuncio: productResponse?.response.payload._id,
            //     imagem_cor: productResponse?.response.payload.fotos ? productResponse?.response.payload.fotos[0] : ProductPlaceholder,
            //     itens: itens as any,
            //     nome_cor: 'Padrão',
            //     fotos: productResponse?.response.payload.fotos,
            // });
            if (urlSearch.get('size')) {
                const item = itens && itens.find((item: any) => (item.tamanho ? item.tamanho._id : '') === urlSearch.get('size'));
                setSizeSelected(item ? item.tamanho : {
                    _id: '',
                    nome: '',
                });
            }
            const variantionsResponse = await ad.findVariation({ id: params.id ? params.id : '' });
            if (!urlSearch.get('variantion')) {
                setVariantionSelected({
                    _id: productResponse?.response.payload._id,
                    id_anuncio: productResponse?.response.payload._id,
                    imagem_cor: productResponse?.response.payload.fotos ? productResponse?.response.payload.fotos[0] : ProductPlaceholder,
                    itens: itens as any,
                    nome_cor: 'Padrão',
                    fotos: productResponse?.response.payload.fotos,
                });
            } else {
                if (productResponse?.response.payload._id !== urlSearch.get('variantion')) {
                    const variation = variantionsResponse?.response.payload.find((variantion: any) => variantion._id === urlSearch.get('variantion'));

                    setVariantionSelected(variation);
                    if (urlSearch.get('size')) {
                        const item = variation.itens.find((item: any) => item.tamanho._id === urlSearch.get('size'));
                        setSizeSelected(item.tamanho);
                    }
                } else {
                    setVariantionSelected({
                        _id: productResponse?.response.payload._id,
                        id_anuncio: productResponse?.response.payload._id,
                        imagem_cor: productResponse?.response.payload.fotos ? productResponse?.response.payload.fotos[0] : ProductPlaceholder,
                        itens: itens as any,
                        nome_cor: 'Padrão',
                        fotos: productResponse?.response.payload.fotos,
                    });
                }
            }
            if (!params.revisar) {
                await loadSellerAds({
                    storeUrl: productResponse?.response.payload.usuarioInfos[0].minha_loja ?
                        productResponse?.response.payload.usuarioInfos[0].minha_loja.url : '',
                });
                setIsFavorite(productResponse?.response.payload.isFavorite);
            }
        }
    };

    const createSlider = (fotos?: string[]): CreateSliderProps[] => {
        const imagesList = fotos && fotos.length > 0 ?
            fotos.
                reduce((list: any, item) => {
                    const newList = [...list];
                    return [...newList, { image: images.load(item, 'server') }];
                }, []) : [{
                    image: ProductPlaceholder,
                }];

        return imagesList;
    };

    const handleFavorite = async () => {
        const findVariationItemID = variantionSelected.itens ?
            variantionSelected.itens.find((item) => (item.tamanho ? item.tamanho._id : item._id) === sizeSelected._id) : '' as any;

        const response = await favorites.add({
            idAd: ad.ad.listById.data._id,
            idVariation: variantionSelected._id,
            idVariationItem: !!sizeSelected._id ? findVariationItemID._id as string : variantionSelected.itens[0]._id as string,
        });

        if (!response?.error) {
            if (response?.response.message.includes('salvo')) {
                setIsFavorite(true);
            } else {
                setIsFavorite(false);
            }
            await favorites.load({
                limit: 5,
            });
        }
    };

    const handleActionAd = async () => {
        if (params.id && params.revisar) {
            const response = await ads.find({ id: params.id });
            const data = await response?.response.payload;

            const responseMeasureTable = await ads.findMeasureTableByAdID({ idAd: params.id });
            const responseMeasureTableData = await responseMeasureTable?.response.payload;

            await categories.handleCategorization({});

            await adActions.edit(data, responseMeasureTableData);
            navigate('/produtos/editar/' + params.id);
        }
    };

    const activateNewAD = async () => {
        const response = await ads.action({
            type: 'active',
            id: params.id as string,
        });

        if (!response?.error) {
            ad.createNewAd.clearAdData({ clearAll: true });
            ad.createNewAd.insertData({
                type: 'measureTable', data: {
                    measureTable: [],
                },
            });
            ad.measures.clearMeasuresAdData();
            setNewAdMode((prev) => prev = true);
            setTimeout(() => {
                navigate(`/lojas/${ad.ad.listById.data.usuarioInfos[0].minha_loja.url}`);
            }, 1300);
        }
    };

    React.useEffect(() => {
        findProduct();
    }, [location]);

    React.useEffect(() => {
        if (variantionSelected.itens) {
            const index = variantionSelected.itens.findIndex((item) => (item.tamanho ? item.tamanho._id : item._id) === sizeSelected._id);
            setLimitQuantity(variantionSelected.itens[index >= 0 ? index : 0].quantidade_estoque);
            if (!urlSearch.get('quantity')) {
                setQuantity(
                    variantionSelected.itens[index >= 0 ? index : 0].quantidade_estoque > 0 ?
                        1 : 0);
            } else {
                if (variantionSelected.itens[index >= 0 ? index : 0].quantidade_estoque <
                    Number(urlSearch.get('quantity'))) {
                    handleSetURLParams('quantity', String(
                        variantionSelected.itens[index >= 0 ? index : 0].quantidade_estoque));
                    setQuantity(variantionSelected.itens[index >= 0 ? index : 0].quantidade_estoque);
                }
            }
            if (variantionSelected.itens[index >= 0 ? index : 0].valor_por) {
                setPrice(variantionSelected.itens[index >= 0 ? index : 0].valor_por || ad.ad.listById.data.valor_por);
            }
        }
    }, [variantionSelected, sizeSelected]);


    const copyShortLinkToClipboard = async () => {
        setCopyShortLink((prev) => prev = DEFULTS.shortLink);
        const response = await product.generateShortLink({
            type: 'ad',
            idAd: params.id as string,
            urlInfos: {
                long_url: window.location.href,
            },
        });

        if (!response?.error) {
            navigator.clipboard.writeText(response?.response.payload);
            setCopyShortLink((prev) => prev = {
                text: 'Copiado!',
                isLoaded: true,
            });
        }
        setTimeout(() => {
            setCopyShortLink((prev) => prev = DEFULTS.shortLink);
        }, 2000);
    };

    const handleBuyNow = async () => {
        if (limitQuantity === 0) {
            return;
        }

        if ((Object.keys(user).length > 0) && user.isLoaded) {
            !(cart.stores.data.length > 0) ?
                await addToCart({ redirect: 'checkout' }) :
                await addToCart({ redirect: 'cart' });
            return;
        } else {
            await addToCart({ redirect: 'cart' });
            return;
        }
    };

    return (
        <>
            <Snackbar
                ref={snackRef}
                autoHideDuration={2000}
                message={feedback ? feedback.message : ''}
                severity={feedback ? feedback.type as any : 'info'}
            />
            {ad.ad.listById.isLoaded && (
                <Helmet
                    title={ad.ad.listById.data.titulo}
                    meta={[
                        { name: 'twitter:site', content: window.location.href },
                        { name: 'twitter:creator', content: 'mariaz.com.br' },
                        { name: 'twitter:title', content: ad.ad.listById.data.titulo },
                        { name: 'twitter:image', content: createSlider(variantionSelected.fotos)[0].image },
                        { property: 'og:title', content: ad.ad.listById.data.titulo },
                        { property: 'og:site_name', content: 'mariaz.com.br' },
                        { property: 'og:type', content: 'website' },
                        { property: 'og:url', content: window.location.href },
                        { property: 'og:description', content: ad.ad.listById.data.descricao },
                        { property: 'og:image', content: createSlider(variantionSelected.fotos)[0].image },
                        { property: 'og:site_name', content: 'Fishii.shop' },
                    ]} />
            )}

            {params.revisar && (
                <ReviewAdBar
                    edit={handleActionAd}
                    ok={activateNewAD}
                    success={newADMode} />
            )}
            <Container className={matches ? '' : 'no-padding'} maxWidth={'lg'}>
                <Grid container spacing={2}>
                    <Grid item
                        lg={6} sm={6} md={6} xs={12}>
                        {ad.ad.listById.isLoaded ? (
                            <ProductSlider data={createSlider(variantionSelected.fotos)}>
                                {!params.revisar && (
                                    <Box sx={{ ...css.sxBoxIconsButtons }}>
                                        <Stack
                                            direction="row"
                                            justifyContent="flex-end"
                                            alignItems="flex-end"
                                            spacing={1}
                                            sx={{ ...css.sxStackIcons }}>
                                            <ButtonIcon
                                                onClick={() => {
                                                    handleFavorite();
                                                }}
                                                boxShadow
                                                active={isFavorite}
                                                size={'small'}
                                                icon={'heart'} />
                                            <ButtonIcon
                                                boxShadow
                                                onClick={() => addToCart({})}
                                                size={'small'}
                                                icon={'bag-shopping'} />
                                        </Stack>
                                    </Box>
                                )}
                            </ProductSlider>
                        ) : <Box mt={3}>{sk.slider}</Box>}
                    </Grid>
                    <Grid item
                        lg={6} sm={6} md={6} xs={12}>
                        <Box sx={{ ...css.sxBoxGrid2 }}>
                            {!params.revisar && matches &&
                                <Stack
                                    direction="row"
                                    justifyContent="flex-end"
                                    alignItems="flex-end"
                                    spacing={1}
                                    sx={{ ...css.sxBoxIconsButtons2 }}>
                                    <ButtonIcon
                                        tooltip='Compartilhar'
                                        size={'medium'}
                                        icon={'share-nodes'}
                                        onClick={() => shareref.current?.show()} />
                                    <ButtonIcon
                                        tooltip='Favoritar'
                                        onClick={() => {
                                            handleFavorite();
                                        }}
                                        active={isFavorite}
                                        size={'medium'}
                                        icon={'heart'} />
                                    <ButtonIcon
                                        disabled={limitQuantity === 0}
                                        onClick={() => addToCart({})}
                                        tooltip='Adicionar à sacola'
                                        size={'medium'}
                                        icon={'bag-shopping'} />
                                </Stack>
                            }

                            {ad.ad.listById.isLoaded ? (
                                <Stack
                                    spacing={1}>
                                    <Typography
                                        sx={{ ...css.sxProductText }}
                                        variant='h2'>
                                        {ad.ad.listById.data.titulo}
                                    </Typography>
                                    <Price
                                        size={matches ? 'big' : 'medium'}
                                        value={formatNumberToPriceBRL(price as number)}
                                    />
                                    {ad.ad.listById.data.quantidade_dias_disponivel && (
                                        <>
                                            <Typography
                                                sx={{ ...css.sxColorSelected }}>
                                                Prazo para produção
                                            </Typography>
                                            <Typography
                                                variant='body1'
                                                mt={0 + '!important'}
                                                sx={{ ...css.productionTime }}>
                                                {ad.
                                                    ad.
                                                    listById.
                                                    data.
                                                    quantidade_dias_disponivel.length > 1 ?
                                                    '1 Dia' :
                                                    ad.
                                                        ad.
                                                        listById.
                                                        data.
                                                        quantidade_dias_disponivel + ' Dias'}
                                            </Typography>
                                        </>
                                    )}
                                </Stack>
                            ) : (
                                <Stack spacing={1}>
                                    {sk.info.map((el) => el)}
                                </Stack>
                            )}
                            {ad.ad.listAdVariationsById.data.length > 0 &&
                                <Box sx={{ ...css.sxBoxColors }}>
                                    <Stack
                                        sx={{ paddingBottom: '0.625rem' }}
                                        spacing={1} direction={'row'}>
                                        <Typography
                                            variant='h3'
                                            sx={{ ...css.sxColorTitle }}>
                                            Cor:</Typography>
                                        <Typography
                                            variant='body1'
                                            sx={{ ...css.sxColorSelected }}>
                                            {variantionSelected.nome_cor}</Typography>
                                    </Stack>
                                    <Stack spacing={matches ? 2 : 1} direction={'row'}>
                                        <>
                                            {/* {defaultVariantion &&
                                                <VariationItem
                                                    onClick={() => {
                                                        handleSetURLParams('variantion', defaultVariantion._id);
                                                        setVariantionSelected(defaultVariantion);
                                                    }}
                                                    size={matches ? 'medium' : 'small'}
                                                    checkedRadio={defaultVariantion._id === variantionSelected._id}
                                                    image={images.load(defaultVariantion.imagem_cor, 'server')} />} */}
                                            {ad.ad.listAdVariationsById.data.map((variation) => (
                                                <VariationItem
                                                    key={variation._id}
                                                    onClick={() => {
                                                        handleSetURLParams('variantion', variation._id);
                                                        setVariantionSelected(variation);
                                                    }}
                                                    size={matches ? 'medium' : 'small'}
                                                    checkedRadio={variantionSelected._id === variation._id}
                                                    image={images.load(variation.fotos ? variation.fotos[0] : ProductPlaceholder, 'server')} />
                                            ))}
                                        </>
                                    </Stack>
                                </Box>
                            }
                            <Box sx={{ ...css.sxBoxColors }}>
                                {variantionSelected.itens &&
                                    <Typography sx={{ ...css.sxSizesTitle }}>
                                        Tamanhos
                                    </Typography>
                                }
                                {variantionSelected.itens ? (
                                    <Grid container spacing={2}>
                                        {variantionSelected.itens.map((item) => (
                                            item.tamanho ?
                                                <Grid key={item.tamanho._id} item xs={item.tamanho.nome.length > 3 ? 3 : 2}>
                                                    <Item
                                                        onClick={() => handleSizeSelected({
                                                            _id: item.tamanho._id,
                                                            nome: item.tamanho.nome,
                                                            itemSizeID: item._id as string,
                                                        })}
                                                        className={item.tamanho._id === sizeSelected._id ? 'active' : ''}>
                                                        <label htmlFor={''}>
                                                            {item.tamanho.nome}
                                                            <Checkbox
                                                                id={item.tamanho._id}
                                                                size='small'
                                                                value={item.tamanho.nome}
                                                                checked={false}
                                                            />
                                                        </label>
                                                    </Item>
                                                </Grid> :
                                                <Grid key={item._id} item xs={item.nome ? item.nome?.length > 6 ? 4 : 2 : 2}>
                                                    <Item
                                                        onClick={() => handleSizeSelected({
                                                            _id: item._id as string,
                                                            nome: item.nome as string,
                                                            itemSizeID: item._id as string,
                                                        })}
                                                        className={item._id === sizeSelected._id ? 'active' : ''}>
                                                        <label htmlFor={''}>
                                                            {item.nome}
                                                            <Checkbox
                                                                id={item._id}
                                                                size='small'
                                                                value={item.nome}
                                                                checked={false}
                                                            />
                                                        </label>
                                                    </Item>
                                                </Grid>
                                        ))}
                                    </Grid>
                                ) : null}
                                {errors.size.error && (
                                    <FormHelperText error>
                                        {errors.size.message}
                                    </FormHelperText>
                                )}
                            </Box>
                            {measurementTable.isLoaded ? measurementTable.data.length > 0 && (
                                <Box sx={{ marginTop: '20px' }}>
                                    <Button
                                        onClick={() => measurementChartTable.current?.show()}
                                        sx={{ ...css.sxMeasurementButton }}>
                                        Tabela de medidas
                                        <I
                                            style={{ marginLeft: '0.375rem' }}
                                            icon={['far', 'ruler-combined']} />
                                    </Button>
                                </Box>
                            ) : (<Box mt={2}>{sk.measurement}</Box>)}
                            {ad.ad.listById.isLoaded ? (
                                <Box sx={css.sxBoxDeliveryInfo}>
                                    <Box>
                                        <Typography
                                            sx={{ ...css.sxDeliveryText }}
                                            variant='caption'>
                                            Opções de frete disponíveis
                                        </Typography>
                                    </Box>
                                    <Box maxWidth={88}>
                                        <DeliveryType type={'correios'} />
                                    </Box>
                                </Box>
                            ) : (<Stack spacing={1} mt={2}>{sk.shipping.map((el) => el)}</Stack>)}
                            {!params.revisar && (
                                ad.ad.listById.isLoaded ?
                                    <Box sx={{ paddingBottom: '24px' }}>
                                        {limitQuantity > 0 ? variantionSelected._id &&
                                            <>
                                                <Typography
                                                    sx={{ ...css.sxDeliveryText }}
                                                    variant='caption'>
                                                    Quantidade <br />
                                                </Typography>
                                                <ControllQuantity
                                                    value={quantity}
                                                    limit={limitQuantity}
                                                    remove={() => handleQuantity({
                                                        action: 'remove',
                                                    })}
                                                    add={() => handleQuantity({
                                                        action: 'add',
                                                    })} />
                                            </> :
                                            <Typography
                                                sx={{ ...css.sxDeliveryText, color: palette.pomegarnate }}
                                                variant='caption'>
                                                <I
                                                    style={{ marginRight: '5px' }}
                                                    icon={['far', 'circle-info']} />
                                                Sem estoque
                                            </Typography>
                                        }
                                    </Box> : (
                                        <Stack spacing={1} mt={2}>
                                            {sk.quantity.map((el) => el)}
                                        </Stack>
                                    )
                            )}
                            {!params.revisar && (
                                ad.ad.listById.isLoaded ?
                                    <Stack
                                        spacing={1}
                                        sx={{ ...css.sxStackLargeButtons }}>
                                        <ButtonLarge
                                            disabled={limitQuantity === 0}
                                            backgroundColor=
                                            {palette.pomegarnate}
                                            color={palette.common.white}
                                            text={'COMPRAR AGORA'}
                                            onClick={() => handleBuyNow()} />
                                    </Stack> : (
                                        <Box mt={2}>
                                            {sk.buyBtn}
                                        </Box>
                                    )
                            )}
                        </Box>
                    </Grid>
                    <Grid item
                        lg={6} sm={6} md={6} xs={12}
                        sx={{ ...css.sxGridPadding }}>
                        <Box sx={{ ...css.sxBoxPaddingDescription }}>
                            {ad.ad.listById.isLoaded ? (
                                <Box sx={{ ...css.sxBoxDescription }}>
                                    <Typography
                                        variant='h3'
                                        sx={{ ...css.sxTitleGrid }}>
                                        Descrição
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        sx={{ ...css.sxDescriptionText }}>
                                        {ad.ad.listById.data.descricao}
                                    </Typography>
                                </Box>
                            ) : (
                                <Box>
                                    {sk.description.title}
                                    <Stack spacing={1} mt={2}>
                                        {sk.description.info.map((el) => el)}
                                    </Stack>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                    {ad.ad.listById.isLoaded ? (
                        <Grid item
                            lg={6} sm={6} md={6} xs={12}
                            sx={{ ...css.sxGridPadding }}>
                            <Box sx={{ ...css.sxBoxPaddingAdvertiser }}>
                                <Box sx={{ ...css.sxBoxAdvertiser }}>
                                    <Typography
                                        variant='h3'
                                        sx={{ ...css.sxTitleGrid }}>
                                        Marca
                                    </Typography>
                                    <Box onClick={() => {
                                        ad.ad.listById.data.usuarioInfos[0].minha_loja ?
                                            navigate(`/lojas/${ad.ad.listById.data.usuarioInfos[0].minha_loja.url}`) :
                                            null;
                                    }}>
                                        <AvatarGroup
                                            align={'right'}
                                            size={'medium'}
                                            src={
                                                ad.ad.listById.data.usuarioInfos[0].imagens &&
                                                    ad.ad.listById.data.usuarioInfos[0].imagens.avatar ?
                                                    images.load(ad.ad.listById.data.usuarioInfos[0].imagens.avatar, 'server') :
                                                    images.load(UserAvatar, 'local')
                                            }
                                            borderWidth={'none'}
                                            title={
                                                ad.ad.listById.data.usuarioInfos[0].minha_loja ?
                                                    ad.ad.listById.data.usuarioInfos[0].minha_loja.titulo :
                                                    ad.ad.listById.data.usuarioInfos[0].nome}
                                            legend={ad.ad.listById.data.usuarioInfos[0].endereco[0] ?
                                                `${ad.ad.listById.data.usuarioInfos[0].endereco[0].cidade} - ${ad.ad.listById.data.usuarioInfos[0].endereco[0].estado}` :
                                                ''} />
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>) : (
                        <Box mt={2}>
                            {sk.seller.title}
                            <Stack direction='row' alignItems='center' spacing={1} mt={1}>
                                {sk.seller.info.map((el) => el)}
                            </Stack>
                        </Box>
                    )}

                    {ad.ad.listById.isLoaded ? (
                        <Grid item
                            lg={6} sm={12} md={6} xs={12}>
                            <Box sx={{ ...css.sxCharacteristicsBox }}>
                                <Typography
                                    variant='h3'
                                    sx={{ ...css.sxCharacteristicsTitle }}>
                                    Caracteríscticas
                                </Typography>
                                <Table>
                                    <TableBody>
                                        {ad.ad.listById.data.categorizacao &&
                                            <TableList
                                                align={'right'}
                                                keyTab={'Categoria/Subcategoria'}
                                                value={ad.ad.listById.data.
                                                    categorizacao.map((category, i, arr) => arr.length !== i + 1 ? [category.nome, <I
                                                        icon={['far', 'chevron-right']}
                                                        fontSize={10}
                                                        style={{ marginLeft: 5, marginRight: 5 }} />] : category.nome)}
                                                noPaddingSide={matches} />
                                        }
                                        {ad.ad.listById.data.composicao &&
                                            <TableList
                                                align={'right'}
                                                keyTab={'Composição'}
                                                value={ad.ad.listById.data.composicao}
                                                noPaddingSide={matches} />
                                        }
                                        {ad.ad.listById.data.outros &&
                                            <TableList
                                                align={'right'}
                                                keyTab={'Outros'}
                                                value={ad.ad.listById.data.outros}
                                                noPaddingSide={matches} />
                                        }
                                    </TableBody>
                                </Table>
                            </Box>
                        </Grid>
                    ) : null}
                </Grid>
            </Container>
            {!params.revisar && sellerdAds.isLoaded &&
                sellerdAds.ads && sellerdAds.ads.length > 0 && (
                    <Container maxWidth={'lg'}>
                        <Typography
                            variant='h2'
                            sx={{ pt: 2, pb: 2 }}
                            fontSize={matches ? '1.75rem' : '1.125rem'}
                            fontWeight={typography.fontWeightMedium}>
                            Mais produtos da marca
                        </Typography>
                        <Grid container spacing={3}>
                            {sellerdAds.ads.map((ad) => {
                                return (
                                    <Grid key={ad._id} item
                                        lg={3} md={3} sm={6} xs={6}>
                                        <Product
                                            key={ad._id}
                                            stockQuantity={ad.variacoes.itens[0].quantidade_estoque}
                                            favorite={ad.isFavorite}
                                            _id={ad.variacoes._id}
                                            _idAd={ad._id}
                                            itemVariationId={ad.variacoes.itens[0]._id}
                                            mode='buy'
                                            size={'fluid'}
                                            align={'bottom'}
                                            src={images.load(
                                                ad.variacoes.fotos[0], 'server')}
                                            title={ad.titulo}
                                            price={ad.variacoes.itens[0].valor_por as any}
                                            // eslint-disable-next-line max-len
                                            to={() => navigate('/produto/' + ad._id + '?variantion=' + ad.variacoes._id)} />
                                    </Grid>
                                );
                            })}
                        </Grid>
                        <Box pb={3} />
                    </Container>
                )}
            <MuiDialog
                maxWidth='xs'
                fullScreen={!matches}
                ref={shareref}
                title='Compartilhar'
                showDialogTitle>
                <Box p={3}>
                    <Stack direction='column' spacing={3}>
                        <WhatsappShareButton url={window.location.href}>
                            <Stack direction='row' alignItems='center' spacing={2}>
                                <I
                                    icon={['fab', 'whatsapp']}
                                    fontSize={30}
                                    color={css.share.copyToClipboard.color} />
                                <Typography
                                    variant='h3'
                                    sx={css.sxColorSelected}>
                                    Whatsapp
                                </Typography>
                            </Stack>
                        </WhatsappShareButton>
                        <Box>
                            <Button
                                type='button'
                                onClick={copyShortLinkToClipboard}
                                disabled={copyShortLink.isLoaded}
                                sx={{
                                    'pl': 0,
                                    'opacity': !copyShortLink.isLoaded ? 1 : 0.8,
                                    ':hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}>
                                <Stack direction='row' alignItems='center' spacing={2}>
                                    <I
                                        icon={['far', 'link']}
                                        fontSize={18}
                                        color={css.share.copyToClipboard.color} />
                                    <Typography
                                        variant='h3'
                                        sx={{
                                            ...css.sxColorSelected,
                                            ...css.share.copyToClipboard,
                                        }}>
                                        {copyShortLink.text}
                                    </Typography>
                                </Stack>
                            </Button>
                        </Box>

                    </Stack>
                </Box>
            </MuiDialog>
            <MuiDialog
                maxWidth='md'
                fullScreen={!matches}
                ref={measurementChartTable}
                contentPadding={false}
                onCancel={{
                    action: () => closeMeasurementDialog(),
                }}
                title='Tabela de medidas'
                showDialogTitle>
                {measurementTable.isLoaded ?
                    <MeasureTable data={measurementTable.data} /> :
                    <Box sx={{ ...css.sxBoxCircularTable }}>
                        <CircularProgress size={40} sx={{ ...css.sxCircularProgress }} />
                    </Box>
                }
            </MuiDialog>
            {
                !matches &&
                <FixedMenu />
            }
        </>
    );
};

export { ProductView };
