import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {
    Box,
    Container,
    Menu,
    useMediaQuery,
} from '@mui/material';
import { sx } from './sx';
import { ButtonFilterMobile } from '../filter/buttons/select-filter/mobile';
import { FilterWrapper } from '../mobile/filter-wrapper';
import { FilterHead } from '../mobile/filter-head';
import { FilterContent } from '../filter/layout/content';
import { MenuItem } from '@components';
import { ButtonFilterDesktop } from '../filter/buttons/select-filter/desktop';
import {
    DesktopFiltersProps,
    FiltersProductsListProps,
    MobileFiltersProps,
} from '@interfaces';
import { useFilters } from '@hooks';
import { FiltersType } from '@types';
import { useNavigate } from 'react-router-dom';

interface FiltersProps {
    filters: {
        mobile: MobileFiltersProps;
        desktop: DesktopFiltersProps;
        filtersList: FiltersProductsListProps[];
    };
};

const Product = ({ filters }: FiltersProps) => {
    const filter = useFilters();
    const navigate = useNavigate();

    const matches = useMediaQuery('(min-width:600px)');
    const { css } = sx();

    const setURLParams = (key: FiltersType, value: string | undefined) => {
        const search = filter.handleSetURLParams(key, value);
        navigate({ search });
    };

    const removeAllFilters = () => {
        for (let i = 0; i < filter.filtersTypeList.length; i++) {
            filter.add({ type: filter.filtersTypeList[i], data: [] });
            setURLParams(filter.filtersTypeList[i], undefined);
        }
    };

    return (
        <React.Fragment>
            {/* Mobile Filters */}
            {!matches && (
                <React.Fragment>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        sx={css.selectFilterBarButtons}>
                        <ButtonFilterMobile
                            text='Ordenar por'
                            icon={['far', 'chevron-down']}
                            onClick={() => filters.mobile.handleVisibilityMobileFilters({
                                action: 'open',
                                type: 'onlyOrderBy',
                            })} />
                        <ButtonFilterMobile
                            text='Filtros'
                            icon={['far', 'sliders']}
                            onClick={() => filters.mobile.handleVisibilityMobileFilters({
                                action: 'open',
                                type: 'all',
                            })}
                        />
                    </Box>
                    {filters.mobile.visibilityMobileFilters && (
                        <FilterWrapper>
                            <FilterHead
                                presentation={!Object.keys(filters.mobile.
                                    selectedFilter).
                                    length ?
                                    'clearFilters' :
                                    'insideFilter'}
                                activeFilterName={filters.mobile.selectedFilter.name}
                                actionClose={() => filters.mobile.
                                    handleVisibilityMobileFilters({
                                        action: 'close',
                                        type: 'all',
                                    })}
                                actionBackTo={filters.mobile.handleResetSelectedFilters}
                                onClearAllFilters={removeAllFilters}
                            />
                            {!Object.keys(filters.mobile.selectedFilter).length && (
                                <FilterContent>
                                    {filters.filtersList.map((filter) => {
                                        if (filters.mobile.handleMobileFilterCondition({
                                            cond: filters.mobile.mobileFilteringFilters,
                                            filter: filter,
                                        })) return;

                                        if (filter.enabled) {
                                            return (
                                                <MenuItem
                                                    key={filter.id}
                                                    onClick={() => filters.mobile.
                                                        handleSelectedFilter({
                                                            filter: filter,
                                                        })}
                                                    borderType={'bottom'}
                                                    title={{
                                                        text: filter.name,
                                                        size: 'small',
                                                    }}
                                                    legend={{
                                                        text: filter.historyFilters,
                                                        fontWeight: 'bold',
                                                        size: 'tiny',
                                                    }}>
                                                    <Box pr={2}>
                                                        <I
                                                            icon={['far',
                                                                'chevron-right']}
                                                        />
                                                    </Box>
                                                </MenuItem>
                                            );
                                        }
                                    })}

                                    {filters.filtersList.map((filter) => {
                                        if (filters.mobile.handleMobileFilterCondition({
                                            cond: filters.mobile.mobileFilteringFilters,
                                            filter: filter,
                                        })) return;
                                        if (!filter.enabled) {
                                            return (
                                                <MenuItem
                                                    key={filter.id}
                                                    onClick={() => filters.mobile.
                                                        handleSelectedFilter({
                                                            filter: filter,
                                                        })}
                                                    borderType={'bottom'}
                                                    title={{
                                                        text: filter.name,
                                                        size: 'small',
                                                    }}
                                                    legend={{
                                                        text: filter.historyFilters,
                                                        fontWeight: 'bold',
                                                        size: 'tiny',
                                                    }}>
                                                    <Box pr={2}>
                                                        <I
                                                            icon={['far',
                                                                'chevron-right']}
                                                        />
                                                    </Box>
                                                </MenuItem>
                                            );
                                        }
                                    })}

                                </FilterContent>
                            )}
                            {filters.mobile.selectedFilter.filterContent}
                        </FilterWrapper>
                    )}
                </React.Fragment>
            )}

            {/* Desktop Filters */}
            <Container maxWidth='lg'>
                {matches && (
                    <React.Fragment>
                        <Box
                            position='relative'
                            display='flex'
                            pt={1.5}
                            pb={1.5}
                            sx={{ overflowY: 'auto' }}
                        >
                            <Box display='flex' minWidth={908}
                                sx={css.filters.desktop.buttonSpace}>
                                {filters.filtersList.sort((a, b) =>
                                    (a.order as number) - (b.order as number))
                                    .map((filter) => (
                                        <ButtonFilterDesktop
                                            key={filter.id}
                                            filter={filter}
                                            active={filter.active}
                                            currentFilterOpened={
                                                filters.mobile.selectedFilter}
                                            isOpened={filters.desktop.open}
                                            onClick={({ event, filter }) =>
                                                filters.desktop.showFilter(event, filter)}
                                        />
                                    ))}
                            </Box>
                        </Box>

                        <Menu
                            id="basic-menu"
                            anchorEl={filters.desktop.anchorEl}
                            open={filters.desktop.open}
                            onClose={filters.desktop.hideFilter}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <Box maxWidth={280} minWidth={280} width='100%'>
                                {filters.mobile.selectedFilter.filterContent}
                            </Box>
                        </Menu>
                    </React.Fragment>
                )}
            </Container >
        </React.Fragment>

    );
};

export { Product };
