/* eslint-disable max-len */
import React from 'react';
import { Box, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { CollectionsSliderItemProps, ToggleStaticProps } from '@interfaces';

import { Image } from './styles';
import { sx } from './sx';
import { Link } from 'react-router-dom';
import { contents } from '../../../../api/services/contents';
import { Snackbar } from '@components';

const ContentItem: React.FC<CollectionsSliderItemProps> = ({
    link, srcImage, title, legend, tag, slider, isFavorite, idContent,
}: CollectionsSliderItemProps) => {
    const { css } = sx();
    const matches = useMediaQuery('(max-width:600px)');
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const [feedback, setFeedback] = React.useState<{message: string, type: string}>();
    const [favoriteChecked, setFavoriteChecked] = React.useState<boolean>(!!isFavorite);

    const handleSaveButton = async () => {
        const response = await contents.save({
            idContent: idContent as string,
        });
        snackRef.current?.show();
        setFeedback({
            type: response?.response.type,
            message: response?.response.payload,
        });
        setFavoriteChecked(!favoriteChecked);
    };

    return (
        <Box
            style={{ ...css.sxLink[slider ? 'slider' : 'default'] }}>
            <Link to={link}>
                <Box sx={{ ...css.sxBoxImage }}>
                    <Image src={srcImage} alt={title} />
                    {tag &&
                        <Typography
                            variant='caption'
                            sx={{ ...css.sxTag }}>
                            {tag}
                        </Typography>
                    }
                </Box>
            </Link>
            <Box sx={{ ...css.sxBoxText }}>
                <Link
                    style={{
                        width: idContent ? 'calc(100% - 40px)' : '100%',
                        textDecoration: 'none' }}
                    to={link}>
                    <Stack
                        spacing={0.3}>
                        <Typography
                            variant='h1'
                            fontSize={matches ? '0.75rem' : '1rem'}
                            fontWeight={500}
                            sx={{ color: '#010101', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {title}
                        </Typography>
                        <Typography
                            variant='body1'
                            fontSize={matches ? '0.625rem' : '0.8125rem'}
                            fontWeight={400}
                            sx={{ color: '#010101', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {legend}
                        </Typography>
                    </Stack>
                </Link>
                {idContent &&
                    <IconButton
                        onClick={() => handleSaveButton()}
                        sx={{ ...css.sxIconButton }}>
                        <I icon={[favoriteChecked ? 'fas' : 'fal', 'heart']} />
                    </IconButton>
                }
            </Box>
            <Snackbar
                ref={snackRef}
                autoHideDuration={1000}
                message={feedback? feedback.message: ''}
                severity={feedback? feedback.type as any: 'info'}
            />
        </Box>
    );
};

export { ContentItem };
