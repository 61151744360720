import React from 'react';
import Box from '@mui/material/Box';
import { ProductImgSliderProps } from '@interfaces';

import { sx } from './sx';
import { Image } from './styles';

const ProductImageSlider: React.FC<ProductImgSliderProps> = ({
    src, children,
}: ProductImgSliderProps) => {
    const { sxBox, sxBoxImage } = sx();
    return (
        <Box sx={{
            ...sxBox,
        }}>
            <Box sx={{
                ...sxBoxImage,
            }}>
                <Image src={src} alt="" />
                {children}
            </Box>
        </Box>
    );
};

export { ProductImageSlider };
