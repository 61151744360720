import React from 'react';
import { Box, Button, Step, StepIconProps, StepLabel, Stepper } from '@mui/material';
import { ColorlibConnector, ColorlibStepIconRoot } from './styles';

function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className } = props;

    const icons: { [index: string]: string } = {
        1: '1',
        2: '2',
        3: '3',
    };

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

interface WizardProps {
    steps: string[];
    activeStep: number;
    completed: {
        [k: number]: boolean;
    };
    handleBack: () => void;
    handleNext: () => void;
    children: React.ReactNode;
}

const Wizard = ({
    steps,
    activeStep,
    completed,
    handleBack,
    handleNext,
    children,
}: WizardProps) => {
    return (
        <React.Fragment>
            <Stepper
                activeStep={activeStep}
                connector={<ColorlibConnector />}>
                {steps.map((label, index) => {
                    return (
                        <Step
                            key={label}
                            completed={completed[index]}
                            sx={{
                                padding: 0,
                            }}
                        >
                            <StepLabel
                                StepIconComponent={ColorlibStepIcon}
                                sx={{
                                    padding: 0,
                                }} />
                        </Step>
                    );
                })}
            </Stepper>

            {children}

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={handleNext} sx={{ mr: 1 }}>
                    Next
                </Button>
            </Box>
        </React.Fragment>
    );
};

export { Wizard };
