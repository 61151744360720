import React from 'react';
import {
    Box,
    Checkbox,
    Typography,
} from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { DeliveryTypeProps } from '@interfaces';

import { sx } from './sx';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const DeliveryType: React.FC<DeliveryTypeProps> = (props: DeliveryTypeProps) => {
    const { css } = sx();
    const { type, error, checkbox } = props;

    const deliveryIcon = {
        correios: ['fal', 'truck'] as IconProp,
    };

    return (
        <Box
            sx={{
                ...css.sxBox(error as boolean),
                cursor: checkbox ? 'pointer' : 'default',
            }}
            component={checkbox ?
                'label' :
                'div'}
            htmlFor='delivery'>
            {checkbox && (
                <Checkbox
                    id='delivery'
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                    }}
                    {...checkbox}
                />
            )}
            <Box sx={checkbox ? {
            } : css.sxBoxIcon}>
                <I
                    fontSize={checkbox ? 18 : 14}
                    icon={deliveryIcon[type]} />
            </Box>
            <Typography
                variant='body1'
                sx={{ ...css.sxTypeText }} >
                {type}
            </Typography>
        </Box>
    );
};

export { DeliveryType };
