/* eslint-disable max-len */
/* eslint-disable indent */
import { Box, Typography, useTheme, alpha } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { CartListStoresProps } from '@interfaces/Cart';

interface ShippingTimeProps {
    days: number;
    item: CartListStoresProps;
}

const ShippingTime: React.FC<ShippingTimeProps> = (
    { days, item }: ShippingTimeProps) => {
    const { palette, typography } = useTheme();

    const onlyTrueListElems = (list: any[]) => {
        return list.filter((el) => el !== undefined);
    };

    const productionTimeList = onlyTrueListElems(item.itens.map((product) => product.id.quantidade_dias_disponivel ?
        product.id.quantidade_dias_disponivel : undefined));

    const productionTime = productionTimeList.length > 0 ? Math.max(...productionTimeList) : undefined;

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'baseline',
            backgroundColor: alpha(palette.clouds, 0.3),
            pb: 1,
        }}>
            <Box sx={{
                height: typography.pxToRem(55),
                width: typography.pxToRem(45),
                display: 'flex',
                alignItems: 'baseline',
            }}>
                <Box sx={{
                    pt: typography.pxToRem(6),
                    pl: typography.pxToRem(15),
                    position: 'relative',
                    top: typography.pxToRem(1),
                }}>
                    <I icon={['far', 'truck']} />
                </Box>
            </Box>
            <Box>
                <Box mb={0.5}>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        color={palette.dark}>
                        Prazo de envio:
                    </Typography>
                    <Typography
                        variant="body2"
                        fontWeight="medium"
                        color={palette.darkGrey}>
                        {productionTime ?
                            productionTime :
                            3} dias úteis para <strong>{productionTime ?
                                'produção' :
                                'envio'}</strong>
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        variant="body2"
                        fontWeight="medium"
                        color={palette.darkGrey}>
                        +{days > 1 ?
                            days + ' dias úteis para entrega.' :
                            ['1 dia útil para entrega.']}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export { ShippingTime };
