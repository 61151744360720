export const sx = () => {
    const css = {
        sxBox: {
            height: '100%',
        },
        sxBoxHistory: {
            display: 'flex',
            backgroundColor: '#f9f9f9',
            overflow: 'auto',
            paddingRight: '0.9375rem',
        },
        sxIconNext: {
            fontSize: '1rem',
        },
    };

    return { ...css };
};
