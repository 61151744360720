import React from 'react';
import {
    Box, CircularProgress, Container, FormControl, FormControlLabel,
    Grid,
    Radio, RadioGroup, Typography,
} from '@mui/material';
import { sx } from './sx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Form } from './styles';
import { ButtonLarge, Snackbar } from '@components';
import { useAuth } from '../../../hooks/auth';
import { ToggleStaticProps } from '@interfaces';
import { useNavigate, useParams } from 'react-router-dom';
import { useUser } from '../../../hooks/user';

interface FormSendCodeToProps {
    sendto: 'email' | 'sms';
};

const ChooseShippingMethod: React.FC = () => {
    const { css } = sx();
    const navigate = useNavigate();
    const params = useParams();
    const { clearResponseCreate } = useUser();
    const auth = useAuth();
    const snackRef = React.useRef<ToggleStaticProps>(null);
    const [isLoading, setIsLoading] = React.useState(false);
    const [feedback, setFeedback] = React.useState<{ message: string, type: string }>();

    const {
        register,
        handleSubmit,
        // setValue,
        formState: { },
    } = useForm<FormSendCodeToProps>({
        mode: 'onBlur',
    });

    // React.useEffect(() => {
    //     setValue('sendForm', 'email');
    // }, []);

    const onSubmit: SubmitHandler<FormSendCodeToProps> = async (data) => {
        setIsLoading(true);
        const { sendto } = data;

        const email = !!(sendto === 'email');
        const sms = !!(sendto === 'sms');

        const response = await auth.resendValidation(email, sms);
        if (response?.error) {
            setFeedback({
                message: response?.response
                    .message, type: response?.response.type,
            });
            snackRef.current?.show();
            setIsLoading(false);
            return;
        }
        setFeedback({
            message: response?.response
                .message, type: response?.response.type,
        });
        snackRef.current?.show();

        setTimeout(() => {
            clearResponseCreate();
            navigate('/codigo-seguranca/' + sendto);
        }, 1500);
    };


    return (
        <Container maxWidth={'xs'}
            sx={{ ...css.sxContainer }}>
            <Box sx={{ ...css.sxBoxAccess }}>
                <Typography
                    variant='h2'
                    textAlign={'center'}
                    sx={{ ...css.sxAccessText }}
                >
                    Enviar código de confirmação
                </Typography>
                <Typography
                    variant='caption'
                    textAlign={'center'}
                    sx={{ ...css.sxTypeText }}
                >
                    Por onde deseja receber seu código de confirmação de conta?
                </Typography>
            </Box>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Grid item xs={12} mb={1}>
                    <FormControl>
                        <RadioGroup
                            aria-labelledby="account-type"
                            defaultValue="email"
                            name="radio-buttons-group"
                        // row
                        >
                            <FormControlLabel
                                value="email"
                                control={<Radio
                                    size='small'
                                    {...register('sendto')}
                                />}
                                label="E-mail" />
                            {/* {responseCreateUser && responseCreateUser.telefone && */}
                            {params.to === 'sms-email' && (
                                <FormControlLabel
                                    value="sms"
                                    control={<Radio
                                        size='small'
                                        {...register('sendto')}
                                    />}
                                    label="SMS"
                                />
                            )}
                            {/* } */}
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <ButtonLarge
                    {...css.sxButtonLarge}
                    type={'submit'}
                    text={'Enviar'}
                    onClick={() => null}>
                    {isLoading &&
                        <CircularProgress
                            size={14}
                            color='inherit'
                            sx={{ ml: 0.5 }} />
                    }
                </ButtonLarge>
            </Form>

            <Snackbar
                ref={snackRef}
                message={feedback ? feedback.message : ''}
                severity={feedback ? feedback.type as any : 'success'}
            />
        </Container>
    );
};

export { ChooseShippingMethod };
