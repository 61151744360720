import { FindStoresProps, StoreFormProps } from '@interfaces';
import { client } from '../../client';


const createStore = async ({ ...store }: StoreFormProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.post('/store', {
            ...store,
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const findStores = async ({ key, limit }: FindStoresProps) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance
            .get(`/store/find?search=${key}&limit=${limit ? limit : 8}`);
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};


export { createStore, findStores };
