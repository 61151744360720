import { UpdateCreditCardProps } from '@interfaces';
import { client } from '../../client';

const updateCreditCard = async ({
    cardInfos, creditCardId }: UpdateCreditCardProps) => {
    const { instance, handleErrors, handleResponse } = client();
    try {
        if (creditCardId || cardInfos._id) {
            if (!!cardInfos.endereco) {
                delete cardInfos.id_endereco;
            }
            const response = await instance.put('/userPaymentMethod', {
                cardInfos, creditCardId,
            });
            return handleResponse(response);
        } else {
            const response = await instance.post('/userPaymentMethod', {
                cardInfos,
            });
            return handleResponse(response);
        }
    } catch (error: any) {
        return handleErrors(error);
    }
};

const listPaymentsMethods = async () => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.get('/userPaymentMethod');
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

const deleteCreditCard = async ({ cardId }: {cardId: string}) => {
    const { instance, handleErrors, handleResponse } = client();

    try {
        const response = await instance.delete('/userPaymentMethod', {
            data: {
                cardId: cardId,
            },
        });
        return handleResponse(response);
    } catch (error: any) {
        return handleErrors(error);
    }
};

export {
    listPaymentsMethods,
    updateCreditCard,
    deleteCreditCard,
};
