import React from 'react';
import {
    Box,
    CircularProgress,
    Container,
    Tab,
    Tabs,
    Typography,
    useMediaQuery } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';

import { homeBaseTabs } from '@static/home-base-tabs';
import { sx } from './sx';
import { useUser } from '@hooks';
import { FixedMenu } from '@components';

const HomeBase: React.FC = () => {
    const { css } = sx();
    const matches = useMediaQuery('(min-width:600px)');
    const navigate = useNavigate();
    const { user } = useUser();

    const handleActiveTab = () => {
        switch (location.pathname) {
        case '/membros/descobrir':
            return 0;
        case '/membros/meus-conteudos':
            return 1;
        default:
            return 0;
        }
    };

    return (
        <Container
            className={matches ? '' : 'no-padding'}
            sx={{ ...css.sxContainer }}>
            {user.isLoaded ? user.data?.cnpj ?
                <Navigate to={'not-permission'} /> :
                <>
                    <Box sx={{ ...css.sxBoxTitle }}>
                        <Typography
                            sx={{ ...css.sxTitle }}
                            variant='h1'>
                        CONTEÚDOS EXCLUSIVOS
                        </Typography>
                        <Typography
                            variant='caption'
                            sx={{ ...css.sxLegend }}>
                            por e para mulheres despertas, autênticas e revolucionárias
                        </Typography>
                    </Box>
                    <Box>
                        <Tabs
                            value={handleActiveTab()}
                            aria-label="basic tabs example"
                            sx={{ ...css.sxTabs }}>
                            {homeBaseTabs.map(({ label, path, icon }) => (
                                <Tab
                                    key={label}
                                    label={
                                        <Typography sx={{ ...css.sxTabText }}>
                                            {label}
                                            <I icon={icon} />
                                        </Typography>
                                    }
                                    onClick={() => navigate(path)} />
                            ))}
                        </Tabs>
                    </Box>
                    <Box sx={{ ...css.sxBoxOutlet }}>
                        <Outlet />
                    </Box>
                </> :
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    height={355}>
                    <CircularProgress color="inherit" size={26} />
                </Box> }
            {!matches && <FixedMenu />}
        </Container>
    );
};

export { HomeBase };
