import React from 'react';
import Slider from 'react-slick';
import {
    Box,
    useMediaQuery,
} from '@mui/material';

import {
    ProductImageSlider,
} from '@components';
import { ProductSliderProps } from '@interfaces';

import { settings } from './slider-settings';
import { sx } from './sx';

const ProductSlider: React.FC<ProductSliderProps> = ({
    data, children,
}: ProductSliderProps) => {
    const matches = useMediaQuery('(min-width:600px)');
    const { sxBox } = sx();
    const settingsDesk = {
        ...settings,
        dotsClass: 'slick-dots slick-thumb',
        customPaging: function(i: number) {
            return (
                <a>
                    <img src={data[i] && data[i].image} />
                </a>
            );
        },
    };

    const handleSettings = matches ? settingsDesk : settings;

    return (
        <Box sx={{ ...sxBox }}>
            <Slider
                { ...handleSettings }
                className={'product-pictures'}>
                {data.map(({ image }, i) => (
                    <ProductImageSlider
                        key={`${image}-${i}`}
                        src={image} />
                ))}
            </Slider>
            {!matches && children}
        </Box>
    );
};

export { ProductSlider };
