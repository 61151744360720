import { useTheme } from '@mui/material';

export const sx = () => {
    const { palette, typography } = useTheme();
    const css = {
        sxBox: {
            display: 'flex',
            flexDirection: 'column',
        },
        sxTitleSection: {
            marginBottom: '0.5rem',
        },
        sxLink: {
            'textDecoration': 'none',
            'color': palette.dark,
            'fontSize': '1rem',
            'fontWeight': typography.fontWeightRegular,
            'marginBottom': '0.375rem',
        },
        sxIcon: {
            fontSize: '1.75rem',
            width: '1.75rem',
            cursor: 'pointer',
        },
        sxTitle: {
            'textTransform': 'lowercase',
            'cursor': 'pointer',
            ':hover': {
                textDecoration: 'underline',
            },
        },
    };
    return {
        ...css,
    };
};
