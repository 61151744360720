import React from 'react';
import { UserContext } from './context';
import { userProvider } from '../../hooks/user';

interface UserProviderProps {
    children: React.ReactNode;
};

const UserProvider = ({ children }: UserProviderProps) => {
    const user = userProvider();

    return (
        <UserContext.Provider value={user}>
            {children}
        </UserContext.Provider>
    );
};

export {
    UserProvider,
};
