import {
    AdStateProps,
    FilterAddProps,
    FilterDataProps,
    FilterStateProps,
    ParseURLParamsListProps,
    ParseURLParamsObjectProps,
    ParseURLParamsProps,
    ParseURLParamsResponseProps,
    SizesListProps,
} from '@interfaces/Contexts';
import React from 'react';
import { FiltersContext } from '../../contexts/filters/context';
import queryString from 'query-string';
import { FiltersType } from '@types';

const DEFAULTS = {
    filters: {
        category: [] as FilterDataProps[],
        size: [] as FilterDataProps[],
        brand: [] as FilterDataProps[],
        locations: [] as FilterDataProps[],
        orderby: [] as FilterDataProps[],
        price: [] as FilterDataProps[],
    },
};

const filtersHookProvider = () => {
    const [filters,
        setFilters] = React.useState<FilterStateProps>(DEFAULTS.filters);
    const [tempFilters, setTempFilters] = React.useState<FilterStateProps>(() => {
        const storagedFilters = localStorage.getItem('@filters:search');
        if (storagedFilters) {
            return JSON.parse(storagedFilters);
        }
        return DEFAULTS.filters;
    });

    const [filtersDataLenght, setFiltersDataLenght] = React.useState<number>(0);
    const filtersTypeList: FiltersType[] = [
        'brand',
        'category',
        'locations',
        'orderby',
        'price',
        'size'];

    const filtersListQuery2 = {
        brand: 'brand',
        category: 'category',
        locations: '',
        orderby: '',
        price: '',
        size: 'size',
    };

    const prevCartRef = React.useRef<FilterStateProps>();

    React.useEffect(() => {
        prevCartRef.current = tempFilters;
    });

    const tempFilterPrevValue = prevCartRef.current ?? tempFilters;

    React.useEffect(() => {
        if (tempFilterPrevValue !== tempFilters) {
            localStorage.setItem('@filters:search', JSON.stringify(tempFilters));
        }
        checkFiltersDataLenght();
    }, [tempFilters, tempFilterPrevValue]);

    const add = ({ type, data }: FilterAddProps) => {
        setFilters((prev) => {
            return {
                ...prev,
                [type]: data,
            };
        });
        setTempFilters((prev) => prev = {
            ...prev,
            [type]: data,
        });
    };

    const clearAllFiltersData = () => {
        localStorage.removeItem('@filters:search');
        setTempFilters((prev) => prev = DEFAULTS.filters);
        setFilters((prev) => prev = DEFAULTS.filters);
    };

    const handleSetURLParams = (
        key: string, value: string | undefined, type?: 'single') => {
        const urlParams = new URLSearchParams(location.search);

        if (!value) {
            urlParams.delete(key);
        } else if (type === 'single' && urlParams.get(key)) {
            urlParams.set(key, value);
        } else {
            urlParams.append(key, value);
        }
        return urlParams.toString();
    };

    // const reduceQueryString = (data: FilterDataProps[]) => {
    //     const reduce = data.reduce((list: any, prev) => {
    //         const newList = [...list];
    //         return [...newList, prev._id];
    //     }, []);
    //     return reduce as string[];
    // };

    const formatQuery = (data: FilterDataProps[]) => {
        const array = data.map((item) => {
            return [
                filtersListQuery2[item.filter as FiltersType],
                item._id+','+item.nome,
            ];
        });

        return array;
    };

    const handleURLParams = async (data: Partial<FilterStateProps>,
        type: FiltersType) => {
        let response = {
            params: [['', '']],
            error: false,
            existData: false,
        };

        try {
            if (data) {
                const formatTest = formatQuery(data[type] as FilterDataProps[]);

                return response = {
                    params: formatTest,
                    error: false,
                    existData: true,
                };
            }
        } catch (error) {
            return response = {
                params: [['', '']],
                error: true,
                existData: false,
            };
        } finally {
            return response;
        }
    };

    const onlyTrueListElems = (list: any[]) => {
        return list.filter((el) => el !== undefined);
    };

    const parseURLParams = async ({ searchParams }: ParseURLParamsProps):
    Promise<ParseURLParamsResponseProps> => {
        let response: ParseURLParamsResponseProps = {
            data: [],
            message: '',
            error: false,
        };

        try {
            const arr = [];
            const paramKeys = [
                'category', 'size', 'brand', 'orderby', 'pricemin',
                'pricemax', 'state', 'city', 'tag', 'aleatory', 'key',
                'showcaseId', 'quickLinkId',
            ];
            for (let i = 0; i < paramKeys.length; i++) {
                const allParams = searchParams.getAll(paramKeys[i]);
                // VERIFICAR AQUI SE OS PARAMETROS RECEBIDOS
                // COINCIDEM COM OS PARAMETROS DA LISTA
                arr.push({
                    [paramKeys[i]]: allParams,
                });
            }
            const map = arr.map((param, i) => {
                if (param[paramKeys[i]].length > 0) {
                    const str = param[paramKeys[i]].reduce((list: any, item) => {
                        const newList = [...list];
                        const [value, name] = item.split(',');
                        if ([paramKeys[i]]) {
                            const result = [...newList, {
                                value,
                                name,
                                filterName: paramKeys[i],
                            }];
                            return result;
                        }
                    }, []);
                    return str;
                }
            });

            const onlyTrueList = onlyTrueListElems(map) as ParseURLParamsObjectProps[];

            const result =
            onlyTrueList.reduce((list: ParseURLParamsObjectProps[], item) => {
                return list = [...list, ...item as any];
            }, []) as ParseURLParamsListProps[];

            return response = {
                data: result,
                error: false,
                message: 'Parâmetros da url convertidos para uma lista de objetos.',
            };
        } catch (error: any) {
            return response = {
                data: [],
                error: true,
                message: error.message,
            };
        } finally {
            return response;
        }
    };

    const parseQueryStringURL = async (
        query: string, type: FiltersType,
        ad: AdStateProps, filter?: FilterDataProps[]) => {
        let response = {
            data: [] as FilterDataProps[],
        };

        try {
            const parse = queryString.parse(query,
                { arrayFormat: 'comma' });
            const exceptArrays = (type !== 'category' &&
                type !== 'orderby' &&
                type !== 'locations' &&
                type !== 'price');

            if (!(typeof parse[type] === 'string')) {
                if (type === 'locations' && !!(parse[type] as [])) {
                    const map = (parse[type] as []).map((id) => {
                        const find = filter?.find(((item: SizesListProps) => item.
                            _id === id));
                        return find;
                    }) as FilterDataProps[];

                    const onlyTrueElems = onlyTrueListElems(map);

                    add({ type: 'locations', data: onlyTrueElems });
                    return response = {
                        data: onlyTrueElems,
                    };
                }

                if (type === 'price' && !!(parse[type] as [])) {
                    const map = (parse[type] as []).map((id) => {
                        const find = filter?.find(((item: SizesListProps) => item.
                            _id === id));

                        return find;
                    }) as FilterDataProps[];
                    const onlyTrueElems = onlyTrueListElems(map);

                    add({ type: 'price', data: onlyTrueElems });
                    return response = {
                        data: onlyTrueElems,
                    };
                }

                if (type === 'size') {
                    const reduceSizes = ad.sizes.data.reduce((list: any, item) => {
                        return list = [...list, ...item.tamanhos];
                    }, []);

                    const map = (parse[type] as []).map((id) => {
                        const find = reduceSizes.find(((item: SizesListProps) => item.
                            _id === id));
                        return find;
                    }) as FilterDataProps[];
                    const onlyTrueElems = onlyTrueListElems(map);

                    add({ type: 'sizes', data: onlyTrueElems });
                    return response = {
                        data: onlyTrueElems,
                    };
                } else {
                    if (type === 'category') {
                        const map = (parse[type] as []).map((id) => {
                            const find = filter?.find(((item: SizesListProps) => item.
                                _id === id));
                            return find;
                        }) as FilterDataProps[];
                        const onlyTrueElems = onlyTrueListElems(map);

                        add({ type: type, data: onlyTrueElems });
                        return response = {
                            data: onlyTrueElems,
                        };
                    }

                    const map = (parse[type] as []).map((id) => {
                        const find = exceptArrays ?
                            ad[type].data.find(((item) => item.
                                _id === id)) : [];
                        return find;
                    }) as FilterDataProps[];
                    const onlyTrueElems = onlyTrueListElems(map);

                    add({ type: type, data: onlyTrueElems });

                    return response = {
                        data: onlyTrueElems,
                    };
                };
            } else {
                if (type === 'locations') {
                    const filterLocations = filter?.filter(((item) => item.
                        _id === (parse[type] as string))) as FilterDataProps[];
                    const onlyTrueElems = onlyTrueListElems(filterLocations);

                    add({ type: 'locations', data: onlyTrueElems });
                    return response = {
                        data: onlyTrueElems,
                    };
                }

                if (type === 'orderby') {
                    const filterPrice = filter?.filter(((item) => item.
                        _id === (parse[type] as string))) as FilterDataProps[];
                    const onlyTrueElems = onlyTrueListElems(filterPrice);

                    add({ type: 'orderby', data: onlyTrueElems });
                    return response = {
                        data: onlyTrueElems,
                    };
                }
                if (type === 'size') {
                    const reduceSizes = ad.sizes.data.reduce((list: any, item) => {
                        return list = [...list, ...item.tamanhos];
                    }, []);
                    const filterSizes = exceptArrays ?
                        reduceSizes.filter(((item: SizesListProps) => item.
                            _id === parse[type])) : [];

                    add({ type: 'sizes', data: filterSizes });
                    return response = {
                        data: filterSizes,
                    };
                } else {
                    if (type === 'category') {
                        const filterCategories = filter?.filter(((item) => item.
                            _id === (parse[type] as string))) as FilterDataProps[];
                        const onlyTrueElems = onlyTrueListElems(filterCategories);

                        add({ type: type, data: onlyTrueElems });
                        return response = {
                            data: onlyTrueElems,
                        };
                    }

                    const filterParams = exceptArrays ?
                        ad[type].data.filter(((item) => item.
                            _id === parse[type])) : [];

                    add({ type: type, data: filterParams });

                    return response = {
                        data: filterParams,
                    };
                }
            };
        } catch (error) {
            return response = {
                data: [],
            };
        } finally {
            return response;
        }
    };

    const filterQuery = (data: ParseURLParamsObjectProps[]) => {
        let query = '';

        const keyQuery = data.map((item) => {
            if (item.filterName === 'key') {
                return item.value;
            };
        });

        if (onlyTrueListElems(keyQuery).length > 0) {
            query += '&key='+onlyTrueListElems(keyQuery)[0];
        }


        const categoryQuery = data.map((item) => {
            if (item.filterName === 'category') {
                return item.value;
            };
        });

        if (onlyTrueListElems(categoryQuery).length > 0) {
            query += '&categoryList='+onlyTrueListElems(categoryQuery).join(',');
        }

        const brandQuery = data.map((item) => {
            if (item.filterName === 'brand') {
                return item.value;
            };
        });

        if (onlyTrueListElems(brandQuery).length > 0) {
            query += '&brand='+onlyTrueListElems(brandQuery).join(',');
        }

        const priceMinQuery = data.map((item) => {
            if (item.filterName === 'pricemin') {
                return item.value;
            };
        });

        const priceMaxQuery = data.map((item) => {
            if (item.filterName === 'pricemax') {
                return item.value;
            };
        });

        if (onlyTrueListElems(priceMinQuery).length > 0 &&
            onlyTrueListElems(priceMaxQuery).length > 0) {
            query +=
                '&points='+onlyTrueListElems(priceMinQuery)[0]+
                '-'+onlyTrueListElems(priceMaxQuery)[0];
        }

        const stateQuery = data.map((item) => {
            if (item.filterName === 'state') {
                return item.name;
            };
        });

        if (onlyTrueListElems(stateQuery).length > 0) {
            query += '&state='+onlyTrueListElems(stateQuery)[0];
        }

        const cityQuery = data.map((item) => {
            if (item.filterName === 'city') {
                return item.name;
            };
        });

        if (onlyTrueListElems(cityQuery).length > 0) {
            query += '&city='+onlyTrueListElems(cityQuery).join(',');
        }

        const sizeQuery = data.map((item) => {
            if (item.filterName === 'size') {
                return item.value;
            };
        });

        if (onlyTrueListElems(sizeQuery).length > 0) {
            query += '&size='+onlyTrueListElems(sizeQuery).join(',');
        }

        const orderByQuery = data.map((item) => {
            if (item.filterName === 'orderby') {
                return item.value;
            };
        });

        if (onlyTrueListElems(orderByQuery).length > 0) {
            query += '&price='+onlyTrueListElems(orderByQuery)[0];
        }

        const tagQuery = data.map((item) => {
            if (item.filterName === 'tag') {
                return item.value;
            };
        });

        if (onlyTrueListElems(tagQuery).length > 0) {
            query += '&tag='+onlyTrueListElems(tagQuery)[0];
        }

        const showcaseQuery = data.map((item) => {
            if (item.filterName === 'showcaseId') {
                return item.value;
            };
        });

        if (onlyTrueListElems(showcaseQuery).length > 0) {
            query += '&showcaseId='+onlyTrueListElems(showcaseQuery)[0];
        }

        const quicklinkQuery = data.map((item) => {
            if (item.filterName === 'quickLinkId') {
                return item.value;
            };
        });

        if (onlyTrueListElems(quicklinkQuery).length > 0) {
            query += '&quickLinkId='+onlyTrueListElems(quicklinkQuery)[0];
        }

        const aleatoryQuery = data.map((item) => {
            if (item.filterName === 'aleatory') {
                return item.value;
            };
        });

        if (onlyTrueListElems(aleatoryQuery).length > 0) {
            query += '&aleatory='+onlyTrueListElems(aleatoryQuery)[0];
        }


        return query;
    };

    const checkFiltersDataLenght = () => {
        const lenght = (tempFilters.brand?.length as any) +
            tempFilters.category?.length +
            tempFilters.price.length +
            tempFilters.locations.length +
            tempFilters.size.length +
            tempFilters.orderby.length;

        setFiltersDataLenght(lenght);
    };

    return {
        filters,
        tempFilters,
        url: {
            parseURLParams,
        },
        add,
        handleURLParams,
        handleSetURLParams,
        parseQueryStringURL,
        filtersTypeList,
        filterQuery,
        clearAllFiltersData,
        filtersDataLenght,
        // reset,
    };
};

const useFilters = () => {
    const context = React.useContext(FiltersContext);
    return context;
};

export { useFilters, filtersHookProvider };
