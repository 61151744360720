import { OnlyImageProps } from '@interfaces';
import { Wrapper } from './styles';

const Image = ({ maxHeight, src, alt }: OnlyImageProps) => {
    return (
        <Wrapper maxHeight={maxHeight}>
            <div className="WrapAnchorPicture">
                <div className="AnchorPicture">
                    <div className="WrapPicture">
                        <picture>
                            <img
                                src={src}
                                alt={alt}
                            />
                        </picture>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
};

export { Image };
