/* eslint-disable indent */
import { client } from '../../api/client';
import { HighlightedAdsProps, WebsiteSearchDomainType } from '@interfaces';
import React from 'react';
import {
    AdActionProps,
    AdBrandsProps,
    AdFindProps,
    AdImagesProps,
    AdListProps,
    AdStateProps,
    CreateNewAdCreateProps,
    LoadSizesProps,
    MeasuresAdProps,
    NewAdClearAdData,
    // LoadSizesByCategoryProps,
    NewAdInsertDataProps,
    MeasuresProps,
    NewAdProps,
    NewAdRemoveData,
    SearchProps,
    MeasuresTableProps,
    AdVariationsProps,
    AddVariationsToCreateProps,
    CreateStateVariationsImagesProps,
    AdVariationsColorsProps,
    CreateAdVariation,
    AdColorsProps,
    UpdateAdVariation,
    FindMeasureTableByAdIDProps,
    DeleteVariationProps,
    HighlightedProps,
    ExternalDiloagActionProps,
    // UpdateAdProps,
} from '@interfaces/Contexts';
import { DEFAULTS, responseLists } from './defaults';
import { DropResult } from 'react-beautiful-dnd';
import { AdsContext } from '../../contexts/ads/context';
// import { useUser } from '../user';
import { ImagesActionType, NewAdDataTypes } from '@types';
import { images } from '@utils';

const adsHookProvider = () => {
    const CONSTANTS = {
        searchLimit: 8,
        website: 'shopping' as WebsiteSearchDomainType,
    };

    // const { user } = useUser();

    const [ad, setAd] = React.useState<AdStateProps>(DEFAULTS.ad);
    const [adImages, setAdImages] = React.useState<AdImagesProps[]>([]);
    const [newAd, setNewAd] = React.useState<NewAdProps>(DEFAULTS.newAd);
    const [measuresAd,
        setMeasuresAd] = React.useState<MeasuresAdProps>(DEFAULTS.measures);
    const [adVariations,
        setAdVariations] = React.
            useState<AdVariationsProps>(DEFAULTS.variations);
    const [adVariationsImages,
        setAdVariationsImages] = React.
            useState<CreateStateVariationsImagesProps>(DEFAULTS.variations.images);
    const [adVariationsColor,
        setAdVariationsColor] = React.
            useState<AdVariationsColorsProps>(DEFAULTS.variations.colors);
    const [adVariationsCustomColor,
        setAdVariationsCustomColor] = React.
            useState<AdVariationsColorsProps>(DEFAULTS.variations.colors);
    const [actionOnExternalComponent,
        setActionOnExternalComponent] = React.useState({
            variationDialog: {
                state: false,
            },
            sizeDialog: {
                state: false,
            },
            colorsDialog: {
                state: false,
            },
        });
    const [highlightedAds,
        setHighlightedAds] = React.useState<HighlightedProps>(DEFAULTS.highlighted);

    const externalDialogAction = (
        { action, state }: ExternalDiloagActionProps) => {
        setActionOnExternalComponent((prev) => prev = {
            ...prev,
            [action]: {
                state,
            },
        });
    };

    const resetIsLoaded = () => {
        setAd((prev) => prev = {
            ...prev,
            weights: responseLists,
            measures: responseLists,
        });
    };

    const findVariationByID = async ({ id }: AdFindProps) => {
        const { services } = client();

        setAd((prev) => prev = {
            ...prev,
            variationsById: {
                ...DEFAULTS.ad.variationsById,
            },
        });

        const response = await services.ads.variations.findByID({ id });
        setAd((prev) => prev = {
            ...prev,
            variationsById: {
                data: response?.response.payload,
                message: response?.response.message,
                isLoaded: true,
            },
        });

        return response;
    };

    const resetVariationsItemsData = () => {
        setAdVariations((prev) => prev = {
            ...prev,
            items: {
                currentSizeID: '',
                list: [],
                response: {
                    error: false,
                    message: '',
                },
            },
        });
    };

    const addVariationsToCreate = ({
        section,
        type,
        variants,
    }: AddVariationsToCreateProps) => {
        const [sizeID] = variants.items.currentSizeID.split('-');
        if (type === 'add' && section === 'size') {
            setAdVariations((prev) => prev = {
                ...prev,
                items: {
                    currentSizeID: sizeID,
                    list: [...prev.items.list, ...variants.items.list],
                    response: {
                        error: false,
                        message: 'Variação de tamanho ADICIONADO.',
                    },
                },
            });
            return;
        };

        if (type === 'edit' && section === 'size') {
            const find = adVariations.items.list
                .find((item) => (item._id === sizeID));

            if (!find) {
                setAdVariations((prev) => prev = {
                    ...prev,
                    items: {
                        currentSizeID: sizeID,
                        list: [...prev.items.list, ...variants.items.list],
                        response: {
                            error: false,
                            message: 'Variação de tamanho ADICIONADO.',
                        },
                    },
                });
            } else {
                const map = adVariations.items.list.map((item) => {
                    if (item.itemListID === variants.items.list[0].itemListID) {
                        const obj = {
                            ...item,
                            nome: variants.items.list[0].nome,
                            itemListID: variants.items.list[0].itemListID,
                            price: variants.items.list[0].price,
                            size: variants.items.list[0].size,
                            sizeName: variants.items.list[0].sizeName,
                            sku: variants.items.list[0].sku,
                            stockQuantity: variants.items.list[0].stockQuantity,
                        };

                        return obj;
                    }
                    return item;
                });

                setAdVariations((prev) => prev = {
                    ...prev,
                    items: {
                        currentSizeID: '',
                        list: map,
                        response: {
                            error: true,
                            message: 'Variação de tamanho ALTERADO.',
                        },
                    },
                });
            }
        }
    };

    const addVariantColor = ({ _id, imagem, nome, customColor }: AdColorsProps) => {
        setAdVariationsColor((prev) => prev = {
            color: {
                _id,
                imagem,
                nome,
                customColor,
            },
        });
    };

    const addVariantCustomColor = ({ _id, imagem, nome, customColor }: AdColorsProps) => {
        setAdVariationsCustomColor((prev) => prev = {
            color: {
                _id,
                imagem,
                nome,
                customColor,
            },
        });
    };

    const removeVariationListItem = ({ _id }:
        { _id: string }) => {
        const filter = adVariations.
            items.list.filter((item) => item.itemListID !== _id);
        setAdVariations((prev) => prev = {
            ...prev,
            items: {
                currentSizeID: '',
                list: filter,
                response: {
                    error: false,
                    message: '',
                },
            },
        });
    };

    const addSelectedMeasuresAd = ({ _id, data }:
        { _id: string; data: MeasuresProps[] }) => {
        setMeasuresAd((prev) => prev = {
            ...prev,
            listSelected: {
                _id: _id,
                data: data,
            },
        });
    };

    const removeMeasuresAd = ({ _id }:
        { _id: string }) => {
        const filter = measuresAd.list.filter((item) => item.size !== _id);
        setMeasuresAd((prev) => prev = {
            ...prev,
            list: [...filter],
        });
    };

    const clearMeasuresAdData = () => {
        setMeasuresAd((prev) => prev = DEFAULTS.measures);
    };

    const addMeasuresAdList = (data: MeasuresTableProps[]) => {
        setMeasuresAd((prev) => prev = {
            ...prev,
            list: data,
        });
    };

    const addMeasuresAd = ({ _id, oldID, data }:
        { _id: string; oldID?: string; data: MeasuresTableProps[] }) => {
        const find = measuresAd.list.find((item) => item.size === _id);

        const findOld = measuresAd.list.find((item) => item.size === oldID);

        if (findOld) {
            const filter = measuresAd.list.map((item) => {
                if (item.size === oldID) {
                    return {
                        ...item,
                        size: data[0].size,
                        measures: data[0].measures,
                    };
                }
                return item;
            });
            setMeasuresAd((prev) => prev = {
                ...prev,
                list: [...filter],
            });
            return;
        }
        if (!find) {
            setMeasuresAd((prev) => prev = {
                ...prev,
                list: [...prev.list, ...data],
            });
        } else {
            const filter = measuresAd.list.map((item) => {
                if (item.size === _id) {
                    return {
                        ...item,
                        size: data[0].size,
                        measures: data[0].measures,
                    };
                }
                return item;
            });
            setMeasuresAd((prev) => prev = {
                ...prev,
                list: [...filter],
            });
        }
    };

    const checkIsThereNewAdData = () => {
        const keys = [
            'title',
            'description',
            'quantity_days_available',
            'price',
            'weight',
            'deliveryTypes',
            'measureTable',
            'brand',
            'condition',
            'size',
            'category',
            'categoryHistory',
        ] as NewAdDataTypes[];
        let count = 0;
        for (let i = 0; keys.length > i; i++) {
            if (newAd) {
                const list = newAd[keys[i]];
                if (typeof list === 'object' && Array.isArray(list)) {
                    if (list.length > 0) {
                        count += list.length;
                    }
                }
            }
        };

        selectImagesToEdit('ad', []);
        selectImagesToEdit('variation', []);
        selectImagesToEdit('variation-color', []);
        selectImagesToEdit('variation-custom-color', []);

        setNewAd((prev) => prev = {
            ...prev,
            isThereData: !!(count),
        });
    };

    const search = async ({
        query, searchFor, limit,
    }: SearchProps) => {
        const { services } = client();
        if (searchFor === 'ads') {
            const response = await services.ads.search({
                limit: limit ? limit : CONSTANTS.searchLimit,
                website: CONSTANTS.website,
                query: query,
            });
            return response;
        }
        if (searchFor === 'brands') {
            return null;
        }
    };

    const list = async ({ type }: AdListProps) => {
        const { services } = client();

        // setAd((prev) => prev = DEFAULTS.ad);
        setAd((prev) => prev = {
            ...prev,
            list: {
                ...DEFAULTS.ad.list,
            },
        });

        const response = await services.ads.list({ type: type });

        setAd((prev) => prev = {
            ...prev,
            list: {
                data: response?.response.payload,
                message: response?.response.message,
                isLoaded: true,
            },
        });

        return response;
    };

    const find = async ({ id }: AdFindProps) => {
        const { services } = client();

        setAd((prev) => prev = {
            ...prev,
            listById: { ...DEFAULTS.ad.listById },
        });

        const response = await services.ads.find({ id });

        setAd((prev) => prev = {
            ...prev,
            listById: {
                data: response?.response.payload,
                message: response?.response.message,
                isLoaded: true,
            },
        });

        return response;
    };

    const findVariation = async ({ id }: AdFindProps) => {
        const { services } = client();

        setAd((prev) => prev = {
            ...prev,
            listAdVariationsById: {
                ...DEFAULTS.ad.listAdVariationsById,
            },
        });

        const response = await services.ads.variations.find.byAdID({ id });
        setAd((prev) => prev = {
            ...prev,
            listAdVariationsById: {
                data: response?.response.payload,
                message: response?.response.message,
                isLoaded: true,
            },
        });

        return response;
    };

    const loadMeasureTypes = async () => {
        const { services } = client();

        setAd((prev) => prev = {
            ...prev,
            measures: {
                ...DEFAULTS.ad.measures,
            },
        });

        const response = await services.ads.load.measure();
        setAd((prev) => prev = {
            ...prev,
            measures: {
                data: response?.response.payload,
                message: response?.response.message,
                isLoaded: true,
            },
        });
        return response;
    };

    const findMeasureTableByAdID = async ({ idAd }: FindMeasureTableByAdIDProps) => {
        const { services } = client();

        const response = await services.ads.findMeasureTableByAdID({ idAd });
        return response;
    };

    const action = async ({ type, id }: AdActionProps) => {
        const { services } = client();

        const response = await services.ads.action({ type, id });

        return response;
    };

    const select = ({ _id, action }: {
        _id: string,
        action: 'add' | 'clear',
    }) => {
        if (action === 'add') {
            setAd((prev) => prev = {
                ...prev,
                currentAd: {
                    _id: _id,
                    isLoaded: true,
                },
            });
        }

        if (action === 'clear') {
            setAd((prev) => prev = {
                ...prev,
                currentAd: {
                    _id: '',
                    isLoaded: false,
                },
            });
        }
    };

    // * IMAGES
    const handleImages = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files as FileList;
        if (event.target.files) {
            const file = event.target.files[0] as File;
            const fileTypes = ['image/png', 'image/jpeg'];
            for (let i = 0; fileTypes.length > i; i++) {
                if (fileTypes[i].includes(file.type)) {
                    const response = await images.add(event);

                    if (!response.error) {
                        if (files.length === 1 && !(adImages.length >= 4)) {
                            setAdImages((prev) => prev = [
                                ...prev,
                                ...response.files,
                            ]);
                        } else {
                            const res = response.files.slice(0, 4 - adImages.length);
                            setAdImages((prev) => prev = [
                                ...prev,
                                ...res,
                            ]);
                        }
                    }
                    return;
                }
            };
        };
    };

    const selectImage = (event: React.ChangeEvent<HTMLInputElement>,
        type: ImagesActionType) => {
        const selectedFiles = event.target.files as FileList;
        const dataImageURL = URL.createObjectURL(selectedFiles?.[0]);
        const imageToArray = dataImageURL.split('/');
        const imageHash = imageToArray[imageToArray.length - 1];
        const existsImages = Object.entries(type === 'ad' && adImages ||
            type === 'variations' && adVariationsImages.variationList ||
            type === 'color' && adVariationsImages.colors);

        if (type === 'color') {
            setAdVariationsImages((prev) => prev = {
                ...prev,
                colors: [{
                    id: imageHash,
                    src: dataImageURL,
                    content: dataImageURL,
                    file: selectedFiles[0],
                }],
            });
            return;
        }

        if (!!existsImages.length) {
            if (!(existsImages.length >= 4)) {
                const find = type === 'ad' && adImages.
                    find((item) => item.id === imageHash) ||
                    type === 'variations' && adVariationsImages.variationList.
                        find((item) => item.id === imageHash);

                if (!find) {
                    if (type === 'ad') {
                        setAdImages((prev) => prev = [...prev, {
                            id: imageHash,
                            src: dataImageURL,
                            content: dataImageURL,
                            file: selectedFiles[0],
                        }]);
                        return;
                    }
                    if (type === 'variations') {
                        setAdVariationsImages((prev) => prev = {
                            ...prev,
                            variationList: [...prev.variationList, {
                                id: imageHash,
                                src: dataImageURL,
                                content: dataImageURL,
                                file: selectedFiles[0],
                            }],
                        });
                        return;
                    }
                } else {
                    if (type === 'ad') {
                        const filter = adImages.filter((item) => item.id !== imageHash);
                        setAdImages((prev) => prev = [...filter]);
                        return;
                    }

                    if (type === 'variations') {
                        const filter = adVariationsImages.variationList
                            .filter((item) => item.id !== imageHash);
                        setAdVariationsImages((prev) => prev = {
                            ...prev, variationList: filter,
                        });
                        return;
                    }

                    if (type === 'color') {
                        const filter = adVariationsImages.colors
                            .filter((item) => item.id !== imageHash);
                        setAdVariationsImages((prev) => prev = {
                            ...prev, colors: filter,
                        });
                        return;
                    }
                }
            }
        } else {
            if (type === 'ad') {
                setAdImages((prev) => prev = [...prev, {
                    id: imageHash,
                    src: dataImageURL,
                    content: dataImageURL,
                    file: selectedFiles[0],
                }]);
            }
            if (type === 'variations') {
                setAdVariationsImages((prev) => prev = {
                    ...prev,
                    variationList: [
                        ...prev.variationList,
                        {
                            id: imageHash,
                            src: dataImageURL,
                            content: dataImageURL,
                            file: selectedFiles[0],
                        },
                    ],
                });
                return;
            }
        }
    };

    const selectImagesToEdit = (type: 'ad' |
        'variation' |
        'variation-color' |
        'variation-custom-color', images: AdImagesProps[]) => {
        if (type === 'ad') {
            return setAdImages((prev) => prev = [...prev, ...images]);
        }

        if (type === 'variation') {
            return setAdVariationsImages((prev) => prev = {
                ...prev,
                variationList: images,
            });
        }

        if (type === 'variation-color') {
            return setAdVariationsImages((prev) => prev = {
                ...prev,
                colors: {
                    ...images,
                },
            });
        }

        if (type === 'variation-custom-color') {
            return setAdVariationsImages((prev) => prev = {
                ...prev,
                customColors: images,
            });
        }
    };

    const handleDelete = (id: string) => {
        const filter = adImages.filter((item) => item.id !== id);
        return setAdImages((prev) => prev = [...filter]);
    };

    const clearAdVariationsImages = () => {
        setAdVariationsImages((prev) => prev = {
            variationList: [],
            colors: [],
            customColors: [],
        });
    };

    const loadConditions = async () => {
        const { services } = client();
        setAd((prev) => prev = {
            ...prev,
            conditions: {
                ...DEFAULTS.ad.conditions,
            },
        });

        const response = await services.ads.load.conditions();
        return setAd((prev) => prev = {
            ...prev,
            conditions: {
                data: response?.response.payload,
                message: response?.response.message,
                isLoaded: true,
            },
        });
    };

    const loadBrands = async () => {
        const { services } = client();
        setAd((prev) => prev = {
            ...prev,
            brand: {
                ...DEFAULTS.ad.brand,
            },
        });

        const response = await services.ads.load.brands();

        const mapBrands: AdBrandsProps[] = response?.response.payload ? response?.
            response.
            payload.
            map((brand: AdBrandsProps) => {
                return {
                    ...brand,
                    label: brand.nome,
                };
            }) : [];
        return setAd((prev) => prev = {
            ...prev,
            brand: {
                data: mapBrands,
                message: response?.response.message,
                isLoaded: true,
            },
        });
    };

    const loadWeights = async () => {
        const { services } = client();
        setAd((prev) => prev = {
            ...prev,
            weights: {
                ...DEFAULTS.ad.weights,
            },
        });

        const response = await services.ads.load.weights();

        if (!response?.error) {
            return setAd((prev) => prev = {
                ...prev,
                weights: {
                    data: response?.response.payload,
                    message: response?.response.message,
                    isLoaded: true,
                },
            });
        };
    };

    const loadAllSizes = async () => {
        const { services } = client();

        const response = await services.
            ads.
            load.
            sizes();

        setAd((prev) => prev = {
            ...prev,
            sizes: {
                data: response?.response.payload,
                message: response?.response.message,
                isLoaded: true,
            },
        });
    };

    const loadSizes = async ({ type, categoriesList }: LoadSizesProps) => {
        const { services } = client();
        setAd((prev) => prev = {
            ...prev,
            sizes: DEFAULTS.ad.sizes,
        });

        if (type === 'all') {
            const response = await services.
                ads.
                load.
                sizes();

            setAd((prev) => prev = {
                ...prev,
                sizes: {
                    data: response?.response.payload ?
                        response?.response.payload : [],
                    message: response?.response.message,
                    isLoaded: true,
                },
            });
            return response;
        };

        if (type === 'byCategory') {
            const response = await services.
                ads.
                load.
                sizesByCategory({ categoriesList: categoriesList as string[] });

            setAd((prev) => prev = {
                ...prev,
                sizes: {
                    data: response?.response.payload ?
                        response?.response.payload :
                        DEFAULTS.ad.sizes.data,
                    message: response?.response.message,
                    isLoaded: response?.response.payload ? true : false,
                },
            });
            return response;
        };

        if (type === 'reset') {
            setAd((prev) => prev = {
                ...prev,
                ...DEFAULTS.ad.sizes,
            });
        };
    };

    const loadShippingOptions = async () => {
        const { services } = client();
        const response = await services.ads.load.deliveryTypes();
        setAd((prev) => prev = {
            ...prev,
            deliveryTypes: {
                isLoaded: true,
                data: response?.response.payload,
                message: response?.response.message,
            },
        });
        return response;
    };

    const loadUserCommission = async () => {
        const { services } = client();
        setAd((prev) => prev = {
            ...prev,
            userCommission: {
                ...DEFAULTS.ad.userCommission,
            },
        });
        const response = await services.ads.load.userCommission();

        setAd((prev) => prev = {
            ...prev,
            userCommission: {
                data: response?.response.payload,
                message: response?.response.message,
                isLoaded: true,
            },
        });
        return response;
    };

    const loadColors = async () => {
        const { services } = client();
        const response = await services.ads.load.colors();
        setAd((prev) => prev = {
            ...prev,
            colors: {
                data: response?.response.payload,
                message: response?.response.message,
                isLoaded: true,
            },
        });
    };

    const newAdInsertData = ({ type, data }: NewAdInsertDataProps) => {
        if (type && newAd[type]) {
            setNewAd((prev) => prev = {
                ...prev,
                [type]: data[type],
            });
        } else if (type) {
            setNewAd((prev) => prev = {
                ...prev,
                [type]: data[type],
            });
        }
    };

    const newAdRemoveData = ({ type, data }: NewAdRemoveData) => {
        if (type && newAd[type] &&
            type !== 'deliveryTypes' &&
            type !== 'title' &&
            type !== 'description' &&
            type !== 'quantity_days_available' &&
            type !== 'composition' &&
            type !== 'others' &&
            type !== 'stockQuantity' &&
            type !== 'sku' &&
            type !== 'weight' &&
            type !== 'measureTable' &&
            type !== 'price') {
            const filter = newAd[type]?.filter((item) => item._id !== data?._id);
            setNewAd((prev) => prev = {
                ...prev,
                [type]: filter,
            });
        }

        switch (type) {
            case 'title':
                setNewAd((prev) => prev = {
                    ...prev,
                    title: '',
                });
                break;
            case 'description':
                setNewAd((prev) => prev = {
                    ...prev,
                    description: '',
                });
                break;
            case 'price':
                setNewAd((prev) => prev = {
                    ...prev,
                    price: '',
                });
                break;
            case 'deliveryTypes':
                setNewAd((prev) => prev = {
                    ...prev,
                    deliveryTypes: {
                        aplicativo_entrega: false,
                        entrega_maos: false,
                    },
                });
                break;
            default:
                break;
        }
    };

    const clearAdData = ({ type, clearAll }: NewAdClearAdData) => {
        if (clearAll) {
            setAd((prev) => prev = DEFAULTS.ad);
            setAdImages((prev) => prev = []);
            setNewAd((prev) => prev = DEFAULTS.newAd);
            setMeasuresAd((prev) => prev = DEFAULTS.measures);
            setAdVariations((prev) => prev = DEFAULTS.variations);
            setAdVariationsImages((prev) => prev = DEFAULTS.variations.images);
            setAdVariationsColor((prev) => prev = DEFAULTS.variations.colors);
            setAdVariationsCustomColor((prev) => prev = DEFAULTS.variations.colors);
        } else {
            newAdInsertData({
                type: type as NewAdDataTypes,
                data: {
                    ...DEFAULTS.newAd,
                },
            });
        }
    };

    const createAd = async (ad: CreateNewAdCreateProps) => {
        const { services } = client();
        const response = await services.ads.create(ad);
        return response;
    };

    const updateAd = async (idAd: string, ad: CreateNewAdCreateProps) => {
        const { services } = client();
        const response = await services.ads.update(idAd, ad);
        return response;
    };

    const createAdVariation = async ({
        adID,
        ad,
    }: CreateAdVariation) => {
        const { services } = client();
        const response = await services.ads.variations.create({
            adID,
            ad,
        });
        return response;
    };

    const updateAdVariation = async ({
        ad,
    }: UpdateAdVariation) => {
        const { services } = client();
        const response = await services.ads.variations.update({
            ad,
        });
        return response;
    };

    const deleteAdVariation = async ({ idAd, variationID }: DeleteVariationProps) => {
        const { services } = client();
        const response = await services.ads.variations.delete({ idAd, variationID });
        return response;
    };

    const loadHighlightedAds = async ({ limit, aleatory }: HighlightedAdsProps) => {
        setHighlightedAds((prev) => prev = DEFAULTS.highlighted);
        const { services } = client();
        const response = await services.ads.load.highlighted({ limit, aleatory });

        setHighlightedAds((prev) => prev = {
            isLoaded: true,
            data: response?.response.payload,
            message: response?.response.message,
        });
        return response;
    };

    // * DND
    // const reorder = (list: any, startIndex: any, endIndex: any) => {
    //     const result = Array.from(list);
    //     const [removed] = result.splice(startIndex, 1);
    //     result.splice(endIndex, 0, removed);

    //     return result;
    // };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const items = images.dnd.reorder(
            adImages,
            result.source.index,
            result.destination.index,
        );

        setAdImages(items as any);
    };

    // React.useEffect(() => {
    //     if (user.isLoaded) {
    //         loadUserCommission();
    //     }
    // }, [user.isLoaded]);

    React.useEffect(() => {
        checkIsThereNewAdData();
    }, [newAd.brand?.length,
    newAd.category?.length,
    newAd.condition?.length,
    newAd.size?.length,
    adImages.length]);

    return {
        search,
        loadConditions,
        loadBrands,
        loadWeights,
        loadSizes,
        loadAllSizes,
        loadUserCommission,
        loadShippingOptions,
        loadMeasureTypes,
        loadColors,
        // loadVariations,
        list,
        find,
        findVariation,
        findMeasureTableByAdID,
        ad,
        action,
        select,
        update: updateAd,
        resetIsLoaded,
        externalActions: {
            status: actionOnExternalComponent,
            action: externalDialogAction,
        },
        highlighted: {
            load: loadHighlightedAds,
            list: highlightedAds,
        },
        variations: {
            listByAdID: {
                list: adVariations.listByAdID ?
                    adVariations.listByAdID.list :
                    DEFAULTS.variations.listByAdID.list,
            },
            listByVariationID: findVariationByID,
            images: {
                list: adVariationsImages.variationList,
                colorsList: adVariationsImages.colors,
                customColorsList: adVariationsImages.customColors,
                add: selectImage,
                delete: handleDelete,
                clear: clearAdVariationsImages,
            },
            items: {
                currentSizeID: adVariations.items.currentSizeID,
                list: adVariations.items.list,
                add: addVariationsToCreate,
                reset: resetVariationsItemsData,
                addResponse: adVariations.items.response,
                remove: removeVariationListItem,
            },
            colors: {
                add: addVariantColor,
                addCustom: addVariantCustomColor,
                data: {
                    list: adVariationsColor,
                    customList: adVariationsCustomColor,
                },
            },
        },
        measures: {
            measuresAd,
            addSelectedMeasuresAd,
            addMeasuresAd,
            addMeasuresAdList,
            removeMeasuresAd,
            clearMeasuresAdData,
        },
        createNewAd: {
            images: {
                add: selectImage,
                addNew: handleImages,
                addToEdit: selectImagesToEdit,
                delete: handleDelete,
                list: adImages,
                dnd: {
                    onDragEnd: onDragEnd,
                },
            },
            create: createAd,
            variations: {
                create: createAdVariation,
                update: updateAdVariation,
                delete: deleteAdVariation,
            },
            insertData: newAdInsertData,
            removeData: newAdRemoveData,
            clearAdData: clearAdData,
            data: newAd,
        },
    };
};

const useAds = () => {
    const context = React.useContext(AdsContext);
    return context;
};

export { useAds, adsHookProvider };
