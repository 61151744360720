import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { TopicsActionProps } from '@interfaces';
import { sx } from './sx';
import { Link } from 'react-router-dom';

const TopicsAction: React.FC<TopicsActionProps> = ({
    section, items,
}: TopicsActionProps) => {
    const { typography } = useTheme();
    const { sxBox, sxLink, sxTitleSection, sxIcon, sxTitle } = sx();
    return (
        <Box sx={{ ...sxBox }}>
            <Typography
                variant='h4'
                fontSize={'1rem'}
                fontWeight={typography.fontWeightSemiBold}
                textTransform={'uppercase'}
                sx={{ ...sxTitleSection }}
            >
                {section}
            </Typography>
            {items.map(({ title, link, icon, action }) => (
                (action ?
                    <Box key={title} sx={{ ...sxLink }} onClick={action}>
                        {icon ?
                            <I
                                icon={icon}
                                style={{ ...sxIcon }}/> :
                            <Typography
                                sx={{ ...sxTitle }}>
                                {title}
                            </Typography>
                        }
                    </Box> : link &&
                    <Link key={title} to={link} style={{
                        ...sxLink,
                    }}>
                        {icon ?
                            <I
                                icon={icon}
                                style={{ ...sxIcon }}/> :
                            <Typography
                                sx={{ ...sxTitle }}>
                                {title}
                            </Typography>
                        }
                    </Link>)
            ))}
        </Box>
    );
};

export { TopicsAction };
