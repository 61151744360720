import * as Yup from 'yup';

export const formSchema = Yup.object().shape({
    cep: Yup.string().required('Preencha o CEP').length(9,
        'Informe um CEP válido'),
    state: Yup.string().required('Preencha o estado'),
    city: Yup.string().required('Preeencha a cidade'),
    address: Yup.string().required('Preencha o endereço'),
    number: Yup.number().typeError('Preencha o número')
        .required('Preencha o número'),
    nickname: Yup.string().required('Preencha o apelido'),
    neighborhood: Yup.string().required('Preencha o bairro'),
});
