import React from 'react';
import { Stack, Box, Typography, useTheme, IconButton } from '@mui/material';
import { Price } from '../../../../../../components/design-system/molecule/price';
import { formatNumberToPriceBRL } from '../../../../../../utils/formatNumberToPriceBRL';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { useCheckout } from '@hooks';
import { SnackBarResponse, ToggleStaticProps } from '@interfaces';
import { useCart } from '../../../../../../hooks/shopping';
import { Snackbar } from '@components';

interface CouponListProps {
    title: string;
    discount: number;
}

const CouponList = ({ title, discount }: CouponListProps) => {
    const { palette, typography } = useTheme();
    const { checkout } = useCheckout();
    const { cart } = useCart();
    const snackRef = React.useRef<ToggleStaticProps>(null);

    const [feedback, setFeedback] = React.useState<SnackBarResponse>(
        {} as SnackBarResponse);

    const removeCoupon = async () => {
        const response = await checkout.coupon.remove();
        if (response) {
            setFeedback((prev) => prev = {
                type: response.response.type,
                message: response?.response.message,
            });
            snackRef.current?.show();

            if (!response.error) {
                await cart.load();
            }
        };
    };

    return (
        <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'>
            <Box>
                <Typography
                    variant="body1"
                    color={palette.dark}
                    fontWeight={typography.fontWeightBold}>
                    {title}
                </Typography>
                {/* <Typography
                    variant="caption"
                    color={palette.darkGrey}
                    fontWeight={typography.fontWeightMedium}>
                    <I icon={['far', 'clock']}
                        color={palette.darkGrey}
                        style={{ marginRight: 4 }} />
                    Vence {expiryDate}
                </Typography> */}
            </Box>
            <Stack
                direction={'row'}
                alignItems={'center'}
                spacing={1}
                pl={typography.pxToRem(15)}>
                <Price
                    size='small'
                    value={formatNumberToPriceBRL(discount)}
                    negative />

                <IconButton
                    onClick={() => removeCoupon()}
                    sx={{
                        minHeight: '3.75rem',
                        minWidth: '2.875rem',
                    }}>
                    <Box>
                        <I
                            icon={['fal', 'trash-can']}
                            color={palette.pomegarnate}
                            style={{ fontSize: '1.125rem' }} />
                        <Typography
                            variant="caption"
                            display="block"
                            fontWeight="700"
                            color={palette.darkGrey}
                            fontSize={typography.pxToRem(11)}
                            gutterBottom>
                            Excluir
                        </Typography>
                    </Box>
                </IconButton>
            </Stack>
            <Snackbar
                ref={snackRef}
                message={feedback.message}
                severity={feedback?.type}
            />
        </Stack>
    );
};

export { CouponList };
