/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { FeedbackPage } from '@components';
import { ListOrderProps } from '@interfaces/Checkout';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CheckoutPaymentMethodType } from '@types';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useCheckout } from '../../../../../hooks/shopping/checkout';
import { ContentCreditCard } from './messages/credit-card';
import { ContentPixBoleto } from './messages/pix-boleto';

const DEFAULTS = {
    ORDER: {
        isLoaded: false,
        data: {} as ListOrderProps,
    },
};

const Success = () => {
    const { checkout } = useCheckout();

    const matches = useMediaQuery('(min-width:600px)');
    const { palette, typography } = useTheme();
    const { pxToRem } = typography;

    const params = useParams();

    const [order, setOrder] = React.useState(DEFAULTS.ORDER);
    let contentType;

    const findOrder = async () => {
        setOrder((prev) => prev = DEFAULTS.ORDER);

        const id = params.orderID;
        if (id) {
            const response = await checkout.order.find({ orderID: id });
            if (!(response?.error)) {
                setOrder((prev) => prev = {
                    isLoaded: true,
                    data: response?.response.payload.pagamento,
                });
            }
        };
    };

    // * Change Message By Transaction Type
    const paymentMethod = params.paymentMethodType as CheckoutPaymentMethodType;

    switch (paymentMethod) {
    case 'CARTAO':
        contentType = <ContentCreditCard />;
        break;

    case 'PIX':
        contentType = <ContentPixBoleto
            billing_link={order.isLoaded ?
                order.data.informacoes.url_fatura :
                ''}
        />;
        break;

    case 'BOLETO':
        contentType = <ContentPixBoleto
            billing_link={order.isLoaded ?
                order.data.informacoes.url_fatura :
                ''}
        />;
        break;

    default: contentType = 'aguarde..';
    }

    React.useEffect(() => {
        findOrder();
    }, []);

    return (
        <Box pt={matches ? 6 : 0} pb={6}>
            <FeedbackPage.TemplateSuccess>
                {contentType}
                <Typography
                    variant='h2'
                    component='p'
                    align='center'
                    fontSize={pxToRem(16)}
                    fontWeight={typography.fontWeightMedium}
                    color={palette.dark}
                    sx={{
                        '& a': {
                            color: palette.dark,
                            textDecoration: 'underline',
                        },
                    }}>
                    Acompanhe tudo sobre o seu pedido
                    em  <Link to='/minhas-compras'>"Minhas compras"</Link>
                </Typography>
            </FeedbackPage.TemplateSuccess>
        </Box>
    );
};

export { Success };
